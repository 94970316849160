import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import ModalHeader from '../Modal/ModalHeader';
import { fetchDeviceKeyProtection } from '../../state/device-key/fetchDeviceKeyProtectionActionCreators';
import { AppState } from '../../state/reducers/rootReducer';
import DeviceKeyDownloadBody from './DeviceKeyDownloadBody';
import { CLOSE_DKD_MODAL, CloseDKDModalAction, } from '../../state/device-key-download/downloadHashOfDKDModalReducer';

function DeviceKeyDownloadModal() {
  const rootElement = document.getElementsByTagName('body');
  const dispatch = useDispatch();
  const downloadHash = useSelector(
    (state: AppState) => state.deviceKeyProtection.downloadHashOfDKDModal,
  );

  if (!downloadHash) {
    throw new Error('The required parameter downloadHash is missing!');
  }

  useEffect(() => {
    dispatch(fetchDeviceKeyProtection(downloadHash));
  }, [dispatch, downloadHash]);

  return ReactDOM.createPortal(
    <div
      tabIndex={0}
      className='device-modal'
      id={'device-key-download-draggable'}
      onClick={(event: React.MouseEvent) => event.stopPropagation()}
    >
      <ModalHeader
        isDevice={false}
        titleTranslationId='device-key-download'
        targetId='draggable'
        handleClose={() =>
          dispatch({ type: CLOSE_DKD_MODAL } as CloseDKDModalAction)
        }
      />
      <div className='device-modal__body'>
        <DeviceKeyDownloadBody downloadHash={downloadHash} />
      </div>
    </div>,
    rootElement[0],
  );
}

export default DeviceKeyDownloadModal;
