import { License } from '@wiot/shared-domain/models/license/License';

export const ADD_LICENSE = 'ADD_LICENSE';
export const ADD_LICENSE_SUCCEEDED = 'ADD_LICENSE_SUCCEEDED';
export const ADD_LICENSE_FAILED = 'ADD_LICENSE_FAILED';

export interface AddLicenseAction {
  type: typeof ADD_LICENSE;
  license: License;
  fileName: string;
}

export interface AddLicenseSucceededAction {
  type: typeof ADD_LICENSE_SUCCEEDED;
}

export interface AddLicenseFailedAction {
  type: typeof ADD_LICENSE_FAILED;
  errorMessage: string;
}
