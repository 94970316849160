import { Translate } from 'react-localize-redux';
import React from 'react';

interface LoginButtonProps {
  show: number;
}

const LoginButton = (props: LoginButtonProps) => (
  <button
    className="login-form__button login-form__button--valid border-color-main background-color-main"
    type="submit"
    data-testid="login-button"
    style={{ opacity: props.show }}
  >
    <Translate id="login" />
  </button>
);

export default LoginButton;
