import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';

export type PropertySettingsModalState = {
  selectedDeviceGroup?: DeviceGroup;
  isVisible: boolean;
  isLoading: boolean;
};

export const propertySettingsModalState: PropertySettingsModalState = {
  isVisible: false,
  isLoading: false,
};
