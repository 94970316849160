import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'react-localize-redux';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ProcessStepProps {
  isActive: boolean;
  titleTranslationId: string;
  mainMessage?: string | JSX.Element;
  mainMessageIcon?: IconProp;
  mainMessageIconColor?: string;
  children?: React.ReactNode;
  isLastStep?: boolean;
}

function ProcessStep({
  isActive,
  titleTranslationId,
  mainMessage,
  mainMessageIcon,
  mainMessageIconColor,
  children,
  isLastStep,
}: ProcessStepProps) {
  return (
    <div className={isActive ? 'process-step-container' : 'step-inactive process-step-container'}>
      <div className="status-box">
        <div className="process-step-container-status-box">
          <FontAwesomeIcon
            visibility={isActive ? 'visible' : 'hidden'}
            icon={faCheck}
            size="2x"
            color="var(--main-color)"
          />
        </div>
        {!isLastStep && <div className="vertical-line" />}
      </div>

      <div className="process-step-container-body text-line-break">
        <div className="process-step-header">
          <Translate id={titleTranslationId} />
        </div>

        <div className="process-step-container-content">
          {mainMessage && (
            <div>
              {mainMessageIcon && (
                <FontAwesomeIcon
                  size="2x"
                  icon={mainMessageIcon}
                  color={mainMessageIconColor}
                  className="mr-1"
                />
              )}
              &nbsp;
              {mainMessage}
              <br />
            </div>
          )}
          <div className="m-3">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default ProcessStep;
