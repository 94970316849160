import { AppState } from '../../reducers/rootReducer';
import { getInitialSingleGroupSelectState, OneGroupSelectState } from './groupSelectsReducer';


export const selectGroupSelectStateByUuid = (state: AppState, groupSelectUuid: string): OneGroupSelectState => {
  const groupSelect = state.groupSelects.groupSelects.find(
    groupSelect => groupSelect.groupSelectUuid === groupSelectUuid);
  if (!groupSelect) {
    return getInitialSingleGroupSelectState(groupSelectUuid);
  }

  return groupSelect;
};

