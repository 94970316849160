import React from 'react';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';
import { IRole } from '@wiot/shared-domain/models/role/role';
import { SetFieldValue } from '../../state/types';

interface ICustomToggle {
  name: string;
  value: boolean;
  onChange: SetFieldValue;
  label: string;
  values?: IRole;
  readOnly?: boolean;
  groupToggleChange?: (
    name: string,
    value: boolean,
    setFieldValue: SetFieldValue,
    values: IRole,
  ) => void;
}

const CustomToggle = ({
  name,
  value,
  onChange,
  label,
  groupToggleChange,
  values,
  readOnly,
}: ICustomToggle) => {
  const handleOnClick = () => {
    groupToggleChange && values
      ? groupToggleChange(name, !value, onChange, values)
      : onChange(name, !value);
  };

  return (
    <button
      className={classNames('w-100', {
        'form__button--blue background-color-main text-color-white border-color-main': value,
        'form__button--cancel': !value,
      })}
      type="button"
      onClick={handleOnClick}
      disabled={readOnly}
    >
      <Translate id={label} />
    </button>
  );
};

export default CustomToggle;
