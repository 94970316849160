const BASE_URL = (process.env.REACT_APP_BACKEND_URL as string) || '/';

// TODO: Do not use this file, instead add single files for each responsibility

export default {
  baseUrl: BASE_URL,
  baseAPIUrl: `${ BASE_URL }api/v1`,
  authLogin: '/auth/login',
  authLogout: '/auth/logout',
  authUser: '/auth/me',
  register: '/auth/register',
  validateUser: '/auth/validate',
  resetPassword: '/auth/resetPassword',
  resendRegistrationMail: '/auth/email',
  devices: '/devices',
  radioKeyDevices: '/devices/radioKeys',
  gateways: '/devices/gateways',
  deviceMessages: '/deviceReadings',
  /**
   * @deprecated Please use the const DEVICE_GROUPS_API
   */
  deviceGroups: '/deviceGroups',
  deviceGroupTypes: '/deviceGroupTypes',
  deviceKeys: '/deviceKeys',
  import: '/import',
  validate: '/validate',
  users: '/users',
  roles: '/roles',
  permittedUserRoles: '/permittedUserRoles',
  keys: '/radioKeys',
  dataIntegrationJobs: '/dataIntegrationJobs',
  runDataIntegrationJobs: '/run/dataIntegrationJobs',
  deviceTypes: '/deviceTypes',
  dummyDataUrl: 'https://my-json-server.typicode.com/miku86/fake-json-data/db',
  dashboard: '/dashboard',
  images: '/devices/images',
  image: '/devices/image',
  deviceTypeProfile: '/deviceTypeProfiles',
  devicesCache: 'DEVICES_CACHE',
  settings: '/settings',
  systemHealth: '/systemHealth',
  containerVersion: '/systemHealth/containerVersion',
  customizeLogo: '/customize/logo',
  customizeWallpaper: '/customize/wallpaper',
  customizeFavIcon: '/customize/favicon',
  customizeBranding: '/customize/branding',
  siteSettings: '/settings/site',
  ftpUsers: '/ftpUsers',
  customizeTheme: '/customize/theme',
  contactForm: '/contact/email',
  manufacturers: '/manufacturers',
  licenses: '/licenses',
};
