export enum FeatureAvailability {
  None = 0b000000,
  Wiot = 0b000001,
  KeyManager = 0b00010,
  Desktop = 0b00100,
  Mobile = 0b01000,
  WithoutPermissionCheck = 0b10000,
}

export const isAvailableInCurrentMode = (
  itemAvailability: FeatureAvailability,
  isKeyManagerModeEnabled: boolean = false,
): boolean => {
  if (isKeyManagerModeEnabled && hasAvailability(itemAvailability, FeatureAvailability.KeyManager)) {
    return true;
  }

  if (!isKeyManagerModeEnabled && hasAvailability(itemAvailability, FeatureAvailability.Wiot)) {
    return true;
  }

  return false;
};


export const isAvailableForCurrentScreenResolution = (itemAvailability: FeatureAvailability, isMobileDisplay: boolean): boolean => {
    if (isMobileDisplay && hasAvailability(itemAvailability, FeatureAvailability.Mobile)) {
      return true;
    }

    if (!isMobileDisplay && hasAvailability(itemAvailability, FeatureAvailability.Desktop)) {
      return true;
    }

    return false;
  }
;

export const hasAvailability = (featureAvailability: FeatureAvailability, availabilityTag: FeatureAvailability): boolean => {
  return (featureAvailability & availabilityTag) === availabilityTag;
};

