import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Mobile from '../../components/Responsive/Mobile';
import { AppState } from '../../state/reducers/rootReducer';
import DashboardDetails from './DashboardDetails';
import DashboardHeader from './DashboardHeader';
import DashboardOverview from './DashboardOverview';
import ErrorBoundary from '../../components/ErrorBoundary';
import RenderOnCondition from '../../components/RenderOnCondition';
import { fetchWidgetsData } from '../../state/dashboard/fetchWidgetsDataActionCreator';


function Dashboard() {
  const dispatch = useDispatch();

  const isKeyManagerModeEnabled = useSelector((state: AppState) => state.siteSettings.isKeyManagerModeEnabled);

  useEffect(() => {
    dispatch(fetchWidgetsData());
  }, []);

  return (
    <main className="dashboard" data-testid="page-dashboard">
      <ErrorBoundary>
        <Mobile>
          <DashboardHeader/>
        </Mobile>
        <DashboardOverview/>
        <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
          <DashboardDetails/>
        </RenderOnCondition>
      </ErrorBoundary>
    </main>
  );
}

export default Dashboard;
