import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import ReactAsyncSelect from 'react-select/async';
import { components, ControlProps, OptionTypeBase, Styles, Theme } from 'react-select';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { IndicatorProps } from 'react-select/src/components/indicators';
import { OptionProps } from 'react-select/src/components/Option';
import classNames from 'classnames';
import { ActionMeta, ValueType } from 'react-select/src/types';
import { AppState } from '../../../state/reducers/rootReducer';
import useTranslation from '../../../hooks/useTranslation';
import { getSelectStyles } from '../../Input/select/get-styles';
import { Control } from './components/Control';
import { ValueContainer } from './components/ValueContainer';
import { DropdownIndicator } from './components/DropdownIndicator';
import { Option } from './components/Option';
import { getReasoningMessageForNoOptions } from './functions/get-reasoning-message-for-no-options';
import { ClearIndicator } from './components/ClearIndicator';
import { getPlaceholderForSelect } from './functions/get-placeholder-for-select';
import { sortOptions } from './functions/sort-options';
import { InputContextType } from '../../Input/input-context-type';
import { showTooManySelectedOptionsError } from './functions/show-too-many-selected-options-error';
import { MINIMUM_NUMBER_OF_CHARACTERS_TO_START_SEARCH } from './constants/minimum-number-of-characters-to-start-search';
import { MAXIMUM_NUMBER_OF_SELECTED_OPTIONS } from './functions/selection-limits';

export interface AsyncBaseSelectProps<OptionType extends OptionTypeBase> {
  translationId: string;
  loadOptions: (inputValue: string) => Promise<OptionType[]>;
  selectedOptions: undefined | OptionType | OptionType[];
  onChange: (values: ValueType<OptionType>, action?: ActionMeta<OptionType>) => void;
  defaultValue?: OptionType | OptionType[];

  getOptionLabel?: (option: OptionType) => string;
  getOptionValue?: (option: OptionType) => string;

  placeholderTranslationId?: string;
  placeholderPrefix?: string;

  option?: (props: OptionProps<OptionType>) => React.ReactElement;
  noOptionsMessage?: (args: any) => string;

  className?: string;
  inputContextType?: InputContextType;
  showDefaultOptions?: boolean;
  isMulti?: boolean;
  hasClearIndicator?: boolean;
  readOnly?: boolean;

  isLoading?: boolean;
  inputValue?: string;
  onInputChange?: (value: string) => void;
  translateOptions?: boolean;
  sortLabels?: boolean;
}

export const AsyncBaseSelect = <OptionType extends OptionTypeBase> (props: AsyncBaseSelectProps<OptionType>) => {
  const {
    translationId,
    loadOptions,
    selectedOptions,
    onChange,

    getOptionLabel,
    getOptionValue,

    placeholderTranslationId,
    placeholderPrefix,

    option,
    noOptionsMessage,

    className,
    inputContextType= InputContextType.MODAL,
    showDefaultOptions = true,
    isMulti = true,
    hasClearIndicator = false,
    readOnly = false,
    translateOptions = true,

    isLoading,
    inputValue,
    onInputChange,
    sortLabels = true,
  } = props;

  const isMobileDisplay = useSelector((state: AppState) => state.isMobileDisplay);
  const reactSelectStyles = getSelectStyles(isMobileDisplay, undefined, undefined, 120);
  const translate = useTranslation();

  const loadSelectableOptions = async (inputValue: string) => {
    const preventSearchOnEmptyString = !inputValue && !showDefaultOptions;
    const isSearchStringToShort = inputValue && inputValue.length < MINIMUM_NUMBER_OF_CHARACTERS_TO_START_SEARCH;
    if (preventSearchOnEmptyString || isSearchStringToShort) {
      return [];
    }
    const options = await loadOptions(inputValue);

    // (DM) TODO: WIOT-1239 react-select `sortLabel` prop is not working as expected. This is a workaround.
    if (sortLabels) {
      return sortOptions<OptionType>(options, getOptionLabel);
    }

    return options;
  }

  const handleSelectionChange = (values: ValueType<OptionType>) => {
    if (values?.length > MAXIMUM_NUMBER_OF_SELECTED_OPTIONS) {
      showTooManySelectedOptionsError(translate);
      return;
    }
    onChange(values);
  }

  const getControl = (controlProps: ControlProps<OptionType>) =>
    <Control
      { ...controlProps }
    />

  const emptySelection = isMulti ? [] : undefined;
  const getClearIndicator = (clearIndicatorProps: IndicatorProps<OptionType>) =>
    (!hasClearIndicator || readOnly)
    ? null
    : (
      <ClearIndicator
        { ...clearIndicatorProps }
        clear={ () => handleSelectionChange(emptySelection) }
      />
    );

  const getDropdownIndicator = (dropdownIndicatorProps: IndicatorProps<OptionType>) => readOnly ? null : (
      <DropdownIndicator
        { ...dropdownIndicatorProps }
      />
    );

  const getOptions = (optionProps: OptionProps<OptionType>) => {
    if (option) {
      return option(optionProps);
    } else {
      return (
        <Option
          {...optionProps}
          isOptionChecked={ optionProps.isSelected }
          label={ getOptionLabel ? getOptionLabel(optionProps.data) : optionProps.data.label }
        />
      );
    }
  }

  const getPlaceholder = getPlaceholderForSelect(
      translate,
      selectedOptions,
      placeholderTranslationId || translationId,
      getOptionLabel,
      translateOptions,
      placeholderPrefix,
    );

  const getStyles: Styles = ({
    ...reactSelectStyles,
    menuList: (provided: CSSProperties) => ({
      ...provided,
      fontSize: isMobileDisplay ? '16px' : '12px',
    }),
  });
  const getTheme = (theme: Theme): Theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#E4F0FDFF',
    },
  });

  return (
    <ReactAsyncSelect
      id={`${ inputContextType }-${ translationId }-async-select-component`}
      readOnly={ readOnly }
      className={ classNames(
        className,
        `${ inputContextType }-select`,
        { [`${ inputContextType }-input--readonly select-disabled`] : readOnly, }) }
      classNamePrefix={ `${ inputContextType }-select` }
      openMenuOnClick={ !readOnly }
      onSelectResetsInput={ false }
      closeMenuOnSelect
      components={{
        Control: getControl,
        Input: readOnly ? () => null : components.Input,
        ValueContainer,
        ClearIndicator: readOnly ? () => null : getClearIndicator,
        DropdownIndicator: readOnly ? () => null : getDropdownIndicator,
        Option: getOptions,
        Placeholder: () => null,
        MultiValueContainer: () => null,
        MultiValueLabel: () => null,
        SingleValue: () => null,
        IndicatorSeparator: () => null,
      }}
      isMulti={ isMulti }
      cacheOptions
      defaultOptions={ showDefaultOptions }
      isClearable={ true }
      isSearchable
      backspaceRemovesValue={ false }
      value={ selectedOptions }
      onChange={ handleSelectionChange }
      loadOptions={ loadSelectableOptions }
      getOptionLabel={ getOptionLabel }
      getOptionValue={ getOptionValue }
      placeholder={ getPlaceholder }
      noOptionsMessage={
        noOptionsMessage
          ? noOptionsMessage
          : (args) => getReasoningMessageForNoOptions(args.inputValue, translate)
      }
      isLoading={ isLoading }
      inputValue={ inputValue }
      onInputChange={ onInputChange }
      hideSelectedOptions={ false }
      styles={ getStyles }
      theme={ getTheme }
    />
  );
}
