import { DeviceViews } from '@wiot/shared-domain/models/device/device';
import React from 'react';

import Toggle, { ToggleOption } from './Toggle';

export interface ToggleViewProps {
  handleViewToggle?: (value: DeviceViews) => void;
  viewOptions?: ToggleOption[];
  selectedView?: DeviceViews;
}

const ToggleView = ({ handleViewToggle, viewOptions, selectedView }: ToggleViewProps) => (
  <Toggle
    label="views"
    handleToggle={handleViewToggle}
    options={viewOptions}
    selectedOption={selectedView}
  />
);

export default ToggleView;
