import { SAVE_KEYS, TOGGLE_SELECT_ALL_KEYS, TOGGLE_SELECT_KEY } from '../constants/actionTypes';
import { KeysActionTypes, KeysState, RadioKeyExtended } from '../types';

const initialState: KeysState = [];

export const keysReducer = (
  state: KeysState = initialState,
  action: KeysActionTypes,
): KeysState => {
  switch (action.type) {
    case SAVE_KEYS:
      return action.payload.map((key: RadioKeyExtended) => ({
        ...key,
        checked: false,
      }));
    case TOGGLE_SELECT_KEY:
      return state.map((key: RadioKeyExtended) => {
        if (String(key.id) !== action.payload) {
          return key;
        }
        return {
          ...key,
          checked: !key.checked,
        };
      });

    case TOGGLE_SELECT_ALL_KEYS:
      return state.map((key: RadioKeyExtended) => ({
        ...key,
        checked: action.payload,
      }));

    default:
      return state;
  }
};
