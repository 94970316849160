import React from 'react';
import { MapConsumer } from 'react-leaflet';

export const InvalidateSize = () => (
  /*
  Leaflet has a known unresolved issue where the map doesn't load completely until a page resize. One of the
  commonly suggested solution for this is to calling invalidateSize() on the map element.
  */
  <MapConsumer>
    {(map) => {
      setTimeout(() => map.invalidateSize());
      return null;
    }}
  </MapConsumer>
);
