import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';

import { BlobWithPath } from '@wiot/shared-domain/models/data-integration/data-integration';
import { RadioKey } from '@wiot/shared-domain/models/radio-key/radio-key';

interface IXmlValidationResultSuccessProps {
  objects: RadioKey[];
  file: BlobWithPath;
}

const XmlValidationResultSuccess = (props: IXmlValidationResultSuccessProps) => {
  const { objects, file } = props;

  return (
    <>
      <h4 className="form__subheading">
        <Translate id="preview" />: {file.path}{' '}
      </h4>
      <Col className="form__label">
        {objects.map((object, index) => (
          <Row className="mt-1" key={`${object.name}-${object.key}`}>
            {index + 1}. <Translate id="key" />:{' '}
            <span className="integration-modal__code">{object.key}</span>{' '}
            <Translate id="key-name" />:{' '}
            <span className="integration-modal__code">{object.name}</span>
          </Row>
        ))}
      </Col>
    </>
  );
};

export default XmlValidationResultSuccess;
