import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { DeviceGroup, DeviceGroupType } from '@wiot/shared-domain/models/device-group/device-group';
import DeviceActionModal from '../../DeviceManager/DeviceActionModal/DeviceActionModal';
import DeviceGroupActionModal from '../../DeviceGroups/DeviceGroupActionModal';
import ConfirmModal, { ConfirmationVariant } from '../../../components/Modal/ConfirmModal';
import { deviceGroupIcons } from '../../../constants';
import QUESTION_MARK from '../../../assets/devices/question-mark-small.svg';
import RenderOnCondition from '../../../components/RenderOnCondition';
import DeviceGroupDetailsModal from '../../DeviceGroups/DeviceGroupDetailsModal';
import TableMenu from '../../DeviceGroups/Table/TableMenu';
import UserActionModal from '../../UserManagement/UserActionModal/UserActionModal';
import { AppState } from '../../../state/reducers/rootReducer';
import { getMenuId } from '../../../components/PortalMenu/constants/context-menu-ids';
import { closeContextMenu, openContextMenu } from '../../../state/context-menu/toggleContextMenuActionCreators';
import { Tooltip } from '../../../components/shared/Tooltip';

interface IDeviceGroupItemProps {
  onItemChanged: () => void;
  deviceGroup: DeviceGroup;
  removeUnit: (id: string) => Promise<void>;
  handleStepInto: (group: DeviceGroup) => void;
}

const DeviceGroupItem = ({
  onItemChanged,
  deviceGroup,
  removeUnit,
  handleStepInto,
}: IDeviceGroupItemProps) => {
  const menuButtonRef = useRef(null);
  const [showDeviceGroupDetails, setShowDeviceGroupDetails] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeviceGroupActionModal, setShowDeviceGroupActionModal] = useState(false);
  const [
    showDeviceGroupActionAddGroupInGroupModal,
    setShowDeviceGroupActionAddGroupInGroupModal,
  ] = useState(false);
  const [
    showDeviceGroupActionAddDeviceInGroupModal,
    setShowDeviceGroupActionAddDeviceInGroupModal,
  ] = useState(false);

  const [showUserActionModalAddUserInGroup, setShowUserActionModalAddUserInGroup] = useState(false);

  const visibleContextMenu = useSelector((state: AppState) => state.contextMenu.visibleContextMenuId);

  const dispatch = useDispatch();

  const menuId = getMenuId(deviceGroup.id);
  const showMenu = (visibleContextMenu === menuId);
  const closeMenu = (event?: React.MouseEvent) => {
    event && event.preventDefault();
    dispatch(closeContextMenu());
  }
  const toggleMenu = () => {
    if (!showMenu) {
      dispatch(openContextMenu(menuId));
    } else {
      dispatch(closeContextMenu());
    }
  };

  const toggleDeviceGroupDetails = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    event && event.preventDefault();
    setShowDeviceGroupDetails((prevState) => !prevState);
  };

  const toggleShowMenu = (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
    event && event.preventDefault();
    toggleMenu();
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal((prevState) => !prevState);
    closeMenu();
  };

  const toggleDeviceGroupActionModal = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDeviceGroupActionModal((prevState) => !prevState);
    closeMenu();
  };

  const toggleDeviceGroupActionAddGroupInGroupModal = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDeviceGroupActionAddGroupInGroupModal((prevState) => !prevState);
    closeMenu();
  };

  const toggleDeviceActionModalAddDeviceInGroup = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDeviceGroupActionAddDeviceInGroupModal((prevState) => !prevState);
    closeMenu();
  };

  const toggleUserActionModalAddUserInGroup = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowUserActionModalAddUserInGroup((prevState) => !prevState);
    closeMenu();
  };

  const { id, name, type } = deviceGroup;

  return (
    <div className="radio-key-data-card-container">
      <Row className="radio-key-data-card-container__header data-card-container__header-dark">
        <div className="radio-key-data-card-container__header__title text-overflow-ellipsis">{name}</div>
      </Row>
      <Row className="radio-key-data-card-container__body p-0">
        <Col xs={6} className="radio-key-data-card-container__key">
          <img
            data-tip="device-group-type-icon"
            data-for={`deviceGroupType-${id}-${type}`}
            src={(type && deviceGroupIcons[type as DeviceGroupType]) || QUESTION_MARK}
            alt="device group type"
          />
          <Tooltip
            id={`deviceGroupType-${id}-${type}`}
            place="right"
          >
            {type ? <Translate id={type} /> : '-'}
          </Tooltip>
        </Col>
        <Col
          xs={6}
          className="group-navigation-container"
          onClick={() => handleStepInto(deviceGroup)}
        >
          <FontAwesomeIcon icon={faChevronRight} className="navigation-icon" />
        </Col>
      </Row>
      <Row className="data-card-container__footer justify-content-center">
        <Col xs={9} className="text-center">
          <span className="device-details-btn" onClick={toggleDeviceGroupDetails}>
            <Translate id="details" />
          </span>
          <RenderOnCondition condition={showDeviceGroupDetails}>
            <DeviceGroupDetailsModal
              closeModal={toggleDeviceGroupDetails}
              deviceGroupData={deviceGroup}
            />
          </RenderOnCondition>
        </Col>
        <Col xs={3} ref={menuButtonRef}>
          <div className="device-details">
            <div className="device-details-icon" role="presentation" onClick={toggleShowMenu}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </div>
            <RenderOnCondition condition={showMenu}>
              <TableMenu
                isMobileDisplay
                deviceGroupData={deviceGroup}
                removeUnit={removeUnit}
                menuId={ menuId }
                toggleDetails={toggleDeviceGroupDetails}
                toggleDeviceGroupActionModal={toggleDeviceGroupActionModal}
                toggleDeleteModal={toggleDeleteModal}
                toggleDeviceGroupActionModalAddGroupInGroup={
                  toggleDeviceGroupActionAddGroupInGroupModal
                }
                toggleDeviceActionModalAddDeviceInGroup={toggleDeviceActionModalAddDeviceInGroup}
                toggleUserActionModalAddUserInGroup={toggleUserActionModalAddUserInGroup}
              />
            </RenderOnCondition>
            <RenderOnCondition condition={showDeviceGroupActionAddDeviceInGroupModal}>
              <DeviceActionModal
                closeAddAndUpdateModal={toggleDeviceActionModalAddDeviceInGroup}
                title="add-dev-under-group"
                showDeleteButton={false}
                addUnit
                selectedDeviceGroup={{ id }}
                disableGroupSelect
              />
            </RenderOnCondition>
            <RenderOnCondition condition={showDeviceGroupActionModal}>
              <DeviceGroupActionModal
                closeAddAndUpdateModal={toggleDeviceGroupActionModal}
                title="update-group"
                showDeleteButton
                addUnit={false}
                removeUnit={(itemId: string) => removeUnit(itemId)}
                id={id}
              />
            </RenderOnCondition>
            <RenderOnCondition condition={showDeleteModal}>
              <ConfirmModal
                modalCloseRequested={toggleDeleteModal}
                actionConfirmed={() => removeUnit(id!)}
                translationIdOfElementType="device-group"
                confirmationVariant={ ConfirmationVariant.DELETE }
              />
            </RenderOnCondition>
            <RenderOnCondition condition={showDeviceGroupActionAddGroupInGroupModal}>
              <DeviceGroupActionModal
                closeAddAndUpdateModal={toggleDeviceGroupActionAddGroupInGroupModal}
                title="add-group"
                showDeleteButton={false}
                addUnit
                selectedDeviceGroup={id}
                disableGroupSelect
              />
            </RenderOnCondition>
            <RenderOnCondition condition={showUserActionModalAddUserInGroup}>
              <UserActionModal
                saved={() => {
                  onItemChanged();
                  toggleUserActionModalAddUserInGroup();
                }}
                cancelled={toggleUserActionModalAddUserInGroup}
                isInCreationMode
                title="add-user-under-group"
                showDeleteButton={false}
                selectedDeviceGroupId={id}
              />
            </RenderOnCondition>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DeviceGroupItem;
