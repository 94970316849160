import { Dispatch } from 'redux';

import { authenticatedInstance, handleApiError } from '../../../api/apiHelpers';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { toastr } from 'react-redux-toastr';
import useTranslation from '../../../hooks/useTranslation';
import { normaliseDeviceGroup } from '../deviceGroupApiCallPreparer';
import { deviceGroupUpdatedAction, deviceGroupUpdateFailedAction, updateDeviceGroupAction } from './deviceGroupUpdateTypes';
import { DEVICE_GROUPS_API } from '../deviceGroupApi';

export const updateDeviceGroupActionCreator = (
  deviceGroup: DeviceGroup,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const translate = useTranslation();

  dispatch(updateDeviceGroupAction(deviceGroup));

  try {
    // temporary remove these keys until they are fully implemented
    const normalisedDeviceGroup = normaliseDeviceGroup(deviceGroup) as DeviceGroup;
    const { data: updatedDeviceGroup } = await authenticatedInstance.patch<DeviceGroup>(DEVICE_GROUPS_API, normalisedDeviceGroup);
    dispatch(deviceGroupUpdatedAction(updatedDeviceGroup));
    toastr.success(
      translate('success').toString(),
      translate('edit-dev-group-success').toString(),
    );
  } catch (error) {
    handleApiError(error);
    dispatch(deviceGroupUpdateFailedAction(deviceGroup));
  }
};
