import "./ResponsiveContainer.less";

import React, { CSSProperties, ReactElement } from 'react';
import { Translate } from 'react-localize-redux';
import { Col } from 'react-bootstrap';
import ErrorBoundary from '../ErrorBoundary';
import { MobileBreakpoints } from '../../state/types';
import { useSelector } from 'react-redux';
import { AppState } from '../../state/reducers/rootReducer';

interface ResponsiveContainerProps {
  children?: ReactElement | ReactElement[] | null;
  title: string;
  maxWidths?: MobileBreakpoints;
  maxContentHeigth?: number;
  idOfElement?: string;
  headerLevel?: number;
  className?: string;
}

const ResponsiveContainer = function (props: ResponsiveContainerProps) {
  const { children, title, maxWidths = {}, maxContentHeigth, idOfElement, headerLevel = 6 } = props;
  const { sm = 12, md = 12, lg = 6, xl = 4 } = maxWidths;
  const isMobileDisplay = useSelector((state: AppState) => state.isMobileDisplay);

  if (!children) {
    return null;
  }

  const className = `vertical-spacer responsive-container ${props.className || ''}`;
  const Header = `h${headerLevel}` as keyof JSX.IntrinsicElements;

  // In mobile devices the height must be dynamic for consistency with the other cards
  const itemCardStyle: CSSProperties = { maxHeight: isMobileDisplay ? 'initial' : maxContentHeigth };

  return (
    <Col
      lg={ lg }
      sm={ sm }
      md={ md }
      xl={ xl }
      className={ className }
      id={ idOfElement }
    >
      <Header className="responsive-container__card--title text-color-main">
        <Translate id={ title }/>
      </Header>
      <div className="responsive-container__item__card" style={ itemCardStyle }>
        <ErrorBoundary>
          <div className="responsive-container__item__row flex-column standard-font-size">
            { children }
          </div>
        </ErrorBoundary>
      </div>
    </Col>
  );
};

export default ResponsiveContainer;
