import { ServerFrontendVersionFetchedAction } from '../../types';
import { SERVER_FRONTEND_VERSION_FETCHED } from '../../constants/actionTypes';

const initialState = '';

export const serverFrontendVersionReducer = (
  state: string = initialState,
  action: ServerFrontendVersionFetchedAction,
): string => {
  switch (action.type) {
    case SERVER_FRONTEND_VERSION_FETCHED:
      return action.version;

    default:
      return state;
  }
};
