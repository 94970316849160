import { IRole } from '@wiot/shared-domain/models/role/role';
import { SAVE_ROLES, TOGGLE_SELECT_ALL_ROLES, TOGGLE_SELECT_ROLE } from '../constants/actionTypes';
import { RolesActionTypes } from '../types';

export const saveRoles = (roles: IRole[] = []): RolesActionTypes => ({
  type: SAVE_ROLES,
  payload: roles,
});

export const toggleSelectAllRoles = (isChecked: boolean): RolesActionTypes => ({
  type: TOGGLE_SELECT_ALL_ROLES,
  payload: isChecked,
});

export const toggleSelectRole = (roleId: string): RolesActionTypes => ({
  type: TOGGLE_SELECT_ROLE,
  payload: roleId,
});
