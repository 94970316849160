import { DeviceReadingSourceType } from '../../../device-reading/device-reading';
import {
  DefaultFormat_DeviceExport
} from '@wiot/shared-domain/models/domain/services/export/export-providers/default-format/default-format.model';
import { AbSys_DeviceExport } from '@wiot/shared-domain/models/domain/services/export/export-providers/absys/absys';
import {
  CasametaVbox_V_1_1_3_DeviceExport
} from '@wiot/shared-domain/models/domain/services/export/export-providers/casameta/casameta-vbox-v1.1.3-json.model';
import {
  MfairConnectCSV_DeviceExport
} from '@wiot/shared-domain/models/domain/services/export/export-providers/mfair-connect/mfair-connect-csv.model';
import { IUser } from '@wiot/shared-domain/models/user/user';
import { PaginationHelper } from '@wiot/shared-domain/models/pagination/pagination-helper';
import { DeviceFilter } from '@wiot/shared-domain/models/device/device-filter';

export enum ExportContentType {
  APPLICATION_JSON = 'application/json',
  TEXT_CSV = 'text/csv',
}

export enum ExportFileExtension {
  CSV = 'csv',
  JSON = 'json',
}

export class Export<T> {
  private readonly _contentType: string;

  constructor(contentType: string, content: T) {
    this._contentType = contentType;
    this.content = content;
  }

  get contentType(): string {
    return this._contentType;
  }

  content: T;
}

export class JsonExport extends Export<DeviceExport[]> {
  constructor(content: DeviceExport[]) {
    super(ExportContentType.APPLICATION_JSON, content);
  }
}

export class CsvExport extends Export<string> {
  constructor(content: string) {
    super(ExportContentType.TEXT_CSV, content);
  }
}

export interface IExportFormat {
  name: string;
  value: string;
  extension: ExportFileExtension;
  inputFieldsToDisplay?: {
    frequency: boolean;
    attributeIds: boolean;
    includeLastPrimaryMeasurement: boolean;
    includeDueDateMeasurement: boolean;
    includeLastGateways: boolean;
  };
}

type DeviceExport = DefaultFormat_DeviceExport | MfairConnectCSV_DeviceExport | AbSys_DeviceExport | CasametaVbox_V_1_1_3_DeviceExport;

export interface IDeviceExportReading {
  date?: string;
  value?: string | number;
  unit?: string;
}

export interface DeviceExportProvider {
  getDeviceExport(
    measurementOptions: MeasurementOptions,
    user: IUser,
    deviceFilter?: DeviceFilter,
    exportContentType?: ExportContentType,
  ): Promise<DeviceExport[] | string>;
}

export interface MeasurementOptions {
  startDate?: Date | null,
  endDate?: Date | null,
}

/**
 * The export format strings should have the following format:
 * {file extension}-{product name}-{version number}(-{optional specifications})
 * - file extension: For example json for .json, csv for .csv files
 * - product name: For example mfair-connect for the M-FAIR connect platform
 * - version number: For example v-1-0-3, with hyphen instead of dots for v1.0.3
 * - optional specifications: For example header-1 to specify a sub-declaration
 */
export enum DeviceExportFormats {
  CSV_MFAIR_CONNECT_V_1_0_0 = 'csv-mfair-connect-v-1-0-0',
  JSON_MFAIR_CONNECT_V_1_0_0 = 'json-mfair-connect-v-1-0-0',
  JSON_ABSYS_V_1_0_0 = 'json-absys-v-1-0-0',
  JSON_CASAMETA_VBOX_V_1_1_3 = 'json-casameta-vbox-v-1-1-3',
  CSV_ENGELMANN_V_2_0_1_HEADER_1 = 'csv-engelmann-v-2-0-1-header-1',
  JSON_DEFAULT_FORMAT_V_1_0_0 = 'json-default-format-v-1-0-0',
}

export function getContentTypeFromDeviceExportFormat(format: string) {
  if (format.split('-')[0] === 'csv') {
    return ExportContentType.TEXT_CSV;
  }
  return ExportContentType.APPLICATION_JSON;
}

export type IDeviceExportReadings = {
  [K in DeviceReadingSourceType]?: IDeviceExportReading;
}
