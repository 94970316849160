import { DataIntegrationJobExtended } from '@wiot/shared-domain/models/data-integration/data-integration';
import {
  SAVE_DATA_INTEGRATION_JOBS,
  TOGGLE_SELECT_DATA_INTEGRATION_JOB,
  TOGGLE_SELECT_ALL_DATA_INTEGRATION_JOBS,
} from '../constants/actionTypes';
import { DataIntegrationJobsActionTypes, DataIntegrationJobsState } from '../types';

const initialState: DataIntegrationJobsState = [];

export const dataIntegrationJobsReducer = (
  state: DataIntegrationJobsState = initialState,
  action: DataIntegrationJobsActionTypes,
): DataIntegrationJobsState => {
  switch (action.type) {
    case SAVE_DATA_INTEGRATION_JOBS:
      return action.payload.map((dataIntegrationJob: DataIntegrationJobExtended) => ({
        ...dataIntegrationJob,
        checked: false,
      }));
    case TOGGLE_SELECT_DATA_INTEGRATION_JOB:
      return state.map((dataIntegrationJob: DataIntegrationJobExtended) => {
        if (String(dataIntegrationJob.id) !== action.payload) {
          return dataIntegrationJob;
        }
        return {
          ...dataIntegrationJob,
          checked: !dataIntegrationJob.checked,
        };
      });

    case TOGGLE_SELECT_ALL_DATA_INTEGRATION_JOBS:
      return state.map((dataIntegrationJob: DataIntegrationJobExtended) => ({
        ...dataIntegrationJob,
        checked: action.payload,
      }));

    default:
      return state;
  }
};
