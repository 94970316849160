import * as React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import UpdateOverview from './UpdateOverview';
import LicensesOverview from './LicensingOverview';

/**
 * Props of the {@link UpdateAndLicenses} component.
 */
export interface UpdateAndLicensesProps extends LocalizeContextProps {
  /**
   * Determines whether the component should be displayed in read-only mode,
   * meaning the user will not be able to add licenses.
   */
  readOnly: boolean;
}

/**
 * A component that displays an overview of the update status and the licensing of the platform.
 */
const UpdateAndLicenses = ({ readOnly }: UpdateAndLicensesProps) => (
  <div>
    <div className="update-and-licenses-container">
      <UpdateOverview />
      <LicensesOverview readOnly={readOnly} />
    </div>
  </div>
);

export default withLocalize(UpdateAndLicenses);
