import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { AppState } from '../../state/reducers/rootReducer';
import RenderOnCondition from '../RenderOnCondition';
import DownloadStep from './DownloadStep';
import DeviceInfo from './DeviceInfo';
import KeyProtectionStep from './KeyProtectionStep';
import AuthenticationStep from './AuthenticationStep';
import { fetchDeviceKeyProtection } from '../../state/device-key/fetchDeviceKeyProtectionActionCreators';

function DeviceKeyDownloadBody(props: { downloadHash: string }) {
  const dispatch = useDispatch();
  const { downloadHash } = props;
  const protectionDetails = useSelector(
    (state: AppState) => state.deviceKeyProtection.protectionDetails.protectionDetails,
  );

  const downloadHashFound = useSelector(
    (state: AppState) => !state.deviceKeyProtection.downloadHashNotFound.downloadHash,
  );

  useEffect(() => {
    if (downloadHash) {
      dispatch(fetchDeviceKeyProtection(downloadHash));
    }
  }, [downloadHash]);

  return (
    <>
      <RenderOnCondition condition={ !downloadHashFound }>
        <div className="download-hash-not-found">
          <Translate
            id="download-hash-not-found"
            data={ { downloadHash } }
          />
        </div>
      </RenderOnCondition>

      <RenderOnCondition condition={ downloadHashFound }>
        <div className="device-key-download col">
          <DeviceInfo protectionDetails={ protectionDetails }/>
          <br/>
          <br/>
          <KeyProtectionStep downloadHash={ downloadHash }/>
          <AuthenticationStep downloadHash={ downloadHash }/>
          <DownloadStep downloadHash={ downloadHash }/>
        </div>
      </RenderOnCondition>
    </>
  );
}

export default connect()(DeviceKeyDownloadBody);
