import React from 'react';
import { Translate } from 'react-localize-redux'
import { InvalidArrayRecord } from "@wiot/shared-domain/models/utils/invalid-array-record";

interface ValidationMessageProps {
  validationResult: InvalidArrayRecord[];
  translationKey: string;
}

const ValidationMessage = ({ validationResult, translationKey }: ValidationMessageProps) => {
  if (validationResult?.length === 0) {
    return null;
  }

  return (
    <div className="mb-3 form__section">
      <div className="form__title">
        <Translate id={translationKey} />
      </div>
      {validationResult.map((res, index) => (
        <div className="form__label" key={index}>
          <Translate id='row' /> {res.index}: <Translate id={res.error} />
        </div>
      ))}
    </div>
  );
};

export default ValidationMessage;
