import { MINIMUM_NUMBER_OF_CHARACTERS_TO_START_SEARCH } from '../FilterBar/select/constants/minimum-number-of-characters-to-start-search';
import { DeviceManagerFilter } from '../../state/device/device-manager-filter';

export const getDeviceManagersFilterString = (filter?: DeviceManagerFilter): string => {
  const {
    name,
    deviceGroups,
    manufacturers,
    status,
    deviceTypes,
    gateways,
    ...remaining
  } = filter || {};

  const updatedFilter = { ...remaining }
  if (name && name.length >= MINIMUM_NUMBER_OF_CHARACTERS_TO_START_SEARCH) {
    updatedFilter['name'] = name;
  }

  if (deviceGroups) {
    updatedFilter['deviceGroups'] = deviceGroups.map(deviceGroup=>deviceGroup.id!);
  }

  const deviceTypeIds = deviceTypes?.map((option) => option.value);
  if (deviceTypeIds && deviceTypeIds.length > 0) {
    updatedFilter['deviceType'] = deviceTypeIds;
  }

  const statusIds = status?.map((option) => option.value);
  if (statusIds && statusIds.length > 0) {
    updatedFilter['status'] = statusIds;
  }

  const manufacturerIds = manufacturers?.map((option) => option.value);
  if (manufacturerIds && manufacturerIds.length > 0) {
    updatedFilter['manufacturer.flagId'] = manufacturerIds;
  }

  return JSON.stringify(updatedFilter);
};
