import './PropertyViewChildGroups.less';

import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { useHistory } from 'react-router';

import ResponsiveContainer from '../../components/shared/ResponsiveContainer';
import { generatePropertyViewPath } from './PropertyViewPathUtils';
import { Property } from '@wiot/shared-domain/domain/property/property';
import PROPERTY_VIEW_ICON from '../../assets/side-nav/property-view.svg';
import DEVICE_GROUP_VIEW_ICON from '../../assets/side-nav/device-groups.svg';
import RenderOnCondition from '../../components/RenderOnCondition';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import useBreakpoint from '../../hooks/useBreakpoint';
import { useDispatch } from 'react-redux';
import { PROPERTY_VIEW_PAGE_KEY } from '@wiot/shared-domain/domain/property/property-view-page-key';
import { setFilter } from '../../state/filter/set-filter-action-creator';

type PropertyViewChildGroupsProps = {
  property: Property;
};

export const PropertyViewChildGroups = ({ property }: PropertyViewChildGroupsProps) => {
  const breakpoint = useBreakpoint();
  const history = useHistory();
  const dispatch = useDispatch();

  if (!property.children?.length) {
    return null;
  }

  function onChildClick(event: React.MouseEvent<HTMLDivElement>, deviceGroup: DeviceGroup, childGroupPath: string): void {
    if (!deviceGroup.id || !deviceGroup.propertySettings?.isProperty) {
      return;
    }

    history.push(childGroupPath);

    dispatch(
      setFilter({
        page: PROPERTY_VIEW_PAGE_KEY,
        values: {
          deviceGroup: [[deviceGroup]],
        },
      }),
    );
  }


  return (
    <ResponsiveContainer
      title="units"
      className="propertyview-childgroups"
      maxContentHeigth={ 268 }
      maxWidths={ { sm: 12, md: 12, lg: 12, xl: breakpoint === 'xxl' ? 4 : 12 } }
    >
      <>
        { property.children.map((childGroup, index) => {
          const childIsProperty = childGroup.propertySettings?.isProperty;
          const childGroupPath = generatePropertyViewPath({ deviceGroupId: childGroup.id });

          return (
            <div
              key={ `childgroup-${ index }` }
              className={ `childgroups-row ${ childIsProperty ? 'is-property' : '' }` }
              onClick={ (event) => onChildClick(event, childGroup, childGroupPath) }
            >
              <img src={ childIsProperty ? PROPERTY_VIEW_ICON : DEVICE_GROUP_VIEW_ICON } className="childview-icon"/>
              <div className="text-parts">
                <div className="childgroups-row-name" title={ childGroup.name }>{ childGroup.name }</div>
                <RenderOnCondition condition={ childIsProperty }>
                  <div className="childgroups-row-propertylink text-primary">
                    <Translate id="select-this-property"/>
                  </div>
                  <FontAwesomeIcon icon={ faChevronRight } className="childgroups-row-propertylink-chevron" size="1x"/>
                </RenderOnCondition>
              </div>
            </div>
          );
        }) }
      </>
    </ResponsiveContainer>
  );
};
