import React from 'react';
import { faUser, faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IUser } from '@wiot/shared-domain/models/user/user';

interface HeaderProfileToggleProps {
  toggleUserSettings: () => void;
  currentUser: IUser;
  showUserSettings: boolean;
}

const HeaderProfileToggle = (props: HeaderProfileToggleProps) => {
  const { toggleUserSettings, currentUser, showUserSettings } = props;

  return (
    <div className="header__settings" onClick={toggleUserSettings} data-testid="header-settings">
      <FontAwesomeIcon icon={faUser} size="sm" className="header__user--user" />
      <h6 className="header__settings__name">{currentUser.displayName}</h6>
      <span className="header__settings__caret" role="presentation">
        {showUserSettings ? (
          <FontAwesomeIcon icon={faCaretDown} size="sm" className="header__user--user" />
        ) : (
          <FontAwesomeIcon icon={faCaretRight} size="sm" className="header__user--user" />
        )}
      </span>
    </div>
  );
};

export default HeaderProfileToggle;
