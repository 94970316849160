import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AggregatedPermission } from '@wiot/shared-domain/models/role/role';
import { DataIntegrationJob } from '@wiot/shared-domain/models/data-integration/data-integration';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import FilterBar from '../../components/Filter/FilterBar';
import Table from '../../components/Table/Table';
import { DispatchActionTypes, IPagination } from '../../state/types';
import { getFetchOptions, hasPermission } from '../../utils/common';
import { fetchDataIntegrationJobsFromDB, removeDataIntegrationJobFromDB, } from '../../api/apiHelpers';
import TableData from './Table/TableDataDataIntegration';
import { savePagination as savePaginationAction } from '../../state/actions/savePaginationAction';
import { isTableLoading } from '../../state/table/isTableLoadingAction';
import { isLoading } from '../../state/actions/isLoadingAction';
import Mobile from '../../components/Responsive/Mobile';
import {
  saveDataIntegrationJobs as saveDataIntegrationJobsAction,
  toggleSelectAllDataIntegrationJobs as toggleSelectAllDataIntegrationJobsAction,
  toggleSelectDataIntegrationJob as toggleSelectDataIntegrationJobAction,
} from '../../state/actions/saveDataIntegrationJobsAction';
import { AppState } from '../../state/reducers/rootReducer';
import IntegrationModal from './Modal/IntegrationActionModal';
import { IDataIntegrationFilter } from '../../state/reducers/filterSortReducer';
import MainGrid from '../../components/shared/MainGrid';
import { showNoResultsInfo } from '../../state/device/fetching-limit/fetchNoResultInfoAction';

export interface DataIntegrationProps {
  dataIntegrationJobs: DataIntegrationJob[];
  savePagination: (paginationData: IPagination) => void;
  toggleSelectDataIntegrationJob: (id: string) => void;
  toggleSelectAllDataIntegrationJobs: (isChecked: boolean) => void;
  saveDataIntegrationJobs: (dataIntegrationJobs: DataIntegrationJob[]) => void;
  currentEntriesPerPage: number;
  currentPage: number;
  setIsTableLoading: (loading: boolean) => void;
  setIsLoading: (loading: boolean) => void;
  filter: IDataIntegrationFilter;
  permission?: AggregatedPermission;
  showNoResultsInfo: () => void;
}

const DataIntegration = (props: DataIntegrationProps) => {
  const [showDataIntegrationModal, setShowDataIntegrationModal] = React.useState(false);
  const {
    permission,
    filter,
    currentEntriesPerPage,
    currentPage,
    saveDataIntegrationJobs,
    setIsTableLoading,
    savePagination,
    setIsLoading,
  } = props;

  const fetchJobs = useCallback(
    async (column?: IColumnObject) => {
      const fetchOptions = getFetchOptions(currentEntriesPerPage, column, filter, currentPage);

      setIsTableLoading(true);
      showNoResultsInfo();
      try {
        const res = await fetchDataIntegrationJobsFromDB(fetchOptions);

        if (res && res.dataIntegrationJobs) {
          const { dataIntegrationJobs: dataIntegrationJobsRes, totalDocs, totalPages } = res;
          await saveDataIntegrationJobs(dataIntegrationJobsRes);

          const paginationData: IPagination = { totalDocs, totalPages };
          await savePagination(paginationData);
        }
      } finally {
        setIsTableLoading(false);
        setIsLoading(false);
      }
    },
    [
      currentEntriesPerPage,
      currentPage,
      filter,
      saveDataIntegrationJobs,
      savePagination,
      setIsLoading,
      setIsTableLoading,
    ],
  );

  useEffect(() => {
    setIsLoading(true);
    fetchJobs();
  }, [fetchJobs, setIsLoading]);

  const removeUnit = async (id: string, refresh = true) => {
    setIsTableLoading(true);
    const response = await removeDataIntegrationJobFromDB(id);

    if (refresh && response) {
      await fetchJobs();
    }
  };

  const getTableComponent = () => <TableData refreshData={fetchJobs} removeUnit={removeUnit} />;

  return (
    <MainGrid dataTestId="page-keys">
      <FilterBar page="data-integration" />
      <Table
        page="data-integration"
        changeView={false}
        addModal={hasPermission(permission, 'dataIntegration.add')}
        addText="data-integration"
        refreshTableData={fetchJobs}
        tableComponent={getTableComponent()}
        elementType="data-integration"
        editorModalCreator={(toggleShowEditorModal) => (
          <IntegrationModal
            closeModal={toggleShowEditorModal}
            title="data-integration"
            showDeleteButton={false}
            addUnit
            refreshData={fetchJobs}
          />
        )}
        showPagination
      />
      <Mobile>
        <>
          {getTableComponent()}
          {showDataIntegrationModal && (
            <IntegrationModal
              closeModal={() => setShowDataIntegrationModal(false)}
              title="data-integration"
              showDeleteButton={false}
              addUnit
              refreshData={fetchJobs}
            />
          )}
        </>
      </Mobile>
    </MainGrid>
  );
};

const mapStateToProps = (state: AppState) => ({
  dataIntegrationJobs: state.dataIntegrationJobs,
  currentEntriesPerPage: state.currentEntriesPerPage,
  currentPage: state.pagination['data-integration'].currentPage,
  isLoading: state.isLoading,
  filter: state.filters.filter['data-integration'],
  permission: state.currentUser.permission,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  toggleSelectDataIntegrationJob: (dataIntegrationJobId: string) =>
    dispatch(toggleSelectDataIntegrationJobAction(dataIntegrationJobId)),
  toggleSelectAllDataIntegrationJobs: (isChecked: boolean) =>
    dispatch(toggleSelectAllDataIntegrationJobsAction(isChecked)),
  saveDataIntegrationJobs: (dataIntegrationJobs: DataIntegrationJob[]) =>
    dispatch(saveDataIntegrationJobsAction(dataIntegrationJobs)),
  savePagination: (paginationData: IPagination) =>
    dispatch(savePaginationAction(paginationData, 'data-integration')),
  setIsTableLoading: (loading: boolean) => dispatch(isTableLoading(loading)),
  setIsLoading: (loading: boolean) => dispatch(isLoading(loading)),
  showNoResultsInfo: () => dispatch(showNoResultsInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataIntegration);
