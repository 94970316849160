import { faCog } from '@fortawesome/free-solid-svg-icons';

import { DeviceGroupType } from '@wiot/shared-domain/models/device-group/device-group';
import COUNTRY from './assets/device-groups/country.svg';
import COUNTRY_BORDER from './assets/device-groups/country-border_bg.svg';

import COMPANY from './assets/device-groups/company.svg';
import COMPANY_BORDER from './assets/device-groups/company-border_bg.svg';

import OBJECT from './assets/device-groups/object.svg';
import OBJECT_BORDER from './assets/device-groups/object-border_bg.svg';

import PROPERTY from './assets/device-groups/property.svg';
import PROPERTY_BORDER from './assets/device-groups/property-border_bg.svg';

import BUILDING from './assets/device-groups/building.svg';
import BUILDING_BORDER from './assets/device-groups/building-border_bg.svg';

import TOWN from './assets/device-groups/city.svg';
import TOWN_BORDER from './assets/device-groups/city-border_bg.svg';

import DISTRICT from './assets/device-groups/district.svg';
import DISTRICT_BORDER from './assets/device-groups/district-border_bg.svg';

import FLAT from './assets/device-groups/flat.svg';
import FLAT_BORDER from './assets/device-groups/flat-border_bg.svg';

import FLOOR from './assets/device-groups/floor.svg';
import FLOOR_BORDER from './assets/device-groups/floor-border_bg.svg';

import ROOM from './assets/device-groups/room.svg';
import ROOM_BORDER from './assets/device-groups/room-border_bg.svg';

import STREET from './assets/device-groups/street.svg';
import STREET_BORDER from './assets/device-groups/street-border_bg.svg';

import BIDIRECTION_REPEATER_SMALL from './assets/devices/bi-direction-repeater-small.svg';
import UNIDIRECTION_REPEATER_SMALL from './assets/devices/uni-direction-repeater-small.svg';
import LORA_GATEWAY_SMALL from './assets/devices/gateway-lora-small.svg';
import OMS_GATEWAY_SMALL from './assets/devices/gateway-oms-small.svg';

import QUESTION_MARK_SMALL from './assets/devices/question-mark-small.svg';

import USER_SMALL from './assets/source-types/user_small.svg';
import SYSTEM_SMALL from './assets/source-types/system_small.svg';

// Key Manager specific icons
import KEY_ASSIGNED from './assets/key-manager/key-assigned-bigger.svg';
import KEY_UNASSIGNED from './assets/key-manager/key-unassigned-bigger.svg';
import MONTH from './assets/key-manager/calendar.svg';
import YEAR from './assets/key-manager/year.svg';
import CHECKLIST from './assets/key-manager/checklist.svg';
import ORDER_POSITION from './assets/key-manager/order-position.svg';
import SINGLE_UNIT from './assets/key-manager/single-unit.svg';
import PACKAGING_UNIT from './assets/key-manager/packaging-unit.svg';
import USER from './assets/key-manager/user.svg';

import { ReactComponent as DeviceManagerIcon } from './assets/side-nav/group-admin.svg';
import { ReactComponent as DeviceGroupsIcon } from './assets/side-nav/device-groups.svg';
import { ReactComponent as UserAccountsIcon } from './assets/side-nav/user-accounts-admin.svg';
import { ReactComponent as RoleAdminIcon } from './assets/side-nav/role-admin.svg';
import { ReactComponent as KeyAdminIcon } from './assets/side-nav/key-admin.svg';
import { ReactComponent as ProfileAdminIcon } from './assets/side-nav/profile-admin.svg';
import { ReactComponent as DataIntegrationIcon } from './assets/side-nav/data-integration.svg';

export const pageIcons: any = {
  deviceManager: DeviceManagerIcon,
  deviceGroups: DeviceGroupsIcon,
  keys: KeyAdminIcon,
  users: UserAccountsIcon,
  roles: RoleAdminIcon,
  dataIntegration: DataIntegrationIcon,
  profile: ProfileAdminIcon,
  settings: faCog,
};

export const sourceTypeIconSmall: any = {
  BIDIRECTION_REPEATER: BIDIRECTION_REPEATER_SMALL,
  UNIDIRECTION_REPEATER: UNIDIRECTION_REPEATER_SMALL,
  LORA_GATEWAY: LORA_GATEWAY_SMALL,
  OMS_GATEWAY: OMS_GATEWAY_SMALL,
  SYSTEM: SYSTEM_SMALL,
  MANUAL: USER_SMALL,
  2: QUESTION_MARK_SMALL, // TODO: update when all device types are available in backend
};

export const deviceGroupIcons: Record<DeviceGroupType, string> = {
  BUILDING,
  TOWN,
  COUNTRY,
  DISTRICT,
  COMPANY,
  OBJECT,
  PROPERTY,
  FLAT,
  FLOOR,
  ROOM,
  STREET,
};

export const deviceGroupIconsBorder: any = {
  BUILDING: BUILDING_BORDER,
  TOWN: TOWN_BORDER,
  COUNTRY: COUNTRY_BORDER,
  DISTRICT: DISTRICT_BORDER,
  COMPANY: COMPANY_BORDER,
  OBJECT: OBJECT_BORDER,
  PROPERTY: PROPERTY_BORDER,
  FLAT: FLAT_BORDER,
  FLOOR: FLOOR_BORDER,
  ROOM: ROOM_BORDER,
  STREET: STREET_BORDER,
};

export const keyManagerDeviceGroupIcons: any = {
  BUILDING: KEY_ASSIGNED,
  STREET: KEY_UNASSIGNED,
  TOWN: CHECKLIST,
  COUNTRY: YEAR,
  OBJECT: MONTH,
  PROPERTY: ORDER_POSITION,
  DISTRICT: PACKAGING_UNIT,
  ROOM: SINGLE_UNIT,
  FLAT: USER,
};

export const DEVICE_MESSAGES_TABLE_HEADERS = [
  'device-type-icon',
  'device-id',
  'device-name',
  'device-group',
  'source',
  'last-message',
  'value',
];

export const MANAGE_DEVICE_GROUP_TYPES_TABLE_HEADERS = [
  'device-group-type-icon',
  'device-group-type-name',
  'is-system-type',
  'actions',
];

export const MANAGE_DEVICE_TYPES_TABLE_HEADERS = [
  'device-type-icon',
  'device-type-name',
  'is-system-type',
  'actions',
];

export const DEVICE_GROUPS_TABLE_HEADERS = [
  'device-group-name',
  'abbreviation',
  'device-group-type',
  'external-id',
  'device-group-membership',
  'actions',
];


// FIXME: Remove non-ASCII characters from identifiers.
export const countries = {
  deutschland: 'DE',
  österreich: 'AT',
  schweiz: 'CH',
};

export const momentUnits = [
  {
    name: 'All',
    value: 'all',
    frequency: 'all',
    period: 'always',
  },
  {
    name: 'Years',
    value: 'y',
    frequency: 'yearly',
    period: 'year-s',
  },
  {
    name: 'Months',
    value: 'M',
    frequency: 'monthly',
    period: 'month-s',
  },
  {
    name: 'Weeks',
    value: 'w',
    frequency: 'weekly',
    period: 'week-s',
  },
  {
    name: 'Days',
    value: 'd',
    frequency: 'daily',
    period: 'day-s',
  },
  {
    name: 'Hours',
    value: 'h',
    frequency: 'hourly',
    period: 'hour-s',
  },
];

export enum IconVariant {
  default = '',
  small = '-small',
  large = '-large',
  largeWithBorderBackground = '-border_bg',
  largeWithBorderBackgroundGreen = '-border_bg_green',
  largeWithBorderBackgroundRed = '-border_bg_red',
  largeWithBorderBackgroundYellow = '-border_bg_yellow',
}

export const MAP_PROVIDERS = [
  {
    name: 'Open street map',
    value: 'open-street-map',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    fields: [],
  },
  {
    name: 'HERE',
    value: 'here-map',
    url:
      'https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/style/{z}/{x}/{y}/512/png8?apiKey=apiKeyValue&ppi=320',
    attribution: '&copy; HERE 2019',
    fields: [
      {
        name: 'apiKey',
        type: 'text',
      },
      {
        name: 'style',
        type: 'text',
      },
    ],
  },
];

// E. Wehrle GmbH, Furtwangen im Schwarzwald
export const DEFAULT_MAP_CENTER = {
  lat: 48.04740817001512,
  lng: 8.215917348861696,
};

export const DEFAULT_MAP_ZOOM = 13;
