import { LatestDeviceReadingPerDevice } from '@wiot/shared-domain/models/device-reading/latest-device-reading-per-device';

export const FETCH_DEVICE_READINGS = 'FETCH_DEVICE_READINGS';
export const DEVICE_READINGS_FETCHED = 'DEVICE_READINGS_FETCHED';
export const DEVICE_READINGS_FETCH_FAILED = 'DEVICE_READINGS_FETCH_FAILED';

interface FetchDeviceReadingsAction {
  type: typeof FETCH_DEVICE_READINGS;
  idsOfDevices: string[];
}

export interface DeviceReadingsFetchedAction {
  type: typeof DEVICE_READINGS_FETCHED;
  latestDeviceReadingOfDevices: LatestDeviceReadingPerDevice[];
}

interface DeviceReadingsFetchFailedAction {
  type: typeof DEVICE_READINGS_FETCH_FAILED;
  idsOfDevices: string[];
}

export type FetchDeviceReadingsActionTypes = FetchDeviceReadingsAction | DeviceReadingsFetchedAction | DeviceReadingsFetchFailedAction;

export const fetchDeviceReadingsAction = (idsOfDevices: string[]): FetchDeviceReadingsAction => ({
  type: FETCH_DEVICE_READINGS,
  idsOfDevices: idsOfDevices,
});

export const deviceReadingsFetched =
  (latestDeviceReadingPerDevices: LatestDeviceReadingPerDevice[])
    : DeviceReadingsFetchedAction => ({
    type: DEVICE_READINGS_FETCHED,
    latestDeviceReadingOfDevices: latestDeviceReadingPerDevices,
  });

export const deviceReadingsFetchFailed = (idsOfDevices: string[]): DeviceReadingsFetchFailedAction => ({
  type: DEVICE_READINGS_FETCH_FAILED,
  idsOfDevices: idsOfDevices,
});