import { TreeNode } from '@wiot/shared-domain/domain/device-tree/tree-node';

export const FETCH_SUB_TREE = 'FETCH_SUB_TREE';
export interface FetchSubTreeAction {
  type: typeof FETCH_SUB_TREE;
  idOfSubTreeRoot?: string;
}
export const fetchSubTree = (idOfSubTreeRoot?:string): FetchSubTreeAction => ({
  type: FETCH_SUB_TREE,
  idOfSubTreeRoot: idOfSubTreeRoot,
});

export const SUB_TREE_FETCHED = 'SUB_TREE_FETCHED'
export interface SubTreeFetchedAction {
  type: typeof SUB_TREE_FETCHED;
  subTreeRoot: TreeNode;
}

export const subTreeFetched = (subTreeRoot: TreeNode): SubTreeFetchedAction => ({
  type: SUB_TREE_FETCHED,
  subTreeRoot: subTreeRoot,
});


