import { buildQueryString } from '../utils/common';
import { authenticatedInstance, handleApiError } from './apiHelpers';
import { MeasurementAttributeId } from '@wiot/shared-domain/models/device-type-profile/device-type-profile';
import { MeasurementFrequency } from '@wiot/shared-domain/models/device-reading/measurement';
import moment from 'moment/moment';
import { ExportDeviceMeasurementsApiRequest } from '@wiot/shared-domain/models/device/export-device-measurements-request';
import { getDeviceManagersFilterString } from '../components/Filter/get-device-managers-filter-string';
import { getGatewayFilterString } from '../components/Filter/get-gateway-filter-string';
import { DeviceManagerFilter } from '../state/device/device-manager-filter';

/**
 *  The exportService.ts should be removed and the code moved to the action creator.
 */
export const fetchDeviceExport = async (
  endDate: string,
  exportFormat: string,
  filter: DeviceManagerFilter,
  startDate?: string | null,
  attributeIds?: MeasurementAttributeId[],
  measurementFrequency?: MeasurementFrequency,
  includeLastPrimaryMeasurement?: boolean,
  includeDueDateMeasurement?: boolean,
  includeLastGateways?: boolean,
) => {
  try {
    const requestParameters: ExportDeviceMeasurementsApiRequest = {
      startDate: startDate && `"${ moment(startDate).format('YYYY-MM-DD') }"`,
      endDate: `"${ moment(endDate).format('YYYY-MM-DD') }"`,
      filter: getDeviceManagersFilterString(filter),
      gateways: getGatewayFilterString(filter),
      exportFormat: `"${ exportFormat }"`,
      attributeIds: attributeIds?.join(','),
      measurementFrequency,
      includeLastPrimaryMeasurement,
      includeDueDateMeasurement,
      includeLastGateways,
    };

    const qs = buildQueryString(requestParameters);
    const url = `/export/device/measurements?${ qs }`;
    const { data } = await authenticatedInstance.get(url);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};
