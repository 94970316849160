import * as React from 'react';
import { IUser } from '@wiot/shared-domain/models/user/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { Translate, LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { toastr } from 'react-redux-toastr';
import { resendRegistrationEmail } from '../../../api/apiHelpers';
import RenderOnCondition from '../../../components/RenderOnCondition';

interface UserResendEmailMenuItemProps extends LocalizeContextProps {
  user: IUser;
  closeMenu: (event: React.MouseEvent) => void;
}

const UserResendEmailMenuItem = ({ user, closeMenu, translate }: UserResendEmailMenuItemProps) => {
  const resendEmail = async (event: React.MouseEvent) => {
    const userEmail = user?.email;
    const res = userEmail && (await resendRegistrationEmail(userEmail));

    if (res === 'OK') {
      toastr.success(
        translate('success').toString(),
        translate('resent-registration-email-success').toString(),
      );
    } else {
      toastr.error(
        translate('ERROR').toString(),
        translate('resent-registration-email-failure').toString(),
      );
    }
    closeMenu(event);
  };

  return (
    <>
      <RenderOnCondition condition={user?.email}>
        <button className="td-menu__item" onClick={(e) => resendEmail(e)}>
          <div className="td-menu__item__icon">
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
          <Translate id="resend-email" />
        </button>
      </RenderOnCondition>
    </>
  );
};

export default withLocalize(UserResendEmailMenuItem);
