import { IUser } from '@wiot/shared-domain/models/user/user';
import { AggregatedPermission } from '@wiot/shared-domain/models/role/role';
import { SetCurrentUserAction, UserPermissionActionTypes } from '../types';
import { SET_CURRENT_USER, SET_USER_PERMISSION } from '../constants/actionTypes';

export const setCurrentUser = (userDetails: IUser): SetCurrentUserAction => ({
  type: SET_CURRENT_USER,
  payload: userDetails,
});

export const setUserPermission = (userPermission: AggregatedPermission): UserPermissionActionTypes => ({
  type: SET_USER_PERMISSION,
  payload: userPermission,
});
