import { PropertySettings } from '@wiot/shared-domain/models/settings/property-settings';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';

export const OPEN_PROPERTY_SETTINGS_MODAL = 'OPEN_PROPERTY_SETTINGS_MODAL';
export interface OpenPropertySettingsModalAction {
  type: typeof OPEN_PROPERTY_SETTINGS_MODAL;
  deviceGroup: DeviceGroup;
}

export const CLOSE_PROPERTY_SETTINGS_MODAL = 'CLOSE_PROPERTY_SETTINGS_MODAL';
export interface ClosePropertySettingsModalAction {
  type: typeof CLOSE_PROPERTY_SETTINGS_MODAL;
}

export const UPDATE_PROPERTY_SETTINGS = 'UPDATE_PROPERTY_SETTINGS';
export interface UpdatePropertySettingsAction {
  type: typeof UPDATE_PROPERTY_SETTINGS;
  propertyId: string;
  propertySettings: PropertySettings;
}

export const PROPERTY_SETTINGS_UPDATED = 'PROPERTY_SETTINGS_UPDATED';
export interface PropertySettingsUpdatedAction {
  type: typeof PROPERTY_SETTINGS_UPDATED;
  deviceGroup: DeviceGroup;
}

export type PropertySettingsModalActionTypes =
  | OpenPropertySettingsModalAction
  | ClosePropertySettingsModalAction
  | UpdatePropertySettingsAction
  | PropertySettingsUpdatedAction;
