import * as React from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AppState } from '../../state/reducers/rootReducer';
import { NoResultsInfoState } from '../../state/device/fetching-limit/fetchNoResultInfoAction';

interface NoResultsInfoProps {
  positionAbsolute?: boolean;
}

const NoResultsInfo = (props: NoResultsInfoProps): JSX.Element => {
  const { positionAbsolute } = props;

  const showNoResultsInfo = useSelector((state: AppState) => state.showNoResultsInfo,);
  const exceedsDisplayLimit = (showNoResultsInfo === NoResultsInfoState.tooMuchToShow);

  return (
    <div
      className={ `no-result__no-data ${ positionAbsolute ? 'position-absolute' : '' }` }
    >
      <h2>
        <FontAwesomeIcon icon={faInfoCircle} color={ exceedsDisplayLimit ? 'blue' : 'gray' }/>
      </h2>
      <hr className={'no-result__horizontal-line'} />
      <div className={'no-result__text'}>
        <Translate id={exceedsDisplayLimit ? 'too-much-data' : 'no-data'} />
      </div>
      <div className={'no-result__text'}>
        <Translate id="adjust-filters" />
      </div>
    </div>
  );
};

export default NoResultsInfo;
