import React, { useEffect } from 'react';
import { Translate } from 'react-localize-redux';
import { faEdit, faInfoCircle, faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import HasPermission from '../../../components/HasPermission';
import { AppState } from '../../../state/reducers/rootReducer';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import RenderOnCondition from '../../../components/RenderOnCondition';
import GoToDeviceKeyDownloadMenuItem from '../../DeviceManager/Table/GoToDeviceKeyDownloadMenuItem';
import {
  closeContextMenu,
  openContextMenu,
} from '../../../state/context-menu/toggleContextMenuActionCreators';
import { OutsideClickHandler } from '../../../components/OutsideClickHandler';

export interface TableMenuProps {
  removeUnit: (id: string) => void;
  menuId: string;
  deviceGroupData?: any;
  refreshTreeData?: () => void;
  toggleDetails: (event: React.MouseEvent) => void;
  toggleDeviceGroupActionModal: (event: React.MouseEvent) => void;
  toggleDeleteModal: (event: React.MouseEvent) => void;
  toggleDeviceGroupActionModalAddGroupInGroup: (event: React.MouseEvent) => void;
  toggleDeviceActionModalAddDeviceInGroup: (event: React.MouseEvent) => void;
  toggleUserActionModalAddUserInGroup: (event: React.MouseEvent) => void;
  isMobileDisplay?: boolean;
  downloadHash?: string;
}

interface ExtendedTableMenuProps extends TableMenuProps, RouteComponentProps { }

const TableMenu = (props: ExtendedTableMenuProps) => {
  const {
    isMobileDisplay,
    menuId,
    toggleDetails,
    toggleDeviceGroupActionModal,
    toggleDeviceGroupActionModalAddGroupInGroup,
    toggleDeviceActionModalAddDeviceInGroup,
    toggleUserActionModalAddUserInGroup,
    toggleDeleteModal,
    downloadHash,
  } = props;

  const permission = useSelector((state: AppState) => state.currentUser.permission);
  const isKeyManagerModeEnabled = useSelector((state: AppState) => !!state.siteSettings.isKeyManagerModeEnabled);

  const dispatch = useDispatch();

  const closeMenu = (event?: React.MouseEvent) => {
    event && event.preventDefault();
    dispatch(closeContextMenu());
  }

  const tdRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    dispatch(openContextMenu(menuId));
  }, [])

  useEffect(() => {
    const targetElement = tdRef.current;
    isMobileDisplay && disableBodyScroll(targetElement);

    return () => {
      isMobileDisplay && enableBodyScroll(targetElement);
    };
  }, [isMobileDisplay, tdRef]);

  const handleMenuButtonClick = (
    event: React.MouseEvent,
    action: (event: React.MouseEvent) => void,
  ) => {
    closeMenu(event);
    action(event);
  };

  return (
    <OutsideClickHandler handleClickOutside={() => closeMenu()}>

    <div
      className="td-menu"
      ref={ tdRef }
    >
      <button
        className="td-menu__item"
        onClick={ (e) => handleMenuButtonClick(e, toggleDetails) }
        data-testid="devices-row-name"
      >
        <div className="td-menu__item__icon">
          <FontAwesomeIcon icon={ faInfoCircle } />
        </div>
        <Translate id="details" />
      </button>

      <HasPermission permissionObj={ permission } permissionKey="deviceGroups.edit">
        <>
          <button
            className="td-menu__item"
            onClick={ (e) => handleMenuButtonClick(e, toggleDeviceGroupActionModal) }
            data-testid="update-device"
          >
            <div className="td-menu__item__icon">
              <FontAwesomeIcon icon={ faEdit } />
            </div>
            <Translate id="update" />
          </button>
        </>
      </HasPermission>

      <HasPermission permissionObj={ permission } permissionKey="deviceGroups.remove">
        <>
          <button
            className="td-menu__item"
            onClick={(e) => handleMenuButtonClick(e, toggleDeleteModal)}
          >
            <div className="td-menu__item__icon">
              <FontAwesomeIcon icon={ faTrashAlt } />
            </div>
            <Translate id="remove" />
          </button>
        </>
      </HasPermission>

      <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
        <HasPermission permissionObj={ permission } permissionKey="deviceGroups.add">
          <>
            <button
              className="td-menu__item"
              onClick={ (e) => handleMenuButtonClick(e, toggleDeviceGroupActionModalAddGroupInGroup) }
              data-testid="add-group-same-group"
            >
              <div className="td-menu__item__icon">
                <FontAwesomeIcon icon={ faPlusCircle } />
              </div>
              <Translate id="add-group-same-group" />
            </button>
          </>
        </HasPermission>
      </RenderOnCondition>

      <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
        <button
          className="td-menu__item"
          onClick={ (e) => handleMenuButtonClick(e, toggleDeviceActionModalAddDeviceInGroup) }
          data-testid="add-dev-under-group"
        >
          <div className="td-menu__item__icon">
            <FontAwesomeIcon icon={ faPlusCircle } />
          </div>
          <Translate id="add-dev-under-group" />
        </button>
      </RenderOnCondition>

      <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
        <HasPermission permissionObj={ permission } permissionKey="users.add">
          <button
            className="td-menu__item"
            onClick={ (e) => handleMenuButtonClick(e, toggleUserActionModalAddUserInGroup) }
            data-testid="add-user-same-group"
          >
            <div className="td-menu__item__icon">
              <FontAwesomeIcon icon={ faPlusCircle } />
            </div>
            <Translate id="add-user-under-group" />
          </button>
        </HasPermission>
      </RenderOnCondition>

      <RenderOnCondition condition={ isKeyManagerModeEnabled && downloadHash }>
        <GoToDeviceKeyDownloadMenuItem downloadHash={ downloadHash } closeMenu={() => closeMenu()}/>
      </RenderOnCondition>
    </div>

    </OutsideClickHandler>
  );
};

export default withRouter(TableMenu);
