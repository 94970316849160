import React from 'react';
import { Translate } from 'react-localize-redux';
import { Col, Row } from 'react-bootstrap';
import { HorizontalDivider } from '../../../components/shared/Divider';
import { Formik } from 'formik';
import ConsumptionField from './ConsumptionField';
import { createFilledArray } from '../../../utils/array';
import * as Yup from 'yup';
import useTranslation from '../../../hooks/useTranslation';
import { PropertySettings } from '@wiot/shared-domain/models/settings/property-settings';
import RenderOnCondition from '../../../components/RenderOnCondition';

type PropertyViewSettingsProps = {
  tabKey: string;
  handleSubmit: (values: PropertySettings) => void;
  initialValues?: PropertySettings;
  readOnly?: boolean;
  displayInModal?: boolean;
};

const PropertyViewSettings = ({ handleSubmit, initialValues, readOnly, tabKey, displayInModal }: PropertyViewSettingsProps) => {
  const translate = useTranslation();

  const prepareInitialValues = () => {
    let newInitialValues = { ...initialValues };
    if (!newInitialValues.averageConsumptionsByMonths) {
      newInitialValues = { averageConsumptionsByMonths: { ENERGY: [], WATER: [] } };
    }

    let { ENERGY: energy, WATER: water } = newInitialValues.averageConsumptionsByMonths!;

    if (!energy?.length) {
      energy = createFilledArray(12, 0);
    }
    if (!water?.length) {
      water = createFilledArray(12, 0);
    }

    newInitialValues.averageConsumptionsByMonths!.ENERGY = energy;
    newInitialValues.averageConsumptionsByMonths!.WATER = water;

    return newInitialValues;
  }

  const formInitialValues = prepareInitialValues();

  return (
    <Formik
      enableReinitialize
      onSubmit={ handleSubmit }
      initialValues={ formInitialValues }
      validationSchema={
      Yup.object().shape({
        averageConsumptionsByMonths: Yup.object().shape({
          ENERGY: Yup.array().of(Yup.number().typeError(translate('validation-error-invalid-number').toString())).min(12).max(12).required(),
          WATER: Yup.array().of(Yup.number().typeError(translate('validation-error-invalid-number').toString())).min(12).max(12).required(),
        }),
      })}
    >
      {(formikProps) => (
        <form id={ `${ tabKey }-settings` } className="well" onSubmit={ formikProps.handleSubmit }>
          <div className={ displayInModal ? '' : 'settings-header' }>
            <Translate id="average-consumptions" />
          </div>
          <Row>
            <RenderOnCondition condition={!displayInModal}>
              <Col lg={ 3 }>
                <label className="settings-form__label">
                  <Translate id="energy" />
                  {' '}
                  <Translate id="in-kWh" />
                </label>
              </Col>
            </RenderOnCondition>
            <Col lg={ displayInModal ? 12 : 9 }>
              <Row>
                <Col className='standard-font-size mb-3'>
                  <Translate id='enter-property-view-monthly-average-energy-consumption-values' />
                </Col>
              </Row>
              <Row>
              {formikProps.values.averageConsumptionsByMonths?.ENERGY.map((value, index) => (
                <ConsumptionField
                  key={`energy-${index}`}
                  index={index}
                  name={`averageConsumptionsByMonths.ENERGY[${index}]`}
                  value={value}
                  formikProps={formikProps}
                  readOnly={readOnly}
                />
              ))}
              </Row>
            </Col>
          </Row>
          <HorizontalDivider />
          <Row>
            <RenderOnCondition condition={!displayInModal}>
              <Col lg={ 3 }>
                <label className="settings-form__label">
                  <Translate id="water" />
                  {' '}
                  <Translate id="in-qm" />
                </label>
              </Col>
            </RenderOnCondition>
            <Col lg={ displayInModal ? 12 : 9 }>
              <Row>
                <Col className='standard-font-size mb-3'>
                  <Translate id='enter-property-view-monthly-average-water-consumption-values' />
                </Col>
              </Row>
              <Row>
                {formikProps.values.averageConsumptionsByMonths?.WATER.map((value, index) => (
                  <ConsumptionField
                    key={`water-${index}`}
                    index={index}
                    name={`averageConsumptionsByMonths.WATER[${index}]`}
                    value={value}
                    formikProps={formikProps}
                    readOnly={readOnly}
                  />
                ))}
              </Row>
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default PropertyViewSettings;
