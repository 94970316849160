import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import ConfirmModal, { ConfirmationVariant } from '../../../components/Modal/ConfirmModal';
import { DEVICE_GROUPS_TABLE_HEADERS as TABLE_HEADERS } from '../../../constants';
import RenderOnCondition from '../../../components/RenderOnCondition';
import DeviceTypeDetailsModal from '../DeviceTypeDetailsModal';
import { DeviceTypeExtended } from '../../../state/types';
import TableMenu from '../Table/TableMenu';
import DeviceTypeActionModal from '../DeviceTypeActionModal';
import {
  getDeviceTypeIconFullPath,
  getTranslationValueInCurrentLanguage,
} from '../../../utils/common';
import { AppState } from '../../../state/reducers/rootReducer';
import { getMenuId } from '../../../components/PortalMenu/constants/context-menu-ids';
import { closeContextMenu, openContextMenu } from '../../../state/context-menu/toggleContextMenuActionCreators';
import { Tooltip } from '../../../components/shared/Tooltip';

interface IDataRow {
  deviceType: DeviceTypeExtended;
  deleteDeviceType: (deviceType: IDeviceType) => Promise<void>;
  refreshData: () => void;
}

const DataRow = (props: IDataRow) => {
  const { deleteDeviceType, deviceType, refreshData } = props;

  const [showDeviceGroupDetails, setShowDeviceGroupDetails] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeviceGroupActionModal, setShowDeviceGroupActionModal] = useState(false);

  const menuButtonRef = useRef(null);

  const visibleContextMenu = useSelector((state: AppState) => state.contextMenu.visibleContextMenuId);
  const dispatch = useDispatch();

  const menuId = getMenuId(deviceType.id);
  const showMenu = (visibleContextMenu === menuId);
  const closeMenu = (event?: React.MouseEvent) => {
    event && event.preventDefault();
    dispatch(closeContextMenu());
  }
  const toggleMenu = async (event?: React.MouseEvent) => {
    event && event.stopPropagation();
    if (!showMenu) {
      dispatch(openContextMenu(menuId));
    } else {
      dispatch(closeContextMenu());
    }
  }

  useEffect(() => {
    dispatch(openContextMenu(menuId));
  }, [])

  const toggleDeviceGroupDetails = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    event && event.preventDefault();
    setShowDeviceGroupDetails((prevState) => !prevState);
    closeMenu();
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prevState) => !prevState);
    closeMenu();
  };

  const toggleDeviceGroupActionModal = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDeviceGroupActionModal((prevState) => !prevState);
    closeMenu();
  };

  return (
    <div className="data-card-container">
      <Row className="data-card-container__header">
        <div className="data-card-container__header__title text-overflow-ellipsis">{deviceType.name}</div>
      </Row>
      <Row className="data-card-container__device-type">
        <img
          data-tip="device-type-icon"
          data-for={`deviceType-${deviceType.id}-tooltip-name`}
          src={getDeviceTypeIconFullPath(deviceType)}
          alt="device type"
        />
        <Tooltip
          id={`deviceType-${deviceType.id}-tooltip-name`}
          place="right"
          className="tooltip"
        >
          {deviceType.name ? getTranslationValueInCurrentLanguage(deviceType.name) : '-'}
        </Tooltip>
      </Row>
      <Row className="data-card-container__body">
        <Col xs={4} className="data-card-container__key">
          <Translate id={TABLE_HEADERS[0]} />
          {':'}
        </Col>
        <Col className="data-card-container__value">{deviceType.name || '-'}</Col>
      </Row>
      <Row className="data-card-container__footer justify-content-center">
        <Col xs={9} className="text-center">
          <span className="device-details-btn" onClick={toggleDeviceGroupDetails}>
            <Translate id="details" />
          </span>
          <RenderOnCondition condition={showDeviceGroupDetails}>
            <DeviceTypeDetailsModal
              closeModal={toggleDeviceGroupDetails}
              deviceTypeData={deviceType}
            />
          </RenderOnCondition>
        </Col>
        <Col xs={3} ref={menuButtonRef}>
          <div className="device-details">
            <div className="device-details-icon" role="presentation" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </div>
            <RenderOnCondition condition={showMenu}>
              <TableMenu
                isMobileDisplay
                deviceType={deviceType}
                menuId={deviceType.id}
                toggleDetails={toggleDeviceGroupDetails}
                toggleDeviceGroupActionModal={toggleDeviceGroupActionModal}
                toggleDeleteModal={toggleDeleteModal}
              />
            </RenderOnCondition>
            <RenderOnCondition condition={showDeviceGroupActionModal}>
              <DeviceTypeActionModal
                closeAddAndUpdateModal={toggleDeviceGroupActionModal}
                title="update-device-type"
                showDeleteButton
                removalRequested={() => deleteDeviceType(deviceType)}
                deviceType={deviceType}
                deviceTypeChanged={() => {
                  refreshData();
                  toggleMenu();
                }}
              />
            </RenderOnCondition>
            <RenderOnCondition condition={showDeleteModal}>
              <ConfirmModal
                modalCloseRequested={toggleDeleteModal}
                actionConfirmed={() => deleteDeviceType(deviceType)}
                translationIdOfElementType="device-type"
                confirmationVariant={ ConfirmationVariant.DELETE }
              />
            </RenderOnCondition>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DataRow;
