import { DeviceReading } from '@wiot/shared-domain/models/device-reading/device-reading';
import { SAVE_DEVICE_MESSAGES } from '../constants/actionTypes';
import { DeviceMessagesActionTypes, DeviceMessagesState } from '../types';

const initialState: DeviceMessagesState = [];

export const deviceMessagesReducer = (
  state: DeviceMessagesState = initialState,
  action: DeviceMessagesActionTypes,
): DeviceMessagesState => {
  switch (action.type) {
    case SAVE_DEVICE_MESSAGES:
      return action.payload.map((deviceMessage: DeviceReading) => deviceMessage);

    default:
      return state;
  }
};
