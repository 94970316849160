import React, { useEffect } from 'react';
import { Translate } from 'react-localize-redux';
import onClickOutside from 'react-onclickoutside';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { OutsideClickHandler } from '../../../components/OutsideClickHandler';
import { AppState } from '../../../state/reducers/rootReducer';
import HasPermission from '../../../components/HasPermission';
import { closeContextMenu, openContextMenu } from '../../../state/context-menu/toggleContextMenuActionCreators';

export interface TableMenuProps {
  removeUnit: (id: string) => void;
  closeMenu: () => void;
  menuId: string;
  refreshRoles?: () => void;
  toggleRoleActionModal: () => void;
  toggleDeleteModal: () => void;
}

const TableMenu = (props: TableMenuProps) => {
  const { menuId, toggleRoleActionModal, toggleDeleteModal } = props;

  const userPermission = useSelector<AppState>((state) => state.currentUser.permission);

  const tdRef = React.createRef<HTMLDivElement>();

  const dispatch = useDispatch();

  const closeMenu = (event?: React.MouseEvent) => {
    event && event.preventDefault();
    dispatch(closeContextMenu());
  }

  useEffect(() => {
    dispatch(openContextMenu(menuId));
  }, [])

  const handleClickOutside = () => {
    props.closeMenu();
  };

  const handleMenuButtonClick = (
    event: React.MouseEvent,
    action: (event: React.MouseEvent) => void,
  ) => {
    props.closeMenu();
    action(event);
  };

  return (
    <OutsideClickHandler onClickOutside={ handleClickOutside }>
      <div
        className="td-menu"
        ref={ tdRef }
      >

        <HasPermission permissionObj={ userPermission } permissionKey="roles.edit">
          <>
            <button
              className="td-menu__item"
              onClick={ (e) => handleMenuButtonClick(e, toggleRoleActionModal) }
            >
              <div className="td-menu__item__icon">
                <FontAwesomeIcon icon={ faEdit } />
              </div>
              <Translate id="update" />
            </button>
          </>
        </HasPermission>

        <HasPermission permissionObj={ userPermission } permissionKey="roles.remove">
          <>
            <button
              className="td-menu__item"
              onClick={(e) => handleMenuButtonClick(e, toggleDeleteModal)}
            >
              <div className="td-menu__item__icon">
                <FontAwesomeIcon icon={ faTrashAlt } />
              </div>
              <Translate id="remove" />
            </button>
          </>
        </HasPermission>
      </div>
    </OutsideClickHandler>
  );
};

export default onClickOutside(TableMenu);
