import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { Device, DeviceViews } from '@wiot/shared-domain/models/device/device';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import TableFooter from './TableFooter';
import TableHeader from './TableHeader';
import { ToggleViewProps } from './ToggleView';
import { PageKeys } from '../../state/reducers/filterSortReducer';
import RenderOnCondition from '../RenderOnCondition';
import { Tooltip } from '../shared/Tooltip';

interface TableProps extends ToggleViewProps {
  addModal: boolean;
  addText: string;
  disableControls?: boolean;
  disabledControlsTooltipTranslationTerm?: string;
  elementType: string;
  changeView: boolean;
  handleBulkRemove?: () => void;
  page: PageKeys;
  selectedRows?: Partial<Device>[] | DeviceGroup[] | IDeviceType[]; // TODO: Implement a generic type.
  tableComponent?: JSX.Element;
  visibleView?: DeviceViews;
  showPagination?: boolean;
  showExportButton?: boolean;
  refreshTableData: () => void;
  editorModalCreator?: (toggleShowEditorModal: () => void) => JSX.Element;
}

const Table = (props: TableProps) => (
  <div className="device-manager__table device-manager__table__container">
    <RenderOnCondition condition={ props.disableControls }>
      <Tooltip id="disabled-controls-tooltip">
        <Translate id={ props.disabledControlsTooltipTranslationTerm || 'disabled' }/>
      </Tooltip>
    </RenderOnCondition>
    <TableHeader
      page={ props.page }
      changeView={ props.changeView }
      visibleView={ props.visibleView }
      addModal={ props.addModal }
      addText={ props.addText }
      selectedRows={ props.selectedRows || [] }
      handleBulkRemove={ props.handleBulkRemove }
      elementType={ props.elementType }
      showExportButton={ props.showExportButton }
      viewOptions={ props.viewOptions }
      handleViewToggle={ props.handleViewToggle }
      selectedView={ props.selectedView }
      refreshTableData={ props.refreshTableData }
      editorModalCreator={ props.editorModalCreator }
      disableControls={ props.disableControls }
    />
    { props.tableComponent }
    { props.showPagination && (
      <TableFooter page={ props.page } disableControls={ props.disableControls }/>
    ) }
  </div>
);

export default Table;
