import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons';
import Badge from 'react-bootstrap/Badge';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import ConfirmModal, { ConfirmationVariant } from '../../../components/Modal/ConfirmModal';
import { UserExtended } from '../../../state/types';
import UserActionModal from '../UserActionModal/UserActionModal';
import RenderOnCondition from '../../../components/RenderOnCondition';
import TableMenu from '../Table/TableMenu';
import UserDetailsModal from '../UserDetailsModal';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { IRole } from '@wiot/shared-domain/models/role/role';
import { AppState } from '../../../state/reducers/rootReducer';
import { getMenuId } from '../../../components/PortalMenu/constants/context-menu-ids';
import { closeContextMenu, openContextMenu } from '../../../state/context-menu/toggleContextMenuActionCreators';
import { Tooltip } from '../../../components/shared/Tooltip';
import { EMAIL_HEADER, ROLE_HEADER, USER_NAME_HEADER, VERIFIED_HEADER } from '../user-table-headers';

interface IDataRow {
  user: UserExtended;
  removeUnit: (id: string) => Promise<void>;
  refreshData: (column?: IColumnObject) => void;
}

const DataRow = (props: IDataRow): JSX.Element => {
  const menuButtonRef = useRef(null);
  const [showUserEdit, setShowUserEdit] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);

  const visibleContextMenu = useSelector((state: AppState) => state.contextMenu.visibleContextMenuId);

  const dispatch = useDispatch();

  const toggleShowUserEdit = (event?: React.MouseEvent) => {
    event && event.preventDefault();
    setShowUserEdit((prevState) => !prevState);
  };

  const menuId = getMenuId(props.user.id);
  const showMenu = (visibleContextMenu === menuId);
  const toggleMenu = () => {
    if (!showMenu) {
      dispatch(openContextMenu(menuId));
    } else {
      dispatch(closeContextMenu());
    }
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prevState) => !prevState);
  };

  const toggleUserDetailsModal = () => {
    setShowUserDetailsModal((prevState) => !prevState);
  };

  const { id, displayName, email, validated, roles } = props.user;

  return (
    <div className="radio-key-data-card-container">
      <Row className="radio-key-data-card-container__header">
        <div className="radio-key-data-card-container__header__title text-overflow-ellipsis">{displayName}</div>
      </Row>
      <Row className="radio-key-data-card-container__body">
        <Col xs={4} className="radio-key-data-card-container__key">
          <Translate id={USER_NAME_HEADER} />
          {':'}
        </Col>
        <Col xs={8}>{displayName}</Col>
      </Row>
      <Row className="radio-key-data-card-container__body">
        <Col xs={4} className="radio-key-data-card-container__key">
          <Translate id={EMAIL_HEADER} />
          {':'}
        </Col>
        <Col xs={8}>{email}</Col>
      </Row>
      <Row className="radio-key-data-card-container__body">
        <Col xs={4} className="radio-key-data-card-container__key">
          <Translate id={VERIFIED_HEADER} />
          {':'}
        </Col>
        <Col xs={8} className="word-break-all">
          {validated ? (
            <FontAwesomeIcon icon={faCheckCircle} />
          ) : (
            <FontAwesomeIcon icon={faCircle} />
          )}
        </Col>
      </Row>
      <Row className="radio-key-data-card-container__body">
        <Col xs={4} className="radio-key-data-card-container__key">
          <Translate id={ROLE_HEADER} />
          {':'}
        </Col>
        <Col xs={8}>
          {roles!.map((ur: { deviceGroup: DeviceGroup; role: IRole }, index: number) => (
            <Badge
              data-tip="pil"
              data-for={ur.deviceGroup.name + ur.role.name + index}
              className="pil pil-long background-color-main w-100"
              key={index}
            >
              <span>{ur.role.name}</span>
              <Tooltip
                id={ur.deviceGroup.name + ur.role.name + index}
                place="top"
                className="device-manager__table__table__trow__pil__tooltip"
              >
                {ur.role.name} | {ur.deviceGroup.name}
              </Tooltip>
            </Badge>
          ))}
        </Col>
      </Row>
      <Row className="radio-key-data-card-container__footer justify-content-center">
        <Col xs={9} className="text-center">
          <span className="radio-details-btn" onClick={toggleShowUserEdit}>
            <Translate id="update" />
          </span>
          <RenderOnCondition condition={showUserEdit}>
            <UserActionModal
              saved={() => {
                props.refreshData();
                toggleShowUserEdit();
              }}
              cancelled={toggleShowUserEdit}
              isInCreationMode={false}
              title="update-user"
              showDeleteButton
              userId={id}
              removeUnit={(itemId: string) => props.removeUnit(itemId)}
            />
          </RenderOnCondition>
        </Col>
        <Col xs={3} ref={menuButtonRef}>
          <div className="radio-details">
            <div className="radio-details-icon" role="presentation" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </div>
            {showMenu && (
              <TableMenu
                isMobileDisplay
                user={props.user}
                removeUnit={props.removeUnit}
                menuId={menuId}
                toggleUserActionModal={toggleShowUserEdit}
                toggleDeleteModal={toggleDeleteModal}
                toggleUserDetailsModal={toggleUserDetailsModal}
              />
            )}

            <RenderOnCondition condition={showDeleteModal}>
              <ConfirmModal
                modalCloseRequested={() => toggleDeleteModal()}
                actionConfirmed={() => props.removeUnit(id)}
                translationIdOfElementType="user"
                confirmationVariant={ ConfirmationVariant.DELETE }
              />
            </RenderOnCondition>

            <RenderOnCondition condition={showUserDetailsModal}>
              <UserDetailsModal closeModal={toggleUserDetailsModal} id={id} />
            </RenderOnCondition>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DataRow;
