import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';
import { removeDeviceGroupFromDB } from '../../api/apiHelpers';
import useTranslation from '../../hooks/useTranslation';
import { DEVICE_GROUP_REMOVED, DeviceGroupRemovedAction, REMOVE_DEVICE_GROUP, RemoveDeviceGroupAction } from './removeDeviceGroupActions';
import { refreshDeviceTree } from './fetch-device-tree/refreshDeviceTreeActionCreator';
import { handleDeviceGroupDeletionResponse } from '../device-group/deviceGroupRemovalResponseHandler';

export const removeDeviceGroup = (
  id: string,
  shouldRefreshDeviceTree = true,
) => async (
  dispatch: Dispatch<any>,
): Promise<void> => {
  const translate = useTranslation();

  try {
    dispatch({ type: REMOVE_DEVICE_GROUP, id } as RemoveDeviceGroupAction);
    const response = await removeDeviceGroupFromDB(id);
    const isDeviceGroupRemoved = handleDeviceGroupDeletionResponse(translate, response);
    if (!isDeviceGroupRemoved) {
      return;
    }

    dispatch({ type: DEVICE_GROUP_REMOVED, id } as DeviceGroupRemovedAction);

    if (shouldRefreshDeviceTree) {
      dispatch(refreshDeviceTree());
    }
  } catch (e) {
    console.error(e);
    toastr.error(translate('error').toString(), e);
  }
};
