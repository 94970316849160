import {
  CLOSE_CONTEXT_MENU,
  OPEN_CONTEXT_MENU,
  ToggleContextMenuAction
} from './toggleContextMenuAction';

export const openContextMenu = (idOfOpenedContextMenu: string): ToggleContextMenuAction => ({
  type: OPEN_CONTEXT_MENU,
  payload: idOfOpenedContextMenu,
});

export const closeContextMenu = (): ToggleContextMenuAction => ({
  type: CLOSE_CONTEXT_MENU,
});

