import { Form, Formik } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as Yup from 'yup';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { Col, Row } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { v4 as uuidv4 } from 'uuid';
import { RadioKey } from '@wiot/shared-domain/models/radio-key/radio-key';
import { AggregatedPermission } from '@wiot/shared-domain/models/role/role';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import RenderOnCondition from '../../components/RenderOnCondition';
import Desktop from '../../components/Responsive/Desktop';
import Mobile from '../../components/Responsive/Mobile';
import LoadingIcon from '../../components/shared/LoadingIcon';
import { addKeyToDB, fetchKeyFromDB, updateKeyInDB } from '../../api/apiHelpers';
import { CustomFieldInput } from '../../components/Table/CustomFieldInput';
import { CustomFieldAdvancedInput } from '../../components/Table/CustomFieldAdvancedInput';
import GroupSelect from '../../components/shared/GroupSelect';
import ConfirmModal, { ConfirmationVariant } from '../../components/Modal/ConfirmModal';
import { saveKeys } from '../../state/actions/saveKeysAction';
import { AppState } from '../../state/reducers/rootReducer';
import { DispatchActionTypes, SetFieldValue } from '../../state/types';
import ModalHeader from '../../components/Modal/ModalHeader';
import { isTableLoading } from '../../state/table/isTableLoadingAction';
import HasPermission from '../../components/HasPermission';
import { GetHexValueValidationSchema } from '../../components/shared/YupValidators';
import { getRandomModalOffset } from '../../utils/dialog';
import { FeedbackAttachment } from '../../components/Feedback/feedback';
import { filterOutSensitiveDataFromRadioKey } from '../../components/Feedback/filter-sensitive-data';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';

export interface KeyActionModalProps extends LocalizeContextProps {
  closeAddAndUpdateModal: () => void;
  title: string;
  showDeleteButton: boolean;
  addUnit: boolean;
  id?: string;
  saveKeys: (keys: RadioKey[]) => void;
  currentEntriesPerPage: number;
  currentPage: number;
  removeUnit?: (id: string) => void;
  refreshData: (column?: IColumnObject) => void;
  permission?: AggregatedPermission;
  setIsTableLoading: (loading: boolean) => void;
}

export interface KeyActionModalState {
  keyData: RadioKey;
  showDeleteModal: boolean;
  randomModalOffset?: { marginTop: number; marginRight: number };
  isLoading: boolean;
}

class KeyActionModal extends React.Component<KeyActionModalProps, KeyActionModalState> {
  modalUid = uuidv4();

  constructor(props: KeyActionModalProps) {
    super(props);
    this.state = {
      keyData: {
        name: '',
        key: '',
        deviceGroup: '',
      },
      showDeleteModal: false,
      randomModalOffset: { marginTop: 0, marginRight: 0 },
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    this.setState({ randomModalOffset: getRandomModalOffset() });
    if (!this.props.addUnit && this.props.id) {
      const { radioKey } = await fetchKeyFromDB(this.props.id);
      this.setState({
        keyData: {
          ...radioKey,
          deviceGroup: radioKey.deviceGroup.id,
        },
      });
    }
    this.setState({
      isLoading: false,
    });
  };

  handleDeviceGroupChange = (deviceGroup: DeviceGroup | undefined, setFieldValue: SetFieldValue) => {
    setFieldValue('deviceGroup', deviceGroup?.id, false);
  };

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }));
  };

  cleanValues = (values: RadioKey) => {
    const { id, name, key, deviceGroup } = values;
    const cleanedValues: any = {};

    if (id) {
      cleanedValues.id = id;
    }
    if (name) {
      cleanedValues.name = name;
    }
    if (key) {
      cleanedValues.key = key;
    }
    if (deviceGroup) {
      cleanedValues.deviceGroup = deviceGroup;
    }
    return cleanedValues;
  };

  handleSubmit = async (values: RadioKey) => {
    const {
      setIsTableLoading,
      addUnit,
      refreshData,
      translate,
      closeAddAndUpdateModal,
    } = this.props;
    setIsTableLoading(true);
    const cleanedValues = this.cleanValues(values);
    const response = addUnit ? await addKeyToDB(cleanedValues) : await updateKeyInDB(cleanedValues);

    if (response) {
      await refreshData();
      closeAddAndUpdateModal();
      setIsTableLoading(false);
      addUnit
        ? toastr.success(translate('success').toString(), translate('add-key-success').toString())
        : toastr.success(translate('success').toString(), translate('edit-key-success').toString());
    }
  };

  getAttachmentForFeedback = (): FeedbackAttachment | null => {
    if (!this.state.keyData) {
      return null;
    }
    return {
      key: filterOutSensitiveDataFromRadioKey(this.state.keyData),
    };
  };

  render() {
    const {
      translate,
      permission,
      showDeleteButton,
      closeAddAndUpdateModal,
      addUnit,
      removeUnit,
      id,
      title,
    } = this.props;
    const { keyData, showDeleteModal, isLoading } = this.state;
    return (
      <div
        tabIndex={0} // make it focusable
        style={this.state.randomModalOffset}
        className="device-modal"
        id={`${title}-${this.modalUid}`}
      >
        <ModalHeader
          isDevice={false}
          titleTranslationId={title}
          targetId={this.modalUid}
          handleClose={closeAddAndUpdateModal}
          enableFeedbackSubmission={true}
          getFeedbackAttachment={this.getAttachmentForFeedback}
        />
        <div className="device-modal__body">
          <Formik
            initialValues={keyData}
            validationSchema={Yup.object().shape({
              name: Yup.string().trim().required(translate('required-field').toString()),
              key: GetHexValueValidationSchema(),
              deviceGroup: Yup.string().required(translate('required-field').toString()),
            })}
            enableReinitialize
            onSubmit={(values: RadioKey) => {
              this.handleSubmit(values);
            }}
            render={({ values, setFieldValue, errors, touched }: any) =>
              isLoading ? (
                <div className="loading-container__small">
                  <LoadingIcon />
                </div>
              ) : (
                <Form
                  className="form"
                  onClick={(event: React.MouseEvent) => event.stopPropagation()}
                >
                  <div className="form__section">
                    <div className="form__row">
                      <GroupSelect
                        onSelectionChangedSingleSelect={ (deviceGroup) => this.handleDeviceGroupChange(deviceGroup, setFieldValue) }
                        preSelectedDeviceGroupId={values.deviceGroup || ''}
                        error={errors.deviceGroup}
                        touched={touched.deviceGroup}
                        targetId={`group-select-${title}-${id}`}
                      />
                    </div>
                  </div>

                  <div className="form__section">
                    <Row>
                      <Col lg={5}>
                        <CustomFieldInput translationId="key-name" fieldName="name" value={values.name} />
                      </Col>
                      <Col lg={7}>
                        <CustomFieldAdvancedInput
                          fieldName="key"
                          inputFontFamily="monospace"
                          maxLength={32}
                          type="password"
                          translationId="key-value"
                          value={values.key}
                          showEyeButton
                          showCopyButton
                          error={errors.key}
                          touched={touched.key}
                        />
                      </Col>
                    </Row>
                  </div>

                  <div className="form__section last">
                    <div className="form__row space-between ai">
                      <div className="form__row--left">
                        <HasPermission permissionObj={permission} permissionKey="keys.remove">
                          <>
                            {showDeleteButton && (
                              <button
                                type="button"
                                className="form__button--delete"
                                onClick={this.toggleDeleteModal}
                              >
                                <Translate id="remove" />
                              </button>
                            )}
                          </>
                        </HasPermission>
                      </div>
                      <div className="form__row--right">
                        <button
                          type="button"
                          className="form__button--cancel"
                          onClick={closeAddAndUpdateModal}
                        >
                          <Translate id="cancel" />
                        </button>
                        <button
                          className="form__button--blue background-color-main text-color-white border-color-main"
                          type="submit"
                        >
                          <Desktop>
                            <Translate id={addUnit ? 'add-key' : 'save-key'} />
                          </Desktop>
                          <Mobile>
                            <Translate id={addUnit ? 'add-key' : 'save'} />
                          </Mobile>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )
            }
          />
          <RenderOnCondition condition={showDeleteModal}>
            <>
              {id && (
                <ConfirmModal
                  modalCloseRequested={() => this.toggleDeleteModal()}
                  actionConfirmed={async () => {
                    removeUnit && (await removeUnit(id));
                  }}
                  translationIdOfElementType="key"
                  confirmationVariant={ ConfirmationVariant.DELETE }
                />
              )}
            </>
          </RenderOnCondition>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  currentEntriesPerPage: state.currentEntriesPerPage,
  currentPage: state.pagination['key-management'].currentPage,
  permission: state.currentUser.permission,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  setIsTableLoading: (loading: boolean) => dispatch(isTableLoading(loading)),
  saveKeys: (keys: RadioKey[]) => dispatch(saveKeys(keys)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(KeyActionModal));
