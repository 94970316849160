import { Dispatch } from 'redux';
import { buildQueryString, getFetchOptions } from '../../../utils/common';
import { authenticatedInstance, handleApiError } from '../../../api/apiHelpers';
import { DEVICE_GROUPS_API } from '../api-device-groups';
import { DeviceGroups } from '@wiot/shared-domain/models/device-group/device-group';

import { AppState } from '../../reducers/rootReducer';
import { IDeviceGroupFilter } from '../../reducers/filterSortReducer';
import { IPagination } from '../../types';
import { savePagination as savePaginationAction } from '../../actions/savePaginationAction';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import { getGroupTypes } from '../../../utils/device-group-filter-helper';
import axios, { Canceler } from 'axios';
import { DEVICE_GROUPS_PAGE_NAME } from '../device-group-page-name';
import { deviceGroupsFetched, deviceGroupsFetchFailed, fetchDeviceGroupsAction } from './paginated-device-group-action-types';

const { CancelToken } = axios;


export const fetchDeviceGroups = (
  columnToSortWith?: IColumnObject
) => async (
  dispatch: Dispatch<any>,
  getState: () => AppState,
): Promise<void> => {
  const state = getState();
  dispatch(fetchDeviceGroupsAction());
  try {
    const deviceGroups = await fetchDeviceGroupsFromApi(
      true,
      state,
      true,
      true,
      false,
      false,
      columnToSortWith,
    );

    dispatch(deviceGroupsFetched(deviceGroups));

    const paginationData: IPagination = { totalDocs: deviceGroups.totalDocs ?? 0, totalPages: deviceGroups.totalPages };
    dispatch(savePaginationAction(paginationData, DEVICE_GROUPS_PAGE_NAME));
  } catch (error) {
    const wasFetchCanceledByNewFetch = axios.isCancel(error);
    if (!wasFetchCanceledByNewFetch) {
      dispatch(deviceGroupsFetchFailed());
      handleApiError(error);
      throw error;
    }
  }
};

let cancelDeviceGroupsRequest: Canceler;

const fetchDeviceGroupsFromApi = async (
  includeDescendants = false,
  appState: AppState,
  appendAncestorGroups: boolean,
  cancelable = false,
  appendUserAndDeviceInfo = false,
  appendChildGroups = true,
  columnToSortWith?: IColumnObject,
): Promise<DeviceGroups> => {
  cancelable && cancelDeviceGroupsRequest && cancelDeviceGroupsRequest();
  const fetchOptions = getFetchOptions<IDeviceGroupFilter>(
    appState.currentEntriesPerPage,
    columnToSortWith,
    appState.filters.filter[DEVICE_GROUPS_PAGE_NAME],
    appState.pagination[DEVICE_GROUPS_PAGE_NAME].currentPage,
  );

  const { page, pageSize, filters, sort, gateways } = fetchOptions;
  const groupTypes = getGroupTypes(filters?.type);
  const updatedFilter = { ...filters, type: groupTypes };
  const filterString = JSON.stringify(updatedFilter);
  const qs = buildQueryString({
    page,
    pageSize,
    filter: filterString,
    sort,
    gateways,
    includeDescendants,
    appendUserAndDeviceInfo,
    appendAncestorGroups,
    appendChildGroups,
  });
  const url = `${ DEVICE_GROUPS_API }?${ qs }`;
  const { data: deviceGroups } = await authenticatedInstance.get<DeviceGroups>(url, {
    cancelToken: new CancelToken((c) => {
      // An executor function receives a cancel function as a parameter
      if (cancelable) {
        cancelDeviceGroupsRequest = c;
      }
    }),
  });

  return deviceGroups;
};


