import React, { useEffect } from 'react';
import useTranslation from '../../hooks/useTranslation';
import { BaseSelect } from '../FilterBar/select/BaseSelect';
import { OptionProps } from 'react-select/src/components/Option';
import { Option } from '../FilterBar/select/components/Option';
import { CustomFieldProps } from './custom-field-select';
import { OptionsType, OptionTypeBase } from 'react-select';
import { InputContextType } from '../Input/input-context-type';

interface CustomFieldSelectProps<OptionType extends OptionTypeBase> extends CustomFieldProps<OptionType> {
  fieldName: string;
  translationId?: string;
  placeholderTranslationId?: string;
  options: OptionType[];
  translateOptions?: boolean;
  translateNewMapped?: boolean;
  onBlur?: (event: any) => void;
  nestedError?: boolean;
  error?: string;
  touched?: boolean;
  labelKey?: string;
  valueKey?: string;
  portalTargetId?: string;

  isMulti?: boolean;
  hideLabel?: boolean;
  isClearable?: boolean;
  sortLabels?: boolean;
}

const CustomFieldSelect = <OptionType extends OptionTypeBase> ({
  fieldName,
  translationId,
  placeholderTranslationId = 'select',
  options,
  translateOptions,
  value,
  onChange,
  error,
  readOnly = false,
  required = false,
  labelKey = 'name',
  valueKey = 'id',
  portalTargetId,

  isMulti = false,
  hideLabel,
  isClearable = true,
  sortLabels = true,
}: CustomFieldSelectProps<OptionType>): React.ReactElement => {

  const [selectedValue, setSelectedValue] = React.useState<OptionType | undefined>(undefined);

  const translate = useTranslation();

  const getLabel = (option: OptionType | OptionsType<OptionType>) => option[labelKey];
  const getValue = (option: OptionType | OptionsType<OptionType>) => option[valueKey];

  useEffect(() => {
    if (!value) {
      setSelectedValue(undefined);
      return;
    }
    if (!isMulti && Array.isArray(value)) {
      setSelectedValue(value[0]);
      return;
    }
    if (typeof value === 'string') {
      const option = options?.find((option) => getValue(option) === value);
      setSelectedValue(option);
      return;
    }
    setSelectedValue(value);
  }, [value, options, isMulti]);

  const createChangeEvent = (option: OptionType) => {
    if ( onChange ) {
      onChange({ currentTarget: { name: fieldName, value: getValue(option) } } as React.ChangeEvent<HTMLSelectElement>);
    }
  }

  const isOptionChecked = (option: OptionType) => {
    if (!value) {
      return false;
    }
    if ( typeof value === 'string' ) {
      return value === getValue(option);
    }
    return getValue(value) === getValue(option);
  }

  const getOptions = (props: OptionProps<OptionType>) => {
    return (
      <Option
        {...props}
        label={ translateOptions ? `${ translate(getLabel(props.data)) }` : `${ getLabel(props.data) }` }
        isOptionChecked={ isOptionChecked(props.data) }
      />
    );
  }

  return (
    <BaseSelect<OptionType>
      translationId={ translationId || fieldName }
      label={ hideLabel ? undefined : translationId }

      getOptionLabel={ getLabel }
      getOptionValue={ getValue }

      defaultOptions={ options }
      selectedOptions={ selectedValue }
      onChange={ createChangeEvent }
      option={ getOptions }
      placeholderTranslationId={ placeholderTranslationId }

      portalTargetId={ portalTargetId }

      isMulti={ isMulti }
      isClearable={ isClearable }
      translateOptions={ translateOptions }

      readOnly={ readOnly }
      showSelectAndDeselectAllButtons={ false }

      required={ required }
      error={ error }

      inputContextType={ InputContextType.MODAL }
      sortLabels={ sortLabels }
    />
  );
};

export default CustomFieldSelect;
