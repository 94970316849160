import { ILegalDetails } from '@wiot/shared-domain/models/settings/settings';
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../state/reducers/rootReducer';
import LegalDetailsContent from '../../components/LegalDetails/LegalDetailsContent';

interface IPrivacyProps {
  legalDetails?: ILegalDetails;
}

const Privacy = ({ legalDetails }: IPrivacyProps) => {
  const getContentString = () => {
    if (legalDetails && legalDetails.privacy) {
      return legalDetails.privacy.custom || legalDetails.privacy.default;
    }
  };
  return <LegalDetailsContent contentString={getContentString()} />;
};

const mapStateToProps = (state: AppState) => ({
  legalDetails: state.siteSettings.legalDetails,
});

export default connect(mapStateToProps)(Privacy);
