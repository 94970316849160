import { createBrowserHistory } from 'history';
import localforage from 'localforage';
import { applyMiddleware, createStore } from 'redux';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import { getPersistConfig } from 'redux-deep-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createRootReducer } from '../reducers/rootReducer';
import migrations from './migrations/index';

export const history = createBrowserHistory();
const rootReducer = createRootReducer(history);

const persistConfig = getPersistConfig({
  key: 'IoTPlatform',
  storage: localforage,
  whitelist: ['filters', 'deviceManagerState', 'isOffline', 'currentUser', 'properties.propertiesById'],
  rootReducer,
  migrate: createMigrate(migrations),
  version: 1, // Increment this number for every new version
});

// localforage.clear(); // Comment in to reset the redux store
const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = composeWithDevTools({}); // use { trace: true, traceLimit: 25 } for redux dev tools tracing
const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));

export const persistor = persistStore(store);

export default store;
