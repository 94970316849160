import { DataIntegrationJobExtended, pullFrequencyOptions, } from '@wiot/shared-domain/models/data-integration/data-integration';
import React from 'react';
import { Col } from 'react-bootstrap';
import CustomFieldSelect from '../../../../components/Table/CustomFieldSelect';
import { validateRequiredFieldValue } from '../../../../utils/validation';
import Portal from '../../../../components/shared/Portal';
import { SetFieldValue } from '../../../../state/types';

interface IPullFrequencySelectProps {
  api: DataIntegrationJobExtended;
  setFieldValue: SetFieldValue;
}

const PullFrequencySelect = (props: IPullFrequencySelectProps) => {

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = (event?.currentTarget || event?.target);
    if (target) {
      props.setFieldValue('api.pullFrequency', target.value);
    }
  }

  return (

  <Col lg={ 6 }>
    <CustomFieldSelect
      validate={ validateRequiredFieldValue }
      required
      translationId="pull-frequency"
      fieldName="api.pullFrequency"
      options={ pullFrequencyOptions.map((option) => ({
        id: String(option.id),
        name: option.name,
      })) }
      translateOptions
      value={ String(props.api.pullFrequency) }
      onChange={ handleChange }
      sortLabels={ false }
      portalTargetId="modal-pull-frequency-select-portal"
    />
    <Portal>
      <div id="modal-pull-frequency-select-portal" />
    </Portal>
  </Col>
);

}

export default PullFrequencySelect;
