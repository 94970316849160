import * as React from 'react';
import { Translate } from 'react-localize-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { setFilter } from '../../state/filter/set-filter-action-creator';
import { PageKeys } from '../../state/reducers/filterSortReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../state/reducers/rootReducer';
import { FilterField } from './filter-field';

interface FilterDatePickerProps {
  field: FilterField;
  pageKey: PageKeys;
  readOnly?: boolean;
}

const FilterDatePicker = (props: FilterDatePickerProps) => {
  const { field, pageKey, readOnly = false } = props;
  const dispatch = useDispatch();
  const filter = useSelector((state: AppState) => state.filters.filter);

  // @ts-ignore The 'any' type in the global filter must be refactored
  const selected = filter[pageKey][field.stateField];
  const handleDatePickerChange = (newValues: Record<string, unknown>) => {
    dispatch(
      setFilter({
        page: pageKey,
        values: newValues,
      }),
    );
  };

  return (
    <div className="filterbar__item">
      <label>
        <Translate id={ field.name }/>
      </label>
      <DatePicker
        readOnly={ readOnly }
        className={`control filter-bar-select__control ${readOnly ? 'input--readonly' : ''}`}
        calendarClassName="select__menu"
        showTimeSelect
        selected={ selected }
        timeFormat="HH:mm"
        timeIntervals={ 15 }
        dateFormat="dd/MM/yyyy HH:mm"
        timeCaption="time"
        onChange={ (date) => handleDatePickerChange({ [field.stateField]: date }) }
      />
    </div>
  );
};

export default FilterDatePicker;
