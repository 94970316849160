import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';
import useTranslation from '../../hooks/useTranslation';
import { authenticatedInstance, handleApiError } from '../../api/apiHelpers';
import api from '../../api/api';
import {
  DELETE_MANUAL_DEVICE_READING, DELETE_MANUAL_DEVICE_READING_FAILED,
  DELETE_MANUAL_DEVICE_READING_SUCCEEDED,
  DeleteManualDeviceReadingAction, DeleteManualDeviceReadingFailedAction,
  DeleteManualDeviceReadingSucceededAction
} from './DeleteManualDeviceReadingActions';
import { DispatchActionTypes } from '../types';

export const deleteManualDeviceReading = (deviceReadingId: string) =>
  async (dispatch: Dispatch<DispatchActionTypes>): Promise<void> => {
    dispatch({
      type: DELETE_MANUAL_DEVICE_READING,
      deviceReadingId: deviceReadingId,
    } as DeleteManualDeviceReadingAction);
    const translate = useTranslation();

    try {
      const url = `${ api.deviceMessages }/manual/${ deviceReadingId }`;
      const { data: deletedDeviceReadingId } = await authenticatedInstance.delete<string>(url);

      dispatch({
        type: DELETE_MANUAL_DEVICE_READING_SUCCEEDED,
        deviceReadingId: deletedDeviceReadingId,
      } as DeleteManualDeviceReadingSucceededAction);

      toastr.success(
        translate('success').toString(),
        translate('delete-device-reading-succeeded').toString(),
      );
    } catch (error) {
      handleApiError(error, false);
      const errorMessage = error.response ? error.response.data : error.message;

      dispatch({
        type: DELETE_MANUAL_DEVICE_READING_FAILED,
        deviceReadingId: deviceReadingId,
        errorMessage: errorMessage,
      } as DeleteManualDeviceReadingFailedAction);

      toastr.error(
        translate('delete-device-reading-failed').toString(),
        errorMessage,
      );
    }
  };
