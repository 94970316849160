import React from 'react';
import { Translate } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ProtectionDetails } from '@wiot/shared-domain/models/device-key/protection-details';
import RenderOnCondition from '../RenderOnCondition';
import { DEVICE_MANAGER_PATH } from '../../navigation/paths';
import { useSelector } from 'react-redux';
import { AppState } from '../../state/reducers/rootReducer';

interface DeviceInfoProps {
  protectionDetails?: ProtectionDetails;
}

function DeviceInfo({ protectionDetails }: DeviceInfoProps) {
  const isShownInModal = useSelector((state: AppState) => state.deviceKeyProtection.downloadHashOfDKDModal);

  return (
    <div className="device-info m-2">
      <FontAwesomeIcon icon={ faInfoCircle } size="2x" color="#267fda"/>
      <div className="device-info__text">
        <div className="device-info-title">
          <Translate id={ protectionDetails?.isDeviceGroup ? 'device-group' : 'device' }/>-
          <Translate id="info"/>
        </div>
        <RenderOnCondition condition={ !protectionDetails?.isDeviceGroup }>
          <>
            <Translate id="device-id"/>:
            <div className="device-info-attribute">{ protectionDetails?.deviceSerialNumber }</div>
          </>
        </RenderOnCondition>
        <div>
          <Translate id="device-name"/>:
          <div className="device-info-attribute">{ protectionDetails?.name }</div>
        </div>
      </div>
      <RenderOnCondition condition={ !isShownInModal && protectionDetails?.hasCurrentUserAccessToKey }>
        <div className="device-info__right-side py-3">
          <button
            className="secondary-button py-1 bg-white"
            onClick={ () => window.open(`${ DEVICE_MANAGER_PATH }/`, '_blank') }>
            <Translate id="go-to-device-manager"/>
            <FontAwesomeIcon
              icon={ faExternalLinkAlt }
              className="ml-2"
            />
          </button>

        </div>
      </RenderOnCondition>
    </div>
  );
}

export default DeviceInfo;
