import React from 'react';
import { ReactComponent as DeviceGroupsIcon } from '../../assets/side-nav/device-groups.svg';
import { ReactComponent as UserAccountsIcon } from '../../assets/side-nav/user-accounts-admin.svg';
import { ReactComponent as RoleAdminIcon } from '../../assets/side-nav/role-admin.svg';
import { ReactComponent as KeyAdminIcon } from '../../assets/side-nav/key-admin.svg';

export const TOAST_VISIBILITY_DURATION = 10000;

interface OnDependencyDeleteToastrProps {
  dgName?: string;
  roleName?: string;
  userIdentifier?: string;
  keyName?: string;
  type: string;
}

const OnDependencyDeleteToastr = (props: OnDependencyDeleteToastrProps) => {
  const {
    dgName,
    roleName,
    userIdentifier,
    keyName,
    type,
  } = props;

  return (
    <>
      <div>{type}</div>
      {userIdentifier && (
        <div>
          <UserAccountsIcon className="navbar-left__container-top__item__text__icon"  />
          {' '}
          {userIdentifier}
        </div>
      )}
      {roleName && (
        <div>
          <RoleAdminIcon className="navbar-left__container-top__item__text__icon" />
          {' '}
          {roleName}
        </div>
      )}
      {keyName && (
        <div>
          <KeyAdminIcon className="navbar-left__container-top__item__text__icon" />
          {' '}
          {keyName}
        </div>
      )}
      {dgName && (
        <div>
          <DeviceGroupsIcon className="navbar-left__container-top__item__text__icon" />
          {' '}
          {dgName}
        </div>
      )}
    </>
  );
};

export default OnDependencyDeleteToastr;
