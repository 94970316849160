import { CHANGE_FILTER, CLEAR_PAGINATION, SAVE_CURRENT_PAGE, SAVE_PAGINATION } from '../constants/actionTypes';
import { ChangeFilterAction, PaginationActionTypes } from '../types';
import { PageKeys } from './filterSortReducer';

export interface IPaginationData {
  totalDocs: number;
  totalPages?: number;
  currentPage: number;
}

export type PaginationState = Record<PageKeys, IPaginationData>;

export const initialState: PaginationState = {
  'undefined': {
    currentPage: 0,
    totalDocs: 0,
  },
  'device-manager': {
    currentPage: 1,
    totalDocs: 0,
  },
  'device-messages': {
    currentPage: 1,
    totalDocs: 0,
  },
  'device-groups': {
    currentPage: 1,
    totalDocs: 0,
  },
  'key-management': {
    currentPage: 1,
    totalDocs: 0,
  },
  'user-management': {
    currentPage: 1,
    totalDocs: 0,
  },
  roles: {
    currentPage: 1,
    totalDocs: 0,
  },
  'data-integration': {
    currentPage: 1,
    totalDocs: 0,
  },
  backups: {
    currentPage: 1,
    totalDocs: 0,
  },
  'manage-device-types': {
    currentPage: 1,
    totalDocs: 0,
  },
  'manage-device-group-types': {
    currentPage: 1,
    totalDocs: 0,
  },
  'property-view': {
    currentPage: 1,
    totalDocs: 0,
  },
};

export const paginationReducer = (
  state: PaginationState = initialState,
  action: PaginationActionTypes | ChangeFilterAction,
): PaginationState => {
  switch (action.type) {
    case SAVE_PAGINATION:
      return {
        ...state,
        [action.payload.pageName]: {
          ...state[action.payload.pageName],
          ...('data' in action.payload && action.payload.data),
        },
      };
    case CHANGE_FILTER:
      return {
        ...state,
        [action.payload.page]: {
          ...initialState[action.payload.page],
        },
      };
    case CLEAR_PAGINATION:
      return {
        ...state,
        [action.payload.pageName]: {
          ...initialState[action.payload.pageName],
        },
      };
    case SAVE_CURRENT_PAGE:
      return {
        ...state,
        [action.payload.pageName]: {
          ...state[action.payload.pageName],
          currentPage: 'currentPage' in action.payload ? action.payload.currentPage : 1,
        },
      };
    default:
      return state;
  }
};
