import { NavigationLink } from './navigation-models';
import DASHBOARD_ICON from '../assets/side-nav/home.svg';
import PROPERTY_VIEW_ICON from '../assets/side-nav/property-view.svg';
import DEVICE_MANAGER_ICON from '../assets/side-nav/group-admin.svg';
import KEY_ADMIN_ICON from '../assets/side-nav/key-admin.svg';
import DEVICE_GROUP_ICON from '../assets/side-nav/device-groups.svg';
import { faCog, faCube } from '@fortawesome/free-solid-svg-icons';
import USER_ACCOUNTS_ICON from '../assets/side-nav/user-accounts-admin.svg';
import ROLE_ADMIN_ICON from '../assets/side-nav/role-admin.svg';
import DATA_INTEGRATION_ICON from '../assets/side-nav/data-integration.svg';
import MESSAGES_ICON from '../assets/side-nav/messages.svg';
import { FeatureAvailability } from '../utils/feature-availability';

export const allNavigationItems: NavigationLink[] = [
  {
    path: 'dashboard',
    title: 'dashboard',
    svgIcon: DASHBOARD_ICON,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
      | FeatureAvailability.WithoutPermissionCheck
  },
  {
    path: 'device-manager',
    title: 'device-manager',
    svgIcon: DEVICE_MANAGER_ICON,
    svgIconForKeyManager: KEY_ADMIN_ICON,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
      | FeatureAvailability.WithoutPermissionCheck
  },
  {
    path: 'property-view',
    title: 'property-view',
    svgIcon: PROPERTY_VIEW_ICON,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
  },
  {
    path: 'device-messages',
    title: 'device-messages',
    svgIcon: MESSAGES_ICON,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
      | FeatureAvailability.WithoutPermissionCheck
  },
  {
    path: 'device-groups',
    title: 'device-groups',
    svgIcon: DEVICE_GROUP_ICON,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
  },
  {
    path: 'key-management',
    title: 'key-management',
    svgIcon: KEY_ADMIN_ICON,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
  },
  {
    path: 'manage-device-types',
    title: 'manage-device-types',
    icon: faCube,
    availability: FeatureAvailability.None,
  },
  {
    path: 'user-management',
    title: 'user-management',
    svgIcon: USER_ACCOUNTS_ICON,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
  },
  {
    path: 'roles',
    title: 'roles',
    svgIcon: ROLE_ADMIN_ICON,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager
      | FeatureAvailability.Desktop
  },
  {
    path: 'data-integration',
    title: 'data-integration',
    svgIcon: DATA_INTEGRATION_ICON,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.Desktop
  },
  {
    path: 'device-hierarchy',
    title: 'device-hierarchy',
    svgIcon: DEVICE_MANAGER_ICON,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager
      | FeatureAvailability.Mobile
      | FeatureAvailability.WithoutPermissionCheck
  },
  {
    path: 'settings',
    title: 'settings',
    icon: faCog,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager
      | FeatureAvailability.Desktop
  },
];
