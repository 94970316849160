import * as React from 'react';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import _ from 'lodash';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import TableLoadingIcon from "../shared/TableLoadingIcon";
import { changeEntriesPerPage } from '../../state/actions/changeEntriesPerPageAction';
import { calculateRecommendedEntriesPerPage } from '../shared/CalculateRecommendedEntriesPerPage';
import { setRecommendedEntriesPerPage } from '../../state/actions/setRecommendedEntriesPerPageAction';
import { saveCurrentPage } from '../../state/actions/savePaginationAction';
import { AppState } from '../../state/reducers/rootReducer';
import { DispatchActionTypes } from '../../state/types';
import { PageKeys } from '../../state/reducers/filterSortReducer';
import { PaginationState } from '../../state/reducers/paginationReducer';

interface TableFooterProps extends LocalizeContextProps {
  changeEntriesPerPage: (entriesPerPage: number) => void;
  currentEntriesPerPage: number;
  currentRecommendedEntriesPerPage: number;
  disableControls?: boolean;
  devicesLength: number;
  isTableLoading?: boolean;
  page: PageKeys;
  pagination: PaginationState;
  setCurrentPage: (pageNumber: number, page: PageKeys) => void;
  setRecommendedEntriesPerPage: (recommendedEntries: number) => void;
}

class TableFooter extends React.Component<TableFooterProps, never> {
  componentDidMount = async () => {
    await this.props.setRecommendedEntriesPerPage(
      calculateRecommendedEntriesPerPage(this.props.page),
    );
    await this.props.changeEntriesPerPage(this.props.currentRecommendedEntriesPerPage);
  };

  handleEntryClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const entriesPerPage = Number(event.currentTarget.name);
    await this.props.changeEntriesPerPage(entriesPerPage);
    this.handlePageClick(1);
  };

  handlePageClick = (pageNumber: number) => {
    const { disableControls, setCurrentPage, page } = this.props;

    if (disableControls) {
      return;
    }

    setCurrentPage(pageNumber, page);
  };

  getEntriesPerPage = () => {
    const { page, pagination } = this.props;
    const entriesPerPage = {
      [this.props.translate('all').toString()]: pagination[page].totalDocs,
      [this.props.translate('auto').toString()]: this.props.currentRecommendedEntriesPerPage,
      '25': 25,
      '50': 50,
      '100': 100,
      '250': 250,
    };
    return entriesPerPage;
  };

  render() {
    const { disableControls, pagination, page } = this.props;
    const currentPagination = pagination[page];
    const entriesPerPage = this.getEntriesPerPage();
    const buttonClass = 'table__footer__button';
    const paginationInfo = {
      pageRangeDisplayed: 3,
      marginPagesDisplayed: 3,
      initialPage: 0,
    };

    return currentPagination.totalDocs ? (
      <footer className="table__footer" data-testid="table-footer">
        <div className="table__footer__wrapper" data-testid="table-entries-per-page">
          {_.map(entriesPerPage, (key, value) => (
            <button
              key={`${key}-${value}`}
              value={value}
              name={`${key}`}
              onClick={this.handleEntryClick}
              className={`${buttonClass} ${
                key === this.props.currentEntriesPerPage ? `${buttonClass}--active` : ''
              }`}
              disabled={disableControls}
              data-tip={disableControls ? 'disabled-controls-tooltip' : null}
              data-for={disableControls ? 'disabled-controls-tooltip' : null}
            >
              {value}
            </button>
          ))}
        </div>

        {this.props.isTableLoading && <TableLoadingIcon size={40} />}

        <div
          data-testid="table-pagination"
          className={disableControls ? 'disabled' : ''}
          data-tip={disableControls ? 'disabled-controls-tooltip' : null}
          data-for={disableControls ? 'disabled-controls-tooltip' : null}
        >
          <ReactPaginate
            pageCount={Math.ceil(currentPagination.totalDocs / this.props.currentEntriesPerPage)}
            pageRangeDisplayed={paginationInfo.pageRangeDisplayed}
            marginPagesDisplayed={paginationInfo.marginPagesDisplayed}
            initialPage={paginationInfo.initialPage}
            disableInitialCallback
            previousLabel={String.fromCharCode(8249)}
            nextLabel={String.fromCharCode(8250)}
            breakLabel="..."
            onPageChange={({ selected }) => this.handlePageClick(selected + 1)}
            containerClassName="pagination"
            pageClassName={`${buttonClass}`}
            previousClassName={`${buttonClass} ${buttonClass}--bold`}
            nextClassName={`${buttonClass} ${buttonClass}--bold`}
            breakClassName={`${buttonClass}`}
            activeClassName={`${buttonClass}--active`}
            disabledClassName={`${buttonClass}--disabled`}
            forcePage={currentPagination.currentPage - 1}
          />
        </div>
      </footer>
    ) : null;
  }
}

const mapStateToProps = (state: AppState) => ({
  devicesLength: state.devices.length,
  currentEntriesPerPage: state.currentEntriesPerPage,
  pagination: state.pagination,
  currentRecommendedEntriesPerPage: state.currentRecommendedEntriesPerPage,
  deviceMessagesLength: state.deviceMessages.length,
  isTableLoading: state.isTableLoading,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  changeEntriesPerPage: (currentEntriesPerPage: number) =>
    dispatch(changeEntriesPerPage(currentEntriesPerPage)),
  setRecommendedEntriesPerPage: (recommendedEntriesPerPage: number) =>
    dispatch(setRecommendedEntriesPerPage(recommendedEntriesPerPage)),
  setCurrentPage: (pageNumber: number, page: PageKeys) =>
    dispatch(saveCurrentPage(pageNumber, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(TableFooter));
