import { IExportFormat } from '@wiot/shared-domain/models/domain/services/export/export.models';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import React from 'react';
import moment from 'moment/moment';

export interface DownloadProgressProps {
  count: number,
  exportFormat: IExportFormat,
  estimatedTimeFromTotalDocs: number,
  now: number
}

export function DownloadProgress({
  count,
  estimatedTimeFromTotalDocs,
  exportFormat: { name },
  now
}: DownloadProgressProps) {
  return <>
    <Row className="my-3">
      <Col>
        <Translate id="creating-billing-export" data={ { count: count } }/>
        ...
      </Col>
    </Row>
    <Row className="mb-2">
      <Col>
        <Translate id="exportFormat"/>:
      </Col>
      <Col>
        <Translate id={ name }/>
      </Col>
    </Row>
    <Row className="my-3">
      <Col>
        <Translate id="estimated-time"/>:
      </Col>
      <Col>
        {
          estimatedTimeFromTotalDocs < 60 ? <Translate id="less-than-a-minute"/> :
            moment.duration(estimatedTimeFromTotalDocs, 'seconds').humanize()
        }
      </Col>
    </Row>
    <ProgressBar animated now={ now }/>
  </>;
}