import React, { useEffect } from 'react';
import { Device } from '@wiot/shared-domain/models/device/device';
import ConsumptionChart from '../../../../components/shared/ConsumptionGraph';
import { fetchLast14MonthlyEndValues } from '../../../../state/device/consumption/fetchLast14MonthlyEndValuesActionCreators';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../state/reducers/rootReducer';
import { Translate } from 'react-localize-redux';
import LoadingIcon from '../../../../components/shared/LoadingIcon';
import ConsumptionTable from './ConsumptionTable';

interface IConsumptionTabProps {
  device: Device;
}

const ConsumptionTab = ({ device }: IConsumptionTabProps) => {
  const dispatch = useDispatch();

  const monthlyEndValues = useSelector((appState: AppState) => appState.last14MonthlyEndValues[device.id]);

  useEffect(() => {
    dispatch(fetchLast14MonthlyEndValues(device));
  }, [dispatch, device]);

  if (!monthlyEndValues) {
    return <LoadingIcon centerBothAxis={ false }/>;
  }

  if (monthlyEndValues.values.length) {
    return <>
      <ConsumptionChart key={ monthlyEndValues.atDate?.toString() } consumptionValues={ monthlyEndValues }/>
      <ConsumptionTable historicalValues={ monthlyEndValues }/>
    </>;
  }

  return <Translate id="no-data"/>;
};

export default ConsumptionTab;
