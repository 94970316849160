import React, { useEffect } from 'react';
import { Translate } from 'react-localize-redux';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { ISystemHealth, WARNING_STATES, DANGER_STATES } from './SystemHealth';
import SystemHealthIndicator from './SystemHealthIndicator';
import { Tooltip } from '../../../components/shared/Tooltip';

interface SystemHealthComponentProps {
  data: ISystemHealth;
  setSelectedComponent: React.Dispatch<React.SetStateAction<string | undefined>>;
}

enum DIRECTIONS {
  CENTER = 'center',
  LTR = 'right',
  RTL = 'left',
}

interface IComponentPosition {
  [key: string]: {
    direction: DIRECTIONS;
    column: number[];
    row: number[];
  };
}

const componentPositions: IComponentPosition = {
  traefik_proxy_1: {
    direction: DIRECTIONS.CENTER,
    column: [3, 6],
    row: [1, 2],
  },
  frontend: {
    direction: DIRECTIONS.RTL,
    column: [1, 2],
    row: [3, 4],
  },
  api: {
    direction: DIRECTIONS.LTR,
    column: [5, 6],
    row: [3, 4],
  },
  adminMongo: {
    direction: DIRECTIONS.RTL,
    column: [1, 2],
    row: [5, 6],
  },
  mongodb: {
    direction: DIRECTIONS.LTR,
    column: [5, 6],
    row: [5, 6],
  },
  influxdb: {
    direction: DIRECTIONS.LTR,
    column: [5, 6],
    row: [6, 7],
  },
  processing: {
    direction: DIRECTIONS.RTL,
    column: [1, 2],
    row: [8, 9],
  },
  rabbitmq: {
    direction: DIRECTIONS.LTR,
    column: [5, 6],
    row: [8, 9],
  },
  preprocessing: {
    direction: DIRECTIONS.RTL,
    column: [1, 2],
    row: [10, 11],
  },
  ftp: {
    direction: DIRECTIONS.LTR,
    column: [5, 6],
    row: [10, 11],
  },
};

const SystemHealthComponent = (props: SystemHealthComponentProps) => {
  const { data, setSelectedComponent } = props;

  useEffect(() => {
    const component = document.getElementById(data.name);
    if (component) {
      // the SVG elements for each system-component has its name as #id
      if (WARNING_STATES.includes(data.state))
        component.classList.add('health-card-indicator-warning');
      else if (DANGER_STATES.includes(data.state))
        component.classList.add('health-card-indicator-danger');
    }
  }, [data.name, data.state]);

  return (
    <>
      {data.name && (
        <div
          data-for={`health-tooltip-${data.name}`}
          style={{
            gridColumnStart: componentPositions[data.name]?.column[0],
            gridColumnEnd: componentPositions[data.name]?.column[1],
            gridRowStart: componentPositions[data.name]?.row[0],
            gridRowEnd: componentPositions[data.name]?.row[1],
            textAlign: componentPositions[data.name]?.direction,
            padding:
              componentPositions[data.name]?.direction === DIRECTIONS.LTR
                ? '0 30px 0 0'
                : '0 0 0 30px',
          }}
          className="health-component-details"
          data-tip
        >
          <Tooltip
            place={componentPositions[data.name]?.direction === DIRECTIONS.RTL ? 'right' : 'left'}
            id={`health-tooltip-${data.name}`}
          >
            {data.environment && (
              <h6 className="health-component-environment">
                <Translate id="environment" />: {data.environment}
              </h6>
            )}
            {data.state && (
              <h6 className="health-component-state">
                <Translate id="state" />: <Translate id={data.state} />
              </h6>
            )}
            {data.status && (
              <h6 className="health-component-status">
                <Translate id="status" />: {data.status}
              </h6>
            )}
          </Tooltip>
          <div>
            <SystemHealthIndicator state={data.state} />
            <div className="health-component-title">
              <Translate id={`system-health-${data.name}`} />
            </div>
            {data.version && (
              <div className="health-component-version">
                <Translate id="version" />: {data.version}
              </div>
            )}
          </div>
          <Button
            className="health-component-details-button"
            variant="link"
            onClick={() => setSelectedComponent(data.name)}
          >
            <FontAwesomeIcon icon={faFileAlt} /> Logs
          </Button>
        </div>
      )}
    </>
  );
};

export default SystemHealthComponent;
