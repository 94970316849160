import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OptionTypeBase } from 'react-select';
import { ValueType } from 'react-select/src/types';
import { OptionProps } from 'react-select/src/components/Option';
import { AppState } from '../../../state/reducers/rootReducer';
import { PageKeys } from '../../../state/reducers/filterSortReducer';
import { setFilter } from '../../../state/filter/set-filter-action-creator';
import { FilterFieldKeys } from '../../Filter/filter-field-keys';
import { AsyncBaseSelect } from './AsyncBaseSelect';
import { InputContextType } from '../../Input/input-context-type';

export interface AsyncSelectWrapperForFiltersProps<OptionType extends OptionTypeBase> {
  translationId: string;
  filterFieldKey: FilterFieldKeys;

  pageKey: PageKeys;
  loadOptions: (inputValue: string) => Promise<OptionType[]>;

  placeholderTranslationId?: string;
  placeholderPrefix?: string;

  option?: (props: OptionProps<OptionType>) => React.ReactElement;

  getLabel?: (option: OptionType) => string;
  getValue?: (option: OptionType) => any;

  className?: string;
  inputContextType?: InputContextType;
  isMulti?: boolean;
  showDefaultOptions?: boolean;
  hasClearIndicator?: boolean;
}

export const AsyncSelectWrapperForFilters = <OptionType extends OptionTypeBase> (props: AsyncSelectWrapperForFiltersProps<OptionType>) => {
  const {
    translationId,
    filterFieldKey,
    pageKey,
    loadOptions,

    getLabel,
    getValue,

    placeholderTranslationId,
    placeholderPrefix,

    className= 'filterbar__item',
    inputContextType = InputContextType.FILTER_BAR,
    isMulti= true,
    hasClearIndicator = false,
    showDefaultOptions= false,
  } = props;

  const filter = useSelector((state: AppState) => state.filters.filter);
  // @ts-ignore The 'any' type in the global filter must be refactored
  const selectedOptions: OptionType[] = filterFieldKey in filter[pageKey] ? filter[pageKey][filterFieldKey] : [];

  const dispatch = useDispatch();

  const handleSelectionChange = async (options: ValueType<OptionType>) => {
    dispatch(
      setFilter({
        page: pageKey,
        values: {
          [filterFieldKey]: options,
        },
      }),
    );
  };

  return (
    <AsyncBaseSelect<OptionType>
      translationId={ translationId }
      loadOptions={ loadOptions }
      selectedOptions={ selectedOptions }
      onChange={ handleSelectionChange }
      getOptionLabel={ getLabel }
      getOptionValue={ getValue }

      placeholderTranslationId={ placeholderTranslationId }
      placeholderPrefix={ placeholderPrefix }

      className={ className }
      inputContextType={ inputContextType }
      isMulti={ isMulti }
      hasClearIndicator={ hasClearIndicator }
      showDefaultOptions={ showDefaultOptions }
    />
  );
};
