import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValueType, OptionTypeBase } from 'react-select';
import { AppState } from '../../../state/reducers/rootReducer';
import { PageKeys } from '../../../state/reducers/filterSortReducer';
import { setFilter } from '../../../state/filter/set-filter-action-creator';
import { FilterFieldKeys } from '../../Filter/filter-field-keys';
import { BaseSelect } from './BaseSelect';
import { InputContextType } from '../../Input/input-context-type';

export interface SelectWrapperForFiltersProps<OptionType extends OptionTypeBase> {
  translationId: string;
  filterFieldKey: FilterFieldKeys;
  pageKey: PageKeys;
  defaultOptions: OptionType[];
  isMulti?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;

  placeholderTranslationId?: string;
  placeholderPrefix?: string;

  className?: string,
  inputContextType?: InputContextType;
  onlyStoreValues?: boolean;
  translateOptions?: boolean;
}

export const SelectWrapperForFilters = <OptionType extends OptionTypeBase> (props: SelectWrapperForFiltersProps<OptionType>) => {
  const {
    translationId,
    pageKey,
    filterFieldKey,
    defaultOptions,
    isMulti = true,
    isClearable = false,
    isSearchable = true,
    translateOptions,

    placeholderTranslationId,
    placeholderPrefix,

    className= 'filterbar__item',
    inputContextType= InputContextType.FILTER_BAR,
  } = props;

  const filter = useSelector((state: AppState) => state.filters.filter);
  // @ts-ignore The 'any' type in the global filter must be refactored
  const selectedOptions: OptionType[] = filterFieldKey in filter[pageKey] ? filter[pageKey][filterFieldKey] : [];

  const dispatch = useDispatch();

  const handleOptionChange = (values: ValueType<OptionType>) => {
    dispatch(
      setFilter({
        page: pageKey,
        values: {
          [filterFieldKey]: values,
        },
      }),
    );
  };

  return (
    <BaseSelect<OptionType>
      translationId={ translationId }
      defaultOptions={ defaultOptions }
      selectedOptions={ selectedOptions }
      isMulti={ isMulti }
      onChange={ handleOptionChange }

      translateOptions={ translateOptions }

      placeholderTranslationId={ placeholderTranslationId }
      placeholderPrefix={ placeholderPrefix }

      className={ className }
      inputContextType={ inputContextType }

      isClearable={ isClearable }
      isSearchable={ isSearchable }
      showSelectAndDeselectAllButtons={ true }
    />
  );
};
