import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';
import { removeDeviceFromDB } from '../../api/apiHelpers';
import { AppState } from '../reducers/rootReducer';
import useTranslation from '../../hooks/useTranslation';
import { DEVICE_REMOVED, DeviceRemovedAction, REMOVE_DEVICE, RemoveDeviceAction } from './removeDeviceActions';
import { refreshDeviceTree } from './fetch-device-tree/refreshDeviceTreeActionCreator';

export const removeDevice = (
  id: string,
  shouldRefreshDeviceTree = true,
) => async (
  dispatch: Dispatch<any>,
  getState: () => AppState,
): Promise<void> => {
  const translate = useTranslation();

  try {
    dispatch({ type: REMOVE_DEVICE, id } as RemoveDeviceAction);
    const response = await removeDeviceFromDB(id);
    if (!response) {
      return;
    }

    dispatch({ type: DEVICE_REMOVED, id } as DeviceRemovedAction);
    toastr.success(
      translate('success').toString(),
      translate('delete-device-success').toString(),
    );

    if (shouldRefreshDeviceTree) {
      dispatch(refreshDeviceTree());
    }
  } catch (e) {
    console.error(e);
    toastr.error(translate('error').toString(), e);
  }
};
