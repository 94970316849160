import { IUser } from '@wiot/shared-domain/models/user/user';
import { SAVE_USERS, TOGGLE_SELECT_ALL_USERS, TOGGLE_SELECT_USER } from '../constants/actionTypes';
import { UsersActionTypes } from '../types';

export const saveUsers = (users: IUser[] = []): UsersActionTypes => ({
  type: SAVE_USERS,
  payload: users,
});

export const toggleSelectAllUsers = (isChecked: boolean): UsersActionTypes => ({
  type: TOGGLE_SELECT_ALL_USERS,
  payload: isChecked,
});

export const toggleSelectUser = (userId: string): UsersActionTypes => ({
  type: TOGGLE_SELECT_USER,
  payload: userId,
});
