import { ObjectId } from 'bson';
import { FileLocation, IFileLocation } from '../file/file-location';
import { generateDefaultTranslations, ITranslation } from '../localization/translation';
import { MBusDeviceType } from './m-bus-device-types';
import { IDeviceRole } from '@wiot/shared-domain/models/role/role';


export interface IDeviceType {
  id: string;
  name: ITranslation[];
  mBusDeviceTypeId?: MBusDeviceType;
  isSystem: boolean;
  isActive: boolean;
  iconLocation?: IFileLocation;
}

export type DeviceTypeWithPermission = IDeviceType & { devicePermission: IDeviceRole };

export interface TestDeviceType extends IDeviceType {
  _id: ObjectId;
}

function getIconInitialLocation(mBusDeviceTypeId: MBusDeviceType): IFileLocation | undefined {
  const assetPathDevices = '/assets/devices/';

  switch (mBusDeviceTypeId) {
    case MBusDeviceType.COLD_WATER_METER:
    case MBusDeviceType.HOT_WATER_METER:
    case MBusDeviceType.WARM_WATER_METER:
    case MBusDeviceType.WATER_METER:
      return new FileLocation(assetPathDevices, 'water', 'svg');
    case MBusDeviceType.GATEWAY:
      return new FileLocation(assetPathDevices, 'gateway', 'svg');
    case MBusDeviceType.GAS_METER:
      return new FileLocation(assetPathDevices, 'gas', 'svg');
    case MBusDeviceType.SMOKE_DETECTOR:
      return new FileLocation(assetPathDevices, 'smoke', 'svg');
    case MBusDeviceType.UNIDIRECTION_REPEATER:
    case MBusDeviceType.RADIO_CONVERTER_METER_SIDE:
      return new FileLocation(assetPathDevices, 'repeater', 'svg');
    case MBusDeviceType.HEAT_METER:
    case MBusDeviceType.HEAT_COOLING_METER:
    case MBusDeviceType.HEAT_METER_INLET:
    case MBusDeviceType.COOLING_METER_INLET:
    case MBusDeviceType.COOLING_METER_OUTLET:
      return new FileLocation(assetPathDevices, 'heating', 'svg');
    case MBusDeviceType.HEAT_COST_ALLOCATOR:
      return new FileLocation(assetPathDevices, 'warmth', 'svg');
    case MBusDeviceType.OTHER:
      return new FileLocation(assetPathDevices, 'other', 'svg');
    default:
      return undefined;
  }
}

/**
 * Gets the translation for a name of a device.
 *
 * @param mBusDeviceTypeId The M-Bus identifier of the device type.
 *
 * @example Translation of M-Bus device type `MBusDeviceType.WATER_METER`:
 * [
 *   { languageCode: 'en', value: 'Water meter' },
 *   { languageCode: 'de', value: 'Wasserzähler' },
 * ]
 *
 * @returns The translation for a name of a M-Bus device.
 */
function getInitialDeviceTypeNameTranslation(mBusDeviceTypeId: MBusDeviceType): ITranslation[] {
  switch (mBusDeviceTypeId) {
    case MBusDeviceType.GAS_METER:
      return generateDefaultTranslations('Gaszähler', 'Gas meter');
    case MBusDeviceType.WARM_WATER_METER:
      return generateDefaultTranslations('Warmwasserzähler', 'Warm water meter');
    case MBusDeviceType.WATER_METER:
      return generateDefaultTranslations('Wasserzähler', 'Water meter');
    case MBusDeviceType.HOT_WATER_METER:
      return generateDefaultTranslations('Heißwasserzähler', 'Hot water meter');
    case MBusDeviceType.COLD_WATER_METER:
      return generateDefaultTranslations('Kaltwasserzähler', 'Cold water meter');
    case MBusDeviceType.HEAT_COOLING_METER:
      return generateDefaultTranslations('Wärme-/Kältezähler', 'Heat/cooling meter');
    case MBusDeviceType.COOLING_METER_INLET:
      return generateDefaultTranslations('Kältezähler (Vorlauf)', 'Cooling meter (inlet)');
    case MBusDeviceType.COOLING_METER_OUTLET:
      return generateDefaultTranslations('Kältezähler', 'Cooling meter');
    case MBusDeviceType.HEAT_METER:
      return generateDefaultTranslations('Wärmezähler', 'Heat meter');
    case MBusDeviceType.HEAT_METER_INLET:
      return generateDefaultTranslations('Wärmezähler (Vorlauf)', 'Heat meter (inlet)');
    case MBusDeviceType.HEAT_COST_ALLOCATOR:
      return generateDefaultTranslations('Heizkostenverteiler', 'Heat cost allocator');
    case MBusDeviceType.SMOKE_DETECTOR:
      return generateDefaultTranslations('Rauchwarnmelder', 'Smoke detector');
    case MBusDeviceType.UNIDIRECTION_REPEATER:
      return generateDefaultTranslations('Unidirektionaler Repeater', 'Uni-directional repeater');
    case MBusDeviceType.RADIO_CONVERTER_METER_SIDE:
      return generateDefaultTranslations('Funkwandler (zählerseitig)', 'Radio converter (meterside)');
    case MBusDeviceType.GATEWAY:
      return generateDefaultTranslations(
        'Gateway',
        'Gateway',
      );
    case MBusDeviceType.ROOM_SENSOR_TEMP_HUM:
      return generateDefaultTranslations('Temp./Feuchtigkeitssensor', 'Temp./Humidity sensor');
    case MBusDeviceType.OTHER:
      return generateDefaultTranslations('Sonstige', 'Other');
    case MBusDeviceType.UNKNOWN:
      return generateDefaultTranslations('Unbekannter Gerätetyp', 'Unknown device type');
    default:
      return generateDefaultTranslations(
        MBusDeviceType[mBusDeviceTypeId],
        MBusDeviceType[mBusDeviceTypeId],
      );
  }
}

function getInitialActiveState(mBusDeviceTypeId: MBusDeviceType): boolean {
  switch (mBusDeviceTypeId) {
    case MBusDeviceType.HEAT_METER:
    case MBusDeviceType.HEAT_METER_INLET:
    case MBusDeviceType.HEAT_COOLING_METER:
    case MBusDeviceType.COOLING_METER_INLET:
    case MBusDeviceType.COOLING_METER_OUTLET:
    case MBusDeviceType.WATER_METER:
    case MBusDeviceType.WARM_WATER_METER:
    case MBusDeviceType.HOT_WATER_METER:
    case MBusDeviceType.COLD_WATER_METER:
    case MBusDeviceType.HEAT_COST_ALLOCATOR:
    case MBusDeviceType.SMOKE_DETECTOR:
    case MBusDeviceType.UNIDIRECTION_REPEATER:
    case MBusDeviceType.RADIO_CONVERTER_METER_SIDE:
    case MBusDeviceType.GATEWAY:
    case MBusDeviceType.OTHER:
      return true;
    default:
      return false;
  }
}

function isSystemDefined(mBusDeviceTypeId: MBusDeviceType): boolean {
  switch (mBusDeviceTypeId) {
    case MBusDeviceType.HEAT_METER:
    case MBusDeviceType.HEAT_METER_INLET:
    case MBusDeviceType.HEAT_COOLING_METER:
    case MBusDeviceType.COOLING_METER_INLET:
    case MBusDeviceType.COOLING_METER_OUTLET:
    case MBusDeviceType.WATER_METER:
    case MBusDeviceType.GAS_METER:
    case MBusDeviceType.WARM_WATER_METER:
    case MBusDeviceType.HOT_WATER_METER:
    case MBusDeviceType.COLD_WATER_METER:
    case MBusDeviceType.HEAT_COST_ALLOCATOR:
    case MBusDeviceType.SMOKE_DETECTOR:
    case MBusDeviceType.UNIDIRECTION_REPEATER:
    case MBusDeviceType.RADIO_CONVERTER_METER_SIDE:
    case MBusDeviceType.GATEWAY:
    case MBusDeviceType.UNKNOWN:
    case MBusDeviceType.OTHER:
      return true;
    default:
      return false;
  }
}

/**
 * Gets the name and value from an M-Bus device type.
 *
 * @param mBusDeviceTypeId The ID of the M-Bus device type.
 *
 * @return The name and value from an M-Bus device type.
 */
export function getNameAndValueOfMBusDeviceType(
  mBusDeviceTypeId: MBusDeviceType,
): { name: ITranslation[]; mBusDeviceTypeId?: number } {
  const deviceType: IDeviceType = generateDeviceTypeFromMBusDeviceTypeId(mBusDeviceTypeId);

  return {
    name: deviceType.name,
    mBusDeviceTypeId: deviceType.mBusDeviceTypeId,
  };
}

export function generateDeviceTypeFromMBusDeviceTypeId(
  mBusDeviceTypeId: MBusDeviceType,
): IDeviceType {
  return {
    id: '',
    name: getInitialDeviceTypeNameTranslation(mBusDeviceTypeId),
    mBusDeviceTypeId,
    iconLocation: getIconInitialLocation(mBusDeviceTypeId),
    isActive: getInitialActiveState(mBusDeviceTypeId),
    isSystem: true,
  };
}

/**
 * Generate all available system defined device types.
 * @returns All available system defined device types.
 */
export function generateSystemDefinedDeviceTypes(): IDeviceType[] {
  return Object.values(MBusDeviceType)
    .filter(
      mBusDeviceTypeId =>
        typeof mBusDeviceTypeId === 'number' && isSystemDefined(mBusDeviceTypeId),
    )
    .map(mBusDeviceTypeId => generateDeviceTypeFromMBusDeviceTypeId(mBusDeviceTypeId as number));
}

export const isMBusDeviceTypeWithConsumptionData = (mBusDeviceTypeId?: MBusDeviceType) => (
  mBusDeviceTypeId !== MBusDeviceType.SMOKE_DETECTOR
  && mBusDeviceTypeId !== MBusDeviceType.GATEWAY
  && mBusDeviceTypeId !== MBusDeviceType.UNIDIRECTION_REPEATER
  && mBusDeviceTypeId !== MBusDeviceType.RADIO_CONVERTER_METER_SIDE
);

export const isHeatCostAllocator = (mBusDeviceTypeId?: number) => (mBusDeviceTypeId === MBusDeviceType.HEAT_COST_ALLOCATOR);
