import React, { useEffect } from 'react';
import { Translate } from 'react-localize-redux';
import onClickOutside from 'react-onclickoutside';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
  CombinedDataIntegrationFormats,
  DataIntegrationJobExtended,
} from '@wiot/shared-domain/models/data-integration/data-integration';
import { OutsideClickHandler } from '../../../components/OutsideClickHandler';
import { AppState } from '../../../state/reducers/rootReducer';
import HasPermission from '../../../components/HasPermission';
import RenderOnCondition from '../../../components/RenderOnCondition';
import { closeContextMenu, openContextMenu } from '../../../state/context-menu/toggleContextMenuActionCreators';

export interface TableMenuProps {
  removeUnit: (id: string) => void;
  menuId: string;
  dataIntegrationJob: DataIntegrationJobExtended;
  fetchDataIntegrationJobs: () => void;
  toggleDataIntegrationActionModal: () => void;
  toggleDeleteModal: () => void;
}

const editableDataIntegrationJobs = [
  CombinedDataIntegrationFormats.FTP,
  CombinedDataIntegrationFormats.MyDatanet,
  CombinedDataIntegrationFormats.CasametaCloud,
  CombinedDataIntegrationFormats.LoraTTN,
];

const TableMenu = (props: TableMenuProps) => {
  const { menuId, toggleDataIntegrationActionModal, toggleDeleteModal, dataIntegrationJob } = props;

  const userPermission = useSelector<AppState>((state) => state.currentUser.permission);

  const tdRef = React.createRef<HTMLDivElement>();

  const dispatch = useDispatch();

  const closeMenu = (event?: React.MouseEvent) => {
    event && event.preventDefault();
    dispatch(closeContextMenu());
  }

  useEffect(() => {
    dispatch(openContextMenu(menuId));
  }, [])

  const handleClickOutside = (event: React.MouseEvent) => {
    closeMenu(event);
  };

  const handleMenuButtonClick = (event: React.MouseEvent, action: () => void) => {
    closeMenu(event);
    action();
  };

  return (
    <OutsideClickHandler onClickOutside={ handleClickOutside }>
      <div
        className="td-menu"
        ref={ tdRef }
      >
        <RenderOnCondition
          condition={
            dataIntegrationJob.class &&
            editableDataIntegrationJobs.includes(
              dataIntegrationJob.class as CombinedDataIntegrationFormats,
            )
          }
        >
          <HasPermission permissionObj={ userPermission } permissionKey="dataIntegration.edit">
            <button
              className="td-menu__item"
              onClick={ (e) => handleMenuButtonClick(e, toggleDataIntegrationActionModal) }
            >
              <div className="td-menu__item__icon">
                <FontAwesomeIcon icon={ faEdit } />
              </div>
              <Translate id="update" />
            </button>
          </HasPermission>
        </RenderOnCondition>

        <HasPermission permissionObj={ userPermission } permissionKey="dataIntegration.remove">
          <button
            className="td-menu__item"
            onClick={ (e) => handleMenuButtonClick(e, toggleDeleteModal) }
          >
            <div className="td-menu__item__icon">
              <FontAwesomeIcon icon={ faTrashAlt } />
            </div>
            <Translate id="remove" />
          </button>
        </HasPermission>
      </div>
    </OutsideClickHandler>
  );
};

export default onClickOutside(TableMenu);
