import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';

// this method is called so that the device group values can be accepted in the backend
export const normaliseDeviceGroup = (deviceGroup: DeviceGroup) => {
  const normalisedDeviceGroup = {
    ...deviceGroup,
  };

  // pass parent as an id and not an object
  normalisedDeviceGroup.parent = normalisedDeviceGroup.parent && normalisedDeviceGroup.parent.id;

  // need to delete fields that cannot be changed, such as
  // devices, children and ancestors
  delete normalisedDeviceGroup.deviceList;
  delete normalisedDeviceGroup.ancestors;
  delete normalisedDeviceGroup.hasChildren;
  delete normalisedDeviceGroup.children;
  delete normalisedDeviceGroup.users;
  // @ts-ignore
  delete normalisedDeviceGroup.permission;

  return normalisedDeviceGroup;
};
