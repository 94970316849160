import { Dispatch } from 'redux';
import { SERVER_FRONTEND_VERSION_FETCHED } from '../constants/actionTypes';
import { ServerFrontendVersionFetchedAction } from '../types';
import { authenticatedInstance as httpClientInstance } from './../../api/apiHelpers';

export const serverFrontendVersionFetched = (
  version: string,
): ServerFrontendVersionFetchedAction => ({
  type: SERVER_FRONTEND_VERSION_FETCHED,
  version,
});

export const getServerFrontendVersion = () => async (dispatch: Dispatch<any>): Promise<void> => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  const { data: serverFrontendVersion } = await httpClientInstance.get<string>('/systemHealth/serverFrontendVersion');
  if (serverFrontendVersion) {
    dispatch(serverFrontendVersionFetched(serverFrontendVersion));
  }
};
