import { Property, PropertyMeasurementFrequency } from '@wiot/shared-domain/domain/property/property';
import { Dispatch } from 'redux';
import { authenticatedInstance as httpClientInstance, handleApiError } from '../../api/apiHelpers';
import { FETCH_PROPERTY, FetchPropertyAction, PROPERTY_FETCHED, PropertyFetchedAction } from './propertyViewActions';
import { buildQueryString } from '../../utils/common';
import { DispatchActionTypes } from '../types';
import { PropertyConsumptionType } from '@wiot/shared-domain/domain/property/property-consumption-type';

export const propertyFetched = (property: Property): PropertyFetchedAction => ({
  type: PROPERTY_FETCHED,
  property,
});

export const fetchProperty = (
  propertyId: string,
  startDate: string,
  billingFrequency: PropertyMeasurementFrequency,
  consumptionType: PropertyConsumptionType,
  includeDescendants: boolean,
  ) =>
  async (dispatch: Dispatch<DispatchActionTypes>): Promise<void> => {
    dispatch({
      type: FETCH_PROPERTY,
      propertyId,
      consumptionType,
    } as FetchPropertyAction);

    const qs = buildQueryString({
      startDate: `"${ startDate }"`,
      consumptionType,
      billingFrequency,
      includeDescendants,
    });
    const url = `/properties/${ propertyId }?${ qs }`;
    try {
      const { data } = await httpClientInstance.get<Property>(url);

      dispatch(propertyFetched(data));
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  };
