import * as React from 'react';
import { Translate } from 'react-localize-redux';
import {
  BlobWithPath,
  DataIntegrationApiFormats, DataIntegrationConfigs,
  DataIntegrationFormats, DataIntegrationJobExtended
} from '@wiot/shared-domain/models/data-integration/data-integration';
import { SetFieldValue } from '../../../../state/types';
import { IntegrationModalState } from '../IntegrationActionModal';
import CsvImportButton from '../Csv/CsvImportButton';
import XmlImportButton from '../Xml/XmlImportButton';

interface GetActionButtonProps {
  values: IntegrationModalState,
  setFieldValue: SetFieldValue,

  updateTableAndClose: () => void,
  testApiConnection: (api: DataIntegrationJobExtended) => Promise<void>,
  onSubmit: (values: IntegrationModalState) => void,

  isApiValid?: boolean,
  isCsvValid?: boolean,
  addUnit?: boolean,

  acceptedCsvFile?: string | BlobWithPath,
  acceptedXmlFile?: BlobWithPath,
}

export const ActionButton = (props: GetActionButtonProps): JSX.Element => {
  const {
    values,
    setFieldValue,
    updateTableAndClose,
    testApiConnection,
    onSubmit,
    isApiValid,
    addUnit,

    isCsvValid,
    acceptedCsvFile,
    acceptedXmlFile,
  } = props;
  const { api, selectedOption} = values;

  const dataIntegrationConfig = DataIntegrationConfigs[selectedOption as DataIntegrationApiFormats];
  const shouldTest = !!api.active && !isApiValid && !!dataIntegrationConfig.isTestable;

  // eslint-disable-next-line no-nested-ternary
  const translationId = (shouldTest ? 'do-test' : addUnit ? 'add' : 'save');

  const onSubmitButtonClick = () => {
    if (shouldTest) {
      testApiConnection(api)
      return;
    }
    onSubmit(values)
  }

  switch (selectedOption) {
    case DataIntegrationFormats.CSV:
      return (
        <CsvImportButton
          isCSVValid={ isCsvValid }
          acceptedCsvFile={ acceptedCsvFile }
          updateTableAndClose={ updateTableAndClose }
        />
      );
    case DataIntegrationFormats.XML:
      return (
        <XmlImportButton
          formikValues={ values }
          setFieldValue={ setFieldValue }
          acceptedXmlFile={ acceptedXmlFile }
          updateTableAndClose={ updateTableAndClose }
        />
      );
    case DataIntegrationApiFormats.MyDatanet:
    case DataIntegrationApiFormats.CasametaCloud:
    case DataIntegrationApiFormats.LoraTTN:
    default:
      return (
        <button
          className="form__button--blue background-color-main text-color-white border-color-main"
          type="button"
          onClick={ onSubmitButtonClick }
        >
          <Translate id={ translationId }
          />
        </button>
      );
  }
};
