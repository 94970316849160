import { ProtectionDetails } from '@wiot/shared-domain/models/device-key/protection-details';
import { DeviceKeyProtectionDetailsFetchedAction } from '../../types';
import { DEVICE_KEY_PROTECTION_INFO_FETCHED } from '../deviceKeyActionTypes';

export type DeviceKeyProtectionDetailsState = {
  protectionDetails: ProtectionDetails;
};

const initialState: DeviceKeyProtectionDetailsState = {
  protectionDetails: {
    isProtected: false,
    isProtectedByDevicePassword: false,
    isDeviceGroup: false,
    hasCurrentUserAccessToKey: false,
    name: undefined,
    deviceSerialNumber: undefined,
    assigneeMustBeValidated: false,
  },
};

export const protectionDetailsReducer = (
  state: DeviceKeyProtectionDetailsState = { ...initialState },
  action: DeviceKeyProtectionDetailsFetchedAction,
): DeviceKeyProtectionDetailsState => {
  switch (action.type) {
    case DEVICE_KEY_PROTECTION_INFO_FETCHED:
      return {
        ...state,
        protectionDetails: action.protectionDetails,
      };

    default:
      return state;
  }
};
