import * as React from 'react';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { Consumption } from '@wiot/shared-domain/domain/property/consumption';
import { IDeviceType, isHeatCostAllocator } from '@wiot/shared-domain/models/device-types/device-types';
import { getDeviceTypeIconFullPath, getTranslationValueInCurrentLanguage } from '../../../../utils/common';
import { IconVariant } from '../../../../constants';
import TableDataRowTooltip from '../../../../components/TableDataRowTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown, faArrowCircleUp, faChartLine, faChartPie, faEquals } from '@fortawesome/free-solid-svg-icons';
import { FilterSelectButton } from './FilterSelectButton';
import { PROPERTY_VIEW_PAGE_KEY } from '@wiot/shared-domain/domain/property/property-view-page-key';
import { PROPERTY_VIEW_CONSUMPTION_CONSUMPTION_ID, PROPERTY_VIEW_CONSUMPTION_PIE_CHART_ID } from '../property-view-constants';
import { AppState } from '../../../../state/reducers/rootReducer';
import RenderOnCondition from '../../../../components/RenderOnCondition';
import { getPercentage } from '../../../../utils/number';
import { selectPageFilters } from '../../../../state/filter/filterSelectors';
import { selectProperty, selectPropertyConsumptionUnit } from '../../../../state/property-view/propertySelectors';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import Mobile from '../../../../components/Responsive/Mobile';
import classNames from 'classnames';
import useTranslation from '../../../../hooks/useTranslation';
import { PropertyConsumptionType } from '@wiot/shared-domain/domain/property/property-consumption-type';
import { MeasurementFrequency } from '@wiot/shared-domain/models/device-reading/measurement';

type ConsumptionRowProps = {
  deviceType: IDeviceType;
  consumption: Consumption;
  pieChartButtonClick?: () => void;
  consumptionButtonClick?: () => void;
  isHeaderRow?: boolean;
};

const IconsComparingConsumption = {
  '': <FontAwesomeIcon key='faIcon-equal' icon={faEquals} />,
  '+': <FontAwesomeIcon key='faIcon-positive' icon={faArrowCircleUp} color='red' />,
  '-': <FontAwesomeIcon key='faIcon-negative 'icon={faArrowCircleDown} color='green' />,
};

export const ConsumptionRow = (props: ConsumptionRowProps) => {
  const {
    deviceType,
    consumption: currentConsumption,
    pieChartButtonClick,
    consumptionButtonClick,
    isHeaderRow = false,
  } = props;
  const { mBusDeviceTypeId, id, name } = deviceType;

  const filter = useSelector((state: AppState) => selectPageFilters(state, PROPERTY_VIEW_PAGE_KEY));

  const translate = useTranslation();

  const consumptionUnit = useSelector(selectPropertyConsumptionUnit);
  const property = useSelector((state: AppState) => selectProperty(state));

  if (!property || !mBusDeviceTypeId) {
    return null;
  }

  const { billingPeriodStartDate, billingFrequency, consumptionType } = filter;

  const deviceTypeIconSrc = getDeviceTypeIconFullPath(deviceType, IconVariant.small);

  const shouldCalculateConsumptionDifference = !isHeatCostAllocator(mBusDeviceTypeId) || moment(billingPeriodStartDate).month() !== 0;

  const getCurrentConsumption = () => {
    const { currentPeriodValue, lastPeriodValue } = currentConsumption;
    if (shouldCalculateConsumptionDifference) {
      return currentPeriodValue - lastPeriodValue;
    }
    return currentPeriodValue;
  };

  function getPercentageStringWithIcon(percentageComparedToLastPeriod: number): JSX.Element {
    // eslint-disable-next-line no-nested-ternary
    const arithmeticOperand = percentageComparedToLastPeriod === 0 ? '' : percentageComparedToLastPeriod > 0 ? '+' : '-';
    const percentageString = `${ arithmeticOperand } ${ String(percentageComparedToLastPeriod).replace('-', '') } %`;
    const icon = IconsComparingConsumption[arithmeticOperand];

    return <>
      { icon } { percentageString }
    </>;
  }

  const getConsumptionComparedToLastPeriod = (consumption: Consumption): JSX.Element | null => {
    const { currentPeriodValue, lastPeriodValue, secondLastPeriodValue } = consumption;
    let percentageComparedToLastPeriod;
    if (shouldCalculateConsumptionDifference) {
      const currentPeriodDifference = currentPeriodValue - lastPeriodValue;
      const lastPeriodDifference = lastPeriodValue - secondLastPeriodValue;
      percentageComparedToLastPeriod = getPercentage(currentPeriodDifference, lastPeriodDifference);
    } else {
      percentageComparedToLastPeriod = getPercentage(currentPeriodValue, lastPeriodValue);
    }

    if (!isFinite(percentageComparedToLastPeriod)) {
      return null;
    }

    return getPercentageStringWithIcon(percentageComparedToLastPeriod);
  };

  const averageConsumptions = property.propertySettings.averageConsumptionsByMonths?.[consumptionType];

  const getConsumptionComparedToAverage = (): JSX.Element | null => {
    if (!isHeaderRow || !averageConsumptions) {
      return null;
    }

    let averageConsumption: number;

    if (billingFrequency === MeasurementFrequency.DAY) {
      averageConsumption = averageConsumptions[moment(billingPeriodStartDate).month()];
    } else {
      averageConsumption = averageConsumptions.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);
    }

    const currentPeriodDifference = currentConsumption.currentPeriodValue - currentConsumption.lastPeriodValue;

    const percentageComparedToAverage = getPercentage(currentPeriodDifference, averageConsumption);

    if (!isFinite(percentageComparedToAverage)) {
      return null;
    }

    return getPercentageStringWithIcon(percentageComparedToAverage);
  };

  const getHeaderTranslation = () => {
    if (consumptionType === PropertyConsumptionType.WATER) {
      return translate('overall-water-consumption');
    }

    return translate('overall-energy-consumption');
  }

  const currentAbsoluteConsumption = getCurrentConsumption().toFixed(2);
  const consumptionComparedToLastPeriod = getConsumptionComparedToLastPeriod(currentConsumption);

  return (
    <Row className="property-view__consumption__table-row mb-3">
      <Col
        lg={1}
        md={1}
        sm={1}
        xs={2}
      >
        <img
          className={classNames({ 'pl-3': !isHeaderRow })}
          data-testid="device-manager__table__table__trow__device-type-icon"
          data-tip="device-type-icon"
          data-for={ id }
          src={ deviceTypeIconSrc }
          alt="device type"
        />
        <TableDataRowTooltip id={ id }>
          { getTranslationValueInCurrentLanguage(name) }
        </TableDataRowTooltip>
      </Col>

      <Col
        lg={2}
        md={11}
        sm={11}
        xs={10}
        className={classNames('property-view__consumption__table-row-device-type', { 'pl-4': !isHeaderRow })}
      >
        { isHeaderRow ? getHeaderTranslation() : getTranslationValueInCurrentLanguage(name) }
      </Col>

      <Mobile>
        <Col
          md={6}
          sm={6}
          xs={8}
          className="property-view__consumption__table-row-label"
        >
          <Translate id="current-consumption" />
        </Col>
      </Mobile>

      <Col
        lg={2}
        md={6}
        sm={6}
        xs={4}
        className="property-view__consumption__table-row-value"
      >
        {currentAbsoluteConsumption}
        {' '}
        {consumptionUnit}
      </Col>

      <Mobile>
        <Col
          md={6}
          sm={6}
          xs={8}
          className="property-view__consumption__table-row-label"
        >
          <Translate id={ `to-last-${ billingFrequency }` } />
        </Col>
      </Mobile>

      <Col
        lg={2}
        md={6}
        sm={6}
        xs={4}
        className="property-view__consumption__table-row-value"
      >
        {consumptionComparedToLastPeriod}
      </Col>

      <RenderOnCondition condition={averageConsumptions}>
        <Mobile>
          <Col
            md={6}
            sm={7}
            xs={8}
          >
            <Translate id="to-average-user" />
          </Col>
        </Mobile>

        <Col
          lg={3}
          md={6}
          sm={5}
          xs={4}
          className="property-view__consumption__table-row-value"
        >
          {getConsumptionComparedToAverage()}
        </Col>
      </RenderOnCondition>

      <Col
        lg={1}
        className="d-inline-flex property-view__consumption__table-row-last"
      >
        <RenderOnCondition condition={!isHeaderRow}>
          <FilterSelectButton
            mBusDeviceTypeId={mBusDeviceTypeId}
            icon={faChartPie}
            pageKey={PROPERTY_VIEW_PAGE_KEY}
            stateField={PROPERTY_VIEW_CONSUMPTION_PIE_CHART_ID}
            onClick={pieChartButtonClick}
          />
          <FilterSelectButton
            mBusDeviceTypeId={mBusDeviceTypeId}
            icon={faChartLine}
            pageKey={PROPERTY_VIEW_PAGE_KEY}
            stateField={PROPERTY_VIEW_CONSUMPTION_CONSUMPTION_ID}
            onClick={consumptionButtonClick}
          />
        </RenderOnCondition>
      </Col>
    </Row>
  );
};
