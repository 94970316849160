import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Dispatch } from 'redux';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { NotAssignedLabel } from '../../../components/NotAssignedLabel';
import TableDataRowTooltip from '../../../components/TableDataRowTooltip';
import DeviceGroupPathCell from '../../../components/Table/DeviceGroupPathCell';
import moment from 'moment';
import { connect } from 'react-redux';
import { ITranslation } from '@wiot/shared-domain/models/localization/translation';
import { IDeviceRole } from '@wiot/shared-domain/models/role/role';
import { DeviceMetadataFields, IColumnObject } from '@wiot/shared-domain/models/device/device';
import Portal from '../../../components/shared/Portal';
import ConfirmModal, { ConfirmationVariant } from '../../../components/Modal/ConfirmModal';
import { DeviceExtended } from '../../../state/types';
import DeviceDetailsModal from '../DeviceDetailsModal/DeviceDetailsModal';
import QUESTION_MARK from '../../../assets/devices/question-mark-small.svg';
import { IconVariant, sourceTypeIconSmall } from '../../../constants';
import { PortalMenu } from '../../../components/PortalMenu/PortalMenu';
import {
  getDetailedGatewayIconSource,
  getDeviceTypeIconFullPath,
  getSourceTypeName,
  getTranslationValueInCurrentLanguage,
  hasPermission,
  localizeDate,
} from '../../../utils/common';
import { getDeviceGroupPathOfDevice } from '../../../utils/device-group-path-helper';
import { fetchDeviceFromDB } from '../../../api/apiHelpers';
import RenderOnCondition from '../../../components/RenderOnCondition';
import { AppState } from '../../../state/reducers/rootReducer';
import DeviceActionModal from '../DeviceActionModal/DeviceActionModal';
import DeviceStatusPil from '../DeviceStatusPil';
import CustomCheckbox from '../../../components/shared/CustomCheckbox';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { getSelectedMetaDataValue } from '../DeviceActionModal/metaDataUtilityFunctions';
import AddManualDeviceReadingModal from '../AddManualDeviceReadingModal';
import TableMenu from './TableMenu';
import { HORIZONTAL_OFFSET_TABLE_VIEW, VERTICAL_OFFSET_TABLE_VIEW } from '../../../components/PortalMenu/constants/offset-table-view';
import { getMenuId } from '../../../components/PortalMenu/constants/context-menu-ids';
import { closeContextMenu, openContextMenu } from '../../../state/context-menu/toggleContextMenuActionCreators';
import { toggleContextMenuActionTypes } from '../../../state/context-menu/toggleContextMenuAction';
import { FormattedDeviceReadingValue } from '../../../components/shared/FormattedDeviceReadingValue';
import LoadingIcon from '../../../components/shared/LoadingIcon';

export interface TableDataRowProps extends LocalizeContextProps, RouteComponentProps {
  device: DeviceExtended;
  markOneDeviceAsSelected?: (id: string) => void;
  removeUnit: (id: string) => Promise<void>;
  withCheckBox?: boolean;
  refreshDevices?: () => void;
  showFullTimestamp: boolean | undefined;
  columns: IColumnObject[];
  isKeyManagerModeEnabled: boolean;
  isLoadingDeviceReading: boolean;
  visibleContextMenu?: string;
  openContextMenu: (id: string) => toggleContextMenuActionTypes;
  closeContextMenu: () => toggleContextMenuActionTypes;
}

export interface TableDataRowState {
  showDetails: boolean;
  permissionLoading: boolean;
  deviceTypeName: ITranslation[];
  toggleMenuButtonBoundingClientRect: DOMRect;
  deviceRole?: IDeviceRole;
  deviceMessageRole?: any;
  showDeviceActionModal: boolean;
  showDuplicateDeviceActionModal: boolean;
  showDeviceActionModalAddSameGroup: boolean;
  showAddManualDeviceReadingModal: boolean;
  showDeleteModal: boolean;
  showDeviceChangeModal: boolean;
  downloadHash?: string;
}


class TableDataRow extends React.Component<TableDataRowProps, TableDataRowState> {
  toggleMenuButton: HTMLDivElement | null;

  constructor(props: TableDataRowProps) {
    super(props);
    this.state = {
      showDetails: false,
      permissionLoading: false,
      deviceTypeName: [],
      toggleMenuButtonBoundingClientRect: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        toJSON: () => null,
      },
      deviceRole: undefined,
      showDeviceActionModal: false,
      showDuplicateDeviceActionModal: false,
      showDeviceActionModalAddSameGroup: false,
      showDeleteModal: false,
      showDeviceChangeModal: false,
      showAddManualDeviceReadingModal: false,
      downloadHash: getSelectedMetaDataValue(DeviceMetadataFields.metadata, 'downloadHash', this.props.device?.deviceMetadata),
    };
  }

  closeMenu = (event?: React.MouseEvent) => {
    event?.stopPropagation();
    this.props.closeContextMenu();
  };

  initDeviceData = () => {
    this.fetchDeviceTypeName();
  };

  componentDidMount = () => {
    this.initDeviceData();
  };

  fetchDeviceTypeName = () => {
    if (this.props.device.deviceType) {
      const { name } = this.props.device.deviceType;
      this.setState({ deviceTypeName: name });
    }
  };

  fetchDeviceRoleAndMessageRole = async (id: string) => {
    if (!this.state.deviceRole || !this.state.deviceMessageRole) {
      this.setState({ permissionLoading: true });

      const device = await fetchDeviceFromDB(id);

      this.setState({
        deviceRole: device.permission,
        deviceMessageRole: device.messageRole,
        permissionLoading: false,
      });
    }
  };

  showDetails = async (id: string) => {
    await this.fetchDeviceRoleAndMessageRole(id);

    if (hasPermission(this.state.deviceRole, 'view', true)) {
      this.setState({
        showDetails: true,
      });
    }
  };

  closeDetails = (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
    event?.stopPropagation();
    this.setState({
      showDetails: false,
    });
  };

  toggleDeviceActionModal = (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState((prevState) => ({
      showDeviceActionModal: !prevState.showDeviceActionModal,
    }));
  };

  toggleDuplicateDeviceActionModal = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState((prevState) => ({
      showDuplicateDeviceActionModal: !prevState.showDuplicateDeviceActionModal,
    }));
  };

  toggleChangeDeviceActionModal = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState((prevState) => ({
      showDeviceChangeModal: !prevState.showDeviceChangeModal,
    }));
  };

  toggleDeviceActionModalAddSameGroup = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState((prevState) => ({
      showDeviceActionModalAddSameGroup: !prevState.showDeviceActionModalAddSameGroup,
    }));
  };

  toggleAddManualDeviceReadingModal = () => {
    this.setState((prevState) => ({
      showAddManualDeviceReadingModal: !prevState.showAddManualDeviceReadingModal,
    }));
  };

  onAddManualDeviceReadingModalClosed = (hasUserAddedOneOrMoreDeviceReadings: boolean) => {
    this.setState(() => ({
      showAddManualDeviceReadingModal: false,
    }));

    if (hasUserAddedOneOrMoreDeviceReadings) {
      this.props.refreshDevices?.();
    }
  };

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }));
  };

  getBoundingClientRect = () => {
    if (this.toggleMenuButton) {
      this.setState({
        toggleMenuButtonBoundingClientRect: this.toggleMenuButton.getBoundingClientRect(),
      });
    }
  };

  render() {
    const {
      withCheckBox = true,
      device,
      markOneDeviceAsSelected,
      refreshDevices,
      showFullTimestamp,
      removeUnit,
      columns,
      isLoadingDeviceReading,
    } = this.props;
    const {
      id,
      deviceId,
      name,
      status,
      checked,
      manufacturer,
      deviceGroup,
      deviceType,
      lastMessage,
      isDeprecated,
    } = device;
    const {
      deviceTypeName,
      showDeviceActionModal,
      showDeleteModal,
      toggleMenuButtonBoundingClientRect,
      permissionLoading,
      showDetails,
      showDeviceActionModalAddSameGroup,
      showDeviceChangeModal,
      showDuplicateDeviceActionModal,
      showAddManualDeviceReadingModal,
      deviceRole,
      deviceMessageRole,
      downloadHash,
    } = this.state;

    const visibleColumns = columns.filter((column) => column.visible);

    const { source } = lastMessage || {};
    const sourceTypeName = getSourceTypeName(source);

    const manufacturerName = manufacturer?.name || '-';
    const deviceGroupPath = getDeviceGroupPathOfDevice(device);

    const deviceTypeIconSrc =
      getDetailedGatewayIconSource(device.protocolType, deviceType, IconVariant.small) ||
      getDeviceTypeIconFullPath(deviceType, IconVariant.small);

    const menuId = getMenuId(id);
    const showMenu = (this.props.visibleContextMenu === menuId);
    const toggleMenu = async () => {
      if (!showMenu) {
        await this.fetchDeviceRoleAndMessageRole(id);
        this.getBoundingClientRect();

        this.props.openContextMenu(menuId);
        return;
      }
      this.closeMenu();
    };

    return (
      <tr
        key={ id }
        id={ id }
        className={ `device-manager__table__table__trow ${ isDeprecated ? 'deprecated-device' : '' }` }
      >
        <RenderOnCondition condition={ withCheckBox }>
          <td>
            <CustomCheckbox
              onChange={ () => markOneDeviceAsSelected && markOneDeviceAsSelected(id) }
              checked={ checked }
            />
          </td>
        </RenderOnCondition>
        { visibleColumns.map((column) => {
          switch (column.name) {
            case 'device-type-icon':
              return (
                <td
                  className="device-manager__table__table__td device-manager__table__table__trow__device-type-icon__wrapper"
                  key={ `device-type-icon-${ id }` }
                >
                  <img
                    data-testid="device-manager__table__table__trow__device-type-icon"
                    data-tip="device-type-icon"
                    data-for={ id }
                    src={ deviceTypeIconSrc }
                    alt="device type"
                  />
                  <TableDataRowTooltip id={ id }>
                    { deviceTypeName ? getTranslationValueInCurrentLanguage(deviceTypeName) : '-' }
                  </TableDataRowTooltip>

                </td>
              );
            case 'device-id':
              return (
                <td
                  className="device-manager__table__table__td device-manager__table__table__link"
                  onClick={ () => this.showDetails(id) }
                  key={ `device-id-${ id }` }
                >
                  { deviceId }
                </td>
              );
            case 'device-name':
              return (
                <td
                  className="device-manager__table__table__td device-manager__table__table__link"
                  onClick={ () => this.showDetails(id) }
                  data-testid="devices-row-name"
                  key={ `device-name-${ id }` }
                >
                  { name || <NotAssignedLabel/> }
                  <RenderOnCondition condition={ showDetails }>
                    <Portal>
                      <DeviceDetailsModal
                        closeModal={ this.closeDetails }
                        removeUnit={ removeUnit }
                        refreshDevices={ refreshDevices }
                        deviceId={ id }
                      />
                    </Portal>
                  </RenderOnCondition>
                </td>
              );
            case 'device-group':
              return (
                <DeviceGroupPathCell
                  key={id}
                  id={id}
                  deviceGroupPath={ deviceGroupPath }
                />
              );
            case 'manufacturer':
              return (
                <td
                  key={ `device-manufacturer-${ id }` }
                  data-tip="dev-mgr_manufacturer"
                  data-for={ `dev-mgr_manufacturer-${ id }` }
                >
                  { manufacturerName }
                  { manufacturerName && (
                    <TableDataRowTooltip id={ `dev-mgr_manufacturer-${ id }` }>
                      { manufacturerName }
                    </TableDataRowTooltip>
                  ) }
                </td>
              );
            case 'last-message':
              return (
                <td
                  key={ `device-last-message-${ id }` }
                  data-tip="dev-mgr_last-msg"
                  data-for={ `dev-mgr_table_last-msg${ id }` }
                >
                  <RenderOnCondition condition={ isLoadingDeviceReading }>
                    <LoadingIcon size={ 20 }/>
                  </RenderOnCondition>
                  { lastMessage ? (
                    <>
                      {/* if lastMsg date = timestamp, show it before the value
                        if lastMsg date = timedelta, show it after
                      */ }
                      { lastMessage.receivedAt && showFullTimestamp && (
                        <span className="device-manager__table__table__last-msg__date">
                          { localizeDate(new Date(lastMessage.receivedAt)) }
                        </span>
                      ) }
                      { lastMessage.receivedAt && !showFullTimestamp && (
                        <span className="device-manager__table__table__last-msg__date">
                          { moment(new Date(lastMessage.receivedAt)).fromNow() }
                        </span>
                      ) }

                      <TableDataRowTooltip
                        id={ `dev-mgr_table_last-msg${ id }`}
                        className={"device-manager__table__table__last-msg__tooltip"}
                        backgroundColor={"#ddd"}
                        themeType={"light"}
                      >
                        <>
                          <img
                            src={
                              (sourceTypeName && sourceTypeIconSmall[sourceTypeName]) || QUESTION_MARK
                            }
                            alt="source type"
                          />{ ' ' }
                          { lastMessage?.receivedAt &&
                            moment(new Date(lastMessage.receivedAt)).fromNow() }
                        </>
                      </TableDataRowTooltip>
                    </>
                  ) : (
                    ''
                  ) }
                </td>
              );
            case 'status':
              return (
                <td key={ `device-status-${ id }` } className="pil-container">
                  { status && status.length > 0 ? (
                    <DeviceStatusPil id={ `table-data-row-device-status-pil-${ id }` } status={ status } tooltipPlace="left"/>
                  ) : (
                    <div></div>
                  ) }
                </td>
              );
            case 'actions':
              return (
                <td key={ `device-actions-${ id }` }>
                  <div className="device-details">
                    <div
                      className="ellipsis text-color-main"
                      role="presentation"
                      ref={ (tc) => {
                        this.toggleMenuButton = tc;
                      } }
                      onClick={ () => toggleMenu() }
                      data-tip="table-actions-button-tooltip"
                      data-for="table-actions-button-tooltip"
                    >
                      <FontAwesomeIcon icon={ faCog } spin={ permissionLoading }/>
                    </div>
                    <TableDataRowTooltip
                      id={"table-actions-button-tooltip"}
                      place={"left"}
                      delayUntilShow={ 100 }
                    >
                      <Translate id="actions" />
                    </TableDataRowTooltip>
                    <RenderOnCondition condition={ showMenu }>
                      <PortalMenu
                        anchorPosition={{
                          left: toggleMenuButtonBoundingClientRect.left + HORIZONTAL_OFFSET_TABLE_VIEW,
                          top: toggleMenuButtonBoundingClientRect.top + window.scrollY + VERTICAL_OFFSET_TABLE_VIEW
                        }}
                        minWidth={ 315 }
                      >
                        <TableMenu
                          removeUnit={ removeUnit }
                          deviceId={ device.id  }
                          menuId={ menuId }
                          deviceGroup={ device.deviceGroup }
                          refreshDevices={ refreshDevices }
                          ancestorPath={ deviceGroupPath.truncatedPath }
                          deviceRole={ deviceRole }
                          deviceMessageRole={ deviceMessageRole }
                          deviceType={ deviceType }
                          showDetails={ this.showDetails }
                          toggleDeviceActionModal={ this.toggleDeviceActionModal }
                          toggleDeleteModal={ this.toggleDeleteModal }
                          toggleDuplicateDeviceActionModal={ this.toggleDuplicateDeviceActionModal }
                          toggleChangeDeviceActionModal={ this.toggleChangeDeviceActionModal }
                          toggleDeviceActionModalAddSameGroup={ this.toggleDeviceActionModalAddSameGroup }
                          toggleAddManualDeviceReadingModal={ this.toggleAddManualDeviceReadingModal }
                          downloadHash={ downloadHash }
                        />
                      </PortalMenu>
                    </RenderOnCondition>
                    <RenderOnCondition condition={ showDeviceActionModal }>
                      <DeviceActionModal
                        closeAddAndUpdateModal={ this.toggleDeviceActionModal }
                        title="update-device"
                        showDeleteButton
                        addUnit={ false }
                        removeUnit={ removeUnit }
                        id={ device.id }
                        refreshDevices={ refreshDevices }
                      />
                    </RenderOnCondition>
                    <RenderOnCondition condition={ showDeleteModal }>
                      <ConfirmModal
                        modalCloseRequested={ () => this.toggleDeleteModal() }
                        actionConfirmed={ () => removeUnit(device.id) }
                        translationIdOfElementType="device"
                        confirmationVariant={ ConfirmationVariant.DELETE }
                      />
                    </RenderOnCondition>
                    <RenderOnCondition condition={ showDuplicateDeviceActionModal }>
                      <DeviceActionModal
                        closeAddAndUpdateModal={ this.toggleDuplicateDeviceActionModal }
                        title="clone-device"
                        showDeleteButton={ false }
                        duplicate
                        addUnit
                        id={ device.id }
                        refreshDevices={ refreshDevices }
                      />
                    </RenderOnCondition>
                    <RenderOnCondition condition={ showDeviceChangeModal }>
                      <Portal>
                        <DeviceDetailsModal
                          closeModal={ this.toggleChangeDeviceActionModal }
                          deviceId={ id }
                          removeUnit={ removeUnit }
                          isChangeModal
                          refreshDevices={ refreshDevices }
                        />
                      </Portal>
                    </RenderOnCondition>
                    <RenderOnCondition condition={ showAddManualDeviceReadingModal }>
                      <AddManualDeviceReadingModal
                        onModalClosed={ this.onAddManualDeviceReadingModalClosed }
                        device={ device }
                      />
                    </RenderOnCondition>
                    <RenderOnCondition condition={ showDeviceActionModalAddSameGroup }>
                      <DeviceActionModal
                        closeAddAndUpdateModal={ this.toggleDeviceActionModalAddSameGroup }
                        title="add-dev-same-group"
                        showDeleteButton={ false }
                        addUnit
                        selectedDeviceGroup={ deviceGroup }
                        refreshDevices={ refreshDevices }
                        disableGroupSelect
                      />
                    </RenderOnCondition>
                  </div>
                </td>
              );
            default:
              return (
                <td key={ `device-${ column.name }-${ id }` }>
                  <FormattedDeviceReadingValue
                    deviceReadingValues={ lastMessage?.values }
                    systemFieldId={ column.name }
                  />
                </td>
              );
          }
        }) }
      </tr>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  showFullTimestamp: state.currentUser.user?.settings?.display?.showFullTimestamp,
  isKeyManagerModeEnabled: !!state.siteSettings.isKeyManagerModeEnabled,
  visibleContextMenu: state.contextMenu.visibleContextMenuId,
  isLoadingDeviceReading: state.isLoadingDeviceReadings,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openContextMenu: (id: string) => dispatch(openContextMenu(id)),
  closeContextMenu: () => dispatch(closeContextMenu()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLocalize(TableDataRow)));
