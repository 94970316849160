import * as React from 'react';
import { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';

import { AccordionGroup} from '../shared/AccordionGroup';

import { AppState } from '../../state/reducers/rootReducer';
import { getRandomModalOffset } from '../../utils/dialog';
import ModalHeader from '../Modal/ModalHeader';
import RenderOnCondition from '../RenderOnCondition';
import { CustomFieldInput } from '../Table/CustomFieldInput';

import useTranslation from '../../hooks/useTranslation';
import { AccordionItem } from '../shared/AccordionItem';
import { closeFeedbackModal, sendFeedback } from '../../state/feedback/feedbackActionCreators';

type FeedbackFormValues = {
  email: string;
  message: string;
};

export function FeedbackModal(): React.ReactElement {
  const currentUser = useSelector((state: AppState) => state.currentUser.user);
  const currentPage = useSelector((state: AppState) => state.currentPageTitle);
  const currentFeedbackAttachment = useSelector(
    (state: AppState) => state.feedback.information.attachment,
  );

  const [randomOffset] = useState(getRandomModalOffset());
  const [modalUid] = useState(uuidv4());

  const dispatch = useDispatch();
  const translate = useTranslation();

  const initialValues: FeedbackFormValues = ({
    email: currentUser?.email || '',
    message: '',
  });

  function handleSubmit( values: FeedbackFormValues) {
    dispatch(sendFeedback({
      emailAddressOfSendingUser: values.email,
      messageOfSendingUser: values.message,
      attachment: {
        ...currentFeedbackAttachment,
        pageKey: currentPage,
      },
    }));
  }

  function closeModal() {
    dispatch(closeFeedbackModal());
  }

  const AttachmentAccordionBody = (
    <div className="feedback__preview feedback__json">
      {JSON.stringify(
        { pageKey: currentPage, ...currentFeedbackAttachment },
        null,
        4,
      )}
    </div>
  );

  const AttachmentAccordionHeader = (<div>
    <Translate id={ 'preview' }/>
  </div>);

  return (
    <div
      tabIndex={ 0 } // make it focusable
      style={ randomOffset }
      id={ `send-feedback-${modalUid}` }
      className="device-modal"
      onClick={ (event: React.MouseEvent) => event.stopPropagation() }
    >
      <ModalHeader
        isDevice={ false }
        titleTranslationId="send-feedback"
        targetId={ modalUid }
        handleClose={ closeModal }
        enableFeedbackSubmission={ false }
      />
      <div className="feedback">
        <Formik
          initialValues={ initialValues }
          validationSchema={
            Yup.object().shape({
              message: Yup.string().required(translate("required-field").toString()),
              email: Yup.string().nullable().email(translate("invalid-email").toString()),
            })
          }
          onSubmit={ handleSubmit }
        >
          {({ values, errors, isValid, setFieldValue }) => (
            <Form className="form text-left" onClick={ (event: React.MouseEvent) => event.stopPropagation() }>
              <div className="form__section">
                <Row>
                  <Col>
                    <CustomFieldInput
                      className="form-select"
                      fieldName="message"
                      translationId="message"
                      value={ values.message }
                      error={ errors.message }
                      isTextarea={ true }
                      rows={ 6 }
                      required={ true }
                    />
                  </Col>
                </Row>
              </div>
              <div className="form__section">
                <Row>
                  <div className="feedback__input">
                    <CustomFieldInput
                      translationId="email-callback"
                      fieldName="email"
                      value={ values.email }
                      error={ errors.email }
                    />
                  </div>
                </Row>
                <Row>
                  <RenderOnCondition condition={ values.email === ''}>
                    <div className="input-error feedback__errortext">
                      <Translate id="missing-email-address-no-exchange-possible" />
                    </div>
                  </RenderOnCondition>
                </Row>
              </div>
              <div className="standard-font-size form__section feedback__accordion">
                <Row>
                  <AccordionGroup>
                    <AccordionItem
                      eventKey="0"
                      header={ AttachmentAccordionHeader }
                      body={ AttachmentAccordionBody }
                    />
                  </AccordionGroup>
                </Row>
              </div>
              <div className="form__section">
                <Row className="feedback__buttons">
                  <button type="button" className="form__button--cancel" onClick={ closeModal }>
                    <Translate id="cancel" />
                  </button>
                  <button
                      className="form__button--blue background-color-main text-color-white border-color-main"
                      type="submit"
                      disabled={ !isValid }
                  >
                    <Translate id={ 'submit' } />
                  </button>
                </Row>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default FeedbackModal;
