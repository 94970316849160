export const USER_NAME_HEADER = 'user-name';
export const EMAIL_HEADER = 'email';
export const VERIFIED_HEADER = 'verified';
export const ROLE_HEADER = 'role';
export const CREATED_HEADER = 'created';
export const ACTIONS_HEADER = 'actions';

export const USER_TABLE_HEADERS = [
  USER_NAME_HEADER,
  EMAIL_HEADER,
  VERIFIED_HEADER,
  ROLE_HEADER,
  CREATED_HEADER,
  ACTIONS_HEADER,
];

