import { Route } from './navigation-models';
import Contact from '../pages/shared/Contact';
import { InitialRoute } from '../components/shared/InitialRoute';
import { FeatureAvailability } from '../utils/feature-availability';
import Imprint from '../pages/shared/Imprint';
import Privacy from '../pages/shared/Privacy';
import DeviceHierarchy from '../pages/DeviceHierarchy/DeviceHierarchy';
import Roles from '../pages/Roles/Roles';
import UserManagement from '../pages/UserManagement/UserManagement';
import DataIntegration from '../pages/DataIntegration/DataIntegration';
import Settings from '../pages/Settings/Settings';
import Dashboard from '../pages/Dashboard/Dashboard';
import DeviceManager from '../pages/DeviceManager/DeviceManager';
import DeviceMessages from '../pages/DeviceMessages/DeviceMessages';
import ManageDeviceTypes from '../pages/ManageDeviceTypes/ManageDeviceTypes';
import DeviceGroups from '../pages/DeviceGroups/DeviceGroups';
import Keys from '../pages/Keys/Keys';
import NoMatch from '../pages/shared/404';
import ValidateUser from '../components/User/ValidateUser';
import {
  CONTACT_PATH,
  DASHBOARD_PATH,
  DATA_INTEGRATION_PATH,
  DEVICE_GROUPS_PATH,
  DEVICE_HIERARCHY_PATH,
  DEVICE_MANAGER_PATH,
  DEVICE_MESSAGES_PATH,
  IMPRINT_PATH,
  KEYS_PATH,
  MANAGE_DEVICE_TYPES_PATH,
  PRIVACY_PATH,
  PROPERTY_VIEW_PATH,
  ROLES_PATH,
  SETTINGS_PATH,
  USER_MANAGEMENT_PATH,
  VALIDATE_USER_PATH,
} from './paths';
import { PropertyView } from '../pages/PropertyView/PropertyView';

export const allRoutes: Route[] = [
  {
    exact: false,
    path: '',
    component: NoMatch,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
  },
  {
    exact: true,
    path: '/',
    component: InitialRoute,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
      | FeatureAvailability.WithoutPermissionCheck,
  },
  {
    exact: true,
    path: DASHBOARD_PATH,
    component: Dashboard,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
      | FeatureAvailability.WithoutPermissionCheck
  },
  {
    exact: true,
    path: CONTACT_PATH,
    component: Contact,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
      | FeatureAvailability.WithoutPermissionCheck
  },
  {
    exact: true,
    path: IMPRINT_PATH,
    component: Imprint,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
      | FeatureAvailability.WithoutPermissionCheck
  },
  {
    exact: true,
    path: PRIVACY_PATH,
    component: Privacy,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
      | FeatureAvailability.WithoutPermissionCheck
  },
  {
    exact: true,
    path: DEVICE_HIERARCHY_PATH,
    component: DeviceHierarchy,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager
      | FeatureAvailability.Mobile
      | FeatureAvailability.WithoutPermissionCheck
  },
  {
    exact: true,
    path: ROLES_PATH,
    component: Roles,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
  },
  {
    exact: true,
    path: USER_MANAGEMENT_PATH,
    component: UserManagement,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
  },
  {
    exact: true,
    path: DATA_INTEGRATION_PATH,
    component: DataIntegration,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
  },
  {
    exact: true,
    path: SETTINGS_PATH,
    component: Settings,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
  },
  {
    exact: true,
    path: DEVICE_MANAGER_PATH,
    component: DeviceManager,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
      | FeatureAvailability.WithoutPermissionCheck
  },
  {
    exact: true,
    path: DEVICE_MESSAGES_PATH,
    component: DeviceMessages,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
      | FeatureAvailability.WithoutPermissionCheck
  },
  {
    exact: true,
    path: MANAGE_DEVICE_TYPES_PATH,
    component: ManageDeviceTypes,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
  },
  {
    exact: true,
    path: DEVICE_GROUPS_PATH,
    component: DeviceGroups,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
  },
  {
    exact: true,
    path: PROPERTY_VIEW_PATH,
    component: PropertyView,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
  },
  {
    exact: true,
    path: KEYS_PATH,
    component: Keys,
    availability:
      FeatureAvailability.Wiot
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
  },
  {
    exact: true,
    path: VALIDATE_USER_PATH,
    component: ValidateUser,
    availability:
       FeatureAvailability.KeyManager
      | FeatureAvailability.Mobile
      | FeatureAvailability.Desktop
      | FeatureAvailability.WithoutPermissionCheck
  },
];
