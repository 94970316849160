import { MeasurementAttributeId as SystemFieldIds } from '@wiot/shared-domain/models/device-type-profile/device-type-profile';
import { DataValidator } from '@wiot/shared-domain/models/validators/data-validators';
import { NumberUtils } from '@wiot/shared-domain/models/utils/primitives/number';
import { DeviceReading, DeviceReadingValue, FunctionType, MEASURE_UNITS } from '@wiot/shared-domain/models/device-reading/device-reading';

const VALUE_DESCRIPTIONS = [
  'HCA',
  'ENERGY',
  'VOLUME',
  // These fields come from external API mydatanet
  'wassermenge',
  'digital 1',
  'current_volume',
  'understander1',
  'abrechnung_1',
  'abrechnung',
  'value',
  // mydatanet end
  'FLOW_TEMPERATURE',
  'ERROR_FLAGS',
  'Status',
];

export const CURRENT_VALUE_REGEX = new RegExp(
  `"description":"(${ VALUE_DESCRIPTIONS.join('|') })","functionType":${ FunctionType.CURRENT_VALUE }`,
);
export const NON_M_BUS_VALUE_REGEX = new RegExp('^(?!(functionType))');

const getValueByDescription = (
  deviceReadingValues: DeviceReadingValue[]
): DeviceReadingValue | undefined =>
  deviceReadingValues.find(value => value.description && VALUE_DESCRIPTIONS.includes(value.description));

type DeviceReadingValues = {
  readingValue: any;
  formattedValue: string;
  unit: string;
  functionType?: FunctionType;
};

export const getFormattedDeviceReading = (
  deviceReadingValues?: DeviceReadingValue[],
  deviceReading?: DeviceReading,
  systemFieldId?: string,
): DeviceReadingValues | null => {
  const values = deviceReading?.values || deviceReadingValues;
  if (!values || values.length === 0) return null;

  let desiredReadingValue: DeviceReadingValue | undefined;
  switch (systemFieldId) {
    default:
      desiredReadingValue = values.find((value => value.systemFieldId === systemFieldId));
      break;
    case SystemFieldIds.primaryValue:
      desiredReadingValue = values.find((value => value.systemFieldId === systemFieldId));
      if (!desiredReadingValue) desiredReadingValue = getValueByDescription(values);
      break;
    case undefined:
      desiredReadingValue = getValueByDescription(values);
  }

  if (!desiredReadingValue) {
    return null;
  }

  let { value, unit, functionType } = desiredReadingValue;

  value = (DataValidator.isNumeric(value) ? parseFloat(value) : value) || 0;

  let displayUnit: string | undefined = unit;
  let displayValue: string | number | Date = value;
  switch (displayUnit) {
    case 'Reserved':
      displayUnit = '';
      displayValue = value;
      break;
    case 'Wh':
      displayUnit = 'kWh';
      displayValue = Math.round(value / 1000);
      break;
    case undefined:
      if (desiredReadingValue.description === 'ERROR_FLAGS') {
        if (desiredReadingValue.value === 0) displayValue = 'OK';
        else {
          displayValue = desiredReadingValue.value;
          displayUnit = 'ERROR';
        }
      } else if (desiredReadingValue.description === 'DATE') {
        displayValue = new Date(desiredReadingValue.value).toLocaleDateString('de-DE');
      } else if (desiredReadingValue.description === 'DATE_TIME') {
        displayValue = new Date(desiredReadingValue.value).toLocaleString('de-DE');
      }
      displayUnit = displayUnit || '';
      break;
    default:
      displayValue = DataValidator.isNumeric(value) ? NumberUtils.formatAsDin5008(Number(displayValue)) : displayValue;
      displayUnit = MEASURE_UNITS[displayUnit] || displayUnit;
      break;
  }

  return {
    readingValue: displayValue,
    unit: displayUnit,
    formattedValue: `${ displayValue } ${ displayUnit }`,
    functionType,
  };
};
