import { TreeNode } from '@wiot/shared-domain/domain/device-tree/tree-node';

export const DEVICE_TREE_UPDATED = 'DEVICE_TREE_UPDATED';

export interface DeviceTreeUpdatedAction {
  type: typeof DEVICE_TREE_UPDATED;
  rootNode: TreeNode;
}

export const deviceTreeUpdated = (treeData: TreeNode): DeviceTreeUpdatedAction => ({
  type: DEVICE_TREE_UPDATED,
  rootNode: treeData,
});
