import { faCheck, faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { IRoleChecked } from '@wiot/shared-domain/models/role/role';
import ConfirmModal, { ConfirmationVariant } from '../../../components/Modal/ConfirmModal';
import DeviceTypeColumn from './DeviceTypeColumn';
import PageAccessColumn from './PageAccessColumn';
import RoleActionModal from '../RoleActionModal';
import RenderOnCondition from '../../../components/RenderOnCondition';
import HasPermission from '../../../components/HasPermission';
import { AppState } from '../../../state/reducers/rootReducer';
import CustomCheckbox from '../../../components/shared/CustomCheckbox';
import { PortalMenu } from '../../../components/PortalMenu/PortalMenu';
import TableMenu from './TableMenu';
import {
  HORIZONTAL_OFFSET_TABLE_VIEW,
  VERTICAL_OFFSET_TABLE_VIEW
} from '../../../components/PortalMenu/constants/offset-table-view';
import { getMenuId } from '../../../components/PortalMenu/constants/context-menu-ids';
import { closeContextMenu, openContextMenu } from '../../../state/context-menu/toggleContextMenuActionCreators';
import { Tooltip } from '../../../components/shared/Tooltip';

export interface TableDataRowProps {
  role: IRoleChecked;
  selectOne: (id: string) => void;
  removeUnit: (id: string) => Promise<void>;
  refreshRoles?: () => void;
}

const TableDataRow = (props: TableDataRowProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const [toggleMenuButtonBoundingClientRect, setToggleMenuButtonBoundingClientRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });
  const [showRoleActionModal, setShowRoleActionModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  let toggleMenuButton: HTMLDivElement | null;

  const userPermission = useSelector<AppState>((state) => state.currentUser.permission);
  const visibleContextMenu = useSelector<AppState>((state: AppState) => state.contextMenu.visibleContextMenuId);

  const dispatch = useDispatch();

  const toggleShowDetails = (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
    event && event.stopPropagation();
    setShowDetails((prevState) => !prevState);
  };

  const menuId = getMenuId(props.role.id);
  const showMenu = (visibleContextMenu === menuId);
  const toggleMenu = () => {
    if (toggleMenuButton) {
      setToggleMenuButtonBoundingClientRect(toggleMenuButton.getBoundingClientRect());
    }
    if (!showMenu) {
      dispatch(openContextMenu(menuId));
    } else {
      dispatch(closeContextMenu());
    }
  };

  const toggleRoleActionModal = () => {
    setShowRoleActionModal((prevState) => !prevState);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prevState) => !prevState);
  };

  const { id, name, activeAccounts, superAdmin, checked } = props.role;

  return (
    <tr key={id} id={id} className="device-manager__table__table__trow roles__table__table__trow">
      <td>
        <CustomCheckbox onChange={() => id && props.selectOne(id)} checked={checked} />
      </td>
      <td
        className="device-manager__table__table__td device-manager__table__table__link"
        onClick={toggleShowDetails}
      >
        {name}
        {showDetails && (
          <RoleActionModal
            closeAddAndUpdateModal={toggleShowDetails}
            title="details-of-role"
            showDeleteButton
            addUnit={false}
            id={id}
            readOnly
            removeUnit={(itemId: string) => props.removeUnit(itemId)}
            refreshRoles={props.refreshRoles}
          />
        )}
      </td>
      <td className="text-center" data-tip="" data-for={`superadmin-${id}`}>
        {superAdmin && (
          <>
            <FontAwesomeIcon icon={faCheck} size="sm" className="pil-fa" />
            <Tooltip id={`superadmin-${id}`} delayShow={400}>
              <Translate id="super-admin-description" />
            </Tooltip>
          </>
        )}
      </td>
      <td>
        <RenderOnCondition condition={!props.role.superAdmin}>
          <DeviceTypeColumn role={props.role} />
        </RenderOnCondition>
      </td>
      <td>
        <RenderOnCondition condition={!props.role.superAdmin}>
          <PageAccessColumn role={props.role} />
        </RenderOnCondition>
      </td>
      <td className="text-center">{activeAccounts}</td>
      <td className="no-overflow">
        <HasPermission permissionObj={userPermission} oneOf={['roles.edit', 'roles.remove']}>
          <div className="device-details">
            <div
              className="ellipsis text-color-main"
              role="presentation"
              onClick={toggleMenu}
              ref={(tc) => {
                toggleMenuButton = tc;
              }}
              data-tip="table-actions-button-tooltip"
              data-for="table-actions-button-tooltip"
            >
              <FontAwesomeIcon icon={faCog} />
            </div>
            <Tooltip id="table-actions-button-tooltip">
              <Translate id="actions" />
            </Tooltip>
            { showMenu && id && (
              <PortalMenu
                anchorPosition={{
                  left: toggleMenuButtonBoundingClientRect.left + HORIZONTAL_OFFSET_TABLE_VIEW,
                  top: toggleMenuButtonBoundingClientRect.top + window.scrollY + VERTICAL_OFFSET_TABLE_VIEW
                }}
                minWidth={ 120 }
              >
                <TableMenu
                  removeUnit={ (itemId: string) => props.removeUnit(itemId) }
                  closeMenu={ toggleMenu }
                  menuId={ menuId }
                  refreshRoles={ props.refreshRoles }
                  toggleRoleActionModal={ toggleRoleActionModal }
                  toggleDeleteModal={ toggleDeleteModal }
                />
              </PortalMenu>
            ) }
            {showRoleActionModal && (
              <RoleActionModal
                closeAddAndUpdateModal={toggleRoleActionModal}
                closeMenu={toggleMenu}
                title="update-role"
                showDeleteButton
                addUnit={false}
                id={id}
                removeUnit={(itemId: string) => props.removeUnit(itemId)}
                refreshRoles={props.refreshRoles}
              />
            )}

            {showDeleteModal && id && (
              <ConfirmModal
                modalCloseRequested={toggleDeleteModal}
                actionConfirmed={() => props.removeUnit(id)}
                translationIdOfElementType="user-role"
                confirmationVariant={ ConfirmationVariant.DELETE }
              />
            )}
          </div>
        </HasPermission>
      </td>
    </tr>
  );
};

export default TableDataRow;
