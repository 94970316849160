import { DataIntegrationJob } from '@wiot/shared-domain/models/data-integration/data-integration';
import {
  SAVE_DATA_INTEGRATION_JOBS,
  TOGGLE_SELECT_ALL_DATA_INTEGRATION_JOBS,
  TOGGLE_SELECT_DATA_INTEGRATION_JOB,
} from '../constants/actionTypes';
import { DataIntegrationJobsActionTypes } from '../types';

export const saveDataIntegrationJobs = (
  dataIntegrationJobs: DataIntegrationJob[] = [],
): DataIntegrationJobsActionTypes => ({
  type: SAVE_DATA_INTEGRATION_JOBS,
  payload: dataIntegrationJobs,
});

export const toggleSelectAllDataIntegrationJobs = (
  isChecked: boolean,
): DataIntegrationJobsActionTypes => ({
  type: TOGGLE_SELECT_ALL_DATA_INTEGRATION_JOBS,
  payload: isChecked,
});

export const toggleSelectDataIntegrationJob = (
  dataIntegrationJobId: string,
): DataIntegrationJobsActionTypes => ({
  type: TOGGLE_SELECT_DATA_INTEGRATION_JOB,
  payload: dataIntegrationJobId,
});
