import { DeviceGroupType } from '@wiot/shared-domain/models/device-group-types/device-group-types';
import { DeviceGroupType as DeviceGroupTypes } from '@wiot/shared-domain/models/device-group/device-group';

const KEY_MANAGER_GROUP_TYPES: DeviceGroupTypes[] = [
  DeviceGroupTypes.TOWN, // Order number
  DeviceGroupTypes.PROPERTY, // Order position
  DeviceGroupTypes.ROOM, // Single unit
  DeviceGroupTypes.COUNTRY, // Year
  DeviceGroupTypes.OBJECT, // Month
  DeviceGroupTypes.FLAT, // User
  DeviceGroupTypes.STREET, // Unassigned Keys
  DeviceGroupTypes.DISTRICT, // Packaging unit (VPE)
  DeviceGroupTypes.BUILDING, // Assigned Keys
];

function sortDeviceGroupTypes(deviceGroupTypes: DeviceGroupType[], sortOrder: DeviceGroupTypes[]): DeviceGroupType[] {
  const sortOrderNames = sortOrder.map(type => DeviceGroupTypes[type] as string);

  return deviceGroupTypes
    .filter(dgt => sortOrderNames.includes(dgt.name))
    .sort((a, b) => sortOrderNames.indexOf(a.name) - sortOrderNames.indexOf(b.name));
}

export function filterDeviceGroupTypes(deviceGroupTypes: DeviceGroupType[], isKeyManagerModeEnabled: boolean): DeviceGroupType[] {
  if (!isKeyManagerModeEnabled) {
    return deviceGroupTypes;
  }

  // In the Key Manager Mode we cannot use the labels (ROOM, FLOOR, etc.) for sorting, so we have to hardcode a sort order
  return sortDeviceGroupTypes(deviceGroupTypes, KEY_MANAGER_GROUP_TYPES);
}
