import React from 'react';
import { Translate, withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Col, Row, Image } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import api from '../../api/api';
import CustomFileInput from '../CustomFileInput';
import RenderOnCondition from '../RenderOnCondition';
import CustomImageUpload from './ImageUploadControls';

interface IDropzoneOptions {
  multiple: boolean;
  maxSize: number;
  minSize: number;
  onDrop: (images: File[]) => void;
  accept: string;
}

interface IImageUploadProps extends LocalizeContextProps {
  title: string;
  description: string;

  images: File[] | undefined;
  options: IDropzoneOptions;
  src: string;
  file: string;
  readOnly: boolean | undefined;

  /**
   * Callback function which will be called if an image upload was requested.
   *
   * @param image The image (file) to be uploaded.
   */
  uploadRequested?: (image: File) => void;
}

const ImageUpload = ({
  file,
  images,
  uploadRequested,
  options,
  description,
  title,
  readOnly = true,
  src,
  translate,
}: IImageUploadProps) => {
  const { minSize, maxSize } = options;

  const onDropRejected = (rejectedFiles: File[]) => {
    if (rejectedFiles.length) {
      if (!options.accept.includes(rejectedFiles[0].type)) {
        toastr.error(translate('ERROR').toString(), translate('file-type-invalid').toString());
      } else {
        toastr.error(translate('ERROR').toString(), translate('file-size-invalid').toString());
      }
    }
  };

  const getCustomizePath = () => {
    switch (file) {
      case 'logo':
        return api.customizeLogo;
      case 'wallpaper':
        return api.customizeWallpaper;
      case 'branding':
        return api.customizeBranding;
      default:
        return api.customizeFavIcon;
    }
  };

  return (
    <>
      <Row>
        <Col lg={3}>
          <Row>
            <label>Title: {title}</label>
            <label>Description: {description}</label>
          </Row>
          <Row className="m-2">
            <label className="settings-form__label">
              <Translate id={`upload-site-${file}`} />
            </label>
          </Row>
          <Row className={`m-2 settings-form-thumbnail-${file}`}>
            <Image className="thumbnail" thumbnail src={`${api.baseAPIUrl}${getCustomizePath()}`} />
          </Row>
        </Col>
        <Col lg={9}>
          <label className="settings-form__label">
            <Translate id={`settings-${file}-description`} />
          </label>
          <label className="settings-form__label">
            <Translate
              data={{ maxSize: maxSize / 1000, minSize: minSize / 1000 }}
              id={`settings-${file}-requirements`}
            />
          </label>
          {readOnly ? (
            <Translate id="insufficient-privileges" />
          ) : (
            <CustomFileInput options={{ ...options, onDropRejected }} label="" />
          )}
          <RenderOnCondition condition={images?.length}>
            <CustomImageUpload
              src={src}
              images={images}
              file={file}
              uploadRequested={uploadRequested}
            />
          </RenderOnCondition>
        </Col>
      </Row>
    </>
  );
};

export default withLocalize(ImageUpload);
