import React from 'react';
import ButtonBase, { ButtonBaseProps } from './ButtonBase';

function PrimaryButton(
  {
    textTranslationId,
    textPlainText = '',
    onClick,
    style,
    isVisible = true,
    mouseOverTextTranslationId,
    mouseOverPlainText,
    formNameToSubmitOnClick,
    icon,
    iconSize,
    classNames,
  }: ButtonBaseProps) {
  return (
    <ButtonBase
      classNames={ classNames ? `btn-primary ${ classNames }` : 'btn-primary' }
      textTranslationId={ textTranslationId }
      textPlainText={ textPlainText }
      onClick={ onClick }
      style={ style }
      isVisible={ isVisible }
      mouseOverTextTranslationId={ mouseOverTextTranslationId }
      mouseOverPlainText={ mouseOverPlainText }
      formNameToSubmitOnClick={ formNameToSubmitOnClick }
      icon={ icon }
      iconSize={ iconSize }
    />
  );
}

export default PrimaryButton;
