import { Dispatch } from 'redux';
import useTranslation from '../../../hooks/useTranslation';
import { authenticatedInstance as httpClientInstance, handleApiError } from '../../../api/apiHelpers';
import { toastr } from 'react-redux-toastr';
import {
  CLOSE_PROPERTY_SETTINGS_MODAL,
  ClosePropertySettingsModalAction,
  OPEN_PROPERTY_SETTINGS_MODAL,
  OpenPropertySettingsModalAction,
  PROPERTY_SETTINGS_UPDATED,
  PropertySettingsModalActionTypes,
  PropertySettingsUpdatedAction,
  UPDATE_PROPERTY_SETTINGS,
  UpdatePropertySettingsAction
} from './propertySettingsModalActions';
import { PropertySettings } from '@wiot/shared-domain/models/settings/property-settings';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';

export const openPropertySettingsModal = (
  deviceGroup: DeviceGroup,
) => (dispatch: Dispatch<PropertySettingsModalActionTypes>): void => {
  dispatch({
    type: OPEN_PROPERTY_SETTINGS_MODAL,
    deviceGroup: deviceGroup,
  } as OpenPropertySettingsModalAction);
};

export const closePropertySettingsModal = () => (
  dispatch: Dispatch<PropertySettingsModalActionTypes>,
): void => {
  dispatch({ type: CLOSE_PROPERTY_SETTINGS_MODAL } as ClosePropertySettingsModalAction);
};

export const updatePropertySettings = (
  propertyId: string,
  propertySettings: PropertySettings,
) => async (dispatch: Dispatch<PropertySettingsModalActionTypes>): Promise<void> => {
  const translate= useTranslation();

  dispatch({
    type: UPDATE_PROPERTY_SETTINGS,
    propertyId,
    propertySettings,
  } as UpdatePropertySettingsAction);

  try {
    const url = `/properties/${propertyId}`;
    const response = await httpClientInstance.post(url, propertySettings);

    if (response) {
      toastr.success(
        translate('success').toString(),
        translate('property-settings-changed').toString(),
      );

      dispatch({ type: PROPERTY_SETTINGS_UPDATED, deviceGroup: response.data.deviceGroup } as PropertySettingsUpdatedAction);
    }
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};
