import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIn } from 'formik';
import { IRole } from '@wiot/shared-domain/models/role/role';
import { pageIcons } from '../../../constants';
import QUESTION_MARK from '../../../assets/devices/question-mark-small.svg';
import RoleGroup from '../components/RoleGroup';
import RenderOnCondition from '../../../components/RenderOnCondition';
import { Tooltip } from '../../../components/shared/Tooltip';

interface IPageAccessColumn {
  role: IRole;
}

const PageAccessColumn = ({ role }: IPageAccessColumn) => {
  const getPageIcon = (key: string) => (key && pageIcons[key]) || QUESTION_MARK;

  const getTooltipContent = (key: string) => (
    <>
      <RoleGroup values={role} setFieldValue={() => {}} groupKey={key} readOnly />
    </>
  );

  const hasTruthy = (groupObj: any) => {
    const truthyIndex = Object.values(groupObj).findIndex((val) => {
      if (typeof val === 'object') {
        return hasTruthy(val);
      }
      return val;
    });
    return groupObj && truthyIndex !== -1;
  };

  return (
    <Row>
      {Object.keys(pageIcons).map((key: string, index) => {
        const PageIcon: React.SFC<React.SVGProps<SVGSVGElement>> = getPageIcon(key);
        const groupObj = getIn(role, key);
        const hasTruthyValue = groupObj && hasTruthy(groupObj);

        if (!(key in role) || !hasTruthyValue) {
          return (
            <Col key={index} xs={1} data-tip="" data-for={key}>
              {null}
            </Col>
          );
        }
        return (
          <React.Fragment key={index}>
            <Col key={index} xs={1} className="cursor-pointer">
              <RenderOnCondition condition={key !== 'settings'}>
                <PageIcon
                  className="table-icon text-color-main"
                  // @ts-ignore
                  alt={key}
                  data-tip=""
                  data-for={`${role.id}-${key}`}
                />
              </RenderOnCondition>
              <RenderOnCondition condition={key === 'settings'}>
                <FontAwesomeIcon
                  className="table-icon text-color-main"
                  icon={pageIcons[key]}
                  data-tip=""
                  data-for={`${role.id}-${key}`}
                />
              </RenderOnCondition>
            </Col>
            <Tooltip
              id={`${role.id}-${key}`}
              backgroundColor="#e8e7e7"
              delayShow={400}
              className="w-50"
              place="bottom"
            >
              {getTooltipContent(key)}
            </Tooltip>
          </React.Fragment>
        );
      })}
    </Row>
  );
};

export default PageAccessColumn;
