import React from 'react';
import { Translate } from 'react-localize-redux';
import { Row, Col } from 'react-bootstrap';
import { HorizontalDivider } from '../../../../components/shared/Divider';
import ThemeColorPicker from './ThemeColorPicker';

interface ThemeCustomizationsProps {
  readOnly: boolean | undefined;
}

const ThemeCustomizations = ({ readOnly = true }: ThemeCustomizationsProps) => (
  <>
    <HorizontalDivider />
    <div className="settings-header">
      <Translate id="site-settings-customizations-theme" />
    </div>
    <Row>
      <Col sm={2} lg={3}>
        <label className="settings-form__label">
          <Translate id="settings-theme-choose-color" />
        </label>
      </Col>
      <Col sm={6} lg={9}>
        {readOnly ? <Translate id="insufficient-privileges" /> : <ThemeColorPicker />}
      </Col>
    </Row>
  </>
);

export default ThemeCustomizations;
