import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { DataValidator } from '@wiot/shared-domain/models/validators/data-validators';
import api from '../../api/api';
import { setPassword } from '../../api/apiHelpers';
import { LOGIN_PATH } from '../../navigation/paths';

interface SetPasswordProps extends LocalizeContextProps, RouteComponentProps {}

class SetPassword extends React.Component<SetPasswordProps, any> {
  constructor(props: SetPasswordProps) {
    super(props);
    this.state = {
      faIcon: faEye,
      token: '',
    };
  }

  componentDidMount = () => {
    this.setState({
      token: new URLSearchParams(window.location.search).get('token'),
    });
  };

  handleEyeClick = (event: React.SyntheticEvent<any>) => {
    const inputField = event.currentTarget.parentNode.querySelector('.set-password-form__input');
    if (inputField.type === 'password') {
      inputField.type = 'text';
      this.setState({ faIcon: faEyeSlash });
    } else {
      inputField.type = 'password';
      this.setState({ faIcon: faEye });
    }
  };

  handleSubmit = async (password: string) => {
    const { token } = this.state;
    if (!token) {
      toastr.error(
        this.props.translate('failed').toString(),
        this.props.translate('missing-token').toString(),
      );
      return;
    }
    try {
      const response = await setPassword(password, token);
      if (response) {
        this.props.history.push(LOGIN_PATH);
        toastr.success(
          this.props.translate('success').toString(),
          this.props.translate('set-password-success').toString(),
        );
      }
    } catch (e) {
      toastr.error(
        this.props.translate('failed').toString(),
        this.props.translate('set-password-fail').toString(),
      );
    }
  };

  render() {
    return (
      <main
        data-testid="page-set-password"
        className="main"
        style={{
          backgroundImage: `url(${api.baseAPIUrl}${api.customizeWallpaper})`,
        }}
      >
        <Formik
          initialValues={{
            password: '',
            faIcon: faEye,
          }}
          validate={({ password }) => {
            const isValidPassword = DataValidator.validatePasswordComplexity(password);
            const errors: { email?: string; password?: string } = {};

            if (!password || !isValidPassword) {
              errors.password = this.props.translate('new-password-requirements').toString();
            }

            return errors;
          }}
          onSubmit={(values) => {
            const isValidPassword = DataValidator.validatePasswordComplexity(values.password);

            if (isValidPassword) {
              this.handleSubmit(values.password);
            }
          }}
          render={({ isValid }) => (
            <Form className="set-password-form">
              <h1 className="set-password-form__heading" data-testid="set-password-heading">
                <Translate id="set-new-pw" />
              </h1>

              <label className="set-password-form__label">
                <Translate id="password" />
                <br />
                <div className="set-password-form__container">
                  <Field
                    className="set-password-form__input set-password-form__input--icon"
                    name="password"
                    type="password"
                    data-testid="set-password-input"
                  />
                  <div onClick={this.handleEyeClick}>
                    <FontAwesomeIcon
                      icon={this.state.faIcon}
                      size="lg"
                      className="set-password-form__input__eye"
                    />
                  </div>
                </div>
                <ErrorMessage name="password" component="div" className="input-error" />
              </label>

              <button
                className={`set-password-form__button ${
                  isValid ? 'set-password-form__button--valid' : ''
                }`}
                disabled={!isValid}
                type="submit"
                data-testid="set-password-button"
              >
                <Translate id="save" />
              </button>
            </Form>
          )}
        />
      </main>
    );
  }
}

export default withLocalize(withRouter(SetPassword));
