import React, { useEffect } from 'react';
import { OptionsType, OptionTypeBase } from 'react-select';
import { OptionProps } from 'react-select/src/components/Option';
import { BaseSelect } from './BaseSelect';
import { InputContextType } from '../../Input/input-context-type';

interface SelectWrapperWithFormikProps<OptionType extends OptionTypeBase> {
  label?: string;
  fieldName: string;
  value?: string | OptionType | OptionType[];
  onChange?: ( (name: string, value: undefined | string | OptionType | OptionType[]) => void );
  options: OptionType[];
  onBlur?: (name: string) => void;
  error?: string;
  touched?: boolean;
  defaultValue?: OptionType;
  labelKey?: string;
  valueKey?: string;
  translationId?: string;
  isMulti?: boolean;
  listHeight?: number;
  backgroundColor?: string;

  placeholderTranslationId?: string;
  placeholderPrefix?: string;

  customizeOption?: (props: OptionProps<OptionType>) => React.ReactElement;
  labelClassName?: string;
  className?: string;
  inputContextType?: InputContextType;
  hideLabel?: boolean;
  readOnly?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  translateOptions?: boolean;
  portalTargetId?: string;
  showDefaultOption?: boolean;
  sortLabels?: boolean;
}

export const SelectWrapperWithFormik = <OptionType extends OptionTypeBase> (props: SelectWrapperWithFormikProps<OptionType>) => {
  const {
    label,
    fieldName,
    options,
    value,
    onChange,
    onBlur,
    error,
    touched,
    defaultValue,
    valueKey = 'value',
    labelKey = 'label',
    isMulti = false,
    translateOptions = false,

    placeholderTranslationId = 'select',
    placeholderPrefix,

    listHeight = 200,
    backgroundColor,
    className,
    inputContextType = InputContextType.MODAL,
    labelClassName = 'form__label',
    readOnly = false,
    isClearable = true,
    isSearchable = true,
    portalTargetId,
    showDefaultOption = true,
    sortLabels,
  } = props;

  const [selectedValue, setSelectedValue] = React.useState<undefined | OptionType | OptionType[]>(undefined);

  const getLabel = (option: OptionType | OptionsType<OptionType>) => option[labelKey];
  const getValue = (option: OptionType | OptionsType<OptionType>) => option[valueKey];

  useEffect(() => {
    if ( !value ) {
      setSelectedValue(undefined);
    } else {
      if ( !isMulti && Array.isArray(value) ) {
        setSelectedValue(value[0])
      } else if ( typeof value === 'string' ) {
        const option = options?.find((option) => getValue(option) === value);
        setSelectedValue(option);
      } else {
        setSelectedValue(value);
      }
    }
  }, [value, options]);

  return (
    <BaseSelect<OptionType>
      translationId={ fieldName }
      getOptionLabel={ getLabel }
      getOptionValue={ getValue }

      defaultOptions={ options }
      selectedOptions={ selectedValue }
      defaultValue={ defaultValue }
      onChange={
        onChange
          ? (option: OptionType) => onChange(fieldName, getValue(option))
          : () => {}
      }
      label = { label }

      placeholderTranslationId={ placeholderTranslationId }
      placeholderPrefix={ placeholderPrefix }

      portalTargetId={ portalTargetId }

      isMulti={ isMulti }
      isClearable={ isClearable }
      isSearchable={ isSearchable }
      translateOptions={ translateOptions }

      readOnly={ readOnly }
      showDefaultOption={ showDefaultOption }
      showSelectAndDeselectAllButtons={ false }

      onBlur={ onBlur }
      error={ error }

      className={ className }
      inputContextType={ inputContextType }
      classNameForLabel={ labelClassName }
      listHeight={ listHeight }
      sortLabels={ sortLabels }
    />
  );
};
