import React, { useEffect } from 'react';
import { Translate } from 'react-localize-redux';
import onClickOutside from 'react-onclickoutside';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import { AppState } from '../../../state/reducers/rootReducer';
import HasPermission from '../../../components/HasPermission';
import { OutsideClickHandler } from '../../../components/OutsideClickHandler';
import TableMenuCloseButton from '../../../components/Table/TableMenuCloseButton';
import { closeContextMenu, openContextMenu } from '../../../state/context-menu/toggleContextMenuActionCreators';

export interface TableMenuProps {
  refreshData: (column?: IColumnObject) => void;
  removeUnit: (id: string) => void;
  menuId: string;
  toggleDeleteModal: () => void;
  toggleKeyActionModal: () => void;
  isMobileDisplay?: boolean;
}

const TableMenu = (props: TableMenuProps) => {
  const { menuId, toggleKeyActionModal, toggleDeleteModal, isMobileDisplay } = props;

  const userPermission = useSelector<AppState>((state) => state.currentUser.permission);

  const tdRef = React.createRef<HTMLDivElement>();

  const dispatch = useDispatch();

  const closeMenu = (event?: React.MouseEvent) => {
    event && event.preventDefault();
    dispatch(closeContextMenu());
  }

  useEffect(() => {
    dispatch(openContextMenu(menuId));
  }, [])

  useEffect(() => {
    const targetElement = tdRef.current;
    isMobileDisplay && disableBodyScroll(targetElement);

    return () => {
      isMobileDisplay && enableBodyScroll(targetElement);
    };
  }, [isMobileDisplay, tdRef]);

  const handleClickOutside = (event: React.MouseEvent) => {
    closeMenu(event);
  };

  const handleMenuButtonClick = (
    event: React.MouseEvent,
    action: (event: React.MouseEvent) => void,
  ) => {
    closeMenu(event);
    action(event);
  };

  return (
    <OutsideClickHandler onClickOutside={ handleClickOutside }>
      <div
        className="td-menu"
        ref={ tdRef }
      >
        <TableMenuCloseButton closeMenu={ handleClickOutside } />

        <HasPermission permissionObj={ userPermission } permissionKey="keys.edit">
          <>
            <button
              className="td-menu__item"
              onClick={ (e) => handleMenuButtonClick(e, toggleKeyActionModal) }
            >
              <div className="td-menu__item__icon">
                <FontAwesomeIcon icon={faEdit} />
              </div>
              <Translate id="update" />
            </button>
          </>
        </HasPermission>

        <HasPermission permissionObj={ userPermission } permissionKey="keys.remove">
          <>
            <button
              className="td-menu__item"
              onClick={ (e) => handleMenuButtonClick(e, toggleDeleteModal) }
            >
              <div className="td-menu__item__icon">
                <FontAwesomeIcon icon={ faTrashAlt } />
              </div>
              <Translate id="remove" />
            </button>
          </>
        </HasPermission>
      </div>
    </OutsideClickHandler>
  );
};

export default onClickOutside(TableMenu);
