import { combineReducers } from 'redux';
import { protectionDetailsReducer } from './protectionDetailsReducer';
import { downloadDetailsReducer } from './downloadDetailsReducer';
import { downloadHashNotFoundReducer } from './downloadHashNotFoundReducer';
import { deviceKeysAsFileReducer } from '../../device-key-download/deviceKeysAsFileReducer';
import { downloadHashOfDKDModalReducer } from '../../device-key-download/downloadHashOfDKDModalReducer';

const deviceKeyProtectionReducer = combineReducers({
  protectionDetails: protectionDetailsReducer,
  downloadDetails: downloadDetailsReducer,
  downloadHashNotFound: downloadHashNotFoundReducer,
  deviceKeysAsFile: deviceKeysAsFileReducer,
  downloadHashOfDKDModal:downloadHashOfDKDModalReducer,
});

export default deviceKeyProtectionReducer;
