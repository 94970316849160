import { DownloadHashNotFoundAction } from '../../types';
import { DOWNLOAD_HASH_NOT_FOUND } from '../deviceKeyActionTypes';

export type DownloadHashNotFoundState = {
  downloadHash: string;
};

const initialState: DownloadHashNotFoundState = {
  downloadHash: '',
};

export const downloadHashNotFoundReducer = (
  state: DownloadHashNotFoundState = { ...initialState },
  action: DownloadHashNotFoundAction,
): DownloadHashNotFoundState => {
  switch (action.type) {
    case DOWNLOAD_HASH_NOT_FOUND:
      return {
        ...state,
        downloadHash: action.downloadHash,
      };

    default:
      return state;
  }
};
