import { TreeNode, TreeNodeType } from '@wiot/shared-domain/domain/device-tree/tree-node';
import * as React from 'react';
import { CustomNodeElementProps } from 'react-d3-tree/lib/types/common';
import DeviceGroupAndDeviceTreeNode from './DeviceGroupAndDeviceTreeNode';
import ExpandTreeNode from './ExpandTreeNode';
import RootTreeNode from './RootTreeNode';

export const getTreeNodeComponent = (
  treeNode: TreeNode,
  d3props: CustomNodeElementProps
): JSX.Element => {
  let treeNodeComponent;
  switch (treeNode.type) {
    case TreeNodeType.ROOT:
      treeNodeComponent = (<RootTreeNode/>);
      break;

    case TreeNodeType.DEVICE:
    case TreeNodeType.DEVICE_GROUP:
      treeNodeComponent = (<DeviceGroupAndDeviceTreeNode treeNode={ treeNode } { ...d3props }/>);
      break;


    case TreeNodeType.EXPAND:
      treeNodeComponent = (<ExpandTreeNode treeNode={ treeNode } { ...d3props }/>);
      break;

    default:
      throw new Error(`There is no component for the type:${ treeNode.type }`);
  }

  return wrapInStandardSizeSvgElement(treeNodeComponent);
};

export const wrapInStandardSizeSvgElement = (treeNodeComponent: JSX.Element): JSX.Element => {
  return (
    <g>
      <foreignObject width="110" height="118" x="-53" y="-70" >
        { treeNodeComponent }
      </foreignObject>
    </g>
  );
};
