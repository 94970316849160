import { IS_LOADING, SET_LOADING_MESSAGE } from '../constants/actionTypes';
import {
  IsLoadingActionTypes,
  IsLoadingState,
  LoadingMessageState,
  SetLoadingMessageActionTypes,
} from '../types';

const initialState: IsLoadingState = false;

export const isLoadingReducer = (
  state: IsLoadingState = initialState,
  action: IsLoadingActionTypes,
): IsLoadingState => {
  switch (action.type) {
    case IS_LOADING:
      return action.payload;

    default:
      return state;
  }
};

const initialMessage: LoadingMessageState = {
  message: '',
  translatable: true,
};

export const loadingMessageReducer = (
  state: LoadingMessageState = initialMessage,
  action: SetLoadingMessageActionTypes,
): LoadingMessageState => {
  switch (action.type) {
    case SET_LOADING_MESSAGE:
      return action.payload;

    default:
      return state;
  }
};
