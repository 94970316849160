import { TreeNode } from '@wiot/shared-domain/domain/device-tree/tree-node';
import { INITIAL_TREE_CHILD_LIMIT } from '@wiot/shared-domain/domain/device-tree/initial-tree-children-limit';
import { Dispatch } from 'redux';
import { IsLoadingActionTypes } from '../../types';
import { DeviceTreeUpdatedAction } from '../fetch-device-tree/deviceTreeUpdatedAction';
import { isLoading } from '../../actions/isLoadingAction';
import api from '../../../api/api';
import { authenticatedInstance as httpClientInstance, handleApiError } from '../../../api/apiHelpers';
import { FETCH_TREE_NODE, FetchTreeNodeAction, TREE_NODE_FETCHED, TreeNodeFetchedAction } from './fetchTreeNodeAction';
import { AppState } from '../../reducers/rootReducer';
import { DEVICE_MANAGER_PAGE_KEY } from '@wiot/shared-domain/domain/device-manager/device-manager-page-key';
import { getDeviceManagersFilterString } from '../../../components/Filter/get-device-managers-filter-string';
import { buildQueryString, QueryMap } from '../../../utils/common';

import { DeviceManagerFilter } from '../../device/device-manager-filter';

export const fetchTreeNode = (deviceGroupId: string, deviceManagerFilter: DeviceManagerFilter): FetchTreeNodeAction => ({
  type: FETCH_TREE_NODE,
  deviceGroupId: deviceGroupId,
  deviceManagerFilter: deviceManagerFilter,
});

export const treeNodeWithChildrenFetched = (treeNode: TreeNode, childLimit: number): TreeNodeFetchedAction => ({
  type: TREE_NODE_FETCHED,
  treeNode,
  childLimit
});

export const fetchTreeNodeWithChildren = (
  deviceGroupId: string,
  childLimit: number = INITIAL_TREE_CHILD_LIMIT
) => async (
  dispatch: Dispatch<FetchTreeNodeAction | IsLoadingActionTypes | DeviceTreeUpdatedAction | TreeNodeFetchedAction>,
  getState: () => AppState,
): Promise<void> => {
  const state = getState();
  const filter = state.filters.filter[DEVICE_MANAGER_PAGE_KEY];
  dispatch(fetchTreeNode(deviceGroupId, filter));
  dispatch(isLoading(true));

  const filterString = getDeviceManagersFilterString(filter);
  let queryMap: QueryMap = {
    filter: filterString,
    childLimit: childLimit,
  };

  const qs = buildQueryString(queryMap);
  const url = `${ api.deviceGroups }/tree/${ encodeURIComponent(deviceGroupId) }` + `?${ qs }`;

  try {
    const { data: treeNodeWithLoadedChildren } = await httpClientInstance.get<TreeNode>(url);

    dispatch(treeNodeWithChildrenFetched(treeNodeWithLoadedChildren, childLimit));
  } catch (error) {
    handleApiError(error);
    throw error;
  } finally {
    dispatch(isLoading(false));
  }
};
