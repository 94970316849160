import React from 'react';
import classNames from 'classnames';

interface ChartToggleProps {
  value: boolean;
  onChange: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  readOnly?: boolean;
}

const ChartToggle = ({
  value,
  onChange,
  label,
  readOnly,
}: ChartToggleProps) => {
  const toggleOnChange = () => {
    onChange(!value);
  }

  return (
    <button
      className={classNames( {
        'form__button--blue background-color-main text-color-white border-color-main': value,
        'form__button--cancel mr-0': !value,
      })}
      type="button"
      onClick={toggleOnChange}
      disabled={readOnly}
    >
      {label}
    </button>
  );
};

export default ChartToggle;
