import { Dispatch } from 'redux';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import { authenticatedInstance as httpClientInstance, handleApiError, } from '../../api/apiHelpers';
import { DEVICE_TYPES_FETCHED, DeviceTypesFetchedAction, FETCH_DEVICE_TYPES, FetchDeviceTypesAction, } from './deviceTypesActions';
import api from '../../api/api';
import { localCompareTranslationsCurrentLanguage } from '../../utils/common';

export const deviceTypesFetched = (deviceTypes: IDeviceType[]): DeviceTypesFetchedAction => ({
  type: DEVICE_TYPES_FETCHED,
  deviceTypes,
});

export const fetchDeviceTypes = () =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch({
      type: FETCH_DEVICE_TYPES,
    } as FetchDeviceTypesAction);

    const url = `${api.deviceTypes}`;
    try {
      const { data: deviceTypes } = await httpClientInstance.get<IDeviceType[]>(
        url,
      );

      const sortedDeviceTypes = deviceTypes
        .filter((dt) => dt.isActive)
        .sort((a, b) =>
          localCompareTranslationsCurrentLanguage(a.name, b.name),
        );

      dispatch(deviceTypesFetched(sortedDeviceTypes));
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  };
