import * as React from 'react';
import { ImpulseSpinner } from 'react-spinners-kit';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { AppState } from '../../state/reducers/rootReducer';
import { ILoadingMessage } from '../../state/types';
import RenderOnCondition from '../RenderOnCondition';

interface LoadingIconProps {
  size?: number;
  centerBothAxis?: boolean;
  padding?: number;
}

const LoadingIcon = ({ size, centerBothAxis = true, padding }: LoadingIconProps) => {
  const loadingMessage: ILoadingMessage = useSelector((state: AppState) => state.loadingMessage);

  return (
    <div
      className={ `loading-icon ${ centerBothAxis ? 'centered-both-axis' : '' }` }
      style={{ padding }}
    >
      <div className="d-flex flex-column align-items-center">
        <ImpulseSpinner frontColor="var(--main-color)" size={ size || 35 }/>
        <RenderOnCondition condition={ loadingMessage.message }>
          <RenderOnCondition condition={ loadingMessage.translatable }>
            <Translate id={ loadingMessage.message }/>
          </RenderOnCondition>
          <RenderOnCondition condition={ !loadingMessage.translatable }>
            <p>{ loadingMessage.message }</p>
          </RenderOnCondition>
        </RenderOnCondition>
      </div>
    </div>
  );
};

export default LoadingIcon;
