import * as React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer as RechartsResponsiveContainer, Tooltip } from 'recharts';
import _ from 'lodash';
import { connect } from 'react-redux';
import { IDeviceCountsByType } from '@wiot/shared-domain/models/device/device';
import { AppState } from '../../state/reducers/rootReducer';
import { getTranslationValueInCurrentLanguage } from '../../utils/common';
import { GraphAPIData } from '@wiot/shared-domain/models/dashboard/graph-api-data';
import { DeviceCountsByTypeSummary } from '@wiot/shared-domain/models/dashboard/device-counts-by-type-summary';
import { DEVICE_GROUPS_PATH, DEVICE_MANAGER_PATH } from '../../navigation/paths';
import { ArrayUtils } from '@wiot/shared-domain/models/utils/primitives/array';

export interface DashBoardGraphProps extends LocalizeContextProps {
  data: GraphAPIData | DeviceCountsByTypeSummary;
  dataUnit?: string;
  isMobileDisplay: boolean;
  translateLegend?: boolean;
  minHeight?: number;
  redirectOnClick?: boolean;
  showSliceLegend?: boolean;
}

interface IPieChartData {
  id: string;
  name: string;
  value: number;
  color?: string;
}

const pieChartColors = [
  'var(--main-color)',
  'var(--main-color-gray)',
  'var(--main-color-lighter)',
  'var(--main-color-mod-1)',
  'var(--main-color-mod-2)',
  'var(--main-color-mod-3)',
  'var(--main-color-mod-4)',
  'var(--main-color-mod-5)',
  'var(--main-color-mod-6)',
];

const DashBoardPieGraph = ({
  redirectOnClick = true,
  data,
  dataUnit,
  isMobileDisplay,
  translate,
  translateLegend = true,
  minHeight = 280,
  showSliceLegend = false,
}: DashBoardGraphProps) => {
  const history = useHistory();

  const getLegendLink = (id: string) => {
    switch (data.type) {
      case 'device-types':
        return `${DEVICE_MANAGER_PATH}?deviceType=${ id }`;
      case 'device-groups':
        return `${DEVICE_GROUPS_PATH}?deviceGroup=${ id }`;
      case 'device-status':
        return `${DEVICE_MANAGER_PATH}?deviceStatus=${ id }`;
      default:
        return '';
    }
  };

  // Reason for 'any' type: It says LegendPayload is not exported from recharts while it is
  const renderLegendText = (value: number, { id, color, name }: any) => {
    const legend = (<span className='standard-font-size' style={ { color } }>
        { name.slice(0, 23) }
        { name.length > 23 && '...' }
        { ` (${ value })` }
      </span>);

    if (redirectOnClick) {
      return (<Link to={ getLegendLink(id) }>
        {legend}
      </Link>);
    } else {
      return legend;
    }
  };

  const onPieClick = (event: any) => {
    if (redirectOnClick) {
      const cellName = event.name;
      const redirectTo = getLegendLink(cellName);
      history.push(redirectTo);
    }
  };

  const tooltipFormatter = (value: any, name: string) => {
    const formattedValue = dataUnit ? `${value} ${dataUnit}` : value;
    return [formattedValue, name];
  };

  const mapDeviceCountsByTypeToPieChartData = (
    deviceCountByType: IDeviceCountsByType,
  ): IPieChartData => {
    if (!deviceCountByType.deviceType) {
      return {
        id: 'NO_DEVICE_TYPE',
        name: translate('NO_DEVICE_TYPE').toString(),
        value: deviceCountByType.numberOfDevices,
      };
    }

    return {
      id: deviceCountByType.deviceType.id,
      name: getTranslationValueInCurrentLanguage(deviceCountByType.deviceType.name),
      value: deviceCountByType.numberOfDevices,
      color: ArrayUtils.getRandomElement(pieChartColors),
    };
  };

  let mappedData: IPieChartData[];

  if (data.type === 'device-types') {
    const { deviceCountsByType } = data as DeviceCountsByTypeSummary;

    mappedData = deviceCountsByType.map(mapDeviceCountsByTypeToPieChartData);
  } else {
    const { types } = data as GraphAPIData;
    mappedData = _.map(types, (value, name) => ({
      id: name,
      name: translateLegend ? translate(name).toString() : name,
      value,
      color: ArrayUtils.getRandomElement(pieChartColors),
    }));
  }

  const pieChartLegendPayload = mappedData.map((item) => ({
    id: item.id,
    name: item.name,
    type: 'circle',
    value: dataUnit ? `${item.value} ${dataUnit}` : item.value,
    color: item.color,
  }));

  if (!data || data.total === 0) {
    return (
      <RechartsResponsiveContainer width="100%" height={ minHeight }>
        <div className="standard-font-size pt-5">{ translate('no-data') }</div>
      </RechartsResponsiveContainer>
    );
  }

  return (
    <RechartsResponsiveContainer width="100%" height={ minHeight }>
      <PieChart
        margin={ isMobileDisplay ? { top: 5, right: 5, bottom: 5, left: 5 } : { top: 2, right: 40 } }
      >
        <Legend
          layout={ isMobileDisplay ? 'horizontal' : 'vertical' }
          align={ isMobileDisplay ? 'center' : 'right' }
          verticalAlign={ isMobileDisplay ? 'bottom' : 'middle' }
          iconSize={ 6 }
          formatter={ renderLegendText }
          payload={ pieChartLegendPayload as any }
          wrapperStyle={ {
            maxHeight: isMobileDisplay ? '100px' : '100%',
            overflow: 'auto',
            right: isMobileDisplay ? 0 : 20,
          } }
        />
        <Pie
          data={ mappedData }
          label={ showSliceLegend }
          nameKey="name"
          dataKey="value"
          innerRadius={ 40 }
          outerRadius={ 65 }
          startAngle={ 90 }
          endAngle={ 450 }
          onClick={ onPieClick }
        >
          { mappedData.map((entry, index) => (
            <Cell fill={ entry.color } key={ index }/>
          )) }
          <Label width={ 30 } position="center" className="graphlabel">
            { `${ data.total }` }
          </Label>
        </Pie>
        <Tooltip formatter={ tooltipFormatter }/>
      </PieChart>
    </RechartsResponsiveContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  isMobileDisplay: state.isMobileDisplay,
});

export default connect(mapStateToProps)(withLocalize(DashBoardPieGraph));
