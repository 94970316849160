import * as React from 'react';
import { toastr } from 'react-redux-toastr';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import {
  BlobWithPath,
  CsvImportResult,
} from '@wiot/shared-domain/models/data-integration/data-integration';
import { validateCSV } from '../../../../api/apiHelpers';
import CustomFileInput from '../../../../components/CustomFileInput';

interface ICsvImportProps extends LocalizeContextProps {
  setAcceptedFile: (acceptedCsvFile: BlobWithPath) => void;
  setValidationResult: (csvValidationResult: CsvImportResult) => void;
}

const CsvImport = (props: ICsvImportProps) => {
  const { translate, setAcceptedFile, setValidationResult } = props;

  const onCSVDropAccepted = async (droppedFiles: any) => {
    toastr.info(
      translate('validate-csv').toString(),
      translate('csv-validation-started').toString(),
    );

    setAcceptedFile(droppedFiles[0]);

    const formData = new FormData();
    formData.append('csvFile', droppedFiles[0]);

    const response = await validateCSV(formData);
    if (response) {
      setValidationResult(response);

      toastr.success(
        translate('validate-csv').toString(),
        translate('csv-validation-success').toString(),
      );
    }
  };

  const onCSVDropRejected = () => {
    toastr.warning(translate('upload-csv').toString(), translate('file-type-invalid').toString());
  };

  const csvDropzoneOptions = {
    accept: ['text/plain', '.csv', 'text/csv', 'application/vnd.ms-excel'],
    onDropAccepted: onCSVDropAccepted,
    onDropRejected: onCSVDropRejected,
    multiple: false,
  };

  return <CustomFileInput options={csvDropzoneOptions} label="files" />;
};

export default withLocalize(CsvImport);
