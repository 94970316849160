import React from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { CustomFieldInput } from '../Table/CustomFieldInput';
import PrimaryButton from '../shared/PrimaryButton';
import useTranslation from '../../hooks/useTranslation';
import { CustomFieldAdvancedInput } from '../Table/CustomFieldAdvancedInput';

interface LoginFormProps {
  isUsernameVisible?: boolean;
  userName?: string;
  loginButtonTranslationId: string;
  onLoginButtonClick: (username: string, password: string) => void;
  autoFocusOnPassword?: boolean;
}

function LoginForm(
  {
    loginButtonTranslationId,
    onLoginButtonClick,
    isUsernameVisible = true,
    userName = '',
    autoFocusOnPassword = false,
  }: LoginFormProps) {
  const translate = useTranslation();

  return (
    <>
      <Formik
        initialValues={ {
          username: userName,
          password: '',
        } }
        onSubmit={ (values) => onLoginButtonClick(values.username, values.password) }
      >
        { ({ values, errors }) => (
          <Form className="text-left" id="loginForm">
            <div className="form__section">
              { isUsernameVisible && (
                <Row>
                  <Col lg={ 8 }>
                    <div className="my-1">
                      <CustomFieldInput
                        fieldName="username"
                        inputFontFamily="monospace"
                        maxLength={ 32 }
                        type="text"
                        translationId="email"
                        value={ values.username }
                      />
                      <ErrorMessage name="username" component="div" className="input-error"/>
                    </div>
                  </Col>
                </Row>
              ) }
              <Row>
                <Col lg={ 8 }>
                  <div className="my-2">
                    <CustomFieldAdvancedInput
                      type="password"
                      fieldName="password"
                      value={ values.password }
                      translationId="password"
                      showPasswordHintButton={ false }
                      autoFocus={ autoFocusOnPassword }
                    />
                  </div>
                </Col>
              </Row>
              <div className="mt-2">
                <PrimaryButton
                  textTranslationId={ loginButtonTranslationId }
                  formNameToSubmitOnClick="loginForm"
                />
              </div>
            </div>
          </Form>
        ) }
      </Formik>
    </>
  );
}

export default LoginForm;
