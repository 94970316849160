import { IFilterPayload } from '../reducers/filterSortReducer';
import { Dispatch } from 'redux';
import { FilterSortActionTypes } from '../types';
import { CHANGE_FILTER } from '../constants/actionTypes';

export const setFilter = (filterString: IFilterPayload) => (
  dispatch: Dispatch<any>,
): void => {
  dispatch({
    type: CHANGE_FILTER,
    payload: filterString,
  } as FilterSortActionTypes);
};
