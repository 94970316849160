import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { Device } from '@wiot/shared-domain/models/device/device';

export enum TreeNodeType {
  DEVICE_GROUP = 'DEVICE_GROUP',
  DEVICE = 'DEVICE',
  EXPAND = 'EXPAND',
  ROOT = 'ROOT',
}

export type ExpandItem = {
  totalChildren: number,
}

export type TreeNodeInnerObjectType = DeviceGroup | Device | ExpandItem;

export interface TreeNode {
  id: string;
  name: string;
  type: TreeNodeType;
  parentId: string | null;
  children: TreeNode[];
  hiddenChildren: TreeNode[];
  hasChildrenToBeLoaded: boolean;
  subTreeContainsDevices: boolean;
  innerObject: TreeNodeInnerObjectType;
}

export interface ExpandedNode {
  nodeId: string;
  childLimit: number;
}
