import * as React from 'react';
import { PageKeys } from '../../state/reducers/filterSortReducer';
import { FilterField } from './filter-field';
import useTranslation from '../../hooks/useTranslation';
import { SelectWrapperForFilters } from '../FilterBar/select/SelectWrapperForFilters';

export interface BooleanSelectProps {
  filterField: FilterField;
  pageKey: PageKeys;
}

const BooleanSelect = (props: BooleanSelectProps) => {
  const { pageKey, filterField } = props;

  const translate = useTranslation();

  const booleanValues = [ false, true ];
  const options = booleanValues.map((v: boolean) => (
    { label: String(v), value: String(v) })
  );
  return (
    <SelectWrapperForFilters
      translationId={ filterField.name }
      filterFieldKey={ filterField.stateField }
      pageKey={ pageKey }
      defaultOptions={ options }
      isMulti={ false }
      isClearable={ true }
      isSearchable={ false }
      translateOptions={ true }
      placeholderTranslationId="select"
      placeholderPrefix={ `${ translate(filterField.name) }: ` }
    />
  );
};

export default BooleanSelect;
