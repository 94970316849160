import { toastr } from 'react-redux-toastr';
import showDeleteToastrOnConflictingRoleUsage from '../../components/shared/showDeleteToastrOnConflictingRoleUsage';
import showDeleteToastrOnConflictingRadioKeys from '../../components/shared/showDeleteToastrOnConflictingRadioKeys';
import { TranslateFunction } from 'react-localize-redux';
import { RadioKey } from '@wiot/shared-domain/models/radio-key/radio-key';
import { RoleUsage } from '@wiot/shared-domain/models/user-role/role-usage';

type DeviceGroupDeletionResponse = {
  error: boolean;
  leftUserRoles?: RoleUsage[];
  leftRadioKeys?: RadioKey[];
};

export const handleDeviceGroupDeletionResponse = (translate: TranslateFunction, response: DeviceGroupDeletionResponse): boolean => {
  if (!response) {
    return false;
  }

  const { error, leftUserRoles, leftRadioKeys } = response;
  if (!error) {
    toastr.success(
      translate('success').toString(),
      translate('delete-dev-group-success').toString(),
    );
    return true;
  }

  if (leftUserRoles) {
    showDeleteToastrOnConflictingRoleUsage(translate, leftUserRoles);
  }
  if (leftRadioKeys) {
    showDeleteToastrOnConflictingRadioKeys(translate, leftRadioKeys);
  }
  return false;
};
