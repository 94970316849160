import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { FormikProps, getIn } from 'formik';
import { ISettings } from '@wiot/shared-domain/models/settings/settings';
import { CustomFieldInput } from '../../../components/Table/CustomFieldInput';
import { HorizontalDivider } from '../../../components/shared/Divider';
import { MAP_PROVIDERS } from '../../../constants';
import { validateRequiredFieldValue } from '../../../utils/validation';
import { SelectWrapperWithFormik } from '../../../components/FilterBar/select/SelectWrapperWithFormik';
import { SelectableOption } from '../../../components/FilterBar/select/selectable-option';

interface IMapSettingsProps {
  readOnly?: boolean;
  formikProps: FormikProps<ISettings>;
}

const MapSettings = (props: IMapSettingsProps) => {
  const { readOnly, formikProps } = props;
  const selectedProvider = MAP_PROVIDERS.find(
    (provider) => provider.value === formikProps.values.site?.mapTiles?.provider,
  );

  return (
    <>
      <HorizontalDivider/>
      <div className="settings-header">
        <Translate id="map-settings-header"/>
      </div>
      <Row>
        <Col sm={ 2 } lg={ 3 }>
          <label className="settings-form__label">
            <Translate id="choose-map-provider"/>
          </label>
        </Col>
        <Col sm={ 6 } lg={ 5 }>
          <SelectWrapperWithFormik<SelectableOption>
            fieldName="site.mapTiles.provider"
            options={ MAP_PROVIDERS.map(provider =>
              ({ label: provider.name, value: provider.value })) }
            onChange={ formikProps.setFieldValue }
            value={ formikProps.values.site?.mapTiles?.provider }
            hideLabel
            readOnly={ readOnly }
          />
        </Col>
      </Row>
      <HorizontalDivider/>
      { selectedProvider &&
        selectedProvider.fields.length > 0 &&
        selectedProvider.fields.map((field) => (
          <Row>
            <Col sm={ 2 } lg={ 3 }>
              <label className="settings-form__label">
                <Translate id={ `${ field.name }` }/>
              </label>
            </Col>
            <Col sm={ 6 } lg={ 5 }>
              <CustomFieldInput
                className="form-select"
                fieldName={ `site.mapTiles.${ field.name }` }
                value={ getIn(formikProps.values.site?.mapTiles, field.name) }
                readOnly={ readOnly }
                validate={ validateRequiredFieldValue }
              />
            </Col>
          </Row>
        )) }
    </>
  );
};

export default MapSettings;
