import { SetCurrentUserAction, LogoutAction } from '../types';
import { LOGOUT, SET_CURRENT_USER } from '../constants/actionTypes';
import { CURRENT_USER_DETAILS_KEY } from './currentUserReducer';

const initialState = Boolean(localStorage.getItem(CURRENT_USER_DETAILS_KEY));

export const isLoggedInReducer = (
  state: boolean = initialState,
  action: SetCurrentUserAction | LogoutAction,
): boolean => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return !!action.payload;

    case LOGOUT:
      return false;

    default:
      return state;
  }
};
