import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { LazyLog } from 'react-lazylog';
import { Translate } from 'react-localize-redux';
import { getSystemHealthLogs } from '../../../api/apiHelpers';
import LoadingIcon from '../../../components/shared/LoadingIcon';

interface SystemLogsModalProps {
  show: boolean;
  handleClose: () => void;
  component?: string;
}

const SystemLogsModal = ({ show, handleClose, component }: SystemLogsModalProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [logs, setLogs] = useState<string | undefined>(undefined);
  const [refreshInterval, setRefreshInterval] = useState<number>(300);

  const fetchSystemHealthLogs = useCallback(async () => {
    try {
      const data = component && (await getSystemHealthLogs(component));
      setLoading(false);
      data && setLogs(data);
    } catch (e) {
      console.error(e);
    }
  }, [component]);

  useEffect(() => {
    setLoading(true);
    setLogs(undefined);
    fetchSystemHealthLogs();
  }, [fetchSystemHealthLogs]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchSystemHealthLogs();
    }, refreshInterval * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [fetchSystemHealthLogs, refreshInterval]);

  const onError = (error: any) => {
    console.error(error);
  };

  const onRangeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRefreshInterval(Number(event.target.value));
  };

  return (
    <>
      {loading ? (
        <LoadingIcon />
      ) : (
        <Modal
          show={show}
          onHide={handleClose}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {component}
            </Modal.Title>
            <Container className='m-auto'>
              <Row className="justify-content-md-center">
                <Col className='text-right'><Translate id='auto-refresh' />:</Col>
                <Col>
                  <input
                    type="range"
                    min="5"
                    max="300"
                    onChange={onRangeChange}
                    value={refreshInterval}
                    step="5"
                  />
                </Col>
                <Col className='text-left'>{refreshInterval} <Translate id='seconds' /></Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <div className="health-log-modal-container">
              {logs !== undefined && (
                <LazyLog
                  text={logs}
                  onError={onError}
                  extraLines={1}
                  // @ts-ignore The types lib is not updated for new props( for ex: enableSearch)
                  enableSearch
                  follow
                />
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default SystemLogsModal;
