import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import SyncOfflineModal from './Offline/SyncOfflineModal';
import RenderOnCondition from './RenderOnCondition';

const OfflineBanner = () => {
  const [showOfflineSyncModal, setShowOfflineSyncModal] = useState(false);

  const toggleOfflineSyncModal = () => {
    setShowOfflineSyncModal((prevState) => !prevState);
  };

  return (
    <>
      <div className="offline-banner" onClick={toggleOfflineSyncModal}>
        <Translate id="offlineMode" />
      </div>
      <RenderOnCondition condition={showOfflineSyncModal}>
        <SyncOfflineModal toggleOfflineSyncModal={toggleOfflineSyncModal} />
      </RenderOnCondition>
    </>
  );
};

export default OfflineBanner;
