import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';

interface IOfflineItemProps {
  action: string;
  name?: string;
  syncState?: string;
}

const OfflineItem = ({ action, name, syncState }: IOfflineItemProps) => (
  <Row className="pt-2 pb-2">
    <Col lg={4} className="form__label">
      <Translate id={action} />
    </Col>
    <Col lg={4} className="form__label">
      {name || '-'}
    </Col>
    <Col lg={4} className="form__label">
      {syncState}
    </Col>
  </Row>
);

export default OfflineItem;
