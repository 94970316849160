import * as React from 'react';
import { Translate } from 'react-localize-redux';

const DashboardHeader = () => (
  <div className="dashboard__header text-color-main">
    <h1>
      <Translate id="welcome" />
    </h1>
  </div>
);

export default DashboardHeader;
