import React, { useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { ValueType } from 'react-select/src/types';
import { Device } from '@wiot/shared-domain/models/device/device';
import AsyncDeviceSelect from '../../components/shared/AsyncDeviceSelect';
import { ChangeDevice } from './DeviceDetailsModal/DeviceDetailsModal';
import classNames from 'classnames';

interface DeviceChangeOptionsProps extends LocalizeContextProps {
  selectedChangeOption?: ChangeDevice;
  handleOptionSelection: (selectedChangeOption: ChangeDevice) => void;
  handlePrevious: () => void;
  completeChangeAction: (newDeviceId?: string) => void;
}

const DeviceChangeOptions = ({
  selectedChangeOption,
  handleOptionSelection,
  handlePrevious,
  completeChangeAction,
}: DeviceChangeOptionsProps) => {
  const [selectedDevice, setSelectedDevice] = useState<Device | undefined>(undefined);

  const handleDeviceSelection = (value: ValueType<Device>) => {
    // @ts-ignore
    value && setSelectedDevice(value);
  };

  const handleCompleteClick = () => {
    selectedDevice && selectedChangeOption === ChangeDevice.USE_EXISTING
      ? completeChangeAction(selectedDevice.id)
      : completeChangeAction();
  };

  const getSaveButton = () => {
    let buttonInner;
    switch (selectedChangeOption) {
      case ChangeDevice.REMOVE_DEVICE:
      case ChangeDevice.USE_EXISTING:
        buttonInner = <Translate id="save" />;
        break;
      case ChangeDevice.CREATE_NEW:
        buttonInner = <Translate id="next" />;
        break;
      default:
        buttonInner = undefined;
    }

    return (
      buttonInner && (
        <button
          type="button"
          className="form__button--blue background-color-main text-color-white border-color-main"
          onClick={handleCompleteClick}
        >
          {buttonInner}
        </button>
      )
    );
  };

  return (
    <div>
      <ListGroup>
        <ListGroup.Item
          className="device-change-option"
          action
          onClick={() => handleOptionSelection(ChangeDevice.USE_EXISTING)}
          active={selectedChangeOption === ChangeDevice.USE_EXISTING}
        >
          <Translate id={ChangeDevice.USE_EXISTING} />
        </ListGroup.Item>
        {selectedChangeOption === ChangeDevice.USE_EXISTING && (
          <div className="mt-2 mb-5 mx-1">
            <AsyncDeviceSelect
              handleDeviceSelection={handleDeviceSelection}
              selectedDevice={selectedDevice}
            />
          </div>
        )}
        <ListGroup.Item
          className={ classNames('device-change-option', { 'mt-4': selectedChangeOption === ChangeDevice.USE_EXISTING }) }
          action
          onClick={() => handleOptionSelection(ChangeDevice.CREATE_NEW)}
          active={selectedChangeOption === ChangeDevice.CREATE_NEW}
        >
          <Translate id={ChangeDevice.CREATE_NEW} />
        </ListGroup.Item>
        <ListGroup.Item
          className="device-change-option"
          action
          onClick={() => handleOptionSelection(ChangeDevice.REMOVE_DEVICE)}
          active={selectedChangeOption === ChangeDevice.REMOVE_DEVICE}
        >
          <Translate id={ChangeDevice.REMOVE_DEVICE} />
        </ListGroup.Item>
      </ListGroup>

      <div className="details__row--right justify-content-end mt-4">
        <button
          type="button"
          className="form__button--blue background-color-main text-color-white border-color-main mr-2"
          onClick={handlePrevious}
        >
          <Translate id="back" />
        </button>
        {getSaveButton()}
      </div>
    </div>
  );
};

export default withLocalize(DeviceChangeOptions);
