import EmailValidator from 'email-validator';
import HttpStatus from 'http-status-codes';
import { InvalidEmailError } from '../errors/invalid-email-error';

export class DataValidator {
  // Number.isFinite() differs from global isFinite()
  public static isNumeric = (num: any) => !Number.isNaN(parseFloat(num)) && isFinite(num);

  public static validateEmail(email: any): boolean {
    return EmailValidator.validate(email);
  }

  /**
   * Validates and formats the email . Throws exception if not valid
   * @deprecated Use the validateEamailAndFormatToLowercase without the res(ponse) parameter
   */
  public static validateAndFormatEmail(email: string, res: any): string {
    if (!EmailValidator.validate(email)) {
      throw Object({
        message: res.__('GLOBAL.InvalidEmail', { email }),
        statusCode: HttpStatus.BAD_REQUEST,
      });
    }

    return email.toLowerCase();
  }

  /**
   * Validates and formats the email. Throws InvalidEmailError if not valid.
   */
  public static validateEmailAndFormatToLowercase(email: string): string {
    if (!EmailValidator.validate(email)) {
      throw new InvalidEmailError();
    }

    return email.toLowerCase();
  }

  public static validateManufacturerFlagId(flagId: string): boolean {
    if (flagId.match(/^[A-Z]{3}$/)) {
      return true;
    }
    return false;
  }

  public static validateTag(v: any): boolean {
    // Should contain no hashtag #
    const match = v.toString().match(/#/gi);
    return !match;
  }

  /**
   * ^                The password string will start this way
   * (?=.*[a-z])      The string must contain at least 1 lowercase alphabetical character
   * (?=.*[A-Z])      The string must contain at least 1 uppercase alphabetical character
   * (?=.*[0-9])      The string must contain at least 1 numeric character
   * (?=.[!@#\$%\^&]) The string must contain at least one special character,
   *                  but we are escaping reserved RegEx characters to avoid conflict
   * (?=.{8,})        The string must be eight characters or longer
   */
  public static PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[<>|/{}[\]()=ß?\\!"§,;.:+~\-'°^_@#$%&*])(?=.{8,})/;

  public static validatePasswordComplexity(password: string): boolean {
    return DataValidator.PASSWORD_REGEX.test(password);
  }
}
