
export const VALIDATE_USER = 'FETCH_DEVICE_KEY_PROTECTION_INFO';
export const USER_IS_VALIDATED = 'USER_WAS_VALIDATED';

export interface ValidateUserAction {
  type: typeof VALIDATE_USER;
  userId: string;
}

export interface UserIsValidatedAction {
  type: typeof USER_IS_VALIDATED;
  email: string;
  wasAlreadyValidated: boolean;
}
