import React from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { Col, Row } from 'react-bootstrap';

import { IXmlValidationResult } from '@wiot/shared-domain/models/data-integration/oms-key-xml';
import { BlobWithPath } from '@wiot/shared-domain/models/data-integration/data-integration';
import XmlValidationResultSuccess from './Oms/XmlValidationResultSuccess';
import XmlOmsMasterKeyInput from './Oms/XmlOmsMasterKeyInput';
import DeviceGroupImportPreview from '../Shared/DeviceGroupImportPreview';
import UserImportPreview from '../Csv/UserImportPreview';
import DeviceImportPreview from '../Shared/DeviceImportPreview';
import RenderOnCondition from '../../../../components/RenderOnCondition';

interface IXmlValidationResultProps extends LocalizeContextProps {
  result: IXmlValidationResult;
  file: BlobWithPath;
  omsXmlMasterKey?: string;
}

const XmlValidationResult = (props: IXmlValidationResultProps) => {
  const { result, file, omsXmlMasterKey } = props;
  const { err, newObjects = { keys: [] } } = result;
  const { keys, deviceGroups, devices, users, deviceReadingCount } = newObjects;

  if (err?.msg) {
    if (err.msg === 'aes-kw-integrity-check-failed') {
      return <XmlOmsMasterKeyInput file={file} omsXmlMasterKey={omsXmlMasterKey} />;
    }

    return (
      <>
        <div className="form__section">
          <h4 className="form__subheading">
            <Translate id="error" />
          </h4>
          <div className="form__section">
            <div className="form__row standard-font-size">
              <Translate id="xml-validation-failed" />:
            </div>
          </div>
          <div className="form__section">
            <div className="form__row standard-font-size">
              <Translate id={err.msg} />.
            </div>
          </div>
        </div>
        <div className="form__section">
          <div className="form__row standard-font-size">
            <Translate id="please-try-other-file-or-contact-administrator" />.
          </div>
        </div>
      </>
    );
  }

  if (keys && keys.length) {
    return <XmlValidationResultSuccess file={file} objects={keys} />;
  }

  return (
    <>
      <RenderOnCondition condition={deviceGroups}>
        <DeviceGroupImportPreview groupsList={deviceGroups!} />
      </RenderOnCondition>
      <RenderOnCondition condition={users}>
        <UserImportPreview users={users!} />
      </RenderOnCondition>
      <RenderOnCondition condition={deviceReadingCount}>
        <Row className="form__title">
          <Col>
            <Translate id="preview-device-readings-count" data={{ count: deviceReadingCount }} />
          </Col>
        </Row>
      </RenderOnCondition>
      <RenderOnCondition condition={devices}>
        <DeviceImportPreview devices={devices!} />
      </RenderOnCondition>
    </>
  );
};

export default withLocalize(XmlValidationResult);
