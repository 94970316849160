import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { LocalizeContextProps, Translate, withLocalize, } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import CustomCheckbox from '../../../components/shared/CustomCheckbox';
import { AppState } from '../../../state/reducers/rootReducer';
import { localizeDateOnly } from '../../../utils/common';
import LoadingIcon from '../../../components/shared/LoadingIcon';

/**
 * A component that displays a list of licenses of the platform.
 */
const LicensesList = ({ translate }: LocalizeContextProps) => {
  const [showOnlyActiveLicenses, setShowOnlyActiveLicenses] =
    React.useState<boolean>(true);

  const licensingState = useSelector((state: AppState) => state.licensing);
  let licenses = licensingState.overview?.licenses ?? [];
  const isLoadingLicenseOverview = licensingState.isLoadingLicenseOverview;

  if (showOnlyActiveLicenses) {
    licenses = licenses.filter((a) => a.isLicenseActive);
  }

  if (isLoadingLicenseOverview) {
    return <LoadingIcon/>;
  }

  return (
    <>
      <Table responsive className="standard-font-size">
        <thead>
          <tr>
            <th className="license-settings__table__wide-column">
              <Translate id="period" />
            </th>
            <th className="license-settings__table__wide-column">
              <Translate id="device-type" />
            </th>
            <th className="license-settings__table__narrow-column">
              <Translate id="devices" />
            </th>
          </tr>
        </thead>
        <tbody>
          {licenses.map((license, index) => (
            <tr
              key={index}
              className={ license.isLicenseActive ? '' : 'license__inactive' }
            >
              <td>
                {localizeDateOnly(license.validFromUtc)} -{' '}
                {localizeDateOnly(license.validToUtc)}
              </td>
              <td>{translate(license.deviceType)}</td>
              <td>{license.numberOfDevices}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="standard-font-size">
        <label htmlFor="show-only-active-licenses">
          <Translate id="show-only-active-licenses" />
        </label>
        <CustomCheckbox
          id="show-only-active-licenses"
          readOnly
          checked={showOnlyActiveLicenses}
          onClick={() => setShowOnlyActiveLicenses(!showOnlyActiveLicenses)}
        />
      </div>
    </>
  );
};

export default withLocalize(LicensesList);
