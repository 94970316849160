import { combineReducers } from 'redux';
import { deviceTreeExpandStateReducer } from './expand-state-tracker/deviceTreeExpandStateReducer';
import { isDeviceTreeLoadingReducer } from './tree-loading-indication/isDeviceTreeLoadingReducer';
import { deviceTreeNodesReducer } from './fetch-device-tree/deviceTreeNodesReducer';

export const deviceTreeReducer = combineReducers({
  nodes: deviceTreeNodesReducer,
  expandState: deviceTreeExpandStateReducer,
  isLoading: isDeviceTreeLoadingReducer,
});
