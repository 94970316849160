import L from 'leaflet';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import { getDeviceTypeIconFullPath } from '../../utils/common';
import { IconVariant } from '../../constants';

const leafletDeviceTypeIcon = (deviceType: IDeviceType | undefined): L.Icon =>
  new L.Icon({
    iconUrl: getDeviceTypeIconFullPath(deviceType, IconVariant.small),
    iconSize: new L.Point(35, 35),
    className: 'leaflet-div-icon',
  });

export { leafletDeviceTypeIcon };
