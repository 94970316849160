import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { validateUser } from '../../state/user/validateUserActionCreator';
import { useDispatch, useSelector } from 'react-redux';
import RenderOnCondition from '../RenderOnCondition';
import PrimaryButton from '../shared/PrimaryButton';
import { AppState } from '../../state/reducers/rootReducer';
import { Translate } from 'react-localize-redux';
import api from '../../api/api';
import { DEVICE_KEY_PATH } from '../../navigation/paths';

function ValidateUser() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { downloadHash, userId } = useParams<{ downloadHash: string; userId: string }>();
  const {
    email: emailOfValidatedUser,
    wasAlreadyValidated,
  } = useSelector((state: AppState) => state.userValidationResult);
  const logo = `${ api.baseAPIUrl }${ api.customizeLogo }`;
  let isValidating = true;
  if (wasAlreadyValidated || emailOfValidatedUser) {
    isValidating = false;
  }

  useEffect(() => {
    if (userId) {
      dispatch(validateUser(userId));
    }
  }, [ userId, downloadHash ]);

  const getValidationResultText = (): JSX.Element => {
    if (emailOfValidatedUser) {
      return (
        <Translate id="validate-user_successfully_validated"
                   data={ { censoredEmailOfValidatedUser: emailOfValidatedUser } }/>
      );
    }

    return <Translate id="validate-user_email_already_validated"/>;
  };

  return (
    <>
      <div className="app pt-0">
        <header className="header">
          <>
            <div className="centered w-100">
              <img src={ logo } alt="logo" className="header__brand"/>
              <div className="ml-1">
                <Translate id="validate-user"/>
              </div>
            </div>
          </>
        </header>
        <div className="mt-4 pt-5 ml-5 main-grid">
          <RenderOnCondition condition={ isValidating }>
            <div>Validating...</div>
          </RenderOnCondition>
          <RenderOnCondition condition={ !isValidating }>
            <div>
              <p>
                { getValidationResultText() }
              </p>
              <PrimaryButton
                textTranslationId="device-key-download_go-to-login"
                onClick={ () => history.push(`${DEVICE_KEY_PATH}/${ downloadHash }/${ emailOfValidatedUser }`) }/>
            </div>
          </RenderOnCondition>
        </div>
      </div>
    </>
  );
}

export default ValidateUser;
