import { TreeNode } from '@wiot/shared-domain/domain/device-tree/tree-node';
import { FetchSubTreeAction, SUB_TREE_FETCHED, SubTreeFetchedAction } from './subTreeActions';
import { DEVICE_KEY_ASSIGNED_TO_USER } from '../device-key/deviceKeyActionTypes';
import { DeviceKeyAssignedToUserAction } from '../device-key/assignDeviceKeyToCurrentUserActions';

export type DeviceHierarchySubTree = {
  children: TreeNode[];
  root: TreeNode | null;
  isDeviceHierarchyOutdated: boolean;
};

const initialState: DeviceHierarchySubTree = {
  children: [],
  root: null,
  isDeviceHierarchyOutdated: false,
};

export const subTreeReducer = (
  state: DeviceHierarchySubTree = initialState,
  action: FetchSubTreeAction | SubTreeFetchedAction | DeviceKeyAssignedToUserAction,
): DeviceHierarchySubTree => {
  switch (action.type) {
    case DEVICE_KEY_ASSIGNED_TO_USER:
      return {
        ...state,
        isDeviceHierarchyOutdated: true,
      };

    case SUB_TREE_FETCHED:
      return {
        ...state,
        children: action.subTreeRoot.children,
        root: action.subTreeRoot,
        isDeviceHierarchyOutdated: false,
      };
    default:
      return state;
  }
};
