import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { Tab, Tabs } from 'react-bootstrap';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { ISettings, ISite } from '@wiot/shared-domain/models/settings/settings';
import { DispatchActionTypes } from '../../state/types';
import { setSiteSettings as setSiteSettingsAction } from '../../state/actions/setSiteSettingsActions';
import { getSettingsInDB, updateSettingsInDB } from '../../api/apiHelpers';
import EmailSettings from './Email/EmailSettings';
import SystemHealth from './SystemHealth/SystemHealth';
import UpdateAndLicenses from './UpdateAndLicenses/UpdateAndLicenses';
import SiteSettings from './Site/SiteSettings';
import SupportSettings from './Support/FeedbackSettings';
import Backups from './Backups/Backups';
import { AppState } from '../../state/reducers/rootReducer';
import { hasPermission, setDocumentTitle } from '../../utils/common';
import RenderOnCondition from '../../components/RenderOnCondition';
import MainGrid from '../../components/shared/MainGrid';
import PropertyViewSettings from './PropertyView/PropertyViewSettings';
import { PropertySettings } from '@wiot/shared-domain/models/settings/property-settings';

enum TABS {
  SITE = 'site',
  EMAIL = 'email',
  HEALTH = 'health',
  SUPPORT = 'support',
  PROPERTY_VIEW = 'property-view',
  BACKUPS = 'backups',
  UPDATE_AND_LICENSES = 'update-and-licenses'
}

const FORM_TABS = [TABS.SITE, TABS.EMAIL, TABS.SUPPORT, TABS.PROPERTY_VIEW];

interface SettingsProps extends LocalizeContextProps {
  setSiteSettings: (settings: ISite) => void;
}

const Settings = ({ setSiteSettings, translate }: SettingsProps) => {
  const initialState: ISettings = {
    emailProvider: undefined,
    site: {
      limitOfDevicesWeFetchMeasurementsFor: -1,
      support: {
        isFeedbackEnabled: false,
        feedbackFormEmail: '',
      },
    },
    smtp: {
      port: '',
      connectionSecurity: '',
      authenticationMethod: '',
      senderName: '',
      senderEmail: '',
    },
    sendgrid: {
      apiKey: '',
      templateId: {
        registration: '',
        resetPassword: '',
      },
      senderName: '',
      senderEmail: '',
    },
    frontendUrl: '',
    backendUrl: '',
  };

  const [initialValues, setInitialValues] = useState(initialState);
  const [activeTab, setActiveTab] = useState<TABS>(TABS.SITE);

  const userPermission = useSelector<AppState>((state) => state.currentUser.permission);
  const hasEditPermission = hasPermission(userPermission, 'settings.edit');

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const { settings } = await getSettingsInDB();
        setInitialValues(settings);
      } catch (e) {
        console.error(e);
      }
    };
    fetchSettings();
  }, []);

  const handleTabSelect = (eventKey: string) => {
    // @ts-ignore
    setActiveTab(eventKey);
  };

  const handleSubmit = (values: ISettings) => {
    setSiteSettings(values?.site || {});
    setDocumentTitle(values?.site?.title);
    updateSettingsInDB(values)
      .then((data) => {
        data &&
        toastr.success(translate('success').toString(), translate('settings-saved').toString());
      })
      .catch((err) => {
        toastr.warning(translate('warning').toString(), translate('settings-not-saved').toString());
        toastr.warning(translate('warning').toString(), err.message || err);
      });
  };

  return (
    <MainGrid dataTestId="page-keys">
      <div className="settings-container">
        <Tabs
          id="settings-tab"
          className="settings-tab"
          activeKey={ activeTab }
          onSelect={ handleTabSelect }
        >
          <Tab eventKey={ TABS.SITE } title={ translate('site') }>
            <SiteSettings
              tabKey={ TABS.SITE }
              handleSubmit={ handleSubmit }
              initialValues={ initialValues }
              readOnly={ !hasEditPermission }
            />
          </Tab>
          <Tab eventKey={ TABS.EMAIL } title={ translate('email') }>
            <EmailSettings
              tabKey={ TABS.EMAIL }
              handleSubmit={ handleSubmit }
              initialValues={ initialValues }
              readOnly={ !hasEditPermission }
            />
          </Tab>
          <Tab eventKey={ TABS.SUPPORT } title={ translate('support') }>
            <SupportSettings
              tabKey={ TABS.SUPPORT }
              handleSubmit={ handleSubmit }
              initialValues={ initialValues }
              readOnly={ !hasEditPermission }
            />
          </Tab>
            <Tab eventKey={ TABS.PROPERTY_VIEW } title={ translate('property-view') }>
              <PropertyViewSettings
                tabKey={ TABS.PROPERTY_VIEW }
                handleSubmit={ (propertySettings: PropertySettings) => handleSubmit({ ...initialValues, site: { ...initialValues.site, globalPropertySettings: propertySettings } }) }
                initialValues={ initialValues.site?.globalPropertySettings }
                readOnly={ !hasEditPermission }
              />
          </Tab>
          <Tab eventKey={ TABS.HEALTH } title={ translate('system') }>
            <SystemHealth/>
          </Tab>
          <Tab eventKey={ TABS.BACKUPS } title={ translate('backups') }>
            <Backups readOnly={ !hasEditPermission }/>
          </Tab>
          <Tab eventKey={ TABS.UPDATE_AND_LICENSES } title={ translate('update-and-licenses') }>
            <UpdateAndLicenses readOnly={ !hasEditPermission }/>
          </Tab>
        </Tabs>
        <RenderOnCondition condition={ FORM_TABS.includes(activeTab) }>
          <button
            className="form__button--blue background-color-main text-color-white border-color-main settings-submit-btn"
            type="submit"
            disabled={ !hasEditPermission }
            form={ `${ activeTab }-settings` }
          >
            <Translate id="save-settings" />
          </button>
        </RenderOnCondition>
      </div>
    </MainGrid>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  setSiteSettings: (settings: ISite) => dispatch(setSiteSettingsAction(settings)),
});

export default withLocalize(connect(null, mapDispatchToProps)(Settings));
