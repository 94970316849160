import * as React from 'react';

interface ClosingIconProps {
  stroke?: string;
}

const ClosingIcon = (props: ClosingIconProps) => (
  <svg style={{ height: 20, width: 20 }}>
    <path stroke={props.stroke || 'white'} strokeWidth="2" fill="none" d="M5,5,15,15" />
    <path stroke={props.stroke || 'white'} strokeWidth="2" fill="none" d="M5,15,15,5" />
  </svg>
);

export default ClosingIcon;
