import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { Device } from '@wiot/shared-domain/models/device/device';
import { Ancestor, DeviceGroup, DeviceGroupType } from '@wiot/shared-domain/models/device-group/device-group';
import { filterOutSensitiveDataFromDeviceGroup } from '../../components/Feedback/filter-sensitive-data';

import { IUser } from '@wiot/shared-domain/models/user/user';
import RenderOnCondition from '../../components/RenderOnCondition';
import { LabeledCol } from '../../components/shared/LabeledCol';
import ModalHeader from '../../components/Modal/ModalHeader';
import QUESTION_MARK from '../../assets/question-mark.svg';
import { getDeviceGroupPath } from '../../utils/device-group-path-helper';
import { fetchDeviceGroupFromDB } from '../../api/apiHelpers';
import { deviceGroupIcons } from '../../constants';
import LoadingIcon from '../../components/shared/LoadingIcon';
import { AppState } from '../../state/reducers/rootReducer';
import { getRandomModalOffset } from '../../utils/dialog';
import { FeedbackAttachment } from '../../components/Feedback/feedback';
import useTranslation from '../../hooks/useTranslation';

export interface DeviceGroupDetailsProps {
  closeModal: (event: React.MouseEvent | KeyboardEvent) => void;
  deviceGroupData: DeviceGroup;
}

const DeviceGroupDetails = (props: DeviceGroupDetailsProps) => {
  const [deviceGroup, setDeviceGroup] = useState<DeviceGroup | null>(null);
  const [devicesToShow, setDevicesToShow] = useState<number>(5);
  const [usersToShow, setUsersToShow] = useState<number>(5);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [randomOffset] = useState(getRandomModalOffset());
  const [modalUid] = useState(uuidv4());
  const isMobileDisplay = useSelector((state: AppState) => state.isMobileDisplay);
  const isKeyManagerModeEnabled = useSelector((state: AppState) => !!state.siteSettings.isKeyManagerModeEnabled);
  const isFeedbackEnabled = useSelector((state: AppState) => !!state.siteSettings.support?.isFeedbackEnabled);
  const translate = useTranslation();

  const {
    id,
    name,
    type,
    ancestors,
    externalId,
    notes,
    abbreviation,
    deviceList,
    users,
    propertySettings
  } = deviceGroup || props.deviceGroupData;

  useEffect(() => {
    const fetchDeviceGroup = async () => {
      const  deviceGroup = await fetchDeviceGroupFromDB(id!, true, true);
      setDeviceGroup(deviceGroup);
    };
    fetchDeviceGroup().then(() => {
      setIsLoading(false);
    });
  }, [id]);

  const handleClose = (event: React.MouseEvent | KeyboardEvent) => {
    props.closeModal(event);
  };

  const getShowMoreButton = (tableType: string) => {
    if (users && tableType === 'users' && usersToShow < users.length) {
      return (
        <button
          type="button"
          onClick={ () => {
            setUsersToShow(users.length);
          } }
          className="device-manager__table__header__add-button device-manager__table__header__add-button--all"
        >
          <Translate id="show-all"/>
        </button>
      );
    }
    if (deviceList?.devices && tableType === 'devices' && devicesToShow < deviceList?.devices.length) {
      const totalDevices = deviceList.totalDevices;
      const countOfAvailableDevices = deviceList.devices.length;


      const translationId = deviceList.countOfDevicesIsLimited ? 'show-first-x' : 'show-all';
      return (
        <button
          type="button"
          onClick={ () => {
            setDevicesToShow(deviceList?.devices.length);
          } }
          className="device-manager__table__header__add-button device-manager__table__header__add-button--all"
        >
          <Translate id={ translationId } data={ { count:countOfAvailableDevices } }/>
        </button>
      );
    }
    return null;
  };

  const renderTable = (tableType: string, headings: string[], values: any) => (
    <>
      <table className="device-manager__table__table">
        <thead>
        <tr className="details__headings--table">
          { headings.map((h, i) => (
            <th key={ i }>
              <Translate id={ h }/>
            </th>
          )) }
        </tr>
        </thead>
        <tbody>
        { values.map((val: any, i: number) => (
          <tr className="details__text details__text--messages" key={ i }>
            { val.map((v: any, j: number) => (
              <td style={ { maxWidth: '205px', paddingLeft: '2px' } } key={ j }>
                { v }
              </td>
            )) }
          </tr>
        )) }
        </tbody>
      </table>
      { getShowMoreButton(tableType) }
    </>
  );

  const getUsersTable = () => {
    if (users && users.length) {
      return renderTable(
        'users',
        ['user-name', 'email'],
        users.slice(0, usersToShow).map((u: IUser) => [u.displayName, u.email || '-']),
      );
    }
    return <Translate id="no-data"/>;
  };

  const getDeviceTable = () => {
    if (deviceList && deviceList?.devices.length) {
      if (isMobileDisplay) {
        return renderTable(
          'devices',
          ['device-name', 'device-id'],
          deviceList.devices.slice(0, devicesToShow).map((device: Device) => [device.name, device.deviceId]),
        );
      }
      return renderTable(
        'devices',
        ['device-name', 'device-id'],
        deviceList.devices.slice(0, devicesToShow).map((device: Device) => [device.name, device.deviceId]),
      );
    }
    return <Translate id="no-data"/>;
  };

  const getSelectedTree = (selectedDeviceGroup: DeviceGroup) => {
    const path = getDeviceGroupPath(selectedDeviceGroup);
    return path.truncatedPath || '-';
  };

  const getAttachmentForFeedback = (): FeedbackAttachment | null => {
    if (!deviceGroup && !props.deviceGroupData) {
      return null;
    }
    return {
      deviceGroup: filterOutSensitiveDataFromDeviceGroup(deviceGroup || props.deviceGroupData)
    };
  };

  const activeUserCount = users && users.length ? users.length : 0;
  const activeDeviceCount = deviceList && deviceList.totalDevices ? deviceList.totalDevices : 0;

  const getModalBody = () => {
    if (isLoading) {
      return <LoadingIcon />;
    }

    return (
      <>
        <div className="details__section">
          <Row className="details__row">
            <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
              <Col lg={ 4 } className="text-center">
                <img
                  src={ (type && deviceGroupIcons[type as DeviceGroupType]) || QUESTION_MARK }
                  className="thumbnail"
                  alt="device type"
                />
              </Col>
            </RenderOnCondition>

            <Col lg={ isKeyManagerModeEnabled ? 12 : 8 }>
              <Row>
                <Col lg={6}>
                  <label className="details__label">
                    <Translate id="group-type"/>
                    <Translate>
                      {({ translate }) => (
                        <p className="details__text">{ type ? translate(type) : '-' }</p>
                      )}
                    </Translate>
                  </label>
                </Col>
                <RenderOnCondition condition={ abbreviation }>
                  <LabeledCol
                    labelTranslationId="abbreviation"
                    content={abbreviation}
                    lg={6}
                    detailedTextClassName="details__text__abbreviation text-overflow-ellipsis"
                    showTooltip={true}
                  />
                </RenderOnCondition>
              </Row>
              <Row>
                <LabeledCol
                  labelTranslationId="hierarchy"
                  content={getSelectedTree(deviceGroup || props.deviceGroupData)}
                  lg={12}
                  detailedTextClassName="details__text__hierarchy"
                  showTooltip={true}
                />
              </Row>
              <RenderOnCondition condition={ propertySettings?.isProperty }>
                <Row>
                  <LabeledCol
                    labelTranslationId="property"
                    content={translate("device-group-is-flagged-as-property").toString()}
                  />
                </Row>
              </RenderOnCondition>
              <RenderOnCondition condition={ notes }>
                <Row>
                  <LabeledCol
                    labelTranslationId="notes"
                    content={notes}
                  />
                </Row>
              </RenderOnCondition>
              <RenderOnCondition condition={ externalId }>
                <Row>
                  <LabeledCol
                    labelTranslationId="external-id"
                    content={externalId}
                  />
                </Row>
              </RenderOnCondition>
            </Col>
          </Row>
        </div>

        <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
          <div className="details__section">
            <h4 className="details__headings">
              { activeUserCount } <Translate id="assigned-users"/>
            </h4>
            { getUsersTable() }
          </div>
        </RenderOnCondition>

        <div className="details__section">
          <h4 className="details__headings">
            { activeDeviceCount } <Translate id="assigned-devices"/>
          </h4>
          { getDeviceTable() }
        </div>
      </>
    );
  };

  return (
    <div
      tabIndex={ 0 } // make it focusable
      style={ randomOffset }
      className="device-modal"
      id={ `details-of-group-${ modalUid }` }
      onClick={ (event: React.MouseEvent) => event.stopPropagation() }
    >
      <ModalHeader
        isDevice={ false }
        titleTranslationId="details-of-group"
        targetId={ modalUid }
        titlePostfix={ name }
        handleClose={ handleClose }
        enableFeedbackSubmission={isFeedbackEnabled}
        getFeedbackAttachment={ getAttachmentForFeedback }
      />
      <div className="device-modal__body details">{ getModalBody() }</div>
    </div>
  );
};

export default DeviceGroupDetails;
