import React, { useEffect } from 'react';
import { fetchManufacturersFromDB } from '../../api/apiHelpers';
import { SelectProps } from '../FilterBar/select/components/select-props';
import { SelectWrapperForFilters } from '../FilterBar/select/SelectWrapperForFilters';
import { SelectableOption } from '../FilterBar/select/selectable-option';

const FilterManufacturerSelect = ({ pageKey }: SelectProps) => {
  const [options, setOptions] = React.useState<SelectableOption[]>([]);

  useEffect(() => {
    const fetchOptions = async () => {
      const manufacturers = await fetchManufacturersFromDB();
      let manufacturerOptions: SelectableOption[] = [];
      if (Array.isArray(manufacturers) && manufacturers.length > 0) {
        manufacturerOptions = manufacturers.map((manufacturer) => ({
          label: `${manufacturer.flagId} - ${manufacturer.name}`,
          value: manufacturer.flagId,
        }));
      }
      setOptions(manufacturerOptions);
    };
    fetchOptions();
  }, []);

  return (
    <SelectWrapperForFilters<SelectableOption>
      translationId="manufacturer"
      pageKey={ pageKey }
      filterFieldKey="manufacturers"
      defaultOptions={ options }
    />
  );
};

export default FilterManufacturerSelect;
