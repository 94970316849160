import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';

export const DEVICE_TYPES_FETCHED = 'DEVICE_TYPES_FETCHED';
export const FETCH_DEVICE_TYPES = 'FETCH_DEVICE_TYPES';

export interface FetchDeviceTypesAction {
  type: typeof FETCH_DEVICE_TYPES;
}

export interface DeviceTypesFetchedAction {
  type: typeof DEVICE_TYPES_FETCHED;
  deviceTypes: IDeviceType[];
}
