import { Dispatch } from 'redux';
import { EmailCredentials, UserDetails } from '@wiot/shared-domain/models/auth/auth-api';
import { ProtectionDetails } from '@wiot/shared-domain/models/device-key/protection-details';
import { authenticatedInstance as httpClientInstance, handleApiError, loginUser } from '../../api/apiHelpers';
import { ASSIGN_DEVICE_KEY_TO_USER, DEVICE_KEY_ASSIGNED_TO_USER } from './deviceKeyActionTypes';
import { handleProtectionDetailsResponse } from './fetchDeviceKeyProtectionActionCreators';
import { setCurrentUserAndPermissions } from '../actions/loginActionCreators';
import { DeviceKeyAssignedToUserAction } from './assignDeviceKeyToCurrentUserActions';


export const assignDeviceKeyToCurrentUser =
  (downloadHash: string, userDetails: UserDetails) =>
    async (dispatch: Dispatch<any>) => {
    dispatch({ type: ASSIGN_DEVICE_KEY_TO_USER, downloadHash, user: userDetails });

    try {
      const url = `/deviceKey/${ downloadHash }/currentUser`;
      const { data: protectionDetails } = await httpClientInstance.put<ProtectionDetails>(url, { downloadHash });
      handleProtectionDetailsResponse(downloadHash, dispatch, protectionDetails);
      setCurrentUserAndPermissions(userDetails, dispatch);

      dispatch({
        type: DEVICE_KEY_ASSIGNED_TO_USER,
        downloadHash,
        protectionDetails,
        user: userDetails,
      } as DeviceKeyAssignedToUserAction);
    } catch (error) {
      handleApiError(error);
    }
  };

export const loginAndAssignDeviceKey =
  (credentials: EmailCredentials, downloadHash: string) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch({ type: ASSIGN_DEVICE_KEY_TO_USER, downloadHash });

    const userDetails = await loginUser(credentials);
    if (userDetails) {
      dispatch(assignDeviceKeyToCurrentUser(downloadHash, userDetails));
    }
  };
