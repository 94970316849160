import React, { useEffect, useState } from 'react';
import { MapContainer, Marker } from 'react-leaflet';
import L from 'leaflet';
import { useSelector } from 'react-redux';
import { DEVICE_MANAGER_PAGE_KEY } from '@wiot/shared-domain/domain/device-manager/device-manager-page-key';
import { leafletDeviceTypeIcon } from '../../../components/Map/icons';
import CustomTileLayer from '../../../components/Map/CustomTileLayer';
import { AppState } from '../../../state/reducers/rootReducer';
import { getFetchOptions } from '../../../utils/common';
import { fetchGeoDevices } from '../../../api/apiHelpers';
import { UpdateBoundComponent } from './UpdateBoundComponent';
import MapViewPopup from './MapViewPopup';
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM } from '../../../constants';
import { IGeoDevice } from './geo-device';
import 'leaflet/dist/leaflet.css';

const MapView = () => {
  const filter = useSelector((state: AppState) => state.filters.filter[DEVICE_MANAGER_PAGE_KEY]);
  const [geoDevices, setGeoDevices] = useState<IGeoDevice[]>([]);

  useEffect(() => {
    const fetchDeviceGeoLocations = async () => {
      const fetchOptions = getFetchOptions(undefined, undefined, filter);
      const devices = await fetchGeoDevices({
        ...fetchOptions,
        filters: {
          ...fetchOptions.filters,
          deviceGroups:fetchOptions.filters?.deviceGroups ||[],
          'deviceMetadata.deviceInfo_all_elemMatch': [
            { key: 'latitude', value: undefined },
            { key: 'longitude', value: undefined },
          ],
        },
      });
      setGeoDevices(devices);
    };
    fetchDeviceGeoLocations();
  }, [filter]);

  const latitudesLongitudes = geoDevices.map((g) => new L.LatLng(g.latitude, g.longitude));
  const currentLatLngBounds = L.latLngBounds(latitudesLongitudes);

  return (
    <MapContainer
      bounds={currentLatLngBounds.isValid() ? currentLatLngBounds : undefined}
      center={DEFAULT_MAP_CENTER}
      zoom={DEFAULT_MAP_ZOOM}
      className="map-container"
    >
      <CustomTileLayer />
      <UpdateBoundComponent bounds={currentLatLngBounds} />
      {geoDevices.map((device) => (
        <Marker
          key={device.id}
          position={[device.latitude, device.longitude]}
          icon={leafletDeviceTypeIcon(device.deviceType)}
        >
          <MapViewPopup device={device} />
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MapView;
