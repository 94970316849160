import * as React from 'react';
import { Redirect, Route } from 'react-router';

interface LoginRouteProps {
  path: string;
  component: React.ComponentType<any>;
  auth: boolean;
}

export const LoginRoute = ({ component: Component, ...rest }: LoginRouteProps) => (
  <Route
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    render={(props) =>
      // eslint-disable-next-line react/jsx-props-no-spreading
      rest.auth ? <Redirect to="/" /> : <Component {...props} />
    }
  />
);
