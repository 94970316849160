import { LicensingOverview } from '@wiot/shared-domain/domain/licensing/LicensingOverview';

export const FETCH_LICENSING_OVERVIEW = 'FETCH_LICENSING_OVERVIEW';
export const FETCH_LICENSING_OVERVIEW_SUCCEEDED = 'FETCH_LICENSING_OVERVIEW_SUCCEEDED';

export interface FetchLicensingOverviewAction {
  type: typeof FETCH_LICENSING_OVERVIEW;
}

export interface FetchLicensingOverviewSucceededAction {
  type: typeof FETCH_LICENSING_OVERVIEW_SUCCEEDED;
  licensingOverview: LicensingOverview;
}
