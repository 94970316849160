import { Formik } from 'formik';
import React, { useState } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { toastr } from 'react-redux-toastr';
import { IContactForm } from '@wiot/shared-domain/models/settings/settings';
import { sendWithContactForm } from '../../api/apiHelpers';
import RenderOnCondition from '../RenderOnCondition';
import ContactForm from './ContactForm';

type ContactFormContainerProps = LocalizeContextProps;

const ContactFormContainer = ({ translate }: ContactFormContainerProps) => {
  const initialState: IContactForm = {
    name: '',
    subject: '',
    email: '',
    phone: '',
    message: '',
  };
  const [initialValues] = useState(initialState);
  const [isSending, setIsSending] = useState(false);
  const [sentSuccessful, setSentSuccessful] = useState(undefined);

  const handleSubmit = async (values: IContactForm) => {
    setIsSending(true);
    const { name, email, subject, phone, message } = values;
    const submitSuccess = await sendWithContactForm(name, email, subject, message, phone);
    setSentSuccessful(submitSuccess);
    if (submitSuccess) {
      toastr.success(
        translate('success').toString(),
        translate('contact-form-submit-complete').toString(),
      );
    } else {
      toastr.error(
        translate('error').toString(),
        translate('contact-form-submit-failure').toString(),
      );
    }
  };

  return (
    <>
      <Formik enableReinitialize onSubmit={handleSubmit} initialValues={initialValues}>
        {(formikProps) => (
          <form
            className="legal-details-page__form container-fluid"
            onSubmit={formikProps.handleSubmit}
          >
            <div className="legal-details-page__header">
              <Translate id="contact-request" />
            </div>
            <RenderOnCondition condition={!isSending}>
              <ContactForm formikProps={formikProps} />
              <div>
                <button
                  className="form__button--blue background-color-main text-color-white border-color-main"
                  type="submit"
                >
                  <Translate id="submit" />
                </button>
              </div>
            </RenderOnCondition>
            <div className="mt-5">
              {sentSuccessful === false && <Translate id="contact-request-couldnt-send" />}
              {sentSuccessful === true && <Translate id="contact-request-sent" />}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default withLocalize(ContactFormContainer);
