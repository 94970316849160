import React from 'react';
import RenderOnCondition from '../RenderOnCondition';
import { frontendVersion } from '../../state/reducers/versioning/isDifferentFrontendVersionAvailableReducer';

export interface VersionIndicatorProps {
  className?: string;
}

function VersionIndicator(props: VersionIndicatorProps) {
  const { className= "navbar__version-indicator" } = props;
  return (
    <div
      className={ className }
      data-testid="version-indicator"
    >
      <RenderOnCondition condition={ frontendVersion }>
        <>{ `v${frontendVersion}` }</>
      </RenderOnCondition>
    </div>
  );
}

export default VersionIndicator;
