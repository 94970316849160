import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';

interface DeviceGroupImportProps {
  groupsList: DeviceGroup[][];
}

const DeviceGroupImportPreview = ({ groupsList }: DeviceGroupImportProps) => {
  const getGroupPaths = () =>
    groupsList.map((groups) => {
      let groupPath: string | undefined;
      return groups.map((group) => {
        groupPath = groupPath ? `${groupPath}  ›  ${group.name}` : group.name;
        return groupPath;
      });
    });

  const groupPaths = getGroupPaths();
  // @ts-ignore
  // Fixme Use the spread operator instead of '.apply()'
  // eslint-disable-next-line prefer-spread
  const flattenedPaths = [].concat.apply([], groupPaths);
  const uniquePaths: string[] = Array.from(new Set(flattenedPaths));

  return (
    <div className="mb-3">
      <Row className="form__title">
        <Col>
          <Translate id="preview-groups-count" data={{ count: uniquePaths.length }} />
        </Col>
      </Row>
      {uniquePaths.map((path, index) => (
        <Row className="form__label" key={index}>
          <Col>{path}</Col>
        </Row>
      ))}
    </div>
  );
};

export default DeviceGroupImportPreview;
