export enum ModalTabEventKeys {
  GATEWAYS = 'gateways',
  BASIC = 'basic',
  MESSAGES = 'messages',
  CONSUMPTION = 'consumption',
  USERS_WITH_ACCESS = 'users-with-access',
  IMAGES = 'images',
  DESCRIPTION = 'description',
  RADIO_INFO = 'radioInfo',
  METADATA = 'metadata',
}
