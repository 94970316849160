import React from 'react';
import { OptionTypeBase } from 'react-select';
import { ActionMeta, ValueType } from 'react-select/src/types';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { IRole } from '@wiot/shared-domain/models/role/role';
import { fetchPermittedRolesFromDB } from '../../../api/apiHelpers';
import { AsyncBaseSelect } from '../../../components/FilterBar/select/AsyncBaseSelect';

interface IPermittedRoleSelect<OptionType extends OptionTypeBase> extends LocalizeContextProps {
  value: OptionType;
  onChange: (value: ValueType<OptionType>, action?: ActionMeta<OptionType>) => void;
}

const PermittedRoleSelect = ({ value, onChange, translate }: IPermittedRoleSelect<any>) => {

  const loadOptions = async (inputValue: string): Promise<IRole[]> => {
    try {
      const { permittedRoles } = await fetchPermittedRolesFromDB({
        filters: { name: inputValue },
      });
      return permittedRoles;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  return (
    <AsyncBaseSelect
      translationId="permittedRoles"
      placeholderTranslationId="permittedRoles"
      isMulti
      selectedOptions={value}
      onChange={onChange}
      loadOptions={loadOptions}
      getOptionLabel={(option) => `${option.name}` || ''}
      getOptionValue={(option) => option.id}
    />
  );
};

export default withLocalize(PermittedRoleSelect);
