import { LicensingOverview } from '@wiot/shared-domain/domain/licensing/LicensingOverview';
import { LicensingActionTypes } from './LicensingActionTypes';
import { FETCH_LICENSING_OVERVIEW, FETCH_LICENSING_OVERVIEW_SUCCEEDED } from './FetchLicensingOverviewActions';
import { ADD_LICENSE, ADD_LICENSE_FAILED, ADD_LICENSE_SUCCEEDED } from './AddLicenseActions';

/**
 * The state of the licensing reducer (see {@link licensingReducer}).
 */
export type LicensingState = {
  overview?: LicensingOverview;
  isAddingLicense: boolean;
  isLoadingLicenseOverview: boolean;
};

/**
 * The reducer for licensing related information.
 * @param state The current state.
 * @param action The action to reduce.
 * @returns The new state.
 */
export const licensingReducer = (
  state: LicensingState = { isAddingLicense: false, isLoadingLicenseOverview: false },
  action: LicensingActionTypes,
): LicensingState => {
  switch (action.type) {
    case FETCH_LICENSING_OVERVIEW:
      return {
        ...state,
        isLoadingLicenseOverview: true,
      };
    case FETCH_LICENSING_OVERVIEW_SUCCEEDED:
      return {
        ...state,
        overview: action.licensingOverview,
        isLoadingLicenseOverview: false,
      };
    case ADD_LICENSE:
      return {
        ...state,
        isAddingLicense: true,
      };
    case ADD_LICENSE_SUCCEEDED:
    case ADD_LICENSE_FAILED:
      return {
        ...state,
        isAddingLicense: false,
      };
    default:
      return state;
  }
};
