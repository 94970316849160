import { Dispatch } from 'redux';
import { AppState } from '../../reducers/rootReducer';
import { getTreeNodeById } from '../../../utils/tree-utils';
import { selectChildLimit } from '../selectChildLimit';
import { fetchTreeNodeWithChildren } from './fetchTreeNodeActionCreator';

const CHILDREN_INCREMENT = 5;

export const loadMoreChildren = (parentId: string) => async (
  dispatch: Dispatch<any>,
  getState: () => AppState,
): Promise<void> => {
  const state = getState();
  const treeNode = getTreeNodeById(parentId, [state.deviceTree.nodes.rootNode]);
  if (!treeNode) {
    throw new Error(`The node with the id(${ parentId }) was not found!`);
  }

  const currentChildLimit = selectChildLimit(state,parentId);
  const newChildLimit = currentChildLimit + CHILDREN_INCREMENT;
  dispatch(fetchTreeNodeWithChildren(parentId, newChildLimit));
};
