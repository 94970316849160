import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { GraphAPIData } from '@wiot/shared-domain/models/dashboard/graph-api-data';
import { PROPERTY_VIEW_PAGE_KEY } from '@wiot/shared-domain/domain/property/property-view-page-key';
import ResponsiveContainer from '../../../components/shared/ResponsiveContainer';
import DashBoardPieGraph from '../../Dashboard/DashboardPieGraph';
import { AppState } from '../../../state/reducers/rootReducer';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import RenderOnCondition from '../../../components/RenderOnCondition';
import { PROPERTY_VIEW_CONSUMPTION_PIE_CHART_ID } from './property-view-constants';
import { roundToDecimalPlaces } from '../../../utils/number';
import { isHeatCostAllocator } from '@wiot/shared-domain/models/device-types/device-types';
import { selectPageFilters } from '../../../state/filter/filterSelectors';
import { selectIsPropertyLoading, selectProperty, selectPropertyConsumptionUnit } from '../../../state/property-view/propertySelectors';
import useBreakpoint from '../../../hooks/useBreakpoint';
import NoResultsInfo from '../../../components/Table/NoResultsInfo';

const PIE_CHART_MIN_HEIGHT_DESKTOP = 350;
const PIE_CHART_MIN_HEIGHT_MOBILE = 248; // 248 = ResponsiveContainer max-height (268) - ResponsiveContainer margins (20)

export const ConsumptionPieChart = () => {
  const breakpoint = useBreakpoint();

  const filter = useSelector((state: AppState) => selectPageFilters(state, PROPERTY_VIEW_PAGE_KEY));
  const { propertyMBusDeviceTypeIdForPieChart, billingPeriodStartDate } = filter;

  const property = useSelector((state: AppState) => selectProperty(state));
  const isPropertyLoading = useSelector(selectIsPropertyLoading);
  const consumptionUnit = useSelector(selectPropertyConsumptionUnit);

  const isMobileDisplay = useSelector((state: AppState) => state.isMobileDisplay);

  const [chartData, setChartData] = useState<GraphAPIData>({ type: PROPERTY_VIEW_CONSUMPTION_PIE_CHART_ID, types: {}, total: 0 });

  useEffect(() => {
    if (property) {
      const { devices } = property;

      const selectedDevices = devices?.filter(device => device.deviceType?.mBusDeviceTypeId === propertyMBusDeviceTypeIdForPieChart);
      const shouldCalculateConsumptionDifference = !isHeatCostAllocator(propertyMBusDeviceTypeIdForPieChart) || moment(billingPeriodStartDate).month() !== 0;

      const data: GraphAPIData = { type: PROPERTY_VIEW_CONSUMPTION_PIE_CHART_ID, types: {}, total: 0 };

      selectedDevices?.forEach(device => {
        const { manufacturer, deviceId, name, consumption } = device;

        let currentConsumption = consumption?.values?.currentPeriodValue || 0;
        if (consumption?.values && shouldCalculateConsumptionDifference) {
          currentConsumption = consumption.values.currentPeriodValue - consumption.values.lastPeriodValue;
        }

        data.types[`${ manufacturer?.flagId } ${ deviceId } ${ name }`] = roundToDecimalPlaces(currentConsumption, 2);
        data.total++;
      });

      setChartData(data);
    }
  }, [property?.devices, propertyMBusDeviceTypeIdForPieChart]);

  if (!property) {
    return null;
  }

  const shouldRenderPieChart = !isPropertyLoading && chartData.total > 0;

  return (
    <ResponsiveContainer
      title="consumption-by-devices"
      maxWidths={ { sm: 12, md: 12, lg: 12, xl: breakpoint === 'xxl' ? 8 : 12 } } // TODO(TL): Upgrade to bootstrap v5 for `xxl` breakpoint
      idOfElement={ PROPERTY_VIEW_CONSUMPTION_PIE_CHART_ID }
    >
      <RenderOnCondition condition={ isPropertyLoading }>
        <LoadingIcon/>
      </RenderOnCondition>

      <RenderOnCondition condition={ !shouldRenderPieChart && !isPropertyLoading }>
        <NoResultsInfo />
      </RenderOnCondition>

      <RenderOnCondition condition={ shouldRenderPieChart }>
        <DashBoardPieGraph
          data={ chartData }
          translateLegend={ false }
          dataUnit={ consumptionUnit }
          minHeight={ isMobileDisplay ? PIE_CHART_MIN_HEIGHT_DESKTOP : PIE_CHART_MIN_HEIGHT_MOBILE }
          redirectOnClick={ false }
          showSliceLegend={ isMobileDisplay }
        />
      </RenderOnCondition>
    </ResponsiveContainer>
  );
};
