import React from 'react';
import ButtonBase, { ButtonBaseProps } from './ButtonBase';

function SecondaryButton({
  textTranslationId,
  textPlainText = '',
  onClick,
  style,
  isVisible = true,
  mouseOverTextTranslationId,
  mouseOverPlainText,
  formNameToSubmitOnClick,
}: ButtonBaseProps) {
  return (
    <>
      <ButtonBase
        classNames="btn-secondary"
        textTranslationId={textTranslationId}
        textPlainText={textPlainText}
        onClick={onClick}
        style={style}
        isVisible={isVisible}
        mouseOverTextTranslationId={mouseOverTextTranslationId}
        mouseOverPlainText={mouseOverPlainText}
        formNameToSubmitOnClick={formNameToSubmitOnClick}
      />
    </>
  );
}

export default SecondaryButton;
