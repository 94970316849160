import { Dispatch } from 'redux';

import { isDeviceTreeLoading } from '../tree-loading-indication/isDeviceTreeLoadingAction';
import { isLoading } from '../../actions/isLoadingAction';
import { ExpandedNode, TreeNode } from '@wiot/shared-domain/domain/device-tree/tree-node';
import { deviceTreeUpdated } from './deviceTreeUpdatedAction';
import axios, { Canceler } from 'axios';
import { buildQueryString, QueryMap } from '../../../utils/common';
import api from '../../../api/api';
import { authenticatedInstance as httpClientInstance, handleApiError } from '../../../api/apiHelpers';
import { AppState } from '../../reducers/rootReducer';
import { getDeviceManagersFilterString } from '../../../components/Filter/get-device-managers-filter-string';
import { getGatewayFilterString } from '../../../components/Filter/get-gateway-filter-string';
import { DeviceManagerFilter } from '../../device/device-manager-filter';

const { CancelToken } = axios;

let cancelDeviceTreeRequest: Canceler;

function addNodesExpandedByUserToQueryMap(
  queryMap: QueryMap,
  nodesExpandedByUser: ExpandedNode[]
): QueryMap {
  if (nodesExpandedByUser.length === 0) {
    return queryMap;
  }
  const queryString = encodeURIComponent(JSON.stringify(nodesExpandedByUser));
  queryMap[`nodesExpandedByUser`] = queryString;

  return queryMap;
}

export const fetchDeviceGroupsTreeFromDB = async (
  filter: DeviceManagerFilter,
  nodesExpandedByUser: ExpandedNode[],
  maxTreeDepth = 3, // Root + 2 levels
): Promise<TreeNode> => {
  cancelDeviceTreeRequest && cancelDeviceTreeRequest();
  try {
    const filterString = getDeviceManagersFilterString(filter);
    const gatewayFilter = getGatewayFilterString(filter);
    let queryMap: QueryMap = {
      filter: filterString,
      gateways: gatewayFilter,
      maxTreeDepth: maxTreeDepth,
    };

    queryMap = addNodesExpandedByUserToQueryMap(queryMap, nodesExpandedByUser);
    const qs = buildQueryString(queryMap);
    const url = `${ api.deviceGroups }/tree?${ qs }`;
    const { data } = await httpClientInstance.get(url, {
      cancelToken: new CancelToken((c) => {
        // An executor function receives a cancel function as a parameter
        cancelDeviceTreeRequest = c;
      }),
    });
    const rootTreeNode: TreeNode = data.tree.length > 0 ? data.tree[0] : [];

    return rootTreeNode;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const fetchDeviceGroupTree = (
  filter: DeviceManagerFilter,
) => async (
  dispatch: Dispatch<any>,
  getState: () => AppState,
): Promise<void> => {
  const state = getState();
  if (state.deviceTree.isLoading) {
    return;
  }

  const nodesExpandedByUser = state.deviceTree.expandState.nodesExpandedByUser;
  dispatch(isDeviceTreeLoading(true));
  dispatch(isLoading(true));

  try {
    const rootTreeNode = await fetchDeviceGroupsTreeFromDB(filter, nodesExpandedByUser);
    dispatch(deviceTreeUpdated(rootTreeNode));

  } finally {
    dispatch(isDeviceTreeLoading(false));
    dispatch(isLoading(false));
  }
};
