export class StringUtils {
  public static convertToHex = (valueBase64: string): string => {
    if (!valueBase64) {
      return '';
    }
    const buffer = Buffer.from(valueBase64, 'base64');
    const hexValue = buffer.toString('hex');
    return hexValue;
  };

  public static convertToDoubleDigitHexString = (decimalNumber: string): string => {
    if (!decimalNumber) {
      return '';
    }
    const num = parseInt(decimalNumber, 10);
    const hexStr = num.toString(16).toUpperCase();
    const doubleDigitHex = `0${hexStr}`.slice(-2);
    return doubleDigitHex;
  };

  public static convertToBase64 = (hexValue: string): string => {
    if (!hexValue) {
      return '';
    }
    const buffer = Buffer.from(hexValue, 'hex');
    const base64 = buffer.toString('base64');
    return base64;
  };

  /**
   * Use this only if you can be sure that the data is in german localization.
   */
  public static convertGermanNumberStringToNumber = (value: string | undefined | number): number => {
    if (!value) return 0;
    if (typeof value === 'number') return value;
    return Number(value.replace(',', '.'));
  };

  public static isString(value:any): value is string {
    return typeof value === 'string';
  }

  public static isStringArray(value: any): value is string[] {
    return Array.isArray(value) && value.every(item => typeof item === 'string');
  }

  public static nthLastIndexOf(searchedString: string, searchString: string, n: number): number {
    if (searchedString === null) {
      return -1;
    }
    if (!n || isNaN(n) || n <= 1) {
      return searchedString.lastIndexOf(searchString);
    }
    n--;

    return searchedString.lastIndexOf(searchString, StringUtils.nthLastIndexOf(searchedString, searchString, n) - 1);
  }

  public static removeSemicolons(value?: string): string {
    return value ? value.replace(/;/g, '') : '';
  }
}
