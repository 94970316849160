import * as React from 'react';
import { setFilter } from '../../state/filter/set-filter-action-creator';
import { Dispatch } from 'redux';
import { PageKeys } from '../../state/reducers/filterSortReducer';
import { MINIMUM_NUMBER_OF_CHARACTERS_TO_START_SEARCH } from '../FilterBar/select/constants/minimum-number-of-characters-to-start-search';

export const handleChange = (
  event: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
  dispatch: Dispatch<any>,
  pageKey: PageKeys,
) => {
  const target = event.currentTarget || event.target;
  if (!target) return;

  const { name, value } = target;
  const shouldUpdate = value.length >= MINIMUM_NUMBER_OF_CHARACTERS_TO_START_SEARCH || value === '';
  if (!shouldUpdate) return;

  dispatch(setFilter({
    page: pageKey,
    values: { [name]: value === '' ? undefined : value },
  }));
}
