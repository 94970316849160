import { RecommendedEntriesPerPageState, RecommendedEntriesPerPageTypes } from '../types';
import { SET_RECOMMENDED_ENTRIES_PER_PAGE } from '../constants/actionTypes';

const initialState: RecommendedEntriesPerPageState = 10;

export const recommendedEntriesPerPageReducer = (
  state: RecommendedEntriesPerPageState = initialState,
  action: RecommendedEntriesPerPageTypes,
): RecommendedEntriesPerPageState => {
  switch (action.type) {
    case SET_RECOMMENDED_ENTRIES_PER_PAGE:
      return action.payload;
    default:
      return state;
  }
};
