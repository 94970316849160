import { faGlobe, faSignal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';

interface OfflineModeToggleProps {
  handleClick: () => void;
  isOffline: boolean;
}

/**
 * @deprecated See comment in line 30
 */
const OfflineModeToggle = ({ handleClick, isOffline }: OfflineModeToggleProps) => {
  const getContent = () => {
    if (isOffline)
      return (
        <>
          <FontAwesomeIcon icon={faSignal} /> <Translate id="go-back-online" />
        </>
      );
    return (
      <>
        <FontAwesomeIcon icon={faGlobe} /> <Translate id="offlineMode" />
      </>
    );
  };

  // TODO: The component is disabled until the feature should be used again, see WIOT-452
  return null;

  // return (
  //   <Button
  //     onClick={handleClick}
  //     className="header__button w-100"
  //     data-testid="user-settings-offline-toggle"
  //   >
  //     {getContent()}
  //   </Button>
  // );
};

export default OfflineModeToggle;
