import { RawNodeDatum, TreeNodeDatum } from 'react-d3-tree/lib/types/common';
import { TreeNode } from '@wiot/shared-domain/domain/device-tree/tree-node';

export interface D3NodeWrapper extends RawNodeDatum {
  innerNode: TreeNode;
}

export const convertToD3NodeWrapper = (treeNode: TreeNode): D3NodeWrapper => {
  return {
    name: treeNode.name,
    children: treeNode.children.map(child => convertToD3NodeWrapper(child)),
    innerNode: treeNode,
  };
};
