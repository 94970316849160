import { DownloadDetails } from '@wiot/shared-domain/models/device-key/download-details';
import { DeviceKeyDownloadDetailsFetchedAction, HideOrShowAllKeysAction, HideOrShowDeviceKeyAction, } from '../../types';
import { DEVICE_KEY_DOWNLOAD_DETAILS_FETCHED, HIDE_OR_SHOW_ALL_DEVICE_KEYS, HIDE_OR_SHOW_DEVICE_KEY } from '../deviceKeyActionTypes';

export type DownloadDetailsState = {
  downloadDetails?: DownloadDetails | undefined;
};

const initialState: DownloadDetailsState = {
  downloadDetails: undefined,
};

function hideOrShowDeviceKey(
  downloadDetails: DownloadDetails,
  deviceKeyIndex: number,
): DownloadDetails {
  const updatedDeviceKeyDownloadDetails = { ...downloadDetails };
  const deviceKey = updatedDeviceKeyDownloadDetails.deviceKeys[deviceKeyIndex];
  deviceKey.isVisible = !deviceKey.isVisible;

  return updatedDeviceKeyDownloadDetails;
}

function setVisibilityOfAllDeviceKeys(
  downloadDetails: DownloadDetails,
  visibilityToSet: boolean,
): DownloadDetails {
  const updatedDeviceKeyDownloadDetails = { ...downloadDetails };
  updatedDeviceKeyDownloadDetails.deviceKeys = updatedDeviceKeyDownloadDetails.deviceKeys.map(
    (deviceKey) => ({ ...deviceKey, isVisible: visibilityToSet }),
  );

  return updatedDeviceKeyDownloadDetails;
}

export const downloadDetailsReducer = (
  state: DownloadDetailsState = { ...initialState },
  action:
    | DeviceKeyDownloadDetailsFetchedAction
    | HideOrShowDeviceKeyAction
    | HideOrShowAllKeysAction,
): DownloadDetailsState => {
  switch (action.type) {
    case DEVICE_KEY_DOWNLOAD_DETAILS_FETCHED:
      return {
        ...state,
        downloadDetails: action.downloadDetails,
      };

    case HIDE_OR_SHOW_DEVICE_KEY:
      if (state.downloadDetails) {
        const updatedDeviceKeyDownloadDetails = hideOrShowDeviceKey(
          state.downloadDetails,
          action.deviceKeyIndex,
        );
        return { ...state, downloadDetails: updatedDeviceKeyDownloadDetails };
      }

      return state;

    case HIDE_OR_SHOW_ALL_DEVICE_KEYS:
      if (state.downloadDetails) {
        const updatedDeviceKeyDownloadDetails = setVisibilityOfAllDeviceKeys(
          state.downloadDetails,
          !action.areAllKeysVisible,
        );
        return { ...state, downloadDetails: updatedDeviceKeyDownloadDetails };
      }

      return state;

    default:
      return state;
  }
};
