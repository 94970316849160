import { getTranslate } from 'react-localize-redux';
import store from '../state/store/index';
import { DataValidator } from '@wiot/shared-domain/models/validators/data-validators';
import { BACKUP_FILENAME_PATTERN } from '@wiot/shared-domain/models/backup/backup';

/**
 * Validates geo latitude value and returns an error message if it is invalid
 * The validation only happens if there is a value(to allow the field to be optional)
 * valid latitude value should range between -90 to 90
 * Ref: https://stackoverflow.com/a/7780993/3867205
 * @param value - string
 * @returns - string
 */
export const validateLatitude = (value: string | number) => {
  const translate = getTranslate(store.getState().localize);
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
  if (value && !(Number.isFinite(parsedValue) && parsedValue >= -90 && parsedValue <= 90)) {
    return translate('invalid-latitude').toString();
  }
};
/**
 * Validates geo longitude value and returns an error message if it is invalid
 * The validation only happens if there is a value(to allow the field to be optional)
 * Valid longitude value should range between -180 to 180
 * Ref: https://stackoverflow.com/a/7780993/3867205
 * @param value - string
 * @returns - string
 */
export const validateLongitude = (value: string | number) => {
  const translate = getTranslate(store.getState().localize);
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
  if (value && !(Number.isFinite(parsedValue) && parsedValue >= -180 && parsedValue <= 180)) {
    return translate('invalid-longitude').toString();
  }
};
/**
 * Validates if the value exists and returns an error message otherwise
 * @param value - any value
 * @returns - string
 */
export const validateRequiredFieldValue = (
  value: string | number | boolean | undefined,
): string | undefined => {
  if (typeof value === 'number' || value) return;

  const translate = getTranslate(store.getState().localize);
  return translate('required-field').toString();
};
/**
 * Validates if the value exists and it is a valid email. Returns an error message otherwise
 * @param value - string
 * @returns - string
 */
export const validateEmail = (value: string) => {
  const translate = getTranslate(store.getState().localize);
  const validEmail = DataValidator.validateEmail(value);
  if (!value || !validEmail) {
    return translate('invalid-email').toString();
  }
};

export const validateBackupFilename = (filename: string) => {
  const translate = getTranslate(store.getState().localize);
  const pattern = new RegExp(BACKUP_FILENAME_PATTERN.source + /$/.source);

  if (!filename.match(pattern)) {
    return translate('backup-filename-pattern-required').toString();
  }
};