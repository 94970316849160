import { TranslateFunction } from 'react-localize-redux';
import { OptionTypeBase } from 'react-select';

export const getPlaceholderForSelect = <OptionType extends OptionTypeBase> (
  translate: TranslateFunction,
  selectedOptions: OptionType | OptionType[] | undefined,
  placeholderTranslationId: string,
  getOptionLabel: (option: OptionType) => string = (option: OptionType) => option.label,
  translateOptions = false,
  placeholderPrefix = '',
): string => {
  let placeholder = translate(placeholderTranslationId).toString();
  if (selectedOptions) {
    if (Array.isArray(selectedOptions)) {
      placeholder += selectedOptions.length === 0 ? '' : ` (${ selectedOptions.length })`;
    } else {
      const label = getOptionLabel(selectedOptions);
      placeholder = translateOptions ? translate(label).toString() : label;
    }
  }
  return placeholderPrefix ? `${placeholderPrefix} ${placeholder}` : placeholder;
}
