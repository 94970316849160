import * as React from 'react';
import api from '../../../api/api';

function ExpandTreeNode() {
  return (
    <div className="tree__node__root__badge">
      <img
        src={ `${ api.baseAPIUrl }${ api.customizeBranding }` }
        alt="logo"
        className="tree__node__root__icon"
      />
      <div className="tree__node__label__footer"/>
    </div>
  );
}

export default ExpandTreeNode;
