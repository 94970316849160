
import { DisplayTypes } from '@wiot/shared-domain/models/device/device';

export const restrictedFilterBarCombinations = [
  {
    fieldName: 'device-group',
    selectedContentDisplay: DisplayTypes.UNASSIGNED,
    pageKey:'device-manager',
  }
];
