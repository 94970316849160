import { ModalZindexState, ModalZindexTypes } from '../types';
import { SET_MODAL_ZINDEX } from '../constants/actionTypes';

const initialState: ModalZindexState = { modalId: '', zIndex: 401 };

export const modalZindexReducer = (
  state: ModalZindexState = initialState,
  action: ModalZindexTypes,
): ModalZindexState => {
  switch (action.type) {
    case SET_MODAL_ZINDEX:
      return {
        modalId: action.payload.modalId,
        zIndex: action.payload.zIndex,
      };
    default:
      return state;
  }
};
