
import { UserCreationDefinition } from '@wiot/shared-domain/models/device/user-creation-definition';

export const CREATE_USER_AND_ASSIGN_DOWNLOADHASH = 'CREATE_USER_AND_ASSIGN_DOWNLOADHASH';

export interface CreateUserAndAssignDownloadHashAction {
  type: typeof CREATE_USER_AND_ASSIGN_DOWNLOADHASH;
  userCreationDefinition: UserCreationDefinition;
  downloadHash: string;
}
