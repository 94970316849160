import { StringUtils } from '@wiot/shared-domain/models/utils/primitives/string-operations';
import moment from 'moment';
import { Measurement } from '@wiot/shared-domain/models/device-reading/measurement';

/**
 *  TODO(TL): This should be replaced with the `Measurement` type.
 */
export interface HistoricalValue {
  atDate: string;
  value: number;
  unit?: string;
}

export const HISTORICAL_VALUE_DATE_FORMAT = 'YYYY/MM/DD';

export interface HistoricalValues {
  atDate: Date; // Creation date
  // TODO(TL): Rename to `type`
  description: HistoricalReadingType;
  values: HistoricalValue[];
}

export interface MonthlyValuesWithDifferenceToLastMonth {
  atDate: Date; // Creation date
  // TODO(TL): Rename to `type`
  description: HistoricalReadingType;
  values: MonthlyValue[];
}

// TODO: Can this be calculated in the BE and attached directly in the moment of parsing?
export type MonthlyValue = HistoricalValue & {
  differenceToLastMonthlyValue: number;
}

export enum HistoricalReadingType {
  MONTHLY_END_VALUES = 'monthly-end-values',
}

export const convertMeasurementToHistoricalValue = (measurement: Measurement): HistoricalValue => {
  return {
    value: StringUtils.convertGermanNumberStringToNumber(measurement.value),
    atDate: moment(measurement.date).format(HISTORICAL_VALUE_DATE_FORMAT),
    unit: measurement.unit,
  };
};
