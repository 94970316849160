import { IUser } from '@wiot/shared-domain/models/user/user';
import { SAVE_USERS, TOGGLE_SELECT_ALL_USERS, TOGGLE_SELECT_USER } from '../constants/actionTypes';
import { UserExtended, UsersActionTypes, UsersState } from '../types';

const initialState: UsersState = [];

export const usersReducer = (
  state: UsersState = initialState,
  action: UsersActionTypes,
): UsersState => {
  switch (action.type) {
    case SAVE_USERS:
      return action.payload.map((user: IUser) => {
        const { password, ...userWithoutPassword } = user;
        return {
          ...userWithoutPassword,
          checked: false,
        };
      });
    case TOGGLE_SELECT_USER:
      return state.map((user: UserExtended) => {
        if (String(user.id) !== action.payload) {
          return user;
        }
        return {
          ...user,
          checked: !user.checked,
        };
      });

    case TOGGLE_SELECT_ALL_USERS:
      return state.map((user: UserExtended) => ({
        ...user,
        checked: action.payload,
      }));

    default:
      return state;
  }
};
