import { FormikProps } from 'formik';
import { IUser } from '@wiot/shared-domain/models/user/user';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { CustomFieldInput } from '../Table/CustomFieldInput';
import { AppState } from '../../state/reducers/rootReducer';
import { validateEmail, validateRequiredFieldValue } from '../../utils/validation';

interface IContactFormProps {
  currentUser: IUser | undefined;
  formikProps: FormikProps<any>;
}

const ContactForm = ({ currentUser, formikProps }: IContactFormProps) => {
  const { setFieldValue } = formikProps;
  const displayName = currentUser?.displayName || '';
  const email = currentUser?.email || '';

  useEffect(() => {
    if (displayName) {
      setFieldValue('name', displayName);
    }
    if (email) {
      setFieldValue('email', email);
    }
  }, [displayName, email, setFieldValue]);

  return (
    <>
      <Row className="my-2 w-50">
        <Col>
          <CustomFieldInput
            className="form-select"
            fieldName="name"
            translationId="name"
            value={ formikProps.values.name }
            validate={ validateRequiredFieldValue }
            maxLength={ 50 }
            readOnly={ Boolean(displayName) }
          />
        </Col>
      </Row>
      <Row className="my-2 w-50">
        <Col>
          <CustomFieldInput
            className="form-select"
            fieldName="email"
            translationId="email"
            value={ formikProps.values.email }
            validate={ validateEmail }
            maxLength={ 50 }
            readOnly={ Boolean(email) }
          />
        </Col>
      </Row>
      <Row className="my-2 w-50">
        <Col>
          <CustomFieldInput
            className="form-select"
            fieldName="phone"
            translationId="phone"
            value={ formikProps.values.phone }
            maxLength={ 50 }
          />
        </Col>
      </Row>
      <Row className="my-2 w-50">
        <Col>
          <CustomFieldInput
            className="form-select"
            fieldName="subject"
            translationId="subject"
            value={ formikProps.values.subject }
            validate={ validateRequiredFieldValue }
            maxLength={ 50 }
          />
        </Col>
      </Row>
      <Row className="my-2 w-50">
        <Col>
          <CustomFieldInput
            className="form-select"
            fieldName="message"
            translationId="message-max-500-chars"
            value={ formikProps.values.message }
            validate={ validateRequiredFieldValue }
            isTextarea
            rows={ 6 }
            maxLength={ 500 }
          />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  currentUser: state.currentUser?.user,
});

export default connect(mapStateToProps)(ContactForm);
