import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';

export const CREATE_DEVICE_GROUP = 'CREATE_DEVICE_GROUP';
export const DEVICE_GROUP_CREATED = 'DEVICE_GROUP_CREATED';
export const DEVICE_GROUP_CREATION_FAILED = 'DEVICE_GROUP_CREATION_FAILED';

export type DeviceGroupCreationTypes = CreateDeviceGroupAction | DeviceGroupCreatedAction | DeviceGroupCreationFailed;

export interface CreateDeviceGroupAction {
  type: typeof CREATE_DEVICE_GROUP;
  deviceGroup: DeviceGroup;
}

export interface DeviceGroupCreatedAction {
  type: typeof DEVICE_GROUP_CREATED;
  deviceGroup: DeviceGroup;
}

export interface DeviceGroupCreationFailed {
  type: typeof DEVICE_GROUP_CREATION_FAILED;
  deviceGroup: DeviceGroup;
}

export const createDeviceGroupAction = (deviceGroup: DeviceGroup): CreateDeviceGroupAction => ({
  type: CREATE_DEVICE_GROUP,
  deviceGroup: deviceGroup,
});

export const deviceGroupCreatedAction = (createdDeviceGroup: DeviceGroup): DeviceGroupCreatedAction => ({
  type: DEVICE_GROUP_CREATED,
  deviceGroup: createdDeviceGroup,
});

export const deviceGroupCreationFailedAction = (createdDeviceGroup: DeviceGroup): DeviceGroupCreationFailed => ({
  type: DEVICE_GROUP_CREATION_FAILED,
  deviceGroup: createdDeviceGroup,
});
