import React, { useCallback, useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import Table from '../../../components/Table/Table';
import { DispatchActionTypes, IPagination } from '../../../state/types';
import { getFetchOptions } from '../../../utils/common';
import TableDataBackups from './TableDataBackups';
import { AppState } from '../../../state/reducers/rootReducer';
import { savePagination as savePaginationAction } from '../../../state/actions/savePaginationAction';
import { isLoading } from '../../../state/actions/isLoadingAction';
import ErrorBoundary from '../../../components/ErrorBoundary';
import CreateBackupModal from './CreateBackupModal';
import { isTableLoading } from '../../../state/table/isTableLoadingAction';
import { FileInfo } from '@wiot/shared-domain/models/file/file';
import { deleteBackupFile, getBackupFileInfos } from '../../../api/backupService';

export interface KeysProps {
  savePagination: (paginationData: IPagination) => void;
  currentEntriesPerPage: number;
  currentPage: number;
  setIsLoading: (loading: boolean) => void;
  setIsTableLoading: (loading: boolean) => void;
  readOnly: boolean;
}

const Backups = (props: KeysProps) => {
  const { currentEntriesPerPage, currentPage, setIsLoading, setIsTableLoading, savePagination, readOnly } = props;
  const [backupFiles, setBackupFiles] = useState<FileInfo[]>([]);

  const fetchBackupFiles = useCallback(
    async (column?: IColumnObject) => {
      if (currentEntriesPerPage === 0) {
        return;
      }
      const fetchOptions = getFetchOptions<string>(
        currentEntriesPerPage,
        column,
        undefined,
        currentPage,
      );

      setIsLoading(true);
      setIsTableLoading(true);
      try {
        const { files, totalDocs, totalPages } = await getBackupFileInfos(fetchOptions);
        setBackupFiles(files);
        const paginationData: IPagination = {totalDocs, totalPages};
        await savePagination(paginationData);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        setIsTableLoading(false);
      }
    },
    [currentEntriesPerPage, currentPage, savePagination, setIsLoading, setIsTableLoading],
  );

  useEffect(() => {
    fetchBackupFiles();
  }, [fetchBackupFiles]);

  const removeUnit = async (filename: string, refresh = true) => {
    const response = await deleteBackupFile(filename);
    if (refresh && response) {
      await fetchBackupFiles();
    }
  };

  const getTableComponent = () => (
    <TableDataBackups
      files={backupFiles}
      refreshData={fetchBackupFiles}
      removeUnit={removeUnit}
      readOnly={readOnly}
    />
  );

  return (
    <div className="device-manager__table__table__container">
      <ErrorBoundary>
        <Table
          page="backups"
          elementType="backup"
          addText="database-backup"
          changeView={false}
          addModal={!readOnly}
          tableComponent={getTableComponent()}
          showPagination
          refreshTableData={fetchBackupFiles}
          editorModalCreator={(toggleShowEditorModal) => (
            <CreateBackupModal
              closeModal={toggleShowEditorModal}
              title="create-backup"
              refreshData={fetchBackupFiles}
            />
          )}
        />
      </ErrorBoundary>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  currentEntriesPerPage: state.currentEntriesPerPage,
  currentPage: state.pagination.backups.currentPage,
  isLoading: state.isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  savePagination: (paginationData: IPagination) =>
    dispatch(savePaginationAction(paginationData, 'backups')),
  setIsLoading: (loading: boolean) => dispatch(isLoading(loading)),
  setIsTableLoading: (loading: boolean) => dispatch(isTableLoading(loading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Backups);
