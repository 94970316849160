import { Property, PropertyMeasurementFrequency } from '@wiot/shared-domain/domain/property/property';
import { MBusDeviceType } from '@wiot/shared-domain/models/device-types/m-bus-device-types';
import { PropertyConsumptionType } from '@wiot/shared-domain/domain/property/property-consumption-type';

export const FETCH_PROPERTY = 'FETCH_PROPERTY';
export const PROPERTY_FETCHED = 'PROPERTY_FETCHED';

export interface FetchPropertyAction {
  type: typeof FETCH_PROPERTY;
  propertyId: string;
  consumptionType: PropertyConsumptionType;
}

export interface PropertyFetchedAction {
  type: typeof PROPERTY_FETCHED;
  property: Property;
}

export const FETCH_PROPERTY_CONSUMPTION_HISTORY = 'FETCH_PROPERTY_CONSUMPTION_HISTORY';
export const PROPERTY_CONSUMPTION_HISTORY_FETCHED = 'PROPERTY_CONSUMPTION_HISTORY_FETCHED';

export interface FetchPropertyConsumptionHistoryAction {
  type: typeof FETCH_PROPERTY_CONSUMPTION_HISTORY;
  propertyId: string;
  mBusDeviceTypeId: MBusDeviceType;
}

export interface PropertyConsumptionHistoryFetchedAction {
  type: typeof PROPERTY_CONSUMPTION_HISTORY_FETCHED;
  property: Property;
  mBusDeviceTypeId: MBusDeviceType;
  billingFrequency: PropertyMeasurementFrequency;
  billingPeriodStartDate: string;
}

export const USER_LEFT_PROPERTY_VIEW = 'USER_LEFT_PROPERTY_VIEW';
export interface UserLeftPropertyViewAction {
  type: typeof USER_LEFT_PROPERTY_VIEW;
}

export type PropertyActionTypes =
  | FetchPropertyAction
  | PropertyFetchedAction
  | FetchPropertyConsumptionHistoryAction
  | PropertyConsumptionHistoryFetchedAction
  | UserLeftPropertyViewAction;
