import { Device } from '../device/device';
import { Ancestor } from '../device-group/device-group';
import { MeasurementAttributeId as SystemFieldIds } from '../device-type-profile/device-type-profile';
import { IRole } from '../role/role';
import { AccessPeriodsByDeviceId } from '@wiot/shared-domain/models/device-reading/access-periods-by-device-id';

/**
 * @deprecated Use enum MeasurementSource and MeasurementProtocol instead
 */
export enum DeviceReadingSourceType {
  OMS = 'OMS',
  LORA = 'LORA',
  MANUAL = 'MANUAL',
  SYSTEM = 'SYSTEM',
}
export const NonSystemDeviceReadingSourceTypes = [DeviceReadingSourceType.OMS, DeviceReadingSourceType.LORA, DeviceReadingSourceType.MANUAL];

export interface DeviceReading {
  id?: string;
  receivedAt?: Date | string;
  device: Device;
  ancestors?:Ancestor[];
  values?: DeviceReadingValue[];
  gateway?: any;
  source?: DeviceReadingSourceType;
  attributes?: DeviceReadingAttributes[];
  mainDescription?: string;
  isImported?: boolean;
  raw?: string;
  key?: string;
  statusByte?: number;
}

export interface DeviceReadings {
  deviceReadings: DeviceReading[];
  totalPages?: number | any[];
  totalDocs?: number | any[];
}

export function createDeviceReadingWithPaginationWrap(deviceReadings?: DeviceReading[]): DeviceReadings {
  return {
    deviceReadings: deviceReadings || [],
    totalPages: deviceReadings ? 1 : 0,
    totalDocs: deviceReadings?.length || 0,
  };
}

export interface DeviceReadingsWithAncestors {
  deviceReadings: DeviceReading[];
  totalPages?: number | any[];
  totalDocs?: number | any[];
}

export interface DeviceReadingValue {
  value?: any;
  unit?: string;
  description?: string;
  type?: DataValueType;
  functionType?: FunctionType;
  dueDate?: string;
  systemFieldId?: SystemFieldIds;
}

export interface DeviceReadingAttributes {
  key?: string;
  value?: any;
}

export type DeviceReadingFromSources = {
  [source in DeviceReadingSourceType]?: DeviceReading;
};

export enum FunctionType {
  CURRENT_VALUE = 0,
  MAX_VALUE = 1,
  MIN_VALUE = 2,
  VALUE_IN_ERROR_STATE = 3,
  CUMULATIVE = 4,
}

export enum DataValueType {
  LONG = 0,
  BOOL = 1,
  DOUBLE = 2,
  DATE = 3,
  STRING = 4,
  NONE = 5,
  BCD = 6,
}

export function getDeviceReadingSourceTypes(): string[] {
  return Object.values(DeviceReadingSourceType);
}

export function getFunctionTypes(): string[] {
  return Object.values(FunctionType) as string[];
}

export function getDataTypes(): string[] {
  return Object.values(DataValueType) as string[];
}

export const MEASURE_UNITS: { [key: string]: string } = {
  METRIC_CUBE: 'm³',  // deprecated
  CUBIC_METRE: 'm³',
  HCA_UNITS: '',
  KILO_WATT_HOURS: 'kWh',
};

export interface InfluxQueryOptions {
  makeInfluxCountQuery?: boolean;
  allowedDevices?: string[];
  allowedAccessPeriodsByDeviceId?: AccessPeriodsByDeviceId;
  sources?: DeviceReadingSourceType[];
  queryLimit?: number;
  limit?: number;
  page?: number;
  frequency?: string | number;
  sort?: any;
  filter: {
    [key: string]: any,
    time?: {
      $gte?: Date | string;
      $lte?: Date | string;
    };
    source?: DeviceReadingSourceType;
    deviceGroup?: any;
  };
  deviceTypes?: number[];
  roles?: IRole[];
  range?: {
    stop: string | Date;
  };
}

export type DevicesReadingsBySourceAndDeviceId = {
  [source in DeviceReadingSourceType]?: {
    [deviceId: string]: DeviceReading;
  };
};
