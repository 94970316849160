import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { ISettings, Language, legalDetailsPages } from '@wiot/shared-domain/models/settings/settings';
import { HorizontalDivider } from '../../../components/shared/Divider';
import SiteCustomizations from './SiteCustomizations';
import ThemeCustomizations from './Theme/ThemeCustomizations';
import { CustomFieldInput } from '../../../components/Table/CustomFieldInput';
import LegalDetailsSettings from './LegalDetailsSettings';
import MapSettings from './MapSettings';
import { validateRequiredFieldValue } from '../../../utils/validation';
import RenderOnCondition from '../../../components/RenderOnCondition';
import { useSelector } from 'react-redux';
import { AppState } from '../../../state/reducers/rootReducer';
import { SelectWrapperWithFormik } from '../../../components/FilterBar/select/SelectWrapperWithFormik';
import { SelectableOption } from '../../../components/FilterBar/select/selectable-option';
import useTranslation from '../../../hooks/useTranslation';
import { InputContextType } from "../../../components/Input/input-context-type";
import { getSelectedLanguage } from '../../../utils/common';

interface ISiteSettings {
  tabKey: string;
  handleSubmit: (values: ISettings) => void;
  initialValues: ISettings;
  readOnly?: boolean;
}

const SiteSettings = ({ handleSubmit, initialValues, readOnly, tabKey }: ISiteSettings) => {
  const isKeyManagerModeEnabled = useSelector((state: AppState) => !!state.siteSettings.isKeyManagerModeEnabled);
  const [languageOptions, setLanguageOptions] = useState<SelectableOption[]>([]);

  const language = getSelectedLanguage()

  const translate = useTranslation();

  useEffect(() => {
    const getLanguageOptions = () =>
      Object.keys(Language).map((key) => ({
        label: `${ translate(key?.toLowerCase()) }`,
        value: Language[key],
      }));
    const newLanguageOptions = getLanguageOptions();
    setLanguageOptions(newLanguageOptions);
  }, [language]);

  return (
    <Formik enableReinitialize onSubmit={ handleSubmit } initialValues={ initialValues }>
      {(formikProps) => (
        <form id={ `${ tabKey }-settings` } className="well" onSubmit={ formikProps.handleSubmit }>
          <div className="settings-header">
            <Translate id="site-settings-header" />
          </div>
          <Row>
            <Col sm={ 2 } lg={ 3 }>
              <label className="settings-form__label">
                <Translate id="choose-site-title" />
              </label>
            </Col>
            <Col sm={ 6 } lg={ 5 }>
              <CustomFieldInput
                className="form-select"
                inputContextType={ InputContextType.MODAL }
                fieldName="site.title"
                value={ formikProps.values.site?.title }
                readOnly={ readOnly }
                validate={ validateRequiredFieldValue }
              />
            </Col>
          </Row>
          <HorizontalDivider />
          <Row>
            <Col sm={ 2 } lg={ 3 }>
              <label className="settings-form__label">
                <Translate id="choose-language" />
              </label>
            </Col>
            <Col sm={ 6 } lg={ 5 }>
              <SelectWrapperWithFormik<SelectableOption>
                fieldName="site.language"
                options={ languageOptions }
                onChange={ formikProps.setFieldValue }
                value={ formikProps.values.site?.language }
                hideLabel
                readOnly={ readOnly }
              />
            </Col>
          </Row>
          <SiteCustomizations readOnly={ readOnly } />
          <ThemeCustomizations readOnly={ readOnly } />
          <HorizontalDivider />
          <div className="settings-header">
            <Translate id="site-settings-customizations-legal-details"/>
          </div>
          {legalDetailsPages.map((page) => {
            const { path } = page;
            return (
              <LegalDetailsSettings
                key={ path }
                path={ path }
                readOnly={ readOnly }
                formikProps={ formikProps }
              />
            );
          })}
          <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
            <MapSettings formikProps={ formikProps } readOnly={ readOnly }/>
          </RenderOnCondition>
        </form>
      )}
    </Formik>
  );
};

export default SiteSettings;
