import { DeviceMetadata, DeviceMetadataField, ProtocolType } from '@wiot/shared-domain/models/device/device';
import { ValueType } from 'react-select';
import { SetFieldValue } from '../../../state/types';
import { defaultRadioTypeOptions, RadioType } from '../../../config/device-meta-data';
import { DeviceFields } from '@wiot/shared-domain/models/device/device-fields';
import {
  DeviceTypeWithPermission,
  IDeviceType,
  isMBusDeviceTypeWithConsumptionData
} from '@wiot/shared-domain/models/device-types/device-types';
import { MBusDeviceType } from '@wiot/shared-domain/models/device-types/m-bus-device-types';
import { SelectableOption } from '../../../components/FilterBar/select/selectable-option';

export function getRadioType(
  deviceInfo?: DeviceMetadataField[],
): RadioType | undefined {
  if (!deviceInfo) {
    return undefined;
  }
  const radioType = deviceInfo.find((value) => value.key === DeviceFields.RADIO_TYPE)?.value;
  if (!radioType) {
    return undefined;
  }
  if (typeof radioType === 'object' && radioType.hasOwnProperty('name')) {
    return radioType.name;
  }
  return radioType;
}

export function isRadioInfoTabVisible(isKeyManagerModeEnabled: boolean, deviceInfo?: DeviceMetadataField[]): boolean {
  if (isKeyManagerModeEnabled) {
    return false;
  }
  const radioType = getRadioType(deviceInfo);
  const isRadioInfoTabVisible = !!defaultRadioTypeOptions.find(option => option.id === radioType)?.metadataTabVisibility.radioInfo;

  return isRadioInfoTabVisible;
}

export function isDescriptionTabVisible(isKeyManagerModeEnabled: boolean, deviceInfo?: DeviceMetadataField[], deviceType?: DeviceTypeWithPermission): boolean {
  if (isKeyManagerModeEnabled) {
    return false;
  }
  const radioType = getRadioType(deviceInfo);
  const radioTypeSupportsDescriptionTab = !!defaultRadioTypeOptions.find(option => option.id === radioType)?.metadataTabVisibility.description;

  const allowedDeviceTypes = [
    MBusDeviceType.HOT_WATER_METER,
    MBusDeviceType.WARM_WATER_METER,
    MBusDeviceType.COLD_WATER_METER,
    MBusDeviceType.WATER_METER,
  ];
  const isDeviceTypeAllowed = !!deviceType?.mBusDeviceTypeId && allowedDeviceTypes.includes(deviceType?.mBusDeviceTypeId);

  return radioTypeSupportsDescriptionTab && isDeviceTypeAllowed;
}

export function isConsumptionTabVisible(
  isKeyManagerModeEnabled: boolean,
  isDuplicateModal?: boolean,
  deviceType?: IDeviceType,
  hasDeviceGroup?: boolean,
): boolean {
  if (isKeyManagerModeEnabled || isDuplicateModal || !hasDeviceGroup) {
    return false;
  }

  const isConsumptionTabVisible = isMBusDeviceTypeWithConsumptionData(deviceType?.mBusDeviceTypeId);

  return isConsumptionTabVisible;
}

export function getProtocolType(deviceMetadata?: DeviceMetadata, radioType?: RadioType | string): ProtocolType {
  if (!radioType) {
    radioType = getRadioType(deviceMetadata?.deviceInfo);
  }

  switch (radioType) {
    case 'OMS_GATEWAY':
      return ProtocolType.OMS_GATEWAY;
    case 'LORA_GATEWAY':
      return ProtocolType.LORA_GATEWAY;
    case 'LORA_ABP':
      return ProtocolType.LORA_ABP_DEVICE;
    case 'LORA_OTAA':
      return ProtocolType.LORA_OTAA_DEVICE;
    default:
      return ProtocolType.OMS_DEVICE;
  }
}

export const getSelectedMetaDataValue = (
  metadataCategory: keyof DeviceMetadata,
  metadataKey: string,
  deviceMetadata: DeviceMetadata | undefined,
) => {
  if (deviceMetadata && deviceMetadata[metadataCategory]) {
    const metadataList = deviceMetadata[metadataCategory];
    if (!metadataList) {
      return '';
    }
    const metadata = metadataList.find((value) => value.key === metadataKey);
    return metadata ? metadata.value : '';
  }
  return '';
};

export const updateDeviceMetadataFieldValue = (
  metadataCategoryName: keyof DeviceMetadata,
  metadataKey: string,
  newValue: string | ValueType<SelectableOption>,
  metadata: DeviceMetadata | undefined,
  setFieldValue: SetFieldValue,
) => {
  const metadataCategory = metadata ? metadata[metadataCategoryName] : null;
  if (!metadataCategory) {
    return;
  }

  const metadataKeyIndex = metadataCategory.findIndex((v) => v.key === metadataKey);
  const metadataAlreadyExists = metadataKeyIndex >= 0;
  if (metadataAlreadyExists) {
    metadataCategory[metadataKeyIndex].value = newValue;
  } else {
    metadataCategory.push({ key: metadataKey, value: newValue });
  }

  setFieldValue('deviceMetadata', {
    ...metadata,
    [metadataCategoryName]: metadataCategory,
  });
};

export const isSelectRadioTypeDisabled = (deviceMetadata: DeviceMetadata | undefined, isKeyManagerModeEnabled: boolean) => {
  if (isKeyManagerModeEnabled) {
    return true;
  }

  const protocolType = getProtocolType(deviceMetadata);

  return protocolType === ProtocolType.LORA_ABP_DEVICE
    || protocolType === ProtocolType.LORA_OTAA_DEVICE;
};
