import { connect } from 'react-redux';
import { AppState } from '../../state/reducers/rootReducer';

interface MobileProps {
  children: JSX.Element;
  isMobileDisplay: boolean;
}

const Mobile = (props: MobileProps) => (props.isMobileDisplay ? props.children : null);

const mapStateToProps = (state: AppState) => ({
  isMobileDisplay: state.isMobileDisplay,
});

export default connect(mapStateToProps)(Mobile);
