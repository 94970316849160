import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { v4 as uuidv4 } from 'uuid';
import { Device } from '@wiot/shared-domain/models/device/device';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  filterOutSensitiveDataFromDeviceGroup,
  filterOutSensitiveDataFromDeviceReading
} from '../../components/Feedback/filter-sensitive-data';
import { FeedbackAttachment } from '../../components/Feedback/feedback';
import {
  DeviceReading,
  DeviceReadingSourceType,
  DeviceReadingValue,
  MEASURE_UNITS,
} from '@wiot/shared-domain/models/device-reading/device-reading';
import { AggregatedPermission, IMessageRole } from '@wiot/shared-domain/models/role/role';
import { fetchDeviceFromDB } from '../../api/apiHelpers';
import HasPermission from '../../components/HasPermission';
import RenderOnCondition from '../../components/RenderOnCondition';
import Portal from '../../components/shared/Portal';
import { AppState } from '../../state/reducers/rootReducer';
import DeviceDetailsModal from '../DeviceManager/DeviceDetailsModal/DeviceDetailsModal';
import { localizeDate, localizeDateOnly, localizeNumber } from '../../utils/common';
import ModalHeader from '../../components/Modal/ModalHeader';
import { getRandomModalOffset } from '../../utils/dialog';
import ConfirmModal, { ConfirmationVariant } from '../../components/Modal/ConfirmModal';
import { deleteManualDeviceReading } from '../../state/device-readings/deleteManualDeviceReading';
import { ThunkDispatch } from 'redux-thunk';
import { DispatchActionTypes } from '../../state/types';

export interface DeviceMessagesDetailsModalProps {
  closeModal: (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => void;
  onDeviceReadingDeleted: () => void;
  deleteManualDeviceReading: (deviceReadingId: string) => Promise<void>;
  deviceReading: DeviceReading;
  permission?: AggregatedPermission;
  receivedAt?: string | 0;
}

export interface DeviceMessagesDetailsModalState {
  randomModalOffset?: { marginTop: number; marginRight: number };
  gateway: Partial<Device>;
  showGatewayDetails: boolean;
  showDeleteManualDeviceReadingConfirmModal: boolean;
  deviceMessageRole?: IMessageRole;
}

class DeviceMessagesDetailsModal extends React.Component<
  DeviceMessagesDetailsModalProps,
  DeviceMessagesDetailsModalState
> {
  modalUid = uuidv4();

  constructor(props: DeviceMessagesDetailsModalProps) {
    super(props);

    this.state = {
      randomModalOffset: { marginTop: 0, marginRight: 0 },
      gateway: {
        id: '',
        deviceId: '',
      },
      showGatewayDetails: false,
      showDeleteManualDeviceReadingConfirmModal: false,
      deviceMessageRole: undefined,
    };
  }

  componentDidMount = async () => {
    this.setState({ randomModalOffset: getRandomModalOffset() });

    const { deviceReading } = this.props;

    if (deviceReading.gateway && deviceReading.gateway !== 'undefined') {
      const gatewayDetails = await fetchDeviceFromDB(deviceReading.gateway, false);
      this.setState({ gateway: gatewayDetails });
    }

    const device = await fetchDeviceFromDB(deviceReading.device.id);
    this.setState({ deviceMessageRole: device.messageRole });
  };

  handleClose = (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
    event && this.props.closeModal(event);
  };

  toggleGatewayDetails = async () => {
    this.setState((prev) => ({ showGatewayDetails: !prev.showGatewayDetails }));
  };

  getReadingValue = (reading: any) => {
    if (reading.value !== null && typeof reading.value !== 'object') {
      // only show and format type '3' and numeric values
      if (!!reading.type && reading.type === 3) {
        // For manually added device readings we only show the date,
        // but not the time (see Jira issue WIOT-795).
        return this.props.deviceReading.source === 'MANUAL'
          ? localizeDateOnly(reading.value)
          : localizeDate(reading.value);
      }

      if (typeof reading.value === 'number') {
        return localizeNumber(reading.value);
      }

      return reading.value;
    }
    return '-';
  };

  setShowDeleteManualDeviceReadingConfirmModal = (showModal: boolean) => {
    this.setState(() => ({ showDeleteManualDeviceReadingConfirmModal: showModal }));
  };

  deleteManualDeviceReading = async () => {
    this.setShowDeleteManualDeviceReadingConfirmModal(false);
    await this.props.deleteManualDeviceReading(this.props.deviceReading.id!);
    this.props.onDeviceReadingDeleted();
    this.props.closeModal();
  };

  getAttachmentForFeedback = (): FeedbackAttachment | null => {
    if (!this.props.deviceReading) {
      return null;
    }
    return {
      deviceReading: filterOutSensitiveDataFromDeviceReading(this.props.deviceReading),
    };
  }

  render() {
    const { deviceReading, permission, receivedAt } = this.props;
    const { gateway, showGatewayDetails, deviceMessageRole } = this.state;
    const { device } = deviceReading;
    const isManualDeviceReading = deviceReading.source === DeviceReadingSourceType.MANUAL;

    // @ts-ignore
    const deviceId = typeof device === 'object' ? device?.deviceId : '';

    return (
      <div
        tabIndex={ 0 } // make it focusable
        style={ this.state.randomModalOffset }
        className="device-modal"
        id={ `message-from-${ this.modalUid }` }
      >
        <ModalHeader
          isDevice={ false }
          titleTranslationId="message-from"
          targetId={ this.modalUid }
          titlePostfix={ deviceId }
          handleClose={ this.handleClose }
          enableFeedbackSubmission={ true }
          getFeedbackAttachment={ this.getAttachmentForFeedback }
        />
        <div className="device-modal__body details">
          <RenderOnCondition condition={ gateway?.id && gateway?.deviceId }>
            <Row>
              <Col lg={ 3 }>
                <Translate id="gateway"/>:
              </Col>
              <Col lg={ 3 }>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
                <a
                  style={
                    gateway?.id && gateway?.deviceId
                      ? {
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }
                      : {}
                  }
                  onClick={ () => {
                    if (gateway?.id) {
                      this.toggleGatewayDetails();
                    }
                  } }
                >
                  { gateway?.deviceId }
                </a>
              </Col>
              <HasPermission permissionObj={ permission } permissionKey="view" nested>
                <RenderOnCondition condition={ showGatewayDetails }>
                  <Portal>
                    <DeviceDetailsModal
                      closeModal={ this.toggleGatewayDetails }
                      deviceId={ gateway?.id || '' }
                    />
                  </Portal>
                </RenderOnCondition>
              </HasPermission>
            </Row>
            <RenderOnCondition condition={ receivedAt }>
              <Row>
                <Col lg={ 3 }>
                  <Translate id="received-at"/>:
                </Col>
                <Col lg={ 3 }>{ receivedAt }</Col>
              </Row>
            </RenderOnCondition>
          </RenderOnCondition>
          <div className="details__section">
            <h4 className="details__headings">
              <Translate id="values"/>
            </h4>

            <div className="details__column details__column--all-messages">
              <div className="details__row">
                <div className="details__column grow">
                  { deviceReading ? (
                    <table className="device-manager__table__table">
                      <thead>
                      <tr className="details__headings--table">
                        <th>
                          <Translate id="description"/>
                        </th>
                        <th>
                          <Translate id="value"/>
                        </th>
                        <th>
                          <Translate id="unit"/>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      { deviceReading.values &&
                        deviceReading.values.map((reading: DeviceReadingValue, index: number) => (
                          <tr className="details__text details__text--messages" key={ index }>
                            <td className="details__td">{ reading.description || '-' }</td>
                            <td className="details__td">{ this.getReadingValue(reading) }</td>
                            <td className="details__td">
                              { reading.unit ? MEASURE_UNITS[reading.unit] || reading.unit : '-' }
                            </td>
                          </tr>
                        )) }
                      </tbody>
                    </table>
                  ) : (
                    '-'
                  ) }
                </div>
              </div>
            </div>

            <HasPermission permissionObj={ deviceMessageRole } permissionKey="remove">
              <RenderOnCondition condition={ isManualDeviceReading }>
                <div className="mt-4 d-flex justify-content-end">
                  <button onClick={ () => this.setShowDeleteManualDeviceReadingConfirmModal(true) }>
                    <Translate id="delete"/>
                  </button>
                </div>
                <ConfirmModal
                  show={ this.state.showDeleteManualDeviceReadingConfirmModal }
                  modalCloseRequested={ () => this.setShowDeleteManualDeviceReadingConfirmModal(false) }
                  actionConfirmed={ () => this.deleteManualDeviceReading() }
                  translationIdOfElementType="device-message"
                  confirmationVariant={ ConfirmationVariant.DELETE }
                />
              </RenderOnCondition>
            </HasPermission>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  permission: state.currentUser.permission,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, undefined, DispatchActionTypes>) => ({
  deleteManualDeviceReading: (deviceReadingId: string) =>
    dispatch(deleteManualDeviceReading(deviceReadingId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceMessagesDetailsModal);
