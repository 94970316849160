import { Last14MonthlyEndValuesAction, LAST_14_MONTHLY_END_VALUES_FETCHED } from './last14MonthlyEndValuesAction';
import { MonthlyValuesWithDifferenceToLastMonth } from '@wiot/shared-domain/models/device/historical-value';

export type MonthlyEndValuesState = {
  [idOfDevice: string]: MonthlyValuesWithDifferenceToLastMonth;
};

const initialState: MonthlyEndValuesState = {};

export const last14MonthlyEndValuesReducer = (
  state: MonthlyEndValuesState = initialState,
  action: Last14MonthlyEndValuesAction,
): MonthlyEndValuesState => {
  const { payload, type } = action;

  switch (type) {
    case LAST_14_MONTHLY_END_VALUES_FETCHED:
      if (payload?.values) {
        return {
          ...state,
          [payload.idOfDevice]: payload.values,
        };
      }
      return state;

    default:
      return state;
  }
};
