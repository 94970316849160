import React from 'react';
import { toastr } from 'react-redux-toastr';
import { Form, Formik, FormikValues } from 'formik';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { IUser } from '@wiot/shared-domain/models/user/user';
import { AggregatedPermission } from '@wiot/shared-domain/models/role/role';
import {
  BlobWithPath,
  CombinedDataIntegrationFormats,
  CsvImportResult,
  DataIntegrationApiFormats,
  DataIntegrationConfigs,
  DataIntegrationFormats,
  DataIntegrationJobExtended,
  ImportOption,
} from '@wiot/shared-domain/models/data-integration/data-integration';
import { IXmlValidationResult } from '@wiot/shared-domain/models/data-integration/oms-key-xml';
import ModalHeader from '../../../components/Modal/ModalHeader';
import Portal from '../../../components/shared/Portal';
import {
  addDataIntegrationJobInDB,
  runDataIntegrationJobFromDB,
  updateDataIntegrationJobInDB,
} from '../../../api/apiHelpers';
import { DispatchActionTypes, IPagination, SetFieldValue } from '../../../state/types';
import { hasPermission } from '../../../utils/common';
import { AppState } from '../../../state/reducers/rootReducer';
import { savePagination } from '../../../state/actions/savePaginationAction';
import { saveDataIntegrationJobs } from '../../../state/actions/saveDataIntegrationJobsAction';
import ConfirmModal, { ConfirmationVariant } from '../../../components/Modal/ConfirmModal';
import CustomFieldSelect from '../../../components/Table/CustomFieldSelect';
import { ICustomFieldSelectOption } from '../../../components/Table/custom-field-select';
import CsvImport from './Csv/CsvImport';
import XmlImport from './Xml/XmlImport';
import XmlValidationResult from './Xml/XmlValidationResult';
import ValidationMessage from './Csv/ValidationMessage';
import DeviceGroupImportPreview from './Shared/DeviceGroupImportPreview';
import UserImportPreview from './Csv/UserImportPreview';
import DeviceImportPreview from './Shared/DeviceImportPreview';
import { isTableLoading } from '../../../state/table/isTableLoadingAction';
import FtpSection from './FtpSection';
import ApiSection from './Api/ApiSection';
import RenderOnCondition from '../../../components/RenderOnCondition';
import { getRandomModalOffset } from '../../../utils/dialog';
import { FeedbackAttachment } from '../../../components/Feedback/feedback';
import { filterOutSensitiveDataFromDataIntegrationJob } from '../../../components/Feedback/filter-sensitive-data';
import { ActionButton } from './Shared/ActionButton';

export interface IntegrationModalProps extends LocalizeContextProps {
  savePagination: (paginationData: IPagination) => void;
  saveDataIntegrationJobs: (dataIntegrationJobs: DataIntegrationJobExtended[]) => void;
  closeModal: (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => void;
  currentUser: IUser | undefined;
  title: string;
  showDeleteButton: boolean;
  dataIntegrationJob?: DataIntegrationJobExtended;
  addUnit: boolean;
  removeUnit?: (id: string) => void;
  refreshData: () => void;
  setIsTableLoading: (loading: boolean) => void;
  permission?: AggregatedPermission;
  closeMenu?: () => void;
}

export interface IntegrationModalState {
  selectedOption: string | undefined;
  api: DataIntegrationJobExtended;
  files: (string | File)[];
  isApiValid: boolean;
  showDeleteModal: boolean;
  acceptedCsvFile?: string | BlobWithPath;
  csvValidationResult?: CsvImportResult;
  acceptedXmlFile?: BlobWithPath;
  xmlValidationResult?: IXmlValidationResult;
  omsXmlMasterKey?: string;
  randomModalOffset?: { marginTop: number; marginRight: number };
  selectedDeviceGroupId?: string;
  importOption?: ImportOption;
}

class IntegrationModal extends React.Component<IntegrationModalProps, IntegrationModalState> {
  modalUid = uuidv4();

  initialState: IntegrationModalState;
  constructor(props: IntegrationModalProps) {
    super(props);

    const defaultApi = {
      id: '',
      name: '',
      apiPath: '',
      authMethod: '',
      username: '',
      passphrase: '',
      active: false,
      class: '',
      pullFrequency: 0,
      createdAt: new Date(),
      updatedAt: undefined,
    };

    const { addUnit, dataIntegrationJob } = this.props;

    this.initialState = {
      selectedOption: !addUnit && dataIntegrationJob ? dataIntegrationJob.class : '',
      api: dataIntegrationJob || defaultApi,
      files: [],
      isApiValid: false,
      showDeleteModal: false,
      acceptedCsvFile: undefined,
      csvValidationResult: undefined,
      acceptedXmlFile: undefined,
      xmlValidationResult: undefined,
      omsXmlMasterKey: '',
      randomModalOffset: { marginTop: 0, marginRight: 0 },
      selectedDeviceGroupId: '',
      importOption: ImportOption.DO_NOT_CREATE_DEVICE_GROUPS,
    };

    this.state = this.initialState;
  }

  componentDidMount = () => {
    this.setState({ randomModalOffset: getRandomModalOffset() });
  };

  getPermittedFormats = (): ICustomFieldSelectOption[] =>
    Object.values(CombinedDataIntegrationFormats)
      .filter((format) =>
        hasPermission(this.props.permission, `dataIntegration.allowedFormat.${ format }`),
      )
      .map<ICustomFieldSelectOption>((f) => ({
        id: f,
        name: f,
      }));

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }));
  };

  updateTableAndClose = () => {
    const { closeMenu, closeModal, refreshData } = this.props;
    closeModal && closeModal();
    closeMenu && closeMenu();
    refreshData && refreshData();
  };

  getDataSourceInputField = (values: IntegrationModalState, setFieldValue: SetFieldValue) => {
    const { selectedOption, api } = values;
    switch (selectedOption) {
      case DataIntegrationFormats.CSV:
        return (
          <CsvImport
            setAcceptedFile={ (acceptedCsvFile) => this.setState({ acceptedCsvFile }) }
            setValidationResult={ (csvValidationResult) => this.setState({ csvValidationResult }) }
          />
        );
      case DataIntegrationFormats.XML:
        return (
          <XmlImport
            setAcceptedFile={ (acceptedXmlFile) => this.setState({ acceptedXmlFile }) }
            setFieldValue={ setFieldValue }
            formikValues={ values }
            title={ `${ this.props.title }-${ this.modalUid }` }
          />
        );
      case DataIntegrationFormats.FTP:
        return <FtpSection api={ api } addUnit={ this.props.addUnit }/>;
      default:
        return (
          selectedOption && (
            <ApiSection
              setFieldValue={ setFieldValue }
              formikValues={ values }
              selectedMethod={ selectedOption as DataIntegrationApiFormats }
              refreshData={ this.props.refreshData }
              isApiValid={ this.state.isApiValid }
            />
          )
        );
    }
  };

  testApiConnection = async (api: DataIntegrationJobExtended) => {
    if (api.class === DataIntegrationApiFormats.CasametaCloud && !api.deviceGroup) {
      return toastr.error(
        this.props.translate('failed').toString(),
        this.props.translate('missing-device-group').toString(),
      );
    }
    const testResponse = await runDataIntegrationJobFromDB(api, true);
    if (!testResponse) return;

    const { error } = testResponse;
    if (error) {
      this.setState({ isApiValid: false });
      return toastr.error(this.props.translate('failed').toString(), error);
    }

    this.setState({ isApiValid: true });
    toastr.success(
      this.props.translate('success').toString(),
      this.props.translate('api-test-success').toString(),
    );
  };

  onSubmit = async (values: IntegrationModalState) => {
    this.props.setIsTableLoading(true);
    const { api, selectedOption } = values;
    api.class = selectedOption as keyof typeof DataIntegrationApiFormats;

    const { checked, ...cleanedApi } = api;
    cleanedApi.userId = this.props.currentUser?.id;

    // Add or update data integration job
    if (cleanedApi.userId) {
      // File upload: Data integration job has already been added by endpoint
      if (this.state.acceptedCsvFile) {
        return this.updateTableAndClose();
      }

      const response = this.props.addUnit
        ? await addDataIntegrationJobInDB(cleanedApi)
        : await updateDataIntegrationJobInDB(cleanedApi);
      if (!response) {
        return toastr.error(
          this.props.translate('failed').toString(),
          this.props.translate('add-data-integration-job-failed').toString(),
        );
      } else {
        toastr.success(
          this.props.translate('success').toString(),
          ` ${
            this.props.addUnit
              ? this.props.translate('add')
              : this.props.translate('update')
          } ${
            this.props.translate('data-integration').toString() }`,
        );
        return this.updateTableAndClose();
      }
    } else {
      toastr.error(
        this.props.translate('failed').toString(),
        this.props.translate('missing-user-id').toString(),
      );
    }

    this.updateTableAndClose();
  };

  getAttachmentForFeedback = (): FeedbackAttachment | null => {
    if (!this.state.api) {
      return null;
    }
    return {
      dataIntegration: filterOutSensitiveDataFromDataIntegrationJob(this.state.api),
    };
  };

  override render() {
    const { closeModal, addUnit, removeUnit, title, dataIntegrationJob } = this.props;
    const { csvValidationResult } = this.state;

    const isValidCsv =
      csvValidationResult?.validationResult.users?.length === 0 &&
      csvValidationResult.validationResult.devices?.length === 0;

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>, setFieldValue: SetFieldValue, resetForm: (nextValues?: FormikValues) => void) => {
      const { name, value } = event.currentTarget || event.target;
      if (name && value) {
        setFieldValue(name, value);
        this.setState({ isApiValid: false })
      } else {
        this.setState({ ...this.initialState });
        resetForm();
      }
    };

    return (
      <div
        tabIndex={ 0 } // make it focusable
        style={ this.state.randomModalOffset }
        id={ `${ title }-${ this.modalUid }` }
        className="device-modal"
      >
        <ModalHeader
          isDevice={ false }
          titleTranslationId={ title }
          handleClose={ closeModal }
          targetId={ this.modalUid }
          enableFeedbackSubmission={ true }
          getFeedbackAttachment={ this.getAttachmentForFeedback }
        />
        <div className="device-modal__body">
          <Formik
            initialValues={ {
              ...this.state,
              selectedOption: dataIntegrationJob ? dataIntegrationJob.class : '',
            } }
            enableReinitialize={ false }
            onSubmit={ this.onSubmit }
            render={ ({
              values,
              resetForm,
              setFieldValue
            }) => (
              <Form className="form" onClick={ (event: React.MouseEvent) => event.stopPropagation() }>
                <div className="form__section">
                  <div className="form__row">
                    <CustomFieldSelect
                      translationId="choose-data-source"
                      fieldName="selectedOption"
                      placeholderTranslationId="choose-data-source"
                      options={ this.getPermittedFormats() }
                      translateOptions
                      value={ values.selectedOption }
                      readOnly={ !addUnit || this.state.isApiValid }
                      isClearable={ !!values.selectedOption }
                      onChange={ (e) => handleChange(e, setFieldValue, resetForm) }
                      portalTargetId={ `modal-${title}` }
                    />
                  </div>
                  <div id={ `modal-${title}`} />
                </div>
                <div className="form__section">
                  { values.selectedOption && this.getDataSourceInputField(values, setFieldValue) }
                </div>
                { csvValidationResult?.validationResult && (
                  <>
                    <h4 className="form__subheading">
                      <Translate id="preview"/>:{ ' ' }
                      { this.state.acceptedCsvFile &&
                        // @ts-ignore Check for undefined case exists
                        this.state.acceptedCsvFile.path }
                    </h4>
                    <ValidationMessage
                      validationResult={ csvValidationResult.validationResult.users }
                      translationKey="user-errors"
                    />
                    <ValidationMessage
                      validationResult={ csvValidationResult.validationResult.devices }
                      translationKey="device-errors"
                    />
                  </>
                ) }
                { csvValidationResult?.newObjects && isValidCsv && (
                  <>
                    <DeviceGroupImportPreview
                      groupsList={ csvValidationResult.newObjects.deviceGroups }
                    />
                    <UserImportPreview users={ csvValidationResult.newObjects.users }/>
                    <DeviceImportPreview devices={ csvValidationResult.newObjects.devices }/>
                  </>
                ) }
                <RenderOnCondition condition={ values.xmlValidationResult }>
                  <XmlValidationResult
                    result={ values.xmlValidationResult! }
                    file={ this.state.acceptedXmlFile! }
                    omsXmlMasterKey={ values.omsXmlMasterKey }
                  />
                </RenderOnCondition>

                <div className="form__section last">
                  <div className="form__row space-between ai">
                    <div className="form__row--left">
                      { dataIntegrationJob && (
                        <button
                          type="button"
                          className="form__button--delete"
                          onClick={ this.toggleDeleteModal }
                        >
                          <Translate id="remove"/>
                        </button>
                      ) }
                    </div>

                    <div className="form__row--right">
                      <button type="button" className="form__button--cancel" onClick={ closeModal }>
                        <Translate id="cancel"/>
                      </button>
                      <RenderOnCondition condition={ values.selectedOption }>
                        <ActionButton
                          values={ values }
                          setFieldValue={ setFieldValue }
                          updateTableAndClose={ this.updateTableAndClose }
                          testApiConnection={ this.testApiConnection }
                          onSubmit={ this.onSubmit }
                          isApiValid={ this.state.isApiValid }
                          addUnit={ addUnit }
                          acceptedCsvFile={ this.state.acceptedCsvFile }
                          acceptedXmlFile={ this.state.acceptedXmlFile }
                          isCsvValid={ isValidCsv }
                        />
                      </RenderOnCondition>
                    </div>
                  </div>
                </div>
              </Form>
            ) }
          />
        </div>
        { this.state.showDeleteModal && (
          <Portal>
            <ConfirmModal
              modalCloseRequested={ () => this.toggleDeleteModal() }
              actionConfirmed={ async () => {
                removeUnit && (await removeUnit(this.state.api.id));
                await this.props.refreshData();
              } }
              translationIdOfElementType="data-integration"
              confirmationVariant={ ConfirmationVariant.DELETE }
            />
          </Portal>
        ) }
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  dataIntegrationJobs: state.dataIntegrationJobs,
  isLoading: state.isLoading,
  currentUser: state.currentUser.user,
  permission: state.currentUser.permission,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  savePagination: (paginationData: IPagination) =>
    dispatch(savePagination(paginationData, 'data-integration')),
  saveDataIntegrationJobs: (dataIntegrationJobs: DataIntegrationJobExtended[]) =>
    dispatch(saveDataIntegrationJobs(dataIntegrationJobs)),
  setIsTableLoading: (loading: boolean) => dispatch(isTableLoading(loading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(IntegrationModal));
