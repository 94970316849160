import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useDispatch, useSelector } from 'react-redux';
import { PageKeys } from '../../state/reducers/filterSortReducer';
import { AppState } from '../../state/reducers/rootReducer';
import { FilterFieldKeys } from './filter-field-keys';
import { handleChange } from './filter-value-change-handler';
import useTranslation from '../../hooks/useTranslation';
import { getPlaceholderForMinSearchChars } from '../FilterBar/select/functions/get-placeholder-for-min-search-chars';
import { DEBOUNCE_TIME_TO_START_SEARCH } from '../FilterBar/select/constants/debounce-time-to-start-search';


export interface FilterBarInputProps {
  name: FilterFieldKeys;
  pageKey: PageKeys;
}

const FilterBarInput: React.FC<FilterBarInputProps> = ({ name, pageKey }) => {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const filter = useSelector((state: AppState) => state.filters.filter);
  // @ts-ignore The 'any' type in the global filter must be refactored
  const value = filter[pageKey]?.[name] || '';

  return (
    <div className="filterbar__item filter-bar-select">
      <DebounceInput
        type="text"
        className="filter-bar-control filter-bar-select__control"
        onChange={ (event) => handleChange(event, dispatch, pageKey) }
        name={ name }
        debounceTimeout={ DEBOUNCE_TIME_TO_START_SEARCH }
        value={ value }
        placeholder={ getPlaceholderForMinSearchChars(pageKey, name, translate) }
      />
    </div>
  );
};

export default FilterBarInput;
