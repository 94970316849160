import React, { useCallback, useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { IUser } from '@wiot/shared-domain/models/user/user';
import { AggregatedPermission } from '@wiot/shared-domain/models/role/role';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import FilterBar from '../../components/Filter/FilterBar';
import Table from '../../components/Table/Table';
import { DispatchActionTypes, IPagination, UserExtended } from '../../state/types';
import { getFetchOptions, hasPermission } from '../../utils/common';
import { fetchUsersFromDB, removeUserFromDB } from '../../api/apiHelpers';
import TableDataUsers from './Table/TableDataUsers';
import { AppState } from '../../state/reducers/rootReducer';
import { saveUsers as saveUsersAction, toggleSelectAllUsers, toggleSelectUser, } from '../../state/actions/saveUsersAction';
import { savePagination as savePaginationAction } from '../../state/actions/savePaginationAction';
import { isTableLoading } from '../../state/table/isTableLoadingAction';
import { isLoading } from '../../state/actions/isLoadingAction';
import ErrorBoundary from '../../components/ErrorBoundary';
import Desktop from '../../components/Responsive/Desktop';
import Mobile from '../../components/Responsive/Mobile';
import MobileTable from './Mobile/MobileTable';
import RenderOnCondition from '../../components/RenderOnCondition';
import UserActionModal from './UserActionModal/UserActionModal';
import { IUserManagementFilter } from '../../state/reducers/filterSortReducer';
import MainGrid from '../../components/shared/MainGrid';

export interface UserManagementProps extends LocalizeContextProps {
  isUserModalOpen: boolean;
  users: IUser[];
  saveUsers: (users: IUser[]) => void;
  savePagination: (paginationData: IPagination) => void;
  toggleSelectUser: (id: string) => void;
  toggleSelectAllUsers: (isChecked: boolean) => void;
  currentEntriesPerPage: number;
  currentPage: number;
  setIsTableLoading: (loading: boolean) => void;
  setIsLoading: (loading: boolean) => void;
  filter: IUserManagementFilter;
  permission?: AggregatedPermission;
}

const UserManagement = (props: UserManagementProps) => {
  const { permission } = props;

  const [showAddModal, setShowAddModal] = useState(false);
  const {
    filter,
    currentEntriesPerPage,
    currentPage,
    isUserModalOpen,
    setIsLoading,
    setIsTableLoading,
    saveUsers,
    savePagination,
  } = props;

  const fetchUsers = useCallback(
    async (column?: IColumnObject) => {
      try {

      const fetchOptions = getFetchOptions(currentEntriesPerPage, column, filter, currentPage);

      setIsTableLoading(true);
      const res = await fetchUsersFromDB(fetchOptions);
      const { users, totalDocs, totalPages } = res.users;
      await saveUsers(users);
      await savePagination({totalDocs, totalPages});
      setIsTableLoading(false);
      setIsLoading(false);

      } catch (e) {
        setIsTableLoading(false);
        setIsLoading(false);
        console.error(e);
      }
    },
    [
      currentEntriesPerPage,
      currentPage,
      filter,
      savePagination,
      saveUsers,
      setIsLoading,
      setIsTableLoading,
    ],
  );

  useEffect(() => {
    setIsLoading(true);
    fetchUsers();
  }, [fetchUsers, setIsLoading]);

  const removeUnit = async (id: string, refresh = true) => {
    props.setIsTableLoading(true);
    const response = await removeUserFromDB(id);

    if (refresh && response) {
      await fetchUsers();
      toastr.success(
        props.translate('success').toString(),
        props.translate('delete-user-success').toString(),
      );
    }
  };

  const getSelectedUsers = (): UserExtended[] =>
    props.users ? props.users.filter((user: UserExtended) => user.checked) : [];

  const handleBulkRemove = async () => {
    const selectedUsers: UserExtended[] = getSelectedUsers();
    const removePromises = selectedUsers.map(async (user) => removeUnit(user.id, false));
    await Promise.all(removePromises);
    await fetchUsers();
    toastr.success(
      props.translate('success').toString(),
      props.translate('delete-users-success').toString(),
    );
  };

  const selectAll = (event: React.FormEvent<HTMLInputElement>) => {
    const isChecked = event.currentTarget.checked;
    props.toggleSelectAllUsers(isChecked);
  };

  const toggleShowAddModal = () => {
    setShowAddModal((prevState) => !prevState);
  };

  const getTableComponent = () => (
    <TableDataUsers
      refreshData={fetchUsers}
      removeUnit={removeUnit}
      markOneUserAsSelected={props.toggleSelectUser}
      markAllUsersAsSelected={selectAll}
      isSelectAllChecked={props.users.length === getSelectedUsers().length}
      disableControls={isUserModalOpen}
      disabledControlsTooltipTranslationTerm="close-user-modal-to-activate-control"
    />
  );

  return (
    <MainGrid dataTestId="page-user-management">
      <ErrorBoundary>
        <FilterBar page="user-management" handleAddBtnClick={toggleShowAddModal} />
      </ErrorBoundary>
      <Desktop>
        <ErrorBoundary>
          <Table
            page="user-management"
            changeView={false}
            addModal={hasPermission(permission, 'users.add')}
            addText="user"
            disableControls={isUserModalOpen}
            disabledControlsTooltipTranslationTerm="close-user-modal-to-activate-control"
            tableComponent={getTableComponent()}
            selectedRows={getSelectedUsers()}
            handleBulkRemove={handleBulkRemove}
            elementType="users"
            showPagination
            refreshTableData={fetchUsers}
            editorModalCreator={(toggleShowEditorModal) => (
              <UserActionModal
                saved={() => {
                  fetchUsers();
                  toggleShowEditorModal();
                }}
                cancelled={toggleShowEditorModal}
                title="add-user"
                showDeleteButton={false}
                isInCreationMode
              />
            )}
          />
        </ErrorBoundary>
      </Desktop>
      <Mobile>
        <>
          <MobileTable removeUnit={removeUnit} refreshData={fetchUsers} />
          <RenderOnCondition condition={showAddModal}>
            <UserActionModal
              saved={() => {
                fetchUsers();
                toggleShowAddModal();
              }}
              cancelled={toggleShowAddModal}
              title="add-user"
              showDeleteButton={false}
              isInCreationMode
            />
          </RenderOnCondition>
        </>
      </Mobile>
    </MainGrid>
  );
};

const mapStateToProps = (state: AppState) => ({
  users: state.users,
  currentEntriesPerPage: state.currentEntriesPerPage,
  currentPage: state.pagination['user-management'].currentPage,
  filter: state.filters.filter['user-management'],
  isUserModalOpen: !!state.userActionModal.editUser,
  permission: state.currentUser.permission,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  toggleSelectUser: (user: string) => dispatch(toggleSelectUser(user)),
  toggleSelectAllUsers: (isChecked: boolean) => dispatch(toggleSelectAllUsers(isChecked)),
  saveUsers: (users: IUser[]) => dispatch(saveUsersAction(users)),
  savePagination: (paginationData: IPagination) =>
    dispatch(savePaginationAction(paginationData, 'user-management')),
  setIsLoading: (loading: boolean) => dispatch(isLoading(loading)),
  setIsTableLoading: (loading: boolean) => dispatch(isTableLoading(loading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(UserManagement));
