import React from 'react';
import { Translate } from 'react-localize-redux';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRadioKeyDeviceCounts, RadioKey } from '@wiot/shared-domain/models/radio-key/radio-key';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import DataRow from './DataRow';
import MobileFooter from '../../../components/Mobile/MobileFooter';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import { DispatchActionTypes } from '../../../state/types';
import { AppState } from '../../../state/reducers/rootReducer';
import { saveCurrentPage } from '../../../state/actions/savePaginationAction';
import { PageKeys } from '../../../state/reducers/filterSortReducer';

interface MobileTableProps {
  keys: RadioKey[];
  removeUnit: (id: string) => Promise<void>;
  refreshData: (column?: IColumnObject) => void;
  currentPageTitle: PageKeys;
  totalDocs: number;
  currentEntriesPerPage: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  isLoading: boolean;
  deviceCounts: IRadioKeyDeviceCounts;
}

const MobileTable = (props: MobileTableProps) => {
  const {
    keys,
    removeUnit,
    refreshData,
    currentPageTitle,
    totalDocs,
    deviceCounts,
    currentEntriesPerPage,
    currentPage,
    setCurrentPage,
    isLoading,
  } = props;

  const firstEntry = (currentPage - 1) * currentEntriesPerPage + 1;
  const lastEntry = Math.min(firstEntry + currentEntriesPerPage - 1, totalDocs);

  return isLoading ? (
    <LoadingIcon />
  ) : (
    <div className="device-manager__table__container">
      <div className="page-header-wrapper">
        <h3 className="page-title" data-testid="header-page-title">
          <Translate id={currentPageTitle} />
        </h3>
        <h4 className="page-subtitle" data-testid="table-subheading">
          {totalDocs ? (
            <Translate
              id="device-messages-table-header-subheading"
              data={{
                firstEntry,
                lastEntry,
                allDevices: totalDocs,
              }}
            />
          ) : null}
        </h4>
      </div>
      {keys && keys.length ? (
        keys.map((radioKey: RadioKey) => (
          <DataRow
            key={radioKey.id}
            radioKey={radioKey}
            deviceCount={deviceCounts[radioKey.id]}
            removeUnit={removeUnit}
            refreshData={refreshData}
          />
        ))
      ) : (
        <div className="text-center m-2">
          <Translate id="no-data" />
        </div>
      )}
      <MobileFooter
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalDocs={totalDocs}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  keys: state.keys,
  currentPageTitle: state.currentPageTitle,
  totalDocs: state.pagination['key-management'].totalDocs,
  currentEntriesPerPage: state.currentEntriesPerPage,
  currentPage: state.pagination['key-management'].currentPage,
  isLoading: state.isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  setCurrentPage: (pageNumber: number) => dispatch(saveCurrentPage(pageNumber, 'key-management')),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileTable);
