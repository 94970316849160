import { SAVE_PAGINATION, SAVE_CURRENT_PAGE, CLEAR_PAGINATION } from '../constants/actionTypes';
import {
  ClearPaginationAction,
  IPagination,
  SaveCurrentPageAction,
  SavePaginationAction,
} from '../types';
import { PageKeys } from '../reducers/filterSortReducer';

export const savePagination = (
  paginationData: IPagination,
  page: PageKeys,
): SavePaginationAction => ({
  type: SAVE_PAGINATION,
  payload: {
    pageName: page,
    data: paginationData,
  },
});

export const clearPagination = (page: PageKeys): ClearPaginationAction => ({
  type: CLEAR_PAGINATION,
  payload: {
    pageName: page,
  },
});

export const saveCurrentPage = (currentPage: number, page: PageKeys): SaveCurrentPageAction => ({
  type: SAVE_CURRENT_PAGE,
  payload: {
    pageName: page,
    currentPage,
  },
});
