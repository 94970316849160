import { History } from 'history';
import { localizeReducer } from 'react-localize-redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';

import { currentUserReducer } from './currentUserReducer';
import { currentPageTitleReducer } from './currentPageTitleReducer';
import { deviceMessagesReducer } from './deviceMessagesReducer';
import { devicesReducer } from '../device/devicesReducer';
import { entriesPerPageReducer } from './entriesPerPageReducer';
import { recommendedEntriesPerPageReducer } from './recommendedEntriesPerPageReducer';
import { modalZindexReducer } from './modalZindex';
import { isLoadingReducer, loadingMessageReducer } from './isLoadingReducer';
import { isTableLoadingReducer } from '../table/isTableLoadingReducer';
import { keysReducer } from './keysReducer';
import { dataIntegrationJobsReducer } from './dataIntegrationJobsReducer';
import { isLoggedInReducer } from './isLoggedInReducer';
import { paginationReducer } from './paginationReducer';
import { rolesReducer } from './rolesReducer';
import { userRolesReducer } from './userRolesReducer';
import { usersReducer } from './usersReducer';
import { feedbackReducer } from '../feedback/feedbackReducer';
import { filterSortReducer } from './filterSortReducer';
import { deviceTypesReducer } from '../device-types/deviceTypesReducer';
import { responsiveReducer } from './responsiveReducer';
import { deviceTreeReducer } from '../device-tree/deviceTreeReducer';
import { siteSettingsReducer } from './siteSettingsReducer';
import { isOfflineReducer } from './isOfflineReducer';
import { userActionModalReducer } from './userActionModalReducer';
import deviceKeyProtectionReducer from '../device-key/reducers/deviceKeyProtectionReducer';
import versioningReducer from './versioning/versioninngReducer';
import { last14MonthlyEndValuesReducer } from '../device/consumption/last14MonthlyEndValuesReducer';
import { userValidationResultReducer } from '../user/userValidationResultReducer';
import { widgetsDataReducer } from '../dashboard/widgetsDataReducer';
import { propertyReducer } from '../property-view/propertyReducer';
import { showNoResultsInfoReducer } from '../device/fetching-limit/fetchNoResultInfoReducer';
import { licensingReducer } from '../licensing/licensingReducer';
import { subTreeReducer } from '../device-hierarchy/subTreeReducer';
import {
  requestToRefreshDeviceGroupRelatedEntitiesReducer
} from '../device-group/refresh-request/requestToRefreshDeviceGroupRelatedEntitiesReducer';
import { contextMenuReducer } from '../context-menu/contextMenuReducer';
import { isLoadingDeviceReadingsReducer } from '../device-readings/fetch-device-readings/isLoadingDeviceReadingsReducer';

import { groupSelectsReducer } from '../device-group/group-select/groupSelectsReducer';
import { paginatedDeviceGroupsReducer } from '../device-group/paginated-device-groups/paginatedDeviceGroupsReducer';
import { deviceManagerStateReducer } from '../device-manager/deviceManagerStateReducer';

export const createRootReducer = (history: History) => combineReducers({
  currentEntriesPerPage: entriesPerPageReducer,
  currentPageTitle: currentPageTitleReducer,
  currentRecommendedEntriesPerPage: recommendedEntriesPerPageReducer,
  currentUser: currentUserReducer, // TODO(PS): This should be renamed to something like "userDetails". The actual user is in "currentUser.user"
  contextMenu: contextMenuReducer,
  dataIntegrationJobs: dataIntegrationJobsReducer,
  paginatedDeviceGroups: paginatedDeviceGroupsReducer,
  groupSelects: groupSelectsReducer,
  deviceHierarchySubTree: subTreeReducer,
  deviceKeyProtection: deviceKeyProtectionReducer,
  deviceManagerState: deviceManagerStateReducer,
  deviceMessages: deviceMessagesReducer,
  devices: devicesReducer, // TODO(TL): This should be moved to the device manager state if there are no other usages (WIOT-1405)
  deviceTree: deviceTreeReducer,
  deviceTypes: deviceTypesReducer,
  feedback: feedbackReducer,
  filters: filterSortReducer,
  isLoading: isLoadingReducer,
  isLoadingDeviceReadings: isLoadingDeviceReadingsReducer,
  isLoggedIn: isLoggedInReducer,
  isMobileDisplay: responsiveReducer,
  isOffline: isOfflineReducer,
  isTableLoading: isTableLoadingReducer,
  keys: keysReducer,
  last14MonthlyEndValues: last14MonthlyEndValuesReducer,
  licensing: licensingReducer,
  loadingMessage: loadingMessageReducer,
  localize: localizeReducer,
  modalZindex: modalZindexReducer,
  pagination: paginationReducer,
  properties: propertyReducer,
  roles: rolesReducer,
  requestToRefreshDeviceGroupRelatedEntities: requestToRefreshDeviceGroupRelatedEntitiesReducer,
  showNoResultsInfo: showNoResultsInfoReducer,
  siteSettings: siteSettingsReducer,
  toastr: toastrReducer,
  userActionModal: userActionModalReducer,
  userRoles: userRolesReducer,
  users: usersReducer,
  userValidationResult: userValidationResultReducer,
  versioning: versioningReducer,
  widgetsData: widgetsDataReducer,
});

export type AppState = ReturnType<ReturnType<typeof createRootReducer>>;
