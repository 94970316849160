export const ASSIGN_DEVICE_KEY_TO_USER = 'ASSIGN_DEVICE_KEY_TO_USER';
export const DEVICE_KEY_ASSIGNED_TO_USER = 'DEVICE_KEY_ASSIGNED_TO_USER';
export const FETCH_DEVICE_KEY_DOWNLOAD_DETAILS = 'FETCH_DEVICE_KEY_DOWNLOAD_DETAILS';
export const HIDE_OR_SHOW_DEVICE_KEY = 'HIDE_OR_SHOW_DEVICE_KEY';
export const HIDE_OR_SHOW_ALL_DEVICE_KEYS = 'HIDE_OR_SHOW_ALL_DEVICE_KEYS';
export const DEVICE_KEY_DOWNLOAD_DETAILS_FETCHED = 'DEVICE_KEY_DOWNLOAD_DETAILS_FETCHED';
export const DEVICE_KEY_DOWNLOAD_DETAILS = 'DEVICE_KEY_DOWNLOAD_DETAILS';
export const DEVICE_KEY_PROTECTION_INFO_FETCHED = 'DEVICE_KEY_PROTECTION_INFO_FETCHED';
export const DOWNLOAD_HASH_NOT_FOUND = 'DOWNLOAD_HASH_NOT_FOUND';
export const CREATE_DEVICE_PASSWORD = 'CREATE_DEVICE_PASSWORD';
