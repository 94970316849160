import React from 'react';
import { PageKeys } from '../../state/reducers/filterSortReducer';
import GroupSelect from './GroupSelect';
import { useDispatch } from 'react-redux';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { setFilter } from '../../state/filter/set-filter-action-creator';
import { InputContextType } from '../Input/input-context-type';

interface GroupSelectFilterProps {
  pageKey: PageKeys;
}

const DeviceGroupFilter = ({ pageKey }: GroupSelectFilterProps) => {
  const dispatch = useDispatch();

  const onSelectionChanged = (selectedGroups: DeviceGroup[]) => {
    dispatch(
      setFilter({
        page: pageKey,
        values: { deviceGroups: selectedGroups },
      }),
    );
  };

  return (
    <>
      <GroupSelect
        dropDownWrapperClassNames="group-select"
        preSelectedDeviceGroupId={ '' }
        isMultiSelect
        onSelectionChangedMultiSelect={ onSelectionChanged }
        hideLabel
        targetId="groupSelectFilterTargetId"
        defaultLabel="choose-group"
        isClearable={ false }
        context={ InputContextType.FILTER_BAR }
      />
    </>
  );
};

export default DeviceGroupFilter;
