import { IsMobileDisplay, IsMobileDisplayActionTypes } from '../types';
import { IS_MOBILE_DISPLAY } from '../constants/actionTypes';

const initialState: IsMobileDisplay = false;

export const responsiveReducer = (
  state: IsMobileDisplay = initialState,
  action: IsMobileDisplayActionTypes,
): IsMobileDisplay => {
  switch (action.type) {
    case IS_MOBILE_DISPLAY:
      return action.payload;
    default:
      return state;
  }
};
