import { Ancestor, DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';

export const FETCH_GROUP_SELECT_MENU = 'FETCH_GROUP_SELECT_MENU';
export const GROUP_SELECT_MENU_FETCHED = 'GROUP_SELECT_MENU_FETCHED';
export const GROUP_SELECT_MENU_FETCH_FAILED = 'GROUP_SELECT_MENU_FETCH_FAILED';

export type FetchGroupSelectMenuTypes =
  FetchGroupSelectMenu
  | GroupSelectMenuFetched
  | GroupSelectMenuFetchFailed

export interface FetchGroupSelectMenu {
  type: typeof FETCH_GROUP_SELECT_MENU;
  groupSelectUuid: string;
}

export type GroupSelectMenu = {
  deviceGroupName: string,
  deviceGroupId: string,
  pathGroups: Ancestor[],
  options: DeviceGroup[],
};

export interface GroupSelectMenuFetched {
  type: typeof GROUP_SELECT_MENU_FETCHED;
  groupSelectMenu: GroupSelectMenu;
  groupSelectUuid: string;
}

export interface GroupSelectMenuFetchFailed {
  type: typeof GROUP_SELECT_MENU_FETCH_FAILED;
  groupSelectUuid: string;
}

export const fetchGroupSelectMenu = (groupSelectUuid: string): FetchGroupSelectMenu => ({
  type: FETCH_GROUP_SELECT_MENU,
  groupSelectUuid,
});

export const groupSelectMenuFetched = (
  groupSelectMenu: GroupSelectMenu,
  groupSelectUuid: string,
): GroupSelectMenuFetched => ({
  type: GROUP_SELECT_MENU_FETCHED,
  groupSelectMenu,
  groupSelectUuid,
});

export const groupSelectMenuFetchFailed = (groupSelectUuid: string): GroupSelectMenuFetchFailed => ({
  type: GROUP_SELECT_MENU_FETCH_FAILED,
  groupSelectUuid,
});
