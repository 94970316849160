import React from 'react';
import { Translate } from 'react-localize-redux';
import { Col, Row } from 'react-bootstrap';
import useTranslation from '../../../../hooks/useTranslation';
import moment from 'moment/moment';
import { getValueWithUnitIfAvailable } from '../../../../utils/common';
import { MonthlyValuesWithDifferenceToLastMonth } from '@wiot/shared-domain/models/device/historical-value';

interface ConsumptionTableProps {
  historicalValues: MonthlyValuesWithDifferenceToLastMonth;
}

const ConsumptionTable = ({ historicalValues }: ConsumptionTableProps) => {
  const translate = useTranslation();

  return (
    <>
      <Row className="mt-4 mb-2">
        <Col className="text-center">
          { translate('meter-reading') }
        </Col>
      </Row>
      <table className="device-manager__table__table px-3">
        <thead>
        <tr className="details__headings--table">
          { ['month', 'year', 'value'].map((headerTranslationId, index) => (
            <th key={ index }>
              <Translate id={ headerTranslationId }/>
            </th>
          )) }
        </tr>
        </thead>
        <tbody>
        { historicalValues.values.map(({ atDate, unit, value }) => {
          return (
            <tr className="details__text" key={ atDate }>
              <td className="details__td">
                { moment(atDate, 'MMM YY').format('MMMM') }
              </td>
              <td className="details__td">
                { moment(atDate, 'MMM YY').format('YYYY') }
              </td>
              <td className="details__td w-25">
                { getValueWithUnitIfAvailable(value, unit) }
              </td>
            </tr>
          );
        }) }
        </tbody>
      </table>
    </>
  );
};

export default ConsumptionTable;