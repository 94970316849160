import { faCheck, faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataIntegrationFormats,
  DataIntegrationJobExtended,
} from '@wiot/shared-domain/models/data-integration/data-integration';
import RenderOnCondition from '../../../components/RenderOnCondition';
import ConfirmModal, { ConfirmationVariant } from '../../../components/Modal/ConfirmModal';
import { localizeDate } from '../../../utils/common';
import IntegrationModal from '../Modal/IntegrationActionModal';
import { PortalMenu } from '../../../components/PortalMenu/PortalMenu';
import TableMenu from './TableMenu';
import {
  HORIZONTAL_OFFSET_TABLE_VIEW,
  VERTICAL_OFFSET_TABLE_VIEW
} from '../../../components/PortalMenu/constants/offset-table-view';
import { getMenuId } from '../../../components/PortalMenu/constants/context-menu-ids';
import { closeContextMenu, openContextMenu } from '../../../state/context-menu/toggleContextMenuActionCreators';
import { AppState } from '../../../state/reducers/rootReducer';
import { Tooltip } from '../../../components/shared/Tooltip';

export interface TableDataRowProps {
  dataIntegrationJob: DataIntegrationJobExtended;
  selectOne?: (id: string) => void;
  removeUnit: (id: string) => Promise<void>;
  refreshData: () => void;
}

const TableDataRow = (props: TableDataRowProps) => {
  const {
    id,
    name,
    username,
    createdAt,
    executed,
    active,
    class: integrationClass,
  } = props.dataIntegrationJob;
  let toggleMenuButton: HTMLDivElement | null;

  const [toggleMenuButtonBoundingClientRect, setToggleMenuButtonBoundingClientRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDataIntegrationActionModal, setShowDataIntegrationActionModal] = useState(false);

  const visibleContextMenu = useSelector((state: AppState) => state.contextMenu.visibleContextMenuId);
  const dispatch = useDispatch();

  const menuId = getMenuId(id);
  const showMenu = (visibleContextMenu === menuId);
  const toggleMenu = () => {
    if (toggleMenuButton) {
      setToggleMenuButtonBoundingClientRect(toggleMenuButton.getBoundingClientRect());
    }
    if (!showMenu) {
      dispatch(openContextMenu(menuId));
    } else {
      dispatch(closeContextMenu());
    }
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prevState) => !prevState);
  };

  const toggleDataIntegrationActionModal = () => {
    setShowDataIntegrationActionModal((prevState) => !prevState);
  };

  const getFormattedComment = () => {
    if (integrationClass === DataIntegrationFormats.FTP) {
      return (
        <>
          <Translate id="ftp-user" /> <span className="multiselect_pill">{username}</span>
        </>
      );
    }
    if (integrationClass === DataIntegrationFormats.XML && executed) {
      const keysAdded = executed[0].response;
      return (
        <>
          {keysAdded} <Translate id={keysAdded > 1 ? 'keys-added' : 'key-added'} />{' '}
          {moment(new Date(executed[0].at)).fromNow()}
        </>
      );
    }
    if (executed && executed[executed.length - 1]) {
      const resLength = Number(executed[executed.length - 1].response);
      const lastExecution = executed[executed.length - 1];
      return (
        <>
          {lastExecution.response} <Translate id={resLength === 1 ? 'message' : 'messages'} />{' '}
          {moment(new Date(lastExecution.at)).fromNow()}
        </>
      );
    }
    return '';
  };

  return (
    <tr key={id} id={id} className="device-manager__table__table__trow">
      <td>{name || '-'}</td>
      <td>{createdAt ? localizeDate(createdAt) : ''}</td>
      <td>{getFormattedComment()}</td>
      <td>{active ? <FontAwesomeIcon icon={faCheck} /> : ''}</td>
      <td className="no-overflow">
        <div className="device-details">
          <div
            className="ellipsis text-color-main"
            ref={(tc) => {
              toggleMenuButton = tc;
            }}
            role="presentation"
            onClick={toggleMenu}
            data-tip="table-actions-button-tooltip"
            data-for="table-actions-button-tooltip"
          >
            <FontAwesomeIcon icon={faCog} />
          </div>
          <Tooltip id="table-actions-button-tooltip">
            <Translate id="actions" />
          </Tooltip>
          <RenderOnCondition condition={showMenu}>
            <PortalMenu
              anchorPosition={{
                left: toggleMenuButtonBoundingClientRect.left + HORIZONTAL_OFFSET_TABLE_VIEW,
                top: toggleMenuButtonBoundingClientRect.top + window.scrollY + VERTICAL_OFFSET_TABLE_VIEW
              }}
              minWidth={ 120 }
            >
              <TableMenu
                fetchDataIntegrationJobs={ props.refreshData }
                removeUnit={ (itemId) => props.removeUnit(itemId) }
                menuId={ menuId }
                dataIntegrationJob={ props.dataIntegrationJob }
                toggleDataIntegrationActionModal={ toggleDataIntegrationActionModal }
                toggleDeleteModal={ toggleDeleteModal }
              />
            </PortalMenu>
          </RenderOnCondition>

          <RenderOnCondition condition={showDataIntegrationActionModal}>
            <IntegrationModal
              closeModal={toggleDataIntegrationActionModal}
              title="data-integration"
              showDeleteButton
              addUnit={false}
              removeUnit={() => props.removeUnit(props.dataIntegrationJob.id)}
              dataIntegrationJob={props.dataIntegrationJob}
              closeMenu={toggleMenu}
              refreshData={props.refreshData}
            />
          </RenderOnCondition>

          <RenderOnCondition condition={showDeleteModal}>
            <ConfirmModal
              modalCloseRequested={() => toggleDeleteModal()}
              actionConfirmed={() => props.removeUnit(props.dataIntegrationJob.id)}
              translationIdOfElementType="data-integration"
              confirmationVariant={ ConfirmationVariant.DELETE }
            />
          </RenderOnCondition>
        </div>
      </td>
    </tr>
  );
};

export default TableDataRow;
