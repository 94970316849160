import React, { useEffect, useState } from 'react';
import { faAngleRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'react-localize-redux';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { AppState } from '../../state/reducers/rootReducer';
import { OutsideClickHandler } from '../OutsideClickHandler';
import RenderOnCondition from '../RenderOnCondition';
import TableLoadingIcon from './TableLoadingIcon';
import Portal from './Portal';
import CustomCheckbox from './CustomCheckbox';
import classNames from 'classnames';
import { GROUP_PATH_DELIMITER } from '../../utils/device-group-path-helper';
import { ExpandIcon } from '../FilterBar/select/components/ExpandIcon';
import { VIRTUAL_ROOT_DEVICE_GROUP_ID } from '@wiot/shared-domain/domain/device-tree/virtual-root-device-group-id';
import { includesCaseInsensitive } from '../../utils/string';
import { fetchDeviceGroup } from '../../state/device-group/group-select/fetch-selected-device-group/fetchDeviceGroupActionCreator';

import useTranslation from '../../hooks/useTranslation';
import {
  deselectDeviceGroup,
  resetGroupSelect
} from '../../state/device-group/group-select/fetch-selected-device-group/fetch-device-group-action-types';
import { v4 as uuidV4 } from 'uuid';
import { selectGroupSelectStateByUuid } from '../../state/device-group/group-select/selectGroupSelectStateByUuid';
import {
  fetchMenuWithTopLevelGroups, fetchMenuWithChildrenOfGivenGroup,
  fetchMenuWithGivenGroupAmongItsSiblings
} from '../../state/device-group/group-select/fetch-group-select-menu/fetchMenuActionCreators';
import { InputContextType } from '../Input/input-context-type';
import { Tooltip } from './Tooltip';


export interface GroupSelectProps {
  onSelectionChangedSingleSelect?: (selectedGroup: DeviceGroup | undefined) => void;
  preSelectedDeviceGroupId: string;
  rootIdOfRestrictedSubTree?: string;
  customLabelTranslationId?: string;
  maxHeight?: number;
  error?: string;
  touched?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
  targetId?: string;
  required?: boolean;
  dropDownWrapperClassNames?: string;
  isClearable?: boolean;
  isMultiSelect?: boolean;
  onSelectionChangedMultiSelect?: (selectedGroups: DeviceGroup[]) => void;
  defaultLabel?: string;
  isItemSelectableCustomCondition?: (deviceGroup: DeviceGroup) => boolean;
  context?: InputContextType;
}

const GroupSelect = ({
  onSelectionChangedSingleSelect,
  preSelectedDeviceGroupId,
  rootIdOfRestrictedSubTree,
  customLabelTranslationId,
  maxHeight,
  error,
  touched,
  disabled,
  hideLabel,
  targetId = '',
  required = false,
  dropDownWrapperClassNames,
  isClearable = true,
  isMultiSelect = false,
  onSelectionChangedMultiSelect,
  defaultLabel = 'choose-one',
  isItemSelectableCustomCondition,
  context = InputContextType.MODAL,
}: GroupSelectProps) => {
  const groupSelectUuid = React.useRef<string>(uuidV4()).current;

  const dispatch = useDispatch();
  const translate = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isReadonlyVirtualRoot, setIsReadonlyVirtualRoot] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const reduxZindex = useSelector((state: AppState) => state.modalZindex);
  const groupSelectState = useSelector((state: AppState) => selectGroupSelectStateByUuid(state, groupSelectUuid));
  const selectedGroups = groupSelectState.selectedDeviceGroups;
  const menu = groupSelectState.menu;
  const isLoading = groupSelectState.isLoadingSelectedDeviceGroups || groupSelectState.isLoadingMenu;

  const toggleDropdownOpen = () => {
    if (!disabled && !isReadonlyVirtualRoot) {
      setIsExpanded(!isExpanded);
    }
  };

  useEffect(() => {
    if (preSelectedDeviceGroupId === VIRTUAL_ROOT_DEVICE_GROUP_ID) {
      setIsReadonlyVirtualRoot(true);
      return;
    }

    if (preSelectedDeviceGroupId) {
      dispatch(fetchDeviceGroup(preSelectedDeviceGroupId, groupSelectUuid, isMultiSelect));
      dispatch(fetchMenuWithGivenGroupAmongItsSiblings(preSelectedDeviceGroupId, groupSelectUuid));
    } else {
      dispatch(resetGroupSelect(groupSelectUuid));
      dispatch(fetchMenuWithTopLevelGroups(groupSelectUuid));
    }
  }, []);

  // Cleanup of the state when the component is unloaded
  useEffect(() => {
    return () => {
      dispatch(resetGroupSelect(groupSelectUuid));
    };
  }, []);

  // Handling of onSelectionChanged to outside
  useEffect(() => {
    if (isMultiSelect) {
      onSelectionChangedMultiSelect && onSelectionChangedMultiSelect(selectedGroups);
    } else {
      const singleSelection = selectedGroups.length ? selectedGroups[0] : undefined;
      onSelectionChangedSingleSelect && onSelectionChangedSingleSelect(singleSelection);
    }
  }, [selectedGroups]);

  const selectionToggle = (
    group: DeviceGroup,
  ) => {
    const isAlreadySelected: boolean = isSelected(group);
    if (isMultiSelect) {
      dispatch(
        isAlreadySelected ?
          deselectDeviceGroup(group.id!, groupSelectUuid)
          : fetchDeviceGroup(group.id!, groupSelectUuid, isMultiSelect));
    } else {
      if (!isAlreadySelected) {
        dispatch(fetchDeviceGroup(group.id!, groupSelectUuid, false));
        setIsExpanded(false);
      }
    }
  };

  const clearOptionSelect = () => {
    dispatch(resetGroupSelect(groupSelectUuid));
    dispatch(fetchMenuWithTopLevelGroups(groupSelectUuid));
  };

  const handleStepInto = async (group: DeviceGroup) => {
    if (!isAllowedToStepInto(group)) {
      return;
    }

    dispatch(fetchMenuWithChildrenOfGivenGroup(group.id!, groupSelectUuid, isMultiSelect));
    setSearchTerm('');
  };


  const navigateToDeviceGroup = async (
    event: React.MouseEvent<HTMLAnchorElement>,
    deviceGroup: DeviceGroup,
  ) => {
    event.preventDefault();

    if (deviceGroup.id === VIRTUAL_ROOT_DEVICE_GROUP_ID) {
      dispatch(fetchMenuWithTopLevelGroups(groupSelectUuid));
    } else {
      dispatch(fetchMenuWithChildrenOfGivenGroup(deviceGroup.id!, groupSelectUuid, isMultiSelect));
    }
  };

  const getSelectionLabelText = () => {
    const translatedDefaultLabel = translate(defaultLabel);
    if (!selectedGroups.length) {
      return translatedDefaultLabel;
    }

    if (isMultiSelect) {
      return `${ translatedDefaultLabel } (${ selectedGroups.length })`;
    }

    const [selectedDeviceGroup] = selectedGroups;
    const ancestorsNames = (selectedDeviceGroup.ancestors || []).map((ancestor) => ancestor.name);
    const pathGroupNames = [...ancestorsNames, selectedDeviceGroup.name || ''];

    return pathGroupNames.join(GROUP_PATH_DELIMITER);
  };

  const getMenuBreadCrumb = () => {
    return menu?.pathGroups.map((group, index) => {
      const isLastItem = index === menu?.pathGroups.length - 1;
      if (!isLastItem) {
        return (
          <a key={ index } href="#" onClick={ (e) => navigateToDeviceGroup(e, group) }>
            { ' ' }
            { index !== 0 && '›' } { group.name }
          </a>
        );
      }
      return (
        <span key={ index }>
          { ' ' }
          { index !== 0 && '›' } { group.name }
        </span>
      );
    });
  };

  const getSearchPlaceHolder = () => {
    if (!menu || !menu.deviceGroupName) {
      return '';
    }

    return `🔍 ${ translate('search-in', { groupName: menu.deviceGroupName, })?.toString() }`;
  };

  const getFilteredDeviceGroups = (): DeviceGroup[] => {
    if (!menu) {
      return [];
    }

    if (!searchTerm) {
      return menu.options;
    }

    return menu.options.filter(
      (group: DeviceGroup) =>
        group && includesCaseInsensitive(group.name, searchTerm)
    );
  };

  const isRestrictedSubtree = (deviceGroup: DeviceGroup) =>
    !!rootIdOfRestrictedSubTree && rootIdOfRestrictedSubTree === deviceGroup.id;

  const isAllowedToSelect = (deviceGroup: DeviceGroup) => {
    const allowedToSelectByCustomCondition = isItemSelectableCustomCondition ?
      isItemSelectableCustomCondition(deviceGroup) : true;
    return !isRestrictedSubtree(deviceGroup) && allowedToSelectByCustomCondition;
  };


  const isSelected = (deviceGroup: DeviceGroup) => {
    const isGivenGroupSelected = selectedGroups.some(selectedGroup => selectedGroup.id === deviceGroup.id);
    return isGivenGroupSelected || isAncestorSelected(deviceGroup);
  };

  const isAncestorSelected = (deviceGroup: DeviceGroup): boolean => {
    return selectedGroups.some(selected =>
      deviceGroup.idsPath?.includes(selected.id!) && deviceGroup.id !== selected.id
    );
  };

  const isAllowedToStepInto = (deviceGroup: DeviceGroup) => {
    return !isRestrictedSubtree(deviceGroup) && deviceGroup.hasChildren;
  };

  const getRenderedOptions = () => {
    return getFilteredDeviceGroups().map((group: DeviceGroup, index) => {
      return (
        <div key={ group.id! + index } className="multiselect_option_wrapper">
          <div className="multiselect_option_content">
            <div className="multiselect_option_checkbox">
              <RenderOnCondition condition={ isAllowedToSelect(group) }>
                <CustomCheckbox
                  readOnly
                  onClick={ (event) => {
                    event.stopPropagation();
                    selectionToggle(group);
                  } }
                  checked={ isSelected(group) }
                  disabled={ isAncestorSelected(group) }
                />
              </RenderOnCondition>
            </div>
            <div className="multiselect_option_label" onClick={ () => selectionToggle(group) }>
              { group.name }
            </div>
            <div
              className="group-select__navigate-to-children-button"
              onClick={ () => handleStepInto(group) }
              data-tip="navigate-into"
              data-for="navigate-into"
            >
              { isAllowedToStepInto(group) &&
                  <FontAwesomeIcon className="group-select__dropdown-icon" icon={ faAngleRight }/>
              }
              <Tooltip id="navigate-into" place="right" >
                <Translate id="open-child-elements"/>
              </Tooltip>
            </div>
          </div>
        </div>
      );
    });
  };

  const isModalContext = context === InputContextType.MODAL;
  const isFilterBarContext = context === InputContextType.FILTER_BAR;

  const getGroupSelectPosition = () => {
    const selectEl = document.getElementById(targetId);
    const selectBoundary = selectEl?.getBoundingClientRect();
    return {
      top: selectBoundary?.bottom || 0,
      left: selectBoundary?.left || 0,
      width: selectBoundary?.width || 0,
      zIndex: reduxZindex?.zIndex + 1,
    };
  };

  return (
    <div className="form__label">
      <RenderOnCondition condition={ !hideLabel }>
        <label className={ required ? 'required' : '' }>
          <Translate id={ customLabelTranslationId || 'choose-group' }/>
        </label>
      </RenderOnCondition>
      <div
        className={ classNames({
          'group-select': isModalContext,
          'filterbar__item filter-bar-select': isFilterBarContext,
        }) }
      >
        <div
          id={ targetId }
          className={ classNames({
            'group-select__control': isModalContext,
            'group-select__control--menu-is-open': isModalContext && isExpanded,
            'filter-bar-control filter-bar-select__control': isFilterBarContext,
            'filter-bar-select__control--active': isFilterBarContext && isExpanded,
            'group-select-disabled': disabled || isReadonlyVirtualRoot,
          }) }
          tabIndex={ 0 }
        >
          <div className="select__value-container">
            <div className={ classNames({
              'group-select__label': isModalContext,
              'select__label': isFilterBarContext,
            }) }
                 onClick={ () => !isLoading && toggleDropdownOpen() }
            >
              <RenderOnCondition condition={ isLoading }>
                <TableLoadingIcon groupLoading size={ 30 }/>
              </RenderOnCondition>
              <RenderOnCondition condition={ !isLoading }>
                <span>{ getSelectionLabelText() }</span>
              </RenderOnCondition>
            </div>
          </div>

          <div className={ classNames({ 'group-select__indicator-container': isModalContext }) }>
            <RenderOnCondition condition={ !disabled && !isReadonlyVirtualRoot }>
              <div
                className="group-select__dropdown-indicator"
                onClick={ () => !isLoading && toggleDropdownOpen() }
              >
                <ExpandIcon className="group-select__dropdown-icon" isExpanded={ isExpanded }/>
              </div>
            </RenderOnCondition>
            <RenderOnCondition condition={ isClearable }>
              <div
                className="fa-pull-right group-select__clear-indicator"
                onClick={ () => !isLoading && clearOptionSelect() }
              >
                <FontAwesomeIcon className="group-select__clear-icon" icon={ faTimes }/>
              </div>
            </RenderOnCondition>
          </div>
        </div>

        { isExpanded && !disabled && (
          <>
            <OutsideClickHandler onClickOutside={ () => setIsExpanded(false) }>
              <Portal>
                <div
                  style={ getGroupSelectPosition() }
                  className={ classNames('multiselect_dropdown_wrapper', dropDownWrapperClassNames || 'multiselect_group-select') }
                >
                  <div className="multiselect_breadcrumb">
                    <span>{ getMenuBreadCrumb() }</span>
                  </div>
                  <RenderOnCondition condition={ !!menu?.deviceGroupId }>
                    <Form.Control
                      type="text"
                      value={ searchTerm }
                      placeholder={ getSearchPlaceHolder() }
                      onChange={ (event: React.FormEvent<any>) => (setSearchTerm(event.currentTarget.value)) }
                    />
                  </RenderOnCondition>
                  <div className="multiselect_option_group-select" style={ { maxHeight: maxHeight || '' } }>
                    <>{ getRenderedOptions() }</>
                  </div>
                </div>
              </Portal>
            </OutsideClickHandler>
          </>
        ) }

      </div>
      { !!error && touched && <div className="input-error">{ error }</div> }
    </div>
  );
};

export default GroupSelect;
