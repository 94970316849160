import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { IUser } from '@wiot/shared-domain/models/user/user';

interface UserImportPreviewProps {
  users: IUser[];
}

const UserImportPreview = ({ users }: UserImportPreviewProps) => (
  <div className="mb-3">
    <Row className="form__title text-color-main">
      <Col>
        <Translate
          id="preview-users-count"
          data={{ count: users?.filter((user) => user).length }}
        />
      </Col>
    </Row>
    {users?.map((user, index) => {
      if (user) {
        const { firstName, lastName, email } = user;
        return (
          <Row className="form__label" key={index}>
            <Col>{`${firstName && firstName} ${lastName && lastName}: ${email && email}`}</Col>
          </Row>
        );
      }
      return null;
    })}
  </div>
);

export default UserImportPreview;
