import { CLOSE_CONTEXT_MENU, OPEN_CONTEXT_MENU, ToggleContextMenuAction } from './toggleContextMenuAction';

export type ContextMenuState = {
  visibleContextMenuId?: string,
};

export const contextMenuReducer = (
  state: ContextMenuState = {},
  action: ToggleContextMenuAction,
): ContextMenuState => {
  switch (action.type) {
    case OPEN_CONTEXT_MENU:
      return {
        ...state,
        visibleContextMenuId: action.payload,
      };
    case CLOSE_CONTEXT_MENU:
      return {
        ...state,
        visibleContextMenuId: undefined,
      };
    default:
      return state;
  }
};
