import { DeviceGroupCreationTypes } from '../device-group/create-device-group/deviceGroupCreationTypes';
import { DeviceGroupUpdateTypes } from '../device-group/update-device-group/deviceGroupUpdateTypes';
import { FetchDeviceGroupsActionTypes } from '../device-group/paginated-device-groups/paginated-device-group-action-types';

export const IS_TABLE_LOADING = 'IS_TABLE_LOADING';

interface IsTableLoadingAction {
  type: typeof IS_TABLE_LOADING;
  payload: boolean;
}

export type IsTableLoadingActionTypes =
  IsTableLoadingAction
  | DeviceGroupCreationTypes
  | DeviceGroupUpdateTypes
  | FetchDeviceGroupsActionTypes;
export const isTableLoading = (loading: boolean): IsTableLoadingActionTypes => ({
  type: IS_TABLE_LOADING,
  payload: loading,
});
