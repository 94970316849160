import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';

export const UPDATE_DEVICE_GROUP = 'UPDATE_DEVICE_GROUP';
export const DEVICE_GROUP_UPDATED = 'DEVICE_GROUP_UPDATED';
export const DEVICE_GROUP_UPDATE_FAILED = 'DEVICE_GROUP_UPDATE_FAILED';

export type DeviceGroupUpdateTypes = UpdateDeviceGroupAction | DeviceGroupUpdatedAction | DeviceGroupUpdateFailedAction;

export interface UpdateDeviceGroupAction {
  type: typeof UPDATE_DEVICE_GROUP;
  deviceGroup: DeviceGroup;
}

export interface DeviceGroupUpdatedAction {
  type: typeof DEVICE_GROUP_UPDATED;
  deviceGroup: DeviceGroup;
}

export interface DeviceGroupUpdateFailedAction {
  type: typeof DEVICE_GROUP_UPDATE_FAILED;
  deviceGroup: DeviceGroup;
}

export const updateDeviceGroupAction = (deviceGroup: DeviceGroup): UpdateDeviceGroupAction => ({
  type: UPDATE_DEVICE_GROUP,
  deviceGroup: deviceGroup,
});

export const deviceGroupUpdatedAction = (updatedDeviceGroup: DeviceGroup): DeviceGroupUpdatedAction => ({
  type: DEVICE_GROUP_UPDATED,
  deviceGroup: updatedDeviceGroup,
});

export const deviceGroupUpdateFailedAction = (updatedDeviceGroup: DeviceGroup): DeviceGroupUpdateFailedAction => ({
  type: DEVICE_GROUP_UPDATE_FAILED,
  deviceGroup: updatedDeviceGroup,
});
