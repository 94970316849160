import { Device } from '@wiot/shared-domain/models/device/device';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Col, Form, Row } from 'react-bootstrap';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import ReactDatePicker from 'react-datepicker';
import { toastr } from 'react-redux-toastr';
import { IDeviceTypeProfile } from '@wiot/shared-domain/models/device-type-profile/device-type-profile';
import { getRandomModalOffset } from '../../utils/dialog';
import ModalHeader from '../../components/Modal/ModalHeader';
import Portal from '../../components/shared/Portal';
import { getDeviceTypeProfiles } from '../../api/apiHelpers';
import { addDeviceReading } from '../../api/deviceReadingService';
import LoadingIcon from '../../components/shared/LoadingIcon';

export interface AddManualDeviceReadingModalProps extends LocalizeContextProps {
  /**
   * The device for wich to add a manual device reading.
   */
  device: Partial<Device>;

  /**
   * A callback that is invoked when the user has closed the modal.
   *
   * @param hasUserAddedOneOrMoreDeviceReadings
   * Determines whether the user has added one or more device readings while the modal was open.
   * When this modal was opened from a device list (e.g. the DeviceManager view) it should refresh the device list
   * to display the updated reading value if this argument is set to true.
   *
   */
  onModalClosed: (hasUserAddedOneOrMoreDeviceReadings: boolean) => void;
}

const AddManualDeviceReadingModal = (props: AddManualDeviceReadingModalProps) => {
  const { device, onModalClosed, translate } = props;
  const [modalUid] = useState(uuidv4());

  const hasUserAddedOneOrMoreReadings = useRef(false);

  const [randomModalOffset, setRandomModalOffset] = useState({ marginTop: 0, marginRight: 0 });
  const [deviceTypeProfile, setDeviceTypeProfile] = useState<IDeviceTypeProfile | null>(null);
  const [isAddingReading, setIsAddingReading] = useState(false);
  const [readingDate, setReadingDate] = useState<Date | null>(new Date());
  const [readingValue, setReadingValue] = useState<number | ''>('');

  useEffect(() => {
    const fetchDeviceTypeProfile = async () => {
      const deviceTypeProfiles = await getDeviceTypeProfiles(
        device.manufacturer ? device.manufacturer.flagId : '',
        device.deviceType?.id,
      );

      if (deviceTypeProfiles?.length > 0) {
        setDeviceTypeProfile(deviceTypeProfiles[0]);
      }
    };

    setRandomModalOffset(getRandomModalOffset());
    fetchDeviceTypeProfile();
  }, []);

  const addReading = async (): Promise<boolean> => {
    if (!readingValue || Number.isNaN(readingValue) || !readingDate) {
      toastr.error(
        translate('failed').toString(),
        translate('missing-or-incorrect-input-value').toString(),
      );

      return false;
    }

    if (!deviceTypeProfile) {
      toastr.error(
        translate('failed').toString(),
        translate('failed-add-manual-dev-reading').toString(),
      );

      return false;
    }

    try {
      setIsAddingReading(true);

      const deviceReading = await addDeviceReading(
        readingValue,
        device as Device,
        deviceTypeProfile,
        readingDate,
      );

      if (!deviceReading) {
        toastr.error(
          translate('failed').toString(),
          translate('failed-add-manual-dev-reading').toString());

        return false;
      }

      hasUserAddedOneOrMoreReadings.current = true;

      toastr.success(
        translate('success').toString(),
        translate('success-add-manual-dev-reading').toString(),
        { preventDuplicates: false } as any
      );

      return true;
    } catch (error) {
      toastr.error(
        translate('failed').toString(),
        translate('failed-add-manual-dev-reading').toString());

      return false;
    } finally {
      setIsAddingReading(false);
    }
  };

  const isAddButtonEnabled = () => !!deviceTypeProfile && !!readingDate && readingValue !== '' && !isAddingReading;

  const closeModal = () => {
    onModalClosed(hasUserAddedOneOrMoreReadings.current);
  };

  const onReadingDateChange = (date: Date | [Date | null, Date | null] | null) => {
    if (Array.isArray(date)) {
      setReadingDate(null);
    }

    if (date instanceof Date) {
      const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
      setReadingDate(dateWithoutTime);
    }
  };

  const onCloseButtonClick = (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
    if (event) {
      event.stopPropagation();
    }

    closeModal();
  };

  const onAddButtonClick = async (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
    if (event) {
      event.stopPropagation();
    }

    await addReading();

    setReadingValue('');
  };

  return (
    <Portal>
      <div
        tabIndex={ 0 } // make it focusable
        style={ { ...randomModalOffset } }
        id={ `add-manual-device-reading-${ modalUid }` }
        className="device-modal"
      >
        <ModalHeader
          isDevice={ false }
          titleTranslationId="add-manual-device-reading"
          titlePostfix={ device.deviceId }
          targetId={ modalUid }
          handleClose={ closeModal }
        />
        <div className="device-modal__body" id={ `modal-add-manual-device-reading-${ device.id }` }>
          <div className="form">
            <div className="details__section__form-container">
              {
                isAddingReading
                  ? (
                    <div className="m-5 position-relative w-100 h-100">
                      <LoadingIcon/>
                    </div>
                  )
                  : (
                    <label className="form__label">
                      <Row className="mx-0">
                        <Col md={ 8 } xs={ 6 }>
                          <Translate id="meter-reading"/>
                          <Form.Control
                            className="form-select modal-input"
                            type="text"
                            value={ readingValue }
                            onChange={ (e: ChangeEvent<any>) =>
                              setReadingValue(e.target.value.replace(/,/g, '.').replace(/[^0-9.]/g, ''))
                            }
                            placeholder={ `${ translate('value-in') } ${ deviceTypeProfile?.unit }` }
                            disabled={ isAddingReading }
                          />
                        </Col>
                        <Col md={ 4 } xs={ 6 } className="d-flex flex-column">
                          <Translate id="date"/>
                          <ReactDatePicker
                            className="w-100 modal-input cursor-pointer"
                            selected={ readingDate }
                            onChange={ (date) => onReadingDateChange(date) }
                            maxDate={ new Date() }
                            dateFormat="dd/MM/yyyy"
                            portalId={ `modal-add-manual-device-reading-${ device.id }` }
                            disabled={ isAddingReading }
                          />
                        </Col>
                      </Row>
                      <Row className="mx-0 mt-2">
                        <Col md={ 12 } xs={ 6 } style={ { display: 'flex', justifyContent: 'right' } }>
                          <button
                            type="button"
                            className="form__button--cancel"
                            onClick={ onCloseButtonClick }
                          >
                            <Translate id="close"/>
                          </button>

                          <button
                            type="button"
                            disabled={ !isAddButtonEnabled() }
                            className="form__button--blue background-color-main text-color-white border-color-main"
                            onClick={ () => onAddButtonClick() }
                          >
                            <Translate id="add"/>
                          </button>
                        </Col>
                      </Row>
                    </label>
                  )
              }
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default withLocalize(AddManualDeviceReadingModal);
