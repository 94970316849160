import { Col, Row } from "react-bootstrap";
import { Translate } from "react-localize-redux";
import React from "react";
import { SelectWrapperWithFormik } from "../../../components/FilterBar/select/SelectWrapperWithFormik";
import { SelectableOption } from "../../../components/FilterBar/select/selectable-option";
import { InputContextType } from "../../../components/Input/input-context-type";

export interface DeviceExportSelectProps {
  value: string,
  options: SelectableOption[],
  onChange: (field: string, value: string) => void,
  name: string;
  modalUid: string,
  translateId: string;
  isClearable?: boolean;
  translateLabel?: boolean;
}

export function DeviceExportSelect({
  value,
  modalUid,
  onChange,
  name,
  options,
  translateId,
  isClearable = false,
  translateLabel = true,
}: DeviceExportSelectProps) {
  return <Row className="mb-1" data-testid={ `device-export-${ translateId }` }>
    <Col md={ 7 }>
      <label className="form__label">
        <Translate id={ translateId }/>
      </label>
    </Col>
    <Col>
      <SelectWrapperWithFormik
        translateOptions={ translateLabel }
        isClearable={ isClearable }
        fieldName=""
        value={ value }
        options={ options }
        onChange={ onChange }
        inputContextType={ InputContextType.MODAL }
        portalTargetId={ `modal-${ modalUid }` }
      />
    </Col>
  </Row>;
}
