import React from 'react';
import { Col } from 'react-bootstrap';
import { MapContainer } from 'react-leaflet';
import L, { LatLng } from 'leaflet';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import { CustomFieldInput } from './CustomFieldInput';
import CustomTileLayer from '../Map/CustomTileLayer';
import DraggableMarker from '../Map/DraggableMarker';
import { UpdateBoundComponent } from '../../pages/DeviceManager/Map/UpdateBoundComponent';
import { InvalidateSize } from '../Map/InvalidateSize';
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM } from '../../constants';
import { validateLatitude, validateLongitude } from '../../utils/validation';
import HasPermission from '../HasPermission';
import { ACLDeviceManager } from '@wiot/shared-domain/models/role/access-level';
import { useSelector } from 'react-redux';
import { AppState } from '../../state/reducers/rootReducer';

interface ICustomGeoInputProps {
  latitude: number;
  longitude: number;
  onChange: (fieldName: 'latitude' | 'longitude', value: string) => void;
  deviceType?: IDeviceType;
}

const CustomGeoInput = ({ latitude, longitude, onChange, deviceType }: ICustomGeoInputProps) => {
  const userPermission = useSelector<AppState>((state) => state.currentUser.permission);

  const handlePositionChange = (latLng: LatLng) => {
    onChange('latitude', latLng.lat.toString());
    onChange('longitude', latLng.lng.toString());
  };

  const currentPosition =
    latitude && longitude
      ? {
        lat: latitude,
        lng: longitude,
      }
      : DEFAULT_MAP_CENTER;
  const currentLatLngBounds = L.latLngBounds([currentPosition]);

  return (
    <>
      <Col lg={ 3 }>
        <CustomFieldInput
          type="number"
          translationId="latitude"
          fieldName="latitude"
          onChange={ (event: React.FormEvent<HTMLInputElement>) =>
            onChange('latitude', event.currentTarget.value)
          }
          value={ latitude }
          step={ 0.000001 }
          validate={ () => validateLatitude(latitude) }
        />
      </Col>
      <Col lg={ 3 }>
        <CustomFieldInput
          type="number"
          translationId="longitude"
          fieldName="longitude"
          onChange={ (event: React.FormEvent<HTMLInputElement>) =>
            onChange('longitude', event.currentTarget.value)
          }
          value={ longitude }
          step={ 0.000001 }
          validate={ () => validateLongitude(longitude) }
        />
      </Col>
      <HasPermission permissionObj={userPermission} permissionKey={ACLDeviceManager.VIEW_MAP}>
        <Col lg={ 12 }>
          <ReactVisibilitySensor>
            { ({ isVisible }) => (
              <MapContainer
                center={ currentPosition }
                zoom={ DEFAULT_MAP_ZOOM }
                className="map-container-small"
              >
                <CustomTileLayer/>
                { isVisible && <InvalidateSize/> }
                <UpdateBoundComponent bounds={ currentLatLngBounds }/>
                <DraggableMarker
                  position={ currentPosition }
                  handlePositionChange={ handlePositionChange }
                  deviceType={ deviceType }
                />
              </MapContainer>
            ) }
          </ReactVisibilitySensor>
        </Col>
      </HasPermission>
    </>
  );
};

export default CustomGeoInput;
