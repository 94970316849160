import React from 'react';
import { Translate } from 'react-localize-redux';
import useTranslation from '../../hooks/useTranslation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import RenderOnCondition from '../RenderOnCondition';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

export interface ButtonBaseProps {
  classNames?: string;
  textTranslationId?: string;
  textPlainText?: string;
  onClick?: () => void;
  isVisible?: boolean;
  style?: React.CSSProperties | undefined;
  mouseOverTextTranslationId?: string;
  mouseOverPlainText?: string;
  formNameToSubmitOnClick?: string;
  icon?: IconDefinition;
  iconSize?: SizeProp;
}

function ButtonBase(
  {
    classNames = '',
    textTranslationId,
    textPlainText = '',
    onClick,
    style,
    isVisible = true,
    mouseOverTextTranslationId,
    mouseOverPlainText,
    formNameToSubmitOnClick,
    icon,
    iconSize,
  }: ButtonBaseProps) {
  const translate = useTranslation();

  return (
    <>
      { isVisible && (
        <button
          className={ classNames }
          type={ formNameToSubmitOnClick ? 'submit' : 'button' }
          onClick={ onClick }
          style={ style }
          form={ formNameToSubmitOnClick }
          title={
            mouseOverTextTranslationId
              ? translate(mouseOverTextTranslationId).toString()
              : mouseOverPlainText
          }
        >
          <RenderOnCondition condition={ icon }>
            <span className="device-manager__table__header__add-button__icon">
             <FontAwesomeIcon icon={ icon! } size={ iconSize }/>
          </span>
          </RenderOnCondition>
          { textTranslationId && <Translate id={ textTranslationId }/> }
          { !textTranslationId && <>{ textPlainText }</> }
        </button>
      ) }
    </>
  );
}

export default ButtonBase;
