import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, FormikProps } from 'formik';
import { ISettings } from '@wiot/shared-domain/models/settings/settings';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { CustomFieldInput } from '../../../components/Table/CustomFieldInput';
import RenderOnCondition from '../../../components/RenderOnCondition';
import { validateEmail } from '../../../utils/validation';

interface LegalDetailsSettingsProps {
  readOnly?: boolean;
  path: string;
  formikProps: FormikProps<ISettings>;
}

const LegalDetailsSettings = ({ readOnly, path, formikProps }: LegalDetailsSettingsProps) => {
  const [isInit, setIsInit] = useState<boolean>(true);

  useEffect(() => {
    if (isInit) {
      if (
        formikProps.values?.site?.legalDetails?.[path]?.default &&
        !formikProps.values?.site?.legalDetails?.[path]?.custom
      ) {
        formikProps.setFieldValue(
          `site.legalDetails.${ path }.custom`,
          // <lb> is used for lineBreaking the default string that was saved in mongoDB (to achieve lineBreaks in a textarea)
          formikProps.values.site.legalDetails[path].default?.split('<lb>').join('\n'),
        );
        setIsInit(false);
      }
    }
  }, [formikProps, isInit, path]);

  const handleResetClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e && e.preventDefault();
    if (formikProps.values?.site?.legalDetails?.[path]?.default) {
      formikProps.setFieldValue(
        `site.legalDetails.${ path }.custom`,
        // <lb> is used for lineBreaking the default string that was saved in mongoDB (to achieve lineBreaks in a textarea)
        formikProps.values.site.legalDetails[path].default?.split('<lb>').join('\n'),
      );
      formikProps.setFieldValue(`site.legalDetails.${ path }.showForm`, false);
    }
  };

  return (
    <>
      <Row className="mb-5">
        <Col sm={ 2 } lg={ 3 }>
          <label className="settings-form__label">
            <Translate id={ `site-settings-customizations-${ path }` }/>
          </label>
        </Col>
        <Col sm={ 6 } lg={ 9 }>
          <RenderOnCondition condition={ !readOnly }>
            <div className="standard-font-size mb-2">
              <label>
                <Translate id="site-settings-show-this-page"/>
                <label className="custom-checkbox">
                  <Field
                    component="input"
                    name={ `site.legalDetails.${ path }.isActive` }
                    type="checkbox"
                    checked={ formikProps.values?.site?.legalDetails?.[path]?.isActive }
                  />
                  <span className="checkmark border-color-main"/>
                </label>
              </label>
            </div>
            <RenderOnCondition condition={ formikProps.values?.site?.legalDetails?.[path]?.isActive }>
              <RenderOnCondition condition={ path === 'contact' }>
                <div className="standard-font-size mb-2">
                  <label>
                    <Translate id="site-settings-show-contact-form"/>
                    <label className="custom-checkbox">
                      <Field
                        component="input"
                        name={ `site.legalDetails.${ path }.showForm` }
                        type="checkbox"
                        checked={ formikProps.values?.site?.legalDetails?.[path]?.showForm }
                      />
                      <span className="checkmark border-color-main"/>
                    </label>
                  </label>
                </div>
                <RenderOnCondition
                  condition={ formikProps.values?.site?.legalDetails?.[path]?.showForm }
                >
                  <div className="standard-font-size mb-2">
                    <Col lg={ 8 }>
                      <CustomFieldInput
                        translationId="site-settings-enter-admin-mail"
                        required={ true }
                        className="form-select input"
                        fieldName="site.siteAdminEmail"
                        value={ formikProps.values.site?.siteAdminEmail }
                        readOnly={ readOnly }
                        validate={ validateEmail }
                      />
                    </Col>
                  </div>
                </RenderOnCondition>
              </RenderOnCondition>
              <CustomFieldInput
                fieldName={ `site.legalDetails.${ path }.custom` }
                className="input-area"
                value={ formikProps.values?.site?.legalDetails?.[path]?.custom }
                readOnly={ readOnly }
                isTextarea
                translationId="enter-html-code"
                rows={ 10 }
              />
              <div className="form__row jc-end m-top-15">
                <button
                  className="form__button--cancel"
                  onClick={ handleResetClick }
                  disabled={ readOnly }
                >
                  <FontAwesomeIcon icon={ faUndo }/> <Translate id="reset-legal-details"/>
                </button>
              </div>
            </RenderOnCondition>
          </RenderOnCondition>
          <RenderOnCondition condition={ readOnly }>
            <Translate id="insufficient-privileges"/>
          </RenderOnCondition>
        </Col>
      </Row>
    </>
  );
};

export default LegalDetailsSettings;
