import React, { useEffect, useState } from 'react';
import { TileLayer, TileLayerProps } from 'react-leaflet';
import { IMapTiles } from '@wiot/shared-domain/models/settings/settings';
import { getSiteSettingsInDB } from '../../api/apiHelpers';
import { MAP_PROVIDERS } from '../../constants';

interface ICustomTileLayerProps extends Omit<TileLayerProps, 'url'> {
  // TileLayerProps has url as a required prop but for our Custom Layer component
  // we have a default so it can be an optional prop
  url?: string;
}

const CustomTileLayer = (props: ICustomTileLayerProps) => {
  const { attribution, url, maxZoom, ...remainingProps } = props;
  const [mapTileSettings, setMapTileSettings] = useState<IMapTiles>();

  useEffect(() => {
    const fetchSiteSettings = async () => {
      const settings = await getSiteSettingsInDB();
      if (settings.site?.mapTiles) {
        setMapTileSettings(settings.site.mapTiles);
      }
    };
    fetchSiteSettings();
  }, []);

  const getUrl = (mapUrl: string) => {
    if (
      mapTileSettings?.provider === 'here-map' &&
      mapTileSettings.style &&
      mapTileSettings.apiKey
    ) {
      return mapUrl
        .replace('style', mapTileSettings.style as string)
        .replace('apiKeyValue', mapTileSettings.apiKey);
    }
    return mapUrl;
  };

  const selectedProvider = MAP_PROVIDERS.find(
    (provider) => provider.value === mapTileSettings?.provider,
  );

  if (selectedProvider) {
    return (
      <TileLayer
        attribution={attribution || selectedProvider?.attribution}
        url={url || getUrl(selectedProvider?.url) || ''}
        maxZoom={maxZoom || 18}
        {...remainingProps}
      />
    );
  }
  return null;
};

export default CustomTileLayer;
