import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, Formik, getIn } from 'formik';
import * as Yup from 'yup';
import { Translate } from 'react-localize-redux';
import { ISettings } from '@wiot/shared-domain/models/settings/settings';
import { CustomFieldInput } from '../../../components/Table/CustomFieldInput';
import RenderOnCondition from '../../../components/RenderOnCondition';
import useTranslation from '../../../hooks/useTranslation';

interface ISupportSettings {
  tabKey: string;
  handleSubmit: (values: ISettings) => void;
  initialValues: ISettings;
  readOnly?: boolean;
}

const SupportSettings = ({ handleSubmit, initialValues, readOnly, tabKey }: ISupportSettings) => {
  const translate = useTranslation();

  return (
    <Formik enableReinitialize onSubmit={ handleSubmit } initialValues={ initialValues } validationSchema={
      Yup.object().shape({
        site: Yup.object().shape({
          support: Yup.object().shape({
            isFeedbackEnabled: Yup.boolean(),
            feedbackFormEmail: Yup.string()
              .when('isFeedbackEnabled',
                {
                  is: true,
                  then: Yup.string()
                    .email(translate('invalid-email').toString())
                    .required(translate('required-field').toString()),
                }),
          })
        })
      })
    }
    >
      {(formikProps) => (
        <form id={ `${ tabKey }-settings` } className="settings-container" onSubmit={ formikProps.handleSubmit }>
          <RenderOnCondition condition={ formikProps.values.site?.support }>
            <div className="settings-header">
              <Translate id="support" />
            </div>
            <Row className="mb-5">
              <Col sm={ 2 } lg={ 3 }>
                <label className="settings-form__label">
                  <Translate id="settings-activate-feedback" />
                </label>
              </Col>
              <Col sm={ 6 } lg={ 9 }>
                <div className="standard-font-size mb-2">
                  <label>
                    <Translate id="activate"/>
                    <label className="custom-checkbox">
                      <Field
                        disabled={ readOnly }
                        component="input"
                        name="site.support.isFeedbackEnabled"
                        type="checkbox"
                        checked={ formikProps.values?.site?.support?.isFeedbackEnabled }
                      />
                      <span className="checkmark border-color-main"/>
                    </label>
                  </label>
                </div>
                <RenderOnCondition condition={ formikProps.values?.site?.support?.isFeedbackEnabled }>
                  <div className="standard-font-size mb-2">
                    <Col lg={ 8 }>
                      <CustomFieldInput
                        translationId="site-settings-enter-support-mail"
                        required={ true }
                        fieldName="site.support.feedbackFormEmail"
                        value={ formikProps.values.site?.support?.feedbackFormEmail }
                        error={ getIn(formikProps.errors, 'site.support.feedbackFormEmail') }
                        readOnly={ readOnly }
                      />
                    </Col>
                  </div>
                </RenderOnCondition>
              </Col>
            </Row>

          </RenderOnCondition>
        </form>
      )}
    </Formik>
  );
};

export default SupportSettings;
