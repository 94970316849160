import { Dispatch } from 'redux';
import { authenticatedInstance as httpClientInstance, handleApiError } from '../../api/apiHelpers';
import {
  DEVICE_KEYS_FILE_FETCHED,
  DeviceKeysFileFetchedAction,
  FETCH_DEVICE_KEYS_AS_FILE,
  FetchDeviceKeysAsFileAction,
  RESET_DEVICE_KEYS_FILE,
  ResetDeviceKeysFileAction,
} from './fetchDeviceKeysAsFileAction';
import { buildQueryString } from '../../utils/common';
import { DeviceKeyExportFileFormat } from '@wiot/shared-domain/models/device-key/device-keys-export-file-format';

export const fetchDeviceKeysAsFile = (
  downloadHash: string,
  fileFormat: DeviceKeyExportFileFormat,
  encryptionKey?: string,
) => async (
  dispatch: Dispatch<any>,
): Promise<void> => {
  dispatch({ type: FETCH_DEVICE_KEYS_AS_FILE } as FetchDeviceKeysAsFileAction);
  const queryString = buildQueryString({ downloadHash, encryptionKey, fileFormat });
  const url = `/exportDeviceKeysAsFile?${ queryString }`;
  try {
    const { data } = await httpClientInstance.get<Blob>(url);
    dispatch({
      type: DEVICE_KEYS_FILE_FETCHED,
      fileContent: data,
      fileFormat: fileFormat,
    } as DeviceKeysFileFetchedAction);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const resetDeviceKeysFile = () => async (
  dispatch: Dispatch<any>,
): Promise<void> => {
  dispatch({ type: RESET_DEVICE_KEYS_FILE } as ResetDeviceKeysFileAction);
};
