import * as React from 'react';
import { useSelector } from 'react-redux';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';

import { AppState } from '../../../state/reducers/rootReducer';
import { getDeviceTypeIconFullPathByStatuses, getIconPathFromNodeType, getTranslationValueInCurrentLanguage, } from '../../../utils/common';
import { DeviceStatus } from '@wiot/shared-domain/models/device/device-status';

export interface NodeLabelIconProps extends LocalizeContextProps {
  nodeId?: string;
  groupType?: string;
  deviceType?: IDeviceType;
  status?: DeviceStatus[];
  onClick: (event: React.MouseEvent<Element>) => void;
}

function NodeLabelIcon( props : NodeLabelIconProps ) {
  const { nodeId, onClick, translate, groupType, deviceType, status } = props;

  const isKeyManagerModeEnabled = useSelector((appState: AppState) => !!appState.siteSettings.isKeyManagerModeEnabled);

  const getGroupType = () => groupType || 'unknown-group-type';
  const groupTypeTranslation = `${ translate(getGroupType()) }`

  const getDeviceType = (): string => {
    let translation;
    if (deviceType?.name) {
      translation = getTranslationValueInCurrentLanguage(deviceType?.name);
    }
    return translation ? translation : `${ translate('unknown-device-type') }`;
  };
  const deviceTypeTranslation = getDeviceType();

  const getDeviceStatus = () => {
    if (!status) {
      return '';
    }
    if (Array.isArray(status) && status.length > 0) {
      return ` (${ translate('status') }: ${ status.map(state => translate(state) || state).join(', ') })`;
    }
    return ` (${ translate('status') }: ${ translate(status) || status })`;
  };

  const getNodeTitle = (): string => {
    if (groupType) {
      return groupTypeTranslation;
    }
    return deviceTypeTranslation;
  }

  const getImgTitle = (): string => {
    if (groupType) {
      return groupTypeTranslation;
    }
    return `${ deviceTypeTranslation }${ getDeviceStatus() }`
  }

  const getIconPath = (): string => {
    if (deviceType) {
      if (isKeyManagerModeEnabled) {
        return getDeviceTypeIconFullPathByStatuses(deviceType, undefined);
      }
      return getDeviceTypeIconFullPathByStatuses(deviceType, status);
    }
    return getIconPathFromNodeType(getGroupType(), status, isKeyManagerModeEnabled);
  };

  const title = getNodeTitle();
  return (
    <div
      onClick={ onClick }
      className="tree__node__label__left"
      title={ title  }
      style={ { backgroundColor: 'whitesmoke' } }
    >
      <img
        alt={ title }
        height="60"
        width="94"
        src={ getIconPath() }
        id={ `${ nodeId }` }
        title={ getImgTitle() }
        data-tip="node_label_tooltip"
        data-for={ `node-label-dev-type-${ nodeId }` }
      />
    </div>
  );
}
export default withLocalize(React.memo(NodeLabelIcon));
