import { MINIMUM_NUMBER_OF_CHARACTERS_TO_START_SEARCH } from '../constants/minimum-number-of-characters-to-start-search';

export const getReasoningMessageForNoOptions = (
  inputValue: string,
  translate,
  noOptionsTranslationId = 'no-options-found',
  isLoading = false
): string => {
  if (!inputValue || inputValue.length < MINIMUM_NUMBER_OF_CHARACTERS_TO_START_SEARCH) {
    return translate(
      'type-to-search-with-minimum-number-of-characters',
      { minimumNumberOfCharacters: MINIMUM_NUMBER_OF_CHARACTERS_TO_START_SEARCH }
    ).toString();
  }
  if (isLoading) {
    return translate('loading').toString();
  }
  return translate(noOptionsTranslationId).toString();
};
