import { DeviceExtended, DevicesState, } from '../types';
import { TOGGLE_SELECT_ALL_DEVICES, TOGGLE_SELECT_DEVICE, } from '../constants/actionTypes';
import { DEVICE_READINGS_FETCHED, DeviceReadingsFetchedAction } from '../device-readings/fetch-device-readings/fetchDeviceReadingsTypes';
import { Device } from '@wiot/shared-domain/models/device/device';
import { DeviceReading } from '@wiot/shared-domain/models/device-reading/device-reading';
import { DEVICES_FETCHED, DevicesActionTypes } from './devicesActions';

const initialState: DevicesState = [];

export const devicesReducer = (
  state: DevicesState = initialState,
  action: DevicesActionTypes,
): DevicesState => {
  switch (action.type) {
    case DEVICES_FETCHED:
      if (action.payload) {
        return action.payload.map((device: DeviceExtended) => ({
          ...device,
          checked: false,
        }));
      }
      return state;

    case TOGGLE_SELECT_DEVICE:
      return state.map((device: DeviceExtended) => {
        if (String(device.id) !== action.payload) {
          return device;
        }
        return {
          ...device,
          checked: !device.checked,
        };
      });

    case TOGGLE_SELECT_ALL_DEVICES:
      return state.map((device: DeviceExtended) => ({
        ...device,
        checked: action.payload,
      }));

  case DEVICE_READINGS_FETCHED:
    return state.map((device: Device) => ({ ...device, lastMessage: getLastDeviceReading(device.id, action) }));

    default:
      return state;
  }
};

export const getLastDeviceReading = (
  idOfDevice: string,
  action: DeviceReadingsFetchedAction,
): DeviceReading | undefined => {
  const latestDeviceReadingPerDevice = action.latestDeviceReadingOfDevices.find(
    lastDeviceReading => lastDeviceReading.idOfDevice === idOfDevice);

  return latestDeviceReadingPerDevice ? latestDeviceReadingPerDevice.deviceReading : undefined;
};
