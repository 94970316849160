import React, { useState } from 'react';
import ModalHeader from '../../../../components/Modal/ModalHeader';
import { getRandomModalOffset } from '../../../../utils/dialog';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import PropertyViewSettings from '../../../Settings/PropertyView/PropertyViewSettings';
import { AppState } from '../../../../state/reducers/rootReducer';
import {
  closePropertySettingsModal,
  updatePropertySettings
} from '../../../../state/property-view/property-settings-modal/propertySettingsModalActionCreators';
import { Col } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { hasPermission } from '../../../../utils/common';
import { PropertySettings } from '@wiot/shared-domain/models/settings/property-settings';

const PropertyViewSettingsModal = () => {
  const [randomOffset] = useState(getRandomModalOffset());
  const [modalUid] = useState(uuidv4());

  const property = useSelector((state: AppState) => state.properties.settingsModal.selectedDeviceGroup);

  const dispatch = useDispatch();

  const userPermission = useSelector<AppState>((state) => state.currentUser.permission);
  const hasUserEditPermission = hasPermission(userPermission, 'propertyView.editPropertySettings');

  function closeModal() {
    dispatch(closePropertySettingsModal());
  }

  function handleSubmit(propertySettings: PropertySettings) {
    if (!property?.id) {
      return;
    }

    dispatch(updatePropertySettings(property?.id, propertySettings));
    closeModal();
  }

  const title = 'property-settings';
  const formTabKey = 'property-view-page'

  return (
    <div
      tabIndex={ 0 } // make it focusable
      style={ randomOffset }
      id={ `${ title }-${ modalUid }` }
      className="device-modal"
    >
      <ModalHeader
        isDevice={ false }
        titleTranslationId={ title }
        titlePostfix={ property?.name }
        targetId={ modalUid }
        handleClose={ closeModal }
        enableFeedbackSubmission={ true }
        getFeedbackAttachment={ () => ({
          propertySettings: property?.propertySettings,
        }) }
      />
      <div className="device-modal__body">
        <Col>
          <PropertyViewSettings
            tabKey={ formTabKey }
            handleSubmit={ handleSubmit }
            initialValues={ property?.propertySettings }
            displayInModal={ true }
            readOnly={ !hasUserEditPermission }
          />

          <div className="form__section last">
            <div className="form__row space-between ai">
              <div className="form__row--left" />
              <div className="form__row--right">
                <button
                  type="button"
                  className="form__button--cancel"
                  onClick={ closeModal }
                >
                  <Translate id="cancel"/>
                </button>
                <button
                  className="form__button--blue background-color-main text-color-white border-color-main"
                  type="submit"
                  form={ `${formTabKey}-settings` }
                >
                  <Translate id='save' />
                </button>
              </div>
            </div>
          </div>
        </Col>
      </div>
    </div>
  );
};

export default PropertyViewSettingsModal;
