import { CurrentPageTitleActionTypes, CurrentPageTitleState } from '../types';
import { SET_CURRENT_PAGETITLE } from '../constants/actionTypes';

const initialState: CurrentPageTitleState = 'undefined';

export const currentPageTitleReducer = (
  state: CurrentPageTitleState = initialState,
  action: CurrentPageTitleActionTypes,
): CurrentPageTitleState => {
  switch (action.type) {
    case SET_CURRENT_PAGETITLE:
      return action.payload;

    default:
      return state;
  }
};
