import { faArrowLeft, faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';

interface SettingsToggleProps {
  handleClick: () => void;
  isActive: boolean;
}

const SettingsToggle = ({ handleClick, isActive }: SettingsToggleProps) => {
  const getContent = () => {
    if (isActive)
      return (
        <>
          <FontAwesomeIcon icon={faArrowLeft} /> <Translate id="back" />
        </>
      );
    return (
      <>
        <FontAwesomeIcon icon={faCog} /> <Translate id="extended-settings" />
      </>
    );
  };

  return (
    <Button
      onClick={handleClick}
      className="header__button w-100"
      data-testid="user-settings-advanced-settings-toggle"
    >
      {getContent()}
    </Button>
  );
};

export default SettingsToggle;
