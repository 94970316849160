import React from 'react';
import { Translate } from 'react-localize-redux';
import { UserRole } from '@wiot/shared-domain/models/user/user-role';

interface UserRolesTableProps {
  userRoles?: UserRole[];
}

const UserRolesTable = ({ userRoles }: UserRolesTableProps): JSX.Element =>  (
    <table className="device-manager__table__table">
      <thead>
        <tr className="details__headings--table">
          <th>
            <Translate id="role" />
          </th>
          <th>
            <Translate id="device-group" />
          </th>
        </tr>
      </thead>
      <tbody>
        {userRoles?.map((role) => (
          <tr className="details__text details__text--messages" key={role.id}>
            <td style={{ maxWidth: '205px', paddingLeft: '2px' }}>{role.role?.name || ''}</td>
            <td style={{ maxWidth: '205px', paddingLeft: '2px' }}>{role.deviceGroup?.name || ''}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

export default UserRolesTable;
