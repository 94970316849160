import { Dispatch } from 'redux';
import { ProtectionDetails } from '@wiot/shared-domain/models/device-key/protection-details';
import { FETCH_DEVICE_KEY_PROTECTION_INFO, } from '../constants/actionTypes';
import { DeviceKeyProtectionDetailsFetchedAction, DownloadHashNotFoundAction, FetchDeviceKeyProtectionAction, } from '../types';
import { getDeviceKeyProtectionDetails } from '../../api/deviceKeyService';
import { fetchDeviceKeyDownloadDetails } from './deviceKeysActionCreators';
import { DEVICE_KEY_PROTECTION_INFO_FETCHED, DOWNLOAD_HASH_NOT_FOUND } from './deviceKeyActionTypes';

export const deviceKeyProtectionDetailsFetched = (
  deviceKeyProtectionDetails: ProtectionDetails,
): DeviceKeyProtectionDetailsFetchedAction => ({
  type: DEVICE_KEY_PROTECTION_INFO_FETCHED,
  protectionDetails: deviceKeyProtectionDetails,
});

export const downloadHashNotFound = (downloadHash: string): DownloadHashNotFoundAction => ({
  type: DOWNLOAD_HASH_NOT_FOUND,
  downloadHash,
});

export const fetchDeviceKeyProtection = (downloadHash: string) => async (
  dispatch: Dispatch<any>,
): Promise<void> => {
  dispatch({
    type: FETCH_DEVICE_KEY_PROTECTION_INFO,
    downloadHash,
  } as FetchDeviceKeyProtectionAction);
  const protectionDetails = await getDeviceKeyProtectionDetails(downloadHash);

  handleProtectionDetailsResponse(downloadHash, dispatch, protectionDetails);
};

export const handleProtectionDetailsResponse = (
  downloadHash: string,
  dispatch: Dispatch<any>,
  protectionDetails: ProtectionDetails | null,
) => {
  if (!protectionDetails) {
    dispatch(downloadHashNotFound(downloadHash));
  } else {
    dispatch(downloadHashNotFound(''));
    dispatch(deviceKeyProtectionDetailsFetched(protectionDetails));
    if (protectionDetails.hasCurrentUserAccessToKey) {
      dispatch(fetchDeviceKeyDownloadDetails(downloadHash));
    }
  }
};
