import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { Device, DeviceCreation } from '@wiot/shared-domain/models/device/device';
import { getTranslationValueInCurrentLanguage } from '../../../../utils/common';
import RenderOnCondition from '../../../../components/RenderOnCondition';

interface DeviceImportPreviewProps {
  devices: Device[] | DeviceCreation[];
}

function DeviceImportPreview({ devices }: DeviceImportPreviewProps) {
  return (
    <div className="mb-3">
      <Row className="form__title">
        <Col>
          <Translate id="preview-devices-count" data={{ count: devices.length }} />
        </Col>
      </Row>
      {devices
        .sort((a, b) => Number.parseInt(a.deviceId!, 10) - Number.parseInt(b.deviceId!, 10))
        .map((device, index) => {
          const { deviceType, deviceId, name, } = device;
          const deviceTypeName = deviceType?.name && getTranslationValueInCurrentLanguage(deviceType.name);
          const manufacturerFlagId = device.manufacturer?.flagId;

          return (
            <Row className="form__label" key={`preview-devices-count-${index}`}>
              <Col>
                {`${index + 1}. ${manufacturerFlagId} ${deviceId} ${deviceTypeName}`}
                <RenderOnCondition condition={name}>
                  <>: {name}</>
                </RenderOnCondition>
              </Col>
            </Row>
          );
        })}
    </div>
  );
}

export default DeviceImportPreview;
