export const LOGIN_PATH = "/login";
export const RESET_PASSWORD_PATH = "/reset-password";
export const SET_PASSWORD_PATH = "/set-password";
export const DEVICE_KEY_PATH = "/device-key";
export const VALIDATE_USER_PATH = "/validate-user";
export const CONTACT_PATH = "/contact";
export const IMPRINT_PATH = "/imprint";
export const PRIVACY_PATH = "/privacy";
export const DEVICE_HIERARCHY_PATH = '/device-hierarchy';
export const PROPERTY_VIEW_PATH = '/property-view';
export const ROLES_PATH = "/roles";
export const USER_MANAGEMENT_PATH = "/user-management";
export const DATA_INTEGRATION_PATH = "/data-integration";
export const SETTINGS_PATH = "/settings";
export const DASHBOARD_PATH = '/dashboard';
export const DEVICE_MANAGER_PATH = '/device-manager';
export const DEVICE_MESSAGES_PATH = "/device-messages";
export const MANAGE_DEVICE_TYPES_PATH = "/manage-device-types";
export const DEVICE_GROUPS_PATH = "/device-groups";
export const KEYS_PATH = "/key-management";
