import { Device } from '@wiot/shared-domain/models/device/device';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { DeviceReading } from '@wiot/shared-domain/models/device-reading/device-reading';
import { IUser } from '@wiot/shared-domain/models/user/user';
import { IRole } from '@wiot/shared-domain/models/role/role';
import { RadioKey } from '@wiot/shared-domain/models/radio-key/radio-key';
import { DataIntegrationJob } from '@wiot/shared-domain/models/data-integration/data-integration';
import { UserRole } from '@wiot/shared-domain/models/user/user-role';
import { DeviceList } from '@wiot/shared-domain/models/device/device-list';


const filterDeviceGroup = (deviceGroup: any) => {
  if (!deviceGroup) {
    return undefined;
  }
  if (typeof deviceGroup === 'string') {
    return deviceGroup;
  }
  return filterOutSensitiveDataFromDeviceGroup(deviceGroup);
};

export const filterOutSensitiveDataFromDevice = (device: Device): Device => ({
  id: device.id,
  deviceId: device.deviceId,
  dinAddress: device.dinAddress,
  deviceGroup: filterDeviceGroup(device.deviceGroup),
  deviceGroupAncestors: device.deviceGroupAncestors,
  deviceType: device.deviceType,
  name: device.name,
  manufacturer: device.manufacturer,
  createdAt: device.createdAt,
  updatedAt: device.updatedAt,
  blacklisted: device.blacklisted,

  states: device.states,
  status: device.status,
  gateways: device.gateways ? filterOutSensitiveDataFromDevices(device.gateways) : undefined,

  protocolType: device.protocolType,
  deviceMetadata: device.deviceMetadata,
  isImported: device.isImported,
  isDeprecated: device.isDeprecated,
  isToBeDeprecated: device.isToBeDeprecated,
  oldDevice: device.oldDevice,
  newDevice: device.newDevice,
  notes: device.notes,
  dataIntegrationJobID: device.dataIntegrationJobID,
  lastMessage: device.lastMessage,
  lastMessages: device.lastMessages,
  lastMessageFromSources: device.lastMessageFromSources,
  syncState: device.syncState,
  version: device.version,
  historicalDeviceTypeProfileDeviceReadingValues: device.historicalDeviceTypeProfileDeviceReadingValues,
  communicationOptions: {
    lora: device.communicationOptions?.lora,
  }
});

export const filterOutSensitiveDataFromDevices = (devices: Device[]): Device[] =>
  devices.map((device: Device) => filterOutSensitiveDataFromDevice(device));

export const filterOutSensitiveDataFromDeviceList = (deviceList: DeviceList): DeviceList => ({
  devices: deviceList.devices.map((device: Device) => filterOutSensitiveDataFromDevice(device)),
  totalDevices: deviceList.totalDevices,
  countOfDevicesIsLimited: deviceList.countOfDevicesIsLimited,
});

export const filterOutSensitiveDataFromDeviceReading = (deviceReading: DeviceReading): DeviceReading => ({
  id: deviceReading.id,
  receivedAt: deviceReading.id,
  device: filterOutSensitiveDataFromDevice(deviceReading.device),
  values: deviceReading.values,
  gateway: deviceReading.gateway
    ? () => filterOutSensitiveDataFromDevices(deviceReading.gateway)
    : () => undefined,
  source: deviceReading.source,
  attributes: deviceReading.attributes,
  mainDescription: deviceReading.mainDescription,
  isImported: deviceReading.isImported,
  raw: deviceReading.raw,
  statusByte: deviceReading.statusByte,
});

export const filterOutSensitiveDataFromDeviceGroup = (deviceGroup: DeviceGroup): DeviceGroup => ({
  id: deviceGroup.id,
  externalId: deviceGroup.externalId,
  name: deviceGroup.name,
  type: deviceGroup.type,
  metadata: deviceGroup.metadata,
  parentId: deviceGroup.parentId,
  parent: deviceGroup.parent ? filterOutSensitiveDataFromDeviceGroup(deviceGroup.parent) : undefined,
  deviceList: deviceGroup.deviceList ? filterOutSensitiveDataFromDeviceList(deviceGroup.deviceList) : undefined,
  users: deviceGroup.users ? filterOutSensitiveDataFromUsers(deviceGroup.users) : undefined,
  hasPermission: deviceGroup.hasPermission,
  notes: deviceGroup.notes,
  isImported: deviceGroup.isImported,
  dataIntegrationJobID: deviceGroup.dataIntegrationJobID,
  abbreviation: deviceGroup.abbreviation,
  syncState: deviceGroup.syncState,
  systemName: deviceGroup.systemName,
});

export const filterOutSensitiveDataFromDeviceGroups = (deviceGroups: DeviceGroup[]): DeviceGroup[] =>
  deviceGroups.map(deviceGroup => deviceGroup ? filterOutSensitiveDataFromDeviceGroup(deviceGroup) : {});

export const filterOutSensitiveDataFromUserRole = (userRole: UserRole): UserRole => ({
  id: userRole.id,
  deviceGroup: filterOutSensitiveDataFromDeviceGroup(userRole.deviceGroup),
  permittedDeviceReadingAccessPeriods: userRole.permittedDeviceReadingAccessPeriods,
  role: filterOutSensitiveDataFromRole(userRole.role),
});

export const filterOutSensitiveDataFromUserRoles = (userRoles: UserRole[]): UserRole[] =>
  userRoles.map((userRole: UserRole) => filterOutSensitiveDataFromUserRole(userRole));

export const filterOutSensitiveDataFromUser = (user: IUser): IUser => ({
  id: user.id,
  validated: user.validated,
  isAdmin: user.isAdmin,
  isImported: user.isImported,
  isAnonymous: user.isAnonymous,
  dataIntegrationJobID: user.dataIntegrationJobID,
  settings: user.settings,
  roles: filterOutSensitiveDataFromUserRoles(user.roles as UserRole[])
});

export const filterOutSensitiveDataFromUsers = (users: IUser[]): IUser[] => {
  return users.map((user: IUser) => filterOutSensitiveDataFromUser(user));
};

export const filterOutSensitiveDataFromRole = (role: IRole): IRole => ({
  id: role.id,
  name: role.name,
  systemName: role.systemName,
  superAdmin: role.superAdmin,
  deviceGroups: role.deviceGroups,
  deviceTypes: role.deviceTypes,
  deviceManager: role.deviceManager,
  deviceKeyDownload: role.deviceKeyDownload,
  keys: role.keys,
  users: role.users,
  roles: role.roles,
  dataIntegration: role.dataIntegration,
  profile: role.profile,
  settings: role.settings,

  permittedRoles: role.permittedRoles,
  activeAccounts: role.activeAccounts,
});

export const filterOutSensitiveDataFromRoles = (roles: IRole[]): IRole[] =>
  roles.map((role: IRole) => filterOutSensitiveDataFromRole(role));

export const filterOutSensitiveDataFromRadioKey = (key: RadioKey): RadioKey => ({
  id: key.id,
  name: key.name,
  deviceGroup: key.deviceGroup,
  createdAt: key.createdAt,
  updatedAt: key.updatedAt,
  syncState: key.syncState,
  isImported: key.isImported,
  dataIntegrationJobID: key.dataIntegrationJobID,
});

export const filterOutSensitiveDataFromRadioKeys = (keys: RadioKey[]): RadioKey[] =>
  keys.map((key: RadioKey) => filterOutSensitiveDataFromRadioKey(key));

export const filterOutSensitiveDataFromDataIntegrationJob = (job: DataIntegrationJob): DataIntegrationJob => ({
  id: job.id,
  userId: job.userId,
  apiPath: job.apiPath,
  authMethod: job.authMethod,
  pullFrequency: job.pullFrequency,
  active: job.active,
  class: job.class,
  json: job.json,
  createdAt: job.createdAt,
  updatedAt: job.updatedAt,
  executed: job.executed,
  deviceGroup: job.deviceGroup ? filterOutSensitiveDataFromDeviceGroup(job.deviceGroup) : undefined,
});

export const filterOutSensitiveDataFromDataIntegrationJobs = (jobs: DataIntegrationJob[]): DataIntegrationJob[] =>
  jobs.map((job: DataIntegrationJob) => filterOutSensitiveDataFromDataIntegrationJob(job));
