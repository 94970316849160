import { toastr } from "react-redux-toastr";
import { TranslateFunction } from 'react-localize-redux';
import { RadioKey } from "@wiot/shared-domain/models/radio-key/radio-key";
import OnDependencyDeleteToastr, {TOAST_VISIBILITY_DURATION} from "./OnDependencyDeleteToastr";
import React from "react";

function showDeleteToastrOnConflictingRadioKeys(
  translate: TranslateFunction,
  conflictingRadioKeys: RadioKey[],
){
  for (var key of conflictingRadioKeys) {
    if (key.deviceGroup && ( key.name || key.key)) {
      const { name: dgName } = key.deviceGroup;
      const { name: keyName } = key;
      const toastrOptions = {
        timeOut: TOAST_VISIBILITY_DURATION,
        preventDuplicates: false,
        component: (
          <OnDependencyDeleteToastr
            dgName={ dgName }
            keyName={ keyName }
            type={translate('key').toString()}
          />
        ),
      };
      toastr.error(
        translate('ERROR').toString(),
        translate('delete-failed-dependency-found').toString(),
        toastrOptions,
      );
    }
  }
};

export default showDeleteToastrOnConflictingRadioKeys;
