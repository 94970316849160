import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Badge from 'react-bootstrap/Badge';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import { IRole } from '@wiot/shared-domain/models/role/role';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import RenderOnCondition from '../../../components/RenderOnCondition';
import ConfirmModal, { ConfirmationVariant } from '../../../components/Modal/ConfirmModal';
import UserActionModal from '../UserActionModal/UserActionModal';
import UserDetailsModal from '../UserDetailsModal';
import CustomCheckbox from '../../../components/shared/CustomCheckbox';
import { PortalMenu } from '../../../components/PortalMenu/PortalMenu';
import TableMenu from './TableMenu';
import {
  VERTICAL_OFFSET_TABLE_VIEW,
  HORIZONTAL_OFFSET_TABLE_VIEW
} from '../../../components/PortalMenu/constants/offset-table-view';
import { AppState } from '../../../state/reducers/rootReducer';
import { getMenuId } from '../../../components/PortalMenu/constants/context-menu-ids';
import { closeContextMenu, openContextMenu } from '../../../state/context-menu/toggleContextMenuActionCreators';
import { Tooltip } from '../../../components/shared/Tooltip';
import { localizeDate } from '../../../utils/common';
import useTranslation from '../../../hooks/useTranslation';

export interface TableDataRowProps {
  disableControls?: boolean;
  disabledControlsTooltipTranslationTerm?: string;
  refreshData: (column?: IColumnObject) => void;
  user: any;
  selectOne: (id: string) => void;
  removeUnit: (id: string) => Promise<void>;
}

const TableDataRow = (props: TableDataRowProps): JSX.Element => {
  const { user, disableControls, disabledControlsTooltipTranslationTerm } = props;
  const { id, displayName, email, validated, checked, roles, createdAt } = user;
  let toggleMenuButton: HTMLDivElement | null;

  const [toggleMenuButtonBoundingClientRect, setToggleMenuButtonBoundingClientRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUserActionModal, setShowUserActionModal] = useState(false);
  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);

  const visibleContextMenu = useSelector((state: AppState) => state.contextMenu.visibleContextMenuId);

  const translate = useTranslation();
  const dispatch = useDispatch();

  const toggleUserActionModal = () => {
    setShowUserActionModal((prevState) => !prevState);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prevState) => !prevState);
  };

  const toggleUserDetailsModal = () => {
    setShowUserDetailsModal((prevState) => !prevState);
  };

  const menuId = getMenuId(id);
  const showMenu = (visibleContextMenu === menuId);
  const toggleMenu = () => {
    if (disableControls) {
      return;
    }
    if (!showMenu) {
      dispatch(openContextMenu(menuId));
    } else {
      dispatch(closeContextMenu());
    }
    if (toggleMenuButton) {
      setToggleMenuButtonBoundingClientRect(toggleMenuButton.getBoundingClientRect());
    }
  };

  const getTableActionsButtonClassNames = (): string => {
    let classNames = 'ellipsis text-color-main';

    if (disableControls) {
      classNames += ' disabled';
    }

    return classNames;
  };

  const isMultiBadge = roles.length > 1;
  const badgeLabel = isMultiBadge
    ? `${ roles.length } ${ translate('roles') }`
    : `${ roles[0].role.name }`;

  const tooltipId = `user-row-role-cell-tooltip-id-${ id }`
  return (
    <tr key={id} id={id} className="device-manager__table__table__trow">
      <td>
        <CustomCheckbox onChange={() => props.selectOne(id)} checked={checked} />
      </td>
      <td
        className="device-manager__table__table__td device-manager__table__table__link"
        onClick={toggleUserDetailsModal}
      >
        {displayName || '-'}
      </td>
      <td
        className="device-manager__table__table__td device-manager__table__table__link"
        onClick={toggleUserDetailsModal}
      >
        {email || '-'}
      </td>
      <td>
        {validated ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faCircle} />}
      </td>
      <td className="pil-container">
        <div className="container-fluid m-0 p-0">
          <span
            className="flex-grow-0 flex-shrink-1 m-0 p-0"
            onClick={toggleUserDetailsModal}
          >
            <Badge
              data-tip="pil"
              data-for={tooltipId}
              className={classNames(
                'pil',
                'pil-long',
                {'background-color-main-transparent': isMultiBadge},
                {'background-color-main': !isMultiBadge},
              )}
            >
              <span className="pil-long-content">
                {badgeLabel}
              </span>
            </Badge>
          </span>
          <Tooltip
            id={tooltipId}
            className="device-manager__table__table__trow__pil__tooltip"
          >
            {roles.map((value) => {
              const tooltipLabel = isMultiBadge
                ? `${value.role.name} | ${value.deviceGroup.name}`
                : value.deviceGroup.name;
              return (
                <div
                  className="mt-1 mb-1"
                  key={`key_-_${tooltipId}`}
                >
                  {tooltipLabel}
                </div>
              );
            })}
          </Tooltip>
        </div>
      </td>
      <td>{ createdAt ? localizeDate(createdAt) : '' }</td>
      <td className="no-overflow">
        <div className="device-details">
          <div
            className={getTableActionsButtonClassNames()}
            role="presentation"
            ref={(tc) => {
              toggleMenuButton = tc;
            }}
            onClick={toggleMenu}
            data-tip="table-actions-button-tooltip"
            data-for="table-actions-button-tooltip"
          >
            <FontAwesomeIcon icon={faCog} />
          </div>
          <Tooltip id="table-actions-button-tooltip">
            <Translate id={
              disableControls
                ? disabledControlsTooltipTranslationTerm || 'disabled'
                : "actions"}
            />
          </Tooltip>
          { showMenu && (
            <PortalMenu
              anchorPosition={{
                left: toggleMenuButtonBoundingClientRect.left + HORIZONTAL_OFFSET_TABLE_VIEW,
                top: toggleMenuButtonBoundingClientRect.top + window.scrollY + VERTICAL_OFFSET_TABLE_VIEW
              }}
              minWidth={ 160 }
            >
              <TableMenu
                user={ props.user }
                removeUnit={ (itemId: string) => props.removeUnit(itemId) }
                menuId={ menuId }
                toggleUserActionModal={ toggleUserActionModal }
                toggleDeleteModal={ toggleDeleteModal }
                toggleUserDetailsModal={ toggleUserDetailsModal }
              />
            </PortalMenu>
          ) }
          { showUserActionModal && (
            <UserActionModal
              saved={() => {
                props.refreshData();
                toggleUserActionModal();
              }}
              cancelled={toggleUserActionModal}
              isInCreationMode={false}
              title="update-user"
              showDeleteButton
              userId={id}
              removeUnit={(itemId: string) => props.removeUnit(itemId)}
            />
          ) }
          <RenderOnCondition condition={showUserDetailsModal}>
            <UserDetailsModal closeModal={toggleUserDetailsModal} id={id} />
          </RenderOnCondition>

          <RenderOnCondition condition={showDeleteModal}>
            <ConfirmModal
              modalCloseRequested={() => toggleDeleteModal()}
              actionConfirmed={() => props.removeUnit(id)}
              translationIdOfElementType="user"
              confirmationVariant={ ConfirmationVariant.DELETE }
            />
          </RenderOnCondition>
        </div>
      </td>
    </tr>
  );
};

export default TableDataRow;
