import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { Translate } from 'react-localize-redux';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import ModalHeader from '../../components/Modal/ModalHeader';
import { getDeviceTypeIconFullPath, getTranslationValueInCurrentLanguage, } from '../../utils/common';
import RenderOnCondition from '../../components/RenderOnCondition';
import { LabeledCol } from '../../components/shared/LabeledCol';
import { fetchDeviceTypeFromDB } from '../../api/apiHelpers';
import LoadingIcon from '../../components/shared/LoadingIcon';
import { getRandomModalOffset } from '../../utils/dialog';
import {FeedbackAttachment} from "../../components/Feedback/feedback";

export interface DeviceGroupDetailsProps {
  closeModal: (event: React.MouseEvent | KeyboardEvent) => void;
  deviceTypeData: IDeviceType;
}

const DeviceGroupDetails = (props: DeviceGroupDetailsProps) => {
  const [deviceType, setDeviceType] = useState<IDeviceType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [randomOffset] = useState(getRandomModalOffset());
  const [modalUid] = useState(uuidv4());

  const { id, name } = deviceType || props.deviceTypeData;

  useEffect(() => {
    const fetchDeviceType = async () => {
      const deviceTypeFromDB = await fetchDeviceTypeFromDB(id);
      if (deviceTypeFromDB) {
        setDeviceType(deviceTypeFromDB);
      }
    };
    fetchDeviceType().then(() => {
      setIsLoading(false);
    });
  }, [id]);

  const handleClose = (event: React.MouseEvent | KeyboardEvent) => {
    props.closeModal(event);
  };

  const getModalBody = () => {
    if (isLoading) {
      return <LoadingIcon />;
    }

    return (
      <>
        <div className="details__section">
          <Row className="details__row">
            <Col lg={4} className="text-center">
              <img
                src={getDeviceTypeIconFullPath(deviceType)}
                className="thumbnail"
                alt="device type"
              />
            </Col>
            <Col lg={8}>
              <Row>
                <LabeledCol
                  labelTranslationId="device-type-name"
                  content= { deviceType && deviceType.name
                    ? getTranslationValueInCurrentLanguage(deviceType.name)
                    : '-' }
                />
              </Row>
              <RenderOnCondition condition={ (deviceType && deviceType.mBusDeviceTypeId)? true : false }>
                <Row>
                  <LabeledCol
                    labelTranslationId="mbus-device-type-identifier"
                    content={ deviceType?.mBusDeviceTypeId || '-' }
                  />
                </Row>
              </RenderOnCondition>
              <Row>
                <Col>
                  <label className="details__label">
                    <Translate id="system-defined-type" />
                    <Translate>
                      {({ translate }) => (
                        <p className="details__text">{translate(String(!!deviceType?.isSystem))}</p>
                      )}
                    </Translate>
                  </label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="details__label">
                    <Translate id="is-active" />
                    <Translate>
                      {({ translate }) => (
                        <p className="details__text">{translate(String(!!deviceType?.isActive))}</p>
                      )}
                    </Translate>
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const getAttachmentForFeedback = (): FeedbackAttachment | null => {
    if (!deviceType) {
      return null;
    }
    return {
      deviceType: deviceType,
    };
  };

  return (
    <div
      tabIndex={0} // make it focusable
      style={randomOffset}
      className="device-modal"
      id={`details-of-group-${modalUid}`}
      onClick={(event: React.MouseEvent) => event.stopPropagation()}
    >
      <ModalHeader
        isDevice={false}
        titleTranslationId="details-of-group"
        targetId={modalUid}
        titlePostfix={getTranslationValueInCurrentLanguage(name)}
        handleClose={handleClose}
        enableFeedbackSubmission={true}
        getFeedbackAttachment={getAttachmentForFeedback}
      />
      <div className="device-modal__body details">{getModalBody()}</div>
    </div>
  );
};

export default DeviceGroupDetails;
