import { toastr, toastType } from 'react-redux-toastr';
import HttpStatus from 'http-status-codes';
import axios, { AxiosError, AxiosRequestConfig, Canceler } from 'axios';
import { ColorResult } from 'react-color';
import { DeviceTypeWithPermission, IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import { Device, DeviceCreation, Devices, } from '@wiot/shared-domain/models/device/device';
import { DataIntegrationJob } from '@wiot/shared-domain/models/data-integration/data-integration';
import { ISettings } from '@wiot/shared-domain/models/settings/settings';
import { DeviceReading, MEASURE_UNITS } from '@wiot/shared-domain/models/device-reading/device-reading';
import { IDeviceTypeProfile } from '@wiot/shared-domain/models/device-type-profile/device-type-profile';
import { IUser, Users } from '@wiot/shared-domain/models/user/user';
import { Manufacturer } from '@wiot/shared-domain/models/manufacturer/manufacturer';
import { RegisterUserRequest, UpdateUserRequest, } from '@wiot/shared-domain/models/user/user-api';
import { IRole } from '@wiot/shared-domain/models/role/role';
import { UserCredentials } from '@wiot/shared-domain/models/auth/auth-api';
import {
  IDataIntegrationFilter,
  IKeysFilter,
  IRolesFilter,
  IUserManagementFilter,
} from '../state/reducers/filterSortReducer';
import { DeviceTypeCache } from './deviceTypeCache';
import { IGeoDevice } from '../pages/DeviceManager/Map/geo-device';
import {
  addDeviceOffline,
  addKeyOffline,
  fetchDeviceGatewaysOffline,
  fetchDeviceOffline,
  fetchDevicesOffline,
  fetchDeviceTypeProfileOffline,
  fetchKeyOffline,
  fetchKeysOffline,
  fetchManufacturersOffline,
  fetchMessagesForDeviceOffline,
  removeDeviceGroupOffline,
  removeDeviceOffline,
  removeKeyOffline,
  updateDeviceOffline,
  updateKeyOffline,
} from './offline';
import api from './api';
import { buildQueryString, getDateRangeString, isOffline } from '../utils/common';
import { FetchOptions } from '../state/types';
import { clearLoginData } from '../state/actions/logoutActionCreator';
import { redirectToPath } from '../utils/windowLocation';
import { LOGIN_PATH } from '../navigation/paths';
import { DeviceGroupType } from '@wiot/shared-domain/models/device-group-types/device-group-types';
import { ApiCallError } from '../errors/api-call-error';
import { getDeviceManagersFilterString } from '../components/Filter/get-device-managers-filter-string';
import { getGatewayFilterString } from '../components/Filter/get-gateway-filter-string';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { DeviceManagerFilter } from '../state/device/device-manager-filter';
import { DeviceMessageFilter } from '../state/device-readings/device-message-filter';

const { CancelToken } = axios;

export const authenticatedInstance = axios.create({
  baseURL: api.baseAPIUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export const isTokenIssue = (error: AxiosError) => {
  const tokenIssueResponses = [ 'Token is missing', 'Token is invalid' ];
  const isTokenIssue =
    error.response?.status === 401 &&
    tokenIssueResponses.includes(error.response.data);

  return isTokenIssue;
};

export const handleApiError = (
  error: AxiosError,
  showToaster = true,
  toasterVariant: toastType = 'error',
): void => {
  if (axios.isCancel(error)) {
    return;
  }

  if (isTokenIssue(error)) {
    clearLoginData();
    redirectToPath(LOGIN_PATH);
  }

  if (!showToaster) {
    return;
  }

  let errorMessage: string;
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    errorMessage = `${ error.response.data }`;
    if (error.response?.status === HttpStatus.REQUEST_HEADER_FIELDS_TOO_LARGE) {
      errorMessage = 'The size of the request headers for are too large';
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    errorMessage = `No Response received: ${ error.request.responseText }`;
  } else {
    // Something happened in setting up the request that triggered an Error
    errorMessage = `Request failed: ${ error.message }`;
  }

  switch (toasterVariant) {
    case 'info':
      toastr.info(`Request Failed ${error.name}`, errorMessage);
      break;
    case 'error':
    default:
      toastr.error(`Request Failed ${error.name}`, errorMessage);
      break;
  }
};

let cancelDeviceRequest: Canceler;

/* devices */
export const FETCH_CANCELLED_BY_NEW_FETCH ='FETCH_CANCELLED_BY_NEW_FETCH';
export const fetchDevicesFromDB = async (
  fetchOptions: FetchOptions<DeviceManagerFilter>,
  requestCancelerMessage = FETCH_CANCELLED_BY_NEW_FETCH,
): Promise<Devices> => {
  if (isOffline()) {
    return fetchDevicesOffline(fetchOptions);
  }

  const { page, pageSize, filters: filter, sort } = fetchOptions;

  try {
    if (cancelDeviceRequest) {
      cancelDeviceRequest(requestCancelerMessage);
    }
    const qs = buildQueryString({
      page,
      pageSize,
      filter: getDeviceManagersFilterString(filter),
      sort,
      gateways: getGatewayFilterString(filter),
    });
    const url = `${ api.devices }?${ qs }`;

    const { data } = await authenticatedInstance.get(url, {
      cancelToken: new CancelToken((c) => {
        // An executor function receives a cancel function as a parameter
        cancelDeviceRequest = c;
      }),
    });
    return data;
  } catch (err) {
    handleApiError(err, true);
    throw err;
  }
};

export const fetchDeviceFromDB = async (id: string, showError = true) => {
  if (isOffline()) {
    return fetchDeviceOffline(id);
  }
  try {
    const url = `${ api.devices }/${ id }`;
    const { data } = await authenticatedInstance.get(url);
    return data;
  } catch (error) {
    handleApiError(error, showError);
    throw error;
  }
};

export const addDeviceToDB = async (device: DeviceCreation):Promise<any> => {
  if (isOffline()) {
    return addDeviceOffline(device);
  }
  try {
    const { data } = await authenticatedInstance.post(api.devices, [ { device } ]);
    const error = data.errorsByIds[device.deviceId!];
    if (error) {
      throw new ApiCallError(error.name, error.message, error.statusCode);
    }

    return {
      device: data.addedDevicesByDeviceIds[device.deviceId!],
    };
  } catch (error) {
    handleApiError(error);
  }
};

export const updateDeviceInDB = async (device: Partial<Device>) => {
  if (isOffline()) {
    return updateDeviceOffline(device);
  }
  try {
    const { data } = await authenticatedInstance.patch(api.devices, device);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};


// <editor-fold desc="Device Type Handling...">

export const fetchDeviceTypesFromDB = async (): Promise<IDeviceType[]> => {
  if (isOffline()) {
    return DeviceTypeCache.getCachedDeviceTypes();
  }

  try {
    const url = `${ api.deviceTypes }`;
    const { data: deviceTypes } = await authenticatedInstance.get<IDeviceType[]>(url);
    return deviceTypes;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const fetchDeviceTypeFromDB = async (deviceTypeId: string): Promise<IDeviceType | null> => {
  if (isOffline()) {
    return DeviceTypeCache.getCachedDeviceType(deviceTypeId);
  }

  try {
    const url = `${ api.deviceTypes }/${ encodeURIComponent(deviceTypeId) }`;
    const { data: deviceType } = await authenticatedInstance.get<IDeviceType>(url);
    return deviceType || null;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const validateDeviceTypeName = async (deviceTypeName: string): Promise<boolean> => {
  try {
    const { data: isNameValid } = await authenticatedInstance.get<boolean>(
      `${ api.deviceTypes }/validateName/${ encodeURIComponent(deviceTypeName) }`,
    );

    return isNameValid;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const addDeviceTypesToDB = async (deviceTypes: IDeviceType[]): Promise<IDeviceType[]> => {
  if (deviceTypes.length === 0) {
    return [];
  }

  if (isOffline()) {
    await DeviceTypeCache.cacheTemporaryAddedDeviceTypes(deviceTypes);
    return deviceTypes;
  }

  try {
    const { data: addedDeviceTypes } = await authenticatedInstance.post<IDeviceType[]>(
      api.deviceTypes,
      deviceTypes,
    );

    return addedDeviceTypes;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const addDeviceTypeToDB = async (deviceType: IDeviceType): Promise<IDeviceType> => {
  const addedDeviceTypes = await addDeviceTypesToDB([ deviceType ]);

  if (!addedDeviceTypes[0]) {
    throw new Error('add-device-type-error');
  }

  return addedDeviceTypes[0];
};

export const updateDeviceTypesInDB = async (deviceTypes: IDeviceType[]): Promise<IDeviceType[]> => {
  if (deviceTypes.length === 0) {
    return [];
  }

  if (isOffline()) {
    await DeviceTypeCache.cacheTemporaryDeviceTypeChanges(deviceTypes);
    return deviceTypes;
  }

  try {
    const { data: updatedDeviceTypes } = await authenticatedInstance.patch<IDeviceType[]>(
      api.deviceTypes,
      deviceTypes,
    );

    return updatedDeviceTypes;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const updateDeviceTypeInDB = async (deviceType: IDeviceType): Promise<IDeviceType> => {
  const updatedDeviceTypes = await updateDeviceTypesInDB([ deviceType ]);

  if (!updatedDeviceTypes[0]) {
    throw new Error('edit-device-type-error');
  }

  return updatedDeviceTypes[0];
};

export const removeDeviceTypeFromDB = async (deviceType: IDeviceType): Promise<string> => {
  if (isOffline()) {
    const removedDeviceType = await DeviceTypeCache.cacheTemporaryDeviceTypeRemoval(deviceType);
    return removedDeviceType.id;
  }

  try {
    const url = `${ api.deviceTypes }/${ deviceType.id }`;
    const { data: deletedDeviceTypeId } = await authenticatedInstance.delete<string>(url);
    return deletedDeviceTypeId;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const fetchDeviceTypesForDeviceGroupFromDB = async (
  deviceGroupId: string,
): Promise<DeviceTypeWithPermission[]> => {
  if (isOffline()) {
    return (await DeviceTypeCache.getCachedDeviceTypes()) as DeviceTypeWithPermission[];
  }

  try {
    const url = `${ api.deviceGroups }/${ encodeURIComponent(deviceGroupId) }/deviceTypes`;
    const { data: deviceTypes } = await authenticatedInstance.get<DeviceTypeWithPermission[]>(url);
    return deviceTypes;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// </editor-fold>

export const fetchManufacturersFromDB = async (): Promise<Manufacturer[]  | undefined | void> => {
  if (isOffline()) {
    return fetchManufacturersOffline();
  }
  try {
    const url = `${ api.manufacturers }`;
    const { data } = await authenticatedInstance.get(url);
    return data.manufacturers;
  } catch (error) {
    return handleApiError(error);
  }
};

export const removeDeviceFromDB = async (id: string) => {
  if (isOffline()) {
    return removeDeviceOffline(id);
  }
  try {
    const url = `${ api.devices }/${ id }`;
    const { data } = await authenticatedInstance.delete(url);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const fetchDeviceGatewaysFromDB = async (fetchOptions: FetchOptions<string>) => {
  if (isOffline()) {
    return fetchDeviceGatewaysOffline(fetchOptions);
  }
  try {
    const { page, pageSize, filters: filter, sort } = fetchOptions;
    const qs = buildQueryString({
      page,
      pageSize,
      filter,
      sort,
    });
    const url = `${ api.gateways }?${ qs }`;
    const { data } = await authenticatedInstance.get(url);
    return data;
  } catch (error) {
    handleApiError(error);
    throw error
  }
};

export const fetchGeoDevices = async (fetchOptions: FetchOptions<DeviceManagerFilter>) => {
  const data = await fetchDevicesFromDB(fetchOptions);
  const devices: Device[] = data.devices || [];
  const geoDevices: IGeoDevice[] = [];
  devices.forEach((device) => {
    const { id, name, deviceId, deviceMetadata, status, deviceType } = device;
    // @ts-ignore
    const { deviceInfo } = deviceMetadata;
    const latitude = deviceInfo.find((info: { key: string }) => info.key === 'latitude');
    const longitude = deviceInfo.find((info: { key: string }) => info.key === 'longitude');
    if (latitude && longitude) {
      geoDevices.push({
        id,
        name,
        status,
        deviceId,
        deviceType,
        latitude: latitude.value,
        longitude: longitude.value,
      });
    }
  });
  return geoDevices;
};

/* device messages */
type PartialDeviceMessageFilter = Pick<DeviceMessageFilter,
  'deviceId' | 'idOfDevice' | 'deviceTypes' | 'deviceGroups'>;

interface MessageFilterQuery {
  deviceId?: string;
  idOfDevice?: string;
  deviceGroup?: string[];
  deviceType?: string[];
}
const getDevicesMessagesFilterString = (filter?: PartialDeviceMessageFilter) => {
  const {
    deviceGroups,
    deviceTypes,
  } = filter || {};
  const updatedFilter: MessageFilterQuery = {
    deviceId: filter?.deviceId,
    idOfDevice: filter?.idOfDevice,
  };

  if (deviceGroups?.length) {
    updatedFilter.deviceGroup = deviceGroups.map(deviceGroup=>deviceGroup.id!);
  }

  const deviceTypeIds = deviceTypes?.map((option) => option.value);
  if (deviceTypeIds && deviceTypeIds.length > 0) {
    updatedFilter.deviceType = deviceTypeIds;
  }

  return JSON.stringify(updatedFilter);
};

let cancelMessageRequest: Canceler;

export const fetchDeviceMessagesFromDB = async (
  fetchOptions: FetchOptions<DeviceMessageFilter>,
  requestCancelerMessage = FETCH_CANCELLED_BY_NEW_FETCH,
) => {
  try {
    const {
      page,
      pageSize,
      filters,
      sort,
    } = fetchOptions;

    if (cancelMessageRequest) {
      cancelMessageRequest(requestCancelerMessage);
    }

    let rangeString;
    let gatewayFilter;
    let filterString = '';
    if (filters) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { receivedAt_gte, receivedAt_lte, period, gateways, idOfDevice, ...remainingFilter } = filters;
      rangeString = getDateRangeString({ receivedAt_gte, receivedAt_lte, period })
      gatewayFilter = getGatewayFilterString(filters);
      filterString = getDevicesMessagesFilterString({ ...remainingFilter, deviceId: idOfDevice });
    }

    const qs = buildQueryString({
      page,
      pageSize,
      filter: filterString,
      sort,
      gateways: gatewayFilter,
      range: rangeString,
    });
    const url = `${ api.deviceMessages }?${ qs }`;

    const { data } = await authenticatedInstance.get(url, {
      cancelToken: new CancelToken((c) => {
        // An executor function receives a cancel function as a parameter
        cancelMessageRequest = c;
      }),
    });

    return data;
  } catch (error) {
    if ( error.response?.status === HttpStatus.UNPROCESSABLE_ENTITY) {
      handleApiError(error, true, 'info');
      return error.response?.status;
    }
    handleApiError(error, false);
    throw error;
  }
};

export const fetchLatestMessagesForDeviceFromDB = async (idOfDevice: string, count = 5) => {
  if (isOffline()) {
    return fetchMessagesForDeviceOffline(idOfDevice);
  }
  try {
    const qs = buildQueryString({ limit: count });
    const url = `${ api.deviceMessages }/deviceId/${ idOfDevice }?${ qs }`;
    const { data } = await authenticatedInstance.get(url);
    return data.deviceReadings;
  } catch (error) {
    return handleApiError(error);
  }
};

export const addDeviceMessageToDB = async (reading: DeviceReading): Promise<DeviceReading> => {
  try {
    const { data } = await authenticatedInstance.post<{ deviceReading: DeviceReading }>(
      api.deviceMessages,
      reading,
    );
    return data.deviceReading;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

/* device groups */

/**
 * @deprecated Please use the fetchDeviceGroupAction
 */
export const fetchDeviceGroupFromDB = async (
  deviceGroupId: string,
  appendUserAndDeviceInfo = true,
  appendAncestorGroups = false,
  appendChildGroups = true,
):Promise<DeviceGroup> => {
  const qs = buildQueryString({
    appendUserAndDeviceInfo,
    appendAncestorGroups,
    appendChildGroups,
  });
  try {
    const url = `${ api.deviceGroups }/${ deviceGroupId }?${ qs }`;
    const { data } = await authenticatedInstance.get(url);
    return data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const fetchGroupTypesFromDB = async (): Promise<DeviceGroupType[] | void> => {
  try {
    const { data } = await authenticatedInstance.get(api.deviceGroupTypes);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const validateCSV = async (formdata: FormData) => {
  try {
    const url = `${ api.validate }/csv`;
    const { data } = await authenticatedInstance.post(url, formdata);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const validateXML = async (formdata: FormData) => {
  try {
    const url = `${ api.validate }/xml`;
    const { data } = await authenticatedInstance.post(url, formdata);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const uploadCSV = async (formdata: FormData) => {
  try {
    const url = `${ api.import }/csv`;
    const { data } = await authenticatedInstance.post(url, formdata);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const importXML = async (formdata: FormData) => {
  try {
    const url = `${ api.import }/xml`;
    const { data } = await authenticatedInstance.post(url, formdata);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const removeDeviceGroupFromDB = async (id: string) => {
  if (isOffline()) {
    return removeDeviceGroupOffline(id);
  }
  try {
    const url = `${ api.deviceGroups }/${ id }`;
    const { data } = await authenticatedInstance.delete(url);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

/* users */
let cancelUsersRequest: Canceler;
export const fetchUsersFromDB = async ({
                                         page,
                                         pageSize,
                                         filters: filter,
                                         sort,
                                       }: FetchOptions<IUserManagementFilter>): Promise<{ users: Users }> => {
  cancelUsersRequest && cancelUsersRequest();
  try {
    const updatedFilter = { ...filter, validated: filter?.validated?.value };
    const filterString = JSON.stringify(updatedFilter);
    const qs = buildQueryString({
      page,
      pageSize,
      filter: filterString,
      sort,
    });
    const url = `${ api.users }?${ qs }`;
    const { data } = await authenticatedInstance.get(url, {
      cancelToken: new CancelToken((c) => {
        // An executor function receives a cancel function as a parameter
        cancelUsersRequest = c;
      }),
    });
    return data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const fetchUserWithRolesFromDB = async (id: string): Promise<IUser> => {
  try {
    const url = `${ api.users }/${ id }`;
    const { data } = await authenticatedInstance.get<IUser>(url);
    return data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

type UserDetails = {
  permission: any;
  user: IUser;
};

export const fetchMyUserFromDB = async (): Promise<UserDetails> => {
  try {
    const url = `${ api.authUser }`;
    const { data } = await authenticatedInstance.get<UserDetails>(url);
    return data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const addUserToDB = async (
  user: IUser,
): Promise<IUser> => {
  try {
    const request: RegisterUserRequest = {
      user,
    };
    const { data } = await authenticatedInstance.post<IUser>(api.register, request);
    return data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const removeUserFromDB = async (id: string) => {
  try {
    const url = `${ api.users }/${ id }`;
    const { data } = await authenticatedInstance.delete(url);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const updateUserInDB = async (
  user: IUser,
): Promise<IUser> => {
  try {
    const request: UpdateUserRequest = {
      user,
    };

    const { data } = await authenticatedInstance.patch<IUser>(api.users, request);
    return data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

/* roles */
let cancelPermittedRolesRequest: Canceler;
export const fetchPermittedRolesFromDB = async ({
                                                  page,
                                                  pageSize,
                                                  filters,
                                                  sort,
                                                }: FetchOptions<IRolesFilter> = {}): Promise<{ permittedRoles: IRole[] }> => {
  try {
    cancelPermittedRolesRequest && cancelPermittedRolesRequest();
    const filterString = JSON.stringify(filters);
    const qs = buildQueryString({
      page,
      pageSize,
      filters: filterString,
      sort,
    });
    const url = `${ api.permittedUserRoles }?${ qs }`;
    const { data } = await authenticatedInstance.get<{ permittedRoles: IRole[] }>(url, {
      cancelToken: new CancelToken((c) => {
        // An executor function receives a cancel function as a parameter
        cancelPermittedRolesRequest = c;
      }),
    });
    return data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

let cancelRolesRequest: Canceler;
export const fetchRolesFromDB = async ({
                                         page,
                                         pageSize,
                                         filters: filter,
                                         sort,
                                       }: FetchOptions<IRolesFilter> = {}) => {
  cancelRolesRequest && cancelRolesRequest();
  try {
    const filterString = JSON.stringify(filter);
    const qs = buildQueryString({
      page,
      pageSize,
      filter: filterString,
      sort,
    });
    const url = `${ api.roles }?${ qs }`;
    const { data } = await authenticatedInstance.get(url, {
      cancelToken: new CancelToken((c) => {
        // An executor function receives a cancel function as a parameter
        cancelRolesRequest = c;
      }),
    });
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const fetchRoleFromDB = async (id: string) => {
  try {
    const url = `${ api.roles }/${ id }`;
    const { data } = await authenticatedInstance.get(url);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const addRoleToDB = async (role: any) => {
  try {
    const roleData = { ...role };
    // remove users from role
    if (roleData.users) {
      delete roleData.users;
    }

    const { data } = await authenticatedInstance.post(api.roles, roleData);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const updateRoleInDB = async (role: any) => {
  try {
    const { data } = await authenticatedInstance.patch(api.roles, role);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const removeRoleFromDB = async (id: string) => {
  try {
    const url = `${ api.roles }/${ id }`;
    const { data } = await authenticatedInstance.delete(url);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

/* keys */
let cancelKeysRequest: Canceler;
export const fetchKeysFromDB = async (fetchOptions: FetchOptions<IKeysFilter> = {}) => {
  if (isOffline()) {
    return fetchKeysOffline(fetchOptions);
  }
  cancelKeysRequest && cancelKeysRequest();
  try {
    const { page, pageSize, filters, sort } = fetchOptions;
    const filterString = JSON.stringify(filters);
    const qs = buildQueryString({
      page,
      pageSize,
      filter: filterString,
      sort,
    });
    const url = `${ api.keys }?${ qs }`;
    const { data } = await authenticatedInstance.get(url, {
      cancelToken: new CancelToken((c) => {
        // An executor function receives a cancel function as a parameter
        cancelKeysRequest = c;
      }),
    });
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const fetchKeyFromDB = async (id: string) => {
  if (isOffline()) {
    return fetchKeyOffline(id);
  }
  try {
    const url = `${ api.keys }/${ id }`;
    const { data } = await authenticatedInstance.get(url);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const fetchRadioKeyDevices = async (id: string) => {
  if (isOffline()) {
    return []; // TODO(TL): Is it necessary to have them offline?
  }
  try {
    const filterRadioKey = JSON.stringify(( { radioKeyIds: [ id, ] }));
    const qs = buildQueryString({
      filter: filterRadioKey,
    });
    const url = `${ api.devices }?${ qs }`;
    const { data } = await authenticatedInstance.get(url);
    return data.devices;
  } catch (error) {
    return handleApiError(error);
  }
};

export const fetchRadioKeyDeviceCounts = async (radioKeyIds: string[]) => {
  if (isOffline()) {
    return 0; // TODO(TL): Is it necessary to have them offline?
  }
  try {
    const radioKeyIdsString = radioKeyIds.join(',');
    const qs = buildQueryString({ radioKeyIdsString });
    const url = `${ api.radioKeyDevices }/${ qs }/deviceCount`;
    const { data } = await authenticatedInstance.get(url);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const addKeyToDB = async (key: Record<string, unknown>) => {
  if (isOffline()) {
    return addKeyOffline(key);
  }
  try {
    const url = `${ api.keys }`;
    const { data } = await authenticatedInstance.post(url, key);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const updateKeyInDB = async (key: Record<string, unknown>) => {
  if (isOffline()) {
    return updateKeyOffline(key);
  }
  try {
    const url = `${ api.keys }`;
    const { data } = await authenticatedInstance.patch(url, key);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const removeKeyFromDB = async (id: string) => {
  if (isOffline()) {
    return removeKeyOffline(id);
  }
  try {
    const url = `${ api.keys }/${ id }`;
    const { data } = await authenticatedInstance.delete(url);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

/*
 *  data import jobs
 */
let cancelDataIntegrationRequest: Canceler;
export const fetchDataIntegrationJobsFromDB = async ({
                                                       page,
                                                       pageSize,
                                                       filters: filter,
                                                       sort,
                                                     }: FetchOptions<IDataIntegrationFilter> = {}) => {
  cancelDataIntegrationRequest && cancelDataIntegrationRequest();
  try {
    const filterString = JSON.stringify(filter);
    const qs = buildQueryString({
      page,
      pageSize,
      filter: filterString,
      sort,
    });
    const url = `${ api.dataIntegrationJobs }?${ qs }`;
    const { data } = await authenticatedInstance.get(url, {
      cancelToken: new CancelToken((c) => {
        // An executor function receives a cancel function as a parameter
        cancelDataIntegrationRequest = c;
      }),
    });
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const addDataIntegrationJobInDB = async (dataIntegrationJob: DataIntegrationJob) => {
  try {
    const url = `${ api.dataIntegrationJobs }`;
    const { data } = await authenticatedInstance.post(url, dataIntegrationJob);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const updateDataIntegrationJobInDB = async (dataIntegrationJob: DataIntegrationJob) => {
  try {
    const url = `${ api.dataIntegrationJobs }`;
    const { data } = await authenticatedInstance.patch(url, dataIntegrationJob);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const removeDataIntegrationJobFromDB = async (id: string) => {
  try {
    const url = `${ api.dataIntegrationJobs }/${ id }`;
    const { data } = await authenticatedInstance.delete(url);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

/** Run data-integration jobs */
export const runDataIntegrationJobFromDB = async (
  dataIntegrationJob: DataIntegrationJob,
  isTest?: boolean,
) => {
  try {
    const qs = buildQueryString({ isTest });
    const url = `${ api.runDataIntegrationJobs }?${ qs }`;
    const { data } = await authenticatedInstance.post(url, { dataIntegrationJob });
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

/* login */
export const loginUser = async (credentials: UserCredentials): Promise<UserDetails> => {
  try {
    const authUrl = `${ api.authLogin }`;
    const { data: userDetails } = await authenticatedInstance.post<UserDetails>(
      authUrl,
      credentials,
    );
    return userDetails;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const logoutUser = async () => {
  try {
    const authUrl = `${ api.authLogout }`;
    const { data } = await authenticatedInstance.post(authUrl);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

/* resend registration email */
export const resendRegistrationEmail = async (email: string) => {
  try {
    const authUrl = `${ api.resendRegistrationMail }`;
    const { data } = await authenticatedInstance.post(authUrl, { email });
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const resetPassword = async (payload: Record<string, string>) => {
  try {
    const validateUrl = `${ api.resetPassword }`;
    const { data } = await authenticatedInstance.post(validateUrl, payload);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const setPassword = async (password: string, token: string) => {
  try {
    const validateUrl = `${ api.validateUser }`;
    const { data } = await authenticatedInstance.post(validateUrl, {
      token,
      password,
    });
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const getDeviceImages = async (deviceId: string) => {
  if (isOffline()) {
    return [];
  }
  try {
    const url = `${ api.images }/${ deviceId }`;
    const { data } = await authenticatedInstance.get(url);
    const { images } = data;
    if (!images) {
      return [];
    }
    return images.map((image: string) => `${ api.baseAPIUrl }${ api.devices }/${ image }`);
  } catch (error) {
    return handleApiError(error);
  }
};

export const addDeviceImages = async (deviceId: string, images: FormData) => {
  try {
    const url = `${ api.images }/${ deviceId }`;
    const { data } = await authenticatedInstance.post(url, images);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const deleteDeviceImage = async (imageId: string) => {
  try {
    const url = `${ api.image }/${ imageId }`;
    const { data } = await authenticatedInstance.delete(url);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const getSettingsInDB = async () => {
  try {
    const url = `${ api.settings }`;
    const { data } = await authenticatedInstance.get(url);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const updateSettingsInDB = async (settings: ISettings) => {
  try {
    const url = `${ api.settings }`;
    const { data } = await authenticatedInstance.patch(url, settings);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

type GetSiteSettingsInDBResponse = { settings: ISettings };
export const getSiteSettingsInDB = async (): Promise<ISettings> => {
  try {
    const url = `${ api.siteSettings }`;
    const { data } = await authenticatedInstance.get<GetSiteSettingsInDBResponse>(url);
    return data.settings;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getDeviceTypeProfiles = async (manufacturer?: string, deviceTypeId?: string) => {
  if (isOffline()) {
    return fetchDeviceTypeProfileOffline(manufacturer, deviceTypeId);
  }
  try {
    const url = `${ api.deviceTypeProfile }`;
    const config: AxiosRequestConfig = {
      params: { manufacturer, deviceTypeId },
    };
    const { data } = await authenticatedInstance.get(url, config);
    return (
      data &&
      data.deviceTypeProfiles.map(
        (profile: IDeviceTypeProfile) =>
          profile && {
            ...profile,
            unit: MEASURE_UNITS[profile.unit],
          },
      )
    );
  } catch (error) {
    return handleApiError(error);
  }
};

export const getSystemHealth = async () => {
  try {
    const url = `${ api.systemHealth }`;
    const { data } = await authenticatedInstance.get(url);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const getSystemHealthLogs = async (component: string) => {
  try {
    const url = `${ api.systemHealth }/logs/${ component }`;
    const { data } = await authenticatedInstance.get(url);
    return unescape(data);
  } catch (error) {
    return handleApiError(error);
  }
};

/*
Ftp Users
*/
/* keys */
let cancelFtpUsersRequest: Canceler;
export const fetchFtpUsersFromDB = async ({
                                            page,
                                            pageSize,
                                            filters: filter,
                                            sort,
                                          }: FetchOptions<string> = {}) => {
  cancelFtpUsersRequest && cancelFtpUsersRequest();
  try {
    const qs = buildQueryString({
      page,
      pageSize,
      filter,
      sort,
    });
    const url = `${ api.ftpUsers }?${ qs }`;
    const { data } = await authenticatedInstance.get(url, {
      cancelToken: new CancelToken((c) => {
        // An executor function receives a cancel function as a parameter
        cancelFtpUsersRequest = c;
      }),
    });
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const appendFilesToFormData = (formData: FormData, files: any) => {
  if (files.length > 0) {
    files.forEach((file: any, i: number) => {
      formData.append(`file${ i }`, file);
    });
  }
  return formData;
};

export const replaceSiteLogo = async (image: Blob) => {
  try {
    const formData = new FormData();
    formData.append('logo', image);
    const url = `${ api.customizeLogo }/`;
    const { data } = await authenticatedInstance.post(url, formData);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const replaceSiteWallpaper = async (image: Blob) => {
  try {
    const formData = new FormData();
    formData.append('wallpaper', image);
    const url = `${ api.customizeWallpaper }/`;
    const { data } = await authenticatedInstance.post(url, formData);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const replaceSiteBranding = async (image: Blob) => {
  try {
    const formData = new FormData();
    formData.append('branding', image);
    const url = `${ api.customizeBranding }/`;
    const { data } = await authenticatedInstance.post(url, formData);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const replaceSiteFavicon = async (image: Blob) => {
  try {
    const formData = new FormData();
    formData.append('favicon', image);
    const url = `${ api.customizeFavIcon }/`;
    const { data } = await authenticatedInstance.post(url, formData);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const patchSiteTheme = async (color: ColorResult) => {
  try {
    const { hsl } = color;
    const url = `${ api.customizeTheme }/`;
    const { data } = await authenticatedInstance.post(url, hsl);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const sendWithContactForm = async (
  name: string,
  email: string,
  subject: string,
  message: string,
  phone?: string,
) => {
  try {
    const url = `${ api.contactForm }/`;
    const { data } = await authenticatedInstance.post(url, {
      name,
      email,
      subject,
      message,
      phone,
    });
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};
