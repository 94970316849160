import { Property, PropertyMeasurementFrequency } from '@wiot/shared-domain/domain/property/property';
import { Dispatch } from 'redux';
import { authenticatedInstance as httpClientInstance, handleApiError } from '../../api/apiHelpers';
import {
  FETCH_PROPERTY_CONSUMPTION_HISTORY,
  FetchPropertyConsumptionHistoryAction,
  PROPERTY_CONSUMPTION_HISTORY_FETCHED,
  PropertyConsumptionHistoryFetchedAction
} from './propertyViewActions';
import { buildQueryString } from '../../utils/common';
import { ConsumptionHistory } from '@wiot/shared-domain/domain/property/consumption';
import { MBusDeviceType } from '@wiot/shared-domain/models/device-types/m-bus-device-types';
import { DispatchActionTypes } from '../types';
import { PropertyConsumptionType } from '@wiot/shared-domain/domain/property/property-consumption-type';

export const propertyConsumptionHistoryFetched = (
  property: Property,
  mBusDeviceTypeId: MBusDeviceType,
  billingFrequency: PropertyMeasurementFrequency,
  billingPeriodStartDate: string,
): PropertyConsumptionHistoryFetchedAction => ({
  type: PROPERTY_CONSUMPTION_HISTORY_FETCHED,
  property,
  mBusDeviceTypeId,
  billingFrequency,
  billingPeriodStartDate,
});

export const fetchPropertyConsumptionHistory = (
  property: Property,
  mBusDeviceTypeId: number,
  startDate: string,
  billingFrequency: PropertyMeasurementFrequency,
  includeDescendants: boolean,
  consumptionType: PropertyConsumptionType,
  ) =>
  async (dispatch: Dispatch<DispatchActionTypes>): Promise<void> => {
    dispatch({
      type: FETCH_PROPERTY_CONSUMPTION_HISTORY,
      propertyId: property.id,
      mBusDeviceTypeId,
    } as FetchPropertyConsumptionHistoryAction);

    const qs = buildQueryString({
      startDate: `"${ startDate }"`,
      billingFrequency,
      includeDescendants,
      consumptionType,
    });
    const url = `/properties/${ property.id }/${ mBusDeviceTypeId }?${ qs }`;
    try {
      const { data: consumptionHistory } = await httpClientInstance.get<ConsumptionHistory[]>(url);

      const fetchedProperty: Property = {
        id: property.id,
        consumptionHistory: {
          [mBusDeviceTypeId]: {
            [billingFrequency]: {
              [startDate]: consumptionHistory,
            },
          },
        },
      } as Property;

      dispatch(propertyConsumptionHistoryFetched(fetchedProperty, mBusDeviceTypeId, billingFrequency, startDate));
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  };
