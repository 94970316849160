export const SHOW_TOO_MANY_RESULTS_INFO = 'SHOW_TOO_MANY_RESULTS_INFO';
export const SHOW_NO_RESULTS_INFO = 'SHOW_TOO_MANY_RESULTS_INFO';

export enum NoResultsInfoState {
  noData,
  tooMuchToShow,
}

export interface NoResultsInfoAction {
  type: typeof SHOW_TOO_MANY_RESULTS_INFO | typeof SHOW_NO_RESULTS_INFO;
  payload: NoResultsInfoState;
}

export type NoResultsInfoActionTypes = NoResultsInfoAction;
export const showDataExceedsDisplayLimit = (): NoResultsInfoAction => ({
  type: SHOW_TOO_MANY_RESULTS_INFO,
  payload: NoResultsInfoState.tooMuchToShow,
});

export const showNoResultsInfo = (): NoResultsInfoAction => ({
  type: SHOW_NO_RESULTS_INFO,
  payload: NoResultsInfoState.noData,
});
