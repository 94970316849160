import { DEVICE_TYPES_FETCHED, DeviceTypesFetchedAction } from './deviceTypesActions';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';

export type DeviceTypesState = IDeviceType[];

const initialState: DeviceTypesState = [];

export const deviceTypesReducer = (
  state: DeviceTypesState = initialState,
  action: DeviceTypesFetchedAction,
): DeviceTypesState => {
  const { type, deviceTypes } = action;

  switch (type) {
    case DEVICE_TYPES_FETCHED:
      if (deviceTypes) {
        return deviceTypes;
      }
      return state;

    default:
      return state;
  }
}
