import * as React from 'react';
import { useDispatch } from 'react-redux';
import { TreeNodeProps } from './tree-node-props';
import { loadMoreChildren } from '../../../state/device-tree/fetch-tree-node-with-children/loadMoreChildrenActionCreator';
import { Translate } from 'react-localize-redux';

function ExpandTreeNode(props: TreeNodeProps) {
  const dispatch = useDispatch();
  const { treeNode } = props;

  const parentId = treeNode.parentId;

  if (!parentId) {
    throw new Error(`Missing parent ID. This component can't be used on the tree top level`);
  }

  return (
    <>
      <div className="tree__node__root__badge">
        <a onClick={ () => dispatch(loadMoreChildren(parentId)) }>
          <div>
            <div className="expand-tree-node vertical-center">
              <Translate id="show-more"/>
            </div>
          </div>
        </a>

        <div className="tree__node__label__footer">
          <div className="tree__node__label__icon tree__node__label__icon__expand text-color-main"/>
        </div>
      </div>
    </>
  );
}

export default ExpandTreeNode;
