import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'debounce-promise';
import { Device } from '@wiot/shared-domain/models/device/device';
import { AppState } from '../../state/reducers/rootReducer';
import { fetchDeviceFromDB, fetchDevicesFromDB } from '../../api/apiHelpers';
import { SelectFieldProps } from '../FilterBar/select/components/select-props';
import { setFilter } from '../../state/filter/set-filter-action-creator';
import { AsyncBaseSelect } from '../FilterBar/select/AsyncBaseSelect';
import { DEBOUNCE_TIME_TO_START_SEARCH } from '../FilterBar/select/constants/debounce-time-to-start-search';
import { InputContextType } from '../Input/input-context-type';

const FilterAsyncDeviceSelect = (props: SelectFieldProps) => {
  const { pageKey, filterField } = props;

  // @ts-ignore The 'any' type in the global filter must be refactored
  const deviceIdFromFilter: string | undefined = useSelector((state: AppState) => state.filters.filter[pageKey]['idOfDevice'] ?? undefined);
  const [selectedDevice, setSelectedDevice] = useState<Device | undefined>();

  const dispatch = useDispatch();

  const getLabel = (option: Device) => `${ option.name } / ${ option.deviceId }`;
  const getValue = (option: Device) => `${ option.id }`;

  useEffect(() => {
    setSelectedDeviceByIdOfDevice(deviceIdFromFilter);
  }, [deviceIdFromFilter]);

  async function setSelectedDeviceByIdOfDevice(idOfDevice: string | undefined) {
    if (!idOfDevice) {
      setSelectedDevice(undefined);
      return;
    }
    if (idOfDevice) {
      const device = await fetchDeviceFromDB(idOfDevice);
      setSelectedDevice(device);
      return;
    }
    setSelectedDevice(undefined);
  }

  const handleSelectionChange = async (option: undefined | Device) => {
    let value: string | undefined;
    if (option) {
      value = getValue(option);
    }
    dispatch(
      setFilter({
        page: pageKey,
        values: {
          ['idOfDevice']: value,
        },
      }),
    );
  };

  const fetchOptions = async (searchText: string): Promise<Device[]> => {
    try {
      const { devices: response } = await fetchDevicesFromDB({ filters: { name: searchText, deviceGroups:[] } });
      return response;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  return (
    <AsyncBaseSelect<Device>
      translationId={ filterField.name }
      placeholderTranslationId="device-name-id"

      selectedOptions={ selectedDevice }

      onChange={ handleSelectionChange }

      getOptionLabel={ getLabel }
      getOptionValue={ getValue }

      loadOptions={ debounce(fetchOptions, DEBOUNCE_TIME_TO_START_SEARCH) }

      isMulti={ false }

      className="filterbar__item"
      inputContextType={ InputContextType.FILTER_BAR }
      translateOptions={ false }
      showDefaultOptions={ false }
      hasClearIndicator={ true }
    />
  );
};

export default FilterAsyncDeviceSelect;
