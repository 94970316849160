import { SAVE_USER_ROLES } from '../constants/actionTypes';
import { UserRolesActionTypes, UserRolesState } from '../types';
import { UserRole } from '@wiot/shared-domain/models/user/user-role';

const initialState: UserRolesState = [];

export const userRolesReducer = (
  state: UserRolesState = initialState,
  action: UserRolesActionTypes,
): UserRolesState => {
  switch (action.type) {
    case SAVE_USER_ROLES:
      return action.payload.map((userRole: UserRole) => ({
        ...userRole,
        checked: false,
      }));

    default:
      return state;
  }
};
