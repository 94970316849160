import { IsTableLoadingState } from '../types';
import {
  CREATE_DEVICE_GROUP,
  DEVICE_GROUP_CREATED,
  DEVICE_GROUP_CREATION_FAILED
} from '../device-group/create-device-group/deviceGroupCreationTypes';
import {
  DEVICE_GROUP_UPDATE_FAILED,
  DEVICE_GROUP_UPDATED,
  UPDATE_DEVICE_GROUP
} from '../device-group/update-device-group/deviceGroupUpdateTypes';
import { IS_TABLE_LOADING, IsTableLoadingActionTypes } from './isTableLoadingAction';

const initialState: IsTableLoadingState = false;

export const isTableLoadingReducer = (
  state: IsTableLoadingState = initialState,
  action: IsTableLoadingActionTypes,
): IsTableLoadingState => {
  switch (action.type) {
    case IS_TABLE_LOADING:
      return action.payload;
    case CREATE_DEVICE_GROUP:
    case UPDATE_DEVICE_GROUP:
      return true;
    case DEVICE_GROUP_CREATED:
    case DEVICE_GROUP_UPDATED:
    case DEVICE_GROUP_CREATION_FAILED:
    case DEVICE_GROUP_UPDATE_FAILED:
      return false;

    default:
      return state;
  }
};
