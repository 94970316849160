import { Dispatch } from 'redux';
import { LicensingOverview } from '@wiot/shared-domain/domain/licensing/LicensingOverview';
import { authenticatedInstance, handleApiError } from '../../api/apiHelpers';
import api from '../../api/api';
import {
  FETCH_LICENSING_OVERVIEW,
  FetchLicensingOverviewAction,
  FETCH_LICENSING_OVERVIEW_SUCCEEDED,
  FetchLicensingOverviewSucceededAction
} from './FetchLicensingOverviewActions';

const getLicensingOverview = async (): Promise<LicensingOverview> => {
  try {
    const { data } = await authenticatedInstance.get<LicensingOverview>(
      api.licenses,
    );
    return data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

/**
 * Fetches the licensing overview from the backend.
 * Dispatches a {@link FETCH_LICENSING_OVERVIEW} action immediately.
 * Dispatches a {@link FETCH_LICENSING_OVERVIEW_SUCCEEDED} action once the overview has been retrieved.
 *
 * @returns A promise for the asynchronous operation.
 */
export const fetchLicensingOverview =
  () => async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch({
      type: FETCH_LICENSING_OVERVIEW,
    } as FetchLicensingOverviewAction);

    const licensingOverview = await getLicensingOverview();

    if (licensingOverview) {
      dispatch({
        type: FETCH_LICENSING_OVERVIEW_SUCCEEDED,
        licensingOverview: licensingOverview,
      } as FetchLicensingOverviewSucceededAction);
    }
  };

