import { IS_DEVICE_TREE_LOADING } from '../../constants/actionTypes';
import {
  IsLoadingState,
} from '../../types';
import { IsDeviceTreeLoadingAction } from './isDeviceTreeLoadingAction';

const initialState: boolean = false;

export const isDeviceTreeLoadingReducer = (
  state: boolean = initialState,
  action: IsDeviceTreeLoadingAction,
): IsLoadingState => {
  switch (action.type) {
    case IS_DEVICE_TREE_LOADING:
      return action.value;

    default:
      return state;
  }
};
