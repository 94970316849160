import { IS_OFFLINE } from '../constants/actionTypes';
import { IsLoadingState, IsOfflineActionTypes, IsOfflineState } from '../types';

const initialState: IsOfflineState = false;

export const isOfflineReducer = (
  state: IsOfflineState = initialState,
  action: IsOfflineActionTypes,
): IsLoadingState => {
  switch (action.type) {
    case IS_OFFLINE:
      return action.payload;

    default:
      return state;
  }
};
