import { DeviceViews, DisplayTypes } from '@wiot/shared-domain/models/device/device';

export const SET_DEVICE_MANAGER_VISIBLE_VIEW = 'SET_DEVICE_MANAGER_VISIBLE_VIEW';
export interface ChangeVisibleViewAction {
  type: typeof SET_DEVICE_MANAGER_VISIBLE_VIEW;
  payload: DeviceViews,
}
export const changeVisibleView = (view: DeviceViews): ChangeVisibleViewAction => ({
  type: SET_DEVICE_MANAGER_VISIBLE_VIEW,
  payload: view,
});

export const SET_DEVICE_MANAGER_CONTENT_DISPLAY = 'SET_DEVICE_MANAGER_CONTENT_DISPLAY';
export interface ChangeContentDisplayAction {
  type: typeof SET_DEVICE_MANAGER_CONTENT_DISPLAY;
  payload: DisplayTypes,
}
export const changeContentDisplay = (display: DisplayTypes): ChangeContentDisplayAction => ({
  type: SET_DEVICE_MANAGER_CONTENT_DISPLAY,
  payload: display,
});

export const RESET_DEVICE_MANAGER_STATE = 'RESET_DEVICE_MANAGER_STATE';
export interface ResetDeviceManagerStateAction {
  type: typeof RESET_DEVICE_MANAGER_STATE;
}

export const resetDeviceManagerState = (): ResetDeviceManagerStateAction => ({
  type: RESET_DEVICE_MANAGER_STATE,
});

export const SET_DEVICE_MANAGER_DEVICES_ARE_OUTDATED = 'SET_DEVICE_MANAGER_DEVICES_ARE_OUTDATED';
export interface SetDeviceManagerDevicesAreOutdatedAction {
  type: typeof SET_DEVICE_MANAGER_DEVICES_ARE_OUTDATED;
  payload: boolean;
}

export const setDeviceManagerDevicesAreOutdated = (devicesAreOutdated: boolean): SetDeviceManagerDevicesAreOutdatedAction => ({
  type: SET_DEVICE_MANAGER_DEVICES_ARE_OUTDATED,
  payload: devicesAreOutdated,
});
