import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as Yup from 'yup';
import { Col, Row } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { v4 as uuidv4 } from 'uuid';
import { random } from '@supercharge/strings';
import debounce from 'debounce-promise';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import { ITranslation } from '@wiot/shared-domain/models/localization/translation';
import { AggregatedPermission } from '@wiot/shared-domain/models/role/role';
import Desktop from '../../components/Responsive/Desktop';
import Mobile from '../../components/Responsive/Mobile';
import LoadingIcon from '../../components/shared/LoadingIcon';
import RenderOnCondition from '../../components/RenderOnCondition';
import {
  addDeviceTypeToDB,
  fetchDeviceTypeFromDB,
  fetchDeviceTypesFromDB,
  updateDeviceTypeInDB,
  validateDeviceTypeName,
} from '../../api/apiHelpers';
import ModalHeader from '../../components/Modal/ModalHeader';
import { CustomFieldInput } from '../../components/Table/CustomFieldInput';
import ConfirmModal, { ConfirmationVariant } from '../../components/Modal/ConfirmModal';
import { AppState } from '../../state/reducers/rootReducer';
import { DispatchActionTypes, SetFieldValue } from '../../state/types';
import { isTableLoading } from '../../state/table/isTableLoadingAction';
import { getDeviceTypeIconFullPath, getTranslationValueInCurrentLanguage, } from '../../utils/common';
import HasPermission from '../../components/HasPermission';
import { createAddDeviceTypeAction } from '../../state/actions/addDeviceTypeAction';
import ImageUpload from '../../components/Upload/ImageUpload';
import MultiLanguageInputField from '../../components/Table/MultiLanguageInputField';
import { getRandomModalOffset } from '../../utils/dialog';
import { FeedbackAttachment } from '../../components/Feedback/feedback';

const MAX_LOGOSIZE = 500000;
const MIN_LOGOSIZE = 1000;

export interface DeviceTypeActionModalProps extends LocalizeContextProps {
  closeAddAndUpdateModal: (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => void;
  title: string;
  showDeleteButton?: boolean;
  isInCreationMode?: boolean;
  isOffline: boolean;
  deviceType?: IDeviceType;
  addDeviceType: (deviceType: IDeviceType) => void;
  currentEntriesPerPage: number;
  currentPage: number;

  /**
   * Callback function which will be called if device type was changed.
   */
  deviceTypeChanged?: () => void;

  /**
   * Callback function which will be called if a removal was requested.
   * @param id
   */
  removalRequested?: (id: string) => void;

  permission?: AggregatedPermission;
  setIsTableLoading: (loading: boolean) => void;
  disableGroupSelect?: boolean;
}

export interface DeviceTypeActionModalState {
  deviceTypeIcon: File | null;
  deviceTypeData: IDeviceType;
  showDeleteModal: boolean;
  randomModalOffset?: { marginTop: number; marginRight: number };
  isLoading: boolean;
}

class DeviceTypeActionModal extends React.Component<
  DeviceTypeActionModalProps,
  DeviceTypeActionModalState
> {
  modalUid = uuidv4();

  constructor(props: DeviceTypeActionModalProps) {
    super(props);

    this.state = {
      deviceTypeIcon: null,
      deviceTypeData: {
        id: '',
        name: [],
        isActive: true,
        isSystem: false,
      },
      showDeleteModal: false,
      randomModalOffset: { marginTop: 0, marginRight: 0 },
      isLoading: true,
    };
  }

  public componentDidMount = async (): Promise<void> => {
    const newState = { ...this.state };
    newState.randomModalOffset = getRandomModalOffset();

    if (!this.props.isInCreationMode && this.props.deviceType?.id) {
      const deviceTypeData = await fetchDeviceTypeFromDB(this.props.deviceType?.id);

      if (!deviceTypeData) {
        toastr.error(
          'DEVICE TYPE NOT FOUND!',
          'The requested device type does not exists in the database!',
        );
        return;
      }

      newState.deviceTypeData = deviceTypeData;
    }

    this.setState({
      ...newState,
      isLoading: false,
    });
    await fetchDeviceTypesFromDB();
  };

  private toggleDeleteModal = (): void => {
    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }));
  };

  private onFormSubmit = async (deviceType: IDeviceType): Promise<void> => {
    const {
      setIsTableLoading,
      isInCreationMode,
      deviceTypeChanged,
      closeAddAndUpdateModal,
      translate,
      addDeviceType,
    } = this.props;

    try {
      setIsTableLoading(true);

      if (isInCreationMode) {
        await addDeviceTypeToDB({
          ...deviceType,
          id: random(),
        });
        await addDeviceType(deviceType);
      } else {
        await updateDeviceTypeInDB(deviceType);
      }

      closeAddAndUpdateModal();

      if (deviceTypeChanged) {
        deviceTypeChanged();
      }

      const toasterMessageTerm = isInCreationMode
        ? 'add-device-type-success'
        : 'edit-device-type-success';
      toastr.success(translate('success').toString(), translate(toasterMessageTerm).toString());
    } catch (error) {
      setIsTableLoading(false);

      const errorMessage = error instanceof Error ? error.name : error;
      const translatedErrorMessage =
        typeof error === 'string' ? translate(errorMessage).toString() : '';
      toastr.error(translate('error').toString(), translatedErrorMessage);
    }
  };

  private getValidationSchema = (): { [key: string]: Yup.Schema<any> } => {
    const { deviceType, isOffline, translate } = this.props;

    return {
      name: Yup.string()
        .trim()
        .required(translate('required-field').toString())
        .test(
          'is-device-type-name-unique',
          translate('name-already-in-use').toString(),
          debounce(async (value) => {
            if (isOffline) {
              // We need to prevent the backend validation when offline.
              return true;
            }

            const valueChanged = value !== deviceType?.name;
            const isNameValid = await validateDeviceTypeName(value);
            return !valueChanged || isNameValid;
          }, 200),
        ),
    };
  };

  private onNameChanged = (newName: ITranslation[], setFieldValue: SetFieldValue) => {
    setFieldValue('name', newName);
  };

  private getAttachmentForFeedback = (): FeedbackAttachment | null => {
    if (!this.state.deviceTypeData && !this.props.deviceType) {
      return null;
    }
    return {
      deviceType: this.state.deviceTypeData || this.props.deviceType,
    };
  };

  public render() {
    const {
      title,
      deviceType,
      closeAddAndUpdateModal,
      permission,
      removalRequested,
      showDeleteButton,
      isInCreationMode,
      translate,
    } = this.props;
    const { deviceTypeData, deviceTypeIcon, isLoading } = this.state;

    return (
      <div
        tabIndex={0} // make it focusable
        className="device-modal"
        style={this.state.randomModalOffset}
        id={`${title}-${this.modalUid}`}
      >
        <ModalHeader
          isDevice={false}
          titleTranslationId={title}
          titlePostfix={getTranslationValueInCurrentLanguage(deviceTypeData.name)}
          targetId={this.modalUid}
          handleClose={closeAddAndUpdateModal}
          enableFeedbackSubmission={true}
          getFeedbackAttachment={this.getAttachmentForFeedback}
        />
        <div className="device-type-modal__body">
          <Formik
            initialValues={deviceTypeData}
            validationSchema={Yup.object().shape(this.getValidationSchema())}
            enableReinitialize
            onSubmit={this.onFormSubmit}
            render={({ values, setFieldValue }: FormikProps<IDeviceType>) =>
              isLoading ? (
                <div className="loading-container">
                  <LoadingIcon />
                </div>
              ) : (
                <Form
                  className="form"
                  onClick={(event: React.MouseEvent) => event.stopPropagation()}
                >
                  <div className="form__section">
                    <Row>
                      <Col lg={4} className="text-center">
                        <img
                          src={getDeviceTypeIconFullPath(values)}
                          className="thumbnail"
                          alt="device group type"
                        />
                      </Col>

                      <Col lg={8}>
                        <MultiLanguageInputField
                          translatedInputLabel="device-type-name"
                          fieldName="name"
                          value={values.name}
                          required
                          onChange={(value) => {
                            this.onNameChanged(value, setFieldValue);
                          }}
                        />
                        <CustomFieldInput
                          translationId="oms-device-type-identifier"
                          fieldName="omsId"
                          value={values.mBusDeviceTypeId}
                        />
                      </Col>
                    </Row>
                    <ImageUpload
                      title={translate('', {
                        maxSize: MAX_LOGOSIZE / 1000,
                        minSize: MIN_LOGOSIZE / 1000,
                      }).toString()}
                      description="Hallo"
                      images={deviceTypeIcon ? [deviceTypeIcon] : undefined}
                      options={{
                        multiple: false,
                        maxSize: MAX_LOGOSIZE,
                        minSize: MIN_LOGOSIZE,
                        onDrop: (droppedFile) => {
                          this.setState({
                            deviceTypeIcon:
                              droppedFile && droppedFile.length >= 1 ? droppedFile[0] : null,
                          });
                        },
                        accept: 'image/svg+xml, image/jpeg, image/jpg, image/gif, image/png',
                      }}
                      uploadRequested={async (file) => {
                        // TODO(DS): Implement device type upload
                      }}
                      // src={ deviceTypeIcon ? URL.createObjectURL(deviceTypeIcon) : '' }
                      src={deviceTypeData.iconLocation ? deviceTypeData.iconLocation.path : ''}
                      file="device-type-icon"
                      readOnly={false}
                    />
                  </div>

                  <div className="form__section last">
                    <div className="form__row space-between ai">
                      <div className="form__row--left">
                        <HasPermission
                          permissionObj={permission}
                          permissionKey="deviceTypes.remove"
                        >
                          <>
                            {showDeleteButton && (
                              <button
                                type="button"
                                className="form__button--delete"
                                onClick={this.toggleDeleteModal}
                              >
                                <Translate id="remove" />
                              </button>
                            )}
                          </>
                        </HasPermission>
                      </div>

                      <div className="form__row--right">
                        <button
                          type="button"
                          className="form__button--cancel"
                          onClick={closeAddAndUpdateModal}
                        >
                          <Translate id="cancel" />
                        </button>
                        <button
                          className="form__button--blue background-color-main text-color-white border-color-main"
                          type="submit"
                        >
                          <Desktop>
                            <Translate
                              id={isInCreationMode ? 'add-device-type' : 'save-device-type'}
                            />
                          </Desktop>
                          <Mobile>
                            <Translate id={isInCreationMode ? 'add-device-type' : 'save'} />
                          </Mobile>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )
            }
          />
          <RenderOnCondition condition={this.state.showDeleteModal}>
            <>
              {deviceType && (
                <ConfirmModal
                  modalCloseRequested={this.toggleDeleteModal}
                  actionConfirmed={async () => {
                    removalRequested && removalRequested(deviceType.id);
                  }}
                  translationIdOfElementType="device-type"
                  confirmationVariant={ ConfirmationVariant.DELETE }
                />
              )}
            </>
          </RenderOnCondition>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  currentEntriesPerPage: state.currentEntriesPerPage,
  currentPage: state.pagination['manage-device-types'].currentPage,
  isOffline: state.isOffline,
  permission: state.currentUser.permission,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  addDeviceType: (deviceType: IDeviceType) => dispatch(createAddDeviceTypeAction(deviceType)),
  setIsTableLoading: (loading: boolean) => dispatch(isTableLoading(loading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(DeviceTypeActionModal));
