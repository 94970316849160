import {
  FETCH_GROUP_SELECT_MENU,
  FetchGroupSelectMenuTypes, GROUP_SELECT_MENU_FETCH_FAILED,
  GROUP_SELECT_MENU_FETCHED,
  GroupSelectMenu,
} from './fetch-group-select-menu/fetch-group-select-menu-action-types';
import {
  DEVICE_GROUP_FETCH_FAILED,
  SELECTED_DEVICE_GROUP_FETCHED,
  RESET_GROUP_SELECT,
  ResetGroupSelectAction, FETCH_SELECTED_DEVICE_GROUP,
  FetchDeviceGroupActionTypes, DESELECT_DEVICE_GROUP
} from './fetch-selected-device-group/fetch-device-group-action-types';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';


type GroupSelectsState = {
  groupSelects: OneGroupSelectState[];
};

const initialState: GroupSelectsState = {
  groupSelects: [],
};

export type OneGroupSelectState = {
  groupSelectUuid: string;
  selectedDeviceGroups: DeviceGroup[];
  isLoadingSelectedDeviceGroups: boolean;
  menu: GroupSelectMenu | null;
  isLoadingMenu: boolean;
};

export const getInitialSingleGroupSelectState = (groupSelectUuid: string): OneGroupSelectState => ({
  groupSelectUuid: groupSelectUuid,
  selectedDeviceGroups: [],
  isLoadingSelectedDeviceGroups: false,
  menu: null,
  isLoadingMenu: false
});

export const groupSelectsReducer = (
  state: GroupSelectsState = initialState,
  action: FetchGroupSelectMenuTypes | ResetGroupSelectAction | FetchDeviceGroupActionTypes,
): GroupSelectsState => {
  switch (action.type) {
    case FETCH_GROUP_SELECT_MENU:
      return {
        ...state,
        groupSelects: updateGroupSelect(state.groupSelects, action.groupSelectUuid,
          { isLoadingMenu: true }
        )
      };

    case GROUP_SELECT_MENU_FETCHED:
      return {
        ...state,
        groupSelects: updateGroupSelect(state.groupSelects, action.groupSelectUuid,
          { isLoadingMenu: false, menu: action.groupSelectMenu }
        )
      };

    case GROUP_SELECT_MENU_FETCH_FAILED:
      return {
        ...state,
        groupSelects: updateGroupSelect(state.groupSelects, action.groupSelectUuid,
          { isLoadingMenu: false }
        )
      };

    case FETCH_SELECTED_DEVICE_GROUP:
      return {
        ...state,
        groupSelects: updateGroupSelect(state.groupSelects, action.groupSelectUuid,
          { isLoadingSelectedDeviceGroups: true }
        )
      };

    case SELECTED_DEVICE_GROUP_FETCHED:
      if (!action.isMultiSelect) {
        return {
          ...state,
          groupSelects: updateGroupSelect(state.groupSelects, action.groupSelectUuid,
            { selectedDeviceGroups: [action.deviceGroup], isLoadingSelectedDeviceGroups: false }
          )
        };
      }

      const extendedListOfSelectedGroups = extendListOfSelectedGroups(
        state,
        action.groupSelectUuid,
        action.deviceGroup);

      return {
        ...state,
        groupSelects: updateGroupSelect(state.groupSelects, action.groupSelectUuid,
          {
            selectedDeviceGroups: extendedListOfSelectedGroups,
            isLoadingSelectedDeviceGroups: false
          }
        )
      };


    case  DEVICE_GROUP_FETCH_FAILED:
      return {
        ...state,
        groupSelects: updateGroupSelect(state.groupSelects, action.groupSelectUuid,
          { isLoadingSelectedDeviceGroups: false }
        )
      };

    case  RESET_GROUP_SELECT:
      return {
        ...state,
        groupSelects: state.groupSelects.filter(groupSelect =>
          (groupSelect.groupSelectUuid !== action.groupSelectUuid))
      };

    case DESELECT_DEVICE_GROUP:
      const reducedList = removeGroupFromSelectedGroups(state, action.groupSelectUuid, action.deviceGroupId);
      return {
        ...state,
        groupSelects: updateGroupSelect(state.groupSelects, action.groupSelectUuid,
          {
            selectedDeviceGroups: reducedList,
            isLoadingSelectedDeviceGroups: false
          }
        )
      };

    default:
      return state;
  }
};


const updateGroupSelect = (
  groupSelects: OneGroupSelectState[],
  groupSelectUuid: string,
  update: Partial<OneGroupSelectState>
): OneGroupSelectState[] => {
  const existGroupSelectState = groupSelects.some(
    groupSelect => groupSelect.groupSelectUuid === groupSelectUuid);
  if (!existGroupSelectState) {
    groupSelects.push(getInitialSingleGroupSelectState(groupSelectUuid));
  }

  return groupSelects.map(groupSelect => {
    if (groupSelect.groupSelectUuid === groupSelectUuid) {
      return { ...groupSelect, ...update };
    }

    return groupSelect;
  });
};

const extendListOfSelectedGroups = (
  currentState: GroupSelectsState,
  groupSelectUuid: string,
  groupToExtendWith: DeviceGroup,
): DeviceGroup[] => {
  const currentGroupSelectState = getGroupSelectState(currentState, groupSelectUuid);
  const extendedListOfSelectedGroups = [
    ...currentGroupSelectState.selectedDeviceGroups,
    groupToExtendWith
  ];

  return extendedListOfSelectedGroups;
};

const removeGroupFromSelectedGroups = (
  currentState: GroupSelectsState,
  groupSelectUuid: string,
  deviceGroupId: string,
): DeviceGroup[] => {
  const currentGroupSelectState = getGroupSelectState(currentState, groupSelectUuid);
  return currentGroupSelectState.selectedDeviceGroups.filter(group => group.id !== deviceGroupId);
};

const getGroupSelectState = (
  state: GroupSelectsState,
  groupSelectUuid: string,
): OneGroupSelectState => {
  const currentGroupSelectState = state.groupSelects.find(
    groupSelect => groupSelect.groupSelectUuid === groupSelectUuid);
  if (!currentGroupSelectState) {
    throw new Error('Group select with given UUID does not exists:' + groupSelectUuid);
  }

  return currentGroupSelectState;
};