import { Dispatch } from 'redux';
import { authenticatedInstance as httpClientInstance, handleApiError } from '../../../api/apiHelpers';
import { deviceReadingsFetched, deviceReadingsFetchFailed, fetchDeviceReadingsAction } from './fetchDeviceReadingsTypes';
import { buildQueryString } from '../../../utils/common';
import { LatestDeviceReadingPerDevice } from '@wiot/shared-domain/models/device-reading/latest-device-reading-per-device';

export const fetchDeviceReadingsActionCreator = (
  idsOfDevices: string[],
) => async (dispatch: Dispatch<any>): Promise<void> => {
  dispatch(fetchDeviceReadingsAction(idsOfDevices));
  try {
    const qs = buildQueryString({ idsOfDevices });
    const url = `/deviceReadings/latestDeviceReadingPerDevice?${ qs }`;

    const { data: latestDeviceReadingOfDevices } = await httpClientInstance.get<LatestDeviceReadingPerDevice[]>(url);
    dispatch(deviceReadingsFetched(latestDeviceReadingOfDevices));
  } catch (error) {
    handleApiError(error);
    dispatch(deviceReadingsFetchFailed(idsOfDevices));
  }
};
