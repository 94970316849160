import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'moment/locale/de';
import 'typeface-open-sans';
import './less/main.less';

import { History } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import { LocalizeContextProps, LocalizeProvider, withLocalize } from 'react-localize-redux';
import { connect, Provider } from 'react-redux';
import { RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import { PersistGate } from 'redux-persist/integration/react';
import moment from 'moment';
import Favicon from 'react-favicon';
import { Route } from 'react-router';

import App from './components/App';
import ResetPassword from './components/ResetPassword/ResetPassword';
import SetPassword from './components/SetPassword/SetPassword';
import { LoginRoute } from './components/shared/LoginRoute';
import { ProtectedRoute } from './components/shared/ProtectedRoute';
import * as serviceWorker from './serviceWorker';
import { AppState } from './state/reducers/rootReducer';
import store, { history, persistor } from './state/store';
import globalTranslations from './translations/global.json';
import keyManagerTranslations from './translations/keymanager.json';
import BrowserRouterWithHistory from './components/BrowserRouterWithHistory';
import { getSelectedLanguage, setDocumentTitle } from './utils/common';
import { setSiteSettings } from './state/actions/setSiteSettingsActions';
import { ISettings, ISite } from '@wiot/shared-domain/models/settings/settings';
import { getSiteSettingsInDB } from './api/apiHelpers';
import api from './api/api';
import LoadingIcon from './components/shared/LoadingIcon';
import DeviceKeyProtectionWizard from './components/DeviceKeyProtection/DeviceKeyDownloadPage';
import RenderOnCondition from './components/RenderOnCondition';
import { getServerFrontendVersion } from './state/actions/getServerFrontendVersionActionCreator';
import OutdatedVersionModal from './components/FrontendVersionCheck/OutdatedVersionModal';
import ValidateUser from './components/User/ValidateUser';
import {
  CONTACT_PATH,
  DEVICE_KEY_PATH,
  IMPRINT_PATH,
  LOGIN_PATH,
  PRIVACY_PATH,
  RESET_PASSWORD_PATH,
  SET_PASSWORD_PATH,
  VALIDATE_USER_PATH,
} from './navigation/paths';
import DeviceKeyDownloadModal from './components/DeviceKeyProtection/DeviceKeyDownloadModal';
import FeedbackModal from './components/Feedback/FeedbackModal';
import LoginPage from './pages/Login/LoginPage';

interface MissingTranslationObject {
  translationId: string;
  languageCode: string;
  defaultTranslation: string;
}

interface IndexProps extends LocalizeContextProps, RouteComponentProps {
  initialize: any;
  history: History;
  isLoggedIn: boolean;
  siteSettings: ISite;
  isDifferentFrontendVersionAvailable: boolean;
  downloadHashOfDKDModal: string;
  isFeedbackModalVisible: boolean;

  login: () => void;
  setSiteSettings: (settings: ISite) => void;
  getServerFrontendContainerVersion: () => void;
}

interface IndexState {
  userIgnoredNewUpdate: boolean;
  isLoading: boolean;
}

class Index extends React.Component<IndexProps, IndexState> {
  constructor(props: IndexProps) {
    super(props);
    this.state = {
      userIgnoredNewUpdate: false,
      isLoading: true,
    };
  }

  onMissingTranslation = ({ translationId, languageCode }: MissingTranslationObject) => {
    console.info(`Missing translationId: ${ translationId } for language: ${ languageCode }`);
    return translationId;
  };

  fetchSiteSettings = async (): Promise<ISettings> => {
    const settings = await getSiteSettingsInDB();

    if (settings?.site) {
      this.props.setSiteSettings(settings.site);
      return settings;
    }

    return settings;
  };

  componentDidMount = async () => {
    const { setActiveLanguage } = this.props;
    const { site } = await this.fetchSiteSettings();
    setDocumentTitle(site?.title);

    this.props.getServerFrontendContainerVersion();

    // one-liner to load customize stylesheet
    document.head.insertAdjacentHTML(
      'beforeend',
      `<link rel="stylesheet" type="text/css" href="${ api.baseAPIUrl }${ api.customizeTheme }">`,
    );

    this.props.initialize({
      languages: [
        { name: 'Deutsch', code: 'de' },
        { name: 'English', code: 'en' },
      ],
      translation: this.props.siteSettings.isKeyManagerModeEnabled
        ? keyManagerTranslations
        : globalTranslations,
      options: {
        renderToStaticMarkup,
        defaultLanguage: 'de',
        onMissingTranslation: this.onMissingTranslation,
      },
    });

    const language = await getSelectedLanguage(site);
    setActiveLanguage(language);
    moment.locale(language);

    this.setState({ isLoading: false });
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingIcon />;
    }

    return (
      <>
        <Switch>
          <LoginRoute path={ LOGIN_PATH } component={ LoginPage } auth={ this.props.isLoggedIn }/>
          <LoginRoute
            path={ RESET_PASSWORD_PATH }
            component={ ResetPassword }
            auth={ this.props.isLoggedIn }
          />
          <LoginRoute path={ SET_PASSWORD_PATH } component={ SetPassword } auth={ this.props.isLoggedIn }/>
          <Route path={ `${ DEVICE_KEY_PATH }/:downloadHash/:prefilledEmail?` } component={ DeviceKeyProtectionWizard }/>
          <Route path={ `${ VALIDATE_USER_PATH }/:userId/:downloadHash` } component={ ValidateUser }/>
          <Route path={ CONTACT_PATH } component={ App }/>
          <Route path={ IMPRINT_PATH } component={ App }/>
          <Route path={ PRIVACY_PATH } component={ App }/>
          <ProtectedRoute path="/" component={ App } auth={ this.props.isLoggedIn }/>
        </Switch>
        <RenderOnCondition
          condition={ this.props.isDifferentFrontendVersionAvailable && !this.state.userIgnoredNewUpdate }
        >
          <OutdatedVersionModal
            closeModal={ () => {
              this.setState({ userIgnoredNewUpdate: true });
            } }
          />
        </RenderOnCondition>
        <RenderOnCondition condition={ this.props.downloadHashOfDKDModal }>
          <DeviceKeyDownloadModal/>
        </RenderOnCondition>
        <RenderOnCondition condition={ this.props.isFeedbackModalVisible }>
          <FeedbackModal/>
        </RenderOnCondition>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.isLoggedIn,
  siteSettings: state.siteSettings,
  isDifferentFrontendVersionAvailable: state.versioning.isDifferentFrontendVersionAvailable,
  downloadHashOfDKDModal: state.deviceKeyProtection.downloadHashOfDKDModal,
  isFeedbackModalVisible: state.feedback?.isVisible,
});

const mapDispatchToProps = (dispatch: any) => ({
  setSiteSettings: (settings: ISite) => dispatch(setSiteSettings(settings)),
  getServerFrontendContainerVersion: () => dispatch(getServerFrontendVersion()),
});

const IndexWithLogin = withLocalize<any>(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Index)),
);

ReactDOM.render(
  <Provider store={ store }>
    <PersistGate loading={ <LoadingIcon/> } persistor={ persistor }>
      <Favicon url={ `${ api.baseAPIUrl }${ api.customizeFavIcon }` }/>
      <LocalizeProvider store={ store }>
        <BrowserRouterWithHistory>
          <IndexWithLogin/>
        </BrowserRouterWithHistory>
      </LocalizeProvider>
      <ReduxToastr
        preventDuplicates
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.register();
