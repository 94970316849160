import { DeviceKeyPasswordRequest } from '@wiot/shared-domain/models/device/device';
import { UserDetails } from '@wiot/shared-domain/models/auth/auth-api';
import { DownloadDetails } from '@wiot/shared-domain/models/device-key/download-details';
import { ProtectionDetails } from '@wiot/shared-domain/models/device-key/protection-details';
import { authenticatedInstance as httpClientInstance, handleApiError } from './apiHelpers';

export const getDeviceKeyProtectionDetails = async (
  downloadHash: string,
): Promise<ProtectionDetails | null> => {
  try {
    const url = `/deviceKeyProtectionDetails/${ downloadHash }`;
    const { data } = await httpClientInstance.get<ProtectionDetails>(url);
    return data;
  } catch (error) {
    handleApiError(error, false);
    return null;
  }
};

export const getDeviceKeyDownloadDetails = async (
  downloadHash: string,
): Promise<DownloadDetails> => {
  try {
    const url = `/deviceKeyDownloadDetails/${ downloadHash }`;
    const { data } = await httpClientInstance.get<DownloadDetails>(url);
    return data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const createAnonymousUserAsDevicePassword = async (
  downloadHash: string,
  password: string,
  email?: string,
): Promise<UserDetails> => {
  try {
    const request: DeviceKeyPasswordRequest = {
      downloadHash,
      password,
      email,
    };

    const url = '/createDeviceKeyPassword';
    const { data: userDetails } = await httpClientInstance.post<UserDetails>(url, request);

    return userDetails;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

