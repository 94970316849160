import React, { useEffect } from 'react';
import { Translate } from 'react-localize-redux';
import onClickOutside from 'react-onclickoutside';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect, useDispatch, useSelector } from 'react-redux';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import { AggregatedPermission } from '@wiot/shared-domain/models/role/role';
import { OutsideClickHandler } from '../../../components/OutsideClickHandler';
import HasPermission from '../../../components/HasPermission';
import { AppState } from '../../../state/reducers/rootReducer';
import RenderOnCondition from '../../../components/RenderOnCondition';
import TableMenuCloseButton from '../../../components/Table/TableMenuCloseButton';
import { closeContextMenu, openContextMenu } from '../../../state/context-menu/toggleContextMenuActionCreators';

export interface TableMenuProps {
  menuId: string;
  deviceType: IDeviceType;
  refreshTreeData?: () => void;
  permission?: AggregatedPermission;
  toggleDetails: (event: React.MouseEvent) => void;
  toggleDeviceGroupActionModal: (event: React.MouseEvent) => void;
  toggleDeleteModal: (event: React.MouseEvent) => void;
  isMobileDisplay?: boolean;
}

const TableMenu = (props: TableMenuProps) => {
  const {
    isMobileDisplay,
    menuId,
    toggleDetails,
    toggleDeviceGroupActionModal,
    toggleDeleteModal,
    permission,
  } = props;

  const tdRef = React.createRef<HTMLDivElement>();

  const dispatch = useDispatch();

  const closeMenu = (event?: React.MouseEvent) => {
    event && event.preventDefault();
    dispatch(closeContextMenu());
  }

  useEffect(() => {
    dispatch(openContextMenu(menuId));
  }, []);

  useEffect(() => {
    const targetElement = tdRef.current;
    isMobileDisplay && disableBodyScroll(targetElement);

    return () => {
      isMobileDisplay && enableBodyScroll(targetElement);
    };
  }, [isMobileDisplay, tdRef]);

  const handleClickOutside = (event: React.MouseEvent) => {
    closeMenu(event);
  };

  const handleMenuButtonClick = (
    event: React.MouseEvent,
    action: (event: React.MouseEvent) => void,
  ) => {
    closeMenu(event);
    action(event);
  };

  return (
    <OutsideClickHandler onClickOutside={ handleClickOutside }>
      <div
        className="td-menu"
        ref={ tdRef }
      >
        <TableMenuCloseButton closeMenu={ handleClickOutside } />

        <HasPermission permissionObj={ permission } permissionKey="deviceTypes.edit">
          <RenderOnCondition condition={ !props.deviceType?.isSystem }>
            <>
              <button
                className="td-menu__item"
                onClick={ (e) => handleMenuButtonClick(e, toggleDeviceGroupActionModal) }
                data-testid="update-device"
              >
                <div className="td-menu__item__icon">
                  <FontAwesomeIcon icon={ faEdit } />
                </div>
                <Translate id="update" />
              </button>
            </>
          </RenderOnCondition>
        </HasPermission>

        <HasPermission permissionObj={ permission } permissionKey="deviceTypes.remove">
          <RenderOnCondition condition={ !props.deviceType?.isSystem }>
            <>
              <button
                className="td-menu__item"
                onClick={ (e) => handleMenuButtonClick(e, toggleDeleteModal) }
              >
                <div className="td-menu__item__icon">
                  <FontAwesomeIcon icon={ faTrashAlt } />
                </div>
                <Translate id="remove" />
              </button>
            </>
          </RenderOnCondition>
        </HasPermission>
      </div>
    </OutsideClickHandler>
  );
};

const mapStateToProps = (state: AppState) => ({
  permission: state.currentUser.permission,
});

export default connect(mapStateToProps)(onClickOutside(TableMenu));
