import React, { useState } from 'react';
import { withLocalize, LocalizeContextProps, Translate } from 'react-localize-redux';
import { HorizontalDivider } from '../../../components/shared/Divider';
import CustomImageInputWrapper from './CustomImageInputWrapper';

interface ISiteCustomizationsProps extends LocalizeContextProps {
  readOnly: boolean | undefined;
}

const MAX_LOGOSIZE = 100000;
const MIN_LOGOSIZE = 1000;
const MAX_WALLPAPERSIZE = 500000;
const MIN_WALLPAPERSIZE = 10000;
const MAX_FAVICONSIZE = 50000;
const MIN_FAVICONSIZE = 100;
const MAX_BRANDINGSIZE = 100000;
const MIN_BRANDINGSIZE = 1000;

const SiteCustomizations = ({ readOnly = true }: ISiteCustomizationsProps) => {
  const [logo, setLogo] = useState<File[]>();
  const [wallpaper, setWallpaper] = useState<File[]>();
  const [favicon, setFavicon] = useState<File[]>();
  const [branding, setBranding] = useState<File[]>();

  const getLogoDropzoneOptions = () => ({
    multiple: false,
    maxSize: MAX_LOGOSIZE,
    minSize: MIN_LOGOSIZE,
    onDrop: setLogo,
    accept: 'image/jpeg, image/jpg, image/gif, image/png',
  });

  const getWallpaperDropzoneOptions = () => ({
    multiple: false,
    maxSize: MAX_WALLPAPERSIZE,
    minSize: MIN_WALLPAPERSIZE,
    onDrop: setWallpaper,
    accept: 'image/jpeg, image/jpg, image/gif, image/png',
  });

  const getBrandingDropzoneOptions = () => ({
    multiple: false,
    maxSize: MAX_BRANDINGSIZE,
    minSize: MIN_BRANDINGSIZE,
    onDrop: setBranding,
    accept: 'image/jpeg, image/jpg, image/gif, image/png',
  });

  const getFaviconDropzoneOptions = () => ({
    multiple: false,
    maxSize: MAX_FAVICONSIZE,
    minSize: MIN_FAVICONSIZE,
    onDrop: setFavicon,
    accept: 'image/x-icon',
  });

  const getFileUrl = (image: File[] | undefined) => {
    if (image?.length) {
      return URL.createObjectURL(image[0]);
    }
    return '';
  };

  return (
    <>
      <HorizontalDivider />
      <div className="settings-header">
        <Translate id="site-settings-customizations-header" />
      </div>
      <CustomImageInputWrapper
        images={logo}
        options={getLogoDropzoneOptions()}
        src={getFileUrl(logo)}
        file="logo"
        readOnly={readOnly}
      />
      <HorizontalDivider />
      <CustomImageInputWrapper
        images={wallpaper}
        options={getWallpaperDropzoneOptions()}
        src={getFileUrl(wallpaper)}
        file="wallpaper"
        readOnly={readOnly}
      />
      <HorizontalDivider />
      <CustomImageInputWrapper
        images={branding}
        options={getBrandingDropzoneOptions()}
        src={getFileUrl(branding)}
        file="branding"
        readOnly={readOnly}
      />
      <HorizontalDivider />
      <CustomImageInputWrapper
        images={favicon}
        options={getFaviconDropzoneOptions()}
        src={getFileUrl(favicon)}
        file="favicon"
        readOnly={readOnly}
      />
    </>
  );
};

export default withLocalize(SiteCustomizations);
