import React, { useEffect } from 'react';
import { Translate } from 'react-localize-redux';
import { NavLink } from 'react-router-dom';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { useSelector } from 'react-redux';

import { AppState } from '../../state/reducers/rootReducer';
import Mobile from '../Responsive/Mobile';
import Desktop from '../Responsive/Desktop';
import VersionIndicator from '../FrontendVersionCheck/VersionIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAvailableNavigationLinks } from '../../navigation/navigation-item-provider';

import { OpenFeedbackModalNavItem } from '../Feedback/OpenFeedbackModalNavItem';
import RenderOnCondition from '../RenderOnCondition';

interface INavbarProps {
  showNavigationLinks?: boolean;
}

function Navbar({
  showNavigationLinks = true
}: INavbarProps) {
  const userPermission = useSelector((state: AppState) => state.currentUser.permission);
  const isMobileDisplay = useSelector((state: AppState) => state.isMobileDisplay);
  const isKeyManagerModeEnabled = useSelector((state: AppState) => state.siteSettings.isKeyManagerModeEnabled);
  const isFeedbackEnabled = useSelector((state: AppState) => state.siteSettings.support?.isFeedbackEnabled);

  const targetRef = React.createRef<HTMLElement>();
  const navigationLinks = showNavigationLinks ?
    getAvailableNavigationLinks(isMobileDisplay, userPermission, isKeyManagerModeEnabled) : [];


  useEffect(() => {
    const targetElement = targetRef.current;
    isMobileDisplay && disableBodyScroll(targetElement);
    return () => {
      isMobileDisplay && clearAllBodyScrollLocks(targetElement);
    };
  }, [isMobileDisplay, targetRef]);

  return (
    <nav ref={ targetRef } className="navbar-left" data-testid="navbar-left">
      <div className="navbar-left__container-top">
        { navigationLinks.map(({ path, title, svgIcon, icon }) => (
          <NavLink
            to={ `/${ path }` }
            className="navbar-left__container-top__item"
            activeClassName="navbar-left__container-top__item--active background-color-main"
            data-testid={ `navbar-${ path }` }
            key={ path }
          >
            <span className="navbar-left__container-top__item__text"> {
              icon !== undefined
                ? (<FontAwesomeIcon icon={ icon }/>)
                : (<img src={ svgIcon } className="navbar-left__container-top__item__text__icon svg-white"/>)
            }
            </span>
            <Mobile>
              <span className="navbar-left__container-top__item__mobile">
                <Translate id={ title }/>
              </span>
            </Mobile>
            <Desktop>
              <span className="collapsible-content">
                <Translate id={ title }/>
              </span>
            </Desktop>
          </NavLink>

        ))}
      </div>
      <div className="navbar-left__container-bottom">
        <RenderOnCondition condition={ isFeedbackEnabled }>
          <OpenFeedbackModalNavItem />
        </RenderOnCondition>
        <VersionIndicator />
      </div>
    </nav>
  );
}

export default Navbar;
