import { ILegalDetails } from '@wiot/shared-domain/models/settings/settings';
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../state/reducers/rootReducer';
import ContactFormContainer from '../../components/LegalDetails/ContactFormContainer';
import RenderOnCondition from '../../components/RenderOnCondition';
import LegalDetailsContent from '../../components/LegalDetails/LegalDetailsContent';

interface IContactProps {
  legalDetails?: ILegalDetails;
}

const Contact = ({ legalDetails }: IContactProps) => {
  const getContentString = () => {
    if (legalDetails && legalDetails.contact) {
      return legalDetails.contact.custom || legalDetails.contact.default;
    }
    return '';
  };
  
  return (
    <>
      <LegalDetailsContent contentString={getContentString()} />
      <RenderOnCondition condition={legalDetails?.contact.showForm}>
        <ContactFormContainer />
      </RenderOnCondition>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  legalDetails: state.siteSettings.legalDetails,
});

export default connect(mapStateToProps)(Contact);
