import { DeviceGroup } from '../device-group/device-group';
import { Device, DeviceCreation } from '../device/device';
import { IUser } from '../user/user';
import { DeviceReading } from '@wiot/shared-domain/models/device-reading/device-reading';
import { InvalidArrayRecord } from '@wiot/shared-domain/models/utils/invalid-array-record';
import { RequestInfo, RequestInit, Response } from 'node-fetch';

export const pullFrequencyOptions = [
  { id: 0, name: 'never' },
  { id: 300, name: 'every-5-minutes' },
  { id: 600, name: 'every-10-minutes' },
  { id: 900, name: 'every-15-minutes' },
  { id: 1800, name: 'every-30-minutes' },
  { id: 3600, name: 'every-hour' },
  { id: 7200, name: 'every-2-hours' },
  { id: 21600, name: 'every-6-hours' },
  { id: 43200, name: 'every-12-hours' },
  { id: 64800, name: 'every-18-hours' },
  { id: 86400, name: 'every-24-hours' },
  { id: 129600, name: 'every-36-hours' },
  { id: 172800, name: 'every-2-days' },
  { id: 259200, name: 'every-3-days' },
  { id: 432000, name: 'every-5-days' },
  { id: 604800, name: 'every-week' },
  { id: 1209600, name: 'every-2-weeks' },
  { id: 2592000, name: 'every-month' },
];

export const authMethodOptions = ['none', 'basic'];

export interface DataIntegrationJob {
  id?: any;
  userId?: any;
  name?: string;
  apiPath?: string;
  authMethod?: string;
  username?: string;
  passphrase?: string;
  pullFrequency?: number;
  active?: boolean;
  class?: string;
  json?: string;
  createdAt?: Date;
  updatedAt?: Date;
  executed?: {
    at: Date;
    response: any;
  }[];
  deviceGroup?: DeviceGroup;
}

export interface DataIntegrationJobExtended extends DataIntegrationJob {
  checked?: boolean;
}

export interface DataIntegrationJobs {
  dataIntegrationJobs: DataIntegrationJob[];
  totalPages?: number | any[];
  totalDocs?: number | any[];
}

export type CsvValidationResult = {
  users: InvalidArrayRecord[];
  devices: InvalidArrayRecord[];
}

export interface CsvImportResult {
  validationResult: CsvValidationResult;
  newObjects: {
    deviceGroups: DeviceGroup[][];
    users: IUser[];
    devices: Device[];
    deviceReadings: DeviceReading[];
  };
}

export type CsvImportRowResult = {
  user: IUser | null;
  deviceGroups: DeviceGroup[] | null;
  device: Device | DeviceCreation | null;
  deviceReading: DeviceReading | null;
}

export enum DataIntegrationFormats {
  CSV = 'csv',
  XML = 'xml',
  FTP = 'ftp',
}

export enum DataIntegrationApiFormats {
  MyDatanet = 'mydatanet',
  CasametaCloud = 'casametacloud',
  LoraTTN = 'lorattn',
}

export type CombinedDataIntegrationFormats = DataIntegrationFormats | DataIntegrationApiFormats;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CombinedDataIntegrationFormats = {
  ...DataIntegrationFormats,
  ...DataIntegrationApiFormats,
};

export interface BlobWithPath extends Blob {
  path: string;
}

export enum XmlDocumentTypes {
  OMSKeyExchange = 'OMSKeyExchange',  // OMS key exchange file
  Road = 'Road',  // Sontex 637 road file
}

export enum ImportOption {
  DO_NOT_CREATE_DEVICE_GROUPS = 'DoNotCreateDeviceGroups',
  OVERRIDE_DEVICE_GROUPS = 'OverrideDeviceGroups',
}

export interface ImportRequestOptions {
  masterKey?: string;
  deviceGroup?: string;
  importOption?: ImportOption;
  filename: string;
}

export type IDataIntegrationTestFunction = (
  fetch: (url: RequestInfo, init?: RequestInit | undefined) => Promise<Response>,
  url: string,
  user: string,
  passphrase: string
) => Promise<IDataIntegrationTestFunctionResult>;

export interface IDataIntegrationTestFunctionResult {
  success: boolean,
  error: string
}

export interface IDataIntegrationConfig {
  apiPlaceholder: string;
  authMethod: string[];
  usernameDisplay: string;
  passPhraseDisplay: string;
  usernameDisabled: boolean;
  // Test functions in src/api/helpers/data-integration-job-apis/test-api.ts
  isTestable?: boolean;
}

export const DataIntegrationConfigs: Record<DataIntegrationApiFormats, IDataIntegrationConfig> = {
  [DataIntegrationApiFormats.MyDatanet]: {
    apiPlaceholder: 'https://WEBSITE_URL/api/1/',
    authMethod: ['basic'],
    usernameDisplay: 'username',
    passPhraseDisplay: 'password',
    usernameDisabled: false,
    isTestable: true,
  },
  [DataIntegrationApiFormats.CasametaCloud]: {
    apiPlaceholder: 'https://<$SVC>/vBox/Daten',
    authMethod: ['basic'],
    usernameDisplay: 'username',
    passPhraseDisplay: 'password',
    usernameDisabled: false,
    isTestable: true,
  },
  [DataIntegrationApiFormats.LoraTTN]: {
    apiPlaceholder: 'ttn://<SERVER>:<PORT>?<PARAMS>',
    authMethod: ['basic'],
    usernameDisplay: 'user-id',
    passPhraseDisplay: 'api_key',
    usernameDisabled: false,
    isTestable: true,
  },
};
