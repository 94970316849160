import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import useTranslation from '../../../../hooks/useTranslation';
import { OptionTypeBase } from 'react-select';

interface MenuButtonProps<OptionType extends OptionTypeBase> {
  handleSelectAllClick: (event: React.MouseEvent, options?: OptionType[]) => void;
  handleSelectNoneClick: (event: React.MouseEvent) => void;
  filteredOptions?: OptionType[];
  defaultOptionsCount?: number;
  isDisabled?: boolean;
}

export const MenuButtons = <OptionType extends OptionTypeBase> (props: MenuButtonProps<OptionType>) => {
  const {
    handleSelectAllClick,
    handleSelectNoneClick,
    filteredOptions,
    defaultOptionsCount,
    isDisabled = false,
  } = props;

  const translate = useTranslation();

  const filteredOptionsCount = filteredOptions?.length || 0;
  const getSelectAllLabel = () => {
    if (filteredOptionsCount && filteredOptionsCount > 0 && defaultOptionsCount && defaultOptionsCount > 0) {
      if (filteredOptionsCount === defaultOptionsCount) {
        return `${ translate('all').toString() } ${ filteredOptionsCount }`;
      }
      return `${ filteredOptionsCount } ${ translate('of').toString() } ${ defaultOptionsCount }`
    }
    return `${ translate('all').toString() }${ defaultOptionsCount && defaultOptionsCount > 0 ? ` ${ defaultOptionsCount }` : '' }`;
  }

  return (
    <Row className="select__button-row">
      <Col
        lg={ 6 }
        xs={ 6 }
        onClick={ isDisabled
            ? () => { }
            : (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => handleSelectAllClick(e, filteredOptions) }
        className={ `select__button select__button-${ isDisabled ? 'disabled' : 'enabled' }` }
      >
        <FontAwesomeIcon icon={ faCheckSquare } className="mr-2" />
        { getSelectAllLabel() }
      </Col>
      <Col
        lg={ 6 }
        xs={ 6 }
        onClick={ isDisabled ? () => { } : handleSelectNoneClick }
        className={ `select__button select__button-${ isDisabled ? 'disabled' : 'enabled' }` }
      >
        <FontAwesomeIcon icon={ faSquare } className="mr-2" />
        { `${ translate('none').toString() }` }
      </Col>
    </Row>
  );
}
