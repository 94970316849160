import { Dispatch } from 'redux';
import { setFilter } from './set-filter-action-creator';

export const updateDeviceGroupFilter = () => (
  dispatch: Dispatch<any>,
): void => {
  const urlParams = new URLSearchParams(window.location.search);
  const deviceGroupType = urlParams.get('deviceGroup');
  if (deviceGroupType) {
    dispatch(
      setFilter({
        page: 'device-groups',
        values: {
          type: deviceGroupType,
        },
      }),
    );
  }
};
