import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faMagic } from '@fortawesome/free-solid-svg-icons';
import LoadingIcon from '../shared/LoadingIcon';

interface ThumbnailsProps {
  files: (string | File)[];
  removeFile?: (index: number) => void;
  ocrFile?: (index: number, path: string) => void;
  toggleGallery?: (index: number) => void;
  toggleOcrLoading?: () => void;
  toggleOcrWizard?: () => void;
  isOcrLoading?: boolean;
  setCurrentFile?: (file: string) => void;
}

class Thumbnails extends React.Component<ThumbnailsProps, never> {
  getFileUrl = (file: File | string): string => {
    if (typeof file === 'string') {
      return file;
    }
    return URL.createObjectURL(file);
  };

  onOcrFileClick = (file: File | string, index: number): void => {
    const { toggleOcrWizard, toggleOcrLoading, ocrFile, setCurrentFile } = this.props;

    toggleOcrWizard && toggleOcrWizard();
    setCurrentFile && setCurrentFile(this.getFileUrl(file));
    toggleOcrLoading && toggleOcrLoading();

    typeof file === 'object' && ocrFile && ocrFile(index, this.getFileUrl(file));
  };

  render() {
    const { files, ocrFile, isOcrLoading, removeFile, toggleGallery } = this.props;
    if (files) {
      return (
        <Row className="p-0">
          {files.map((file, i: number) => (
            <Col xs={4} sm={3} lg={2} key={i} style={{ paddingTop: '15px' }}>
              {typeof file !== 'string' && isOcrLoading && <LoadingIcon />}

              {typeof file !== 'string' && ocrFile && !isOcrLoading && (
                <span
                  className="thumbnail_icon thumbnail_icon__ocr text-color-main"
                  onClick={() => this.onOcrFileClick(file, i)}
                >
                  <FontAwesomeIcon icon={faMagic} />
                </span>
              )}

              {removeFile && !isOcrLoading && (
                // @ts-ignore
                <span
                  className="thumbnail_icon thumbnail_icon__remove"
                  onClick={() => removeFile(i)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </span>
              )}

              <Image
                onClick={() => {
                  toggleGallery && toggleGallery(i);
                }}
                className="thumbnail"
                thumbnail
                src={this.getFileUrl(file)}
              />
            </Col>
          ))}
        </Row>
      );
    }
    return null;
  }
}

export default Thumbnails;
