import { Ancestor, DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { stringTruncateFromCenter } from './common';
import { DeviceGroupPath } from './device-group-path';
import { Device } from '@wiot/shared-domain/models/device/device';

export const GROUP_PATH_DELIMITER = '  ›  ';
export const NO_ANCESTORS_PATH: DeviceGroupPath = { fullPath: '', truncatedPath: '' };
export const GROUP_PATH_MAX_LENGTH= 105;

export const getDeviceGroupPath = (
  deviceGroup: DeviceGroup,
): DeviceGroupPath => {
  let ancestors = deviceGroup.ancestors || [];
  ancestors = addDeviceGroupToAncestors(ancestors, deviceGroup);

  return getDeviceGroupPathFromAncestors(ancestors);
};

export const getDeviceGroupPathFromAncestors = (
  ancestors: Ancestor[],
): DeviceGroupPath => {
  const hasAncestors = ancestors && ancestors.length > 0;
  if (!hasAncestors) {
    return NO_ANCESTORS_PATH;
  }

  const fullPath = ancestors.map((ancestor) => ancestor.name).join(GROUP_PATH_DELIMITER);
  const truncatedPath = createTruncatedDeviceGroupPath(ancestors);

  return { truncatedPath: truncatedPath, fullPath: fullPath };
};

export const getDeviceGroupPathOfDevice = (
  device:Device,
): DeviceGroupPath => {
  const { deviceGroupAncestors: deviceGroupAncestorNames, deviceGroup } = device;
  if (deviceGroupAncestorNames) {
    let ancestors: Ancestor[] = deviceGroupAncestorNames.map((ancestorName) => ({ name: ancestorName }));
    ancestors = addDeviceGroupToAncestors(ancestors, deviceGroup);

    return getDeviceGroupPathFromAncestors(ancestors);
  }

  if (deviceGroup) {
    return getDeviceGroupPath(deviceGroup);
  }

  return NO_ANCESTORS_PATH;
};

const createTruncatedDeviceGroupPath = (
  ancestors: Ancestor[],
): string => {
  let truncatedPath = createTruncatedPathFromAncestors(ancestors);
  if (truncatedPath.length > GROUP_PATH_MAX_LENGTH) {
    // Begin truncate with maxLength = 50 for every ancestor's name
    for (let maxAncestorLength = 50; maxAncestorLength > 7; maxAncestorLength -= 2) {
      truncatedPath = createTruncatedPathFromAncestors(ancestors, maxAncestorLength);
      if (truncatedPath.length < GROUP_PATH_MAX_LENGTH) {
        break;
      } // Reduce maxLength with each loop until truncatedPath.length < GROUP_PATH_MAX_LENGTH
    }
  }
  return truncatedPath;
};

const createTruncatedPathFromAncestors = (ancestors: Ancestor[], maxAncestorLength?: number): string =>
  ancestors.map((ancestor) => getAncestorStringValue(ancestor, maxAncestorLength)).join(GROUP_PATH_DELIMITER);

const getAncestorStringValue = (
  ancestor: Ancestor,
  maxAncestorLength?: number
): string => {
  const ancestorStringValue = ancestor.abbreviation || ancestor.name;
  if (!maxAncestorLength || ancestorStringValue.length < maxAncestorLength) {
    return ancestorStringValue;
  }

  return stringTruncateFromCenter(ancestor.name, maxAncestorLength);
};

const addDeviceGroupToAncestors = (
  ancestors: Ancestor[],
  deviceGroup?: DeviceGroup,
): Ancestor[] => {
  const newAncestorArray: Ancestor[] = JSON.parse(JSON.stringify(ancestors));
  if (deviceGroup?.name) {
    newAncestorArray.push(
      {
        name: deviceGroup?.name,
        abbreviation: deviceGroup?.abbreviation
      });
  }

  return newAncestorArray;
};
