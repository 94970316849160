import {
  DefaultFormatMeasurementOptions
} from "@wiot/shared-domain/models/domain/services/export/export-providers/default-format/default-format.model";
import {
  DeviceExportFormats,
  ExportFileExtension,
  IExportFormat
} from "@wiot/shared-domain/models/domain/services/export/export.models";

export type DeviceExportFormikValues = Partial<DefaultFormatMeasurementOptions> & { exportFormat: IExportFormat };

export const DeviceExportFormatList: IExportFormat[] = [
  {
    name: 'mfair-connect-csv-1-0-0',
    value: DeviceExportFormats.CSV_MFAIR_CONNECT_V_1_0_0,
    extension: ExportFileExtension.CSV,
  },
  {
    name: 'mfair-connect-json-1-0-0',
    value: DeviceExportFormats.JSON_MFAIR_CONNECT_V_1_0_0,
    extension: ExportFileExtension.JSON,
  },
  {
    name: 'absys-json-1-0-0',
    value: DeviceExportFormats.JSON_ABSYS_V_1_0_0,
    extension: ExportFileExtension.JSON,
  },
  {
    name: 'casameta-vbox-1-1-3',
    value: DeviceExportFormats.JSON_CASAMETA_VBOX_V_1_1_3,
    extension: ExportFileExtension.JSON,
  },
  {
    name: 'engelmann-v-2-0-1-header-1',
    value: DeviceExportFormats.CSV_ENGELMANN_V_2_0_1_HEADER_1,
    extension: ExportFileExtension.CSV,
  },
  {
    name: 'json-default-format-v-1-0-0',
    value: DeviceExportFormats.JSON_DEFAULT_FORMAT_V_1_0_0,
    extension: ExportFileExtension.JSON,
    inputFieldsToDisplay: {
      frequency: true,
      attributeIds: true,
      includeLastPrimaryMeasurement: true,
      includeDueDateMeasurement: true,
      includeLastGateways: true,
    },
  },
];
