import { DeviceViews, DisplayTypes } from '@wiot/shared-domain/models/device/device';
import { DEVICE_KEY_ASSIGNED_TO_USER } from '../device-key/deviceKeyActionTypes';
import {
  ChangeContentDisplayAction,
  ChangeVisibleViewAction,
  RESET_DEVICE_MANAGER_STATE,
  ResetDeviceManagerStateAction,
  SET_DEVICE_MANAGER_CONTENT_DISPLAY,
  SET_DEVICE_MANAGER_DEVICES_ARE_OUTDATED,
  SET_DEVICE_MANAGER_VISIBLE_VIEW,
  SetDeviceManagerDevicesAreOutdatedAction
} from './deviceManagerStateAction';
import { DeviceKeyAssignedToUserAction } from '../device-key/assignDeviceKeyToCurrentUserActions';
import { DEVICES_FETCHED, DevicesFetchedAction } from '../device/devicesActions';

export interface IDeviceManagerState {
  visibleView: DeviceViews;
  contentDisplay: DisplayTypes;
  devicesAreOutdated: boolean;
}

const initialState: IDeviceManagerState = {
  visibleView: DeviceViews.TABLE,
  contentDisplay: DisplayTypes.ASSIGNED,
  devicesAreOutdated: false,
};

type DeviceManagerActions =
  | ChangeVisibleViewAction
  | ChangeContentDisplayAction
  | ResetDeviceManagerStateAction
  | SetDeviceManagerDevicesAreOutdatedAction
  | DeviceKeyAssignedToUserAction
  | DevicesFetchedAction;

export const deviceManagerStateReducer = (
  state = initialState,
  action: DeviceManagerActions,
): IDeviceManagerState => {
  switch (action.type) {
    case DEVICE_KEY_ASSIGNED_TO_USER:
      return {
        ...state,
        devicesAreOutdated: true,
      };

    case DEVICES_FETCHED:
      return {
        ...state,
        devicesAreOutdated: false,
      };

    case SET_DEVICE_MANAGER_DEVICES_ARE_OUTDATED:
      return {
        ...state,
        devicesAreOutdated: action.payload,
      };

    case SET_DEVICE_MANAGER_VISIBLE_VIEW:
      return <IDeviceManagerState>{
        ...state,
        visibleView: action.payload,
      };

    case SET_DEVICE_MANAGER_CONTENT_DISPLAY:
      return <IDeviceManagerState>{
        ...state,
        contentDisplay: action.payload,
      };

    case RESET_DEVICE_MANAGER_STATE:
      return <IDeviceManagerState>{
        ...initialState,
      };
    default:
      return state;
  }
};
