import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import React from 'react';
import PrimaryButton from '../../../components/shared/PrimaryButton';

export function DownloadConfirmation(props: { onClick: () => void }) {
  return <>
    <Row className="my-3">
      <Col>
        <Translate id="download-billing-export"/>
      </Col>
    </Row>
    <div className="d-flex justify-content-end mt-2">
      <PrimaryButton onClick={ props.onClick } textTranslationId="done"/>
    </div>
  </>;
}