import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { getIn } from 'formik';
import { useSelector } from 'react-redux';
import { IRole } from '@wiot/shared-domain/models/role/role';
import CustomToggle from '../../../components/Table/CustomToggle';
import { SetFieldValue } from '../../../state/types';
import { getRoleSectionStructure } from '../../../utils/common';
import { AppState } from '../../../state/reducers/rootReducer';
import HasPermission from '../../../components/HasPermission';

interface INestedRoleGroup {
  groupKey: string;
  values: IRole;
  readOnly?: boolean;
  setFieldValue: SetFieldValue;
  groupToggleChange?: (
    name: string,
    value: boolean,
    setFieldValue: SetFieldValue,
    values: IRole,
  ) => void;
}

const NestedRoleGroup = ({
  groupKey,
  values,
  readOnly,
  setFieldValue,
  groupToggleChange,
}: INestedRoleGroup) => {
  const userPermission = useSelector<AppState>((state) => state.currentUser.permission);
  const groupPath = groupKey.split('.');
  const groupObj = getIn(values, groupPath);
  const isKeyManagerModeEnabled = useSelector((state: AppState) => !!state.siteSettings.isKeyManagerModeEnabled);
  const structureObj = getRoleSectionStructure(groupPath, groupKey, isKeyManagerModeEnabled);
  const nestedValue = groupObj && Object.values(groupObj).every((val) => val);

  return (
    <Row className="justify-content-start pt-3">
      <Col lg={ 12 } className="pt-2">
        <CustomToggle
          value={ nestedValue }
          onChange={ setFieldValue }
          name={ groupKey }
          label={ groupPath[groupPath.length - 1] }
          groupToggleChange={ groupToggleChange }
          values={ values }
          readOnly={ readOnly }
        />
      </Col>
      { Object.keys(structureObj).map((key, index) => {
        const value = getIn(groupObj, key);
        const name = `${ groupKey }.${ key }`;
        return (
          <HasPermission
            key={ index }
            permissionObj={ userPermission }
            permissionKey={ name }
            skipCheck={ readOnly || groupKey.startsWith('deviceType') }
          >
            <Col lg={ 4 } key={ index } className="pt-2">
              <CustomToggle
                value={ value }
                onChange={ setFieldValue }
                name={ name }
                label={ key }
                readOnly={ readOnly }
              />
            </Col>
          </HasPermission>
        );
      }) }
    </Row>
  );
};

export default NestedRoleGroup;
