import { combineReducers } from 'redux';
import { serverFrontendVersionReducer } from './serverFrontendVersionReducer';
import { isDifferentFrontendVersionAvailableReducer } from './isDifferentFrontendVersionAvailableReducer';

export type VersioningState = {
  serverFrontendVersion: string;
  isDifferentFrontendVersionAvailable: boolean;
};

const versioningReducer = combineReducers({
  serverFrontendVersion: serverFrontendVersionReducer,
  isDifferentFrontendVersionAvailable: isDifferentFrontendVersionAvailableReducer,
});

export default versioningReducer;
