import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import './DateRangePickerRow.less';
import { IsoStringDateRange } from '@wiot/shared-domain/models/date/iso-string-date-range';
import { DATE_PICKER_PORTAL_ID } from './date-picker-portal-id';

interface IDateRangePickerRowProps extends LocalizeContextProps {
  dateRange: IsoStringDateRange;
  onChange?: (prevDateRange: IsoStringDateRange, newDateRange: IsoStringDateRange) => void;
  deleteButtonClicked?: (dateRange: IsoStringDateRange) => void;
  addButtonClicked?: (dateRange: IsoStringDateRange) => void;
  portalTargetId?: string;
  readOnly?: boolean;
}

export const DateRangePickerRow = withLocalize(
  ({
    dateRange,
    deleteButtonClicked,
    addButtonClicked,
    onChange,
    translate,
    portalTargetId = DATE_PICKER_PORTAL_ID,
    readOnly = false,
  }: IDateRangePickerRowProps): JSX.Element => {
    const [isMouseOverDeleteButton, setIsMouseOverDeleteButton] = useState(false);

    function getDatePickerColumn(
      translationTerm: string,
      isoDateString: string | null | undefined,
      onDateChange: (date: Date | [Date | null, Date | null] | null) => void,
    ) {
      return (
        <Col lg={ 5 }
             className="date-range-picker-row__date-picker"
        >
          <ReactDatePicker
            readOnly={ readOnly }
            className={`modal-control cursor-pointer ${ readOnly ? 'input--readonly' : '' }`}
            calendarClassName="select__menu"
            selected={ isoDateString ? new Date(isoDateString) : null }
            dateFormat="dd.MM.yyyy"
            placeholderText={ translate(translationTerm).toString() }
            onChange={ onDateChange }
            portalId={ portalTargetId }
          />
        </Col>
      );
    }

    function getButtonsColumn() {
      return (
        <Col lg={ 1 } className="date-range-picker-row__buttons-column d-flex align-items-center">
          { deleteButtonClicked && (
            <Button
              className="form__icon bg-transparent border-0"
              onClick={ () => deleteButtonClicked(dateRange) }
              onMouseOverCapture={ () => setIsMouseOverDeleteButton(true) }
              onMouseOutCapture={ () => setIsMouseOverDeleteButton(false) }
            >
              <FontAwesomeIcon icon={ faTrash } color="black"/>
            </Button>
          ) }
          { addButtonClicked && (
            <Button
              className="form__icon bg-transparent border-0"
              onClick={ () => addButtonClicked(dateRange) }
            >
              <FontAwesomeIcon icon={ faPlus } color="black"/>
            </Button>
          ) }
        </Col>
      );
    }

    return (
      <Row
        className={ `date-range-picker-row mb-3 py-1 border ${
          isMouseOverDeleteButton ? 'border-danger' : 'border-white'
        }` }
      >
        { getDatePickerColumn('from', dateRange.start, (newDate) => {
          if (!onChange || Array.isArray(newDate)) {
            return;
          }

          const { end } = dateRange;

          onChange(dateRange, {
            start: newDate?.toISOString(),
            end,
          });
        }) }
        { getDatePickerColumn('to', dateRange.end, (newDate) => {
          if (!onChange || Array.isArray(newDate)) {
            return;
          }

          const { start } = dateRange;

          onChange(dateRange, {
            start,
            end: newDate?.toISOString(),
          });
        }) }
        { getButtonsColumn() }
      </Row>
    );
  },
);
