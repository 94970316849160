import React, { useEffect } from 'react';
import { getTranslationValueInCurrentLanguage } from '../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../state/reducers/rootReducer';
import { fetchDeviceTypes } from '../../state/device-types/fetchDeviceTypesActionCreator';
import { SelectProps } from '../FilterBar/select/components/select-props';
import { SelectWrapperForFilters } from '../FilterBar/select/SelectWrapperForFilters';
import { SelectableOption } from '../FilterBar/select/selectable-option';

export const FilterDeviceTypeMultiSelect = ({
  pageKey,
}: SelectProps) => {
  const deviceTypes = useSelector((state: AppState) => state.deviceTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDeviceTypes());
  }, []);

  const deviceTypeOptions = deviceTypes.map(
    (deviceType) => (
      {
        label: getTranslationValueInCurrentLanguage(deviceType.name),
        value: `${ deviceType.id }`,
      } as SelectableOption
    ));

  return (
    <SelectWrapperForFilters<SelectableOption>
      translationId="device-types"
      filterFieldKey="deviceTypes"
      pageKey={ pageKey }
      defaultOptions={ deviceTypeOptions }
    />
  );
}
