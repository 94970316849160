import React, { useEffect } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { filterOutSensitiveDataFromUser } from '../../../components/Feedback/filter-sensitive-data';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import ConfirmModal, { ConfirmationVariant } from '../../../components/Modal/ConfirmModal';
import ModalHeader from '../../../components/Modal/ModalHeader';
import { AppState } from '../../../state/reducers/rootReducer';
import RenderOnCondition from '../../../components/RenderOnCondition';
import {
  resetUserActionModalState,
  toggleShowDeleteModal,
} from '../../../state/actions/user-action-modal/fetchPermittedRolesActionCreators';
import {
  editUserWithRolesFetched,
  fetchEditUserWithRoles,
} from '../../../state/actions/user-action-modal/fetchEditUserWithRolesActionCreators';
import UserForm from './UserForm';
import { setLoadingIndicator } from '../../../state/actions/user-action-modal/loadingIndicatorActionCreators';
import { getRandomModalOffset } from '../../../utils/dialog';
import { FeedbackAttachment } from '../../../components/Feedback/feedback';

export interface UserActionModalProps extends LocalizeContextProps {
  saved: () => void;
  cancelled: () => void;
  title: string;
  showDeleteButton: boolean;
  isInCreationMode: boolean;
  userId?: string;
  removeUnit?: (id: string) => Promise<void>;
  selectedDeviceGroupId?: string;
}

const UserActionModal = (props: UserActionModalProps) => {
  const dispatch = useDispatch();
  const modalUid = uuidv4();
  const {
    isLoadingIndicatorEnabled,
    randomModalOffset,
    showDeleteModal,
    editUser,
    userRolesOfEditUser,
  } = useSelector((state: AppState) => state.userActionModal);

  useEffect(() => {
    const isInUpdateMode = !props.isInCreationMode;

    if (!randomModalOffset) {
      dispatch(getRandomModalOffset());
    }

    if (isInUpdateMode && !editUser && props.userId) {
      dispatch(fetchEditUserWithRoles(props.userId));
    } else if (!isInUpdateMode && isLoadingIndicatorEnabled) {
      // TODO(DS): Find a way to implement the creation mode properly.
      dispatch(editUserWithRolesFetched({}));
      dispatch(setLoadingIndicator(false));
    }

    return () => {
      dispatch(resetUserActionModalState());
    };
  }, []);

  const { title, saved, cancelled, removeUnit, userId, showDeleteButton, isInCreationMode } = props;

  const getAttachmentForFeedback = (): FeedbackAttachment | null => {
    if (!editUser && !userRolesOfEditUser) {
      return null;
    }
    const attachment: FeedbackAttachment = {};
    if (editUser) {
      editUser.roles = userRolesOfEditUser || [];
      attachment.user = filterOutSensitiveDataFromUser(editUser);
    }
    return attachment;
  };

  return (
    <div
      tabIndex={0} // make it focusable
      style={randomModalOffset}
      className="device-modal"
      id={`${title}-${modalUid}`}
    >
      <ModalHeader
        isDevice={false}
        titleTranslationId={title}
        targetId={modalUid}
        handleClose={cancelled}
        enableFeedbackSubmission={true}
        getFeedbackAttachment={getAttachmentForFeedback}
      />
      <div className="device-modal__body">
        {isLoadingIndicatorEnabled ? (
          <div className="loading-container">
            <LoadingIcon />
          </div>
        ) : (
          <UserForm
            modalId={modalUid}
            modalTitle={title}
            user={editUser}
            userRoles={userRolesOfEditUser}
            userCanBeDeleted={showDeleteButton}
            submitted={saved}
            cancelled={cancelled}
            isInCreationMode={isInCreationMode}
          />
        )}
        <RenderOnCondition condition={showDeleteModal}>
          <>
            {userId && (
              <ConfirmModal
                modalCloseRequested={() => dispatch(toggleShowDeleteModal())}
                actionConfirmed={async () => {
                  removeUnit && (await removeUnit(userId));
                }}
                translationIdOfElementType="user"
                confirmationVariant={ ConfirmationVariant.DELETE }
              />
            )}
          </>
        </RenderOnCondition>
      </div>
    </div>
  );
};

export default withLocalize(UserActionModal);
