import React from 'react';
import { Col } from 'react-bootstrap';
import { CustomFieldInput } from '../../../components/Table/CustomFieldInput';
import { FormikProps, getIn } from 'formik';
import { PropertySettings } from '@wiot/shared-domain/models/settings/property-settings';

type ConsumptionFieldProps = {
  name: string;
  index: number;
  value: number;
  readOnly?: boolean;
  formikProps: FormikProps<PropertySettings>;
};

const ConsumptionField = ({ readOnly, formikProps, value, index, name }: ConsumptionFieldProps) => {

  return (
    <Col
      lg={2}
    >
      <CustomFieldInput
        type='number'
        translationId={`month-long-${ index + 1 }`}
        className="form-select"
        fieldName={ name }
        value={ value }
        readOnly={ readOnly }
        error={ getIn(formikProps.errors, name) }
      />
    </Col>
  );
};

export default ConsumptionField;
