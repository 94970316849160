import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { DataIntegrationJobExtended } from '@wiot/shared-domain/models/data-integration/data-integration';
import { CustomFieldInput } from '../../../components/Table/CustomFieldInput';
import RenderOnCondition from '../../../components/RenderOnCondition';
import { CustomFieldAdvancedInput } from '../../../components/Table/CustomFieldAdvancedInput';
import DescriptionInput from './Shared/DescriptionInput';

interface IFtpSectionProps {
  api: DataIntegrationJobExtended;
  addUnit: boolean;
}

const FtpSection = ({ api, addUnit }: IFtpSectionProps) => {
  return (
    <Row>
      <DescriptionInput api={api} width={12} />
      <Col lg={12}>
        <CustomFieldInput
          translationId="user"
          fieldName="api.username"
          value={api.username || ''}
          required
        />
      </Col>
      <RenderOnCondition condition={!addUnit}>
        <Col lg={12}>
          <Translate>
            {({ translate }) => (
              <CustomFieldAdvancedInput
                type="password"
                translationId="passphrase"
                fieldName="api.passphrase"
                value={api.passphrase || ''}
                readOnly
                showCopyButton
              />
            )}
          </Translate>
        </Col>
      </RenderOnCondition>
    </Row>
  );
};

export default FtpSection;
