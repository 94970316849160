import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { toastr } from 'react-redux-toastr';
import { RadioKey } from '@wiot/shared-domain/models/radio-key/radio-key';
import { Device } from '@wiot/shared-domain/models/device/device';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import ModalHeader from '../Modal/ModalHeader';
import {
  clearCache,
  getAddedDeviceGroups,
  getAddedDevices,
  getAddedKeys,
  getRemovedDeviceGroups,
  getRemovedDevices,
  getRemovedKeys,
  getUpdatedDeviceGroups,
  getUpdatedDevices,
  getUpdatedKeys,
  syncCachedDataToBackend,
} from '../../api/offline';
import RenderOnCondition from '../RenderOnCondition';
import LoadingIcon from '../shared/LoadingIcon';
import OfflineItem from './OfflineItem';
import { OutsideClickHandler } from '../OutsideClickHandler';
import useTranslation from '../../hooks/useTranslation';
import { DeviceTypeCache } from '../../api/deviceTypeCache';
import { getTranslationValueInCurrentLanguage } from '../../utils/common';

interface ISyncOfflineModalProps {
  toggleOfflineSyncModal: () => void;
}

const SyncOfflineModal = (props: ISyncOfflineModalProps) => {
  const [showLoading, setShowLoading] = useState(false);
  const [addedDevices, setAddedDevices] = useState<Device[]>();
  const [editedDevices, setEditedDevices] = useState<Device[]>();
  const [removedDevices, setRemovedDevices] = useState<string[]>();

  const [addedDeviceTypes, setAddedDeviceTypes] = useState<IDeviceType[]>();
  const [editedDeviceTypes, setEditedDeviceTypes] = useState<IDeviceType[]>();
  const [removedDeviceTypes, setRemovedDeviceTypes] = useState<IDeviceType[]>();

  const [addedDeviceGroups, setAddedDeviceGroups] = useState<DeviceGroup[]>();
  const [editedDeviceGroups, setEditedDeviceGroups] = useState<DeviceGroup[]>();
  const [removedDeviceGroups, setRemovedDeviceGroups] = useState<string[]>();
  const [addedKeys, setAddedKeys] = useState<RadioKey[]>();
  const [editedKeys, setEditedKeys] = useState<RadioKey[]>();
  const [removedKeys, setRemovedKeys] = useState<string[]>();

  const translate = useTranslation();

  const retrieveKeys = async () => {
    const keysAdded = await getAddedKeys();
    const keysEdited = await getUpdatedKeys();
    const keysRemoved = await getRemovedKeys();
    const devicesAdded = await getAddedDevices();
    const deviceEdited = await getUpdatedDevices();
    const deviceRemoved = await getRemovedDevices();
    const deviceGroupsAdded = await getAddedDeviceGroups();
    const deviceGroupsEdited = await getUpdatedDeviceGroups();
    const deviceGroupsRemoved = await getRemovedDeviceGroups();
    const deviceTypesAdded = await DeviceTypeCache.getTemporaryAddedDeviceTypes();
    const deviceTypesEdited = await DeviceTypeCache.getTemporaryUpdatedDeviceTypes();
    const deviceTypesRemoved = await DeviceTypeCache.getTemporaryRemovedDeviceTypes();

    // @ts-ignore
    setAddedKeys(keysAdded);
    // @ts-ignore
    setEditedKeys(keysEdited);
    // @ts-ignore
    setRemovedKeys(keysRemoved);
    // @ts-ignore
    setAddedDevices(devicesAdded);
    // @ts-ignore
    setEditedDevices(deviceEdited);
    // @ts-ignore
    setRemovedDevices(deviceRemoved);
    // @ts-ignore
    setAddedDeviceGroups(deviceGroupsAdded);
    // @ts-ignore
    setEditedDeviceGroups(deviceGroupsEdited);
    // @ts-ignore
    setRemovedDeviceGroups(deviceGroupsRemoved);

    setAddedDeviceTypes(deviceTypesAdded);
    setEditedDeviceTypes(deviceTypesEdited);
    setRemovedDeviceTypes(deviceTypesRemoved);
  };

  useEffect(() => {
    retrieveKeys();
  }, []);

  const handleSync = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (navigator.onLine) {
      setShowLoading(true);
      await syncCachedDataToBackend();
      await retrieveKeys();
      setShowLoading(false);
    } else {
      toastr.info(
        translate('no-internet').toString(),
        translate('device-not-connected').toString(),
      );
    }
  };

  const handleClearCache = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setShowLoading(true);
    await clearCache();
    setShowLoading(false);
    props.toggleOfflineSyncModal();
  };

  const deviceTypeDataExists =
    (addedDeviceTypes && addedDeviceTypes.length > 0) ||
    (editedDeviceTypes && editedDeviceTypes.length > 0) ||
    (removedDeviceTypes && removedDeviceTypes.length > 0);
  const deviceDataExists = addedDevices || editedDevices || removedDevices;
  const groupsDataExists = addedDeviceGroups || editedDeviceGroups || removedDeviceGroups;
  const keysDataExists = addedKeys || editedKeys || removedKeys;

  return (
    <OutsideClickHandler handleClickOutside={props.toggleOfflineSyncModal}>
      <div
        className="device-modal"
        // used for modal header to target this modal, since no targetId props is passed, suffix = undefined
        id="syncOffline-modal"
      >
        <ModalHeader
          isDevice={false}
          targetId="modal"
          titleTranslationId="syncOffline"
          handleClose={props.toggleOfflineSyncModal}
        />
        <RenderOnCondition condition={showLoading}>
          <div className="details">
            <LoadingIcon />
          </div>
        </RenderOnCondition>
        <RenderOnCondition condition={!showLoading}>
          <div className="form">
            <RenderOnCondition condition={deviceTypeDataExists}>
              <h4 className="form__subheading">
                <Translate id="deviceTypes" />
              </h4>
              <>
                {addedDeviceTypes?.map((deviceType) => (
                  <OfflineItem
                    action="add"
                    name={getTranslationValueInCurrentLanguage(deviceType.name)}
                  />
                ))}
                {editedDeviceTypes?.map((deviceType) => (
                  <OfflineItem
                    action="edit"
                    name={getTranslationValueInCurrentLanguage(deviceType.name)}
                  />
                ))}
                {removedDeviceTypes?.map((deviceType) => (
                  <OfflineItem
                    action="remove"
                    name={getTranslationValueInCurrentLanguage(deviceType.name)}
                  />
                ))}
              </>
            </RenderOnCondition>
            <RenderOnCondition condition={deviceDataExists}>
              <h4 className="form__subheading">
                <Translate id="devices" />
              </h4>
              <>
                {addedDevices?.map((device: Device) => (
                  <OfflineItem action="add" name={device.name} syncState={device.syncState} />
                ))}
                {editedDevices?.map((device: Device) => (
                  <OfflineItem action="edit" name={device.name} syncState={device.syncState} />
                ))}
                {removedDevices?.map((device: string) => (
                  <OfflineItem action="remove" name={device} />
                ))}
              </>
            </RenderOnCondition>
            <RenderOnCondition condition={groupsDataExists}>
              <h4 className="form__subheading">
                <Translate id="device-groups" />
              </h4>
              <>
                {addedDeviceGroups?.map((group: DeviceGroup) => (
                  <OfflineItem action="add" name={group.name} syncState={group.syncState} />
                ))}
                {editedDeviceGroups?.map((group: DeviceGroup) => (
                  <OfflineItem action="edit" name={group.name} syncState={group.syncState} />
                ))}
                {removedDeviceGroups?.map((group: string) => (
                  <OfflineItem action="remove" name={group} />
                ))}
              </>
            </RenderOnCondition>
            <RenderOnCondition condition={keysDataExists}>
              <h4 className="form__subheading">
                <Translate id="radio-key" />
              </h4>
              <>
                {addedKeys?.map((radioKey: RadioKey) => (
                  <OfflineItem action="add" name={radioKey.name} syncState={radioKey.syncState} />
                ))}
                {editedKeys?.map((radioKey: RadioKey) => (
                  <OfflineItem action="edit" name={radioKey.name} syncState={radioKey.syncState} />
                ))}
                {removedKeys?.map((radioKey: string) => (
                  <OfflineItem action="remove" name={radioKey} />
                ))}
              </>
            </RenderOnCondition>
            <RenderOnCondition
              condition={
                !(deviceDataExists || groupsDataExists || keysDataExists || deviceTypeDataExists)
              }
            >
              <p className="text-center">
                <Translate id="no-data" />
              </p>
            </RenderOnCondition>
            <div className="d-flex justify-content-end mt-2">
              <button
                type="button"
                className="form__button--cancel"
                onClick={props.toggleOfflineSyncModal}
              >
                <Translate id="cancel" />
              </button>
              <button type="button" className="form__button--cancel" onClick={handleClearCache}>
                <Translate id="clearCache" />
              </button>
              <button
                className="form__button--blue background-color-main text-color-white border-color-main"
                onClick={handleSync}
              >
                <Translate id="sync" />
              </button>
            </div>
          </div>
        </RenderOnCondition>
      </div>
    </OutsideClickHandler>
  );
};

export default SyncOfflineModal;
