import { TreeNode } from '@wiot/shared-domain/domain/device-tree/tree-node';

import { DeviceManagerFilter } from '../../device/device-manager-filter';

export const FETCH_TREE_NODE = 'FETCH_TREE_NODE';
export const TREE_NODE_FETCHED = 'TREE_NODE_FETCHED';

export interface FetchTreeNodeAction {
  type: typeof FETCH_TREE_NODE;
  deviceGroupId: string;
  deviceManagerFilter: DeviceManagerFilter;
}

export interface TreeNodeFetchedAction {
  type: typeof TREE_NODE_FETCHED;
  treeNode: TreeNode;
  childLimit: number;
}
