import React from 'react';
import { Route } from 'react-router';

interface RouteProps {
  path: string;
  component: React.ComponentType<any>;
  handleRouteClick: () => void;
}

const CustomRoute = (route: RouteProps) => (
  <Route
    path={route.path}
    // eslint-disable-next-line react/jsx-props-no-spreading
    render={(props) => <route.component {...props} />}
  />
);

export default CustomRoute;
