import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { AppState } from '../../state/reducers/rootReducer';
import { LOGIN_PATH, DASHBOARD_PATH, DEVICE_MANAGER_PATH } from '../../navigation/paths';

export const InitialRoute = () => {
  const isLoggedIn = useSelector((appState: AppState) => appState.isLoggedIn);
  const isKeyManagerModeEnabled = useSelector((state: AppState) => !!state.siteSettings.isKeyManagerModeEnabled);

  if (!isLoggedIn) {
    return <Redirect to={LOGIN_PATH} />;
  }
  return <Redirect to={isKeyManagerModeEnabled ? DEVICE_MANAGER_PATH : DASHBOARD_PATH} />;
};
