import React, { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from '../../state/reducers/rootReducer';
import { changeEntriesPerPage as changeEntriesPerPageAction } from '../../state/actions/changeEntriesPerPageAction';
import { DispatchActionTypes } from '../../state/types';

interface MobileFooterProps {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  currentEntriesPerPage: number;
  currentRecommendedEntriesPerPage: number;
  changeEntriesPerPage: (currentEntriesPerPage: number) => void;
  totalDocs: number;
}

const MobileFooter = (props: MobileFooterProps) => {
  const {
    currentEntriesPerPage,
    changeEntriesPerPage,
    currentRecommendedEntriesPerPage,
    currentPage,
    totalDocs,
    setCurrentPage,
  } = props;
  useEffect(() => {
    if (currentEntriesPerPage === 0) {
      changeEntriesPerPage(25);
    }
  }, [currentEntriesPerPage, changeEntriesPerPage]);

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    changeEntriesPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const totalPages = totalDocs / currentEntriesPerPage;

  return (
    <Row>
      <Col xs={4}>
        <Form.Group as={Col} controlId="page-size">
          <Form.Control
            as="select"
            size="sm"
            onChange={handlePageSizeChange}
            value={currentEntriesPerPage}
          >
            <option>{currentRecommendedEntriesPerPage}</option>
            <option>25</option>
            <option>50</option>
            <option>75</option>
            <option>100</option>
          </Form.Control>
        </Form.Group>
      </Col>
      <Col xs={8} className="footer-btn-wrapper">
        <button
          className="btn btn-sm wehrle-btn-primary background-color-main text-color-white"
          disabled={currentPage <= 1}
          onClick={handlePrevClick}
        >
          <Translate id="prev" />
        </button>
        {currentPage} <Translate id="of" /> {Math.ceil(totalPages)}
        <button
          className="btn btn-sm wehrle-btn-primary background-color-main text-color-white"
          disabled={currentPage >= totalPages}
          onClick={handleNextClick}
        >
          <Translate id="next" />
        </button>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: AppState) => ({
  currentEntriesPerPage: state.currentEntriesPerPage,
  currentRecommendedEntriesPerPage: state.currentRecommendedEntriesPerPage,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  changeEntriesPerPage: (currentEntriesPerPage: number) =>
    dispatch(changeEntriesPerPageAction(currentEntriesPerPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileFooter);
