import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Translate } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { DataValidator } from '@wiot/shared-domain/models/validators/data-validators';
import useTranslation from '../../hooks/useTranslation';
import { createDevicePassword } from '../../state/device-key/createDevicePasswordActionCreators';
import PrimaryButton from '../shared/PrimaryButton';
import { GetPasswordValidation } from '../shared/YupValidators';
import RenderOnCondition from '../RenderOnCondition';
import { CustomFieldAdvancedInput } from '../Table/CustomFieldAdvancedInput';
import { CustomFieldInput } from '../Table/CustomFieldInput';

interface CreateDevicePasswordProps {
  downloadHash: string;
}

export interface CreatePasswordFormValues {
  password: string;
  passwordConfirmation: string;
  resetPasswordEmail: string;
}

function CreateDevicePasswordForm({ downloadHash }: CreateDevicePasswordProps) {
  const translate = useTranslation();
  const dispatch = useDispatch();

  const [allowResetPassword, setAllowResetPassword] = useState(true);

  const handleSubmit = async ({ password, resetPasswordEmail }: CreatePasswordFormValues) => {
    dispatch(
      createDevicePassword(
        downloadHash,
        password,
        allowResetPassword ? resetPasswordEmail : undefined,
      ),
    );
  };

  const valueRequiredIfUsingPasswordReset = Yup.string().test(
    'email-is-required-if-using-password-reset',
    translate('invalid').toString(),
    (testedValue) => {
      const isValidEmail = DataValidator.validateEmail(testedValue);
      if (allowResetPassword && !isValidEmail) {
        return false;
      }

      return true;
    },
  );

  return (
    <>
      <Formik
        initialValues={{
          password: '',
          passwordConfirmation: '',
          resetPasswordEmail: '',
        }}
        validationSchema={Yup.object().shape({
          password: GetPasswordValidation(),
          passwordConfirmation: GetPasswordValidation().oneOf(
            [Yup.ref('password'), null],
            translate('passwords-must-match').toString(),
          ),
          resetPasswordEmail: valueRequiredIfUsingPasswordReset,
        })}
        onSubmit={handleSubmit}
      >
        {({ values, errors }) => (
          <Form className="text-left" id="createPasswordForm">
            <div className="form__section">
              <Row>
                <Col>
                  <div className="my-1">
                    <CustomFieldAdvancedInput
                      type="password"
                      fieldName="password"
                      value={values.password}
                      translationId="password"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="my-2">
                    <CustomFieldAdvancedInput
                      type="password"
                      fieldName="passwordConfirmation"
                      value={values.passwordConfirmation}
                      translationId="confirm-password"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="mt-2 mb-1">
                    <Translate id="device-key-download_allow-reset-lost-password" />
                  </label>
                  <div role="group" aria-labelledby="my-radio-group">
                    <div>
                      <input
                        type="radio"
                        id="yes"
                        checked={allowResetPassword}
                        onChange={() => setAllowResetPassword(true)}
                      />
                      <label className="ml-2" htmlFor="yes">
                        <Translate id="yes" />
                      </label>
                    </div>
                    <RenderOnCondition condition={allowResetPassword}>
                      <div className="ml-4 mb-1">
                        <CustomFieldInput
                          type="text"
                          translationId="device-key-download_reset-password-email"
                          fieldName="resetPasswordEmail"
                          value={values.resetPasswordEmail}
                        />
                      </div>
                    </RenderOnCondition>
                    <div>
                      <input
                        type="radio"
                        id="no"
                        name="allowResetLostPassword"
                        value="false"
                        checked={allowResetPassword === false}
                        onChange={() => setAllowResetPassword(false)}
                        translate="no"
                      />
                      <label className="ml-2" htmlFor="no">
                        <Translate id="device-key-download_allow-no-not-recommended" />
                      </label>
                      <p className="ml-2">
                        <RenderOnCondition condition={allowResetPassword === false}>
                          <>
                            <FontAwesomeIcon icon={faExclamationTriangle} color="#ec2727" />
                            &nbsp;
                            <Translate id="device-key-download_a-lost-key-cant-be-recovered" />
                          </>
                        </RenderOnCondition>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="mt-3">
                <PrimaryButton
                  textTranslationId="device-key-download_create-password"
                  formNameToSubmitOnClick="createPasswordForm"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CreateDevicePasswordForm;
