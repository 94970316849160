import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import { ADD_DEVICE_TYPE } from '../constants/actionTypes';
import { AddDeviceTypeAction } from '../types';

/**
 * Action creator which creates an action to add a device type to the store.
 *
 * @param deviceType The device type to be added to the store.
 *
 * @returns An action which adds a device type to the store.
 */
export const createAddDeviceTypeAction = (deviceType: IDeviceType): AddDeviceTypeAction => ({
  type: ADD_DEVICE_TYPE,
  payload: deviceType,
});
