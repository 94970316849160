import React, { useEffect, useState } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer as RechartsResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { MeasurementFrequency } from '@wiot/shared-domain/models/device-reading/measurement';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import { PROPERTY_VIEW_PAGE_KEY } from '@wiot/shared-domain/domain/property/property-view-page-key';
import { AppState } from '../../../../state/reducers/rootReducer';
import useTranslation from '../../../../hooks/useTranslation';
import LoadingIcon from '../../../../components/shared/LoadingIcon';
import { getTranslationValueInCurrentLanguage } from '../../../../utils/common';
import {
  selectIsPropertyConsumptionHistoryLoading,
  selectIsPropertyLoading,
  selectProperty,
  selectPropertyConsumptionUnit
} from '../../../../state/property-view/propertySelectors';
import { selectPageFilters } from '../../../../state/filter/filterSelectors';
import ChartToggle from './ChartToggle';
import { fetchPropertyConsumptionHistory } from '../../../../state/property-view/fetchPropertyConsumptionHistoryActionCreator';
import RenderOnCondition from '../../../../components/RenderOnCondition';
import NoResultsInfo from '../../../../components/Table/NoResultsInfo';

interface PropertyConsumptionHistoryProps extends LocalizeContextProps {
  responsiveContainerHeight?: number;
}

const PropertyConsumptionHistory = (props: PropertyConsumptionHistoryProps) => {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const { responsiveContainerHeight = 150 } = props;

  const filter = useSelector((state: AppState) => selectPageFilters(state, PROPERTY_VIEW_PAGE_KEY));
  const isMobileDisplay = useSelector((state: AppState) => state.isMobileDisplay);

  const {
    consumptionType,
    billingPeriodStartDate,
    billingFrequency,
    propertyMBusDeviceTypeIdForConsumption: mBusDeviceTypeId,
  } = filter;

  const property = useSelector((state: AppState) => selectProperty(state));
  const isPropertyLoading = useSelector(selectIsPropertyLoading);
  const isPropertyConsumptionHistoryLoading = useSelector(selectIsPropertyConsumptionHistoryLoading);
  const consumptionUnit = useSelector(selectPropertyConsumptionUnit);

  const [currentDeviceType, setCurrentDeviceType] = useState<IDeviceType>();
  const [selectedYear, setSelectedYear] = useState<number>(0);
  const [isLastYearBarVisible, setIsLastYearBarVisible] = useState<boolean>(true);

  useEffect(() => {
    const shouldFetchConsumptionHistory = property?.id && !isPropertyLoading && !isPropertyConsumptionHistoryLoading;
    if (shouldFetchConsumptionHistory) {
      dispatch(
        fetchPropertyConsumptionHistory(
          property,
          mBusDeviceTypeId,
          billingPeriodStartDate,
          billingFrequency,
          true,
          consumptionType,
        ),
      );
    }

    setSelectedYear(new Date(billingPeriodStartDate).getFullYear());
  }, [
    dispatch,
    property?.id,
    mBusDeviceTypeId,
    billingPeriodStartDate,
    billingFrequency,
    consumptionType,
    isPropertyLoading,
  ]);

  useEffect(() => {
    if (property) {
      const { devices } = property;

      const device = devices?.find(
        (device) => device.deviceType?.mBusDeviceTypeId === mBusDeviceTypeId,
      );

      setCurrentDeviceType(device?.deviceType);
    }
  }, [consumptionUnit, property, mBusDeviceTypeId]);

  const xAxisTickFormatter = (value: number): string => {
    if (billingFrequency === MeasurementFrequency.MONTH) {
      return translate(`month-short-${value}`).toString();
    }
    return value.toString();
  };

  const tooltipLabelFormatter = (value: number): string => {
    if (billingFrequency === MeasurementFrequency.MONTH) {
      return translate(`month-long-${value}`).toString();
    }
    return `${value}.${moment(billingPeriodStartDate).format('MM.YYYY')}`;
  };

  const isLoading = !property?.consumptionHistory?.[mBusDeviceTypeId]?.[billingFrequency]?.[billingPeriodStartDate];
  if (isLoading) {
    return <LoadingIcon />;
  }

  const propertyHistory = property?.consumptionHistory?.[mBusDeviceTypeId]?.[billingFrequency]?.[billingPeriodStartDate] || [];

  const formatLegendLabel = (value: string | number) => {
    let label = value.toString();
    if (label === (selectedYear - 1).toString()) {
      return <ChartToggle
        label={ translate('previous-year').toString() }
        value={ isLastYearBarVisible }
        onChange={ setIsLastYearBarVisible }
      />;
    }

    return label;
  };

  const formatTooltipLabel = (value: number, label: string | number) => {
    const valueWithUnit = `${ value.toFixed(2) } ${ consumptionUnit }`;

    const labelString = label.toString();
    let translatedLabel = `${translate('current-property')} ${labelString}`;

    return [valueWithUnit, translatedLabel];
  };

  return (
    <>
      <RenderOnCondition condition={ !propertyHistory.length }>
        <NoResultsInfo />
      </RenderOnCondition>

      <RenderOnCondition condition={ propertyHistory.length > 0 }>
        <RechartsResponsiveContainer
          className={ isMobileDisplay ? 'my-4' : '' }
          width="100%"
          height={ responsiveContainerHeight }
        >
          <ComposedChart
            className="consumption-history-chart"
            data={ propertyHistory }
          >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis unit={` ${consumptionUnit}`} width={70} />
            <XAxis
              tickLine={ false }
              tickFormatter={ xAxisTickFormatter }
              // Use index + 1 as X axis tick label (it's the day 1-31 or month 1-12 number)
              dataKey={ dataValue => propertyHistory.indexOf(dataValue) + 1 }
            />
            <Tooltip
              labelFormatter={ tooltipLabelFormatter }
              formatter={ formatTooltipLabel }
            />
            <Legend
              wrapperStyle={{ bottom: isMobileDisplay ? 0 : -15 }}
              verticalAlign="bottom"
              align="right"
              formatter={ formatLegendLabel }
            />
            <Bar
              type="monotone"
              dataKey={ selectedYear }
              fill="var(--main-color)"
            />
            <Line
              hide={ !isLastYearBarVisible }
              type="monotone"
              strokeWidth={ 2 }
              dataKey={ selectedYear - 1 }
              stroke="var(--main-color-mod-2)"
              strokeDasharray="4 3"
            />
          </ComposedChart>
        </RechartsResponsiveContainer>

        <div className="consumption-history-chart__device-type">
          {currentDeviceType?.name &&
            getTranslationValueInCurrentLanguage(currentDeviceType.name)}
        </div>
      </RenderOnCondition>
    </>
  );
};

export default withLocalize(PropertyConsumptionHistory);
