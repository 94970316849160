import React from 'react';
import Moveable, { OnDrag } from 'react-moveable';

interface IDraggableProps {
  targetId: string;
  dragTargetId: string;
  notDraggable?: boolean;
}

const Draggable = (props: IDraggableProps) => {

  const { targetId, dragTargetId, notDraggable } = props;

  const onDrag = ({ target, translate, left, top}: OnDrag) => {

    if(!target)
      return;

    const mainGridElement = document.getElementById("mainGrid");

    if (mainGridElement === undefined || mainGridElement === null)
      return;

    const targetBoundary = target.getBoundingClientRect();
    const mainGridBoundary = mainGridElement.getBoundingClientRect();

    const [mouseMoveDirectionX, mouseMoveDirectionY] = translate;

    if(targetBoundary.left < mainGridBoundary.left && mouseMoveDirectionX < 0
      || targetBoundary.top < mainGridBoundary.top && mouseMoveDirectionY < 0
      || targetBoundary.right > mainGridBoundary.right && mouseMoveDirectionX > 0
      || targetBoundary.bottom > mainGridBoundary.bottom && mouseMoveDirectionY > 0)
      return;

    target.style.left = `${left}px`;
    target.style.top = `${top}px`;

  }

  return (
    <Moveable
      target={document.querySelector(`#${targetId}`)}
      dragTarget={document.querySelector(`#${dragTargetId}`) as HTMLElement}
      container={document.body}
      className="d-none"
      draggable={!notDraggable}
      origin
      edge={false}
      throttleDrag={0}
      onDrag={onDrag}
    />
  );
};

export default Draggable;
