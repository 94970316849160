/**
 *
 * Custom Accordion
 * The bootstrap Version v4 (react-boostrap v1) does not know Accordion.Item, Accordion.Body, Accordion.Header,
 * instead it uses Card, Card.Header, Card.Body and Accordion.Toggle and Accordion.Collapse.
 *
 */

import * as React from "react";
import { Accordion } from "react-bootstrap";

export interface AccordionGroupProps {
  children: React.ReactNode;
}

export function AccordionGroup({ children }: AccordionGroupProps): React.ReactElement {
  return (
    <Accordion className="custom-accordion">
      { children }
    </Accordion>
  );
}
