import React, { useEffect, useState } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import debounce from 'debounce-promise';
import { ValueType } from 'react-select';
import { Device } from '@wiot/shared-domain/models/device/device';
import { AppState } from '../../state/reducers/rootReducer';
import { fetchDevicesFromDB } from '../../api/apiHelpers';
import { IModalZindex } from '../../state/types';
import Portal from './Portal';
import CustomCheckbox from './CustomCheckbox';
import { getTranslationValueInCurrentLanguage } from '../../utils/common';
import { getReasoningMessageForNoOptions } from '../FilterBar/select/functions/get-reasoning-message-for-no-options';
import { AsyncBaseSelect } from '../FilterBar/select/AsyncBaseSelect';
import { DEBOUNCE_TIME_TO_START_SEARCH } from '../FilterBar/select/constants/debounce-time-to-start-search';
import { InputContextType } from '../Input/input-context-type';

interface IAsyncDeviceSelectProps extends LocalizeContextProps {
  handleDeviceSelection: (value: ValueType<Device>) => void;
  reduxZindex: IModalZindex;
  selectedDevice?: Device;
}

const AsyncDeviceSelect = (props: IAsyncDeviceSelectProps) => {
  const { translate, handleDeviceSelection, reduxZindex, selectedDevice } = props;
  const [asyncSelectPosition, setAsyncSelectPosition] = useState({});
  const [isSearchingDevices, setIsSearchingDevices] = useState(false);
  const [searchOnlyAssignedDevices, setSearchOnlyAssignedDevices] = useState(false);

  const searchDevices = async (inputValue: string): Promise<Device[]> => {
    try {
      setIsSearchingDevices(true);
      const { devices } = await fetchDevicesFromDB({
        filters: {
          name: inputValue,
          showOnlyUnAssigned: searchOnlyAssignedDevices,
          deviceGroups: []
        },
      });

      return devices;
    } catch (e) {
      console.error(e);
      return [];
    } finally {
      setIsSearchingDevices(false);
    }
  };

  useEffect(() => {
    const selectEl = document.getElementById('device-change-options-async-select-device');

    if (selectEl) {
      const selectBoundary = selectEl.getBoundingClientRect();

      selectBoundary &&
      setAsyncSelectPosition({
        top: selectBoundary.bottom,
        right: document.body.clientWidth - (selectBoundary.right || 0),
        width: selectBoundary.width,
        zIndex: reduxZindex?.zIndex + 1,
      });
    }
  }, [reduxZindex?.zIndex]);

  const getOptionLabel = (option?: Device): string => {
    if (!option) {
      return '';
    }

    let result = `${ option.name } / ${ option.deviceId }`;

    if (option.deviceType || option.manufacturer) {
      result += ' (';

      if (option.deviceType) {
        result += getTranslationValueInCurrentLanguage(option.deviceType?.name);
      }

      if (option.manufacturer) {
        if (option.deviceType) {
          result += ' / ';
        }

        result += option.manufacturer.flagId;
      }

      result += ')';
    }

    return result;
  };

  return (
    <>
      <label
        id="device-change-options-async-select-device"
        className="form__label"
        htmlFor="device"
      >
        { translate('device') }
      </label>
      <Portal>
        <div className="device-change__async-select" style={ asyncSelectPosition }>
          { asyncSelectPosition && (
            <>
              <AsyncBaseSelect<Device>
                translationId="device-name-id"

                placeholderTranslationId="device-name-id"
                inputContextType={ InputContextType.MODAL }

                loadOptions={ debounce(searchDevices, DEBOUNCE_TIME_TO_START_SEARCH) }

                getOptionLabel={ (option) => getOptionLabel(option) }
                getOptionValue={ (option) => option.id }

                selectedOptions={ selectedDevice }
                onChange={ handleDeviceSelection }
                isMulti={ false }

                noOptionsMessage={ (args) =>
                  getReasoningMessageForNoOptions(
                    args.inputValue,
                    translate,
                    'no-matching-devices-found',
                    isSearchingDevices
                  )
                }

                isLoading={ isSearchingDevices }
                showDefaultOptions={ false }
                translateOptions={ false }
              />
              <label className='standard-font-size'>
                <Translate id="search-only-assigned-devices"/>
              </label>
              <CustomCheckbox
                readOnly
                checked={ searchOnlyAssignedDevices }
                onClick={ () => setSearchOnlyAssignedDevices(!searchOnlyAssignedDevices) }
              />
            </>
          ) }
        </div>
      </Portal>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  reduxZindex: state.modalZindex,
});

export default connect(mapStateToProps)(withLocalize(AsyncDeviceSelect));
