import React from 'react';
import ResponsiveContainer from '../../components/shared/ResponsiveContainer';
import { deviceGroupIcons } from '../../constants';
import { DeviceGroup, DeviceGroupType } from '@wiot/shared-domain/models/device-group/device-group';
import QUESTION_MARK from '../../assets/question-mark.svg';
import useTranslation from '../../hooks/useTranslation';
import { Col, Row } from 'react-bootstrap';
import DetailCol from '../DeviceManager/DetailCol';
import RenderOnCondition from '../../components/RenderOnCondition';
import useBreakpoint from '../../hooks/useBreakpoint';

type PropertyViewGeneralInfoProps = {
  deviceGroup: DeviceGroup;
};

export const PropertyViewGeneralInfo = function (props: PropertyViewGeneralInfoProps) {
  const { deviceGroup } = props;
  const { type, name, abbreviation, externalId } = deviceGroup;
  const translate = useTranslation();
  const breakpoint = useBreakpoint();

  return (
    <>
      <ResponsiveContainer
        title='general-info'
        maxWidths={ { sm: 12, md: 12, lg: 12, xl: breakpoint === 'xxl' ? 4 : 12 } } // TODO(TL): Upgrade to bootstrap v5 for `xxl` breakpoint
      >
        <Row className="property-view__general-info">
          <Col lg={ 4 } sm={ 6 } xs={ 6 }>
            <img
              src={ (type && deviceGroupIcons[type as DeviceGroupType]) || QUESTION_MARK }
              className="thumbnail"
              alt="device type"
            />
          </Col>
          <Col lg={ 8 } sm={ 6 } xs={ 6 }>
            <DetailCol
              translateKey="name"
              colSize={ 12 }
              additionalClassNames={'mb-2 w-100'}
            >
              <>{': '}</>
              <p className="details__text standard-font-size text-overflow-pre-wrap">{ name }</p>
            </DetailCol>
            <DetailCol
              translateKey="type"
              colSize={12}
              additionalClassNames={'mb-2 w-100'}
            >
              <>{': '}</>
              <p className="details__text standard-font-size text-overflow-pre-wrap">{ type && translate(type) }</p>
            </DetailCol>
            <DetailCol
              translateKey="abbreviation"
              colSize={12}
              additionalClassNames={'mb-2 w-100'}
            >
              <>{': '}</>
              <p className="details__text standard-font-size text-overflow-pre-wrap">{ abbreviation || '-' }</p>
            </DetailCol>
          </Col>
        </Row>
        <Row>
          <RenderOnCondition condition={ externalId }>
            <DetailCol
              translateKey="external-id"
              colSize={12}
              additionalClassNames={'mb-2 w-100'}
            >
              <>{': '}</>
              <p className="details__text standard-font-size text-overflow-pre-wrap">{ externalId }</p>
            </DetailCol>
          </RenderOnCondition>
        </Row>
      </ResponsiveContainer>
    </>
  );
};
