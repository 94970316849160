import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router';
import _ from 'lodash';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AggregatedPermission } from '@wiot/shared-domain/models/role/role';

import Footer from './Footer/Footer';
import Header from './Header/Header';
import Navbar from './Navbar/Navbar';
import CustomRoute from './shared/CustomRoute';
import { Breakpoints, DispatchActionTypes } from '../state/types';
import { updateIsMobileDisplay } from '../state/actions/responsiveAction';
import { AppState } from '../state/reducers/rootReducer';
import { setCurrentPageTitle } from '../state/actions/setCurrentPageTitleAction';
import ErrorBoundary from './ErrorBoundary';
import RenderOnCondition from './RenderOnCondition';
import OfflineBanner from './OfflineBanner';
import { getAvailableRoutes } from '../navigation/navigation-item-provider';
import { PageKeys } from '../state/reducers/filterSortReducer';
import { showNoResultsInfo } from '../state/device/fetching-limit/fetchNoResultInfoAction';


interface AppProps {
  updateIsMobileDisplay: (isMobileDisplay: boolean) => void;
  location: any;
  currentPageTitle: PageKeys;
  isMobileDisplay: boolean;
  isOffline: boolean;
  userPermission?: AggregatedPermission;
  showNoResultsInfo: () => void;
  setCurrentPageTitle: (pageTitle: PageKeys) => void;
  isKeyManagerModeEnabled: boolean;
  isLoggedIn: boolean;
}

class App extends Component<AppProps, any> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      navbarVisible: true,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentDidUpdate(prevProps: AppProps) {
    const {
      isMobileDisplay,
      showNoResultsInfo,
      setCurrentPageTitle,
      currentPageTitle,
      location,
    } = this.props;
    const currentPage = location.pathname.split('/')[1] || 'dashboard';
    if (currentPageTitle !== currentPage) {
      showNoResultsInfo();
      setCurrentPageTitle(currentPage);
    }
    if (isMobileDisplay) {
      if (isMobileDisplay !== prevProps.isMobileDisplay) {
        this.setNavbarVisible(!isMobileDisplay);
      }

      if (location !== prevProps.location) {
        this.setNavbarVisible(false);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = _.debounce(() => {
    this.props.updateIsMobileDisplay(window.innerWidth <= Breakpoints.LG);
  }, 400);

  setNavbarVisible = (visible: boolean) => {
    this.setState({
      navbarVisible: visible,
    });
  };

  render() {
    const { isKeyManagerModeEnabled, isMobileDisplay, isLoggedIn, isOffline, userPermission } = this.props;
    const routes = getAvailableRoutes(isKeyManagerModeEnabled, isMobileDisplay, userPermission);
    return (
      <>
        <RenderOnCondition condition={isOffline}>
          <OfflineBanner/>
        </RenderOnCondition>
        <div
          className="app"
          data-testid="app"
          style={ { paddingTop: isOffline ? '35px' : 0 } }
        >
          <ErrorBoundary>
            <Header
              setNavbarVisible={ this.setNavbarVisible }
              navbarVisible={ this.state.navbarVisible }
            />
            { (this.state.navbarVisible || !isMobileDisplay) && <Navbar showNavigationLinks={isLoggedIn}/> }
            <Switch>
              { routes.map((route, index) => (
                <CustomRoute
                  key={`custom-route-${index}`}
                  {...route}
                  handleRouteClick={ () => this.setNavbarVisible(false) }
                />
              )) }
            </Switch>
            <Footer/>
          </ErrorBoundary>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  currentPageTitle: state.currentPageTitle,
  isMobileDisplay: state.isMobileDisplay,
  isOffline: state.isOffline,
  userPermission: state.currentUser.permission,
  isKeyManagerModeEnabled: !!state.siteSettings.isKeyManagerModeEnabled,
  isLoggedIn: state.isLoggedIn,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  updateIsMobileDisplay: (isMobileDisplay: boolean) =>
    dispatch(updateIsMobileDisplay(isMobileDisplay)),
  setCurrentPageTitle: (pageTitle: PageKeys) => dispatch(setCurrentPageTitle(pageTitle)),
  showNoResultsInfo: () => dispatch(showNoResultsInfo()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
