interface IInfoFieldConfig extends Record<'fieldName' | 'component' | 'values' | 'type', string> {
  validate?: ((value: any) => string | Promise<void> | undefined);
  step: number;
}

export enum RadioType {
  NO_RADIO = 'none',
  OMS_WIRELESS_DEVICE = 'wireless',
  OMS_RADIO_EXTENSION = 'tower_module',
  OMS_GATEWAY = 'OMS_GATEWAY',
  LORA_ABP = 'LORA_ABP',
  LORA_OTAA = 'LORA_OTAA',
  LORA_GATEWAY = 'LORA_GATEWAY',
}

type RadioTypeOption = {
  name: string;
  id: string;
  metadataTabVisibility: {
    radioInfo: boolean;
    description: boolean;
  };
};

export const defaultRadioTypeOptions: RadioTypeOption[] = [
  {
    name: RadioType.NO_RADIO,
    id: RadioType.NO_RADIO,
    metadataTabVisibility: {
      radioInfo: false,
      description: true,
    },
  },
  {
    name: RadioType.OMS_WIRELESS_DEVICE,
    id: RadioType.OMS_WIRELESS_DEVICE,
    metadataTabVisibility: {
      radioInfo: false,
      description: true,
    },
  },
  {
    name: RadioType.OMS_RADIO_EXTENSION,
    id: RadioType.OMS_RADIO_EXTENSION,
    metadataTabVisibility: {
      radioInfo: true,
      description: true,
    },
  },
  {
    name: RadioType.LORA_ABP,
    id: RadioType.LORA_ABP,
    metadataTabVisibility: {
      radioInfo: true,
      description: false,
    },
  },
  {
    name: RadioType.LORA_OTAA,
    id: RadioType.LORA_OTAA,
    metadataTabVisibility: {
      radioInfo: true,
      description: false,
    },
  },
];

export const radioTypeOptionsByMBusDeviceTypeId: {
  [key: number]: { name: string, id: string }[]
} = {
  0x04: defaultRadioTypeOptions, // HEAT_METER
  0x06: defaultRadioTypeOptions,// WARM_WATER_METER
  0x07: defaultRadioTypeOptions, // WATER_METER
  0x08: defaultRadioTypeOptions, // HEAT_COST_ALLOCATOR
  0x0a: defaultRadioTypeOptions, // COOLING_METER_OUTLET
  0x0b: defaultRadioTypeOptions, // COOLING_METER_INLET
  0x0c: defaultRadioTypeOptions, // HEAT_METER_INLET
  0x0d: defaultRadioTypeOptions, // HEAT_COOLING_METER
  0x15: defaultRadioTypeOptions, // HOT_WATER_METER
  0x16: defaultRadioTypeOptions, // COLD_WATER_METER
  0x1a: defaultRadioTypeOptions, // SMOKE_DETECTOR
  0x99: [ // GATEWAY
    {
      name: RadioType.OMS_GATEWAY,
      id: RadioType.OMS_GATEWAY,
    },
    {
      name: RadioType.LORA_GATEWAY,
      id: RadioType.LORA_GATEWAY,
    },
  ],
};

const defaultDeviceInfoFields = [
  {
    fieldName: 'item_number',
    component: 'input',
  },
  {
    component: 'geo',
  }
];

export const deviceInfoFields: Record<string, Partial<IInfoFieldConfig>[]> = {
  [RadioType.NO_RADIO]: defaultDeviceInfoFields,
  [RadioType.OMS_RADIO_EXTENSION]: defaultDeviceInfoFields,
  [RadioType.OMS_WIRELESS_DEVICE]: defaultDeviceInfoFields,
  [RadioType.LORA_ABP]: defaultDeviceInfoFields,
  [RadioType.LORA_OTAA]: defaultDeviceInfoFields,
  [RadioType.OMS_GATEWAY]: defaultDeviceInfoFields,
  [RadioType.LORA_GATEWAY]: defaultDeviceInfoFields,
};

export enum MetadataFieldColumnSize {
  FULL = 12,
  HALF = 6,
  QUARTER = 3,
}

export const deviceDescriptionFields = {
  [RadioType.NO_RADIO]: [
    {
      fieldName: 'type',
      columnSize: MetadataFieldColumnSize.HALF,
      component: 'autosuggest',
      translateOptions: true,
      options: [
        {
          id: 'surface',
          displayName: 'surface',
        },
        {
          id: 'riser',
          displayName: 'riser',
        },
        {
          id: 'downer',
          displayName: 'downer',
        },
      ],
    },
    {
      fieldName: 'length',
      columnSize: MetadataFieldColumnSize.QUARTER,
      component: 'half_autosuggest',
      options: [
        {
          id: 80,
          displayName: '80',
        },
        {
          id: 110,
          displayName: '110',
        },
        {
          id: 130,
          displayName: '130',
        },
      ],
    },
    {
      fieldName: 'q3',
      columnSize: MetadataFieldColumnSize.QUARTER,
      component: 'half_autosuggest',
      options: [
        {
          id: 1.5,
          displayName: '1.5',
        },
        {
          id: 2.5,
          displayName: '2.5',
        },
        {
          id: 3.5,
          displayName: '3.5',
        },
        {
          id: 6.0,
          displayName: '6.0',
        },
      ],
    },
    {
      fieldName: 'attestation',
      columnSize: MetadataFieldColumnSize.HALF,
      component: 'input',
      masked: true,
    },
    {
      fieldName: 'measuring_capsule',
      columnSize: MetadataFieldColumnSize.HALF,
      component: 'autosuggest',
      translateOptions: true,
      options: [
        {
          id: 'multiple_emitter',
          displayName: 'multiple_emitter',
        },
        {
          id: 'single_beam',
          displayName: 'single_beam',
        },
      ],
    },
  ],
  [RadioType.OMS_RADIO_EXTENSION]: [
    {
      fieldName: 'type',
      columnSize: MetadataFieldColumnSize.HALF,
      component: 'autosuggest',
      translateOptions: true,
      options: [
        {
          id: 'surface',
          displayName: 'surface',
        },
        {
          id: 'riser',
          displayName: 'riser',
        },
        {
          id: 'downer',
          displayName: 'downer',
        },
      ],
    },
    {
      fieldName: 'length',
      columnSize: MetadataFieldColumnSize.QUARTER,
      component: 'half_autosuggest',
      options: [
        {
          id: 80,
          displayName: '80',
        },
        {
          id: 110,
          displayName: '110',
        },
        {
          id: 130,
          displayName: '130',
        },
      ],
    },
    {
      fieldName: 'q3',
      columnSize: MetadataFieldColumnSize.QUARTER,
      component: 'half_autosuggest',
      options: [
        {
          id: 1.5,
          displayName: '1.5',
        },
        {
          id: 2.5,
          displayName: '2.5',
        },
        {
          id: 3.5,
          displayName: '3.5',
        },
        {
          id: 6.0,
          displayName: '6.0',
        },
      ],
    },
    {
      fieldName: 'attestation',
      columnSize: MetadataFieldColumnSize.HALF,
      component: 'input',
      masked: true,
    },
    {
      fieldName: 'measuring_capsule',
      columnSize: MetadataFieldColumnSize.HALF,
      component: 'autosuggest',
      translateOptions: true,
      options: [
        {
          id: 'multiple_emitter',
          displayName: 'multiple_emitter',
        },
        {
          id: 'single_beam',
          displayName: 'single_beam',
        },
      ],
    },
  ],
  [RadioType.OMS_WIRELESS_DEVICE]: [
    {
      fieldName: 'type',
      columnSize: MetadataFieldColumnSize.HALF,
      component: 'autosuggest',
      translateOptions: true,
      options: [
        {
          id: 'surface',
          displayName: 'surface',
        },
        {
          id: 'riser',
          displayName: 'riser',
        },
        {
          id: 'downer',
          displayName: 'downer',
        },
      ],
    },
    {
      fieldName: 'length',
      columnSize: MetadataFieldColumnSize.QUARTER,
      component: 'half_autosuggest',
      options: [
        {
          id: 80,
          displayName: '80',
        },
        {
          id: 110,
          displayName: '110',
        },
        {
          id: 130,
          displayName: '130',
        },
      ],
    },
    {
      fieldName: 'q3',
      columnSize: MetadataFieldColumnSize.QUARTER,
      component: 'half_autosuggest',
      options: [
        {
          id: 1.5,
          displayName: '1.5',
        },
        {
          id: 2.5,
          displayName: '2.5',
        },
        {
          id: 3.5,
          displayName: '3.5',
        },
        {
          id: 6.0,
          displayName: '6.0',
        },
      ],
    },
    {
      fieldName: 'attestation',
      columnSize: MetadataFieldColumnSize.HALF,
      component: 'input',
      masked: true,
    },
    {
      fieldName: 'measuring_capsule',
      columnSize: MetadataFieldColumnSize.HALF,
      component: 'autosuggest',
      translateOptions: true,
      options: [
        {
          id: 'multiple_emitter',
          displayName: 'multiple_emitter',
        },
        {
          id: 'single_beam',
          displayName: 'single_beam',
        },
      ],
    },
  ],
};

export const defaultDescriptionDataFields = deviceDescriptionFields[RadioType.NO_RADIO];

const defaultRadioInfoFields = [
  {
    fieldName: 'item_number',
    component: 'input',
  },
  {
    fieldName: 'manufacturer',
    component: 'react-select',
    values: 'manufacturer',
    optionFromKey: 'name',
  },
];

export const radioInfoFields = {
  [RadioType.NO_RADIO]: [],
  [RadioType.OMS_RADIO_EXTENSION]: [
    ...defaultRadioInfoFields,
    {
      fieldName: 'device_id',
      component: 'input',
    },
    {
      fieldName: 'key',
      component: 'autosuggest',
      values: 'keys',
    },
  ],
  [RadioType.OMS_WIRELESS_DEVICE]: [],
  [RadioType.LORA_ABP]: [
    ...defaultRadioInfoFields,
    {
      fieldName: 'dev_eui',
      component: 'input',
    },
  ],
  [RadioType.LORA_OTAA]: [
    ...defaultRadioInfoFields,
  ],
  [RadioType.OMS_GATEWAY]: [
    {
      fieldName: 'server_address',
      component: 'input',
    },
  ],
  [RadioType.LORA_GATEWAY]: [
    {
      fieldName: 'server_address',
      component: 'input',
    },
  ],
};
