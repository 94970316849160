import React from 'react';
import { IconDefinition} from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-localize-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Tooltip} from "../../../components/shared/Tooltip";

type ITableActionButtonProps = {
  icon: IconDefinition;
  onClick: (value: any) => void;
  filename: string;
  label: string;
}

const TableActionButton = (props: ITableActionButtonProps) => {
  const { icon, onClick, filename, label } = props;

  return (
    <span
      className="ellipsis text-color-main mr-3"
      role="presentation"
      onClick={onClick}
      data-tip="device-type-icon"
      data-for={`${label}-${filename}`}
    >
      <Tooltip id={`${label}-${filename}`}>
        <Translate id={label} />
      </Tooltip>
      <FontAwesomeIcon icon={icon} />
    </span>
  )
}

export default TableActionButton;
