import { DeviceViews, DisplayTypes } from '@wiot/shared-domain/models/device/device';
import React from 'react';
import Toggle from '../Toggle';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../state/reducers/rootReducer';
import { clearPagination } from '../../../state/actions/savePaginationAction';
import { deviceListOptions } from './device-list-options';
import { changeContentDisplay } from '../../../state/device-manager/deviceManagerStateAction';

const DeviceListsToggle = () => {
  const dispatch = useDispatch();
  const currentPageKey = useSelector((state: AppState) => state.currentPageTitle);
  const visibleView = useSelector((state: AppState) => state.deviceManagerState.visibleView);
  const selectedContentDisplay = useSelector((state: AppState) => state.deviceManagerState.contentDisplay);
  const availableOptions = currentPageKey === 'device-manager' && visibleView === DeviceViews.TABLE
    ? deviceListOptions
    : undefined;

  const onContentDisplayChanged = (value: DisplayTypes) => {
    dispatch(clearPagination(currentPageKey));
    dispatch(changeContentDisplay(value));
  };

  return (
    <Toggle
      label="lists"
      handleToggle={ onContentDisplayChanged }
      options={ availableOptions }
      selectedOption={ selectedContentDisplay }
    />
  );
};

export default DeviceListsToggle;
