import React, { useEffect, useRef, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { MENU_ID_TABLE_CONTEXT_MENU } from './constants/context-menu-ids';

interface PortalMenuProps {
  children: React.ReactNode | React.ReactNode[];
  anchorPosition: { left: number, top: number };
  openToRightHorizontalOffset?: number;
  maxWidth?: number;
  minWidth?: number;
}

export const PortalMenu = (props: PortalMenuProps) => {
  const {
    children,
    anchorPosition,
    openToRightHorizontalOffset,
    maxWidth,
    minWidth,
  } = props;

  const [positionOpen, setPositionOpen] = React.useState<{ left: number, top: number }>({ left: 0, top: 0 });
  const [openToRight, setOpenToRight] = React.useState<boolean>(false);
  const [opacity, setOpacity] = useState(0);

  const rootElement: HTMLElement | null = document.getElementById('root');
  const treePortalMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!treePortalMenuRef) {
      return;
    }

    const menusBoundingClient = treePortalMenuRef.current?.getBoundingClientRect();
    if (!menusBoundingClient) {
      return;
    }
    const menuWidth = menusBoundingClient.width;
    const menuHeight = menusBoundingClient.height;

    const deltaTop = menuHeight !== 0 ? Math.floor(menuHeight / 2) : 0;
    let top = anchorPosition.top - deltaTop;

    let left = anchorPosition.left - menuWidth;
    if (openToRightHorizontalOffset) {
      const openMenuToTheRight = left < 0;
      setOpenToRight(openMenuToTheRight);
      left = openMenuToTheRight ? anchorPosition.left + openToRightHorizontalOffset : left;
    }
    setPositionOpen({
      left: left,
      top: top
    })
    setOpacity(treePortalMenuRef.current ? 1 : 0);
  }, [treePortalMenuRef]);

  if (!rootElement) {
    return null;
  }

  if (rootElement) {
    return ReactDOM.createPortal(
      <div
        id={ `${ MENU_ID_TABLE_CONTEXT_MENU }` }
        ref={ treePortalMenuRef }
        className={ `context-menu ${ openToRight 
          ? 'context-menu-arrow-right'
          : 'context-menu-arrow-left'}`
        }
        style={{
          top: positionOpen.top,
          left: positionOpen.left,
          width: 'fit-content',
          maxWidth: maxWidth || 'unset',
          minWidth: minWidth || 'unset',
          position: 'absolute',
          opacity: opacity,
          zIndex: 1000,
        }}
      >
        { children }
      </div>,
      rootElement,
    );
  }

  return null;
};
