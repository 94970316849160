import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux';

import DashboardReadingsBox from './DashboardReadingsBox';
import ErrorBoundary from '../../components/ErrorBoundary';
import { AppState } from '../../state/reducers/rootReducer';
import RenderOnCondition from '../../components/RenderOnCondition';
import ResponsiveContainer from '../../components/shared/ResponsiveContainer';
import DashboardConsumptionGraph from './Consumption/DashboardConsumptionGraph';

const DashboardDetails = () => {
  const isUserSuperAdmin = useSelector((appState: AppState) => appState.currentUser.permission?.superAdmin);

  return (
    <Row className="mt-3">
      <RenderOnCondition condition={ !isUserSuperAdmin }>
        <Col lg={ 4 }>
          <ErrorBoundary>
            <DashboardReadingsBox/>
          </ErrorBoundary>
        </Col>
        <ResponsiveContainer title="consumption" maxWidths={ { lg: 8, xl: 8 } } headerLevel={ 5 }>
          <div className="responsive-container__item__chart-wrapper">
            <DashboardConsumptionGraph/>
          </div>
        </ResponsiveContainer>
      </RenderOnCondition>
    </Row>
  );
};

export default DashboardDetails;
