import React from 'react';
import debounce from 'debounce-promise';
import { fetchDeviceGatewaysFromDB } from '../../api/apiHelpers';
import { SelectFieldProps } from '../FilterBar/select/components/select-props';
import { SelectableOption } from '../FilterBar/select/selectable-option';
import { AsyncSelectWrapperForFilters } from '../FilterBar/select/AsyncSelectWrapperForFilters';
import { DEBOUNCE_TIME_TO_START_SEARCH } from '../FilterBar/select/constants/debounce-time-to-start-search';

export const FilterGatewaySelect = ({ pageKey, filterField }: SelectFieldProps) => {

  const loadOptions = async (inputValue: string): Promise<SelectableOption[]> => {
    try {
      const { devices } = await fetchDeviceGatewaysFromDB({
        filters: JSON.stringify({ name: inputValue }),
      });
      const deviceOptions = devices.map((device) => {
        return({ label: `${ device.deviceId } ${ device.name }`, value: device.id } as SelectableOption);
      });
      return deviceOptions;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  return (
    <AsyncSelectWrapperForFilters<SelectableOption>
      translationId={ filterField.name }
      filterFieldKey={ filterField.stateField }
      pageKey={ pageKey }
      loadOptions={ debounce(loadOptions, DEBOUNCE_TIME_TO_START_SEARCH, { leading: true }) }
      // TODO: WIOT-1025: Filter gateway select: disable show default options
      showDefaultOptions={ true }
    />
  );
};
