import { ILegalDetails } from '@wiot/shared-domain/models/settings/settings';
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../state/reducers/rootReducer';
import LegalDetailsContent from '../../components/LegalDetails/LegalDetailsContent';
import useTranslation from '../../hooks/useTranslation';

interface IImprintProps {
  legalDetails?: ILegalDetails;
}

const Imprint = ({ legalDetails }: IImprintProps) => {
  const translate = useTranslation();

  const getContentString = () => {
    if (legalDetails && legalDetails.imprint) {
      return legalDetails.imprint.custom || legalDetails.imprint.default;
    }
    
    return translate('missing-legal-details').toString();
  };

  return <LegalDetailsContent contentString={getContentString()} />;
};

const mapStateToProps = (state: AppState) => ({
  legalDetails: state.siteSettings.legalDetails,
});

export default connect(mapStateToProps)(Imprint);
