import { Dispatch } from 'redux';
import {
  EDIT_USER_FETCHED_WITH_ROLES,
  FETCH_EDIT_USER_WITH_ROLES,
} from '../../constants/actionTypes';
import { EditUserWithRolesFetchedAction, FetchEditUserWithRolesAction } from '../../types';
import { fetchUserWithRolesFromDB } from '../../../api/apiHelpers';
import { IUser } from '@wiot/shared-domain/models/user/user';

export const editUserWithRolesFetched = (
  user: IUser,
): EditUserWithRolesFetchedAction => ({
  type: EDIT_USER_FETCHED_WITH_ROLES,
  user: user,
});

export const fetchEditUserWithRoles = (userId: string) => async (
  dispatch: Dispatch<any>,
): Promise<void> => {
  dispatch({ type: FETCH_EDIT_USER_WITH_ROLES, userId } as FetchEditUserWithRolesAction);
  const user = await fetchUserWithRolesFromDB(userId);
  dispatch(editUserWithRolesFetched(user));
};
