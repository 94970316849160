import * as React from 'react';
import { connect } from 'react-redux';
import { DataIntegrationJob } from '@wiot/shared-domain/models/data-integration/data-integration';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import TableDataHeader from '../../../components/Table/TableDataHeader';
import NoResultsInfo from '../../../components/Table/NoResultsInfo';
import { AppState } from '../../../state/reducers/rootReducer';
import TableDataRow from './TableDataRow';
import RenderOnCondition from '../../../components/RenderOnCondition';

export interface TableDataProps {
  dataIntegrationJobs: DataIntegrationJob[];
  isLoading: boolean;
  refreshData: (column?: IColumnObject) => void;
  removeUnit: (id: string) => Promise<void>;
  isSelectAllChecked?: boolean;
}

export interface TableDataState {
  columns: IColumnObject[];
}

const TABLE_HEADERS = ['description', 'created-at', 'comment', 'active', 'actions'];

class TableData extends React.Component<TableDataProps, TableDataState> {
  constructor(props: TableDataProps) {
    super(props);
    this.state = {
      columns: [],
    };
  }

  componentDidMount = () => {
    this.props.refreshData();
    this.initState();
  };

  initState = () => {
    const columnState = TABLE_HEADERS.map((headerName: string) => ({
      name: headerName,
      visible: true,
      sortable: true,
    }));

    this.setState({ columns: columnState });
  };

  render() {
    if (this.props.isLoading) {
      return <LoadingIcon />;
    }

    const existsAnyIntegrationJob = (this.props.dataIntegrationJobs && this.props.dataIntegrationJobs.length);
    return (
      <div className="device-manager__table__table__container device-messages__table__table__container">
        <table className="device-manager__table__table">
          <TableDataHeader
            withCheckbox={false}
            tableHeaders={this.state.columns}
            isSelectAllChecked={this.props.isSelectAllChecked}
          />
          <tbody>
            <RenderOnCondition condition={existsAnyIntegrationJob}>
              {this.props.dataIntegrationJobs.map((dataIntegrationJob: DataIntegrationJob) => (
                  <TableDataRow
                    dataIntegrationJob={dataIntegrationJob}
                    key={dataIntegrationJob.id}
                    removeUnit={this.props.removeUnit}
                    refreshData={this.props.refreshData}
                  />
              ))}
            </RenderOnCondition>
          </tbody>
        </table>
        <RenderOnCondition condition={!existsAnyIntegrationJob}>
          <NoResultsInfo />
        </RenderOnCondition>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  dataIntegrationJobs: state.dataIntegrationJobs,
  isLoading: state.isLoading,
});

export default connect(mapStateToProps)(TableData);
