import { Device } from '@wiot/shared-domain/models/device/device';
import { MonthlyEndValuesByDevice } from './monthly-end-values-by-device';

export const FETCH_LAST_14_MONTHLY_END_VALUES = 'FETCH_LAST_14_MONTHLY_END_VALUES';
export const LAST_14_MONTHLY_END_VALUES_FETCHED = 'LAST_14_MONTHLY_END_VALUES_FETCHED';

export interface Last14MonthlyEndValuesAction {
  type: typeof LAST_14_MONTHLY_END_VALUES_FETCHED;
  payload: MonthlyEndValuesByDevice;
}

export interface FetchLast14MonthlyEndValuesAction {
  type: typeof FETCH_LAST_14_MONTHLY_END_VALUES;
  device: Device;
}
