import React from 'react';
import { toastr } from 'react-redux-toastr';
import { withLocalize, LocalizeContextProps, Translate } from 'react-localize-redux';
import { uploadCSV } from '../../../../api/apiHelpers';

interface ICsvImportButtonProps extends LocalizeContextProps {
  updateTableAndClose: () => void;
  acceptedCsvFile?: string | Blob;
  isCSVValid?: boolean;
}

const CsvImportButton = (props: ICsvImportButtonProps) => {
  const { translate, updateTableAndClose, acceptedCsvFile, isCSVValid } = props;

  const onCSVUploadApproved = async () => {
    toastr.info(translate('upload-csv').toString(), translate('csv-upload-started').toString());
    if (acceptedCsvFile) {
      const formData = new FormData();
      formData.append('csvFile', acceptedCsvFile);
      const data = await uploadCSV(formData);
      if (data) {
        updateTableAndClose();
        toastr.success(
          translate('upload-csv').toString(),
          translate('upload-completed').toString(),
        );
      }
    }
  };

  return (
    <button
      type="button"
      className="form__button--blue background-color-main text-color-white border-color-main"
      onClick={onCSVUploadApproved}
      disabled={!isCSVValid}
    >
      <Translate id="import" />
    </button>
  );
};

export default withLocalize(CsvImportButton);
