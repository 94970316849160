
export const KEY_NAME_HEADER = 'key-name';
export const DEVICE_GROUP_HEADER = 'device-group';
export const CREATED_HEADER = 'created';
export const DEVICE_COUNT_HEADER = 'devices';
export const KEY_TYPE_HEADER = 'key-type';
export const ACTIONS_HEADER = 'actions';

export const RADIO_KEY_TABLE_HEADERS = [
  KEY_NAME_HEADER,
  DEVICE_GROUP_HEADER,
  CREATED_HEADER,
  DEVICE_COUNT_HEADER,
  KEY_TYPE_HEADER,
  ACTIONS_HEADER,
];
