import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import RenderOnCondition from '../../../components/RenderOnCondition';
import ConfirmModal, { ConfirmationVariant } from '../../../components/Modal/ConfirmModal';
import DeviceGroupDetailsModal from '../DeviceTypeDetailsModal';
import { DeviceTypeExtended } from '../../../state/types';
import DeviceTypeActionModal from '../DeviceTypeActionModal';
import CustomCheckbox from '../../../components/shared/CustomCheckbox';
import {
  getDeviceTypeIconFullPath,
  getTranslationValueInCurrentLanguage,
} from '../../../utils/common';
import { IconVariant } from '../../../constants';
import { PortalMenu } from '../../../components/PortalMenu/PortalMenu';
import TableMenu from './TableMenu';
import {
  HORIZONTAL_OFFSET_TABLE_VIEW,
  VERTICAL_OFFSET_TABLE_VIEW
} from '../../../components/PortalMenu/constants/offset-table-view';
import { AppState } from '../../../state/reducers/rootReducer';
import { MENU_ID_TABLE_CONTEXT_MENU } from '../../../components/PortalMenu/constants/context-menu-ids';
import { closeContextMenu, openContextMenu } from '../../../state/context-menu/toggleContextMenuActionCreators';
import { toggleContextMenuActionTypes } from '../../../state/context-menu/toggleContextMenuAction';
import { Tooltip } from '../../../components/shared/Tooltip';

export interface TableDataRowProps {
  deviceType: DeviceTypeExtended;
  selectOne: (id: string) => void;
  deleteDeviceType: (deviceType: IDeviceType) => Promise<void>;
  refreshGroups: () => void;
  visibleContextMenu?: string;
  openContextMenu: (id: string) => toggleContextMenuActionTypes;
  closeContextMenu: () => toggleContextMenuActionTypes;
}

export interface TableDataRowState {
  showDetails: boolean;
  showMenu: boolean;
  toggleMenuButtonBoundingClientRect: ClientRect;
  showDeviceTypeActionModal: boolean;
  showDeleteModal: boolean;
}

class TableDataRow extends React.Component<TableDataRowProps, TableDataRowState> {
  private toggleMenuButton: HTMLDivElement | null;
  private menuId = `${ MENU_ID_TABLE_CONTEXT_MENU }_${ this.props.deviceType.id }`;
  private showMenu = (this.props.visibleContextMenu === this.menuId);

  constructor(props: TableDataRowProps) {
    super(props);
    this.state = {
      showDetails: false,
      showMenu: false,
      // @ts-ignore
      toggleMenuButtonBoundingClientRect: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      showDeviceTypeActionModal: false,
      showDeleteModal: false,
    };
  }

  closeMenu = (event?: React.MouseEvent) => {
    event && event.stopPropagation();
    this.props.closeContextMenu();
  }

  toggleMenu = async () => {
    if (!this.showMenu) {
      this.props.openContextMenu(this.menuId);
      return;
    }
    this.closeMenu();
  };

  private onToggleMenuButtonClicked = () => {
    this.updateBoundingClientRect();
    this.toggleMenu();
  };

  private toggleDeviceGroupActionModal = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState((prevState: TableDataRowState) => ({
      showDeviceTypeActionModal: !prevState.showDeviceTypeActionModal,
    }));
  };

  private toggleDeleteModal = (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState((prevState: TableDataRowState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }));
  };

  private toggleDetails = (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState((prevState: TableDataRowState) => ({
      showDetails: !prevState.showDetails,
    }));
  };

  private updateBoundingClientRect = () => {
    if (this.toggleMenuButton) {
      this.setState({
        toggleMenuButtonBoundingClientRect: this.toggleMenuButton.getBoundingClientRect(),
      });
    }
  };

  public render() {
    const { deviceType, selectOne, deleteDeviceType, refreshGroups } = this.props;
    const {
      showDeviceTypeActionModal,
      showDeleteModal,
      showDetails,
      showMenu,
      toggleMenuButtonBoundingClientRect,
    } = this.state;

    return (
      <tr key={deviceType.id} id={deviceType.id} className="device-manager__table__table__trow">
        <td>
          <CustomCheckbox onChange={() => selectOne(deviceType.id)} checked={deviceType.checked} />
        </td>
        <td className="device-manager__table__table__td">
          <img
            src={getDeviceTypeIconFullPath(deviceType, IconVariant.small)}
            alt="icon"
            width="30"
            height="30"
          />
        </td>
        <td
          className="device-manager__table__table__td device-manager__table__table__link"
          onClick={this.toggleDetails}
        >
          {getTranslationValueInCurrentLanguage(deviceType.name) || '-'}
        </td>
        <td className="device-manager__table__table__td">
          <Translate id={deviceType.isSystem ? 'true' : 'false'} />
        </td>
        <td>
          <div className="device-details">
            <div
              className="ellipsis text-color-main"
              style={{ margin: '0 auto' }}
              role="presentation"
              ref={(tc) => {
                this.toggleMenuButton = tc;
              }}
              onClick={this.onToggleMenuButtonClicked}
              data-tip="table-actions-button-tooltip"
              data-for="table-actions-button-tooltip"
            >
              <FontAwesomeIcon icon={faCog} />
            </div>
            <Tooltip id="table-actions-button-tooltip">
              <Translate id="actions" />
            </Tooltip>
            <RenderOnCondition condition={showMenu}>
              <PortalMenu
                anchorPosition={{
                  left: toggleMenuButtonBoundingClientRect.left + HORIZONTAL_OFFSET_TABLE_VIEW,
                  top: toggleMenuButtonBoundingClientRect.top + window.scrollY + VERTICAL_OFFSET_TABLE_VIEW
                }}
                minWidth={ 315 }
              >
                <TableMenu
                  deviceType={ deviceType }
                  menuId={ this.menuId }
                  toggleDetails={ this.toggleDetails }
                  toggleDeviceGroupActionModal={ this.toggleDeviceGroupActionModal }
                  toggleDeleteModal={ this.toggleDeleteModal }
                />
              </PortalMenu>
            </RenderOnCondition>
            <RenderOnCondition condition={showDetails}>
              <DeviceGroupDetailsModal
                closeModal={this.toggleDetails}
                deviceTypeData={deviceType}
              />
            </RenderOnCondition>
            <RenderOnCondition condition={showDeviceTypeActionModal}>
              <DeviceTypeActionModal
                closeAddAndUpdateModal={this.toggleDeviceGroupActionModal}
                title="update-group"
                showDeleteButton
                removalRequested={() => deleteDeviceType(deviceType)}
                deviceType={deviceType}
                deviceTypeChanged={refreshGroups}
              />
            </RenderOnCondition>
            <RenderOnCondition condition={showDeleteModal}>
              <ConfirmModal
                modalCloseRequested={this.toggleDeleteModal}
                actionConfirmed={() => deleteDeviceType(deviceType)}
                translationIdOfElementType="device-type"
                confirmationVariant={ ConfirmationVariant.DELETE }
              />
            </RenderOnCondition>
          </div>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  visibleContextMenu: state.contextMenu.visibleContextMenuId,
});

const mapDispatchToProps = (dispatch: Dispatch<toggleContextMenuActionTypes>) => ({
  openContextMenu: (id: string) => dispatch(openContextMenu(id)),
  closeContextMenu: () => dispatch(closeContextMenu()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableDataRow);
