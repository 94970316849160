import { DeviceReading } from '@wiot/shared-domain/models/device-reading/device-reading';
import { SAVE_DEVICE_MESSAGES } from '../constants/actionTypes';
import { DeviceMessagesActionTypes } from '../types';

export const saveDeviceMessages = (
  deviceMessages: DeviceReading[] = [],
): DeviceMessagesActionTypes => ({
  type: SAVE_DEVICE_MESSAGES,
  payload: deviceMessages,
});
