import React from 'react';
import { Translate } from 'react-localize-redux';
import './DeviceReadingAccessPeriodsFormSection.less';
import { IsoStringDateRange, IsoStringDateRangeFactory, } from '@wiot/shared-domain/models/date/iso-string-date-range';
import { DateRangePickerRow } from './DateRangePickerRow';
import { DATE_PICKER_PORTAL_ID } from './date-picker-portal-id';

interface DeviceReadingAccessPeriodsFormSectionProps {
  accessPeriods: IsoStringDateRange[];
  onChange?: (changedAccessPeriods: IsoStringDateRange[]) => void;
}

export const DeviceReadingAccessPeriodsFormSection = ({
  accessPeriods,
  onChange,
}: DeviceReadingAccessPeriodsFormSectionProps): JSX.Element => {
  function getRows(): JSX.Element[] {
    if (!accessPeriods) {
      return [];
    }
    return accessPeriods.map((accessPeriod, index) => {
      const isLastItem = index === accessPeriods.length - 1;
      return (
        <DateRangePickerRow
          key={ `deviceReadingAccessPeriodRow-${ index.toString() }` }
          dateRange={ accessPeriod }
          onChange={ (prevAccessPeriod, newAccessPeriod) => {
            const newAccessPeriods = [...accessPeriods];
            const prevAccessPeriodIndex = newAccessPeriods.indexOf(prevAccessPeriod);
            newAccessPeriods[prevAccessPeriodIndex] = newAccessPeriod;
            onChange && onChange(newAccessPeriods);
          } }
          deleteButtonClicked={ () => {
            const newAccessPeriods = [...accessPeriods];
            newAccessPeriods.splice(accessPeriods.indexOf(accessPeriod), 1);
            onChange && onChange(newAccessPeriods);
          } }
          addButtonClicked={
            isLastItem
              ? () => {
                const newAccessPeriods: IsoStringDateRange[] = [
                  ...accessPeriods,
                  IsoStringDateRangeFactory.createStartTodayMidnight(),
                ];
                onChange && onChange(newAccessPeriods);
              }
              : undefined
          }
          portalTargetId={ DATE_PICKER_PORTAL_ID }
        />
      );
    });
  }

  function getTitle() {
    return (
      <label className="form__label mb-2 mt-2">
        <Translate id="device-readings-access-periods"/>
      </label>
    );
  }

  function getEmptyNote(): JSX.Element {
    return (
      <div className="devicereadingaccessperiodsformsection-emptynote italic mb-2">
        <span className="warning-panel">
          <span className="text-secondary">
            <Translate id="no-device-readings-access-periods"/>{ ' ' }
          </span>
          <a
            className="text-primary"
            href=""
            onClick={ (event) => {
              event.preventDefault();
              onChange && onChange([IsoStringDateRangeFactory.createStartTodayMidnight()]);
            } }
          >
            <Translate id="add"/>?
          </a>
        </span>
      </div>
    );
  }

  return (
    <div
      className="devicereadingaccessperiodsformsection mb-2"
      id={ DATE_PICKER_PORTAL_ID }
    >
      { getTitle() }
      { accessPeriods.length > 0 ? getRows() : getEmptyNote() }
    </div>
  );
};
