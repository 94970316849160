import { toastr } from 'react-redux-toastr';
import { TranslateFunction } from 'react-localize-redux';
import OnDependencyDeleteToastr, { TOAST_VISIBILITY_DURATION, } from '../../components/shared/OnDependencyDeleteToastr';
import { RoleUsage } from '@wiot/shared-domain/models/user-role/role-usage';
import React from 'react';

function showDeleteToastrOnConflictingRoleUsage(
  translate: TranslateFunction,
  conflictingUserRoles: RoleUsage[],
){
  for (var roleUsage of conflictingUserRoles) {
    if (roleUsage.deviceGroupName && roleUsage.roleName) {
      const toastrOptions = {
        timeOut: TOAST_VISIBILITY_DURATION,
        preventDuplicates: false,
        component: (
          <OnDependencyDeleteToastr
            dgName={ roleUsage.deviceGroupName }
            roleName={ roleUsage.roleName }
            userIdentifier={ roleUsage.userIdentifier }
            type={ translate('userRole').toString() }
          />
        ),
      };
      toastr.error(
        translate('ERROR').toString(),
        translate('delete-failed-dependency-found').toString(),
        toastrOptions,
      );
    }
  }
};

export default showDeleteToastrOnConflictingRoleUsage;
