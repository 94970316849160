import { faEdit, faFileExport, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Device, DeviceViews } from '@wiot/shared-domain/models/device/device';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import Portal from '../shared/Portal';
import ConfirmModal, { ConfirmationVariant } from '../Modal/ConfirmModal';
import { AppState } from '../../state/reducers/rootReducer';
import DeviceListsToggle from './device-lists-toggle/DeviceListsToggle';
import ToggleView, { ToggleViewProps } from './ToggleView';
import RenderOnCondition from '../RenderOnCondition';
import DeviceExportModal from '../../pages/DeviceManager/DeviceExport/DeviceExportModal';
import DeviceMassEditModal from '../../pages/DeviceManager/DeviceMassEditModal';
import { PaginationState } from '../../state/reducers/paginationReducer';
import { PageKeys } from '../../state/reducers/filterSortReducer';
import { AggregatedPermission } from '@wiot/shared-domain/models/role/role';
import HasPermission from '../HasPermission';
import { kebabToCamelCase } from '../../utils/common';
import { Tooltip } from '../shared/Tooltip';

export interface TableHeaderProps extends ToggleViewProps, LocalizeContextProps {
  devices: Device[];
  disableControls?: boolean;
  page: PageKeys;
  addModal: boolean;
  addText: string;
  elementType: string;
  changeView: boolean;
  selectedRows: Partial<Device>[] | DeviceGroup[] | IDeviceType[]; // TODO: This should be solved more generic. Maybe with a own type for a row.
  visibleView?: DeviceViews;
  currentEntriesPerPage: number;
  pagination: PaginationState;
  handleBulkRemove?: () => void;
  showExportButton?: boolean;
  refreshTableData: () => void;
  editorModalCreator?: (toggleShowEditorModal: () => void) => JSX.Element;
  isKeyManagerModeEnabled: boolean;
  permission?: AggregatedPermission;
}

export interface TableHeaderState {
  showEditorModal: boolean;
  showMassEdit: boolean;
  showMassDelete: boolean;
  showDeviceExportModal: boolean;
}

class TableHeader extends React.Component<TableHeaderProps, TableHeaderState> {
  constructor(props: TableHeaderProps) {
    super(props);
    this.state = {
      showEditorModal: false,
      showMassEdit: false,
      showMassDelete: false,
      showDeviceExportModal: false,
    };
  }

  toggleShowEditorModal = () => {
    this.setState((prevState) => ({
      showEditorModal: !prevState.showEditorModal,
    }));
  };

  private showMassEditModal = (isShow: boolean) => {
    this.setState({
      showMassEdit: isShow,
    });
  };

  private showMassDeleteModal = (isShow: boolean) => {
    this.setState({
      showMassDelete: isShow,
    });
  };

  private toggleShowDeviceExportModal = () => {
    this.setState((prevState) => ({
      showDeviceExportModal: !prevState.showDeviceExportModal,
    }));
  };

  private getAddModal = () => {
    const { editorModalCreator } = this.props;

    if (editorModalCreator) {
      return editorModalCreator(this.toggleShowEditorModal);
    }

    return null;
  };

  private displayMassEditModal = () => (
    <DeviceMassEditModal
      closeAddAndUpdateModal={ () => this.showMassEditModal(false) }
      title="mass-edit"
      selectedDevices={ this.props.selectedRows as Device[] }
      refreshDevices={ this.props.refreshTableData }
    />
  );

  private getLastEntry = () => {
    const { page, pagination, currentEntriesPerPage } = this.props;
    const { currentPage, totalDocs } = pagination[page];
    let lastEntry = currentPage * currentEntriesPerPage;
    lastEntry = lastEntry > totalDocs ? totalDocs : lastEntry;
    return lastEntry;
  };

  private getMassEditButton = () => {
    const { translate, permission } = this.props;

    return (
      <HasPermission permissionObj={permission} permissionKey="devices.edit.basic">
        <div
          className="change-view__button change-view__button--last"
          data-tip={ translate('update') }
          onClick={ () => this.showMassEditModal(true) }
        >
          <FontAwesomeIcon icon={ faEdit }/>
        </div>
      </HasPermission>
    );
  };

  private getMassDeleteButton = () => {
    const { translate, permission } = this.props;

    return (
      <HasPermission permissionObj={permission} permissionKey="devices.remove">
        <Tooltip
          id="device-remove-tooltip"
          className="type-dark z-index-1000"
        />
        <div
          className="change-view__button"
          data-tip={ translate('delete-permanently') }
          onClick={ () => this.showMassDeleteModal(true) }
        >
          <FontAwesomeIcon icon={ faTrashAlt }/>
        </div>
      </HasPermission>
    );
  };

  private getHeaderText = () => {
    const { page } = this.props;
    switch (page) {
      case 'device-messages':
      case 'backups':
        return 'device-messages-table-header-subheading';
      default:
        return 'table-header-subheading';
    }
  };

  private handleConfirmBulkRemove = () => {
    this.props.handleBulkRemove && this.props.handleBulkRemove();
    this.showMassDeleteModal(false);
  };

  private isVisibleViewTableLike = (): boolean => {
    const { visibleView } = this.props;
    return !visibleView || visibleView === DeviceViews.TABLE;
  };

  render() {
    const {
      disableControls,
      page,
      pagination,
      currentEntriesPerPage,
      selectedRows,
      elementType,
      translate,
      changeView,
      visibleView,
      viewOptions,
      handleViewToggle,
      showExportButton,
      addModal,
      addText,
      isKeyManagerModeEnabled,
      permission,
    } = this.props;
    const { currentPage, totalDocs } = pagination[page];
    const firstEntry = (currentPage - 1) * currentEntriesPerPage + 1;
    const lastEntry = this.getLastEntry();
    const allDevices = totalDocs;
    const selectedRowsLength = selectedRows ? selectedRows.length : 0;
    const showMassEditButton = page === 'device-manager' && selectedRowsLength > 0 && this.isVisibleViewTableLike();
    const showMassDeleteButton = selectedRowsLength > 0 && this.isVisibleViewTableLike();

    return (
      <header className="table__header" data-testid="table-header">
        <h4 className="table__header__subheading" data-testid="table-subheading">
          { totalDocs && this.isVisibleViewTableLike() ? (
            <Translate
              id={ this.getHeaderText() }
              data={ {
                firstEntry,
                lastEntry,
                allDevices,
                selectedRowsLength,
              } }
            />
          ) : null }
        </h4>
        <RenderOnCondition condition={!isKeyManagerModeEnabled}>
          <DeviceListsToggle/>
        </RenderOnCondition>
        <div className="grow"/>
        <RenderOnCondition condition={showMassDeleteButton}>
          { this.getMassDeleteButton() }
        </RenderOnCondition>
        <RenderOnCondition condition={showMassEditButton}>
          { this.getMassEditButton() }
        </RenderOnCondition>
        <Tooltip
          id="table-header-select-view-tooltip"
          className="type-dark z-index-1000"
        />
        { changeView && (
          <div className="change-view" data-testid="table-view-toggle">
            <ToggleView
              viewOptions={ viewOptions }
              handleViewToggle={ handleViewToggle }
              selectedView={ visibleView }
            />
          </div>
        ) }
        <HasPermission permissionObj={permission} permissionKey='devices.canExport'>
          <RenderOnCondition condition={showExportButton}>
            <div className="toggle-btn-group-wrapper__container">
              <span className="table__header__subheading views">
                <Translate id="billing"/>
              </span>
              <button
                className="export__button"
                onClick={ this.toggleShowDeviceExportModal }
                data-tip={ translate('export') }
              >
                <label className="export__button__icon">
                  <FontAwesomeIcon icon={ faFileExport }/>
                </label>
              </button>
            </div>
          </RenderOnCondition>
        </HasPermission>
        <RenderOnCondition condition={addModal}>
          <HasPermission permissionObj={permission} permissionKey={`${kebabToCamelCase(elementType)}.add`}>
            <div className="toggle-btn-group-wrapper__container">
              <span className="table__header__subheading views">
                <Translate id={`add-${ addText }`}/>
              </span>
              <button
                className="add__button"
                onClick={ this.toggleShowEditorModal }
                disabled={ disableControls }
                data-tip={ disableControls ? 'disabled-controls-tooltip' : null }
                data-for={ disableControls ? 'disabled-controls-tooltip' : null }
              >
                <label className="add__button__icon">
                  <FontAwesomeIcon icon={ faPlus }/>
                </label>
              </button>
            </div>
          </HasPermission>
        </RenderOnCondition>
        <Portal>
          { this.state.showMassEdit && this.displayMassEditModal() }
          <RenderOnCondition condition={ this.state.showEditorModal }>
            { this.getAddModal() }
          </RenderOnCondition>
          <RenderOnCondition condition={ this.state.showDeviceExportModal }>
            <DeviceExportModal handleClose={ this.toggleShowDeviceExportModal }/>
          </RenderOnCondition>
          <ConfirmModal
            show={ this.state.showMassDelete }
            modalCloseRequested={ () => this.showMassDeleteModal(false) }
            actionConfirmed={ this.handleConfirmBulkRemove }
            countOfElements={ selectedRowsLength }
            translationIdOfElementType={ elementType }
            translationIdOfAdditionalInfo={ this.props.page === 'device-groups' ? 'confirm-to-delete-group' : '' }
            confirmationVariant={ ConfirmationVariant.DELETE }
          />
        </Portal>
      </header>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  devices: state.devices,
  pagination: state.pagination,
  currentEntriesPerPage: state.currentEntriesPerPage,
  isKeyManagerModeEnabled: !!state.siteSettings.isKeyManagerModeEnabled,
  permission: state.currentUser.permission,
});

export default connect(mapStateToProps)(withLocalize(TableHeader));
