import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { getNameAndValueOfMBusDeviceType, IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { MapContainer, Marker } from 'react-leaflet';
import {DeviceMetadata, DeviceMetadataField, DeviceMetadataFields} from '@wiot/shared-domain/models/device/device';
import { IDeviceRole } from '@wiot/shared-domain/models/role/role';
import { getTranslationValueInCurrentLanguage } from '../../../utils/common';
import CustomTileLayer from '../../../components/Map/CustomTileLayer';
import { leafletDeviceTypeIcon } from '../../../components/Map/icons';
import { InvalidateSize } from '../../../components/Map/InvalidateSize';
import { DEFAULT_MAP_ZOOM } from '../../../constants';
import DetailCol from '../DetailCol';
import HasPermission from '../../../components/HasPermission';
import RenderOnCondition from '../../../components/RenderOnCondition';
import { ACLDeviceView } from '@wiot/shared-domain/models/role/access-level';
import { DeviceFields } from '@wiot/shared-domain/models/device/device-fields';
import {
  defaultDescriptionDataFields,
  MetadataFieldColumnSize,
} from "../../../config/device-meta-data";

interface DeviceMetaDataProps {
  field: string;
  data?: DeviceMetadata;
  permission?: IDeviceRole;
  deviceType?: IDeviceType;
}

const translatableValues = [DeviceFields.RADIO_TYPE, 'medium', 'probe_type'];

const DeviceMetaData = ({ field, data, permission, deviceType }: DeviceMetaDataProps) => {
  if (!data) {
    return null;
  }

  const { deviceDescription, deviceInfo, radioInfo, metadata } = data;

  const getValueFromRadioInfo = (info: { key: string; value: any }) => {
    const { key, value } = info;

    switch (key) {
      case 'device_type': {
        const dt = getNameAndValueOfMBusDeviceType(value);
        return getTranslationValueInCurrentLanguage(dt.name);
      }
      default:
        return value;
    }
  };

  const getGeoCoordinates = () => {
    const lat = Number(deviceInfo?.find((info) => info.key === 'latitude')?.value);
    const lng = Number(deviceInfo?.find((info) => info.key === 'longitude')?.value);
    if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
      return { lat, lng };
    }
    return null;
  };
  const geoCoordinates = getGeoCoordinates();

  const getFilteredDeviceInfo = () => {
    // To filter out infos already displayed somewhere else
    if (!deviceInfo) {
      return [];
    }
    return deviceInfo.filter(info => info.key.toString() !== DeviceFields.RADIO_TYPE )
  };
  const filteredDeviceInfo = getFilteredDeviceInfo();

  const getLabel = (description: DeviceMetadataField | undefined) => {
    if (!description || !description.value) {
      return '-';
    }
    if (translatableValues.includes(description.key)) {
      return <Translate id={ description.value } /> || '-';
    }
    return description.value || '-';
  }

  const isDeviceInfoFieldVisible = !!(deviceDescription && deviceDescription.length && deviceInfo);
  return (
    <>
      <RenderOnCondition condition={ field === DeviceMetadataFields.deviceDescription }>
        <HasPermission permissionObj={ permission } permissionKey={ ACLDeviceView.DESCRIPTION }>
          { deviceDescription && deviceDescription.length ? (
            <Row>
              { defaultDescriptionDataFields.map( value => {
                const { fieldName, columnSize } = value;
                const description = deviceDescription.find((d) => d.key === fieldName);
                return (
                  <DetailCol
                    translateKey={ fieldName }
                    key={ fieldName }
                    colSize={ columnSize || 6 }
                  >
                    <p className="details__text">
                      { getLabel(description) }
                    </p>
                  </DetailCol>
                );
              }) }
            </Row>
          ) : null }
        </HasPermission>
      </RenderOnCondition>
      <RenderOnCondition condition={ field === DeviceMetadataFields.radioInfo }>
        <HasPermission permissionObj={ permission } permissionKey={ ACLDeviceView.RADIO_INFO }>
          { radioInfo && radioInfo.length ? (
            <Row>
              { radioInfo.map((info) => (
                <DetailCol
                  translateKey={ info.key }
                  key={ info.key }
                >
                  <p className="details__text">
                    { translatableValues.includes(info.key) ? (
                      <Translate id={ info.value } />
                    ) : (
                      getValueFromRadioInfo(info) || '-'
                    ) }
                  </p>
                </DetailCol>
              )) }
            </Row>
          ) : null }
        </HasPermission>
      </RenderOnCondition>

      <RenderOnCondition condition={ field === DeviceMetadataFields.metadata }>
        <HasPermission permissionObj={ permission } permissionKey={ ACLDeviceView.METADATA }>
          <Row>
            <Col lg={ 12 }>
              <Row>
                { metadata &&
                  metadata.map((info) => (
                    <DetailCol
                      translateKey={ info.key }
                      key={ info.key }
                    >
                      <p className="details__text">
                        { getLabel(info) }
                      </p>
                    </DetailCol>
                  )) }
              </Row>
            </Col>
          </Row>
          <Row>
            { geoCoordinates && (
              <Col lg={ 12 }>
                <ReactVisibilitySensor>
                  { ({ isVisible }) => (
                    <MapContainer
                      center={ geoCoordinates }
                      zoom={ DEFAULT_MAP_ZOOM }
                      className="map-container-small"
                    >
                      <CustomTileLayer />
                      { isVisible && <InvalidateSize /> }
                      <Marker
                        position={ [geoCoordinates.lat, geoCoordinates.lng] }
                        icon={ leafletDeviceTypeIcon(deviceType) }
                      />
                    </MapContainer>
                  ) }
                </ReactVisibilitySensor>
              </Col>
            ) }
          </Row>
        </HasPermission>
      </RenderOnCondition>

      <RenderOnCondition condition={ field === DeviceMetadataFields.deviceInfo }>
        <HasPermission permissionObj={ permission } permissionKey={ ACLDeviceView.METADATA }>
          <RenderOnCondition condition={ isDeviceInfoFieldVisible }>
            <Row>
              { filteredDeviceInfo.map((info) => {
                const { key, value } = info;
                const { fieldName, columnSize } = value;
                return (
                  <DetailCol
                    translateKey={ fieldName  || key }
                    key={ fieldName  || key }
                    colSize={ columnSize || fieldName ? MetadataFieldColumnSize.HALF : MetadataFieldColumnSize.QUARTER }
                  >
                    <p className="details__text">
                      { getLabel(info) }
                    </p>
                  </DetailCol>
                );
              })}
            </Row>
          </RenderOnCondition>
        </HasPermission>
      </RenderOnCondition>
    </>
  );
};

export default DeviceMetaData;
