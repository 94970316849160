import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Ancestor } from '@wiot/shared-domain/models/device-group/device-group';
import { AggregatedPermission } from '@wiot/shared-domain/models/role/role';
import Portal from '../../../components/shared/Portal';
import DeviceGroupDetailsModal from '../../DeviceGroups/DeviceGroupDetailsModal';
import DeviceDetailsModal from '../../DeviceManager/DeviceDetailsModal/DeviceDetailsModal';
import DeviceMessagesDetailsModal from '../DeviceMessagesDetailsModal';
import QUESTION_MARK from '../../../assets/devices/question-mark-small.svg';
import { IconVariant, sourceTypeIconSmall } from '../../../constants';
import {
  getDeviceTypeIconFullPath,
  getSourceTypeName,
  getTranslationValueInCurrentLanguage,
  localizeDate,
} from '../../../utils/common';
import { getDeviceGroupPathFromAncestors } from '../../../utils/device-group-path-helper';
import { AppState } from '../../../state/reducers/rootReducer';
import RenderOnCondition from '../../../components/RenderOnCondition';
import HasPermission from '../../../components/HasPermission';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import { FormattedDeviceReadingValue } from '../../../components/shared/FormattedDeviceReadingValue';
import { Tooltip } from '../../../components/shared/Tooltip';

interface TableDataRowProps {
  message: any;
  permission?: AggregatedPermission;
  refreshData: (column?: IColumnObject) => void;
}

interface TableDataRowState {
  showDeviceDetails: boolean;
  showGroupDetails: boolean;
  showMessageDetails: boolean;
}

class TableDataRow extends React.Component<TableDataRowProps, TableDataRowState> {
  constructor(props: TableDataRowProps) {
    super(props);
    this.state = {
      showDeviceDetails: false,
      showGroupDetails: false,
      showMessageDetails: false,
    };
  }

  handleClickOutside = () => {
    this.setState({
      showDeviceDetails: false,
      showGroupDetails: false,
    });
  };

  setShowDeviceDetails = async (deviceId: string) => {
    if (deviceId) {
      this.setState({
        showDeviceDetails: true,
      });
    }
  };

  showGroupDetails = async (deviceGroupId: string) => {
    if (deviceGroupId) {
      this.setState({
        showGroupDetails: true,
      });
    }
  };

  showMessageDetails = () => {
    this.setState({
      showMessageDetails: true,
    });
  };

  closeDetails = (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
    event && event.stopPropagation();
    this.setState({
      showDeviceDetails: false,
      showMessageDetails: false,
      showGroupDetails: false,
    });
  };

  getSelectedTree = () => {
    const { ancestors } = this.props.message;
    if (ancestors && ancestors.length > 0) {
      const path = getDeviceGroupPathFromAncestors(ancestors);
      return path.truncatedPath;
    }
  };

  render() {
    const { id, receivedAt, device, values, source, gateway } = this.props.message;
    const { showDeviceDetails, showGroupDetails } = this.state;

    const deviceGroupData = device ? device.deviceGroup : undefined;

    const sourceTypeName = getSourceTypeName(source);

    let gatewayId = '';
    if (gateway) {
      gatewayId = typeof gateway === 'string' ? gateway : gateway.id;
    }

    const localizedReceivedAt = localizeDate(receivedAt);

    return (
      <tr key={id} id={id} className="device-manager__table__table__trow">
        <td className="device-manager__table__table__td device-manager__table__table__trow__device-type-icon__wrapper">
          <img
            data-testid="device-manager__table__table__trow__device-type-icon"
            data-tip="device-type-icon"
            data-for={`deviceType-${id}-${device.deviceType.id}`}
            src={getDeviceTypeIconFullPath(device.deviceType, IconVariant.small)}
            alt="device type"
          />
          <Tooltip
            id={`deviceType-${id}-${device.deviceType.id}`}
            place="right"
          >
            {getTranslationValueInCurrentLanguage(device.deviceType.name)}
          </Tooltip>
        </td>

        <td
          className="device-manager__table__table__td device-manager__table__table__link"
          onClick={() => this.setShowDeviceDetails(device.id)}
        >
          {device.deviceId}
        </td>

        <td
          className="device-manager__table__table__td device-manager__table__table__link"
          onClick={() => {
            device && this.setShowDeviceDetails(device.id);
          }}
        >
          {(device && device.name) || '-'}
          <HasPermission permissionObj={this.props.permission} permissionKey="view" nested>
            <RenderOnCondition condition={showDeviceDetails}>
              <Portal>
                <DeviceDetailsModal closeModal={this.closeDetails} deviceId={device.id} />
              </Portal>
            </RenderOnCondition>
          </HasPermission>
        </td>

        <td
          className="device-manager__table__table__td device-manager__table__table__link"
          onClick={() => {
            device && this.showGroupDetails(device.deviceGroup);
          }}
        >
          {this.getSelectedTree()}
          {showGroupDetails && (
            <DeviceGroupDetailsModal
              closeModal={this.closeDetails}
              deviceGroupData={deviceGroupData}
            />
          )}
        </td>
        <td
          className={
            `device-manager__table__table__td ${ gatewayId
            ? 'device-manager__table__table__link'
            : '' }
            device-manager__table__table__trow__device-type-icon__wrapper`}
          onClick={this.showMessageDetails}
        >
          <img
            data-testid="device-manager__table__table__trow__device-type-icon"
            data-tip="device-type-icon"
            data-for={`source-${id}-${sourceTypeName}`}
            src={(sourceTypeName && sourceTypeIconSmall[sourceTypeName]) || QUESTION_MARK}
            alt="device type"
          />
          <Tooltip
            id={`source-${id}-${sourceTypeName}`}
            place="right"
          >
            {sourceTypeName ? <Translate id={sourceTypeName} /> : '-'}
          </Tooltip>
        </td>

        <td
          data-testid="device-messages-last-message"
          className="device-manager__table__table__td device-manager__table__table__link"
          onClick={this.showMessageDetails}
        >
          {localizedReceivedAt}
        </td>

        <td
          className="device-manager__table__table__td device-manager__table__table__link"
          onClick={this.showMessageDetails}
        >
          <FormattedDeviceReadingValue deviceReadingValues={ values } />
          {this.state.showMessageDetails && (
            <DeviceMessagesDetailsModal
              closeModal={this.closeDetails}
              onDeviceReadingDeleted={ this.props.refreshData }
              deviceReading={this.props.message}
              receivedAt={localizedReceivedAt}
            />
          )}
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  permission: state.currentUser.permission,
});

export default connect(mapStateToProps)(TableDataRow);
