import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { ValueType } from 'react-select/src/types';
import { FieldArrayRenderProps } from 'formik';
import { DeviceTypeRole, IRole } from '@wiot/shared-domain/models/role/role';
import { getTranslationValueInCurrentLanguage } from '../../../utils/common';
import { BaseSelect } from '../../../components/FilterBar/select/BaseSelect';

interface IDeviceTypeSelect extends LocalizeContextProps {
  name: string;
  options: DeviceTypeRole[];
  onChange: (
    value: ValueType<DeviceTypeRole>,
    arrayHelpers: FieldArrayRenderProps,
    values: IRole,
  ) => void;
  height?: string;
  value: any;
  values: IRole;
  placeholderTranslationId?: string;
  isDisabled?: boolean;
  arrayHelpers: FieldArrayRenderProps;
}

const DeviceTypeSelect = ({
  name,
  onChange,
  options,
  value,
  values,
  isDisabled = false,
  arrayHelpers,
  placeholderTranslationId = 'select',
}: IDeviceTypeSelect) => {
  const handleChange = (changedValue: ValueType<DeviceTypeRole>) => {
    onChange(changedValue, arrayHelpers, values);
  };

  const getLabel = (option: any) =>
    option.deviceType.name
      ? `${ getTranslationValueInCurrentLanguage(option.deviceType.name) }`
      : '';

  return (
    <BaseSelect<DeviceTypeRole>
      translationId={ name }
      label={ name }
      placeholderTranslationId={ placeholderTranslationId }
      getOptionLabel={ getLabel }
      getOptionValue={ (option) => `${ option.value }` }

      defaultOptions={ options }
      selectedOptions={ value }
      onChange={ handleChange }

      isMulti={ false }
      isClearable={ false }

      readOnly={ !!value }
      isDisabled={ isDisabled }
      sortLabels={ true }
    />
  );
};

export default withLocalize(DeviceTypeSelect);
