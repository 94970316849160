import React, { useState } from 'react';
import { faAngleDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'react-localize-redux';
import { OutsideClickHandler } from '../OutsideClickHandler';
import useTranslation from '../../hooks/useTranslation';
import { MenuButtons } from '../FilterBar/select/components/MenuSelectAllNoneButtons';
import { SelectableOption } from '../FilterBar/select/selectable-option';

interface MultiSelectProps {
  titleTranslationId: string;
  options: SelectableOption[];
  selectedOptions: SelectableOption[];
  handleOptionsChange: (options: SelectableOption[]) => void;
}

const MultiSelect = (
  {
    titleTranslationId,
    selectedOptions,
    options,
    handleOptionsChange,
  }: MultiSelectProps) => {

  const [ isExpanded, setIsExpanded ] = useState(false);

  const translate = useTranslation();

  const toggleDropdownOpen = () => {
    setIsExpanded(!isExpanded);
  };

  const isOptionChecked = (option: SelectableOption) => {
    const existsAt =
      selectedOptions &&
      selectedOptions.findIndex((selectedOption) => selectedOption.value === option.value);
    return existsAt >= 0;
  };

  const getRenderedOptions = () =>
    options.map((option: SelectableOption, index) => {
      const isChecked = isOptionChecked(option);
      return (
        <div
          key={ `${ option.value }${ index }` }
          className="multiselect_option_wrapper"
          onClick={ () => handleOptionSelect(option, !isChecked) }
        >
          <div className="row-center">
            <div>
              <Translate id={ option.label?.toString() }/>
            </div>
          </div>
          { isChecked && <FontAwesomeIcon icon={ faCheck }/> }
        </div>
      );
    });

  const handleOptionSelect = (option: SelectableOption, checked: boolean) => {
    if (checked) {
      handleOptionsChange([ ...selectedOptions, option ]);
    } else if (selectedOptions) {
      const existsAt = selectedOptions.findIndex(
        (selectedOption) => selectedOption.value === option.value,
      );
      const updatedSelectedOptions = [ ...selectedOptions ];
      updatedSelectedOptions.splice(existsAt, 1);
      handleOptionsChange(updatedSelectedOptions);
    }
  };

  const handleClickOutside = () => {
    setIsExpanded(false);
  };

  const handleSelectAllClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    handleOptionsChange(options);
  };

  const handleSelectNoneClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    handleOptionsChange([]);
  };

  return (
    <OutsideClickHandler onClickOutside={ handleClickOutside }>
      <div className="filterbar__item">
        <div
          className="form-control form-control__custom"
          onClick={ toggleDropdownOpen }
          tabIndex={ 0 }
        >
          <span>
            <Translate id={ titleTranslationId }/>{ ' ' }
            { selectedOptions?.length > 0 ? `(${ selectedOptions.length })` : '' }
          </span>
          { isExpanded ? (
            <MenuButtons
              handleSelectAllClick={ handleSelectAllClick }
              handleSelectNoneClick={ handleSelectNoneClick }
              defaultOptionsCount={ options.length }
            />
          ) : (
            <FontAwesomeIcon className="fa-pull-right" icon={ faAngleDown }/>
          ) }
        </div>
        { isExpanded && (
          <div
            className="multiselect_dropdown_wrapper"
          >
            <div>{ getRenderedOptions() }</div>
          </div>
        ) }
      </div>
    </OutsideClickHandler>
  );
};

export default MultiSelect;
