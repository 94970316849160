import { Device } from '@wiot/shared-domain/models/device/device';
import { GraphAPIData } from '@wiot/shared-domain/models/dashboard/graph-api-data';
import { DeviceReading } from '@wiot/shared-domain/models/device-reading/device-reading';
import { DeviceCountsByTypeSummary } from '@wiot/shared-domain/models/dashboard/device-counts-by-type-summary';

export const FETCH_WIDGETS_DATA = 'FETCH_WIDGETS_DATA';
export const WIDGETS_DATA_FETCHED = 'WIDGETS_DATA_FETCHED';

export interface FetchWidgetsDataAction {
  type: typeof FETCH_WIDGETS_DATA;
}

export interface WidgetsDataFetchedAction {
  type: typeof WIDGETS_DATA_FETCHED;
  deviceCountsByTypeSummary: DeviceCountsByTypeSummary;
  deviceStatusSummary: GraphAPIData;
  devicesHavingConsumptionData: Device[];
  last5DeviceReadings: DeviceReading[];
  deviceGroupsSummary: GraphAPIData;
}
