import { EnumUtils } from '@wiot/shared-domain/models/utils/primitives/enum';
import { IDeviceType } from '../device-types/device-types';
import { DeviceReadingSourceType } from '../device-reading/device-reading';
import { Description, SpacingControlIncrementMode, SpacingValueDescription } from '../oms/data-record';
import { InvalidArgumentError } from '@wiot/shared-domain/models/errors/invalid-argument-error';
import { HistoricalReadingType } from '@wiot/shared-domain/models/device/historical-value';

export interface IDeviceTypeProfile {
  id?: string;
  manufacturerName: string;
  deviceType: IDeviceType;
  errorCodes?: { errorFlag: any; errorValue: any }[];
  mainDescription?: string;
  unit?: any;
  deviceReadingValuePositions?: TelegramParsingSchema[],
  // TODO(TL): Rename to `parsingSchemes`
}

/**
 * Contains information about the indexes to find the specific
 * values in deviceReading's values array.
 */
export interface TelegramParsingSchema {
  // General values
  id?: string;
  isSystemDefined: boolean;
  description: string;
  sourceType: DeviceReadingSourceType;
  generalValuePositions: ValuePosition[];
  /**
   * Used for example for monthly end values.
   */
  historicalValuePositions?: HistoricalValuesPosition[];
}

export interface ValuePosition {
  systemFieldId?: MeasurementAttributeId;
  parserFieldId?: Description;
  parserFieldIndex: number;
}

export interface HistoricalValuesPosition {
  description: HistoricalReadingType; // for example 'Monthly end values'
  incrementMode?: SpacingControlIncrementMode; // Parser can extract it from deviceReading
  baseValueIndex: number; // for example described as 'Volume stored at month - 15'

  baseDateIndex: number; // for example stored as 'Date stored at month - 15'
  spacingUnit?: SpacingValueDescription; // Parser can extract it from deviceReading
  /**
   * Can vary, e.g. half month = 0.5, one month = 1, three months = 3, six months = 6
   */
  spacingValue?: number; // Parser can extract it from deviceReading

  firstValueIndex: number;// for example described as 'Volume stored at month - 14'
  lastValueIndex: number; // for example described as 'Volume stored at month - 1'
}

/**
 * Used as `systemFieldId` in the device type profiles
 * and as `deviceTypeProfileDeviceReadingValues` in the device
 */
export enum MeasurementAttributeId {
  /**
   * Current consumption/status value.
   */
  primaryValue = 'primaryValue',
  primaryValueLastMonth = 'primaryValueLastMonth',
  primaryValueLastYear = 'primaryValueLastYear',
  secondaryValue = 'secondaryValue',
  secondaryValueLastMonth = 'secondaryValueLastMonth',
  secondaryValueLastYear = 'secondaryValueLastYear',
  dueDate = 'dueDate',
  valueAtDueDate = 'valueAtDueDate',
  detailedErrorFlags = 'detailedErrorFlags',
  manufacturerErrorFlags = 'manufacturerErrorFlags',
  commissioningDate = 'commissioningDate',
  currentDate = 'currentDate',
  firmwareVersion = 'firmwareVersion',
  power = 'power',

  // Uncommon shared values
  fabricationNumber = 'fabricationNumber',
  rfTransmissionInterval = 'rfTransmissionInterval',
  stateOfParameters = 'stateOfParameters',
  deviceUseDuration = 'deviceUseDuration',
  deviceOfflineDuration = 'deviceOfflineDuration',
  updateFrequency = 'updateFrequency',
  notificationTime = 'notificationTime',

  // Meter specific values
  meterId = 'meterId',
  radioId = 'radioId',
  energy = 'energy',
  energyAtDueDate = 'energyAtDueDate',
  volume = 'volume',
  volumeLastYear = 'volumeLastYear',
  volumeAtDueDate = 'volumeAtDueDate',
  flow = 'flow',
  flowTemperature = 'flowTemperature',
  flowReturnTemperature = 'flowReturnTemperature',

  // Heat cost allocator specific values
  radiatorTemperature = 'radiatorTemperature',
  maximumCurrentRadiatorTemperature = 'maximumCurrentRadiatorTemperature',
  maximumPreviousRadiatorTemperature = 'maximumPreviousRadiatorTemperature',
  minimumCurrentRadiatorTemperature = 'minimumCurrentRadiatorTemperature',
  minimumPreviousRadiatorTemperature = 'minimumPreviousRadiatorTemperature',
  ambientTemperature = 'ambientTemperature',
  unitsFactorKc = 'unitsFactorKc',
  unitsFactorKq = 'unitsFactorKq',

  // Heat meter specific values
  energyHeating = 'energyHeating',
  energyHeatingAtDueDate = 'energyHeatingAtDueDate',
  energyCooling = 'energyCooling',
  energyCoolingAtDueDate = 'energyCoolingAtDueDate',
  highTemperature = 'highTemperature',
  lowTemperature = 'lowTemperature',
  temperatureDifference = 'temperatureDifference',

  // Smoke detector specific values
  dateOfLastTest = 'dateOfLastTest',
  fraudDuration = 'fraudDuration',
  dateOfLastFraud = 'dateOfLastFraud',
  fraudCounter = 'fraudCounter',
  alarmCounter = 'alarmCounter',
  dateOfLastAlarm = 'dateOfLastAlarm',
  removeCounter = 'removeCounter',
  dateOfLastRemove = 'dateOfLastRemove',
  testButtonCounter = 'testButtonCounter',
  dateOfLastTestButton = 'dateOfLastTestButton',

  // Water meter specific values

  // Gateway specific values
  lastUplinkReceivedAt = 'lastUplinkReceivedAt',
}

export function parseAttributeIds(attributeIdsCommaSeparated: string): MeasurementAttributeId[] | undefined {
  if (!attributeIdsCommaSeparated) {
    return undefined;
  }

  const attributeIds = attributeIdsCommaSeparated.split(',');

  for (const attributeId of attributeIds) {
    if (!EnumUtils.existsKeyInEnum(attributeId, MeasurementAttributeId)) {
      throw new InvalidArgumentError(`Invalid attribute ID: ${attributeId}`);
    }
  }

  return attributeIds as MeasurementAttributeId[];
}

export function getMeasurementAttributeIds(): (string)[] {
  return Object.values(MeasurementAttributeId);
}

export type IMeasurementAttributeId = keyof typeof MeasurementAttributeId;
