import { PersistedState } from 'redux-deep-persist/lib/types';
import { Store } from '../store';

export default function migrateVersion1ToVersion2(oldState: PersistedState | undefined): PersistedState {
  const oldVersion = oldState?._persist.version || 0;
  console.info(`Migrating store from version ${oldVersion} to version ${oldVersion + 1}`);

  if (!oldState) {
    return oldState;
  }

  const typedOldState = oldState as PersistedState & Store; // This is necessary because the redux-persist does not fully support typescript

  const newState = {
    ...typedOldState,
    filters: {
      ...typedOldState.filters,
      filter: {
        ...typedOldState.filters.filter,
        'device-groups': {
          ...typedOldState.filters.filter['device-groups'],
          type: [], // Here the type was changed from string (oldState) to array (newState)
        },
      },
    },
  };
  return newState;
}
