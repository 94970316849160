import moment from 'moment/moment';
import { Device } from '@wiot/shared-domain/models/device/device';
import { ConsumptionByMBusDeviceTypeId } from '@wiot/shared-domain/domain/property/consumption';
import { IDeviceType, isHeatCostAllocator } from '@wiot/shared-domain/models/device-types/device-types';
import { MBusDeviceType } from '@wiot/shared-domain/models/device-types/m-bus-device-types';

export function getConsumptionByMBusDeviceTypeId(
  device: Device,
  consumptions: ConsumptionByMBusDeviceTypeId,
  ) {
  const { deviceType, consumption } = device;
  if (deviceType?.mBusDeviceTypeId) {
    const { mBusDeviceTypeId } = deviceType;
    if (consumptions[mBusDeviceTypeId] && consumption?.values) {
      consumptions[mBusDeviceTypeId].currentPeriodValue += consumption.values.currentPeriodValue;
      consumptions[mBusDeviceTypeId].lastPeriodValue += consumption.values.lastPeriodValue;
      consumptions[mBusDeviceTypeId].secondLastPeriodValue += consumption.values.secondLastPeriodValue;
    } else if (consumption?.values) {
      consumptions[mBusDeviceTypeId] = { currentPeriodValue: 0, lastPeriodValue: 0, secondLastPeriodValue: 0 };
      consumptions[mBusDeviceTypeId].currentPeriodValue = consumption.values.currentPeriodValue;
      consumptions[mBusDeviceTypeId].lastPeriodValue = consumption.values.lastPeriodValue;
      consumptions[mBusDeviceTypeId].secondLastPeriodValue = consumption.values.secondLastPeriodValue;
    }
  }
}

export function accumulateConsumptions(
  propertyOptions: { mBusDeviceTypeId: MBusDeviceType; startDate: string },
  activeConsumptionDeviceType: IDeviceType | undefined,
  inputConsumptions: ConsumptionByMBusDeviceTypeId,
  accumulatedConsumptions: ConsumptionByMBusDeviceTypeId,
) {
  const { mBusDeviceTypeId, startDate } = propertyOptions;

  if (!activeConsumptionDeviceType?.mBusDeviceTypeId || !mBusDeviceTypeId) {
    return;
  }

  const shouldCalculateConsumptionDifference = !isHeatCostAllocator(mBusDeviceTypeId)
    || moment(startDate).month() !== 0;

  if (shouldCalculateConsumptionDifference) {
    accumulatedConsumptions[activeConsumptionDeviceType.mBusDeviceTypeId].currentPeriodValue +=
      inputConsumptions[mBusDeviceTypeId].currentPeriodValue;
    accumulatedConsumptions[activeConsumptionDeviceType.mBusDeviceTypeId].lastPeriodValue +=
      inputConsumptions[mBusDeviceTypeId].lastPeriodValue;
    accumulatedConsumptions[activeConsumptionDeviceType.mBusDeviceTypeId].secondLastPeriodValue +=
      inputConsumptions[mBusDeviceTypeId].secondLastPeriodValue;
  } else {
    accumulatedConsumptions[activeConsumptionDeviceType.mBusDeviceTypeId].currentPeriodValue +=
      inputConsumptions[mBusDeviceTypeId].currentPeriodValue +
      inputConsumptions[mBusDeviceTypeId].lastPeriodValue +
      inputConsumptions[mBusDeviceTypeId].secondLastPeriodValue;
    accumulatedConsumptions[activeConsumptionDeviceType.mBusDeviceTypeId].lastPeriodValue +=
      inputConsumptions[mBusDeviceTypeId].lastPeriodValue +
      inputConsumptions[mBusDeviceTypeId].secondLastPeriodValue;
    accumulatedConsumptions[activeConsumptionDeviceType.mBusDeviceTypeId].secondLastPeriodValue +=
      inputConsumptions[mBusDeviceTypeId].secondLastPeriodValue;
  }
}