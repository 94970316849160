import * as React from 'react';
import { Redirect, Route } from 'react-router';
import { LOGIN_PATH } from '../../navigation/paths';

interface ProtectedRouteProps {
  path: string;
  component: React.ComponentType<any>;
  auth: boolean;
}

export const ProtectedRoute = ({ component: Component, ...rest }: ProtectedRouteProps) => (
  <Route
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    render={(props) =>
      // eslint-disable-next-line react/jsx-props-no-spreading
      rest.auth ? <Component {...props} /> : <Redirect to={LOGIN_PATH} />
    }
  />
);
