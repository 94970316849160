import * as React from 'react';
import { connect } from 'react-redux';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import TableDataHeader from '../../../components/Table/TableDataHeader';
import NoResultsInfo from '../../../components/Table/NoResultsInfo';
import RenderOnCondition from '../../../components/RenderOnCondition';
import { AppState } from '../../../state/reducers/rootReducer';
import TableDataRow from './TableDataRow';
import { FileInfo } from '@wiot/shared-domain/models/file/file';
import {Tooltip} from "../../../components/shared/Tooltip";

export interface TableDataProps {
  files: FileInfo[];
  isLoading: boolean;
  refreshData: (column?: IColumnObject) => void;
  removeUnit: (filename: string) => void;
  readOnly: boolean;
}

export interface TableDataState {
  columns: IColumnObject[];
}

const TABLE_HEADERS = ['file-name', 'created', 'file-size', 'type', 'actions'];

class TableData extends React.Component<TableDataProps, TableDataState> {
  constructor(props: TableDataProps) {
    super(props);
    this.state = {
      columns: [],
    };
  }

  override componentDidMount = () => {
    this.initState();
  };

  initState = () => {
    const columnState = TABLE_HEADERS.map((headerName: string) => ({
      name: headerName,
      visible: true,
      sortable: true,
    }));

    this.setState({ columns: columnState });
  };

  override render() {
    if (this.props.isLoading) {
      return <LoadingIcon/>
    }

    const showResults = this.props.files && this.props.files.length;

    return (
      <div className="device-manager__table__table__container">
        <Tooltip
          id="device-manager__table__table__container__tooltip"
          className="type-dark z-index-1000"
        />
        <table className="device-manager__table__table">
          <TableDataHeader withCheckbox={ false } tableHeaders={ this.state.columns }/>
          <tbody>
            <RenderOnCondition condition={showResults}>
              { this.props.files.map((file: FileInfo) => (
                <TableDataRow
                  key={ file.filename }
                  refreshData={ this.props.refreshData }
                  file={ file }
                  removeUnit={ this.props.removeUnit }
                  readOnly={ this.props.readOnly }
                />
              ))}
            </RenderOnCondition>
          </tbody>
        </table>
        <RenderOnCondition condition={!showResults}>
          <NoResultsInfo />
        </RenderOnCondition>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.isLoading,
});

export default connect(mapStateToProps)(TableData);
