import React, { useState } from 'react';
import { faFastBackward } from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';

import useTranslation from '../../../hooks/useTranslation';
import ConfirmModal, { ConfirmationVariant } from '../../../components/Modal/ConfirmModal';
import TableActionButton from './TableActionButton';
import { restoreBackupFile } from '../../../api/backupService';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';

type IRestoreBackupProps = {
  filename: string;
  refreshData: (column?: IColumnObject) => void;
}

const RestoreBackup = (props: IRestoreBackupProps) => {
  const { filename, refreshData } = props;
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const translate = useTranslation();


  const handleBackupRestore = async () => {
    toastr.info(translate('restore').toString(), translate('restoring-backup').toString());
    setShowRestoreModal(false);

    const { success } = await restoreBackupFile(filename, true);

    refreshData();

    if (success) {
      toastr.success(translate('success').toString(), translate('backup-restored-successfully').toString());
      return;
    }

    toastr.error(translate('error').toString(), translate('restoring-backup-failed').toString());
  };

  return (
    <>
      <TableActionButton
        icon={ faFastBackward }
        label="restore"
        onClick={ () => setShowRestoreModal(true) }
        filename={ filename }
      />
      <ConfirmModal
        show={ showRestoreModal }
        modalCloseRequested={ () => setShowRestoreModal(false) }
        actionConfirmed={ handleBackupRestore }
        modalHeaderTitle={ filename }
        translationIdOfElementType="backup-file"
        translationIdOfAdditionalInfo="restore-triggers-automatic-database-dump"
        confirmationVariant={ ConfirmationVariant.RESTORE }
      />
    </>
  );
};

export default RestoreBackup;
