import * as React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { every } from 'lodash';
import { Translate } from 'react-localize-redux';
import { frontendVersion } from '../../../state/reducers/versioning/isDifferentFrontendVersionAvailableReducer';
import { AppState } from '../../../state/reducers/rootReducer';
import LoadingIcon from '../../../components/shared/LoadingIcon';

/**
 * A component that displays an overview of the update status of the platform.
 */
const UpdateOverview = () => {
  const licensingState = useSelector((state: AppState) => state.licensing);

  const licenseUsages = licensingState.overview?.licenseUsages ?? [];
  const isLoadingLicenseOverview = licensingState.isLoadingLicenseOverview;
  const hasPlatformSufficientLicenses = every(
    licenseUsages,
    (a) => a.numberOfLicensedDevices >= a.numberOfActiveDevices,
  );


  const getUpdateStatus = () => {
    if (isLoadingLicenseOverview) {
      return <LoadingIcon/>;
    }

    return hasPlatformSufficientLicenses ? (
      <div className="license-settings__pil pil-green standard-font-size">
        <Translate id="update-and-licenses-page-status-up-to-date"/>
      </div>
    ) : (
      <div className="license-settings__pil pil-red standard-font-size">
        <div>
          <b>
            <Translate id="update-and-licenses-page-status-insufficient-licenses-header"/>
            { ':' }
          </b>
        </div>
        <Translate id="update-and-licenses-page-status-insufficient-licenses-message"/>
      </div>
    );
  };

  return (
    <>
      <div className="settings-header">
        <Translate id="update-and-licenses-page-update"/>
      </div>
      <div className="d-flex">
        <Col lg={ 6 } className="p-0">
          <Row>
            <Col lg={ 6 }>
              <label className="settings-form__label">
                <Translate id="update-and-licenses-page-current-version"/>
              </label>
            </Col>
            <Col lg={ 6 } className="standard-font-size">
              v{ frontendVersion }
            </Col>
          </Row>
          <Row>
            <Col lg={ 6 }>
              <label className="settings-form__label">
                <Translate id="update-and-licenses-page-latest-version"/>
              </label>
            </Col>
            <Col lg={ 6 } className="standard-font-size">
              v{ frontendVersion }
            </Col>
          </Row>
        </Col>
        <Col lg={ 6 } className="p-0">
          <div>
            { getUpdateStatus() }
          </div>
        </Col>
      </div>
    </>
  );
};

export default UpdateOverview;
