import { Dispatch } from 'redux';
import { UserCreationDefinition } from '@wiot/shared-domain/models/device/user-creation-definition';
import { CREATE_USER_AND_ASSIGN_DOWNLOADHASH, CreateUserAndAssignDownloadHashAction } from './createUserAndAssignDownloadHashAction';
import { authenticatedInstance as httpClientInstance, handleApiError } from '../../api/apiHelpers';
import { ProtectionDetails } from '@wiot/shared-domain/models/device-key/protection-details';
import { handleProtectionDetailsResponse, } from './fetchDeviceKeyProtectionActionCreators';


export const createUserAndAssignDownloadHashActionCreator = (
  userCreationDefinition: UserCreationDefinition,
  downloadHash: string,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  dispatch({
    type: CREATE_USER_AND_ASSIGN_DOWNLOADHASH,
    userCreationDefinition,
    downloadHash,
  } as CreateUserAndAssignDownloadHashAction);

  try {
    delete userCreationDefinition.passwordConfirmation;
    const request = { userCreationDefinition, downloadHash };
    const url = '/createUserAndAssignDownloadHash';
    const { data: protectionDetails } = await httpClientInstance.post<ProtectionDetails>(url, request);

    handleProtectionDetailsResponse(downloadHash, dispatch, protectionDetails);
  } catch (error) {
    handleApiError(error);
  }
};
