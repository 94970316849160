import React from 'react';
import { Translate } from 'react-localize-redux';
import { Device } from '@wiot/shared-domain/models/device/device';
import { MonthlyValuesWithDifferenceToLastMonth } from '@wiot/shared-domain/models/device/historical-value';
import {LinkToDeviceMessagesPage} from "../../../navigation/LinkToDeviceMessagesPage";

function DashboardConsumptionGraphTitle({
  device,
  monthlyEndValues: { values }
}: {  device: Device, monthlyEndValues: MonthlyValuesWithDifferenceToLastMonth }) {
  return (
    <span className="ml-5 responsive-container__item__text">
        <LinkToDeviceMessagesPage
          idOfDevice={ device.id }
        >
          <Translate id='monthly-consumption' /> { ' ' }
          <Translate id="in"/> { values[0].unit }
        </LinkToDeviceMessagesPage>
      </span>
  );
}

export default DashboardConsumptionGraphTitle;
