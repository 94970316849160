import { Dispatch } from 'redux';
import { fetchDeviceGroupTree } from './fetchDeviceGroupTreeActionCreator';
import { AppState } from '../../reducers/rootReducer';

export const refreshDeviceTree = () => async (
  dispatch: Dispatch<any>,
  getState: () => AppState,
): Promise<void> => {
  const state = getState();
  const deviceTreeFilter = state.filters.filter['device-manager'];
  if (deviceTreeFilter) {
    dispatch(fetchDeviceGroupTree(deviceTreeFilter));
  }
};
