import React from 'react';
import { Translate } from 'react-localize-redux';

import { DeviceReading } from '@wiot/shared-domain/models/device-reading/device-reading';
import DashboardReading from './DashboardReading';
import DashboardBox from './DashboardBox';
import { useSelector } from 'react-redux';
import { AppState } from '../../state/reducers/rootReducer';
import { LinkToDeviceMessagesPage } from '../../navigation/LinkToDeviceMessagesPage';

const DashboardReadingsBox = () => {
  const { last5DeviceReadings } = useSelector((state: AppState) => state.widgetsData);
  const isLoading = useSelector((state: AppState) => state.isLoading);

  const hasLastReadings = last5DeviceReadings && last5DeviceReadings.length > 0;

  if (!isLoading && !hasLastReadings) {
    return (
      <DashboardBox title="new-messages" isLoading={ false }>
        <div className="standard-font-size pt-5">
          <Translate id="no-data"/>
        </div>
      </DashboardBox>
    );
  }

  return (
    <DashboardBox title="new-messages" isLoading={ isLoading }>
      <>
        { last5DeviceReadings.map((deviceReading: DeviceReading) => (
          <DashboardReading data={ deviceReading } key={ deviceReading.id }/>
        )) }
        <div className="dashboard__btn__container">
          <LinkToDeviceMessagesPage className="dashboard__btn__link">
            <Translate id="all-messages"/>
          </LinkToDeviceMessagesPage>
        </div>
      </>
    </DashboardBox>
  );
};

export default DashboardReadingsBox;
