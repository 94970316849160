import React from 'react';
import { Device } from '@wiot/shared-domain/models/device/device';
import { SelectWrapperWithFormik } from '../FilterBar/select/SelectWrapperWithFormik';
import { SelectableOption } from '../FilterBar/select/selectable-option';

export function DeviceSelect({ activeDevice, devices, selectDevice, portalTargetId }: {
  activeDevice?: Device;
  devices: Device[];
  selectDevice: (id: string) => void;
  portalTargetId: string;
}) {
  function getOptions() {
    return devices.map(device => ({ label: `${ device.deviceId } ${ device.name }`, value: device.id }));
  }

  return (
    <SelectWrapperWithFormik<SelectableOption>
      isClearable={ false }
      fieldName=""
      listHeight={ 200 }
      backgroundColor="var(--bg-white)"
      value={ activeDevice ? activeDevice.id : undefined }
      options={ getOptions() }
      portalTargetId={ portalTargetId }
      hideLabel
      onChange={ (name: string, value: string) => selectDevice(value) }
      translateOptions={ false }
      isMulti={ false }
    />
  );
}
