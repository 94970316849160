/**
 * Interface for a file location.
 */
export interface IFileLocation {
  /**
   * Defines the path to the file.
   */
  path: string;

  /**
   * Defines the name of the file.
   */
  name: string;

  /**
   * Defines the extension of the file.
   */
  extension: string;
}

/**
 * Represents a location of a file.
 */
export class FileLocation implements IFileLocation {
  constructor(
    /**
     * Defines the path to the file.
     */
    public path: string,

    /**
     * Defines the name of the file.
     */
    public name: string,

    /**
     * Defines the extension of the file.
     */
    public extension: string,
  ) {}

  /**
   * Gets the full path of the file.
   *
   * @param fileNamePostFix Defines a post fix for the file name right before the file extension.
   *
   * @returns The full path of the file.
   */
  public getFullPath(fileNamePostFix = '') {
    let fullPath = `${this.path}${this.name}${fileNamePostFix}`;
    if (this.extension) {
      fullPath += `.${this.extension}`;
    }
    return fullPath;
  }

  /**
   * Gets the full path of the file.
   *
   * @param fileLocation Defines the file location to get the full path for.
   * @param fileNamePostFix Defines a post fix for the file name right before the file extension.
   *
   * @returns The full path of the file.
   */
  public static getFullPath(fileLocation: IFileLocation, fileNamePostFix = '') {
    let fullPath = `${fileLocation.path}${fileLocation.name}${fileNamePostFix}`;
    if (fileLocation.extension) {
      fullPath += `.${fileLocation.extension}`;
    }
    return fullPath;
  }
}
