import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import ReactDatePicker from 'react-datepicker';
import React from 'react';

import useTranslation from '../../../hooks/useTranslation';

export interface ExportDatePickerProps {
  selected: Date | null,
  onChange: (date: Date | null) => void,
  maxDate?: Date | null,
  modalUid: string,
  translateId: string,
  placeholder?: string,
  dataTestId: string,
  readOnly?: boolean,
}

export function ExportDatePicker({
  maxDate,
  modalUid,
  onChange,
  selected,
  translateId,
  placeholder,
  dataTestId,
  readOnly = false
}: ExportDatePickerProps) {
  const translate = useTranslation();

  return (
    <Row data-testid={ dataTestId }>

    <Col md={ 7 }>
      <label className="form__label">
        <Translate id={ translateId }/>
      </label>
    </Col>
    <Col>
      <ReactDatePicker
        readOnly={ readOnly }
        className={ `modal-input cursor-pointer ${ readOnly ? 'input--readonly' : '' }` }
        calendarClassName="select__menu"
        selected={ selected }
        onChange={ onChange }
        dateFormat="dd/MM/yyyy"
        maxDate={ maxDate }
        placeholderText={ placeholder ? translate(placeholder).toString() : '' }
        portalId={ `modal-${ modalUid }` }
      />
    </Col>

    </Row>
  );
}
