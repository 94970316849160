import {
  SHOW_TOO_MANY_RESULTS_INFO,
  SHOW_NO_RESULTS_INFO,
  NoResultsInfoActionTypes,
  NoResultsInfoState,
} from './fetchNoResultInfoAction';

export const showNoResultsInfoReducer = (
  state: NoResultsInfoState = NoResultsInfoState.noData,
  action: NoResultsInfoActionTypes,
): NoResultsInfoState => {
  switch (action.type) {
    case SHOW_NO_RESULTS_INFO:
      return action.payload;
    case SHOW_TOO_MANY_RESULTS_INFO:
      return action.payload;
    default:
      return state;
  }
};
