import React from 'react';
import { Table } from 'react-bootstrap';
import { Translate, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { AppState } from '../../../state/reducers/rootReducer';
import { getTranslationValueInCurrentLanguage } from '../../../utils/common';

/**
 * A component that displays the list of license usages of the platform.
 */
const LicenseUsagesList = () => {
  const licensingState = useSelector((state: AppState) => state.licensing);

  const licenseUsages = licensingState.overview?.licenseUsages ?? [];

  return (
    <Table responsive className='standard-font-size'>
      <thead>
        <tr>
          <th className="license-settings__table__wide-column">
            <Translate id="device-type" />
          </th>
          <th className="license-settings__table__narrow-column">
            <Translate id="active-devices" />
          </th>
          <th className="license-settings__table__narrow-column">
            <Translate id="licensed-devices" />
          </th>
        </tr>
      </thead>
      <tbody>
        {licenseUsages.map((usage, index) => (
          <tr key={index}>
            <td
              className={
                usage.numberOfActiveDevices > usage.numberOfLicensedDevices
                  ? 'license-settings__warn'
                  : ''
              }
            >
              {getTranslationValueInCurrentLanguage(usage.deviceTypeName)}
            </td>
            <td
              className={
                usage.numberOfActiveDevices > usage.numberOfLicensedDevices
                  ? 'license-settings__warn'
                  : ''
              }
            >
              {usage.numberOfActiveDevices}
            </td>
            <td>{usage.numberOfLicensedDevices}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default withLocalize(LicenseUsagesList);
