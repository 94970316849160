import React, { useState } from 'react';
import fileDownload from 'js-file-download';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { connect, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { toastr } from 'react-redux-toastr';
import { Formik, FormikProps } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { date, object, string } from 'yup';

import { ExportContentType, ExportFileExtension, } from '@wiot/shared-domain/models/domain/services/export/export.models';
import { DEVICE_MANAGER_PAGE_KEY } from '@wiot/shared-domain/domain/device-manager/device-manager-page-key';
import RenderOnCondition from '../../../components/RenderOnCondition';
import { AppState } from '../../../state/reducers/rootReducer';
import { fetchDeviceExport } from '../../../api/exportService';
import ModalHeader from '../../../components/Modal/ModalHeader';
import { DownloadConfirmation } from './DownloadConfirmation';
import { DownloadProgress } from './DownloadProgress';
import { DeviceExportBody } from './DeviceExportBody';
import { IPaginationData } from '../../../state/reducers/paginationReducer';
import { DeviceExportFormatList, DeviceExportFormikValues } from './device-export';

interface IDeviceExportModalProps extends LocalizeContextProps {
  handleClose: () => void;
  pagination: IPaginationData;
}

const DeviceExportModal = ({ handleClose, pagination, translate }: IDeviceExportModalProps) => {
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [modalUid] = useState(uuidv4());

  const filter = useSelector((state: AppState) => state.filters.filter[DEVICE_MANAGER_PAGE_KEY]);

  const handleSubmit = async (values: DeviceExportFormikValues) => {
    const {
      endDate,
      exportFormat,
      startDate,
      attributeIds,
      includeDueDateMeasurement,
      includeLastPrimaryMeasurement,
      includeLastGateways,
      frequency,
    } = values;

    try {
      setInterval(() => {
        setDownloadProgress((prevValue) => prevValue + 100 / pagination.totalDocs);
      }, 1000);

      const fileContent = await fetchDeviceExport(
        endDate?.toISOString() || new Date().toISOString(),
        exportFormat.value,
        filter,
        startDate?.toISOString(),
        attributeIds,
        frequency,
        includeLastPrimaryMeasurement,
        includeDueDateMeasurement,
        includeLastGateways,
      );

      setDownloadProgress(100);

      if (!fileContent) {
        throw new Error();
      }

      const filename = `DataExport ${ endDate?.toLocaleString() }.${ exportFormat.extension }`;

      if (exportFormat.extension === ExportFileExtension.CSV) {
        fileDownload(fileContent, filename, ExportContentType.TEXT_CSV);
      } else {
        fileDownload(JSON.stringify(fileContent), filename, ExportContentType.APPLICATION_JSON);
      }
    } catch (e) {
      handleClose();
      toastr.error(translate('error').toString(), translate('invalid-user-input').toString());
      console.error(e);
    }
  };

  const initialStartDate = new Date();
  initialStartDate.setFullYear(initialStartDate.getFullYear() - 1);

  return (
    <div className="device-modal" id={ `deviceExport-${ modalUid }` } data-testid="device-export-modal">
      <ModalHeader titleTranslationId="deviceExport" handleClose={ handleClose } targetId={ modalUid }/>
      <div className="device-modal__body" id={ `modal-${ modalUid }` }>
        <Formik
          initialValues={ {
            startDate: initialStartDate,
            endDate: new Date(),
            exportFormat: DeviceExportFormatList[0],
          } }
          validationSchema={ object().shape({
            startDate: date().required(translate('required-field').toString()),
            endDate: date().required(translate('required-field').toString()),
            exportFormat: string().required(translate('required-field').toString()),
          }) }
          onSubmit={ handleSubmit }
          render={ ({
            values,
            setFieldValue,
            submitForm,
          }: FormikProps<DeviceExportFormikValues>) => {
            const { exportFormat, endDate, startDate } = values;

            return (
              <div className="form standard-font-size">
                <RenderOnCondition condition={ !downloadProgress }>
                  <DeviceExportBody
                    setFieldValue={ setFieldValue }
                    values={ values }
                    modalUid={ modalUid }
                  />
                </RenderOnCondition>
                <RenderOnCondition condition={ downloadProgress && downloadProgress < 100 }>
                  <DownloadProgress
                    count={ pagination?.totalDocs }
                    exportFormat={ exportFormat }
                    estimatedTimeFromTotalDocs={ pagination.totalDocs * 0.3 }
                    now={ downloadProgress }
                  />
                </RenderOnCondition>
                <RenderOnCondition condition={ downloadProgress >= 100 }>
                  <DownloadConfirmation onClick={ handleClose }/>
                </RenderOnCondition>

                <RenderOnCondition condition={ !downloadProgress }>
                  <Row className="mt-4">
                    <Col className="d-flex justify-content-end">
                      <button type="button" className="form__button--cancel" onClick={ handleClose }>
                        <Translate id="cancel"/>
                      </button>
                      <button
                        className="form__button--blue background-color-main text-color-white border-color-main"
                        type="submit"
                        onClick={ submitForm }
                        disabled={ !startDate || !endDate || !exportFormat }
                      >
                        <Translate id="export"/>
                      </button>
                    </Col>
                  </Row>
                </RenderOnCondition>
              </div>);
          } }
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  pagination: state.pagination[DEVICE_MANAGER_PAGE_KEY],
});

export default connect(mapStateToProps)(withLocalize(DeviceExportModal));
