export class ArrayUtils {
  public static removeDuplicatesById(inputArray: { id?: string }[]) {
    return inputArray?.filter(
      (item, index, array) =>
        array.findIndex((itemToCompare) => itemToCompare.id === item.id) === index,
    ) || [];
  }

  public static getRandomElement(array: any[]) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  };
}
