import React from 'react';
import ReactTooltip, { Place, Effect, Type, Offset } from 'react-tooltip';
import Portal from './Portal';
import { Translate } from "react-localize-redux";

export interface TooltipProps {
  id: string;
  place?: Place;
  offset?: Offset;
  effect?: Effect;
  backgroundColor?: string;
  type?: Type;
  delayShow?: number,
  delayHide?: number,
  delayUpdate?: number,
  className?: string;

  children?: React.ReactNode | React.ReactNode[];
}

export const Tooltip = (props: TooltipProps) => {
  const {
    id,
    place = 'left',
    offset,
    effect = 'solid',
    backgroundColor,
    type = 'dark',
    delayShow = 100,
    delayHide = 175,
    delayUpdate = 250,
    className="z-index-1000",

    children,
  } = props

  return (
    <Portal>
      <ReactTooltip
        id={ id }
        place={ place }
        offset={ offset }
        effect={ effect }
        backgroundColor={ backgroundColor }
        delayShow={ delayShow }
        delayHide={ delayHide }
        delayUpdate={ delayUpdate }
        type={ type }
        className={ className }
      >
        { children }
      </ReactTooltip>
    </Portal>
  );
}
