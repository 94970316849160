import React, { useEffect } from 'react';
import { Translate } from 'react-localize-redux';

interface ILegalDetailsContentProps {
  contentString?: string;
}

const LegalDetailsContent = ({ contentString }: ILegalDetailsContentProps) => {
  useEffect(() => {
    const el = document.getElementById('legal-details-page-content');
    if (el && contentString) {
      el.innerHTML = contentString;
    }
  }, [contentString]);

  return (
    <main
      data-testid="legal-details-page-content"
      className="legal-details-page"
      id="legal-details-page-content"
    >
      <Translate id="page-not-found-contact-admin" />
    </main>
  );
};

export default LegalDetailsContent;
