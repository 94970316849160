import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ReactDOM from 'react-dom';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import ModalHeader from '../Modal/ModalHeader';
import { AppState } from '../../state/reducers/rootReducer';

interface OutdatedVersionModalProps {
  closeModal: () => void;
}

function OutdatedVersionModal(props: OutdatedVersionModalProps) {
  const { closeModal } = props;
  const serverFrontendVersion = useSelector(
    (appState: AppState) => appState.versioning.serverFrontendVersion,
  );
  const rootEl = document.getElementsByTagName('body');

  const forceRefresh = async () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(async (names) => {
        await Promise.all(names.map((name) => caches.delete(name)));
      });
    }
    window.location.reload();
  };

  return ReactDOM.createPortal(
    <>
      <div
        className="device-modal"
        id="update-available-update-frontend"
        onClick={(event: React.MouseEvent) => event.stopPropagation()}
      >
        <ModalHeader
          isDevice={false}
          titleTranslationId="update-available"
          targetId="update-frontend"
          handleClose={closeModal}
        />
        <div className="form">
          <div className="form__section">
            <div className="form__row justify-content-center">
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </div>
          </div>
          <div className="form__section">
            <div className="form__row">
              <div className="form__label">
                <Translate
                  id="new-frontend-version-available"
                  data={{ version: serverFrontendVersion }}
                />
              </div>
            </div>
          </div>
          <div className="form__section last">
            <div className="form__row justify-content-end">
              <button
                type="button"
                className="form__button--blue background-color-main text-color-white border-color-main"
                onClick={forceRefresh}
              >
                <Translate id="refresh" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    rootEl[0],
  );
}

export default OutdatedVersionModal;
