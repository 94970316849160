import React from 'react';
import { Translate } from 'react-localize-redux';
import { Device } from '@wiot/shared-domain/models/device/device';
import LoadingIcon from '../../components/shared/LoadingIcon';

interface KeyDevicesTableProps {
  devices: Device[];
  inProgress: boolean;
  handleRowClick: (id: string) => void;
}

const KeyDevicesTable = ({ inProgress, handleRowClick, devices }: KeyDevicesTableProps) => {
  if (inProgress) {
    return <LoadingIcon />;
  }

  if (!devices || devices.length < 0) {
    return <Translate id="no-devices" />;
  }

  return (
    <table className="device-manager__table__table">
      <thead>
        <tr className="details__headings--table">
          {['device-id', 'device-name'].map((h, i) => (
            <th key={i}>
              <Translate id={h} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {devices.map((device: Device) => (
          <tr
            className="details__text device-manager__table__table__link"
            key={device.id}
            onClick={() => handleRowClick(device.id)}
          >
            <td className="details__td">{device.deviceId}</td>
            <td className="details__td">{device.name}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default KeyDevicesTable;
