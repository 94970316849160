import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import CustomCheckbox from '../../../components/shared/CustomCheckbox';
import React from 'react';

export interface ExportCheckboxProps {
  checked: boolean,
  onChange: () => void,
  translateId: string,
}

export function ExportCheckbox({ checked, onChange, translateId }: ExportCheckboxProps) {
  return <Row className='my-1'>
    <Col md={ 7 }>
      <label className="form__label">
        <Translate id={ translateId }/>
      </label>
    </Col>
    <Col className="form__checkbox mb-2">
      <CustomCheckbox
        checked={ checked }
        onChange={ onChange }
      />
    </Col>
  </Row>;
}