import { TreeNode, TreeNodeType } from '@wiot/shared-domain/domain/device-tree/tree-node';
import { DEVICE_TREE_UPDATED, DeviceTreeUpdatedAction } from './deviceTreeUpdatedAction';
import { setTreeNodeChildrenAndReturnUpdatedTree } from '../../../utils/tree-utils';
import { TREE_NODE_FETCHED, TreeNodeFetchedAction } from '../fetch-tree-node-with-children/fetchTreeNodeAction';
import { DEVICE_KEY_ASSIGNED_TO_USER } from '../../device-key/deviceKeyActionTypes';
import { DeviceKeyAssignedToUserAction } from '../../device-key/assignDeviceKeyToCurrentUserActions';

export type DeviceTreeState = {
  rootNode: TreeNode;
  isDeviceTreeOutdated: boolean;
};

const initialState: DeviceTreeState = {
  rootNode: {
    id: '',
    name: '',
    parentId: '',
    type: TreeNodeType.DEVICE_GROUP,
    innerObject: {},
    children: [],
    hiddenChildren: [],
    hasChildrenToBeLoaded: false,
    subTreeContainsDevices: false,
  },
  isDeviceTreeOutdated: false,
};

export const deviceTreeNodesReducer = (
  state: DeviceTreeState = initialState,
  action: DeviceTreeUpdatedAction | TreeNodeFetchedAction | DeviceKeyAssignedToUserAction,
): DeviceTreeState => {
  switch (action.type) {
    case DEVICE_KEY_ASSIGNED_TO_USER:
      return {
        ...state,
        isDeviceTreeOutdated: true,
      };

    case DEVICE_TREE_UPDATED:
      return {
        ...state,
        rootNode: action.rootNode,
        isDeviceTreeOutdated: false,
      };

    case TREE_NODE_FETCHED:
      const updatedTree = setTreeNodeChildrenAndReturnUpdatedTree(
        state.rootNode, action.treeNode);
      return {
        ...state,
        rootNode: updatedTree,
      };

    default:
      return state;
  }
};
