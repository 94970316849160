import { CHANGE_FILTER, CHANGE_SORT, RESET_FILTER } from '../constants/actionTypes';
import { FilterSortActionTypes } from '../types';
import { IFilterPayload } from '../reducers/filterSortReducer';

export const changeFilter = (filter: IFilterPayload): FilterSortActionTypes => ({
  type: CHANGE_FILTER,
  payload: filter,
});

export const resetFilter = (payload: IFilterPayload): FilterSortActionTypes => ({
  type: RESET_FILTER,
  payload,
});

export const changeSort = (sort: string): FilterSortActionTypes => ({
  type: CHANGE_SORT,
  payload: sort,
});
