import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { DEVICE_GROUP_CREATED, DeviceGroupCreatedAction } from '../create-device-group/deviceGroupCreationTypes';
import { DEVICE_GROUP_UPDATED, DeviceGroupUpdatedAction } from '../update-device-group/deviceGroupUpdateTypes';
import {
  REMOVE_REQUEST_TO_REFRESH_DG_RELATED_ENTITIES,
  RemoveRequestToRefreshDgRelatedEntitiesAction
} from './removeRequestToRefreshDgRelatedEntitiesActions';

export type RequestState = {
  requesterDeviceGroup?: DeviceGroup;
};

const initialState: RequestState = {
  requesterDeviceGroup: undefined,
};

export const requestToRefreshDeviceGroupRelatedEntitiesReducer = (
  state: RequestState = { ...initialState },
  action: DeviceGroupCreatedAction | DeviceGroupUpdatedAction | RemoveRequestToRefreshDgRelatedEntitiesAction,
): RequestState => {
  switch (action.type) {
    case DEVICE_GROUP_CREATED:
    case DEVICE_GROUP_UPDATED:
      return {
        ...state,
        requesterDeviceGroup: action.deviceGroup,
      };

    case REMOVE_REQUEST_TO_REFRESH_DG_RELATED_ENTITIES:
      return {
        ...state,
        requesterDeviceGroup: undefined,
      };

    default:
      return state;
  }
};
