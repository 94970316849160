import React from 'react';
import { components, OptionTypeBase } from 'react-select';
import { IndicatorProps } from 'react-select/src/components/indicators';
import { ExpandIcon } from './ExpandIcon';

export const DropdownIndicator = <OptionType extends OptionTypeBase> (props: IndicatorProps<OptionType>) => {
  const {
    menuIsOpen = false,
  } = props.selectProps;

  return (
    <components.DropdownIndicator
      { ...props }
      className="select__dropdown-indicator"
    >
      <div className="select__dropdown-icon-wrapper">
        <ExpandIcon isExpanded={ menuIsOpen } />
      </div>
    </components.DropdownIndicator>
  );
}
