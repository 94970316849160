import { FETCH_DEVICES, TOGGLE_SELECT_ALL_DEVICES, TOGGLE_SELECT_DEVICE } from '../constants/actionTypes';
import { Device, IColumnObject } from '@wiot/shared-domain/models/device/device';
import { FetchOptions } from '../types';
import { DeviceManagerFilter } from './device-manager-filter';
import { DeviceReadingsFetchedAction } from '../device-readings/fetch-device-readings/fetchDeviceReadingsTypes';

export interface FetchDevicesAction {
  type: typeof FETCH_DEVICES;
  column: IColumnObject;
  pageOptions?: FetchOptions<DeviceManagerFilter>,
  firstExecution?: boolean,
}

interface ToggleSelectDeviceAction {
  type: typeof TOGGLE_SELECT_DEVICE;
  payload: string;
}

interface ToggleSelectAllDevicesAction {
  type: typeof TOGGLE_SELECT_ALL_DEVICES;
  payload: boolean;
}
export type DevicesActionTypes =
  | DevicesFetchedAction
  | ToggleSelectDeviceAction
  | ToggleSelectAllDevicesAction
  | DeviceReadingsFetchedAction;

export const DEVICES_FETCHED = 'DEVICES_FETCHED';
export const devicesFetched = (devices: Device[] = []): DevicesActionTypes => ({
  type: DEVICES_FETCHED,
  payload: devices,
});

export interface DevicesFetchedAction {
  type: typeof DEVICES_FETCHED;
  payload: Device[];
}
