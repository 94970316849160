import { IS_DEVICE_TREE_LOADING } from '../../constants/actionTypes';

export const isDeviceTreeLoading = (value: boolean): IsDeviceTreeLoadingAction => ({
  type: IS_DEVICE_TREE_LOADING,
  value: value,
});

export interface IsDeviceTreeLoadingAction {
  type: typeof IS_DEVICE_TREE_LOADING;
  value: boolean;
}
