import React from 'react';

import { IExportFormat } from '@wiot/shared-domain/models/domain/services/export/export.models';
import { DeviceExportFormatList, DeviceExportFormikValues } from './device-export';
import { ExportDatePicker } from './ExportDatePicker';
import { OptionalInputFields } from './OptionalInputFields';
import { SetFieldValue } from '../../../state/types';
import { DeviceExportSelect } from './DeviceExportSelect';

export interface DeviceExportBodyProps {
  values: DeviceExportFormikValues,
  setFieldValue: SetFieldValue,
  modalUid: string,
}

export function DeviceExportBody({ values, modalUid, setFieldValue }: DeviceExportBodyProps) {
  const { startDate, endDate, exportFormat } = values;

  const handleDateChange = (field: string, date: Date | null) => {
    setFieldValue(field, date);
  };

  const handleExportFormatChange = (value: string) => {
    const newFormat = DeviceExportFormatList.find((f: IExportFormat) => f.value === value);
    newFormat && setFieldValue('exportFormat', newFormat);
  };

  return (
    <>

    <ExportDatePicker
      selected={ startDate ? startDate : null }
      onChange={ (date) => handleDateChange('startDate', date) }
      maxDate={ endDate }
      translateId="consider-only-until"
      modalUid={ modalUid }
      dataTestId="device-export-start-date"
    />
    <ExportDatePicker
      selected={ endDate ? endDate : null }
      onChange={ (date) => handleDateChange('endDate', date) }
      translateId="billing-date"
      modalUid={ modalUid }
      dataTestId="device-export-end-date"
    />
    <DeviceExportSelect
      value={ exportFormat.value }
      name="exportFormat"
      options={ DeviceExportFormatList.map( deviceExportFormat =>
        ({ label: deviceExportFormat.name, value: deviceExportFormat.value }) ) }
      onChange={ (_, value) => handleExportFormatChange(value) }
      modalUid={ modalUid }
      translateId="exportFormat"
    />
    <OptionalInputFields
      exportFormat={ exportFormat }
      values={ values }
      setFieldValue={ setFieldValue }
      modalUid={ modalUid }
    />

    </>
  );
}
