import { AxiosResponse } from 'axios';
import {
  FeedbackInformation,
  FeedbackAttachment,
} from '../../components/Feedback/feedback';

export const OPEN_FEEDBACK_MODAL = 'OPEN_FEEDBACK_MODAL';
export interface OpenFeedbackModalAction {
  type: typeof OPEN_FEEDBACK_MODAL;
  attachment?: FeedbackAttachment;
}

export const CLOSE_FEEDBACK_MODAL = 'CLOSE_FEEDBACK_MODAL';
export interface CloseFeedbackModalAction {
  type: typeof CLOSE_FEEDBACK_MODAL;
}

export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export interface SendFeedbackAction {
  type: typeof SEND_FEEDBACK;
  information: FeedbackInformation;
}

export const FEEDBACK_SENT = 'FEEDBACK_SENT';
export interface FeedbackSentAction {
  type: typeof FEEDBACK_SENT;
  response: AxiosResponse;
}
