import {
  DEVICE_KEYS_FILE_FETCHED, DeviceKeysFileFetchedAction, RESET_DEVICE_KEYS_FILE, ResetDeviceKeysFileAction,
} from './fetchDeviceKeysAsFileAction';
import { DeviceKeyExportFileFormat } from '@wiot/shared-domain/models/device-key/device-keys-export-file-format';

export type DeviceKeysAsFile = {
  fileFormat: DeviceKeyExportFileFormat;
  fileContent: Blob;
}

export const deviceKeysAsFileReducer = (
  state: DeviceKeysAsFile | null = null,
  action: DeviceKeysFileFetchedAction | ResetDeviceKeysFileAction,
): DeviceKeysAsFile | null => {
  switch (action.type) {
    case DEVICE_KEYS_FILE_FETCHED:
      return {
        fileFormat: action.fileFormat,
        fileContent: action.fileContent,
      };

    case RESET_DEVICE_KEYS_FILE:
      return null;
    default:
      return state;
  }
};
