import React from 'react';
import useTranslation from '../../hooks/useTranslation';

const NoMatch = (): JSX.Element => {
  const translate = useTranslation();

  return <main data-testid="page-404">
    <div className="centered-both-axis">
      { translate('error-404-page-not-found') }
    </div>
  </main>;
};

export default NoMatch;
