import { CSSProperties } from 'react';

export const getSelectStyles = (
  isMobileDisplay: boolean,
  height = '28px',
  backgroundColor = 'whitesmoke',
  listHeight = 300,
) => ({
  container: (provided: CSSProperties) => ({
    ...provided,
    height: isMobileDisplay ? '30px' : height,
  }),
  control: (provided: CSSProperties) => ({
    ...provided,
    backgroundColor,
    height: '100%',
    maxHeight: isMobileDisplay ? '30px' : height,
    minHeight: 'unset !important',
    fontSize: isMobileDisplay ? '16px' : '12px',
    border: '1px solid #9196a3',
    color: 'black',
  }),
  input: () => ({
    height: '100%',
  }),
  singleValue: (provided: CSSProperties) => ({
    ...provided,
  }),
  valueContainer: (provided: CSSProperties) => ({
    ...provided,
    fontSize: isMobileDisplay ? '16px' : '12px',
    height: '100%',
    maxHeight: isMobileDisplay ? '30px' : height,
    position: undefined,
  }),
  option: (provided: CSSProperties, state) => ({
    ...provided,
    fontSize: isMobileDisplay ? '16px' : '12px',
    borderBottom: '1px solid #eee',
    color: state.isSelected ? 'black' : 'inherit',
    backgroundColor: state.isSelected ? 'white' : 'inherit',
  }),
  placeholder: (provided: CSSProperties) => ({
    ...provided,
  }),
  indicatorsContainer: (provided: CSSProperties) => ({
    ...provided,
    height: '100%',
  }),
  menu: (provided: CSSProperties) => ({
    ...provided,
    minHeight: '30px',
    maxHeight: listHeight,
    zIndex: 400,
  }),
  menuList: (provided: CSSProperties) => ({
    ...provided,
    height: '100%',
    fontSize: isMobileDisplay ? '16px' : '12px',
  }),
  menuPortal: (provided: CSSProperties) => ({ ...provided, zIndex: 9999 }),
});
