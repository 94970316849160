import { FieldArray, FieldArrayRenderProps, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep, groupBy } from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { ValueType } from 'react-select/src/types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { v4 as uuidv4 } from 'uuid';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import { AggregatedPermission, DeviceTypeRole, IRole } from '@wiot/shared-domain/models/role/role';
import {
  addRoleToDB,
  fetchDeviceTypesFromDB,
  fetchRoleFromDB,
  fetchRolesFromDB,
  updateRoleInDB,
} from '../../api/apiHelpers';
import ModalHeader from '../../components/Modal/ModalHeader';
import ConfirmModal, { ConfirmationVariant } from '../../components/Modal/ConfirmModal';
import { SetFieldValue } from '../../state/types';
import { CustomFieldInput } from '../../components/Table/CustomFieldInput';
import {
  DEVICE_ROLE_DEFAULT_VALUES_WIOT,
  MESSAGE_ROLE_DEFAULT_VALUES_WIOT,
  ROLE_DEFAULT_VALUES_WIOT
} from '@wiot/shared-domain/models/role/role-default-values.wiot';
import CustomToggle from '../../components/Table/CustomToggle';
import DeviceTypeSelect from './components/DeviceTypeSelect';
import RoleGroup from './components/RoleGroup';
import { getRoleSectionStructure } from '../../utils/common';
import LoadingIcon from '../../components/shared/LoadingIcon';
import AddNewDeviceType from './components/AddNewDeviceType';
import { AppState } from '../../state/reducers/rootReducer';
import HasPermission from '../../components/HasPermission';
import { getRandomModalOffset } from '../../utils/dialog';
import RenderOnCondition from '../../components/RenderOnCondition';
import {
  DEVICE_ROLE_DEFAULT_VALUES_KEY_MANAGER,
  ROLE_DEFAULT_VALUES_KEY_MANAGER
} from '@wiot/shared-domain/models/role/role-default-values.key-manager';
import { FeedbackAttachment } from '../../components/Feedback/feedback';
import { filterOutSensitiveDataFromRole } from '../../components/Feedback/filter-sensitive-data';
import { fetchPermittedRoles } from '../../state/actions/user-action-modal/fetchPermittedRolesActionCreators';
import { Tooltip } from '../../components/shared/Tooltip';

export interface RoleActionModalProps extends LocalizeContextProps {
  closeAddAndUpdateModal: (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => void;
  title: string;
  showDeleteButton: boolean;
  addUnit: boolean;
  readOnly?: boolean;
  id?: string;
  removeUnit?: (id: string) => Promise<void>;
  refreshRoles?: () => void;
  permission?: AggregatedPermission;
  closeMenu?: () => void;
  isKeyManagerModeEnabled: boolean;
}

export interface RoleActionModalState {
  roleData: IRole;
  tempRoleData: IRole;
  hiddenDeviceTypes: DeviceTypeRole[];
  deviceTypeOptions: DeviceTypeRole[];
  showDeleteModal: boolean;
  showAddNewDeviceType: boolean;
  isLoading: boolean;
  randomModalOffset?: { marginTop: number; marginRight: number };
}

class RoleActionModal extends React.Component<RoleActionModalProps, RoleActionModalState> {
  modalUid = uuidv4();

  constructor(props: RoleActionModalProps) {
    super(props);

    const { isKeyManagerModeEnabled } = this.props;

    this.state = {
      deviceTypeOptions: [],
      hiddenDeviceTypes: [],
      roleData: isKeyManagerModeEnabled ? ROLE_DEFAULT_VALUES_KEY_MANAGER : ROLE_DEFAULT_VALUES_WIOT,
      tempRoleData: isKeyManagerModeEnabled ? ROLE_DEFAULT_VALUES_KEY_MANAGER : ROLE_DEFAULT_VALUES_WIOT,
      showDeleteModal: false,
      showAddNewDeviceType: false,
      isLoading: false,
      randomModalOffset: { marginTop: 0, marginRight: 0 },
    };
  }

  componentDidMount = async () => {
    this.setState({
      isLoading: true,
      randomModalOffset: getRandomModalOffset(),
    });
    const activeDeviceTypes = await this.fetchActiveDeviceTypes();
    const activeDeviceTypesIds = activeDeviceTypes.map((dt) => dt.id);
    const roleOptions = await this.fetchPermittedRoleOptions();
    if (!this.props.addUnit && this.props.id) {
      const { role: fetchedRole } = await fetchRoleFromDB(this.props.id);
      const permittedRoles = roleOptions.filter(
        (roleOption: IRole) => roleOption.id && fetchedRole.permittedRoles?.includes(roleOption.id),
      );
      const { deviceTypes } = fetchedRole;
      const { shown, hidden } = groupBy(deviceTypes, (deviceTypeRole: DeviceTypeRole) =>
        activeDeviceTypesIds.includes(deviceTypeRole.deviceType.id) ? 'shown' : 'hidden',
      );

      this.setState({
        hiddenDeviceTypes: hidden || [],
        // @ts-ignore
        deviceTypeOptions: activeDeviceTypes.map<DeviceTypeRole>((dt) => ({
          deviceType: dt,
        })),
        roleData: {
          ...fetchedRole,
          permittedRoles,
          deviceTypes: shown,
        },
      });
    } else {
      this.setState({
        // @ts-ignore
        deviceTypeOptions: activeDeviceTypes.map<DeviceTypeRole>((dt) => ({
          deviceType: dt,
        })),
      });
    }
    this.setState({ isLoading: false });
  };

  fetchPermittedRoleOptions = async () => {
    const data = await fetchRolesFromDB();
    if (data && data.roles) {
      return data.roles;
    }
    return [];
  };

  fetchActiveDeviceTypes = async (): Promise<IDeviceType[]> => {
    const allDeviceTypes = await fetchDeviceTypesFromDB();
    return allDeviceTypes.filter((deviceType) => deviceType.isActive);
  };

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }));
  };

  prepareValuesToSubmit = (values: IRole) => {
    // @ts-ignore
    const { currentUsers, permittedRoles, ...valuesToSubmit } = values;
    // @ts-ignore
    valuesToSubmit.permittedRoles = permittedRoles?.map((role) => role.id);
    return valuesToSubmit;
  };

  handleSubmit = async (values: IRole) => {
    const submitValues = this.prepareValuesToSubmit(values);

    if (this.props.addUnit) {
      const response = await addRoleToDB(submitValues);
      if (response) {
        toastr.success(
          this.props.translate('success').toString(),
          this.props.translate('add-role-success').toString(),
        );
      }
    } else {
      const updatedRole = { ...submitValues };
      if (updatedRole.deviceTypes) {
        updatedRole.deviceTypes = [...updatedRole.deviceTypes, ...this.state.hiddenDeviceTypes];
      } else {
        updatedRole.deviceTypes = this.state.hiddenDeviceTypes;
      }
      const response = await updateRoleInDB(updatedRole);
      if (response) {
        toastr.success(
          this.props.translate('success').toString(),
          this.props.translate('edit-role-success').toString(),
        );
      }
    }
    fetchPermittedRoles();
    this.props.refreshRoles?.();
    this.props.closeAddAndUpdateModal();
    this.props.closeMenu?.();
  };

  handleDeviceTypeChange = (
    value: ValueType<DeviceTypeRole>,
    arrayHelpers: FieldArrayRenderProps,
  ) => {
    const { isKeyManagerModeEnabled } = this.props;
    this.setState({
      showAddNewDeviceType: false,
    });
    arrayHelpers.push({
      ...value,
      devices: cloneDeep(isKeyManagerModeEnabled ? DEVICE_ROLE_DEFAULT_VALUES_KEY_MANAGER : DEVICE_ROLE_DEFAULT_VALUES_WIOT),
      messages: cloneDeep(isKeyManagerModeEnabled ? DEVICE_ROLE_DEFAULT_VALUES_KEY_MANAGER : MESSAGE_ROLE_DEFAULT_VALUES_WIOT),
    });
  };

  handleGroupToggle = (name: string, value: boolean, setFieldValue: SetFieldValue) => {
    const { isKeyManagerModeEnabled } = this.props;
    /*
    Toggle all the child roles when the group button is clicked
    */
    const groupPathArr = name.split('.');
    const structureObj = getRoleSectionStructure(groupPathArr, name, isKeyManagerModeEnabled);
    Object.keys(structureObj).forEach((key) => {
      setFieldValue(`${ name }.${ key }`, value);
    });
  };

  handleSuperAdminToggle = (
    name: string,
    value: boolean,
    setFieldValue: SetFieldValue,
    values: IRole,
  ) => {
    /*
    Super admin by default will have all the roles. Hide other fields when superAdmin is true
    Save role in state and revert back when superAdmin is again unselected
    */
    if (value) {
      // When superadmin is selected, Hide other fields and keep the current state to facilitate resetting to current
      // state when superadmin is unselected
      this.setState({
        tempRoleData: values,
        roleData: {
          ...values,
          id: values.id,
          name: values.name,
          superAdmin: true,
        },
      });
    } else {
      // When superadmin is unselected, reset the previous selected roles
      this.setState((prevState) => ({
        roleData: {
          ...prevState.tempRoleData,
          id: values.id,
          name: values.name,
          superAdmin: false,
        },
      }));
    }
  };

  handleAdditionalBtnClick = () => {
    this.setState({
      showAddNewDeviceType: true,
    });
  };

  getRemainingDeviceTypeOptions = (selectedDeviceTypes?: IDeviceType[]) => {
    if (selectedDeviceTypes && selectedDeviceTypes.length > 0) {
      const selectedIds = selectedDeviceTypes.map((deviceType) => deviceType.id);
      return this.state.deviceTypeOptions.filter(
        (role) => role.deviceType.id && !selectedIds.includes(role.deviceType.id),
      );
    }
    return this.state.deviceTypeOptions;
  };

  getAttachmentForFeedback = (): FeedbackAttachment | null => {
    if (!this.state.roleData) {
      return null;
    }
    return {
      role: filterOutSensitiveDataFromRole(this.state.roleData),
    };
  }

  render() {
    const { isLoading, roleData, deviceTypeOptions, showAddNewDeviceType } = this.state;
    const { title, readOnly, closeAddAndUpdateModal, translate, isKeyManagerModeEnabled } = this.props;

    return (
      <div
        tabIndex={ 0 } // make it focusable
        style={ this.state.randomModalOffset }
        className="device-modal"
        id={ `${ title }-${ this.modalUid }` }
        onClick={ (event: React.MouseEvent) => event.stopPropagation() }
      >
        <ModalHeader
          isDevice={ false }
          titleTranslationId={ title }
          targetId={ this.modalUid }
          titlePostfix={ roleData?.name }
          handleClose={ closeAddAndUpdateModal }
          enableFeedbackSubmission={true}
          getFeedbackAttachment={ this.getAttachmentForFeedback }
        />
        <div className="device-modal__body">
          <Formik
            initialValues={ roleData }
            validationSchema={ Yup.object().shape({
              name: Yup.string().trim().required(translate('required-field').toString()),
            })}
            enableReinitialize
            onSubmit={ (values: IRole) => {
              this.handleSubmit(values);
            } }
            render={ ({ values, setFieldValue }: FormikProps<IRole>) => {
              const remainingDeviceTypeOptions = this.getRemainingDeviceTypeOptions(
                values.deviceTypes?.map((deviceTypeRole) => deviceTypeRole.deviceType),
              );
              return isLoading ? (
                <div className="loading-container">
                  <LoadingIcon/>
                </div>
              ) : (
                <Form
                  className="form"
                  onClick={ (event: React.MouseEvent) => event.stopPropagation() }
                >
                  <div className="form__section">
                    <Row className="align-items-center">
                      <Col lg={ 8 }>
                        <CustomFieldInput
                          translationId="role-name"
                          fieldName="name"
                          value={ values.name ? values.name.toString() : '' }
                          readOnly={ readOnly }
                        />
                      </Col>
                      <HasPermission
                        permissionObj={ this.props.permission }
                        permissionKey="roles.addRootAdmin"
                      >
                        <Col
                          className="pt-2"
                          data-tip=""
                          data-for="superadmin-toggle"
                        >
                          <CustomToggle
                            value={ values.superAdmin }
                            onChange={ setFieldValue }
                            name="superAdmin"
                            label="superAdmin"
                            values={ values }
                            readOnly={ readOnly }
                            groupToggleChange={ this.handleSuperAdminToggle }
                          />
                        </Col>
                        <Tooltip id="superadmin-toggle" delayShow={ 400 }>
                          <Translate id="super-admin-description"/>
                        </Tooltip>
                      </HasPermission>
                    </Row>
                    { !values.superAdmin && (
                      <>
                        <FieldArray
                          name="deviceTypes"
                          render={ (arrayHelpers) => (
                            <>
                              { values?.deviceTypes?.map((deviceType, index) => (
                                <React.Fragment key={ index }>
                                  <Row className="align-items-center mt-2">
                                    <Col xs={ 11 }>
                                      <DeviceTypeSelect
                                        name=""
                                        options={ remainingDeviceTypeOptions }
                                        value={ deviceType }
                                        onChange={ this.handleDeviceTypeChange }
                                        values={ values }
                                        isDisabled
                                        arrayHelpers={ arrayHelpers }
                                      />
                                    </Col>
                                    { !readOnly && (
                                      <Col xs={ 1 } className="align-self-end text-right">
                                        <button
                                          type="button"
                                          className="form__button--delete button-next-to-select"
                                          onClick={() => { arrayHelpers.remove(index); } }
                                        >
                                          <FontAwesomeIcon icon={ faTrash }/>
                                        </button>
                                      </Col>
                                    )}
                                  </Row>
                                  <RoleGroup
                                    values={ values }
                                    setFieldValue={ setFieldValue }
                                    readOnly={ readOnly }
                                    groupKey={ `deviceTypes.${ index }.devices` }
                                    groupToggleChange={ this.handleGroupToggle }
                                  />
                                  <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
                                    <RoleGroup
                                      values={ values }
                                      readOnly={ readOnly }
                                      setFieldValue={ setFieldValue }
                                      groupKey={ `deviceTypes.${ index }.messages` }
                                    />
                                  </RenderOnCondition>
                                </React.Fragment>
                              )) }
                              <AddNewDeviceType
                                deviceTypeOptions={ remainingDeviceTypeOptions }
                                handleDeviceTypeChange={ this.handleDeviceTypeChange }
                                handleAdditionalBtnClick={ this.handleAdditionalBtnClick }
                                values={ values }
                                arrayHelpers={ arrayHelpers }
                                readOnly={ readOnly }
                                showAddNewDeviceType={ showAddNewDeviceType }
                              />
                            </>
                          ) }
                        />
                        <RoleGroup
                          values={ values }
                          readOnly={ readOnly }
                          setFieldValue={ setFieldValue }
                          groupKey="deviceManager"
                        />
                        <RoleGroup
                          values={ values }
                          readOnly={ readOnly }
                          setFieldValue={ setFieldValue }
                          groupKey="deviceGroups"
                        />
                        <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
                          <RoleGroup
                            values={ values }
                            readOnly={ readOnly }
                            setFieldValue={ setFieldValue }
                            groupKey="propertyView"
                          />
                        </RenderOnCondition>
                        <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
                          <RoleGroup
                            values={ values }
                            readOnly={ readOnly }
                            setFieldValue={ setFieldValue }
                            groupKey="keys"
                          />
                        </RenderOnCondition>
                        <RoleGroup
                          values={ values }
                          readOnly={ readOnly }
                          setFieldValue={ setFieldValue }
                          groupKey="users"
                        />
                        <RoleGroup
                          values={ values }
                          readOnly={ readOnly }
                          setFieldValue={ setFieldValue }
                          groupKey="roles"
                        />
                        <RoleGroup
                          values={ values }
                          readOnly={ readOnly }
                          setFieldValue={ setFieldValue }
                          groupKey="settings"
                        />
                        <RoleGroup
                          values={ values }
                          readOnly={ readOnly }
                          setFieldValue={ setFieldValue }
                          groupKey="profile"
                        />
                        <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
                          <RoleGroup
                            values={ values }
                            readOnly={ readOnly }
                            setFieldValue={ setFieldValue }
                            groupKey="dataIntegration"
                            groupToggleChange={ this.handleGroupToggle }
                          />
                        </RenderOnCondition>
                        <RenderOnCondition condition={ this.props.isKeyManagerModeEnabled }>
                          <RoleGroup
                            values={ values }
                            readOnly={ readOnly }
                            setFieldValue={ setFieldValue }
                            groupKey="deviceKeyDownload"
                          />
                        </RenderOnCondition>
                      </>
                    ) }
                  </div>

                  { !this.props.readOnly && (
                    <div className="form__section last">
                      <div className="form__row space-between ai">
                        <div className="form__row--left">
                          { this.props.showDeleteButton && (
                            <button
                              type="button"
                              className="form__button--delete"
                              onClick={ this.toggleDeleteModal }
                            >
                              <Translate id="remove" />
                            </button>
                          ) }
                        </div>

                        <div className="form__row--right">
                          <button
                            type="button"
                            className="form__button--cancel"
                            onClick={ this.props.closeAddAndUpdateModal }
                          >
                            <Translate id="cancel"/>
                          </button>
                          <button
                            className="form__button--blue background-color-main text-color-white border-color-main"
                            type="submit"
                          >
                            <Translate id={ this.props.addUnit ? 'add-role' : 'save-role' }/>
                          </button>
                        </div>
                      </div>
                    </div>
                  ) }
                </Form>
              );
            } }
          />
          { this.state.showDeleteModal && this.props.id && (
            <ConfirmModal
              modalCloseRequested={ () => this.toggleDeleteModal() }
              actionConfirmed={ async () => {
                this.props.removeUnit && (await this.props.removeUnit(this.props.id!));
              } }
              translationIdOfElementType="user-role"
              confirmationVariant={ ConfirmationVariant.DELETE }
            />
          ) }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  permission: state.currentUser.permission,
  isKeyManagerModeEnabled: !!state.siteSettings.isKeyManagerModeEnabled,
});

export default connect(mapStateToProps)(withLocalize(RoleActionModal));
