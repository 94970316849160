import { AxiosResponse } from 'axios';
import moment from 'moment';

import api from './api';
import { FetchOptions } from '../state/types';
import { buildQueryString } from '../utils/common';
import { redirectToPath } from '../utils/windowLocation';
import { authenticatedInstance as httpClientInstance, handleApiError } from './apiHelpers';

export const downloadBackupFile = async (backupFilename: string) => {
  try {
    const url = `/backups/${ encodeURIComponent(backupFilename) }`;
    httpClientInstance.get(url).then((res: AxiosResponse) => {
      const { data } = res;
      if (data) {
        redirectToPath(`${ api.baseAPIUrl }${ url }`);
      }
    });
    return true;
  } catch (error) {
    return handleApiError(error);
  }
};
export const restoreBackupFile = async (backupFilenameToRestore: string, backupBeforeRestore: boolean) => {
  try {
    const backupFilename = moment().format('YYYY-MM-DD-hh-mm-ss')
    const qs = buildQueryString({ backupBeforeRestore, backupFilename });
    const url = `/backups/restore/${ encodeURIComponent(backupFilenameToRestore) }?${ qs }`;
    const { data } = await httpClientInstance.get(url);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};
export const createBackupFile = async (database: string, backupFilename: string) => {
  try {
    const qs = buildQueryString({ backupFilename: encodeURIComponent(backupFilename) });
    const url = `/backups/create/${ database }?${ qs }`;
    const { data } = await httpClientInstance.get(url);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};
export const getBackupFileInfos = async ({ page, pageSize }: FetchOptions<string>) => {
  try {
    const qs = buildQueryString({ page, pageSize });
    const url = `/backups?${ qs }`;
    const { data } = await httpClientInstance.get(url);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};
export const deleteBackupFile = async (backupFilename: string) => {
  try {
    const url = `/backups/${ backupFilename }`;
    const { data } = await httpClientInstance.delete(url);
    return data;
  } catch (error) {
    return handleApiError(error);
  }
};
