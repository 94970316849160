import React from 'react';
import { components, OptionTypeBase } from 'react-select';
import { IndicatorProps } from 'react-select/src/components/indicators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import RenderOnCondition from "../../../RenderOnCondition";

export interface ClearIndicatorProps<OptionType extends OptionTypeBase> extends IndicatorProps<OptionType> {
  clear: (MouseEvent) => void;
}

export const ClearIndicator = <OptionType extends OptionTypeBase> (props: ClearIndicatorProps<OptionType>) => {
  const { value } = props.selectProps;
  return (
    <components.ClearIndicator
      className="select__clear-indicator"
      { ...props }
    >
      <RenderOnCondition condition={ value }>
        <div
          className="select__clear-icon-wrapper"
          onClick={ props.clear }
        >
          <FontAwesomeIcon
            className="select__clear-icon"
            icon={ faTimes }
            color="var(--main-color)"
          />
        </div>
      </RenderOnCondition>
    </components.ClearIndicator>
  );
}
