import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Translate } from 'react-localize-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useTranslation from '../../hooks/useTranslation';
import RenderOnCondition from '../RenderOnCondition';

export enum ConfirmationVariant {
  CONFIRM = 'CONFIRM',
  DELETE = 'DELETE',
  RESTORE = 'RESTORE',
  REMOVE_DEVICE_GROUP = 'REMOVE_DEVICE_GROUP',
}

export interface ConfirmationModalProps {
  show?: boolean;
  modalCloseRequested: () => void;
  actionConfirmed: () => void;
  countOfElements?: number;
  translationIdOfElementType?: string;
  confirmationVariant?: ConfirmationVariant;
  modalHeaderTitle?: string;
  translationIdOfAdditionalInfo?: string;
}

const ConfirmModal = (props: ConfirmationModalProps) => {
  const {
    show = true,
    modalCloseRequested,
    actionConfirmed,
    countOfElements= 1,
    translationIdOfElementType,
    confirmationVariant = ConfirmationVariant.CONFIRM,
    modalHeaderTitle,
    translationIdOfAdditionalInfo,
  } = props;

  const translate = useTranslation();

  const isBulkOperation = countOfElements > 1;
  const translationIdOfAction = `to-${confirmationVariant}${ isBulkOperation ? '-all' : '' }`;

  const userInputRegex = new RegExp(translate(translationIdOfAction).toString());
  return (
    <Modal
      show={ show }
      onHide={ modalCloseRequested }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        onSubmit={() => {
          actionConfirmed();
          modalCloseRequested();
        }}
        validationSchema={Yup.object().shape({
          userInput: Yup.string()
            .required(translate('required-field').toString())
            .matches(userInputRegex, translate('invalid-user-input').toString()),
        })}
        initialValues={{
          userInput: '',
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate id={ `confirm-${ translationIdOfAction }` } />
                <RenderOnCondition condition={ modalHeaderTitle }>
                  <>{ isBulkOperation ? `: ${ countOfElements } ` : ': ' }</>
                  <Translate id={ modalHeaderTitle } />
                </RenderOnCondition>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="form__para">
                <Translate
                  id="confirm-bulk-operation"
                  data={ {
                    count: isBulkOperation ? countOfElements : '',
                    type: <Translate id={ translationIdOfElementType }/>,
                    action: <Translate id={ translationIdOfAction }/>
                  } }
                />
              </p>
              <RenderOnCondition condition={ translationIdOfAdditionalInfo }>
                <p className="form__para font-weight-bold">
                  <Translate id={ translationIdOfAdditionalInfo }/>;
                </p>
              </RenderOnCondition>
              <p className="form__para">
                <Translate id="action-cannot-be-revoked" data={ { action: <Translate id={ `${ translationIdOfAction }` }/> } }/>
              </p>
              <Form noValidate onSubmit={ handleSubmit }>
                <Form.Group controlId="validationFormik01">
                  <Form.Control
                    type="text"
                    name="userInput"
                    value={ values.userInput }
                    onChange={ handleChange }
                    isValid={ touched.userInput && !errors.userInput }
                    isInvalid={ !!errors.userInput }
                    placeholder={ translate(`${ translationIdOfAction }`).toString() }
                  />
                  <Form.Control.Feedback type="invalid">{ errors.userInput }</Form.Control.Feedback>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={ modalCloseRequested}
                className="form__button--cancel"
              >
                <Translate id="cancel" />
              </Button>
              <Button
                variant={"primary"}
                className={ confirmationVariant === 'DELETE' ? 'form__button--delete' : 'form__button--blue' }
                type="submit" onClick={() => handleSubmit()}>
                <Translate id={`confirm-${translationIdOfAction}`} />
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default ConfirmModal;
