import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Device } from '@wiot/shared-domain/models/device/device';
import { PROPERTY_VIEW_PAGE_KEY } from '@wiot/shared-domain/domain/property/property-view-page-key';
import { useDispatch, useSelector } from 'react-redux';
import { Consumption, ConsumptionByMBusDeviceTypeId } from '@wiot/shared-domain/domain/property/consumption';
import { Col, Container, Row } from 'react-bootstrap';
import ResponsiveContainer from '../../../../components/shared/ResponsiveContainer';
import { ConsumptionRow } from './ConsumptionRow';
import { AppState } from '../../../../state/reducers/rootReducer';
import { fetchDeviceTypes } from '../../../../state/device-types/fetchDeviceTypesActionCreator';
import LoadingIcon from '../../../../components/shared/LoadingIcon';
import Desktop from '../../../../components/Responsive/Desktop';
import { setFilter } from '../../../../state/filter/set-filter-action-creator';
import { PageKeys } from '../../../../state/reducers/filterSortReducer';
import RenderOnCondition from '../../../../components/RenderOnCondition';
import NoResultsInfo from '../../../../components/Table/NoResultsInfo';
import { FilterFieldKeys } from '../../../../components/Filter/filter-field-keys';
import { PROPERTY_VIEW_CONSUMPTION_CONSUMPTION_ID, PROPERTY_VIEW_CONSUMPTION_PIE_CHART_ID, } from '../property-view-constants';
import { selectPageFilters } from '../../../../state/filter/filterSelectors';
import { selectIsPropertyLoading, selectProperty, } from '../../../../state/property-view/propertySelectors';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import { MBusDeviceType } from '@wiot/shared-domain/models/device-types/m-bus-device-types';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import { accumulateConsumptions, getConsumptionByMBusDeviceTypeId } from '../ConsumptionUtils';
import { PropertyConsumptionType } from '@wiot/shared-domain/domain/property/property-consumption-type';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasPermission } from '../../../../utils/common';
import { openPropertySettingsModal } from '../../../../state/property-view/property-settings-modal/propertySettingsModalActionCreators';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';

interface PropertyViewConsumptionTableProps {
  pageKey: PageKeys;
  pieChartButtonClick?: () => void;
  consumptionButtonClick?: () => void;
}

export const PropertyViewConsumptionTable = function (props: PropertyViewConsumptionTableProps) {
  const { pageKey, pieChartButtonClick, consumptionButtonClick } = props;

  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();

  const filter = useSelector((state: AppState) => selectPageFilters(state, PROPERTY_VIEW_PAGE_KEY));
  const {
    consumptionType,
    billingFrequency,
    billingPeriodStartDate,
    propertyMBusDeviceTypeIdForConsumption,
    propertyMBusDeviceTypeIdForPieChart,
  } = filter;

  const property = useSelector((state: AppState) => selectProperty(state));
  const isPropertyLoading = useSelector(selectIsPropertyLoading);

  const userPermission = useSelector<AppState>((state) => state.currentUser.permission);
  const deviceTypes = useSelector((state: AppState) => state.deviceTypes);
  const [activeConsumptionDeviceType, setActiveConsumptionDeviceType] = useState<IDeviceType | undefined>();

  const [consumptionsByMBusDeviceTypeId, setConsumptionByMBusDeviceTypeId] = useState<ConsumptionByMBusDeviceTypeId>({});

  useEffect(() => {
    if (!deviceTypes?.length) {
      dispatch(fetchDeviceTypes());
    }
  }, [deviceTypes?.length, dispatch]);

  function setConsumptionDeviceType() {
    switch (consumptionType) {
      case PropertyConsumptionType.ENERGY:
        setActiveConsumptionDeviceType(deviceTypes?.find(dt => dt.mBusDeviceTypeId === MBusDeviceType.HEAT_METER));
        break;
      case PropertyConsumptionType.WATER:
      default:
        setActiveConsumptionDeviceType(deviceTypes?.find(dt => dt.mBusDeviceTypeId === MBusDeviceType.WATER_METER));
    }
  }

  useEffect(() => {
    setConsumptionDeviceType();
  }, [deviceTypes, consumptionType]);

  function getConsumptionsByMBusDeviceTypeId(devices: Device[]) {
    const consumptions: ConsumptionByMBusDeviceTypeId = {};

    devices?.forEach(device => {
      getConsumptionByMBusDeviceTypeId(device, consumptions);
    });

    return consumptions;
  }

  const setInitialMBusDeviceTypeFilter = (propertyViewFilter: FilterFieldKeys) => {
    const initialMBusDeviceTypeId = Number(Object.keys(consumptionsByMBusDeviceTypeId)[0]);

    if (initialMBusDeviceTypeId) {
      dispatch(
        setFilter({
          page: pageKey,
          values: {
            [propertyViewFilter]: initialMBusDeviceTypeId,
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (!propertyMBusDeviceTypeIdForPieChart) {
      setInitialMBusDeviceTypeFilter(PROPERTY_VIEW_CONSUMPTION_PIE_CHART_ID);
    }
    if (!propertyMBusDeviceTypeIdForConsumption) {
      setInitialMBusDeviceTypeFilter(PROPERTY_VIEW_CONSUMPTION_CONSUMPTION_ID);
    }
  }, [consumptionsByMBusDeviceTypeId]);

  useEffect(() => {
    if (property) {
      const { devices } = property;

      const consumptions = getConsumptionsByMBusDeviceTypeId(devices);

      setConsumptionByMBusDeviceTypeId(consumptions);
    }
  }, [property?.devices]);

  const getConsumptionRowsPerDeviceType = () => {
    const rows = Object.keys(consumptionsByMBusDeviceTypeId)
      .map((mBusDeviceTypeId: string) => {
          const deviceType = deviceTypes.find(dt => dt.mBusDeviceTypeId === Number(mBusDeviceTypeId));
          if (!deviceType) {
            return null;
          }

          return <ConsumptionRow
            key={ `consumption-row-${ mBusDeviceTypeId }` }
            deviceType={ deviceType }
            consumption={ consumptionsByMBusDeviceTypeId[Number(mBusDeviceTypeId)] }
            pieChartButtonClick={pieChartButtonClick}
            consumptionButtonClick={consumptionButtonClick}
          />;
        },
      );

    return rows.filter((row): row is React.ReactElement => !!row);
  };

  const getOverallConsumption = (): Consumption => {
    if (!activeConsumptionDeviceType?.mBusDeviceTypeId) {
      return { currentPeriodValue: 0, lastPeriodValue: 0, secondLastPeriodValue: 0 };
    }

    const accumulatedConsumptions: ConsumptionByMBusDeviceTypeId = {};
    accumulatedConsumptions[activeConsumptionDeviceType?.mBusDeviceTypeId] = {
      currentPeriodValue: 0,
      lastPeriodValue: 0,
      secondLastPeriodValue: 0,
    };

    Object.keys(consumptionsByMBusDeviceTypeId).forEach((mBusDeviceTypeId: string) => {
      accumulateConsumptions(
        { startDate: billingPeriodStartDate, mBusDeviceTypeId: Number(mBusDeviceTypeId) },
        activeConsumptionDeviceType,
        consumptionsByMBusDeviceTypeId,
        accumulatedConsumptions,
      );
    });
    return accumulatedConsumptions[activeConsumptionDeviceType?.mBusDeviceTypeId];
  }

  if (!property) {
    return <LoadingIcon />;
  }

  const getCompareToAverageColumn = () => {
    const hasUserViewPermission = hasPermission(userPermission, "propertyView.viewPropertySettings");

    if (!hasUserViewPermission) {
      return <Translate id="to-average-user" />;
    }

    return (
      <div
        onClick={ () => { dispatch(openPropertySettingsModal(property as DeviceGroup)) } }
        className='cursor-pointer'
      >
        <Translate id="to-average-user" />
        {' '}
        <FontAwesomeIcon icon={faCog} />
      </div>
    );
  };

  const shouldDisplayTable = Object.keys(consumptionsByMBusDeviceTypeId).length;

  return (
    <ResponsiveContainer
      title="consumptions-by-device-types"
      maxWidths={ { sm: 12, md: 12, lg: 12, xl: breakpoint === 'xxl' ? 8 : 12 } } // TODO(TL): Upgrade to bootstrap v5 for `xxl` breakpoint
    >
      <RenderOnCondition condition={ isPropertyLoading }>
        <LoadingIcon />
      </RenderOnCondition>
      <RenderOnCondition condition={ !shouldDisplayTable && !isPropertyLoading }>
        <NoResultsInfo />
      </RenderOnCondition>
      <RenderOnCondition condition={ shouldDisplayTable && !isPropertyLoading }>
        <Container className="property-view__consumption__table">
          <Desktop>
            <Row className="property-view__consumption__table-row mb-3">
              <Col lg={3} />
              <Col lg={2} className="text-right ">
                <Translate id="currently" />
              </Col>
              <Col lg={2} className="text-right">
                <Translate id={ `to-last-${ billingFrequency }` } />
              </Col>
              <Col lg={3} className="text-right">
                {getCompareToAverageColumn()}
              </Col>
            </Row>
          </Desktop>
          <RenderOnCondition condition={activeConsumptionDeviceType}>
            <ConsumptionRow
              deviceType={ activeConsumptionDeviceType! }
              consumption={ getOverallConsumption() }
              pieChartButtonClick={pieChartButtonClick}
              consumptionButtonClick={consumptionButtonClick}
              isHeaderRow={true}
            />
          </RenderOnCondition>
          {getConsumptionRowsPerDeviceType()}
        </Container>
      </RenderOnCondition>
    </ResponsiveContainer>
  );
};
