import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ValueType } from 'react-select/src/types';
import { FieldArrayRenderProps } from 'formik';
import { Translate } from 'react-localize-redux';
import { DeviceTypeRole, IRole } from '@wiot/shared-domain/models/role/role';
import DeviceTypeSelect from './DeviceTypeSelect';

interface IAddNewDeviceType {
  deviceTypeOptions: DeviceTypeRole[];
  handleDeviceTypeChange: (
    value: ValueType<DeviceTypeRole>,
    arrayHelpers: FieldArrayRenderProps,
    values: IRole,
  ) => void;
  handleAdditionalBtnClick: () => void;
  values: IRole;
  arrayHelpers: FieldArrayRenderProps;
  readOnly?: boolean;
  showAddNewDeviceType: boolean;
}

const AddNewDeviceType = ({
  deviceTypeOptions,
  handleDeviceTypeChange,
  handleAdditionalBtnClick,
  values,
  readOnly,
  showAddNewDeviceType,
  arrayHelpers,
}: IAddNewDeviceType) => {
  if (readOnly) {
    return null;
  }

  if (showAddNewDeviceType) {
    return (
      <>
        <Row className="align-items-center my-2">
          <Col>
            <DeviceTypeSelect
              name="choose-device-type"
              options={deviceTypeOptions}
              value={undefined}
              onChange={handleDeviceTypeChange}
              values={values}
              arrayHelpers={arrayHelpers}
            />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <button
      className="form__button my-4 py-1"
      type="button"
      onClick={handleAdditionalBtnClick}
      hidden={values.superAdmin}
    >
      <Translate id="additional-access-right" />
    </button>
  );
};

export default AddNewDeviceType;
