import { OptionTypeBase } from 'react-select';

export const sortOptions = <OptionType extends OptionTypeBase>(
  options: OptionType[],
  getOptionLabel?: (option: OptionType) => string
) => {
  return options.sort((a, b) => {
    const labelA = getOptionLabel ? getOptionLabel(a) : a.label;
    const labelB = getOptionLabel ? getOptionLabel(b) : b.label;
    return labelA.localeCompare(labelB);
  });
};
