import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';

import { AppState } from '../../state/reducers/rootReducer';
import Mobile from '../Responsive/Mobile';
import Desktop from '../Responsive/Desktop';

import { closeFeedbackModal, openFeedbackModal } from '../../state/feedback/feedbackActionCreators';

export function OpenFeedbackModalNavItem(): React.ReactElement | null {
  const dispatch = useDispatch();

  const isFeedbackModalVisible = useSelector((state: AppState) => !!state.feedback.isVisible);

  return (
    <div
      onClick={ isFeedbackModalVisible ? () => dispatch(closeFeedbackModal()) : () => dispatch(openFeedbackModal(null)) }
      className={
        `navbar-left__container-bottom__feedback
        ${ isFeedbackModalVisible
          ? 'navbar-left__container-top__item navbar-left__item--active background-color-main'
          : 'navbar-left__container-top__item'
        }`}
      data-testid={"navbar-left__container-top__item__text"}
      key={'send-feedback'}
    >
      <span className="navbar-left__container-top__item__text">
        <FontAwesomeIcon icon={faHeadset} />
      </span>
      <Mobile>
        <span>
          <Translate id={"send-feedback"}/>
        </span>
      </Mobile>
      <Desktop>
        <span className="collapsible-content">
          <Translate id={"send-feedback"}/>
        </span>
      </Desktop>
    </div>
  );
}
