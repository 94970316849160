import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';

export const FETCH_SELECTED_DEVICE_GROUP = 'FETCH_SELECTED_DEVICE_GROUP';
export const SELECTED_DEVICE_GROUP_FETCHED = 'SELECTED_DEVICE_GROUP_FETCHED';
export const RESET_GROUP_SELECT = 'RESET_GROUP_SELECT';
export const DESELECT_DEVICE_GROUP = 'DESELECT_DEVICE_GROUP';
export const DEVICE_GROUP_FETCH_FAILED = 'DEVICE_GROUP_FETCH_FAILED';

export type FetchDeviceGroupActionTypes =
  FetchSelectedDeviceGroupAction
  | SelectedDeviceGroupFetchedAction
  | DeviceGroupFetchFailed
  | ResetGroupSelectAction
  | DeselectDeviceGroupAction;

export interface FetchSelectedDeviceGroupAction {
  type: typeof FETCH_SELECTED_DEVICE_GROUP;
  deviceGroupId: string;
  groupSelectUuid: string;
}

export interface SelectedDeviceGroupFetchedAction {
  type: typeof SELECTED_DEVICE_GROUP_FETCHED;
  deviceGroup: DeviceGroup;
  groupSelectUuid: string;
  isMultiSelect: boolean;
}

export interface ResetGroupSelectAction {
  type: typeof RESET_GROUP_SELECT;
  groupSelectUuid: string;
}
export interface DeselectDeviceGroupAction {
  type: typeof DESELECT_DEVICE_GROUP;
  deviceGroupId:string;
  groupSelectUuid: string;
}

export interface DeviceGroupFetchFailed {
  type: typeof DEVICE_GROUP_FETCH_FAILED;
  deviceGroupId: string;
  groupSelectUuid: string;
}

export const fetchSelectedDeviceGroupAction = (deviceGroupId: string, groupSelectUuid: string): FetchSelectedDeviceGroupAction => ({
  type: FETCH_SELECTED_DEVICE_GROUP,
  deviceGroupId,
  groupSelectUuid
});

export const deselectDeviceGroup = (deviceGroupId: string, groupSelectUuid: string): DeselectDeviceGroupAction => ({
  type: DESELECT_DEVICE_GROUP,
  deviceGroupId,
  groupSelectUuid
});

export const resetGroupSelect = (groupSelectUuid: string): ResetGroupSelectAction => ({
  type: RESET_GROUP_SELECT,
  groupSelectUuid
});

export const deviceGroupFetched = (
  deviceGroup: DeviceGroup,
  groupSelectUuid: string,
  isMultiSelect:boolean,
  ): SelectedDeviceGroupFetchedAction => ({
  type: SELECTED_DEVICE_GROUP_FETCHED,
  deviceGroup: deviceGroup,
  groupSelectUuid,
  isMultiSelect,
});

export const deviceGroupFetchFailed = (deviceGroupId: string, groupSelectUuid: string): DeviceGroupFetchFailed => ({
  type: DEVICE_GROUP_FETCH_FAILED,
  deviceGroupId,
  groupSelectUuid
});
