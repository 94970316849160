import { Field } from 'formik';
import { DataIntegrationJobExtended } from '@wiot/shared-domain/models/data-integration/data-integration';
import React, { useCallback } from 'react';
import { Col } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { SetFieldValue } from '../../../../state/types';

interface IsActiveButtonProps {
  api: DataIntegrationJobExtended;
}

const IsActiveButton = (props: IsActiveButtonProps) =>  (
  <Col lg={2}>
    <div className="form__active">
      <div className="form__label">
        <label>
          <span className="custom-checkbox__label">
            <Translate id="active" />
          </span>
          <label className="custom-checkbox">
            <Field
              component="input"
              name="api.active"
              type="checkbox"
              checked={ props.api.active }
            />
            <span className="checkmark border-color-main" />
          </label>
        </label>
      </div>
    </div>
  </Col>
);

export default IsActiveButton;
