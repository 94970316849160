import { Accordion, Card } from 'react-bootstrap';
import React from 'react';
import { FormikProps } from 'formik';
import { ISettings } from '@wiot/shared-domain/models/settings/settings';
import { CustomFieldInput } from '../../../components/Table/CustomFieldInput';

export function EmailSendgridSettings({ formikProps: { values: { sendgrid } }, readOnly }:
  { formikProps: FormikProps<ISettings>, readOnly: boolean | undefined }) {
  return <Accordion.Collapse eventKey="sendgrid">
    <Card.Body>
      <CustomFieldInput
        translationId="apiKey"
        fieldName="sendgrid.apiKey"
        value={ sendgrid?.apiKey }
        readOnly={ readOnly }
      />
      <CustomFieldInput
        translationId="sender-name"
        fieldName="sendgrid.senderName"
        value={ sendgrid?.senderName }
        readOnly={ readOnly }
      />
      <CustomFieldInput
        translationId="sender-email"
        fieldName="sendgrid.senderEmail"
        value={ sendgrid?.senderEmail }
        readOnly={ readOnly }
      />
      {
        sendgrid?.templateId && Object.keys(sendgrid.templateId).map(templateKey =>
          <CustomFieldInput
            key={`card-body-input-field-template-id-${templateKey}`}
            translationId={ `template-id-${ templateKey }` }
            fieldName={ `sendgrid.templateId.${ templateKey }` }
            value={
              sendgrid.templateId![templateKey]
            }
            readOnly={ readOnly }
          />
        )
      }
    </Card.Body>
  </Accordion.Collapse>;
}
