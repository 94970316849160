import { WIDGETS_DATA_FETCHED, WidgetsDataFetchedAction } from './fetchWidgetsDataAction';
import { WidgetsData } from '@wiot/shared-domain/models/dashboard/widgets-data';

const initialState: WidgetsData = {
  deviceCountsByTypeSummary: {
    total: 0,
    type: '',
    deviceCountsByType: [],
  },
  deviceStatusSummary: {
    total: 0,
    type: '',
    types: {},
  },
  devicesHavingConsumptionData: [],
  last5DeviceReadings: [],
  deviceGroupsSummary: {
    total: 0,
    type: '',
    types: {},
  },
};

export const widgetsDataReducer = (
  state: WidgetsData = initialState,
  action: WidgetsDataFetchedAction,
): WidgetsData => {
  switch (action.type) {
    case WIDGETS_DATA_FETCHED:
      return {
        deviceCountsByTypeSummary: action.deviceCountsByTypeSummary,
        deviceStatusSummary: action.deviceStatusSummary,
        devicesHavingConsumptionData: action.devicesHavingConsumptionData,
        last5DeviceReadings: action.last5DeviceReadings,
        deviceGroupsSummary: action.deviceGroupsSummary,
      };
    default:
      return state;
  }
};
