import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { faExclamationTriangle, faKey, faLock, faSignOutAlt, faUser, faUserPlus, } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { EmailCredentials, UserDetails } from '@wiot/shared-domain/models/auth/auth-api';
import ProcessStep from './ProcessStep';
import LoginForm from './LoginForm';
import CreateDevicePasswordForm from './CreateDevicePasswordForm';
import { AppState } from '../../state/reducers/rootReducer';
import { logout as logoutActionCreator } from '../../state/actions/logoutActionCreator';
import PrimaryButton from '../shared/PrimaryButton';
import SecondaryButton from '../shared/SecondaryButton';
import { assignDeviceKeyToCurrentUser, loginAndAssignDeviceKey, } from '../../state/device-key/assignDeviceKeyToCurrentUserActionCreators';
import RenderOnCondition from '../RenderOnCondition';
import RegisterForm from './RegisterForm';
import { UserCreationDefinition } from '@wiot/shared-domain/models/device/user-creation-definition';
import { createUserAndAssignDownloadHashActionCreator, } from '../../state/device-key/createUserAndAssignDownloadHashActionCreator';

function KeyProtectionStep(props: { downloadHash: string }) {
  const dispatch = useDispatch();
  const { downloadHash } = props;
  const isLoggedIn = useSelector((appState: AppState) => appState.isLoggedIn);
  const currentUserDetails = useSelector((state: AppState) => state.currentUser) as UserDetails;

  const [ isCreatePasswordFormVisible, setIsCreatePasswordFormVisible ] = useState(false);
  const [ isLoginFormVisible, setIsLoginFormVisible ] = useState(false);
  const [ isRegisterFormVisible, setRegisterFormVisibility ] = useState(false);
  const protectionDetails = useSelector(
    (state: AppState) => state.deviceKeyProtection.protectionDetails.protectionDetails,
  );
  const isMenuVisible = () => !isLoginFormVisible && !isCreatePasswordFormVisible && !isRegisterFormVisible;

  useEffect(() => {
    if (isCreatePasswordFormVisible && protectionDetails.isProtected) {
      setIsCreatePasswordFormVisible(false);
    }
  }, [ isCreatePasswordFormVisible, protectionDetails ]);

  const getProtectionStatusText = (): JSX.Element => {
    if (protectionDetails.isProtectedByDevicePassword) {
      return <Translate id="device-key-download_key-is-password-protected"/>;
    }

    if (protectionDetails.isProtected && !protectionDetails.isProtectedByDevicePassword) {
      return <Translate id="device-key-download_key-is-assigned-to-user"/>;
    }

    return <Translate id="device-key-download_key-is-not-protected-please-secure"/>;
  };

  const isRegularUserLoggedIn = (): boolean =>
    isLoggedIn && currentUserDetails.user !== undefined && currentUserDetails.user.isAnonymous === false;

  const getProtectionOptionsMenu = () => {
    return <div className="btn-group-vertical option-list standard-font-size">
      <RenderOnCondition condition={ isRegularUserLoggedIn() }>
        <>
          <PrimaryButton
            textTranslationId="device-key-download_assign-key-to-current-account"
            onClick={ async () => {
              await assignToCurrentUser();
            } }
            icon={ faUser }
            isVisible={ isRegularUserLoggedIn() }
          />
          <PrimaryButton
            textTranslationId="device-key-download_logout-and-use-different-account"
            onClick={ () => {
              dispatch(logoutActionCreator());
            } }
            icon={ faSignOutAlt }
            isVisible={ isRegularUserLoggedIn() }
          />
        </>
      </RenderOnCondition>

      <PrimaryButton
        textTranslationId="device-key-download_assign-to-existing-user"
        onClick={ () => {
          setIsLoginFormVisible(true);
        } }
        isVisible={ !isRegularUserLoggedIn() }
        icon={ faUser }
        classNames="text-left"
      />
      <PrimaryButton
        textTranslationId="device-key-download_register-and-assign"
        onClick={ () => {
          setRegisterFormVisibility(true);
        } }
        isVisible={ !isRegularUserLoggedIn() }
        icon={ faUserPlus }
        classNames="text-left"
      />
      <PrimaryButton
        textTranslationId="device-key-download_protect_with_password_only"
        onClick={ () => setIsCreatePasswordFormVisible(true) }
        icon={ faKey }
        classNames="text-left"
      />
    </div>;
  };

  const loginAndAssignToExistingUser = (username: string, password: string) => {
    const credentials = { email: username, password } as EmailCredentials;
    dispatch(loginAndAssignDeviceKey(credentials, downloadHash));
  };

  const assignToCurrentUser = async () => {
    dispatch(assignDeviceKeyToCurrentUser(downloadHash, currentUserDetails));
  };

  const onRegistrationFormSubmit = async (userCreationDefinition: UserCreationDefinition) => {
    dispatch(createUserAndAssignDownloadHashActionCreator(userCreationDefinition, downloadHash));
  };

  function getControlForCurrentSelectedMenuOption() {
    let innerComponent: JSX.Element | null = null;
    let title = '';
    if (isLoginFormVisible) {
      innerComponent = <LoginForm loginButtonTranslationId="device-key-download_login-and-assign"
                                  onLoginButtonClick={ loginAndAssignToExistingUser }/>;
      title = 'login';
    }

    if (isCreatePasswordFormVisible) {
      innerComponent = <CreateDevicePasswordForm downloadHash={ downloadHash }/>;
      title = 'device-key-download_create-password';
    }

    if (isRegisterFormVisible) {
      innerComponent = <RegisterForm onFormSubmit={ onRegistrationFormSubmit }/>;
      title = 'create-user';
    }

    return (
      <div className="form-wrapper my-2">
        <div className="form-wrapper__title">
          <Translate id={ title }/>
        </div>
        { innerComponent }
      </div>
    );
  }

  return (
    <ProcessStep
      isActive
      titleTranslationId="device-key-download_secure-key"
      mainMessage={ getProtectionStatusText() }
      mainMessageIcon={ protectionDetails.isProtected ? faLock : faExclamationTriangle }
      mainMessageIconColor={ protectionDetails.isProtected ? 'green' : '#ec2727' }
    >
      <RenderOnCondition condition={ !protectionDetails.isProtected }>
        <div>
          <RenderOnCondition condition={ isMenuVisible() }>
            { getProtectionOptionsMenu() }
          </RenderOnCondition>

          <RenderOnCondition condition={ !isMenuVisible() }>
            { getControlForCurrentSelectedMenuOption() }
          </RenderOnCondition>

          <SecondaryButton
            textTranslationId="back"
            onClick={ () => {
              setIsCreatePasswordFormVisible(false);
              setIsLoginFormVisible(false);
              setRegisterFormVisibility(false);
            } }
            isVisible={ !isMenuVisible() }
          />
        </div>

      </RenderOnCondition>
    </ProcessStep>
  );
}

export default KeyProtectionStep;
