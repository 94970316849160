import moment from 'moment';

import { MeasurementFrequency } from '@wiot/shared-domain/models/device-reading/measurement';
import { PROPERTY_VIEW_PATH } from '../../navigation/paths';
import { PropertyConsumptionType } from '@wiot/shared-domain/domain/property/property-consumption-type';

export type PropertyViewRouteArgs = {
  deviceGroupId?: string;
  consumptionType?: PropertyConsumptionType;
  billingFrequency?: MeasurementFrequency;
  billingPeriodStartDate?: string;
};

const addPropertyDeviceGroupIdParams = (
  queryParams: string[],
  propertyDeviceGroupId: string | undefined,
  currentQueryParams: URLSearchParams,
): string[] => {
  const deviceGroupIdQueryParam = currentQueryParams.get('deviceGroupId' as keyof PropertyViewRouteArgs);

  if (!propertyDeviceGroupId && !deviceGroupIdQueryParam) {
    return [...queryParams];
  }

  return [
    ...queryParams,
    `deviceGroupId=${ propertyDeviceGroupId ?? deviceGroupIdQueryParam }`,
  ];
};

const addBillingFrequencyParams = (
  queryParams: string[],
  billingFrequency: MeasurementFrequency | undefined,
  currentQueryParams: URLSearchParams,
): string[] => {
  const billingFrequencyQueryParam = currentQueryParams.get('billingFrequency' as keyof PropertyViewRouteArgs);
  const defaultBillingFrequencyKey = Object.keys(MeasurementFrequency).find(
    (k) => MeasurementFrequency[k] === MeasurementFrequency.MONTH,
  ) ?? null;
  const billingFrequencyKey = Object.keys(MeasurementFrequency).find(
    (k) => MeasurementFrequency[k] === billingFrequency,
  ) ?? null;

  return [
    ...queryParams,
    `billingFrequency=${ billingFrequencyKey ?? billingFrequencyQueryParam ?? defaultBillingFrequencyKey }`,
  ];
};

const addConsumptionTypeParams = (
  queryParams: string[],
  consumptionType: PropertyConsumptionType | undefined,
  currentQueryParams: URLSearchParams,
): string[] => {
  const consumptionTypeQueryParam = currentQueryParams.get('consumptionType' as keyof PropertyViewRouteArgs);
  const defaultConsumptionTypeKey = Object.keys(PropertyConsumptionType).find(
    (k) => PropertyConsumptionType[k] === PropertyConsumptionType.ENERGY,
  ) ?? null;
  const consumptionTypeKey = Object.keys(PropertyConsumptionType).find(
    (k) => PropertyConsumptionType[k] === consumptionType,
  ) ?? null;

  return [
    ...queryParams,
    `consumptionType=${ consumptionTypeKey ?? consumptionTypeQueryParam ?? defaultConsumptionTypeKey }`,
  ];
};

const addBillingPeriodStartDateParams = (
  queryParams: string[],
  billingPeriodStartDate: string | undefined,
  currentQueryParams: URLSearchParams,
) => {
  const billingPeriodStartDateQueryParam = currentQueryParams.get('billingPeriodStartDate' as keyof PropertyViewRouteArgs);
  const defaultBillingPeriodStartDate = moment().startOf('year').format('YYYY-MM-DD');

  return [
    ...queryParams,
    `billingPeriodStartDate=${ billingPeriodStartDate ?? billingPeriodStartDateQueryParam ?? defaultBillingPeriodStartDate }`,
  ];
};

/**
 * Generates a generate property view path to navigate to.
 *
 * @param args The arguments to generate the property view path with.
 * @returns The generated property view path function.
 */
export function generatePropertyViewPath(args: PropertyViewRouteArgs): string {
  const {
    consumptionType,
    billingFrequency,
    billingPeriodStartDate,
    deviceGroupId,
  } = args;
    const currentURLSearchParams = new URLSearchParams(window.location.search);
    let queryParamsList: string[] = addConsumptionTypeParams([], consumptionType, currentURLSearchParams);
    queryParamsList = addPropertyDeviceGroupIdParams(queryParamsList, deviceGroupId, currentURLSearchParams);
    queryParamsList = addBillingFrequencyParams(queryParamsList, billingFrequency, currentURLSearchParams);
    queryParamsList = addBillingPeriodStartDateParams(queryParamsList, billingPeriodStartDate, currentURLSearchParams);

    return `${ PROPERTY_VIEW_PATH }?${ queryParamsList.join('&') }`;
  };
