import { Dispatch } from 'redux';
import { authenticatedInstance as httpClientInstance, handleApiError } from '../../api/apiHelpers';
import { FETCH_WIDGETS_DATA, FetchWidgetsDataAction, WIDGETS_DATA_FETCHED, WidgetsDataFetchedAction, } from './fetchWidgetsDataAction';
import { isLoading } from '../actions/isLoadingAction';
import { WidgetsData } from '@wiot/shared-domain/models/dashboard/widgets-data';

export const fetchWidgetsData = () => async (
  dispatch: Dispatch<any>,
): Promise<void> => {
  dispatch({ type: FETCH_WIDGETS_DATA } as FetchWidgetsDataAction);
  dispatch(isLoading(true));
  try {
    const url = `/dashboard/widgetsData`;
    const { data } = await httpClientInstance.get<WidgetsData>(url);
    dispatch(
      {
        type: WIDGETS_DATA_FETCHED,
        deviceCountsByTypeSummary: data.deviceCountsByTypeSummary,
        devicesHavingConsumptionData: data.devicesHavingConsumptionData,
        deviceStatusSummary: data.deviceStatusSummary,
        last5DeviceReadings: data.last5DeviceReadings,
        deviceGroupsSummary: data.deviceGroupsSummary,
      } as WidgetsDataFetchedAction);

  } catch (error) {
    handleApiError(error, false);
  } finally {
    dispatch(isLoading(false));
  }
};
