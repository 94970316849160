import React, { ReactElement } from 'react';
import { Col } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';

interface DetailColProps {
  translateKey: string;
  children?: ReactElement<any> | ReactElement<any>[];
  colSize?: number;
  additionalClassNames?: string;
}

const DetailCol = ({ translateKey, children, colSize = 6, additionalClassNames = '' }: DetailColProps) => (
  <Col lg={colSize}>
    <label className={`details__label ${ additionalClassNames }`}>
      <Translate id={translateKey} />
      {children}
    </label>
  </Col>
);

export default DetailCol;
