import { ServerFrontendVersionFetchedAction } from '../../types';
import { SERVER_FRONTEND_VERSION_FETCHED } from '../../constants/actionTypes';

export const frontendVersion: string = process.env.WIOT_VERSION ?? '';
const initialState = false;

export const isDifferentFrontendVersionAvailableReducer = (
  state = initialState,
  action: ServerFrontendVersionFetchedAction,
): boolean => {
  switch (action.type) {
    case SERVER_FRONTEND_VERSION_FETCHED: {
      const serverVersion = action.version;

      return frontendVersion !== serverVersion;
    }

    default:
      return state;
  }
};
