import React from 'react';
import { Col, Row, Accordion, Card, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Translate } from 'react-localize-redux';
import OcrWizardResult from './OcrWizardResult';
import { AccordionGroup } from '../shared/AccordionGroup';
import { AccordionItem } from '../shared/AccordionItem';

interface OcrWizardSectionProps {
  ocrFoundData: string[];
  searchedValue: { label: string; value: string };
  activeKey: React.SyntheticEvent<HTMLDivElement> | undefined;
  onFoundValueChange: (event: React.FormEvent, index: number, searchedValue: string) => void;
  setOcrData: (ocrFoundData: string[]) => void;
}

const OcrWizardSection = (props: OcrWizardSectionProps) => {
  const { setOcrData, activeKey, onFoundValueChange, ocrFoundData, searchedValue } = props;
  ocrFoundData && setOcrData(ocrFoundData);

  const ocrWizardAccordionHeader = (
    <Translate id={ searchedValue.label }/>
  );

  const ocrWizardAccordionBody = (
    <div>
      { ocrFoundData &&
        ocrFoundData.map((foundValue: string, index: number) => (
          <OcrWizardResult
            key={ `inputgroup-${ index }` }
            searchedValue={ searchedValue }
            onFoundValueChange={ onFoundValueChange }
            foundValue={ foundValue }
            index={ index }
          />
        )) }
    </div>
  );

  return (
    <Card key={searchedValue.label}>
      <AccordionGroup>
        <AccordionItem
          className="ocr-wizard__header"
          eventKey={searchedValue.label}
          header={ ocrWizardAccordionHeader }
          body={ ocrWizardAccordionBody }
        />
      </AccordionGroup>
    </Card>
  );
};

export default OcrWizardSection;
