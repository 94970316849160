import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import RenderOnCondition from '../../../components/RenderOnCondition';
import ConfirmModal, { ConfirmationVariant } from '../../../components/Modal/ConfirmModal';
import KeyDevicesModal from '../KeyDevicesModal';
import { getDeviceGroupPath, NO_ANCESTORS_PATH } from '../../../utils/device-group-path-helper';
import { RadioKeyExtended } from '../../../state/types';
import KeyActionModal from '../KeyActionModal';
import CustomCheckbox from '../../../components/shared/CustomCheckbox';
import { PortalMenu } from '../../../components/PortalMenu/PortalMenu';
import TableMenu from './TableMenu';
import {
  HORIZONTAL_OFFSET_TABLE_VIEW,
  VERTICAL_OFFSET_TABLE_VIEW
} from '../../../components/PortalMenu/constants/offset-table-view';
import { AppState } from '../../../state/reducers/rootReducer';
import { getMenuId } from '../../../components/PortalMenu/constants/context-menu-ids';
import { closeContextMenu, openContextMenu } from '../../../state/context-menu/toggleContextMenuActionCreators';
import { Tooltip } from '../../../components/shared/Tooltip';
import DeviceGroupPathCell from '../../../components/Table/DeviceGroupPathCell';
import { localizeDate } from '../../../utils/common';

export interface TableDataRowProps {
  refreshData: (column?: IColumnObject) => void;
  radioKey: RadioKeyExtended;
  deviceCount: number | null;
  selectOne: (id: string) => void;
  removeUnit: (id: string) => Promise<void>;
}

const TableDataRow = (props: TableDataRowProps) => {
  const { deviceCount, refreshData, selectOne, removeUnit, radioKey } = props;
  const { id, name, checked, createdAt } = radioKey;
  let toggleMenuButton: HTMLDivElement | null;

  const [showKeyDevices, setShowKeyDevices] = useState(false);
  const [toggleMenuButtonBoundingClientRect, setToggleMenuButtonBoundingClientRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });
  const [showKeyActionModal, setShowKeyActionModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const visibleContextMenu = useSelector((state: AppState) => state.contextMenu.visibleContextMenuId);

  const dispatch = useDispatch();

  const toggleKeyActionModal = () => {
    setShowKeyActionModal((prevState) => !prevState);
  };

  const menuId = getMenuId(id);
  const showMenu = (visibleContextMenu === menuId);
  const toggleMenu = () => {
    if (toggleMenuButton) {
      setToggleMenuButtonBoundingClientRect(toggleMenuButton.getBoundingClientRect());
    }
    if (!showMenu) {
      dispatch(openContextMenu(menuId));
    } else {
      dispatch(closeContextMenu());
    }
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prevState) => !prevState);
  };

  const getSelectedTree = () => {
    if (radioKey.deviceGroup && typeof radioKey.deviceGroup === 'string') {
      // const { deviceGroups } = await fetchDeviceGroupFromDB(radioKey.deviceGroup);
      // radioKey.deviceGroup = deviceGroups[0]
      return {
        fullPath: radioKey.deviceGroup,
        truncatedPath: radioKey.deviceGroup,
      };
    }
    if (radioKey.deviceGroup.ancestors) {
      return getDeviceGroupPath(radioKey.deviceGroup);
    }
    return NO_ANCESTORS_PATH;
  };

  const toggleShowKeyDevices = () => {
    setShowKeyDevices((prevState) => !prevState);
  };

  const getKeyType = (totalDevicesCount: number) => {
    if (totalDevicesCount > 1) {
      return 'MULTI-KEY';
    }

    if (totalDevicesCount === 1) {
      return 'SINGLE-KEY';
    }
    return 'NO-KEY';
  };

  const keyType = getKeyType(deviceCount || 0);
  const deviceGroupPath = getSelectedTree();

  return (
    <>
      <tr key={id} id={id} className="device-manager__table__table__trow">
        <td>
          <CustomCheckbox onChange={() => selectOne(id)} checked={checked} />
        </td>
        <td>{name || '-'}</td>
        <DeviceGroupPathCell
          id={id}
          deviceGroupPath={ deviceGroupPath }
        />
        <td>{createdAt ? localizeDate(createdAt) : ''}</td>
        <td
          className={deviceCount ? 'device-manager__table__table__link' : ''}
          onClick={() => deviceCount && toggleShowKeyDevices()}
          data-tip="key-dev-count"
          data-for={`key-dev-count-${id}`}
        >
          {deviceCount}
          {deviceCount ? (
            <Tooltip id={`key-dev-count-${id}`}>
              <Translate id="key-dev-count-desc" />
            </Tooltip>
          ) : (
            ''
          )}
        </td>
        <td data-tip="key-type-desc" data-for={`key-type-desc-${id}`}>
          <Translate id={keyType} />
          <Tooltip
            id={`key-type-desc-${id}`}
            delayShow={400}
          >
            <Translate id={`${keyType}-desc`} />
          </Tooltip>
        </td>
        <td className="no-overflow">
          <section className="device-details">
            <p
              className="ellipsis text-color-main"
              role="presentation"
              ref={(tc) => {
                toggleMenuButton = tc;
              }}
              onClick={toggleMenu}
              data-tip="table-actions-button-tooltip"
              data-for="table-actions-button-tooltip"
            >
              <FontAwesomeIcon icon={faCog} />
            </p>
            <Tooltip id="table-actions-button-tooltip">
              <Translate id="actions" />
            </Tooltip>
            <RenderOnCondition condition={showMenu}>
              <PortalMenu
                anchorPosition={{
                  left: toggleMenuButtonBoundingClientRect.left + HORIZONTAL_OFFSET_TABLE_VIEW,
                  top: toggleMenuButtonBoundingClientRect.top + window.scrollY + VERTICAL_OFFSET_TABLE_VIEW
                }}
                minWidth={ 120 }
              >
                <TableMenu
                  refreshData={ refreshData }
                  removeUnit={ (itemId: string) => removeUnit(itemId) }
                  menuId={ menuId }
                  toggleKeyActionModal={ toggleKeyActionModal }
                  toggleDeleteModal={ toggleDeleteModal }
                />
              </PortalMenu>
            </RenderOnCondition>

            <RenderOnCondition condition={showDeleteModal}>
              <ConfirmModal
                modalCloseRequested={() => toggleDeleteModal()}
                actionConfirmed={() => removeUnit(id)}
                translationIdOfElementType="key"
                confirmationVariant={ ConfirmationVariant.DELETE }
              />
            </RenderOnCondition>

            <RenderOnCondition condition={showKeyActionModal}>
              <KeyActionModal
                closeAddAndUpdateModal={toggleKeyActionModal}
                title="update-key"
                showDeleteButton
                addUnit={false}
                removeUnit={(itemId: string) => removeUnit(itemId)}
                id={id}
                refreshData={refreshData}
              />
            </RenderOnCondition>
          </section>
        </td>
      </tr>
      {showKeyDevices && (
        <KeyDevicesModal
          keyId={radioKey.id}
          keyName={radioKey.name || ''}
          handleClose={toggleShowKeyDevices}
        />
      )}
    </>
  );
};

export default TableDataRow;
