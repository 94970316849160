import Modal, { ModalProps } from 'react-bootstrap/Modal';
import { Translate } from 'react-localize-redux';
import React from 'react';
import ModalHeader from './ModalHeader';
import { v4 as uuidv4 } from 'uuid';
import PrimaryButton from '../shared/PrimaryButton';

interface InformationModalProps extends ModalProps {
  titleTranslationId: string,
  mainTextTranslationId: string,
  closeModal: () => void,
}

function InfoModal(props: InformationModalProps) {
  const modalUid = uuidv4();
  const { titleTranslationId, mainTextTranslationId, closeModal } = props;

  return (
    <Modal
      { ...props }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader
        isDevice={ false }
        titleTranslationId={ titleTranslationId }
        handleClose={ closeModal }
        targetId={ modalUid }
      />
      <Modal.Body>
        <div className="text-line-break">
          <Translate id={ mainTextTranslationId }/>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <PrimaryButton textTranslationId="OK" onClick={ closeModal }/>
      </Modal.Footer>
    </Modal>
  );
}

export default InfoModal;
