import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { IRadioKeyDeviceCounts, RadioKey } from '@wiot/shared-domain/models/radio-key/radio-key';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import FilterBar from '../../components/Filter/FilterBar';
import Table from '../../components/Table/Table';
import { IPagination, RadioKeyExtended } from '../../state/types';
import { getFetchOptions, hasPermission } from '../../utils/common';
import { fetchKeysFromDB, fetchRadioKeyDeviceCounts, removeKeyFromDB } from '../../api/apiHelpers';
import TableDataKeys from './Table/TableDataKeys';
import { AppState } from '../../state/reducers/rootReducer';
import {
  saveKeys as saveKeysAction,
  toggleSelectAllKeys as toggleSelectAllKeysAction,
  toggleSelectKey as toggleSelectKeyAction,
} from '../../state/actions/saveKeysAction';
import { savePagination as savePaginationAction } from '../../state/actions/savePaginationAction';
import { isLoading } from '../../state/actions/isLoadingAction';
import { isTableLoading } from '../../state/table/isTableLoadingAction';
import ErrorBoundary from '../../components/ErrorBoundary';
import Desktop from '../../components/Responsive/Desktop';
import Mobile from '../../components/Responsive/Mobile';
import MobileTable from './Mobile/MobileTable';
import RenderOnCondition from '../../components/RenderOnCondition';
import KeyActionModal from './KeyActionModal';
import { PageKeys } from '../../state/reducers/filterSortReducer';
import MainGrid from '../../components/shared/MainGrid';
import useTranslation from '../../hooks/useTranslation';

const KEY_MANAGEMENT_PAGE_KEY: PageKeys = 'key-management';

const Keys = () => {
  const dispatch = useDispatch();
  const translate = useTranslation();

  const currentPage = useSelector((state: AppState) => state.pagination[KEY_MANAGEMENT_PAGE_KEY].currentPage);
  const currentEntriesPerPage = useSelector((state: AppState) => state.currentEntriesPerPage);
  const filter = useSelector((state: AppState) => state.filters.filter[KEY_MANAGEMENT_PAGE_KEY]);
  const keys = useSelector((state: AppState) => state.keys);
  const permission = useSelector((state: AppState) => state.currentUser.permission);

  const [showAddModal, setShowAddModal] = useState(false);

  const fetchKeys = useCallback(
    async (column?: IColumnObject) => {
      if (currentEntriesPerPage === 0) {
        return;
      }
      const fetchOptions = getFetchOptions(currentEntriesPerPage, column, filter, currentPage);

      dispatch(isTableLoading(true))
      const res = await fetchKeysFromDB(fetchOptions);
      if (res && res.radioKeys) {
        const { radioKeys, totalDocs, totalPages } = res;
        dispatch(saveKeysAction(radioKeys));

        const paginationData: IPagination = { totalDocs, totalPages };
        dispatch(savePaginationAction(paginationData, KEY_MANAGEMENT_PAGE_KEY));
        dispatch(isTableLoading(false))
        dispatch(isLoading(false))
      }
    },
    [
      currentEntriesPerPage,
      currentPage,
      filter,
      dispatch,
    ],
  );

  const [deviceCounts, setDeviceCounts] = useState<IRadioKeyDeviceCounts>({});

  useEffect(() => {
    const fetchKeyDevices = async () => {
      try {
        const radioKeyIds = keys.map((key: RadioKey) => key.id);
        const devicesResult = await fetchRadioKeyDeviceCounts(radioKeyIds);
        setDeviceCounts(devicesResult);
      } catch (e) {
        setDeviceCounts({});
        console.error(e);
      }
    };

    keys && keys.length && fetchKeyDevices();
  }, [keys]);

  useEffect(() => {
    dispatch(isLoading(true));

    fetchKeys();
  }, [fetchKeys, dispatch]);

  const removeUnit = async (id: string, refresh = true) => {
    dispatch(isTableLoading(true))
    const response = await removeKeyFromDB(id);

    if (refresh && response) {
      await fetchKeys();
      toastr.success(translate('success').toString(), translate('delete-key-success').toString());
    }
  };

  const getSelectedKeys = (): RadioKeyExtended[] =>
    keys ? keys.filter((key: RadioKeyExtended) => key.checked) : [];

  const handleBulkRemove = async () => {
    const selectedKeys: RadioKeyExtended[] = getSelectedKeys();
    const removePromises = selectedKeys.map(async (key) => removeUnit(key.id, false));
    await Promise.all(removePromises);
    await fetchKeys();
    toastr.success(translate('success').toString(), translate('delete-keys-success').toString());
  };

  const selectAll = (event: React.FormEvent<HTMLInputElement>) => {
    const isChecked = event.currentTarget.checked;
    dispatch(toggleSelectAllKeysAction(isChecked));
  };

  const toggleShowAddModal = () => {
    setShowAddModal((prevState) => !prevState);
  };

  const getTableComponent = () => (
    <TableDataKeys
      deviceCounts={deviceCounts}
      refreshData={fetchKeys}
      removeUnit={removeUnit}
      markOneKeyAsSelected={(radioKeyId)=> dispatch(toggleSelectKeyAction(radioKeyId))}
      markAllKeysAsSelected={selectAll}
      isSelectAllChecked={keys.length === getSelectedKeys().length}
    />
  );

  return (
    <MainGrid dataTestId="page-keys">
      <ErrorBoundary>
        <FilterBar page={KEY_MANAGEMENT_PAGE_KEY}  handleAddBtnClick={toggleShowAddModal} />
      </ErrorBoundary>
      <Desktop>
        <ErrorBoundary>
          <Table
            page={KEY_MANAGEMENT_PAGE_KEY}
            changeView={false}
            addModal={hasPermission(permission, 'keys.add')}
            addText="key"
            tableComponent={getTableComponent()}
            selectedRows={getSelectedKeys()}
            handleBulkRemove={handleBulkRemove}
            elementType="keys"
            showPagination
            refreshTableData={fetchKeys}
            editorModalCreator={(toggleShowEditorModal) => (
              <KeyActionModal
                closeAddAndUpdateModal={toggleShowEditorModal}
                title="add-key"
                showDeleteButton={false}
                addUnit
                refreshData={fetchKeys}
              />
            )}
          />
        </ErrorBoundary>
      </Desktop>
      <Mobile>
        <>
          <MobileTable
            deviceCounts={deviceCounts}
            removeUnit={removeUnit}
            refreshData={fetchKeys}
          />
          <RenderOnCondition condition={showAddModal}>
            <KeyActionModal
              closeAddAndUpdateModal={toggleShowAddModal}
              title="add-key"
              showDeleteButton={false}
              addUnit
              refreshData={fetchKeys}
            />
          </RenderOnCondition>
        </>
      </Mobile>
    </MainGrid>
  );
};

export default (Keys);
