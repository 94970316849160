import { Dispatch } from 'redux';
import { AppState } from '../reducers/rootReducer';
import { deviceTreeUpdated } from './fetch-device-tree/deviceTreeUpdatedAction';
import { getTreeNodeById, toggleTreeNodeAndReturnUpdatedTree } from '../../utils/tree-utils';

export const toggleTreeNodeChildren = (parentTreeNodeId: string) => async (
  dispatch: Dispatch<any>,
  getState: () => AppState,
): Promise<void> => {
  const state = getState();
  const treeNode = getTreeNodeById(parentTreeNodeId, [state.deviceTree.nodes.rootNode]);
  if (!treeNode) {
    throw new Error(`The node with the id(${ treeNode }) was not found!`);
  }

  const updatedTree = toggleTreeNodeAndReturnUpdatedTree(state.deviceTree.nodes.rootNode, treeNode.id);
  dispatch(deviceTreeUpdated(updatedTree));
};
