import React, { useCallback, useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { AggregatedPermission, IRole, IRoleChecked } from '@wiot/shared-domain/models/role/role';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import FilterBar from '../../components/Filter/FilterBar';
import Table from '../../components/Table/Table';
import { DispatchActionTypes, IPagination } from '../../state/types';
import { getFetchOptions, hasPermission } from '../../utils/common';
import { fetchRolesFromDB, removeRoleFromDB } from '../../api/apiHelpers';
import TableDataRoles from './Table/TableDataRoles';
import { AppState } from '../../state/reducers/rootReducer';
import {
  saveRoles as saveRolesAction,
  toggleSelectAllRoles as toggleSelectAllRolesAction,
  toggleSelectRole as toggleSelectRoleAction,
} from '../../state/actions/saveRolesAction';
import { savePagination as savePaginationAction } from '../../state/actions/savePaginationAction';
import { isLoading } from '../../state/actions/isLoadingAction';
import { isTableLoading } from '../../state/table/isTableLoadingAction';
import ErrorBoundary from '../../components/ErrorBoundary';
import { IRolesFilter } from '../../state/reducers/filterSortReducer';
import RoleActionModal from './RoleActionModal';
import MainGrid from '../../components/shared/MainGrid';
import showDeleteToastrOnConflictingRoleUsage from "../../components/shared/showDeleteToastrOnConflictingRoleUsage";

export interface RolesProps extends LocalizeContextProps {
  currentEntriesPerPage: number;
  currentPage: number;
  roles: IRole[];
  saveRoles: (roles: IRole[]) => void;
  savePagination: (paginationData: IPagination) => void;
  toggleSelectRole: (id: string) => void;
  toggleSelectAllRoles: (isChecked: boolean) => void;
  setIsLoading: (loading: boolean) => void;
  setIsTableLoading: (loading: boolean) => void;
  filter: IRolesFilter;
  permission?: AggregatedPermission;
}

const Roles = (props: RolesProps) => {
  const {
    setIsLoading,
    setIsTableLoading,
    permission,
    toggleSelectRole,
    saveRoles,
    roles,
    savePagination,
    filter,
    toggleSelectAllRoles,
    currentEntriesPerPage,
    currentPage,
    translate,
  } = props;
  const fetchRoles = useCallback(
    async (column?: IColumnObject) => {
      const fetchOptions = getFetchOptions(currentEntriesPerPage, column, filter, currentPage);

      setIsTableLoading(true);
      try {
        const res = await fetchRolesFromDB(fetchOptions);

        if (res && res.roles) {
          const { roles: rolesRes, totalDocs, totalPages } = res;
          await saveRoles(rolesRes);

          const paginationData: IPagination = { totalDocs, totalPages };
          await savePagination(paginationData);
          setIsLoading(false);
          setIsTableLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
        setIsTableLoading(false);
      }
    },
    [
      currentEntriesPerPage,
      currentPage,
      filter,
      savePagination,
      saveRoles,
      setIsLoading,
      setIsTableLoading,
    ],
  );

  useEffect(() => {
    setIsLoading(true);
    fetchRoles();
  }, [fetchRoles, setIsLoading]);

  const removeUnit = async (id: string) => {
    setIsTableLoading(true);
    const response = await removeRoleFromDB(id);

    if (response) {
      const { error, leftUserRoles } = response;
      if (error) {
        showDeleteToastrOnConflictingRoleUsage(translate, leftUserRoles);
        setIsTableLoading(false);
        return;
      }
      toastr.success(translate('success').toString(), translate('delete-role-success').toString());
      await fetchRoles();
    }
  };

  const getSelectedRoles = (): IRoleChecked[] =>
    roles ? roles.filter((role: IRoleChecked) => role.checked) : [];

  const handleBulkRemove = async () => {
    const selectedRoles: IRoleChecked[] = getSelectedRoles();
    const removePromises = selectedRoles.map(async (role) => role.id && removeUnit(role.id));
    await Promise.all(removePromises);
    await fetchRoles();
  };

  const selectAll = (event: React.FormEvent<HTMLInputElement>) => {
    const isChecked = event.currentTarget.checked;
    toggleSelectAllRoles(isChecked);
  };

  const getTableComponent = () => (
    <TableDataRoles
      refreshData={ fetchRoles }
      removeUnit={ removeUnit }
      markOneRoleAsSelected={ toggleSelectRole }
      markAllRolesAsSelected={ selectAll }
      isSelectAllChecked={ roles.length === getSelectedRoles().length }
    />
  );

  return (
    <MainGrid dataTestId="page-roles">
      <ErrorBoundary>
        <FilterBar page="roles"/>
      </ErrorBoundary>
      <ErrorBoundary>
        <Table
          page="roles"
          changeView={ false }
          addModal={ hasPermission(permission, 'roles.add') }
          addText="role"
          tableComponent={ getTableComponent() }
          selectedRows={ getSelectedRoles() }
          handleBulkRemove={ handleBulkRemove }
          elementType="roles"
          showPagination
          refreshTableData={ fetchRoles }
          editorModalCreator={ (toggleShowEditorModal) => (
            <RoleActionModal
              closeAddAndUpdateModal={ toggleShowEditorModal }
              title="add-role"
              showDeleteButton={ false }
              addUnit
              refreshRoles={ fetchRoles }
            />
          ) }
        />
      </ErrorBoundary>
    </MainGrid>
  );
};

const mapStateToProps = (state: AppState) => ({
  roles: state.roles,
  currentEntriesPerPage: state.currentEntriesPerPage,
  currentPage: state.pagination.roles.currentPage,
  filter: state.filters.filter.roles,
  permission: state.currentUser.permission,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  toggleSelectRole: (role: string) => dispatch(toggleSelectRoleAction(role)),
  toggleSelectAllRoles: (isChecked: boolean) => dispatch(toggleSelectAllRolesAction(isChecked)),
  saveRoles: (roles: IRole[]) => dispatch(saveRolesAction(roles)),
  savePagination: (paginationData: IPagination) =>
    dispatch(savePaginationAction(paginationData, 'roles')),
  setIsLoading: (loading: boolean) => dispatch(isLoading(loading)),
  setIsTableLoading: (loading: boolean) => dispatch(isTableLoading(loading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(Roles));
