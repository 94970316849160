import { SiteSettingsActionTypes, SiteSettingsState } from '../types';
import { SET_SITE_SETTINGS } from '../constants/actionTypes';

const initialState: SiteSettingsState = {};

export const siteSettingsReducer = (
  state: SiteSettingsState = initialState,
  action: SiteSettingsActionTypes,
): SiteSettingsState => {
  switch (action.type) {
    case SET_SITE_SETTINGS:
      return action.payload;

    default:
      return state;
  }
};
