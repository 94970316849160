import { Dispatch } from 'redux';
import { DispatchActionTypes, LogoutAction } from '../types';
import { LOGOUT } from '../constants/actionTypes';
import { logoutUser } from '../../api/apiHelpers';
import { CURRENT_USER_DETAILS_KEY } from '../reducers/currentUserReducer';

export function clearLoginData(): void {
  localStorage.removeItem(CURRENT_USER_DETAILS_KEY);
}

export const logout = () => async (
  dispatch: Dispatch<DispatchActionTypes>,
): Promise<void> => {
  const response = await logoutUser();
  if (response) {
    clearLoginData();
    dispatch({ type: LOGOUT } as LogoutAction);
  } else {
    throw new Error('Logout failed!');
  }
};

