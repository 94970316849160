import { Dispatch } from 'redux';
import { IRole } from '@wiot/shared-domain/models/role/role';
import { FetchOptions, FetchPermittedRolesAction, PermittedRolesFetchedAction } from '../../types';
import { IRolesFilter } from '../../reducers/filterSortReducer';
import {
  FETCH_PERMITTED_ROLES,
  PERMITTED_ROLES_FETCHED,
  RESET_USER_ACTION_MODAL_STATE,
  TOGGLE_SHOW_DELETE_MODAL,
} from '../../constants/actionTypes';
import { fetchPermittedRolesFromDB } from '../../../api/apiHelpers';

export const permittedRolesFetched = (permittedRoles: IRole[]): PermittedRolesFetchedAction => ({
  type: PERMITTED_ROLES_FETCHED,
  permittedRoles,
});

export const fetchPermittedRoles = (
  fetchOptions?: FetchOptions<IRolesFilter>,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  dispatch({ type: FETCH_PERMITTED_ROLES, fetchOptions } as FetchPermittedRolesAction);
  const { permittedRoles } = await fetchPermittedRolesFromDB(fetchOptions);
  dispatch(permittedRolesFetched(permittedRoles));
};

export const toggleShowDeleteModal = () => ({
  type: TOGGLE_SHOW_DELETE_MODAL,
});

export const resetUserActionModalState = () => ({
  type: RESET_USER_ACTION_MODAL_STATE,
});
