import { isAvailableInCurrentMode } from '../../../utils/feature-availability';
import { FilterField } from '../filter-field';
import { PageKeys } from '../../../state/reducers/filterSortReducer';
import { DisplayTypes } from '@wiot/shared-domain/models/device/device';
import { restrictedFilterBarCombinations } from './restricted-filter-bar-combination';
import { allFilterBarFields } from './all-filter-bar-fields';

export const getFilterBarFields = (
  isKeyManagerModeEnabled: boolean,
  pageKey: PageKeys,
  currentContentDisplay: DisplayTypes,
) => {
  return allFilterBarFields[pageKey].filter((field) =>
    isAvailableInCurrentMode(field.availability, isKeyManagerModeEnabled)
    && isAllowedContentDisplayAndPageCombination(field, currentContentDisplay, pageKey)
  );
};

const isAllowedContentDisplayAndPageCombination = (
  filterField: FilterField,
  currentContentDisplay: DisplayTypes,
  pageKey: PageKeys,
): boolean => {
  const isRestricted = restrictedFilterBarCombinations.some(
    restriction =>
      restriction.fieldName === filterField.name
      && restriction.selectedContentDisplay === currentContentDisplay
      && restriction.pageKey === pageKey);
  return !isRestricted;
};
