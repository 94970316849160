import React from 'react';
import { PageKeys } from '../../state/reducers/filterSortReducer';
import GroupSelect from './GroupSelect';
import { useDispatch, useSelector } from 'react-redux';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { setFilter } from '../../state/filter/set-filter-action-creator';
import { generatePropertyViewPath } from '../../pages/PropertyView/PropertyViewPathUtils';
import { useHistory } from 'react-router';
import { AppState } from '../../state/reducers/rootReducer';
import { selectPageFilters } from '../../state/filter/filterSelectors';
import { PROPERTY_VIEW_PAGE_KEY } from '@wiot/shared-domain/domain/property/property-view-page-key';
import { InputContextType } from '../Input/input-context-type';

interface GroupSelectFilterProps {
  pageKey: PageKeys;
}

const PropertySelect = ({ pageKey }: GroupSelectFilterProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const filter = useSelector((state: AppState) => selectPageFilters(state, PROPERTY_VIEW_PAGE_KEY));
  const filterDeviceGroupId = filter.deviceGroup?.id;

  const isItemSelectable = (deviceGroup: DeviceGroup): boolean => {
    return !!deviceGroup.propertySettings?.isProperty;
  };
  const onSelectionChanged = (selectedProperty: DeviceGroup | undefined) => {
    const path = generatePropertyViewPath({ deviceGroupId: selectedProperty?.id, });
    history.push(path);

    dispatch(
      setFilter({
        page: pageKey,
        values: {
          deviceGroup: selectedProperty,
          propertyMBusDeviceTypeIdForConsumption: 0,
          propertyMBusDeviceTypeIdForPieChart: 0,
        },
      }),
    );
  };

  return (
    <>
      <GroupSelect
        dropDownWrapperClassNames="group-select"
        preSelectedDeviceGroupId={ filterDeviceGroupId || '' }
        onSelectionChangedSingleSelect={ onSelectionChanged }
        hideLabel
        targetId="groupSelectFilterTargetId"
        defaultLabel="select-property"
        isClearable={ false }
        isItemSelectableCustomCondition={ isItemSelectable }
      />
    </>
  );
};

export default PropertySelect;
