import * as React from 'react';
import { Translate } from 'react-localize-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { DeviceReading } from '@wiot/shared-domain/models/device-reading/device-reading';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import { Device } from '@wiot/shared-domain/models/device/device';
import { getDeviceTypeIconFullPath, getTranslationValueInCurrentLanguage, } from '../../utils/common';
import { LinkToDeviceMessagesPage } from '../../navigation/LinkToDeviceMessagesPage';

export interface DashboardReadingProps {
  data: DeviceReading;
}

export interface DashboardReadingState {
  deviceGroupName: string;
}

const ReadingIcon = ({ data }: { data: DeviceReading }) => {
  const deviceType = (data?.device as Device)?.deviceType as IDeviceType | undefined;
  const iconSrc = getDeviceTypeIconFullPath(deviceType);

  return (
    <div className="dashboard__reading__icon">
      <img src={iconSrc} alt="device type" />
    </div>
  );
};

export default class DashboardReading extends React.Component<
  DashboardReadingProps,
  DashboardReadingState
> {

  getStartDate = () => {
    const { receivedAt } = this.props.data;
    return moment(receivedAt).subtract('7', 'days').startOf('day').toDate();
  };

  render() {
    const { data } = this.props;
    const device = data.device as Device;

    const deviceGroupName = device && device.deviceGroup ? device.deviceGroup.name : '';

    return (
      <LinkToDeviceMessagesPage
        idOfDevice={ device.id }
        startDate={ this.getStartDate() }
      >
        <div className="dashboard__reading">
          <ReadingIcon data={data} />
          <div className="dashboard__reading__desc">
            <ul className="dashboard__reading__desc__list">
              <li style={{ fontWeight: 600 }}>
                {data.receivedAt && moment(data.receivedAt).fromNow()}
              </li>
              <li style={{ margin: '2px 0' }}>{deviceGroupName}</li>
              <li>
                <Translate>
                  {({ translate }) => {
                    if (device && device.deviceType && device.deviceType.name) {
                      return getTranslationValueInCurrentLanguage(device.deviceType.name);
                    }
                    return translate('2');
                  }}
                </Translate>
                {` (${device && device.deviceId})`}
              </li>
            </ul>
          </div>
        </div>
      </LinkToDeviceMessagesPage>
    );
  }
}
