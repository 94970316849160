import { faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Place } from 'react-tooltip';
import { Translate } from 'react-localize-redux';
import React from 'react';
import { Tooltip } from '../shared/Tooltip';

interface LoginPasswordIndicatorProps {
  tooltipPlace?: Place;
  className?: string;
}

const LoginPasswordIndicator = (props: LoginPasswordIndicatorProps) => {
  return (
    <div className={props.className}>
      <FontAwesomeIcon
        data-tip="login-form__icons__indicator"
        data-for="icons_indicator"
        icon={faKey}
      />
      <Tooltip
        id="icons_indicator"
        place={props.tooltipPlace ?? 'left'}
        type="light"
        className="login-form__icons__tooltip"
      >
        <div className="login-form__icons__tooltip__heading">
          <Translate id="password-requirements" />:
        </div>
        <div className="login-form__icons__tooltip__text">
          <Translate id="login-password-indicator-requirements" />
        </div>
      </Tooltip>
    </div>
  );
};

export default LoginPasswordIndicator;
