import React, { createRef } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../state/reducers/rootReducer';

import TableDataRowTooltip from '../TableDataRowTooltip';
import { NotAssignedLabel } from '../NotAssignedLabel';
import RenderOnCondition from '../RenderOnCondition';
import { DeviceGroupPath } from '../../utils/device-group-path';

interface DeviceGroupPathCellProps {
  id: string;
  deviceGroupPath: DeviceGroupPath;
}

function DeviceGroupPathCell({ id, deviceGroupPath }: DeviceGroupPathCellProps) {
  const { fullPath, truncatedPath } = deviceGroupPath;

  const isKeyManagerModeEnabled = useSelector((state: AppState) => !!state.siteSettings.isKeyManagerModeEnabled);
  const groupPath = isKeyManagerModeEnabled ? fullPath : truncatedPath;

  const tooltipId = `row-tooltip-${id}`;
  const textFieldRef = createRef<HTMLDivElement>();
  return (
    <td
      key={ `device-group-${id}` }
    >
      <div
        ref={ textFieldRef }
        data-tip={ tooltipId }
        data-for={ tooltipId }
        className="fill-space text-overflow-ellipsis"
      >
        { groupPath || <NotAssignedLabel/> }
        <RenderOnCondition condition={ groupPath }>
          <TableDataRowTooltip id={ tooltipId } className='z-index-1000'>
            { fullPath }
          </TableDataRowTooltip>
        </RenderOnCondition>
      </div>
    </td>
  );
}

export default DeviceGroupPathCell;
