import * as React from 'react';
import { useCallback, useLayoutEffect } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import Draggable from '../shared/Draggable';
import { AppState } from '../../state/reducers/rootReducer';
import RenderOnCondition from '../RenderOnCondition';
import { setModalZindex as setModalZindexAction } from '../../state/actions/setModalZindexAction';
import ClosingIcon from '../shared/ClosingIcon';
import { FeedbackAttachment } from '../Feedback/feedback';
import { OpenFeedbackModalButton } from '../Feedback/OpenFeedbackModalButton';

/**
 * There is a Hack to make a modal draggable:
 * In the calling component you have to create a modalUid and keep track of it in the components state (use uuidv4).
 * Then you have to set the `id` of the surrounding div to `${title}-${modalUid}` in the calling component.
 * Pass the `modalUid` as the `targetId` and the `title` as `titleTranslationId` to the modal header.
 */
export interface ModalHeaderProps extends LocalizeContextProps {
  isDevice?: boolean;
  titleTranslationId: string;
  dataTestid?: string;
  isDeviceToChange?: boolean;
  titlePostfix?: string;
  handleClose: (event: React.MouseEvent | KeyboardEvent) => void;
  targetId: string;
  changeOnlyZindex?: boolean;
  enableFeedbackSubmission?: boolean;
  getFeedbackAttachment?: () => FeedbackAttachment | null;
}

const ModalHeader = (props: ModalHeaderProps) => {
  const {
    handleClose,
    titlePostfix,
    dataTestid,
    isDeviceToChange,
    isDevice,
    titleTranslationId,
    targetId,
    translate,
    changeOnlyZindex,
    enableFeedbackSubmission = true,
    getFeedbackAttachment = () => null,
  } = props;
  const draggableId = `${titleTranslationId}-${targetId}`;

  const dispatch = useDispatch();
  const reduxZindex = useSelector((state: AppState) => state.modalZindex);
  const isMobileDisplay = useSelector((state: AppState) => state.isMobileDisplay);

  const isFeedbackEnabled = useSelector((state: AppState) => state.siteSettings.support?.isFeedbackEnabled);

  let modal: HTMLElement = document.querySelector(`#${draggableId}`) as HTMLElement;

  const handleClick = useCallback(() => {
    if (modal && reduxZindex.modalId !== draggableId) {
      const newIndex = reduxZindex.zIndex + 1;
      dispatch(setModalZindexAction({ modalId: modal.id, zIndex: newIndex })),
      modal.style.zIndex = `${newIndex}`;
      modal.focus();
    }
  }, [draggableId, modal, reduxZindex.modalId, reduxZindex.zIndex]);

  // close modal on escape press
  function handleEscape(this: HTMLElement, event: KeyboardEvent) {
    if (reduxZindex.modalId === draggableId) {
      const eventKey = event.key;
      if (eventKey === 'Escape') {
        handleClose(event);
      }
    }
  }

  React.useEffect(() => {
    modal = document.querySelector(`#${draggableId}`) as HTMLElement;
    handleClick();
  }, []);

  React.useEffect(() => {
    modal?.addEventListener('mousedown', handleClick);
    modal?.addEventListener('keydown', handleEscape);
    // prevent scrolling of body in the background on mobile
    isMobileDisplay && disableBodyScroll(modal);

    return () => {
      modal?.removeEventListener('mousedown', handleClick);
      modal?.removeEventListener('keydown', handleEscape);
      isMobileDisplay && clearAllBodyScrollLocks();
    };
  }, [handleClick, isMobileDisplay, modal, reduxZindex]);

  return changeOnlyZindex ? (
    <RenderOnCondition condition={!isMobileDisplay}>
      <Draggable
        notDraggable
        targetId={draggableId}
        dragTargetId={`draggable-header-${draggableId}`}
      />
    </RenderOnCondition>
  ) : (
    <div
      id={`draggable-header-${draggableId}`}
      className={classNames(isDevice ? 'form__header--device' : 'form__header')}
    >
      <RenderOnCondition condition={!isMobileDisplay}>
        <Draggable targetId={draggableId} dragTargetId={`draggable-header-${draggableId}`} />
      </RenderOnCondition>
      <div className="form__row">
        <h4 className="form__heading" data-testid={dataTestid}>
          {isDeviceToChange ? (
            <Translate id="change-this-device" />
          ) : (
            <Translate id={titleTranslationId} />
          )}{' '}
          {titlePostfix}
        </h4>
        <RenderOnCondition condition={enableFeedbackSubmission && isFeedbackEnabled}>
          <OpenFeedbackModalButton getFeedbackAttachment={ getFeedbackAttachment } />
        </RenderOnCondition>

        <div
          onClick={handleClose}
          className="details__button--close"
          title={translate('press-esc-close').toString()}
        >
          <ClosingIcon />
        </div>
      </div>
    </div>
  );
};
export default withLocalize(ModalHeader);
