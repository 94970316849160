import React, { useState } from 'react';
import Autosuggest, { RenderSuggestionsContainerParams } from 'react-autosuggest';
import { Translate, withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import Portal from '../shared/Portal';
import { AppState } from '../../state/reducers/rootReducer';
import { ModalRectangle } from '../../types/util';
import RenderOnCondition from '../RenderOnCondition';

interface ICustomAutoSuggest extends LocalizeContextProps {
  name: string;
  options: any[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function;
  value: any;
  translateOptions: boolean;
  selectOptionAutomatic?: boolean;
  portalId?: string;
  hideLabel?: boolean;
}

const CustomAutoSuggest = ({
  name,
  options,
  onChange,
  value,
  translate,
  translateOptions,
  selectOptionAutomatic,
  portalId,
  hideLabel,
}: ICustomAutoSuggest) => {
  const [suggestions, setSuggestions] = useState<any[]>(options);

  const currentGlobalModalZIndex = useSelector((state: AppState) => state.modalZindex);

  const getPosition = (elementId: string): ModalRectangle => {
    const element = document.getElementById(elementId);
    if (element) {
      const elementRectangle = element.getBoundingClientRect();
      return {
        top: elementRectangle.top,
        left: elementRectangle.left,
        width: elementRectangle.width,
        zIndex: currentGlobalModalZIndex?.zIndex + 1,
      };
    }
    return {};
  };

  const handleChange = (event: any, { newValue }: any) => {
    onChange(name, newValue);
  };

  const getSuggestions = (typedValue: string) => {
    const inputValue = typedValue.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0) {
      return options;
    }
    return options.filter((lang) => {
      const translatedValue = translateOptions
        ? translate(lang.displayName || lang.name).toString()
        : lang.displayName || lang.name;
      return translatedValue.toLowerCase().slice(0, inputLength) === inputValue;
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value: typedValue }: any) => {
    const fetchedSuggestions = getSuggestions(typedValue);
    setSuggestions(fetchedSuggestions);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions(options);
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion: any) => {
    if (translateOptions) {
      return translate(suggestion.displayName || suggestion.name);
    }
    return suggestion.displayName || suggestion.name;
  };

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion: any) => (
    <div className="modal-select__option">
      { translateOptions ? (
        <Translate id={suggestion.displayName || suggestion.name} />
      ) : (
        <span>{suggestion.displayName || suggestion.name}</span>
      )}
    </div>
  );

  const renderSuggestionsContainer = ({
    containerProps,
    children,
  }: RenderSuggestionsContainerParams): JSX.Element => {
    const elementPosition = getPosition(`suggest-${name}`);
    const portalPosition = getPosition(portalId || 'root');
    const dropdownPosition = {
      ...elementPosition,
      top: (elementPosition.top || 0) - (portalPosition.top || 0) + 68, // height of the input container = 68
      left: (elementPosition.left || 0) - (portalPosition.left || 0),
    };
    return (
      <Portal targetId={portalId}>
        <div {...containerProps} style={dropdownPosition}>
          {children}
        </div>
      </Portal>
    );
  };

  const inputProps = {
    className: 'modal-input',
    value,
    onChange: handleChange,
    placeholder: selectOptionAutomatic
      ? translate(`choose-automatic`).toString()
      : translate('choose-one').toString(),
  };

  return (
    <>
      <RenderOnCondition condition={!hideLabel}>
        <label className="form__label" htmlFor={name}>
          <Translate id={name} />
        </label>
      </RenderOnCondition>
      <div id={`suggest-${name}`}>
        <Autosuggest
          getSuggestionValue={getSuggestionValue}
          inputProps={inputProps}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          renderSuggestion={renderSuggestion}
          shouldRenderSuggestions={() => true}
          suggestions={suggestions}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          // Custom dropdown is rendered with portaling if portalId prop is passed
          renderSuggestionsContainer={portalId ? renderSuggestionsContainer : undefined}
          id={`suggest-${name}`}
        />
      </div>
    </>
  );
};

export default withLocalize(CustomAutoSuggest);
