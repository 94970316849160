import React, { useState } from 'react';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { Translate } from 'react-localize-redux';
import moment from 'moment';

import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import ConfirmModal, { ConfirmationVariant } from '../../../components/Modal/ConfirmModal';
import TableActionButton from './TableActionButton';
import RenderOnCondition from '../../../components/RenderOnCondition';
import RestoreBackup from './RestoreBackup';
import { FileInfo } from '@wiot/shared-domain/models/file/file';
import { downloadBackupFile } from '../../../api/backupService';
import { StringUtils } from '@wiot/shared-domain/models/utils/primitives/string-operations';
import { Tooltip } from '../../../components/shared/Tooltip';

export interface TableDataRowProps {
  refreshData: (column?: IColumnObject) => void;
  file: FileInfo;
  removeUnit: (filename: string) => void;
  readOnly: boolean;
}

const TableDataRow = (props: TableDataRowProps) => {
  const { file, removeUnit, readOnly = true, refreshData } = props;
  const { filename, size, birthtime } = file;
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const databaseType = filename.slice(StringUtils.nthLastIndexOf(filename, '.', 3) + 1, StringUtils.nthLastIndexOf(filename, '.', 2));

  return (
    <>
      <tr key={ filename } id={ filename } className="device-manager__table__table__trow">
        <td>{ filename }</td>
        <td>{ moment(birthtime).fromNow() }</td>
        <td>{ Math.round(size / 1024) } KB</td>
        <td data-tip="database-type" data-for={ `${ filename }-database` }>
          <Translate id={ `${ databaseType }-backup` }/>
          <Tooltip id={ `${ filename }-database` }>
            <Translate id={ `${ databaseType }-backup-description` }/>
          </Tooltip>
        </td>
        <td className="no-overflow">
          <TableActionButton
            icon={ faFileDownload }
            label="download"
            onClick={ () => downloadBackupFile(filename) }
            filename={ filename }
          />
          <RenderOnCondition condition={ !readOnly }>
            <RestoreBackup
              filename={ filename }
              refreshData={ refreshData }
            />
            <TableActionButton
              icon={ faTrashAlt }
              label="delete-permanently"
              onClick={ () => setShowDeleteModal(!showDeleteModal) }
              filename={ filename }
            />
          </RenderOnCondition>
        </td>
      </tr>
      <ConfirmModal
        show={ showDeleteModal }
        modalCloseRequested={ () => setShowDeleteModal(!showDeleteModal) }
        actionConfirmed={ () => removeUnit(filename) }
        modalHeaderTitle={ filename }
        translationIdOfElementType="backup-file"
        confirmationVariant={ ConfirmationVariant.DELETE }
      />
    </>
  );
};

export default TableDataRow;
