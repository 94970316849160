import { Dispatch } from 'redux';

import { authenticatedInstance, handleApiError } from '../../../api/apiHelpers';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { toastr } from 'react-redux-toastr';
import useTranslation from '../../../hooks/useTranslation';
import {
  createDeviceGroupAction,
  deviceGroupCreatedAction, deviceGroupCreationFailedAction,
} from './deviceGroupCreationTypes';
import { normaliseDeviceGroup } from '../deviceGroupApiCallPreparer';
import { DEVICE_GROUPS_API } from '../deviceGroupApi';

export const createDeviceGroupActionCreator = (
  deviceGroup: DeviceGroup,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const translate = useTranslation();

  dispatch(createDeviceGroupAction(deviceGroup));

  try {
    // temporary remove these keys until they are fully implemented
    const normalisedDeviceGroup = normaliseDeviceGroup(deviceGroup) as DeviceGroup;
    const { data: createdDeviceGroup } = await authenticatedInstance.post<DeviceGroup>(DEVICE_GROUPS_API, normalisedDeviceGroup);
    dispatch(deviceGroupCreatedAction(createdDeviceGroup));
    toastr.success(
      translate('success').toString(),
      translate('add-dev-group-success').toString(),
    );
  } catch (error) {
    handleApiError(error);
    dispatch(deviceGroupCreationFailedAction(deviceGroup));
  }
};
