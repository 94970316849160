// alphabetically
export enum WiotDeviceStatus {
  APPLICATION_BUSY = 'APPLICATION_BUSY',
  APPLICATION_ERROR = 'APPLICATION_ERROR',
  BACKFLUSH = 'BACKFLUSH',
  BELOW_ZERO = 'BELOW_ZERO',
  BATTERY_DURATION_LESS_3_MONTH = 'BATTERY_DURATION_LESS_3_MONTH',
  CRITICAL_BATTERY = 'CRITICAL_BATTERY',
  CS_ERROR = 'CS_ERROR',
  END_OF_LIFE = 'END_OF_LIFE',
  ERROR = 'ERROR',
  FLOW_ERROR = 'FLOW_ERROR',
  HANDLING_ERROR = 'HANDLING_ERROR',
  HF_ERROR = 'HF_ERROR',
  INVALID_COMMUNICATION_INDICATOR = 'INVALID_COMMUNICATION_INDICATOR',
  INVALID_COMMUNICATION_INDICATOR_IR = 'INVALID_COMMUNICATION_INDICATOR_IR',
  INVALID_CONTROL_INFORMATION = 'INVALID_CONTROL_INFORMATION',
  INVALID_ENCRYPTION = 'INVALID_ENCRYPTION',
  INVALID_MESSAGE_CONTENT = 'INVALID_MESSAGE_CONTENT',
  LEAKAGE = 'LEAKAGE',
  LOW_BATTERY = 'LOW_BATTERY',
  MANIPULATION = 'MANIPULATION',
  MANUFACTURER_SPECIFIC_ERROR = 'MANUFACTURER_SPECIFIC_ERROR',
  MEASURE_ERROR = 'MEASURE_ERROR',
  NEGATIVE_POWER = 'NEGATIVE_POWER',
  NEGATIVE_TEMPERATURE_DIFF = 'NEGATIVE_TEMPERATURE_DIFF',
  NO_KEY = 'NO_KEY',
  NO_TELEGRAM_PARSED = 'NO_TELEGRAM_PARSED',
  OFFLINE = 'OFFLINE',
  OK = 'OK',
  PARSING_FAILED = 'PARSING_FAILED',
  PERMANENT_ERROR = 'PERMANENT_ERROR',
  PIPE_BREAKAGE = 'PIPE_BREAKAGE',
  POWER_LOW = 'POWER_LOW',
  POWER_FAIL = 'POWER_FAIL',
  RESERVED = 'RESERVED',
  RESET_ERROR = 'RESET_ERROR',
  RTC_INVALID = 'RTC_INVALID',
  STANDSTILL = 'STANDSTILL',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
  SOFTWARE_ERROR = 'SOFTWARE_ERROR',
  HARDWARE_ERROR = 'HARDWARE_ERROR',
  TEMPERATURE_ERROR = 'TEMPERATURE_ERROR',
  TEMPORARY_ERROR = 'TEMPORARY_ERROR',
  VOLTAGE_DROP = 'VOLTAGE_DROP',
  WARNING = 'WARNING',
  WRONG_FLOW_DIRECTION = 'WRONG_FLOW_DIRECTION',
  DOUBLET_FILTER_OVERFLOW = 'DOUBLET_FILTER_OVERFLOW',
  TIME_SYNC_FAILED = 'TIME_SYNC_FAILED',
}

export enum KeyManagerDeviceStatus {
  KEY_NOT_DOWNLOADED_YET = 'KEY_NOT_DOWNLOADED_YET',
  KEY_ALREADY_DOWNLOADED = 'KEY_ALREADY_DOWNLOADED',
}

export type DeviceStatus = WiotDeviceStatus | KeyManagerDeviceStatus;

export function getDeviceStatuses(isKeyManagerModeEnabled: boolean = false): string[] {
  if (isKeyManagerModeEnabled) {
    return Object.values(KeyManagerDeviceStatus);
  }
  return Object.values(WiotDeviceStatus);
}
