import * as React from 'react';
import { useEffect, useState } from 'react';
import { fetchGroupTypesFromDB } from '../../api/apiHelpers';
import { useSelector } from 'react-redux';
import { AppState } from '../../state/reducers/rootReducer';
import { filterDeviceGroupTypes } from '../../utils/device-group-type-filter';
import { SelectFieldProps } from '../FilterBar/select/components/select-props';
import { SelectableOption } from '../FilterBar/select/selectable-option';
import useTranslation from '../../hooks/useTranslation';
import { SelectWrapperForFilters } from '../FilterBar/select/SelectWrapperForFilters';
import { getSelectedLanguage } from '../../utils/common';

const GroupTypeSelect = (props: SelectFieldProps) => {
  const { pageKey, filterField } = props;
  const [ groupTypeOptions, setGroupTypeOptions ] = useState<SelectableOption[]>([]);
  const isKeyManagerModeEnabled = useSelector((state: AppState) => !!state.siteSettings.isKeyManagerModeEnabled);

  const language = getSelectedLanguage()

  const translate = useTranslation();

  useEffect(() => {
    initializeGroupTypes();
  }, [ pageKey, filterField, language ]);

  const initializeGroupTypes = async () => {
    const deviceGroupTypes = await fetchGroupTypesFromDB();
    if (!deviceGroupTypes) {
      return;
    }

    const filteredDeviceGroupTypes = filterDeviceGroupTypes(deviceGroupTypes, isKeyManagerModeEnabled);

    const deviceGroupTypesOptions = filteredDeviceGroupTypes.map<SelectableOption>((dgt) =>
      ({ label: `${ translate(dgt.name) }`, value: `${ dgt.name }` }));
    setGroupTypeOptions(deviceGroupTypesOptions);
  };

  return (
    <SelectWrapperForFilters<SelectableOption>
      translationId={ filterField.name }
      filterFieldKey={ filterField.stateField }
      pageKey={ pageKey }
      defaultOptions={ groupTypeOptions }
      isMulti={ true }
    />
  );
};

export default GroupTypeSelect;
