import { Property } from '@wiot/shared-domain/domain/property/property';
import {
  FETCH_PROPERTY,
  FETCH_PROPERTY_CONSUMPTION_HISTORY,
  PROPERTY_CONSUMPTION_HISTORY_FETCHED,
  PROPERTY_FETCHED,
  PropertyActionTypes,
  USER_LEFT_PROPERTY_VIEW
} from './propertyViewActions';
import { MEASURE_UNITS } from '@wiot/shared-domain/models/device-reading/device-reading';
import { ChangeFilterAction } from '../types';
import { CHANGE_FILTER } from '../constants/actionTypes';
import { PropertyConsumptionType } from '@wiot/shared-domain/domain/property/property-consumption-type';
import { PropertySettingsModalState, propertySettingsModalState } from './property-settings-modal/propertySettingsModalState';
import {
  CLOSE_PROPERTY_SETTINGS_MODAL,
  OPEN_PROPERTY_SETTINGS_MODAL,
  PROPERTY_SETTINGS_UPDATED,
  PropertySettingsModalActionTypes,
  UPDATE_PROPERTY_SETTINGS
} from './property-settings-modal/propertySettingsModalActions';

type PropertiesState = {
  propertiesById: {
    [idOfProperty: string]: Property;
  };
  isPropertyLoading: boolean;
  isPropertyConsumptionHistoryLoading: boolean;
  consumptionUnit: string;
  settingsModal: PropertySettingsModalState;
};

const initialState: PropertiesState = {
  propertiesById: {},
  isPropertyLoading: false,
  isPropertyConsumptionHistoryLoading: false,
  consumptionUnit: MEASURE_UNITS.KILO_WATT_HOURS,
  settingsModal: propertySettingsModalState,
};

function getUnitByConsumptionType(consumptionType: PropertyConsumptionType) {
  switch (consumptionType) {
    case PropertyConsumptionType.WATER:
      return MEASURE_UNITS.METRIC_CUBE;
    case PropertyConsumptionType.ENERGY:
    default:
      return MEASURE_UNITS.KILO_WATT_HOURS;
  }
}

export const propertyReducer = (
  state: PropertiesState = initialState,
  action: PropertyActionTypes | ChangeFilterAction | PropertySettingsModalActionTypes,
): PropertiesState => {
  const { type } = action;

  switch (type) {
    case FETCH_PROPERTY:
      return {
        ...state,
        isPropertyLoading: true,
        consumptionUnit: getUnitByConsumptionType(action.consumptionType),
      };
    case PROPERTY_FETCHED:
      const { property: fetchedProperty } = action;

      return {
        ...state,
        isPropertyLoading: false,
        propertiesById: {
          ...state.propertiesById,
          [fetchedProperty.id]: { ...state.propertiesById[fetchedProperty.id], ...fetchedProperty },
        },
      };

    case FETCH_PROPERTY_CONSUMPTION_HISTORY:
      return {
        ...state,
        isPropertyConsumptionHistoryLoading: true,
      };

    case PROPERTY_CONSUMPTION_HISTORY_FETCHED:
      const { mBusDeviceTypeId, billingFrequency, billingPeriodStartDate, property } = action;
      const consumptionHistory = property?.consumptionHistory?.[mBusDeviceTypeId]?.[billingFrequency]?.[billingPeriodStartDate];
      if (!consumptionHistory) {
        return state;
      }

      return {
        ...state,
        isPropertyConsumptionHistoryLoading: false,
          propertiesById: {
            ...state.propertiesById,
          [property.id]: {
            ...state.propertiesById[property.id],
            consumptionHistory: {
              ...state.propertiesById[property.id]?.consumptionHistory,
              [mBusDeviceTypeId]: {
                ...state.propertiesById[property.id]?.consumptionHistory?.[mBusDeviceTypeId],
                [billingFrequency]: {
                  ...state.propertiesById[property.id]?.consumptionHistory?.[mBusDeviceTypeId]?.[billingFrequency],
                  [billingPeriodStartDate]: consumptionHistory,
                },
              },
            },
          },
        },
      };

    case CHANGE_FILTER:
      if (!action.payload.values) {
        return state;
      }

      const { consumptionType: filterConsumptionType } = action.payload.values;

      if (!filterConsumptionType) {
        return state;
      }

      return {
        ...state,
        consumptionUnit: getUnitByConsumptionType(filterConsumptionType as PropertyConsumptionType),
      };

    case USER_LEFT_PROPERTY_VIEW:
      return {
        ...initialState,
        isPropertyLoading: true,
        isPropertyConsumptionHistoryLoading: true,
      };

    case OPEN_PROPERTY_SETTINGS_MODAL:
      return {
        ...state,
        settingsModal: {
          ...state.settingsModal,
          isVisible: true,
          selectedDeviceGroup: action.deviceGroup,
        },
      };
    case CLOSE_PROPERTY_SETTINGS_MODAL:
      return {
        ...state,
        settingsModal: {
          ...state.settingsModal,
          isVisible: false,
        },
      };
    case UPDATE_PROPERTY_SETTINGS:
      return {
        ...state,
        settingsModal: {
          ...state.settingsModal,
          isLoading: true,
        },
      };
    case PROPERTY_SETTINGS_UPDATED:
      if (!action.deviceGroup.id || !action.deviceGroup.propertySettings) {
        return state;
      }

      return {
        ...state,
        propertiesById: {
          ...state.propertiesById,
          [action.deviceGroup.id]: {
            ...state.propertiesById[action.deviceGroup.id],
            propertySettings: action.deviceGroup.propertySettings,
          },
        },
        settingsModal: {
          ...state.settingsModal,
          isLoading: false,
        },
      };

    default:
      return state;
  }
};
