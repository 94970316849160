import React from 'react';

const CustomCheckbox = (props: React.HTMLProps<HTMLInputElement>) => (
  <label className="custom-checkbox">
    <input type="checkbox" data-testid="device-checkbox" {...props} />
    <span className="checkmark border-color-main" />
  </label>
);

export default CustomCheckbox;
