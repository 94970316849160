import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { DropEvent } from 'react-dropzone';
import { LocalizeContextProps, Translate, withLocalize, } from 'react-localize-redux';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';
import { LicenseWithoutId } from '@wiot/shared-domain/models/license/License';
import CustomFileInput from '../../../components/CustomFileInput';
import { fetchLicensingOverview } from '../../../state/licensing/fetchLicensingOverview';
import { addLicense } from '../../../state/licensing/addLicense';
import { AppState } from '../../../state/reducers/rootReducer';

/**
 * Props of the {@link AddLicenses} component.
 */
export interface AddLicensesProps extends LocalizeContextProps {
  /**
   * Determines whether the component should be displayed in read-only mode,
   * meaning the user will not be able to add licenses.
   */
  readOnly: boolean;
}

/**
 * A component that allows the user to add one or more licenses (see {@link License}) to the platform.
 */
const AddLicenses = ({ readOnly, translate }: AddLicensesProps) => {
  const dispatch = useDispatch();

  const licensingState = useSelector((state: AppState) => state.licensing);

  const getLicenseDropZoneOptions = () => ({
    multiple: true,
    onDrop: async (
      acceptedFiles: File[],
      rejectedFiles: File[],
      event: DropEvent,
    ) => {
      if (acceptedFiles) {
        await Promise.all(
          acceptedFiles.map(async (file) => {
            const fileName = file.name;
            const fileContent = await file.text();
            const license: LicenseWithoutId = JSON.parse(fileContent);

            dispatch(addLicense(license, fileName));
          }),
        );

        dispatch(fetchLicensingOverview());
      }
    },
    accept: 'application/json',
  });

  const onDropRejected = (rejectedFiles: File[]) => {
    if (rejectedFiles.length) {
      if (rejectedFiles[0].type !== 'application/json') {
        toastr.error(
          translate('ERROR').toString(),
          translate('file-type-invalid').toString(),
        );
      } else {
        toastr.error(
          translate('ERROR').toString(),
          translate('file-size-invalid').toString(),
        );
      }
    }
  };

  return (
    <Row>
      <Col
        sm={2}
        lg={3}
      >
        <label className="settings-form__label">
          <Translate id="add-license" />
        </label>
      </Col>
      <Col
        sm={6}
        lg={9}
      >
        {readOnly ? (
          <Translate id="insufficient-privileges" />
        ) : (
          <div className='mb-4'>
            <label className="settings-form__label">
              <Translate id="add-license-hint" />
            </label>
            <CustomFileInput
              options={{
                ...getLicenseDropZoneOptions(),
                onDropRejected,
              }}
              label=""
              isDisabled={licensingState.isAddingLicense}
            />
          </div>
        )}
      </Col>
    </Row>
  );
};

export default withLocalize(AddLicenses);
