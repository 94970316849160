export type IsoStringDateRange = {
  /**
   * The ISO date string of the date range start.
   */
  start: string | null | undefined;

  /**
   * The ISO date string of the date range end.
   */
  end: string | null | undefined;
};

export class IsoStringDateRangeFactory {
  static create(start: string | null | undefined, end: string | null | undefined): IsoStringDateRange {
    return { start, end };
  }

  static createStartTodayMidnight(end: string | null | undefined = null): IsoStringDateRange {
    const todayMidnightUnixTimestamp = new Date().setHours(0, 0, 0, 0);
    const todayMidnightIsoString = new Date(todayMidnightUnixTimestamp).toISOString();
    return IsoStringDateRangeFactory.create(todayMidnightIsoString, end);
  }

  static createUnlimited(): IsoStringDateRange {
    return IsoStringDateRangeFactory.create(null, null);
  }
}
