import * as Yup from 'yup';
import { StringSchema } from 'yup';
import { DataValidator } from '@wiot/shared-domain/models/validators/data-validators';
import useTranslation from '../../hooks/useTranslation';

export const GetPasswordValidation = () => {
  const translate = useTranslation();
  return Yup.string()
    .required(translate('required-field').toString())
    .test(
      'matches-password-complexity',
      translate('new-password-requirements').toString(),
      (testedValue) => DataValidator.validatePasswordComplexity(testedValue),
    );
};

export const GetHexValueValidationSchema = (length = 32): StringSchema => {
  const translate = useTranslation();
  const regex = new RegExp(`^[0-9A-Fa-f]{${length}}$`);

  return Yup.string()
    .trim()
    .min(length, translate('value-has-to-have-exactly-chars', { length }).toString())
    .max(length, translate('value-has-to-have-exactly-chars', { length }).toString())
    .matches(regex, {
      message: translate('wrong-format').toString(),
      excludeEmptyString: true,
    })
    .required(translate('required-field').toString());
};
