import { DataIntegrationJobExtended } from '@wiot/shared-domain/models/data-integration/data-integration';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { toastr } from 'react-redux-toastr';
import React from 'react';
import { Col } from 'react-bootstrap';
import { runDataIntegrationJobFromDB } from '../../../../api/apiHelpers';

interface IExecuteJobButtonProps extends LocalizeContextProps {
  api: DataIntegrationJobExtended;
  refreshData: () => void;
}

const ExecuteJobButton = (props: IExecuteJobButtonProps) => {
  const { api, translate, refreshData } = props;

  const runDataIntegrationJob = async (event: React.MouseEvent) => {
    const { checked, ...cleanedApi } = api;
    event.stopPropagation();

    toastr.info(
      translate('fetch-data').toString(),
      translate('fetching-data-external-api-config', { name: api.name }).toString(),
    );

    const response = await runDataIntegrationJobFromDB(cleanedApi);
    if (!response) return;

    const { error } = response;
    if (error) {
      toastr.error(
        translate('fetch-data').toString(),
        translate('fetched-data-external-api-config-error', { name: api.name }).toString(),
      );
    } else {
      toastr.success(
        translate('fetch-data').toString(),
        translate('fetched-data-external-api-config-success', { name: api.name }).toString(),
      );
    }
    refreshData();
  };

  return (
    <Col lg={6} className="text-right">
      <button
        type="button"
        style={{ marginTop: '10px' }}
        className="form__button--blue background-color-main text-color-white border-color-main"
        onClick={(event) => {
          runDataIntegrationJob(event);
        }}
      >
        <Translate id={`${api.class}-fetch-data`} />
      </button>
    </Col>
  );
};

export default withLocalize(ExecuteJobButton);
