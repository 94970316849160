import HasPermission from '../../../components/HasPermission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Translate } from 'react-localize-redux';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../state/reducers/rootReducer';
import {
  OPEN_DKD_MODAL,
  OpenDKDModalAction
} from '../../../state/device-key-download/downloadHashOfDKDModalReducer';
import { ACLDeviceKeyDownload } from '@wiot/shared-domain/models/role/access-level';

interface GoToDeviceKeyDownloadMenuItemProps {
  downloadHash?: string;
  closeMenu: (event: React.MouseEvent) => void;
}

function GoToDeviceKeyDownloadMenuItem(props: GoToDeviceKeyDownloadMenuItemProps) {
  const { downloadHash, closeMenu } = props;
  const permission = useSelector((state: AppState) => state.currentUser.permission);
  const dispatch = useDispatch();

  const requiredPermissionKey = ACLDeviceKeyDownload.VIEW;
  return (
    <HasPermission permissionObj={ permission } permissionKey={ requiredPermissionKey }>
      <button
        type="button"
        className="td-menu__item"
        onClick={ (event) => {
          dispatch({ type: OPEN_DKD_MODAL, downloadHash: downloadHash } as OpenDKDModalAction
          );
          closeMenu(event);
        } }>
        <div className="td-menu__item__icon">
          <FontAwesomeIcon icon={ faDownload }/>
        </div>
        <Translate id="go-to-download"/>
      </button>
    </HasPermission>
  );
}

export default GoToDeviceKeyDownloadMenuItem;
