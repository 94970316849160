import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import * as moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { ISite, Language } from '@wiot/shared-domain/models/settings/settings';
import englishFlag from '../../assets/language/united-kingdom-flag-icon-32.png';
import germanFlag from '../../assets/language/germany-flag-icon-32.png';
import { getSelectedLanguage } from '../../utils/common';
import { AppState } from '../../state/reducers/rootReducer';
import { LANGUAGE_KEY } from './language-key';

/**
 * Available language flags by their two-letter ISO 639-1 language code.
 */
const FLAGS = {
  en: englishFlag,
  de: germanFlag,
};

interface LanguageToggleProps extends LocalizeContextProps {
  siteSettings?: ISite;
}

const LanguageToggle = ({ setActiveLanguage, siteSettings, translate }: LanguageToggleProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState('de');

  useEffect(() => {
    const updateLanguage = async () => {
      const language = await getSelectedLanguage(siteSettings);
      setSelectedLanguage(language);
    };
    updateLanguage();
  }, [siteSettings]);

  const handleLanguageSelect = (eventKey: any) => {
    if (eventKey !== selectedLanguage) {
      setActiveLanguage(eventKey);
      moment.locale(eventKey);
      setSelectedLanguage(eventKey);
      localStorage.setItem(LANGUAGE_KEY, eventKey);
      toastr.success(translate('settings').toString(), translate('language-changed').toString());
    }
  };

  // @ts-ignore complains about string based indexing but it works
  const selectedFlag = FLAGS[selectedLanguage] || germanFlag;

  return (
    <Dropdown
      className="language-dropdown justify-content-end"
      onSelect={handleLanguageSelect}
      data-testid="user-settings-language-toggle"
    >
      <Dropdown.Toggle
        id="language-toggle"
        className="language-toggle text-color-main background-color-white"
      >
        <img src={selectedFlag} alt="selected language" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey={Language.GERMAN}>
          <img src={germanFlag} alt="German flag" className="pr-1" />
          <Translate id="german" />
        </Dropdown.Item>
        <Dropdown.Item eventKey={Language.ENGLISH}>
          <img src={englishFlag} alt="English flag" className="pr-1" />
          <Translate id="english" />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = (state: AppState) => ({
  siteSettings: state.siteSettings,
});

export default withLocalize(connect(mapStateToProps, null)(LanguageToggle));
