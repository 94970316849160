import { IMeasurementAttributeId, } from '@wiot/shared-domain/models/device-type-profile/device-type-profile';
import { DeviceReadingSourceType } from '@wiot/shared-domain/models/device-reading/device-reading';
import { InvalidArgumentError } from '@wiot/shared-domain/models/errors/invalid-argument-error';
import { EnumUtils } from '@wiot/shared-domain/models/utils/primitives/enum';

export type Measurement = {
  date: string;
  // TODO(TL): `value` should be mandatory, later we should make it to type `number`
  value?: string;
  unit?: string;
  statusByte?: number;
  attributeId?: IMeasurementAttributeId;
  source?: MeasurementSource;
  protocol?: MeasurementProtocol;
  tags: MeasurementTag[];
}

export enum MeasurementSource {
  DEVICE = 'DEVICE',
  MANUAL = 'MANUAL',
  SYSTEM = 'SYSTEM',
}

export function getMeasurementSourceFromDeviceReadingSourceType(sourceType?: DeviceReadingSourceType): MeasurementSource | undefined {
  switch (sourceType) {
    case DeviceReadingSourceType.SYSTEM:
      return MeasurementSource.SYSTEM;
    case DeviceReadingSourceType.MANUAL:
      return MeasurementSource.MANUAL;
    case DeviceReadingSourceType.OMS:
    case DeviceReadingSourceType.LORA:
      return MeasurementSource.DEVICE;
    default:
      return;
  }
}

export enum MeasurementProtocol {
  OMS = 'OMS',
  LORA = 'LORA',
}

export function getMeasurementProtocolFromDeviceReadingSourceType(sourceType?: DeviceReadingSourceType): MeasurementProtocol | undefined {
  switch (sourceType) {
    case DeviceReadingSourceType.OMS:
      return MeasurementProtocol.OMS;
    case DeviceReadingSourceType.LORA:
      return MeasurementProtocol.LORA;
    default:
    case DeviceReadingSourceType.MANUAL:
    case DeviceReadingSourceType.SYSTEM:
      return;
  }
}

export enum MeasurementTag {
  AT_DEVICE_DUE_DATE = 'AT_DEVICE_DUE_DATE',
  FROM_CURRENT_VALUE = 'FROM_CURRENT_VALUE',
  FROM_COMPACT_PROFILE = 'FROM_COMPACT_PROFILE',
}

export enum MeasurementFrequency {
  MINUTE = 'm',
  HOUR = 'h',
  DAY = 'd',
  WEEK = 'w',
  MONTH = 'M',
  QUARTER = 'Q',
  YEAR = 'y',
}

export const parseMeasurementFrequency = (frequency: any): MeasurementFrequency | undefined => {
  if (!frequency) {
    return undefined;
  }

  if (!EnumUtils.existsKeyInEnum(frequency, MeasurementFrequency)) {
    throw new InvalidArgumentError();
  }

  return frequency as MeasurementFrequency;
};
