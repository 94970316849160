import React from 'react';
import { Translate } from 'react-localize-redux';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IUser } from '@wiot/shared-domain/models/user/user';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import DataRow from './DataRow';
import MobileFooter from '../../../components/Mobile/MobileFooter';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import { DispatchActionTypes } from '../../../state/types';
import { AppState } from '../../../state/reducers/rootReducer';
import { saveCurrentPage } from '../../../state/actions/savePaginationAction';
import { PageKeys } from '../../../state/reducers/filterSortReducer';

interface MobileTableProps {
  users: IUser[];
  refreshData: (column?: IColumnObject) => void;
  removeUnit: (id: string) => Promise<void>;
  currentPageTitle: PageKeys;
  totalDocs: number;
  currentEntriesPerPage: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  isLoading: boolean;
}

const MobileTable = (props: MobileTableProps) => {
  const firstEntry = (props.currentPage - 1) * props.currentEntriesPerPage + 1;
  const lastEntry = Math.min(firstEntry + props.currentEntriesPerPage - 1, props.totalDocs);

  return props.isLoading ? (
    <LoadingIcon />
  ) : (
    <div className="device-manager__table__container">
      <div className="page-header-wrapper">
        <h3 className="page-title" data-testid="header-page-title">
          <Translate id={props.currentPageTitle} />
        </h3>
        <h4 className="page-subtitle" data-testid="table-subheading">
          {props.totalDocs ? (
            <Translate
              id="device-messages-table-header-subheading"
              data={{
                firstEntry,
                lastEntry,
                allDevices: props.totalDocs,
              }}
            />
          ) : null}
        </h4>
      </div>
      {props.users?.length ? (
        props.users.map((user: IUser) => (
          <DataRow
            key={user.id}
            user={user}
            removeUnit={props.removeUnit}
            refreshData={props.refreshData}
          />
        ))
      ) : (
        <div className="text-center m-2">
          <Translate id="no-data" />
        </div>
      )}
      <MobileFooter
        currentPage={props.currentPage}
        setCurrentPage={props.setCurrentPage}
        totalDocs={props.totalDocs}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  users: state.users,
  currentPageTitle: state.currentPageTitle,
  totalDocs: state.pagination['user-management'].totalDocs,
  currentEntriesPerPage: state.currentEntriesPerPage,
  currentPage: state.pagination['user-management'].currentPage,
  isLoading: state.isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  setCurrentPage: (pageNumber: number) => dispatch(saveCurrentPage(pageNumber, 'user-management')),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileTable);
