import { AggregatedPermission } from '@wiot/shared-domain/models/role/role';
import { hasPermission } from '../utils/common';
import { NavigationItem, NavigationLink, Route } from './navigation-models';
import {
  FeatureAvailability,
  hasAvailability,
  isAvailableForCurrentScreenResolution,
  isAvailableInCurrentMode
} from '../utils/feature-availability';
import { allNavigationItems } from './all-navigation-items';
import { allRoutes } from './all-routes';

export const getAvailableNavigationLinks = (
  isMobileDisplay: boolean,
  userPermission?: AggregatedPermission,
  isKeyManagerModeEnabled = false,
): NavigationLink[] => {

  const availableNavigationLinks = filterRouteItems(
    allNavigationItems,
    isMobileDisplay,
    isKeyManagerModeEnabled,
    userPermission) as NavigationLink[];

  if (isKeyManagerModeEnabled) {
    availableNavigationLinks.map(link => {
      if (link.svgIconForKeyManager) {
        link.svgIcon = link.svgIconForKeyManager;
      }
    });
  }

  return availableNavigationLinks.reverse();
};

export const getAvailableRoutes = (
  isKeyManagerModeEnabled: boolean,
  isMobileDisplay: boolean,
  userPermission?: AggregatedPermission,
): Route[] => {
  const availableRoutes = filterRouteItems(
    allRoutes,
    isMobileDisplay,
    isKeyManagerModeEnabled,
    userPermission) as Route[];

  return availableRoutes;
};

const filterRouteItems = (
  navigationItems: NavigationItem[],
  isMobileDisplay: boolean,
  isKeyManagerModeEnabled = false,
  userPermission?: AggregatedPermission,
): NavigationItem[] => {
  const availableInCurrentMode = navigationItems.filter(route =>
    isAvailableInCurrentMode(route.availability, isKeyManagerModeEnabled));

  const availableForCurrentScreenResolution = availableInCurrentMode.filter(route =>
    isAvailableForCurrentScreenResolution(route.availability,isMobileDisplay)
  );

  const itemsWithGrantedPermission = availableForCurrentScreenResolution.filter(route =>
    hasCurrentUserPermissionToAccessRoute(route, userPermission)).reverse();

  return itemsWithGrantedPermission;
};


const pathToPermissionNameMapping: Record<string, string> = {
  'device-groups': 'deviceGroups',
  'key-management': 'key-management',
  roles: 'roles',
  'user-management': 'users',
  'data-integration': 'dataIntegration',
  'manage-device-types': 'manageDeviceTypes',
  'manage-device-group-types': 'manageDeviceGroupTypes',
  settings: 'settings',
  'property-view': 'propertyView',
};

const hasCurrentUserPermissionToAccessRoute = (navigationItem: NavigationItem, userPermission?: AggregatedPermission) => {

  if (hasAvailability(navigationItem.availability, FeatureAvailability.WithoutPermissionCheck)) {
    return true;
  }

  if (!userPermission) {
    return false;
  }

  if (userPermission.superAdmin) {
    return true;
  }

  const cleanedPath = navigationItem?.path.replace('/', '');
  if (cleanedPath in pathToPermissionNameMapping) {
    return hasPermission(userPermission, `${ pathToPermissionNameMapping[cleanedPath] }.view`);
  }
  return false;
};
