import { IUser } from '@wiot/shared-domain/models/user/user';
import { IRole } from '@wiot/shared-domain/models/role/role';
import {
  EDIT_USER_FETCHED_WITH_ROLES,
  FETCH_EDIT_USER_WITH_ROLES,
  FETCH_PERMITTED_ROLES,
  PERMITTED_ROLES_FETCHED,
  RESET_USER_ACTION_MODAL_STATE,
  SET_LOADING_INDICATOR,
  TOGGLE_SHOW_DELETE_MODAL,
} from '../constants/actionTypes';
import { UserActionModalActionTypes } from '../types';
import { getRandomModalOffset } from '../../utils/dialog';
import { UserRole } from '@wiot/shared-domain/models/user/user-role';

export type UserActionModalState = {
  editUser?: IUser | null;
  userRolesOfEditUser?: UserRole[] | null;
  selectableRoles: IRole[];
  showDeleteModal: boolean;
  randomModalOffset?: { marginTop: number; marginRight: number };
  isLoadingIndicatorEnabled: boolean;
};

const initialState: UserActionModalState = {
  selectableRoles: [],
  showDeleteModal: false,
  randomModalOffset: { marginTop: 0, marginRight: 0 },
  isLoadingIndicatorEnabled: true,
};

export const userActionModalReducer = (
  state: UserActionModalState = { ...initialState },
  action: UserActionModalActionTypes,
): UserActionModalState => {
  switch (action.type) {
    case RESET_USER_ACTION_MODAL_STATE:
      return {
        ...state,
        editUser: null,
        userRolesOfEditUser: null,
        randomModalOffset: getRandomModalOffset(),
        isLoadingIndicatorEnabled: true,
      };
    case FETCH_PERMITTED_ROLES:
    case FETCH_EDIT_USER_WITH_ROLES:
      return {
        ...state,
        isLoadingIndicatorEnabled: true,
      };
    case PERMITTED_ROLES_FETCHED:
      return {
        ...state,
        selectableRoles: action.permittedRoles,
        isLoadingIndicatorEnabled: false,
      };
    case TOGGLE_SHOW_DELETE_MODAL:
      return {
        ...state,
        showDeleteModal: !state.showDeleteModal,
      };
    case EDIT_USER_FETCHED_WITH_ROLES:
      return {
        ...state,
        editUser: action.user,
        userRolesOfEditUser: action.user.roles,
        isLoadingIndicatorEnabled: false,
      };
    case SET_LOADING_INDICATOR:
      return {
        ...state,
        isLoadingIndicatorEnabled: action.isLoadingIndicatorEnabled,
      };

    default:
      return state;
  }
};
