import { Device } from '@wiot/shared-domain/models/device/device';
import { IDeviceRole } from '@wiot/shared-domain/models/role/role';
import React from 'react';
import { Col, Row, Tab } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { ModalTabEventKeys } from '../ModalTabEventKeys';

interface IGatewaysTabProps {
  permission?: IDeviceRole;
  getDeviceInfo: (deviceId: string, setActiveTabKey?: React.Dispatch<React.SetStateAction<ModalTabEventKeys>>) => void;
  setActiveTabKey: React.Dispatch<React.SetStateAction<ModalTabEventKeys>>;
  gatewayDevices: Device[];
}

const GatewaysTab = (props: IGatewaysTabProps) => {
  const { getDeviceInfo, gatewayDevices, setActiveTabKey } = props;

  const availableGateways = gatewayDevices?.filter((g) => g?.id);

  return (
    <Tab.Content>
      <Tab.Pane eventKey={ ModalTabEventKeys.GATEWAYS }>
        {availableGateways?.length ? (
          availableGateways.map((gateway: Device, i: number) => (
              <Row
                key={`gateway-${gateway.id}`}
                className="device-manager__table__table__link"
                onClick={() => getDeviceInfo(gateway.id, setActiveTabKey)}
              >
                <Col lg={1}>{i + 1}.</Col>
                <Col lg={5}>
                  <p className="details__text">{gateway?.deviceId ? gateway.deviceId : '-'}</p>
                </Col>
                <Col lg={4}>
                  <p className="details__text">{gateway?.name}</p>
                </Col>
                <Col lg={2}>
                  <p className="details__text">{gateway?.manufacturer?.flagId}</p>
                </Col>
              </Row>
            ))
        ) : (
          <p className="details__text">
            <Translate id="no-gateways-found" />.
          </p>
        )}
      </Tab.Pane>
    </Tab.Content>
  );
};

export default GatewaysTab;
