import React from 'react';
import { Row, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

interface OcrWizardResultProps {
  searchedValue: { label: string; value: string };
  onFoundValueChange: (event: React.FormEvent, index: number, searchedValue: string) => void;
  foundValue: string;
  index: number;
}

const OcrWizardResult = (props: OcrWizardResultProps) => {
  const { index, foundValue, onFoundValueChange, searchedValue } = props;

  return (
    <Row className="position-relative">
      <InputGroup>
        <FormControl
          id={`inputgroup-${index}`}
          className={`ocr-wizard__input-field ${
            foundValue === searchedValue.value ? 'ocr-wizard__input-field__selected' : ''
          }`}
          value={foundValue}
          key={index}
          onChange={(event: React.FormEvent<any>) =>
            onFoundValueChange(event, index, searchedValue.label)
          }
          onClick={(event: React.FormEvent<any>) =>
            onFoundValueChange(event, index, searchedValue.label)
          }
        />
      </InputGroup>
      <label
        className={`ocr-wizard__input-field__label ${
          foundValue === searchedValue.value ? 'ocr-wizard__input-field__label__checked' : ''
        }`}
        htmlFor={`inputgroup-${index}`}
      >
        <FontAwesomeIcon icon={faCheck} />
      </label>
    </Row>
  );
};

export default OcrWizardResult;
