import React from 'react';

export interface MainGridProps {
  dataTestId?: string;
  children: JSX.Element | JSX.Element[];
}

export default function MainGrid( props: MainGridProps) {
  return (
    <main className="main-grid" data-testid={props.dataTestId} id="mainGrid">
        <div>{props.children}</div>
    </main>
  );
}