import { DeviceExportFormikValues } from './device-export';
import React, { useEffect } from 'react';
import RenderOnCondition from '../../../components/RenderOnCondition';
import { IExportFormat } from '@wiot/shared-domain/models/domain/services/export/export.models';
import { SetFieldValue } from '../../../state/types';
import { MeasurementFrequency } from '@wiot/shared-domain/models/device-reading/measurement';
import { DeviceExportSelect } from './DeviceExportSelect';
import { ExportCheckbox } from './ExportCheckbox';
import MultiSelect from '../../../components/shared/MultiSelect';
import { MeasurementAttributeId } from '@wiot/shared-domain/models/device-type-profile/device-type-profile';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';

export interface OptionalInputFieldsProps {
  exportFormat: IExportFormat,
  setFieldValue: SetFieldValue,
  values: DeviceExportFormikValues,
  modalUid: string,
}

export function OptionalInputFields({ modalUid, values, setFieldValue, exportFormat }: OptionalInputFieldsProps) {
  const { inputFieldsToDisplay } = exportFormat;
  const transformAttributeIdsToMultiSelectOption = (attribute: MeasurementAttributeId) => ({ label: attribute, value: attribute });
  const attributeIdOptions = Object.values(MeasurementAttributeId).map(transformAttributeIdsToMultiSelectOption);

  useEffect(() => {
    setFieldValue('frequency', 'M');
  }, []);

  return (

  <>
    <RenderOnCondition condition={ inputFieldsToDisplay?.attributeIds }>
      <Row className='my-1'>
        <Col md={ 7 }>
          <label className="form__label">
            <Translate id='measurement-attribute-id' />
          </label>
        </Col>
        <Col>
          <MultiSelect
            handleOptionsChange={(options) => setFieldValue('attributeIds', options.map(o => o.value))}
            selectedOptions={ values.attributeIds?.map(transformAttributeIdsToMultiSelectOption) || [] }
            options={ attributeIdOptions }
            titleTranslationId='measurement-attribute-id'
          />
        </Col>
      </Row>
    </RenderOnCondition>

    <RenderOnCondition condition={ inputFieldsToDisplay?.frequency }>
      <DeviceExportSelect
        value={ values.frequency || MeasurementFrequency.MONTH }
        name="frequency"
        options={ Object.values(MeasurementFrequency).map(f => ({ label: `measurement-frequency-${ f }`, value: f })) }
        onChange={ setFieldValue }
        modalUid={ modalUid }
        translateId="measurement-frequency"
      />
    </RenderOnCondition>

    <RenderOnCondition condition={ inputFieldsToDisplay?.includeLastPrimaryMeasurement }>
      <ExportCheckbox
        checked={ !!values.includeLastPrimaryMeasurement }
        onChange={ () => setFieldValue('includeLastPrimaryMeasurement', !values.includeLastPrimaryMeasurement) }
        translateId="includeLastPrimaryMeasurement"
      />
    </RenderOnCondition>

    <RenderOnCondition condition={ inputFieldsToDisplay?.includeDueDateMeasurement }>
      <ExportCheckbox
        checked={ !!values.includeDueDateMeasurement }
        onChange={ () => setFieldValue('includeDueDateMeasurement', !values.includeDueDateMeasurement) }
        translateId="includeDueDateMeasurement"
      />
    </RenderOnCondition>

    <RenderOnCondition condition={ inputFieldsToDisplay?.includeLastGateways }>
      <ExportCheckbox
        checked={ !!values.includeLastGateways }
        onChange={ () => setFieldValue('includeLastGateways', !values.includeLastGateways) }
        translateId="includeLastGateways"
      />
    </RenderOnCondition>
  </>

  );
}
