import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Translate } from 'react-localize-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import { MANDATORY_COLUMNS } from '../../../config/table-columns/device-manager';
import ModalHeader from '../../../components/Modal/ModalHeader';
import CustomCheckbox from '../../../components/shared/CustomCheckbox';
import { getRandomModalOffset } from '../../../utils/dialog';

interface IColumnSelectionModalProps {
  columns: IColumnObject[];
  handleClose: () => void;
  saveColumns: (columns: IColumnObject[]) => void;
}

const ColumnSelectionModal = (props: IColumnSelectionModalProps) => {
  const { columns, handleClose, saveColumns } = props;
  const [modifiedColumns, setModifiedColumns] = useState(columns);
  const [randomModalOffset] = React.useState(getRandomModalOffset());
  const [modalUid] = React.useState(uuidv4());

  const handleColumnSelection = (name: string, visible: boolean) => {
    setModifiedColumns((prevState) =>
      prevState.map((column) => {
        if (column.name === name) {
          return {
            ...column,
            visible,
          };
        }
        return column;
      }),
    );
  };

  const handleSave = () => {
    saveColumns(modifiedColumns);
    handleClose();
  };

  return (
    <div
      tabIndex={0} // make it focusable
      style={randomModalOffset}
      className="overflow-y-auto overflow-x-hidden device-modal"
      onClick={(event: React.MouseEvent) => event.stopPropagation()}
      id={`select-columns-${modalUid}`}
    >
      <ModalHeader
        isDevice
        titleTranslationId="select-columns"
        targetId={modalUid}
        handleClose={handleClose}
      />
      <Container className="device-modal__body p-3">
        <div className="form__section">
          <Row className="form__title">
            <Col>
              <Translate id="select-columns-to-be-displayed" />
            </Col>
          </Row>
        </div>
        <div className="form__section">
          <Row>
            {modifiedColumns.map((column) => {
              const isMandatory = MANDATORY_COLUMNS.includes(column.name);
              return (
                <Col lg={6} key={column.name}>
                  <label className="standard-font-size">
                    <CustomCheckbox
                      onChange={() => handleColumnSelection(column.name, !column.visible)}
                      checked={column.visible}
                      disabled={isMandatory}
                    />
                    <span className="ml-4">
                      <Translate id={column.name} />
                    </span>
                  </label>
                </Col>
              );
            })}
          </Row>
        </div>
        <Row className="last p-3 d-flex justify-content-end">
          <div className="float-right">
            <button type="button" className="form__button--cancel" onClick={handleClose}>
              <Translate id="cancel" />
            </button>
            <button
              className="form__button--blue background-color-main text-color-white border-color-main"
              onClick={handleSave}
            >
              <Translate id="save" />
            </button>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ColumnSelectionModal;
