
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown, faAngleDoubleUp, faCog } from '@fortawesome/free-solid-svg-icons';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { IDeviceRole } from '@wiot/shared-domain/models/role/role';
import { Device, DeviceMetadata, DeviceMetadataFields } from '@wiot/shared-domain/models/device/device';
import { TreeNodeType } from '@wiot/shared-domain/domain/device-tree/tree-node';
import { fetchDeviceFromDB } from '../../../api/apiHelpers';
import RenderOnCondition from '../../../components/RenderOnCondition';
import DeviceGroupActionModal from '../../DeviceGroups/DeviceGroupActionModal';
import ConfirmModal, { ConfirmationVariant } from '../../../components/Modal/ConfirmModal';
import DeviceActionModal from '../DeviceActionModal/DeviceActionModal';
import { hasPermission, stringTruncateFromCenter } from '../../../utils/common';
import Portal from '../../../components/shared/Portal';
import NodeLabelIcon from './NodeLabelIcon';
import DeviceGroupDetailsModal from '../../DeviceGroups/DeviceGroupDetailsModal';
import DeviceDetailsModal from '../DeviceDetailsModal/DeviceDetailsModal';
import UserActionModal from '../../UserManagement/UserActionModal/UserActionModal';
import { getSelectedMetaDataValue } from '../DeviceActionModal/metaDataUtilityFunctions';
import { refreshDeviceTree } from '../../../state/device-tree/fetch-device-tree/refreshDeviceTreeActionCreator';
import { removeDeviceGroup } from '../../../state/device-tree/removeDeviceGroupActionCreator';
import { removeDevice } from '../../../state/device-tree/removeDeviceActionCreator';
import { TreeNodeProps } from './tree-node-props';
import { saveExpandStateActionCreator } from '../../../state/device-tree/expand-state-tracker/saveExpandStateActionCreator';
import AddManualDeviceReadingModal from '../AddManualDeviceReadingModal';
import { PortalMenu } from '../../../components/PortalMenu/PortalMenu';
import DeviceTableMenu from '../Table/TableMenu';
import GroupTableMenu from '../../DeviceGroups/Table/TableMenu';
import {
  HORIZONTAL_OFFSET_TOPOLOGY_VIEW_OPEN_TO_RIGHT,
  VERTICAL_OFFSET_TOPOLOGY_VIEW
} from '../../../components/PortalMenu/constants/offset-topology-view';
import { AppState } from '../../../state/reducers/rootReducer';
import { getTreeNodeMenuId } from '../../../components/PortalMenu/constants/context-menu-ids';
import { closeContextMenu, openContextMenu } from '../../../state/context-menu/toggleContextMenuActionCreators';
import { fetchTreeNodeWithChildren } from '../../../state/device-tree/fetch-tree-node-with-children/fetchTreeNodeActionCreator';

export const TREE_NODE_FOOTER_MAX_VISIBLE_CHAR = 11;
export const TREE_NODE_HEADER_LABEL_MAX_VISIBLE_CHAR = 15;

function DeviceGroupAndDeviceTreeNode(props: TreeNodeProps) {
  const dispatch = useDispatch();
  const [nodeLabelMenu, setNodeLabelMenu] = useState<HTMLSpanElement | null>(null);

  const { treeNode, toggleNode } = props;
  const isCollapsed = treeNode.hiddenChildren.length > 0 || treeNode.hasChildrenToBeLoaded;

  const [showDeviceActionModalAddSameGroup, setShowDeviceActionModalAddSameGroup] = useState<boolean>(false);
  const [showAddManualDeviceReadingModal, setShowAddManualDeviceReadingModal] = useState<boolean>(false);
  const [deviceRole, setDeviceRole] = useState<IDeviceRole | undefined>(undefined);
  const [deviceMessageRole, setDeviceMessageRole] = useState<any>(undefined);
  const [showDeviceDetails, setShowDeviceDetails] = useState<boolean>(false);
  const [showDeviceActionModal, setShowDeviceActionModal] = useState<boolean>(false);
  const [showDuplicateDeviceActionModal, setShowDuplicateDeviceActionModal] = useState<boolean>(false);
  const [showDeleteDeviceModal, setShowDeleteDeviceModal] = useState<boolean>(false);
  const [showDeviceChangeModal, setShowDeviceChangeModal] = useState<boolean>(false);
  const [showDeviceActionModalAddDeviceInGroup, setShowDeviceActionModalAddDeviceInGroup] = useState<boolean>(false);

  const [showDeviceGroupDetails, setShowDeviceGroupDetails] = useState<boolean>(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState<boolean>(false);
  const [showDeviceGroupActionModalAddGroupInGroup, setShowDeviceGroupActionModalAddGroupInGroup] = useState<boolean>(false);
  const [showDeviceGroupActionModal, setShowDeviceGroupActionModal] = useState<boolean>(false);
  const [showUserActionModalAddUserInGroup, setShowUserActionModalAddUserInGroup] = useState<boolean>(false);

  const visibleContextMenu = useSelector((state: AppState) => state.contextMenu.visibleContextMenuId);

  const [boundingClientRect, setBoundingClientRect] = useState({
    x: 0, y: 0, width: 0, height: 0, top: 0, right: 0, bottom: 0, left: 0, toJSON: () => null,
  });


  const isDevice = treeNode.type === TreeNodeType.DEVICE;
  const isDeviceGroup = treeNode.type === TreeNodeType.DEVICE_GROUP;
  const downloadHash = getSelectedMetaDataValue(
    DeviceMetadataFields.metadata,
    'downloadHash',
    isDeviceGroup
      ? treeNode.innerObject as DeviceMetadata
      : (treeNode.innerObject as Device)?.deviceMetadata
  );

  const getBoundingClientRect = () => {
    if (nodeLabelMenu && treeNode) {
      setBoundingClientRect(nodeLabelMenu.getBoundingClientRect());
   }
  };

  const fetchDeviceRoleAndMessageRole = async () => {
    if (treeNode.id && (!deviceRole || !deviceMessageRole)) {
      const device = await fetchDeviceFromDB(treeNode.id);
      setDeviceRole(device.permission);
      setDeviceMessageRole(device.messageRole);
    }
  };

  const menuId = getTreeNodeMenuId(treeNode.id, isDevice);
  const toggleMenu = async (event?: React.MouseEvent) => {
    event && event.stopPropagation();
    if (visibleContextMenu !== menuId) {
      if (isDevice) {
        await fetchDeviceRoleAndMessageRole();
      }
      getBoundingClientRect();
      dispatch(openContextMenu(menuId));
    } else {
      dispatch(closeContextMenu());
    }
  };

  const showDetails = async () => {
    await fetchDeviceRoleAndMessageRole();

    if (hasPermission(deviceRole, 'view', true)) {
      setShowDeviceDetails(true);
    }
  };

  const closeDetails = (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
    event && event.stopPropagation();
    setShowDeviceDetails(false);
  };

  const toggleDeviceGroupDetailsModal = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDeviceGroupDetails(!showDeviceGroupDetails);
  };

  const toggleDeviceActionModal = (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDeviceActionModal(!showDeviceActionModal);
  };

  const toggleDuplicateDeviceActionModal = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDuplicateDeviceActionModal(!showDuplicateDeviceActionModal);
  };

  const toggleChangeDeviceActionModal = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDeviceChangeModal(!showDeviceChangeModal);
  };

  const toggleDeviceActionModalAddSameGroup = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDeviceActionModalAddSameGroup(!showDeviceActionModalAddSameGroup);
  };

  const toggleAddManualDeviceReadingModal = () => {
    setShowAddManualDeviceReadingModal(!showAddManualDeviceReadingModal);
  };

  const toggleDeleteDeviceModal = () => {
    setShowDeleteDeviceModal(!showDeleteDeviceModal);
  };

  const toggleDeleteGroupModal = () => {
    setShowDeleteGroupModal(!showDeleteGroupModal);
  };

  const toggleDeviceGroupActionModal = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }

    setShowDeviceGroupActionModal(!showDeviceGroupActionModal);
  };

  const toggleDeviceGroupActionModalAddGroupInGroup = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDeviceGroupActionModalAddGroupInGroup(!showDeviceGroupActionModalAddGroupInGroup);
  };

  const toggleDeviceActionModalAddDeviceInGroup = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDeviceActionModalAddDeviceInGroup(!showDeviceActionModalAddDeviceInGroup);
  };

  const toggleUserActionModalAddUserInGroup = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }

    setShowUserActionModalAddUserInGroup(!showUserActionModalAddUserInGroup);
  };

  const hasChildren = treeNode.children.length > 0
    || treeNode.hiddenChildren.length > 0
    || treeNode.hasChildrenToBeLoaded;

  const device = isDevice ? (treeNode.innerObject as Device) : null;
  const deviceGroup = isDeviceGroup ? (treeNode.innerObject as DeviceGroup) : undefined;
  const deviceType = device?.deviceType;
  const status = device?.status;
  const selectedDeviceGroupId = deviceGroup?.id;

  const minimumLength = Math.min(('Nicht zugewiesen').length - 1, TREE_NODE_HEADER_LABEL_MAX_VISIBLE_CHAR);
  const labelText = deviceGroup?.abbreviation || stringTruncateFromCenter(treeNode.name, minimumLength);
  const deviceId = device && device.deviceId ? stringTruncateFromCenter(device.deviceId.toString(), TREE_NODE_FOOTER_MAX_VISIBLE_CHAR) : '';

  const onTreeNodeClick =()=> {
    treeNode.hasChildrenToBeLoaded ? dispatch(fetchTreeNodeWithChildren(treeNode.id)) : toggleNode();

    const isExpandAction = isCollapsed;
    dispatch(saveExpandStateActionCreator(treeNode,isExpandAction));
  }

  return (
    <div
      onClick={() => onTreeNodeClick()}
      className='tree__node__badge'
    >
      <div
        className={`tree__node__label tree__node__label__${isDeviceGroup ? 'group' : 'device'}`}
        data-tip='node_label_tooltip'
        data-for={`node-label-title-${treeNode.id}`}
        title={treeNode.name}
      >
        <span className='tree__node__label__centered'>{labelText}</span>
      </div>

      <NodeLabelIcon
        nodeId={treeNode.id}
        groupType={
          isDeviceGroup ? (treeNode.innerObject as DeviceGroup).type : undefined
        }
        deviceType={deviceType}
        status={status}
        onClick={toggleMenu}
      />

      {/* FOOTER */}
      <div className='tree__node__label__footer'>
        {hasChildren && (
          <div className='tree__node__label__icon tree__node__label__icon__expand text-color-main'>
            <FontAwesomeIcon icon={isCollapsed ? faAngleDoubleDown : faAngleDoubleUp}/>
          </div>
        )}

        <RenderOnCondition condition={!!deviceId}>
          <div
            className='tree__node__label__footer__deviceid'
            data-tip='node_label_footer_tooltip'
            data-for={`node-label-footer-title-${deviceId}`}
            title={device?.deviceId}
            onClick={toggleMenu}
          >
            <span className='tree__node__label__footer__centered'>{deviceId}</span>
          </div>
        </RenderOnCondition>
        <div
          className='tree__node__label__icon tree__node__label__icon__menu text-color-main'
          onClick={toggleMenu}
          ref={(tc) => {
            setNodeLabelMenu(tc);
          }}
        >
          <FontAwesomeIcon icon={faCog}/>
        </div>
      </div>

      {/* DEVICE MENU */}
      <RenderOnCondition condition={ visibleContextMenu === getTreeNodeMenuId(treeNode.id, true) }>
        <PortalMenu
          anchorPosition={{
            left: boundingClientRect.left,
            top: boundingClientRect.top + VERTICAL_OFFSET_TOPOLOGY_VIEW
          }}
          minWidth={ 285 }
          openToRightHorizontalOffset={ HORIZONTAL_OFFSET_TOPOLOGY_VIEW_OPEN_TO_RIGHT }
        >
          <DeviceTableMenu
            removeUnit={ (id) => dispatch(removeDevice(id)) }
            deviceId={ treeNode.id }
            menuId={ menuId }
            deviceType={ deviceType }
            deviceMessageRole={ deviceMessageRole }
            refreshTreeData={ () => dispatch(refreshDeviceTree()) }
            showDetails={ showDetails }
            deviceRole={ deviceRole }
            toggleDeviceActionModal={ toggleDeviceActionModal }
            toggleDeleteModal={ toggleDeleteDeviceModal }
            toggleDuplicateDeviceActionModal={ toggleDuplicateDeviceActionModal }
            toggleChangeDeviceActionModal={ toggleChangeDeviceActionModal }
            toggleDeviceActionModalAddSameGroup={ toggleDeviceActionModalAddSameGroup }
            toggleAddManualDeviceReadingModal={ toggleAddManualDeviceReadingModal }
            downloadHash={ downloadHash }
          />
        </PortalMenu>
      </RenderOnCondition>

      {/* GROUP MENU */}
      <RenderOnCondition condition={ visibleContextMenu === getTreeNodeMenuId(treeNode.id, false) }>
        <PortalMenu
          anchorPosition={{
            left: boundingClientRect.left,
            top: boundingClientRect.top + VERTICAL_OFFSET_TOPOLOGY_VIEW
          }}
          minWidth={ 350 }
          openToRightHorizontalOffset={ HORIZONTAL_OFFSET_TOPOLOGY_VIEW_OPEN_TO_RIGHT }
        >
          <GroupTableMenu
            removeUnit={ removeDeviceGroup }
            menuId={ menuId }
            deviceGroupData={ { id: treeNode.id } }
            refreshTreeData={ () => dispatch(refreshDeviceTree()) }
            toggleDetails={ toggleDeviceGroupDetailsModal }
            toggleDeviceGroupActionModal={ toggleDeviceGroupActionModal }
            toggleDeleteModal={ toggleDeleteGroupModal }
            toggleDeviceGroupActionModalAddGroupInGroup={ toggleDeviceGroupActionModalAddGroupInGroup }
            toggleDeviceActionModalAddDeviceInGroup={ toggleDeviceActionModalAddDeviceInGroup }
            toggleUserActionModalAddUserInGroup={ toggleUserActionModalAddUserInGroup }
            downloadHash={ downloadHash }
          />
        </PortalMenu>
      </RenderOnCondition>

      {/* DEVICE DETAILS MODAL */}
      <RenderOnCondition condition={showDeviceDetails}>
        <Portal>
          <DeviceDetailsModal
            closeModal={closeDetails}
            removeUnit={(id) => dispatch(removeDevice(id))}
            deviceId={treeNode.id}
          />
        </Portal>
      </RenderOnCondition>

      {/* EDIT DEVICE MODAL */}
      <RenderOnCondition condition={showDeviceActionModal}>
        <Portal>
          <DeviceActionModal
            closeAddAndUpdateModal={toggleDeviceActionModal}
            title='update-device'
            showDeleteButton
            addUnit={false}
            removeUnit={(id) => dispatch(removeDevice(id))}
            id={treeNode.id}
            refreshTreeData={() => dispatch(refreshDeviceTree())}
          />
        </Portal>
      </RenderOnCondition>

      {/* DELETE DEVICE MODAL */}
      <RenderOnCondition condition={showDeleteDeviceModal}>
        <Portal>
          <ConfirmModal
            modalCloseRequested={() => toggleDeleteDeviceModal()}
            actionConfirmed={() => {
              dispatch(removeDevice(treeNode.id));
              return Promise.resolve();
            }}
            translationIdOfElementType="device"
            confirmationVariant={ ConfirmationVariant.DELETE }
          />
        </Portal>
      </RenderOnCondition>

      {/* DUPLICATE DEVICE MODAL */}
      <RenderOnCondition condition={showDuplicateDeviceActionModal}>
        <Portal>
          <DeviceActionModal
            closeAddAndUpdateModal={toggleDuplicateDeviceActionModal}
            title='clone-device'
            showDeleteButton={false}
            duplicate
            addUnit
            id={treeNode.id}
            refreshTreeData={() => dispatch(refreshDeviceTree())}
          />
        </Portal>
      </RenderOnCondition>

      {/* CHANGE DEVICE MODAL */}
      <RenderOnCondition condition={showDeviceChangeModal}>
        <Portal>
          <DeviceDetailsModal
            removeUnit={(id) => dispatch(removeDevice(id))}
            closeModal={toggleChangeDeviceActionModal}
            deviceId={treeNode.id}
            isChangeModal
            refreshTreeData={() => dispatch(refreshDeviceTree())}
          />
        </Portal>
      </RenderOnCondition>

      {/* DEVICE:ADD DEVICE TO THE SAME GROUP */}
      <RenderOnCondition condition={showDeviceActionModalAddSameGroup}>
        <Portal>
          <DeviceActionModal
            closeAddAndUpdateModal={toggleDeviceActionModalAddSameGroup}
            title='add-dev-same-group'
            showDeleteButton={false}
            addUnit
            selectedDeviceGroup={deviceGroup}
            refreshTreeData={() => dispatch(refreshDeviceTree())}
            disableGroupSelect
          />
        </Portal>
      </RenderOnCondition>

      {/* DEVICE GROUP DETAILS MODAL */}
      <RenderOnCondition condition={showDeviceGroupDetails}>
        <Portal>
          <DeviceGroupDetailsModal
            closeModal={toggleDeviceGroupDetailsModal}
            deviceGroupData={{ id: treeNode.id }}
          />
        </Portal>
      </RenderOnCondition>

      {/* DEVICE GROUP : ADD DEVICE TO THE SAME GROUP */}
      <RenderOnCondition condition={showDeviceActionModalAddDeviceInGroup}>
        <Portal>
          <DeviceActionModal
            closeAddAndUpdateModal={toggleDeviceActionModalAddDeviceInGroup}
            title='add-dev-under-group'
            showDeleteButton={false}
            addUnit
            selectedDeviceGroup={{id: selectedDeviceGroupId}}
            disableGroupSelect
            refreshTreeData={() => dispatch(refreshDeviceTree())}
          />
        </Portal>
      </RenderOnCondition>

      {/* DEVICE GROUP: ADD USER */}
      <RenderOnCondition condition={showUserActionModalAddUserInGroup}>
        <Portal>
          <UserActionModal
            saved={() => {
              dispatch(refreshDeviceTree());
              toggleUserActionModalAddUserInGroup();
            }}
            cancelled={toggleUserActionModalAddUserInGroup}
            isInCreationMode
            title='add-user-under-group'
            showDeleteButton={false}
            selectedDeviceGroupId={selectedDeviceGroupId}
          />
        </Portal>
      </RenderOnCondition>

      {/* UPDATE DEVICE GROUP MODAL */}
      <RenderOnCondition condition={showDeviceGroupActionModal}>
        <Portal>
          <DeviceGroupActionModal
            closeAddAndUpdateModal={toggleDeviceGroupActionModal}
            title='update-group'
            showDeleteButton
            addUnit={false}
            removeUnit={(id: string) => removeDeviceGroup(id)}
            id={treeNode.id}
            refreshTreeData={() => dispatch(refreshDeviceTree())}
          />
        </Portal>
      </RenderOnCondition>

      {/* DELETE DEVICE GROUP MODAL */}
      <RenderOnCondition condition={showDeleteGroupModal}>
        <Portal>
          <ConfirmModal
            modalCloseRequested={toggleDeleteGroupModal}
            actionConfirmed={() => {
              dispatch(removeDeviceGroup(treeNode.id));
              return Promise.resolve();
            }}
            translationIdOfElementType="device-group"
            confirmationVariant={ ConfirmationVariant.DELETE }
          />
        </Portal>
      </RenderOnCondition>

      {/* ADD MANUAL DEVICE READING */}
      <RenderOnCondition condition={showAddManualDeviceReadingModal}>
        <AddManualDeviceReadingModal
          onModalClosed={ toggleAddManualDeviceReadingModal }
          device={ device! }
        />
      </RenderOnCondition>

      {/* DEVICE GROUP: ADD GROUP */}
      <RenderOnCondition condition={showDeviceGroupActionModalAddGroupInGroup}>
        <Portal>
          <DeviceGroupActionModal
            closeAddAndUpdateModal={toggleDeviceGroupActionModalAddGroupInGroup}
            title='add-group'
            showDeleteButton={false}
            addUnit
            selectedDeviceGroup={selectedDeviceGroupId}
            disableGroupSelect
            refreshTreeData={() => dispatch(refreshDeviceTree())}
          />
        </Portal>
      </RenderOnCondition>
    </div>
  );
}

export default DeviceGroupAndDeviceTreeNode;
