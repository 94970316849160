import { AppState } from '../reducers/rootReducer';
import { INITIAL_TREE_CHILD_LIMIT } from '@wiot/shared-domain/domain/device-tree/initial-tree-children-limit';

export const selectChildLimit = (appState: AppState, nodeId: string): number => {
  const expandedNode = appState.deviceTree.expandState.nodesExpandedByUser.find(
    node => node.nodeId === nodeId);
  const currentChildLimit = expandedNode?.childLimit || INITIAL_TREE_CHILD_LIMIT;

  return currentChildLimit;
};
