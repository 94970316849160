import localforage from 'localforage';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import store from '../state/store';
import { setLoadingMessage } from '../state/actions/isLoadingAction';
import { addDeviceTypesToDB, removeDeviceTypeFromDB, updateDeviceTypesInDB } from './apiHelpers';

const LOCAL_STORAGE_KEY_DEVICE_TYPES = 'deviceTypes';
const LOCAL_STORAGE_KEY_ADD_DEVICE_TYPES = `${LOCAL_STORAGE_KEY_DEVICE_TYPES}:add`;
const LOCAL_STORAGE_KEY_EDIT_DEVICE_TYPES = `${LOCAL_STORAGE_KEY_DEVICE_TYPES}:edit`;
const LOCAL_STORAGE_KEY_REMOVE_DEVICE_TYPES = `${LOCAL_STORAGE_KEY_DEVICE_TYPES}:remove`;

/**
 * Represents the offline store of device types.
 */
interface DeviceTypesOfflineStore {
  /**
   * Defines all cached items grouped by id.
   */
  cachedItemsById: { [id: string]: IDeviceType };

  /**
   * Defines the number of cached items.
   */
  cachedItemsCount: number;
}

export class DeviceTypeCache {
  /**
   * Persists all temporary changes from the cache.
   */
  public static async persistAllTemporaryChangesFromCache() {
    await DeviceTypeCache.persistAllTemporaryAddedDeviceTypesFromCache();
    await DeviceTypeCache.persistAllTemporaryDeviceTypeChangesFromCache();
    await DeviceTypeCache.persistAllTemporaryDeviceTypeRemovalsFromCache();
  }

  /**
   * Gets all temporary added device types to b persisted.
   *
   * @returns All temporary added device types to be persisted.
   */
  public static async getTemporaryAddedDeviceTypes(): Promise<IDeviceType[]> {
    const offlineAddedDeviceTypesById =
      (await localforage.getItem<{ [id: string]: IDeviceType }>(
        LOCAL_STORAGE_KEY_ADD_DEVICE_TYPES,
      )) || {};
    return Object.values(offlineAddedDeviceTypesById);
  }

  /**
   * Gets all temporary updated device types to be persisted.
   *
   * @returns All temporary updated device types to be persisted.
   */
  public static async getTemporaryUpdatedDeviceTypes(): Promise<IDeviceType[]> {
    const offlineUpdatedDeviceTypesById =
      (await localforage.getItem<{ [id: string]: IDeviceType }>(
        LOCAL_STORAGE_KEY_EDIT_DEVICE_TYPES,
      )) || {};
    return Object.values(offlineUpdatedDeviceTypesById);
  }

  /**
   * Gets all temporary removed device types to be persisted.
   *
   * @returns All temporary removed device types to be persisted.
   */
  public static async getTemporaryRemovedDeviceTypes(): Promise<IDeviceType[]> {
    const offlineRemovedDeviceTypesById =
      (await localforage.getItem<{ [id: string]: IDeviceType }>(
        LOCAL_STORAGE_KEY_REMOVE_DEVICE_TYPES,
      )) || {};
    return Object.values(offlineRemovedDeviceTypesById);
  }

  /**
   * Replaces cached device types.
   *
   * @param deviceTypes Device types to be cached.
   *
   * @returns A promise which resolves if the device types are successfully cached.
   */
  static async replaceCachedDeviceTypes(deviceTypes: IDeviceType[]): Promise<void> {
    const cachedItemsById: { [id: string]: IDeviceType } = {};

    deviceTypes.forEach((deviceType) => {
      cachedItemsById[deviceType.id] = deviceType;
    });

    await localforage.setItem<DeviceTypesOfflineStore>(LOCAL_STORAGE_KEY_DEVICE_TYPES, {
      cachedItemsById,
      cachedItemsCount: deviceTypes.length,
    });
  }

  /**
   * Caches one or more new device types to be added.
   *
   * @param deviceTypesToAdd One or more device types to be cached for later persistence.
   *
   * @returns A promise which resolves if the device types are successfully cached.
   */
  public static async cacheTemporaryAddedDeviceTypes(
    deviceTypesToAdd: IDeviceType[],
  ): Promise<void> {
    const {
      cachedItemsById,
      cachedItemsCount,
      ...other
    } = await localforage.getItem<DeviceTypesOfflineStore>(LOCAL_STORAGE_KEY_DEVICE_TYPES);
    const deviceTypesToBeAddedById: { [id: string]: IDeviceType } =
      (await localforage.getItem(LOCAL_STORAGE_KEY_ADD_DEVICE_TYPES)) || [];

    deviceTypesToAdd.forEach((deviceTypeToAdd) => {
      deviceTypesToBeAddedById[deviceTypeToAdd.id] = deviceTypeToAdd;
    });

    await localforage.setItem<DeviceTypesOfflineStore>(LOCAL_STORAGE_KEY_DEVICE_TYPES, {
      cachedItemsById: { ...cachedItemsById, ...deviceTypesToBeAddedById },
      cachedItemsCount: cachedItemsCount + 1,
      ...other,
    });
    await localforage.setItem<{ [id: string]: IDeviceType }>(LOCAL_STORAGE_KEY_ADD_DEVICE_TYPES, {
      ...deviceTypesToBeAddedById,
    });
  }

  /**
   * Caches one or more device types to be changed.
   *
   * @param deviceTypesToBeUpdated One or more device types to be cached for later persistence.
   *
   * @returns A promise which resolves if the device types are successfully cached.
   */
  public static async cacheTemporaryDeviceTypeChanges(
    deviceTypesToBeUpdated: IDeviceType[],
  ): Promise<void> {
    const { cachedItemsById, cachedItemsCount, ...other } =
      (await localforage.getItem<DeviceTypesOfflineStore>(LOCAL_STORAGE_KEY_DEVICE_TYPES)) || {};
    const deviceTypesToBeUpdatedById =
      (await localforage.getItem<{ [id: string]: IDeviceType }>(
        LOCAL_STORAGE_KEY_EDIT_DEVICE_TYPES,
      )) || {};

    deviceTypesToBeUpdated.forEach((deviceTypeToBeUpdated) => {
      deviceTypesToBeUpdatedById[deviceTypeToBeUpdated.id] = deviceTypeToBeUpdated;
    });

    await localforage.setItem<DeviceTypesOfflineStore>(LOCAL_STORAGE_KEY_DEVICE_TYPES, {
      cachedItemsById: { ...cachedItemsById, ...deviceTypesToBeUpdatedById },
      cachedItemsCount: cachedItemsCount + 1,
      ...other,
    });
    await localforage.setItem<{ [id: string]: IDeviceType }>(
      LOCAL_STORAGE_KEY_EDIT_DEVICE_TYPES,
      deviceTypesToBeUpdatedById,
    );
  }

  /**
   * Caches a single device type removal.
   *
   * @param deviceTypesToBeRemoved The device type to be temporary removed cached for later persistence.
   *
   * @returns A promise which resolves if the device type removal has been successfully cached.
   */
  public static async cacheTemporaryDeviceTypeRemoval(
    deviceTypesToBeRemoved: IDeviceType,
  ): Promise<IDeviceType> {
    const idsOfCachedDeviceTypes = await this.cacheTemporaryDeviceTypeRemovals([
      deviceTypesToBeRemoved,
    ]);
    return idsOfCachedDeviceTypes[0];
  }

  /**
   * Caches one or more device types removals.
   *
   * @param deviceTypesToBeRemoved One or more device types to be cached for later persistence.
   *
   * @returns A promise which resolves if the device type removals are successfully cached.
   */
  public static async cacheTemporaryDeviceTypeRemovals(
    deviceTypesToBeRemoved: IDeviceType[],
  ): Promise<IDeviceType[]> {
    const deviceTypesToBeRemovedById =
      (await localforage.getItem<{ [id: string]: IDeviceType }>(
        LOCAL_STORAGE_KEY_REMOVE_DEVICE_TYPES,
      )) || {};

    const removalPromises: Promise<void>[] = [];
    deviceTypesToBeRemoved.forEach((deviceTypeToBeRemoved) => {
      deviceTypesToBeRemovedById[deviceTypeToBeRemoved.id] = deviceTypeToBeRemoved;
      removalPromises.push(
        DeviceTypeCache.removeFromCacheByDeviceTypeId(
          deviceTypeToBeRemoved.id,
          LOCAL_STORAGE_KEY_DEVICE_TYPES,
        ),
      );
    });

    await Promise.all(removalPromises);
    await localforage.setItem<{ [id: string]: IDeviceType }>(
      LOCAL_STORAGE_KEY_REMOVE_DEVICE_TYPES,
      {
        ...deviceTypesToBeRemovedById,
      },
    );

    return deviceTypesToBeRemoved;
  }

  /**
   * Gets all cached device types.
   *
   * @returns A promise for all cached device types.
   */
  public static async getCachedDeviceTypes(): Promise<IDeviceType[]> {
    const deviceTypeOfflineStore =
      await localforage.getItem<DeviceTypesOfflineStore>(LOCAL_STORAGE_KEY_DEVICE_TYPES);

    if(!deviceTypeOfflineStore){
     return [];
    }

    return Object.values(deviceTypeOfflineStore.cachedItemsById);
  }

  /**
   * Gets a cached device type by its id.
   *
   * @param id The id of the cached device type to get.
   *
   * @returns A promise for a cached device type by its id.
   */
  public static async getCachedDeviceType(id: string): Promise<IDeviceType | null> {
    const deviceTypeOfflineStore =
      (await localforage.getItem<DeviceTypesOfflineStore>(LOCAL_STORAGE_KEY_DEVICE_TYPES)) || {};

    if (!deviceTypeOfflineStore.cachedItemsById) {
      return null;
    }

    return deviceTypeOfflineStore.cachedItemsById[id];
  }

  private static async persistAllTemporaryAddedDeviceTypesFromCache(): Promise<void> {
    store.dispatch(
      setLoadingMessage({
        message: 'add-cache-device-types',
        translatable: true,
      }),
    );

    const offlineAddedDeviceTypes = await DeviceTypeCache.getTemporaryAddedDeviceTypes();
    const persistedDeviceTypes = await addDeviceTypesToDB(offlineAddedDeviceTypes);

    // We need to remove the item by name because the id will be regenerated by the api after storage.
    await DeviceTypeCache.removeItemsFromCacheByDeviceTypeId(
      persistedDeviceTypes,
      LOCAL_STORAGE_KEY_ADD_DEVICE_TYPES,
    );
  }

  private static async persistAllTemporaryDeviceTypeChangesFromCache() {
    store.dispatch(
      setLoadingMessage({
        message: 'update-cache-device-types',
        translatable: true,
      }),
    );

    const offlineUpdatedDeviceTypes = await DeviceTypeCache.getTemporaryUpdatedDeviceTypes();
    const persistedDeviceTypes = await updateDeviceTypesInDB(offlineUpdatedDeviceTypes);
    await DeviceTypeCache.removeItemFromCacheById(
      persistedDeviceTypes,
      LOCAL_STORAGE_KEY_EDIT_DEVICE_TYPES,
    );
  }

  private static async persistAllTemporaryDeviceTypeRemovalsFromCache() {
    store.dispatch(
      setLoadingMessage({
        message: 'remove-cache-device-types',
        translatable: true,
      }),
    );

    const offlineRemovedDeviceTypes = await DeviceTypeCache.getTemporaryRemovedDeviceTypes();
    const dbRemovalPromises: Promise<string>[] = [];

    offlineRemovedDeviceTypes.forEach((deviceTypeToBeRemoved) => {
      dbRemovalPromises.push(removeDeviceTypeFromDB(deviceTypeToBeRemoved));
    });

    const idsOfRemovedDeviceTypes = await Promise.all(dbRemovalPromises);

    idsOfRemovedDeviceTypes.forEach((id) => {
      DeviceTypeCache.removeFromCacheByDeviceTypeId(id, LOCAL_STORAGE_KEY_REMOVE_DEVICE_TYPES);
    });
  }

  private static async removeItemFromCacheById(deviceTypes: IDeviceType[], storeKey: string) {
    const removalPromises: Promise<void>[] = [];

    deviceTypes.forEach((deviceType) => {
      removalPromises.push(DeviceTypeCache.removeFromCacheByDeviceTypeId(deviceType.id, storeKey));
    });

    await Promise.all(removalPromises);
  }

  private static async removeItemsFromCacheByDeviceTypeId(
    deviceTypes: IDeviceType[],
    storeKey: string,
  ) {
    const removalPromises: Promise<void>[] = [];

    deviceTypes.forEach((deviceType) => {
      removalPromises.push(DeviceTypeCache.removeFromCacheByDeviceTypeId(deviceType.id, storeKey));
    });

    await Promise.all(removalPromises);
  }

  private static async removeFromCacheByDeviceTypeId(deviceTypeId: string, storeKey: string) {
    if (storeKey === LOCAL_STORAGE_KEY_DEVICE_TYPES) {
      const offlineStore = (await localforage.getItem<DeviceTypesOfflineStore>(storeKey)) || {};

      delete offlineStore.cachedItemsById[deviceTypeId];
      await localforage.setItem<DeviceTypesOfflineStore>(LOCAL_STORAGE_KEY_DEVICE_TYPES, {
        cachedItemsById: offlineStore.cachedItemsById,
        cachedItemsCount: offlineStore.cachedItemsCount - 1,
      });
    } else {
      const offlineUpdatedDeviceTypesById =
        (await localforage.getItem<{ [id: string]: IDeviceType }>(storeKey)) || {};

      delete offlineUpdatedDeviceTypesById[deviceTypeId];
      await localforage.setItem<{ [id: string]: IDeviceType }>(
        storeKey,
        offlineUpdatedDeviceTypesById,
      );
    }
  }
}
