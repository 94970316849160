import { ToggleOption } from '../Toggle';
import { DisplayTypes } from '@wiot/shared-domain/models/device/device';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FeatureAvailability } from '../../../utils/feature-availability';
import { ACLDeviceManager } from '@wiot/shared-domain/models/role/access-level';
import { faBan } from '@fortawesome/free-solid-svg-icons';

export const deviceListOptions: ToggleOption[] = [
  {
    value: DisplayTypes.ASSIGNED,
    icon: faCheckCircle,
    availability: FeatureAvailability.Wiot | FeatureAvailability.KeyManager,
    translationId: 'toggle-option-assigned',
  },
  {
    value: DisplayTypes.UNASSIGNED,
    icon: faTimesCircle,
    permissionKey: ACLDeviceManager.VIEW_UNASSIGNED,
    availability: FeatureAvailability.Wiot,
    translationId: 'toggle-option-unassigned',
  },
  {
    value: DisplayTypes.BLOCKED,
    icon: faBan,
    permissionKey: ACLDeviceManager.VIEW_BLACKLISTED,
    availability: FeatureAvailability.Wiot,
    translationId: 'toggle-option-blocked',
  },
];
