import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { DeviceReading, } from '@wiot/shared-domain/models/device-reading/device-reading';
import { Device, IColumnObject } from '@wiot/shared-domain/models/device/device';
import { DEVICE_MESSAGES_TABLE_HEADERS as TABLE_HEADERS, IconVariant, sourceTypeIconSmall, } from '../../../constants';
import QUESTION_MARK from '../../../assets/devices/question-mark-small.svg';
import {
  getDeviceTypeIconFullPath,
  getTranslationValueInCurrentLanguage,
  localizeDate,
} from '../../../utils/common';
import { getDeviceGroupPathFromAncestors } from '../../../utils/device-group-path-helper';
import RenderOnCondition from '../../../components/RenderOnCondition';
import DeviceMessagesDetailsModal from '../DeviceMessagesDetailsModal';
import { FormattedDeviceReadingValue } from '../../../components/shared/FormattedDeviceReadingValue';
import { Tooltip } from '../../../components/shared/Tooltip';

interface IDataRow {
  deviceMessage: DeviceReading;
  refreshData: (column?: IColumnObject) => void;
}

const DataRow = (props: IDataRow) => {
  const [showMessageDetails, setShowMessageDetails] = useState(false);

  const {
    id,
    receivedAt,
    values,
    source,
    // @ts-ignore
    ancestors,
  } = props.deviceMessage;
  const device = props.deviceMessage.device as Device;

  // @ts-ignore
  const deviceTypeName = device && device.deviceType ? device.deviceType.name : '';

  // @ts-ignore
  const { name, deviceId } = device;

  let sourceTypeName: string;
  if (source && (source.includes('OMS') || source.includes('LORA'))) {
    sourceTypeName = `${source}_GATEWAY`;
  } else {
    sourceTypeName = source || '-';
  }

  const toggleMessageDetails = (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
    event && event.preventDefault();
    setShowMessageDetails((prevState) => !prevState);
  };

  const { truncatedPath } = getDeviceGroupPathFromAncestors(ancestors || []);

  return (
    <div className="data-card-container">
      <Row className="data-card-container__header">
        <div className="data-card-container__header__title  text-overflow-ellipsis">{name}</div>
      </Row>
      <Row className="data-card-container__device-type">
        <img
          data-testid="device-manager__table__table__trow__device-type-icon"
          data-tip="device-type-icon"
          data-for={`deviceType-${id}-${deviceTypeName}`}
          src={getDeviceTypeIconFullPath(device.deviceType, IconVariant.small)}
          alt="device type"
        />
        <Tooltip
          id={`deviceType-${id}-${deviceTypeName}`}
          place="right"
        >
          {deviceTypeName ? getTranslationValueInCurrentLanguage(deviceTypeName) : '-'}
        </Tooltip>
      </Row>
      <Row className="data-card-container__body">
        <Col xs={4} className="data-card-container__key">
          <Translate id={TABLE_HEADERS[1]} />
          {':'}
        </Col>
        <Col className="data-card-container__value">{deviceId}</Col>
      </Row>
      <Row className="data-card-container__body">
        <Col xs={4} className="data-card-container__key">
          <Translate id={TABLE_HEADERS[3]} />
          {':'}
        </Col>
        <Col className="data-card-container__value">{truncatedPath}</Col>
      </Row>
      <Row className="data-card-container__body">
        <Col xs={4} className="data-card-container__key">
          <Translate id={TABLE_HEADERS[4]} />
          {':'}
        </Col>
        <Col className="data-card-container__value">
          <img
            data-testid="device-manager__table__table__trow__device-type-icon"
            data-tip="device-type-icon"
            data-for={`source-${id}-${sourceTypeName}`}
            src={(sourceTypeName && sourceTypeIconSmall[sourceTypeName]) || QUESTION_MARK}
            alt="device type"
          />
        </Col>
      </Row>
      <Row className="data-card-container__body">
        <Col className="data-card-container__value">{receivedAt ? localizeDate(receivedAt) : '-'}</Col>
      </Row>
      <Row className="data-card-container__body">
        <Col xs={4} className="data-card-container__key">
          <Translate id={TABLE_HEADERS[6]} />
          {':'}
        </Col>
        <Col className="data-card-container__value">
          <FormattedDeviceReadingValue deviceReadingValues={ values }/>
        </Col>
      </Row>
      <Row className="data-card-container__footer justify-content-center">
        <div>
          <span className="device-details-btn" onClick={toggleMessageDetails}>
            <Translate id="message-details" />
          </span>
          <RenderOnCondition condition={showMessageDetails}>
            <DeviceMessagesDetailsModal
              closeModal={toggleMessageDetails}
              onDeviceReadingDeleted={ props.refreshData }
              deviceReading={props.deviceMessage}
            />
          </RenderOnCondition>
        </div>
      </Row>
    </div>
  );
};

export default DataRow;
