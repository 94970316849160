import * as React from 'react';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ErrorMessage } from 'formik';
import { generateDefaultTranslations, ITranslation } from '@wiot/shared-domain/models/localization/translation';
import { getSelectedLanguage } from '../../utils/common';
import { CustomFieldInput, ICustomFieldInputProps } from './CustomFieldInput';
import englishFlag from '../../assets/language/united-kingdom-flag-icon-32.png';
import germanFlag from '../../assets/language/germany-flag-icon-32.png';

/**
 * Available language flags by their two-letter ISO 639-1 language code.
 */
const FLAGS: { [key: string]: string } = {
  en: englishFlag,
  de: germanFlag,
};

interface IMultiLanguageInputFieldProps
  extends Omit<ICustomFieldInputProps, 'translationId' | 'value' | 'onChange'> {
  translatedInputLabel?: string;
  value: ITranslation[];
  onChange: (value: ITranslation[]) => void;
}

// TODO(DS): Document this class
// TODO(DS): Implement required properly
// TODO(DS): Check other properties of the interface ICustomFieldInputProps
const MultiLanguageInputField = ({
  translatedInputLabel,
  fieldName,
  value = [],
  onChange,
  ...inputProps
}: IMultiLanguageInputFieldProps) => {
  const [currentLanguageCode, setCurrentLanguageCode] = useState<string>(getSelectedLanguage());

  const onLanguageSelectButtonClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    let nextLanguageCode = 'en';
    if (currentLanguageCode === 'en') {
      nextLanguageCode = 'de';
    }
    setCurrentLanguageCode(nextLanguageCode);
  };

  if (!value || value.length === 0) {
    onChange(generateDefaultTranslations('', ''));
    return null;
  }

  const translationIndex = value.findIndex((t) => t.languageCode === currentLanguageCode);
  const selectedFlag = FLAGS[currentLanguageCode];

  return (
    <>
      <div className="form__label">
        <Row>
          <Col lg={2}>
            <button onClick={onLanguageSelectButtonClicked} style={{ marginTop: 19 }}>
              <img src={selectedFlag} alt="selected language" />
            </button>
          </Col>
          <Col lg={8}>
            {value && value[translationIndex] && (
              <CustomFieldInput
                fieldName={fieldName}
                translationId={translatedInputLabel}
                value={value[translationIndex].value}
                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                  const newValue = [...value];
                  newValue[translationIndex].value = event.currentTarget.value;
                  onChange(newValue);
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...inputProps}
              />
            )}
          </Col>
        </Row>
        <ErrorMessage component="span" className="input-error" name={fieldName} />
      </div>
    </>
  );
};

export default MultiLanguageInputField;
