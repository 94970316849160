import React, { useState, useEffect } from 'react';
import { HuePicker, ColorResult, MaterialPicker } from 'react-color';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Row } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { patchSiteTheme } from '../../../../api/apiHelpers';

type IThemeColorPicker = LocalizeContextProps;

const ThemeColorPicker = ({ translate }: IThemeColorPicker) => {
  const [color, setColor] = useState('');

  useEffect(() => {
    const style = getComputedStyle(document.body);
    const currentCol = style?.getPropertyValue('--main-color');
    setColor(currentCol);
  }, []);

  const getMainColors = (selectedColor: ColorResult) => {
    const { hsl } = selectedColor;

    return {
      mainColor: `hsl(${hsl.h}, 100%, 21%)`,
      mainColorLight: `hsl(${hsl.h}, 81%, 41%)`,
      mainColorGray: `hsl(${hsl.h}, 42%, 68%)`,
      mainColorMod1: `hsl(${hsl.h}, 100%, 57%)`,
      mainColorMod2: `hsl(${hsl.h}, 100%, 72%)`,
      mainColorMod3: `hsl(${hsl.h}, 100%, 42%)`,
      mainColorMod4: `hsl(${hsl.h}, 42%, 71%)`,
      mainColorMod5: `hsl(${hsl.h}, 72%, 56%)`,
      mainColorMod6: `hsl(${hsl.h}, 81%, 53%)`,
    };
  };

  const onChangeHandler = (changedColor: ColorResult) => {
    setColor(changedColor?.hex?.toString());
    const mainColors = getMainColors(changedColor);
    const rootEl = document.documentElement;
    rootEl?.style?.setProperty('--main-color', mainColors?.mainColor);
    rootEl?.style?.setProperty('--main-color-lighter', mainColors?.mainColorLight);
    rootEl?.style?.setProperty('--main-color-gray', mainColors?.mainColorGray);
    rootEl?.style?.setProperty('--main-color-mod-1', mainColors?.mainColorMod1);
    rootEl?.style?.setProperty('--main-color-mod-2', mainColors?.mainColorMod2);
    rootEl?.style?.setProperty('--main-color-mod-3', mainColors?.mainColorMod3);
    rootEl?.style?.setProperty('--main-color-mod-4', mainColors?.mainColorMod4);
    rootEl?.style?.setProperty('--main-color-mod-5', mainColors?.mainColorMod5);
    rootEl?.style?.setProperty('--main-color-mod-6', mainColors?.mainColorMod6);
  };

  const onCompleteHandler = async (
    completedColor: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event?.preventDefault();
    const success = await patchSiteTheme(completedColor);
    success &&
      toastr.success(
        translate('success').toString(),
        translate('site-color-theme-updated-successfully').toString(),
      );
  };

  return (
    <>
      <Row className="justify-content-around d-flex mb-3">
        <div className="m-auto">
          <MaterialPicker
            color={color}
            onChange={onChangeHandler}
            onChangeComplete={onCompleteHandler}
          />
        </div>
        <div className="settings-container__hue-picker-container">
          <HuePicker
            color={color}
            onChange={onChangeHandler}
            onChangeComplete={onCompleteHandler}
          />
        </div>
      </Row>
    </>
  );
};

export default withLocalize(ThemeColorPicker);
