import * as React from 'react';
import { connect } from 'react-redux';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import TableDataHeader from '../../../components/Table/TableDataHeader';
import NoResultsInfo from '../../../components/Table/NoResultsInfo';
import RenderOnCondition from '../../../components/RenderOnCondition';
import { AppState } from '../../../state/reducers/rootReducer';
import TableDataRow from './TableDataRow';
import { MANAGE_DEVICE_TYPES_TABLE_HEADERS as TABLE_HEADERS } from '../../../constants';

export interface TableDataProps {
  deviceTypes: IDeviceType[];
  isLoading: boolean;
  isSelectAllChecked: boolean;
  markOneDeviceGroupAsSelected: (id: string) => void;
  markAllDeviceGroupsAsSelected: (event: React.FormEvent<HTMLInputElement>) => void;
  fetchDeviceTypes: (column?: IColumnObject | null) => void;
  deleteDeviceType: (deviceType: IDeviceType) => Promise<void>;
}

export interface TableDataState {
  columns: IColumnObject[];
}

class TableData extends React.Component<TableDataProps, TableDataState> {
  constructor(props: TableDataProps) {
    super(props);

    this.state = {
      columns: [],
    };
  }

  componentDidMount = () => {
    this.props.fetchDeviceTypes();
    this.initState();
  };

  initState = () => {
    const columnState = TABLE_HEADERS.map((headerName) => ({
      name: headerName,
      visible: true,
      sortable: true,
    }));

    this.setState({ columns: columnState });
  };

  render() {
    const { columns } = this.state;
    const {
      deviceTypes,
      isLoading,
      isSelectAllChecked,
      markAllDeviceGroupsAsSelected,
      markOneDeviceGroupAsSelected,
      fetchDeviceTypes,
      deleteDeviceType,
    } = this.props;

    if (isLoading) {
      return <LoadingIcon/>;
    }

    const showResults = (deviceTypes);

    return (
      <div className="device-manager__table__table__container">
        <table className="device-manager__table__table">
          <TableDataHeader
            withCheckbox
            tableHeaders={columns}
            markAllRowsAsSelected={markAllDeviceGroupsAsSelected}
            isSelectAllChecked={isSelectAllChecked}
          />
          <tbody>
            <RenderOnCondition condition={showResults}>
              {
                deviceTypes.map((deviceType: IDeviceType) => (
                  <TableDataRow
                    key={deviceType.id}
                    deviceType={deviceType}
                    deleteDeviceType={deleteDeviceType}
                    selectOne={markOneDeviceGroupAsSelected}
                    refreshGroups={fetchDeviceTypes}
                  />
                ))}
            </RenderOnCondition>
          </tbody>
        </table>
        <RenderOnCondition condition={!showResults}>
          <NoResultsInfo />
        </RenderOnCondition>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  deviceTypes: state.deviceTypes,
  isLoading: state.isLoading,
});

export default connect(mapStateToProps)(TableData);
