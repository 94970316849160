import React from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { Col, Image, Row } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { HorizontalDivider } from "../shared/Divider";
import {
  replaceSiteBranding,
  replaceSiteFavicon,
  replaceSiteLogo,
  replaceSiteWallpaper,
} from '../../api/apiHelpers';
import {
  convertDataURL2blob,
  convertFile2image,
  convertImage2JPGdataURL,
  convertImage2PNGdataURL,
} from '../../utils/common';

interface IImageUploadControlsProps extends LocalizeContextProps {
  images?: File[];
  src: string;
  file: string;

  /**
   * Callback function which will be called if an image upload was requested.
   *
   * @param image The image (file) to be uploaded.
   */
  uploadRequested?: (image: File) => void;
}

const ImageUploadControls = ({
  images,
  src,
  file,
  translate,
  uploadRequested,
}: IImageUploadControlsProps) => {
  /*
   *   converts a File of type jpg/png/gif to "image/jpeg" (not for favicon.ico)
   *   and sends it to the backend
   */
  const handleUpload = async (event: Event, image: File) => {
    event.preventDefault();

    if (uploadRequested) {
      uploadRequested(image);
      return;
    }

    let blob: Blob | null = null;
    if (file === 'wallpaper') {
      // compress file
      const jpegUrl = await convertImage2JPGdataURL(convertFile2image(image));
      blob = convertDataURL2blob(jpegUrl);
    } else if (file !== 'favicon') {
      // convert to png fo preserve transparency
      const pngUrl = await convertImage2PNGdataURL(convertFile2image(image));
      blob = convertDataURL2blob(pngUrl);
    }

    if (blob) {
      let res;
      switch (file) {
        case 'logo':
          res = await replaceSiteLogo(blob);
          break;
        case 'wallpaper':
          res = await replaceSiteWallpaper(blob);
          break;
        case 'branding':
          res = await replaceSiteBranding(blob);
          break;
        default:
          res = await replaceSiteFavicon(image);
          break;
      }
      if (res && res.includes(`customize/own/${file}`)) {
        return window.location.reload();
      }
    }
    toastr.error(translate('ERROR').toString(), translate('upload-failed').toString());
  };

  return (
    <>
      <HorizontalDivider />
      <Row className="just">
        <Col lg={6} className="align-self-center text-right">
          <Image className="thumbnail" thumbnail src={src} />
        </Col>
        <Col lg={6} className="align-self-center">
          <button
            className="form__button--blue background-color-main text-color-white border-color-main"
            // @ts-ignore
            onClick={(e) => handleUpload(e, images[0])}
          >
            <Translate id="upload" />
          </button>
        </Col>
      </Row>
    </>
  );
};

export default withLocalize(ImageUploadControls);
