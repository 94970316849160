import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Col } from 'react-bootstrap';
import RenderOnCondition from '../RenderOnCondition';

export interface MultiSelectPillProps<T> {
  items: Array<T>;
  fieldTranslationId: string;
  handleRemoveClick: (index: number) => void;
  getSelectedValueText: (selectedValue: any) => string;
}

function MultiSelectPill<T>({
  items,
  fieldTranslationId,
  handleRemoveClick,
  getSelectedValueText,
}: MultiSelectPillProps<T>) {
  return (
    <RenderOnCondition condition={ items?.length > 0 }>
      <Col xs={ 12 } className="multiselect_pill_wrapper">
        <span className="filterbar__item__name">
          <Translate id={ fieldTranslationId } />:
        </span>
        <div className="multiselect_pill_container" >
          { items.map((item, index) => (
            <span key={ index } className="multiselect_pill">
              { getSelectedValueText(item) }
              <span
                className="multiselect_pill_icon"
                onClick={ () => handleRemoveClick(index) }
              >
                <FontAwesomeIcon icon={ faTimes } size={ 'sm' }/>
              </span>
            </span>
          ))}
        </div>
      </Col>
    </RenderOnCondition>
  );
}

export default MultiSelectPill;
