import * as React from 'react';
import { ButtonToolbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useHistory } from 'react-router';

import { setFilter } from '../../state/filter/set-filter-action-creator';
import { PageKeys } from '../../state/reducers/filterSortReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../state/reducers/rootReducer';
import { FilterField } from './filter-field';
import useTranslation from '../../hooks/useTranslation';
import { generatePropertyViewPath } from '../../pages/PropertyView/PropertyViewPathUtils';
import { PropertyConsumptionType } from '@wiot/shared-domain/domain/property/property-consumption-type';

interface FilterBillingFrequencyToggleProps {
  field: FilterField;
  pageKey: PageKeys;
}

export const ConsumptionTypeToggleFilter = (props: FilterBillingFrequencyToggleProps) => {
  const { field, pageKey } = props;
  const dispatch = useDispatch();
  const translate = useTranslation();
  const filter = useSelector((state: AppState) => state.filters.filter);
  const history = useHistory();

  const selected = (filter[pageKey] as any)[field.stateField];

  const onConsumptionTypeChange = (newValue: PropertyConsumptionType) => {
    const path = generatePropertyViewPath({
      consumptionType: newValue,
    });
    history.push(path);

    dispatch(
      setFilter({
        page: pageKey,
        values: {
          [field.stateField]: newValue,
          propertyMBusDeviceTypeIdForPieChart: 0,
          propertyMBusDeviceTypeIdForConsumption: 0,
        },
      }),
    );
  };

  return (
    <div className="filterbar__item d-flex justify-content-end">
      <ButtonToolbar>
        <ToggleButtonGroup type="radio" name="options" value={selected} onChange={onConsumptionTypeChange}>
          <ToggleButton variant="secondary" value={PropertyConsumptionType.ENERGY}>{ translate('energy').toString() }</ToggleButton>
          <ToggleButton variant="secondary" value={PropertyConsumptionType.WATER}>{ translate('water').toString() }</ToggleButton>
        </ToggleButtonGroup>
      </ButtonToolbar>
    </div>
  );
};
