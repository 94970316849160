/**
 * Interface for translation based values.
 *
 * @example English translation of a water meter:
 * {
 *   languageCode: 'en',
 *   value: 'Water meter',
 * }
 */
export interface ITranslation {
  /**
   * Defines the two-letter ISO 639-1 language code of the translation e.g. 'de', 'en',... .
   */
  languageCode: string;

  /**
   * Defines the translation value itself e.g. 'Wasserzähler', 'Water meter',... .
   */
  value: string;
}

/**
 * Generates the translations for the default languages.
 *
 * @param translationDE The german translation value.
 * @param translationEN The english translation value.
 *
 * @returns The translations for the default languages.
 */
export function generateDefaultTranslations(
  translationDE: string,
  translationEN: string,
): ITranslation[] {
  return [
    { languageCode: 'en', value: translationEN },
    { languageCode: 'de', value: translationDE },
  ];
}

/**
 * Gets the translation value by the given language code.
 *
 * @param translations The translations to get the translation value from.
 * @param languageCode The language code to get the translation value from.
 *
 * @returns The translation value by the given language code.
 */
export function getTranslationValueByLanguageCode(
  translations: ITranslation[],
  languageCode: string | undefined,
): string {
  const translation = translations?.find(t => t.languageCode === (languageCode || 'en'));

  if (!translation || !translation.value) {
    return '';
  }

  return translation.value;
}

/**
 * The method returns a number indicating whether a reference translations comes before, or after, or is the
 * same as the given compare translation in sort order.
 *
 * @param referenceTranslations The reference string against which the compareTranslations are compared.
 * @param compareTranslations The translations against which the referenceTranslations are compared.
 * @param languageCode The language code to compare the translation values.
 *
 * @returns A number indicating whether a reference string comes before, or after, or is the
 *          same as the given string in sort order.
 */
export function localCompareTranslations(
  referenceTranslations: ITranslation[],
  compareTranslations: ITranslation[],
  languageCode: string | undefined,
): number {
  const originalTranslationValue = getTranslationValueByLanguageCode(
    referenceTranslations,
    languageCode,
  );
  const comparableTranslationValue = getTranslationValueByLanguageCode(
    compareTranslations,
    languageCode,
  );

  return originalTranslationValue.localeCompare(
    comparableTranslationValue,
    languageCode,
  );
}
