import React from 'react';
import classNames from 'classnames';
import { SUCCESS_STATES, WARNING_STATES, DANGER_STATES } from './SystemHealth';

interface SystemHealthIndicatorProps {
  state: string;
}

const SystemHealthIndicator = (props: SystemHealthIndicatorProps) => {
  const { state } = props;

  return (
    <div
      className={classNames({
        'health-card-indicator-success': SUCCESS_STATES.includes(state),
        'health-card-indicator-warning': WARNING_STATES.includes(state),
        'health-card-indicator-danger': DANGER_STATES.includes(state),
      })}
    >
      ●
    </div>
  );
};

export default SystemHealthIndicator;
