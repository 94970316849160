import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { removeDeviceFromDB, removeDeviceGroupFromDB, } from '../../api/apiHelpers';
import { AppState } from '../../state/reducers/rootReducer';
import DataRow from '../DeviceManager/Mobile/DataRow';
import DeviceGroupItem from './List/DeviceGroupItem';
import FilterBar from '../../components/Filter/FilterBar';
import ErrorBoundary from '../../components/ErrorBoundary';
import DeviceActionModal from '../DeviceManager/DeviceActionModal/DeviceActionModal';
import RenderOnCondition from '../../components/RenderOnCondition';
import DeviceExportModal from '../DeviceManager/DeviceExport/DeviceExportModal';
import MainGrid from '../../components/shared/MainGrid';
import { TreeNodeType } from '@wiot/shared-domain/domain/device-tree/tree-node';
import { Device } from '@wiot/shared-domain/models/device/device';
import { fetchSubTreeActionCreator, refreshCurrentSubTree } from '../../state/device-hierarchy/fetchSubTreeActionCreator';
import useTranslation from '../../hooks/useTranslation';
import PropertyViewSettingsModal from '../PropertyView/Consumption/Table/PropertyViewSettingsModal';
import { selectIsPropertySettingsModalVisible } from '../../state/property-view/propertySelectors';
import { handleDeviceGroupDeletionResponse } from '../../state/device-group/deviceGroupRemovalResponseHandler';

const DeviceHierarchy = () => {
  const dispatch = useDispatch();
  const translate = useTranslation();

  const filter = useSelector((state: AppState) => state.filters.filter['device-manager']);

  const pageHeaderEl = useRef(null);
  const [breadCrumbTopPosition, setBreadCrumbTopPosition] = useState<'fixed' | 'relative'>(
    'relative',
  );
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeviceExportModal, setShowDeviceExportModal] = useState(false);

  const subTreeRoot = useSelector((state: AppState) => state.deviceHierarchySubTree.root);
  const subTreeChildren = useSelector((state: AppState) => state.deviceHierarchySubTree.children);
  const deviceHierarchyIsOutdated = useSelector((state: AppState) => state.deviceHierarchySubTree.isDeviceHierarchyOutdated);
  const isPropertySettingsModalVisible = useSelector((state: AppState) => selectIsPropertySettingsModalVisible(state));

  useEffect(() => {
    dispatch(fetchSubTreeActionCreator(undefined));
  }, [filter]);

  useEffect(() => {
    if (deviceHierarchyIsOutdated) {
      dispatch(fetchSubTreeActionCreator(undefined));
    }
  }, [deviceHierarchyIsOutdated]);

  useEffect(() => {
    const onScroll = () => {
      if (pageHeaderEl && pageHeaderEl.current) {
        // @ts-ignore
        const topPosition = pageHeaderEl.current.getBoundingClientRect().top;
        if (topPosition < 17) {
          // header height 45px, page Title height 28px 45px-28px = 17px
          setBreadCrumbTopPosition('fixed');
        } else {
          setBreadCrumbTopPosition('relative');
        }
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const removeDeviceUnit = async (id: string, refresh = true) => {
    try {
      const response = await removeDeviceFromDB(id);
      if (response && refresh) {
        dispatch(fetchSubTreeActionCreator(undefined));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const removeDeviceGroupUnit = async (id: string) => {
    const response = await removeDeviceGroupFromDB(id);
    const isDeviceGroupRemoved = handleDeviceGroupDeletionResponse(translate, response);
    if (!isDeviceGroupRemoved) {
      return;
    }
    dispatch(refreshCurrentSubTree());
  };

  const getBreadCrumb = () => {
    if (!subTreeRoot) {
      return null;
    }

    const currentSubTreeRoot = subTreeRoot.innerObject as DeviceGroup;
    const breadCrumbItems: { id: string, name: string }[] = [];
    currentSubTreeRoot.ancestors?.map(ancestor => breadCrumbItems.push({ id: ancestor.id!, name: ancestor.name }));
    breadCrumbItems.push({ id: currentSubTreeRoot.id!, name: currentSubTreeRoot.name! });

    return breadCrumbItems.map((treeNode, index) => (
      <button
        key={ index }
        className="form__button--blue page__device-hierarchy__breadcrumb"
        onClick={ () => dispatch(fetchSubTreeActionCreator(treeNode.id)) }
      >
        { treeNode.name }
      </button>
    ));
  };

  return (
    <MainGrid dataTestId="page-device-manager">
      <ErrorBoundary>
        <FilterBar
          page="device-manager"
          handleAddBtnClick={ () => setShowAddModal(true) }
          handleDeviceExportBtnClick={ () => setShowDeviceExportModal(true) }
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <div ref={ pageHeaderEl } className="page-header-wrapper">
          <h3 className="page-title" data-testid="header-page-title">
            <Translate id="device-hierarchy"/>
          </h3>
          <div className={ breadCrumbTopPosition === 'fixed' ? 'breadcrumb-fixed' : '' }>
            <button
              className="form__button--blue mr-3"
              onClick={ () => dispatch(fetchSubTreeActionCreator(subTreeRoot!.parentId!)) }
              disabled={ !subTreeRoot }
            >
              <FontAwesomeIcon icon={ faArrowUp } className="navigation-icon"/>
            </button>
            { getBreadCrumb() }
          </div>
        </div>
        { subTreeChildren.map((treeNode, index) =>
          (treeNode.type === TreeNodeType.DEVICE_GROUP) ? (
            <DeviceGroupItem
              key={ `device-group-item-${ index }` }
              deviceGroup={ (treeNode.innerObject as DeviceGroup) }
              removeUnit={ removeDeviceGroupUnit }
              handleStepInto={ () => dispatch(fetchSubTreeActionCreator(treeNode.id)) }
              onItemChanged={ () => dispatch(refreshCurrentSubTree()) }
            />
          ) : (
            <DataRow
              key={ `device-group-data-row-${ index }` }
              device={ (treeNode.innerObject as Device) }
              removeUnit={ removeDeviceUnit }
              refreshData={ () => dispatch(refreshCurrentSubTree()) }
            />
          ),
        ) }
      </ErrorBoundary>
      <RenderOnCondition condition={ showAddModal }>
        <DeviceActionModal
          closeAddAndUpdateModal={ () => setShowAddModal(false) }
          title="add-device"
          showDeleteButton={ false }
          addUnit
          refreshDevices={ () => dispatch(refreshCurrentSubTree()) }
        />
      </RenderOnCondition>
      <RenderOnCondition condition={ showDeviceExportModal }>
        <DeviceExportModal handleClose={ () => setShowDeviceExportModal(false) }/>
      </RenderOnCondition>

      <RenderOnCondition condition={ isPropertySettingsModalVisible }>
        <PropertyViewSettingsModal/>
      </RenderOnCondition>
    </MainGrid>
  );
};

export default withLocalize(DeviceHierarchy);
