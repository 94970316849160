import { ProtocolType } from '@wiot/shared-domain/models/device/device';
import { getProtocolType, getRadioType } from '../pages/DeviceManager/DeviceActionModal/metaDataUtilityFunctions';
import { RadioType } from './device-meta-data';
import { IDeviceFormData } from '../pages/DeviceManager/DeviceActionModal/DeviceActionModal';
import { DeviceFields } from '@wiot/shared-domain/models/device/device-fields';
import { DeviceExtended } from '../state/types';

type DeviceModalTranslationIds = {
  [protocolType in ProtocolType]: {
    [deviceField: string]: {
      [radioType: string]: string
    };
  };
};

export const deviceModalTranslationIds: DeviceModalTranslationIds = {
  [ProtocolType.OMS_DEVICE]: {
    [DeviceFields.DEVICE_NAME]: {
      [RadioType.NO_RADIO]: 'device-name',
      [RadioType.OMS_WIRELESS_DEVICE]: 'device-name',
      [RadioType.OMS_RADIO_EXTENSION]: 'device-name',
    },
    [DeviceFields.DEVICE_ID]: {
      [RadioType.NO_RADIO]: 'device-id',
      [RadioType.OMS_WIRELESS_DEVICE]: 'radioId',
      [RadioType.OMS_RADIO_EXTENSION]: 'radioId',
    },
    [DeviceFields.DEVICE_VERSION]: {
      [RadioType.NO_RADIO]: 'device-version',
      [RadioType.OMS_WIRELESS_DEVICE]: 'device-version',
      [RadioType.OMS_RADIO_EXTENSION]: 'device-version',
    },
    [DeviceFields.RADIO_TYPE]: {
      [RadioType.NO_RADIO]: 'device-type',
      [RadioType.OMS_WIRELESS_DEVICE]: 'device-type',
      [RadioType.OMS_RADIO_EXTENSION]: 'device-type',
    },
    [DeviceFields.RADIO_KEY]: {
      [RadioType.NO_RADIO]: 'key',
      [RadioType.OMS_WIRELESS_DEVICE]: 'radio-key',
      [RadioType.OMS_RADIO_EXTENSION]: 'radio-key',
    },
    [DeviceFields.MANUFACTURER]: {
      [RadioType.NO_RADIO]: 'manufacturer',
      [RadioType.OMS_WIRELESS_DEVICE]: 'manufacturer',
      [RadioType.OMS_RADIO_EXTENSION]: 'manufacturer',
    },
  },
  [ProtocolType.OMS_GATEWAY]: {
    [DeviceFields.DEVICE_NAME]: {
      [RadioType.OMS_GATEWAY]: 'device-name',
    },
    [DeviceFields.DEVICE_ID]: {
      [RadioType.OMS_GATEWAY]: 'radioId',
    },
    [DeviceFields.DEVICE_VERSION]: {
      [RadioType.OMS_GATEWAY]: 'device-version',
    },
    [DeviceFields.RADIO_KEY]: {
      [RadioType.OMS_GATEWAY]: 'radio-key',
    },
    [DeviceFields.MANUFACTURER]: {
      [RadioType.OMS_GATEWAY]: 'manufacturer',
    },
  },
  [ProtocolType.LORA_ABP_DEVICE]: {
    [DeviceFields.DEVICE_NAME]: {
      [RadioType.LORA_ABP]: 'device-name',
    },
    [DeviceFields.DEVICE_ID]: {
      [RadioType.LORA_ABP]: 'dev_addr',
    },
    [DeviceFields.DEVICE_VERSION]: {
      [RadioType.LORA_ABP]: 'device-version',
    },
    [DeviceFields.RADIO_KEY]: {
      [RadioType.LORA_ABP]: 'app_s_key',
    },
    [DeviceFields.MANUFACTURER]: {
      [RadioType.LORA_ABP]: 'manufacturer',
    },
    [DeviceFields.SECONDARY_RADIO_KEY]: {
      [RadioType.LORA_ABP]: 'nwk_s_key',
    },
  },
  [ProtocolType.LORA_GATEWAY]: {
    [DeviceFields.DEVICE_NAME]: {
      [RadioType.LORA_GATEWAY]: 'device-name',
    },
    [DeviceFields.DEVICE_ID]: {
      [RadioType.LORA_GATEWAY]: 'gateway_eui',
    },
    [DeviceFields.DEVICE_VERSION]: {
      [RadioType.LORA_GATEWAY]: 'device-version',
    },
    [DeviceFields.RADIO_KEY]: {
      [RadioType.LORA_GATEWAY]: 'radio-key',
    },
    [DeviceFields.MANUFACTURER]: {
      [RadioType.LORA_GATEWAY]: 'manufacturer',
    },
  },
  [ProtocolType.LORA_OTAA_DEVICE]: {
    [DeviceFields.DEVICE_NAME]: {
      [RadioType.LORA_OTAA]: 'device-name',
    },
    [DeviceFields.DEVICE_ID]: {
      [RadioType.LORA_OTAA]: 'dev_eui',
    },
    [DeviceFields.DEVICE_VERSION]: {
      [RadioType.LORA_OTAA]: 'device-version',
    },
    [DeviceFields.RADIO_KEY]: {
      [RadioType.LORA_OTAA]: 'app_key',
    },
    [DeviceFields.MANUFACTURER]: {
      [RadioType.LORA_OTAA]: 'manufacturer',
    },
    [DeviceFields.JOIN_EUI]: {
      [RadioType.LORA_OTAA]: 'join_eui',
    },
  },
};

export const getTranslationIdFromProtocolType = (device: IDeviceFormData | DeviceExtended, field: string): string => {
  const radioType: RadioType = getRadioType(device.deviceMetadata?.deviceInfo) || RadioType.OMS_WIRELESS_DEVICE;

  const translationsByProtocolType = deviceModalTranslationIds[getProtocolType(device.deviceMetadata, radioType)];

  const deviceFieldTranslations = translationsByProtocolType[field];
  if (!deviceFieldTranslations) {
    return field;
  }
  return deviceFieldTranslations[radioType];
};
