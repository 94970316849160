import { connect } from 'react-redux';
import { AppState } from '../../state/reducers/rootReducer';

interface DesktopProps {
  children: JSX.Element;
  isMobileDisplay: boolean;
}

const Desktop = (props: DesktopProps) => (props.isMobileDisplay ? null : props.children);

const mapStateToProps = (state: AppState) => ({
  isMobileDisplay: state.isMobileDisplay,
});

export default connect(mapStateToProps)(Desktop);
