import React from 'react';
import { components, OptionTypeBase } from 'react-select';
import { ValueContainerProps } from 'react-select/src/components/containers';
import RenderOnCondition from '../../../RenderOnCondition';

export const ValueContainer = <OptionType extends OptionTypeBase> (props: ValueContainerProps<OptionType>) => {
  const { children, selectProps } = props;
  const { inputValue, placeholder } = selectProps;
  return (
    <components.ValueContainer
      { ...props }
      className="select__value-container text-overflow-ellipsis"
    >
      { children }
      <RenderOnCondition condition={ !inputValue }>
        <div className="select__label">{ placeholder }</div>
      </RenderOnCondition>
    </components.ValueContainer>
  );
}
