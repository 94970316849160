import React from 'react';
import { Form, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import PrimaryButton from '../shared/PrimaryButton';
import useTranslation from '../../hooks/useTranslation';
import { CustomFieldInput } from '../Table/CustomFieldInput';
import * as Yup from 'yup';
import { GetPasswordValidation } from '../shared/YupValidators';
import { CustomFieldAdvancedInput } from '../Table/CustomFieldAdvancedInput';
import { UserCreationDefinition } from '@wiot/shared-domain/models/device/user-creation-definition';


interface RegisterFromProps {
  onFormSubmit: (userCreationDefinition: UserCreationDefinition) => void;
}

function RegisterForm({onFormSubmit}:RegisterFromProps) {
  const translate = useTranslation();

  return (
    <>
      <Formik
        initialValues={ {
          firstName: '',
          lastName: '',
          city: '',
          street: '',
          email: '',
          password: '',
          passwordConfirmation: '',
        } as UserCreationDefinition }
        validationSchema={ Yup.object().shape({
          password: GetPasswordValidation(),
          passwordConfirmation: GetPasswordValidation().oneOf(
            [ Yup.ref('password'), null ],
            translate('passwords-must-match').toString(),
          ),
        }) }
        onSubmit={ onFormSubmit }
      >
        { ({ values, errors }) => (
          <Form className="text-left" id="registerForm">
            <div className="form__section mb-0">
              <Row>
                <Col lg={ 6 }>
                  <CustomFieldInput
                    required
                    translationId="first-name"
                    fieldName="firstName"
                    value={ values.firstName }
                  />
                </Col>
                <Col lg={ 6 }>
                  <CustomFieldInput
                    required
                    translationId="last-name"
                    fieldName="lastName"
                    value={ values.lastName }
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={ 6 }>
                  <CustomFieldInput
                    translationId="street"
                    fieldName="street"
                    value={ values.street }
                    required
                  />
                </Col>
                <Col lg={ 6 }>
                  <CustomFieldInput
                    translationId="city"
                    fieldName="city"
                    value={ values.city }
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomFieldInput
                    translationId="email"
                    fieldName="email"
                    value={ values.email }
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="my-1">
                    <CustomFieldAdvancedInput
                      type="password"
                      fieldName="password"
                      value={ values.password }
                      translationId="password"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="my-2">
                    <CustomFieldAdvancedInput
                      type="password"
                      fieldName="passwordConfirmation"
                      value={ values.passwordConfirmation }
                      translationId="confirm-password"
                    />
                  </div>
                </Col>
              </Row>
              <div className="mt-3">
                <PrimaryButton
                  textTranslationId="create-user"
                  formNameToSubmitOnClick="registerForm"
                />
              </div>
            </div>
          </Form>
        ) }
      </Formik>

    </>
  );
}

export default RegisterForm;
