export enum DeviceFields {
  RADIO_TYPE = 'radio_type',
  DEVICE_NAME = 'deviceName',
  DEVICE_ID = 'deviceId',
  JOIN_EUI = 'joinEui',
  DEVICE_VERSION = 'deviceVersion',
  RADIO_KEY = 'radioKey',
  SECONDARY_RADIO_KEY = 'secondaryRadioKey',
  MANUFACTURER = 'manufacturer',
}
