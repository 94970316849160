import * as React from 'react';

interface DividerProps {
  width?: number;
}

export const Divider = ({ width = 20 }: DividerProps): JSX.Element => (
  <div style={{ width: `${width}px` }} />
);

export const HorizontalDivider = ({ width = 100 }: DividerProps): JSX.Element => (
  <div className="horizontal-divider" style={{ width: `${width}%` }} />
);
