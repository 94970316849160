import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { useParams } from 'react-router-dom';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../Footer/Footer';
import { AppState } from '../../state/reducers/rootReducer';
import ErrorBoundary from '../ErrorBoundary';
import RenderOnCondition from '../RenderOnCondition';
import OfflineBanner from '../OfflineBanner';
import api from '../../api/api';
import Navbar from '../Navbar/Navbar';
import Desktop from '../Responsive/Desktop';
import _ from 'lodash';
import { Breakpoints } from '../../state/types';
import { updateIsMobileDisplay } from '../../state/actions/responsiveAction';
import MainGrid from '../../components/shared/MainGrid';
import DeviceKeyDownloadBody from './DeviceKeyDownloadBody';
import { Alert } from 'react-bootstrap';

const IS_DEVICE_KEY_DOWNLOAD_PAGE_ENABLED = false;

function DeviceKeyDownloadPage() {
  const dispatch = useDispatch();
  const { downloadHash } = useParams<{ downloadHash: string }>();
  const currentUser = useSelector((appState: AppState) => appState.currentUser.user);
  const isOffline = useSelector((appState: AppState) => appState.isOffline);
  const logo = `${ api.baseAPIUrl }${ api.customizeLogo }`;

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  }, []);

  // TODO: Lodash library should be substituted/removed
  const handleResize =_.debounce(() => {
    dispatch(updateIsMobileDisplay(window.innerWidth <= Breakpoints.LG));
  }, 400);

  const getDeviceKeyDownloadDisabledInfo = () => (
    <Alert
      variant="warning"
      className="text-center standard-font-size m-5 p-5"
    >
      <p><Translate id="feature-not-available" /></p>
      <p><Translate id="scanning-of-qr-codes-disabled" /></p>
    </Alert>
  );

  return (
    <>
      <RenderOnCondition condition={ isOffline }>
        <OfflineBanner/>
      </RenderOnCondition>
      <div className="app" data-testid="app" style={ { paddingTop: isOffline ? '35px' : 0 } }>
        <ErrorBoundary>
          <br/>
          <header className="header" data-testid="header">
            <>
              <div className="centered w-100">
                <img src={ logo } alt="logo" className="header__brand"/>
                <div className="ml-1">
                  <Translate id="device-key-download"/>
                </div>
              </div>
              <div>
                { currentUser && !currentUser.isAnonymous && (
                  <div className="centered">
                    <FontAwesomeIcon icon={ faUser } size="sm" className="header__user--user"/>
                    <div className="userName ml-2 mr-1">{ currentUser.displayName }</div>
                  </div>
                ) }
              </div>
            </>
          </header>
          <Desktop>
            <Navbar showNavigationLinks={ false }/>
          </Desktop>
          <MainGrid>
            {IS_DEVICE_KEY_DOWNLOAD_PAGE_ENABLED
              ? <DeviceKeyDownloadBody downloadHash={ downloadHash }/>
              : getDeviceKeyDownloadDisabledInfo()
            }
          </MainGrid>
          <Footer/>
        </ErrorBoundary>
      </div>
    </>
  );
}

export default connect()(DeviceKeyDownloadPage);
