import 'react-datepicker/dist/react-datepicker.css';

import * as React from 'react';
import { ButtonToolbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useHistory } from 'react-router';

import { setFilter } from '../../state/filter/set-filter-action-creator';
import { PageKeys } from '../../state/reducers/filterSortReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../state/reducers/rootReducer';
import { FilterField } from './filter-field';
import useTranslation from '../../hooks/useTranslation';
import { MeasurementFrequency } from '@wiot/shared-domain/models/device-reading/measurement';
import { generatePropertyViewPath } from '../../pages/PropertyView/PropertyViewPathUtils';

interface FilterBillingFrequencyToggleProps {
  field: FilterField;
  pageKey: PageKeys;
}

export const FilterBillingFrequencyToggle = (props: FilterBillingFrequencyToggleProps) => {
  const { field, pageKey } = props;
  const dispatch = useDispatch();
  const translate = useTranslation();
  const filter = useSelector((state: AppState) => state.filters.filter);
  const history = useHistory();

  const selected = (filter[pageKey] as any)[field.stateField];

  const handleBillingPeriodUnitChange = (newValue: MeasurementFrequency) => {
    const path = generatePropertyViewPath({
      billingFrequency: newValue,
    });
    history.push(path);

    dispatch(
      setFilter({
        page: pageKey,
        values: { [field.stateField]: newValue },
      }),
    );
  };

  return (
    <div className="filterbar__item d-flex justify-content-end">
      <ButtonToolbar>
        <ToggleButtonGroup type="radio" name="options" value={selected} onChange={handleBillingPeriodUnitChange}>
          <ToggleButton variant="secondary" value={MeasurementFrequency.MONTH}>{ translate('year').toString() }</ToggleButton>
          <ToggleButton variant="secondary" value={MeasurementFrequency.DAY}>{ translate('month').toString() }</ToggleButton>
        </ToggleButtonGroup>
      </ButtonToolbar>
    </div>
  );
};
