/**
 *
 * Custom Accordion Item
 * The bootstrap Version v1 does not know Accordion.Item, Accordion.Body, Accordion.Header,
 * instead it uses Card.Header, Card.Body and Accordion.Toggle and Accordion.Collapse.
 *
 */

import * as React from "react";
import { ReactElement, useState } from 'react';
import {Accordion, Card, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronRight} from "@fortawesome/free-solid-svg-icons";

export type AccordionItemProps = {
  eventKey: string;
  header: React.ReactElement;
  body: React.ReactElement;
  className?: string;
}

export function AccordionItem({
  eventKey,
  header,
  body,
  className
} : AccordionItemProps): React.ReactElement {

  const [isVisible, setVisibility] = useState(false);

  return (
    <Card>
      <Accordion.Toggle as={Card.Header}
        eventKey={eventKey}
        className={ className || 'custom-accordion custom-accoridion__header'}
        onClick={ () => setVisibility(!isVisible) }
      >
        <div>
          <Row>
            <Col className="text-left">
              { header }
            </Col>
            <Col xs={1} className="text-right">
              <FontAwesomeIcon icon={ isVisible ? faChevronDown : faChevronRight } />
            </Col>
          </Row>
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse
        eventKey={eventKey}
        className="custom-accordion custom-accoridion__body"
      >
        <Card.Body className="custom-accordion__card__body">
          { body }
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}
