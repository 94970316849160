import React from 'react';
import { Popup } from 'react-leaflet';
import L from 'leaflet';
import { Translate } from 'react-localize-redux';
import RenderOnCondition from '../../../components/RenderOnCondition';
import DeviceStatusPil from '../DeviceStatusPil';
import { IGeoDevice } from './geo-device';

interface IMapViewPopupProps {
  device: IGeoDevice;
}

const MapViewPopup = (props: IMapViewPopupProps) => {
  const { device } = props;
  return (
    <Popup>
      <div>
        <div className="row">
          <div className="col">{device.name}</div>
        </div>
        <div className="row">
          <div className="col">
            <Translate id="serial-number-short" />
          </div>
          <div className="col">{device.deviceId}</div>
        </div>
        <RenderOnCondition condition={device.status?.length}>
          <div className="row">
            <div className="col">
              <Translate id="status" />
            </div>
            <div className="col">
              <DeviceStatusPil id={`map-view-popup-device-status-pil-${ device.id }`} status={device.status!} tooltipPlace="left" />
            </div>
          </div>
        </RenderOnCondition>
      </div>
    </Popup>
  );
};

export default MapViewPopup;
