import { __ } from 'i18n';
import { TranslatableMessage } from '../localization/translatable-message';

export class BaseError extends Error {
  public readonly name: string;

  public readonly statusCode: number;

  public readonly isOperational: boolean;

  /**
   * Determines whether the message of this error has already been translated and
   * therefore does not need to be translated.
   */
  public readonly isMessageAlreadyTranslated: boolean;

  constructor(
    name: string,
    httpCode: number,
    message: string | TranslatableMessage,
    isOperational: boolean,
  ) {
    if (typeof message === 'string') {
      super(message);
    } else {
      super(__(message.translationTerm, message.translatePlaceholderData));
      this.isMessageAlreadyTranslated = true;
    }
    Object.setPrototypeOf(this, new.target.prototype);

    this.name = name;
    // If you decide to rename the 'statusCode' (like to 'httpStatusCode'),
    // make sure to rename all "any" references to ".statusCode"
    this.statusCode = httpCode;
    this.isOperational = isOperational;
  }

  /**
   * Overwrites the default toJSON method of an object, because default replacer of the JSON.stringify()
   * method removes the `message` property.
   * See https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
   */
  toJSON(): object {
    return {
      name: this.name,
      message: this.message,
      statusCode: this.statusCode,
      isOperational: this.isOperational,
    };
  }
}
