/*
Using react-onclickoutside for functional components throws an error TypeError: Cannot read property
'isReactComponent' of undefined while rendering in Tests.
https://github.com/Pomax/react-onclickoutside/issues/297
https://github.com/Pomax/react-onclickoutside/issues/329

So this is a work around or a better way of using ClickOutside using a wrapper component.
https://github.com/Pomax/react-onclickoutside/issues/316
*/

import * as React from 'react';
import onClickOutside, { OnClickOutProps } from 'react-onclickoutside';

export class XOutsideClickHandler extends React.Component<OnClickOutProps<any>> {
  public handleClickOutside: (event: React.MouseEvent) => void = (event: React.MouseEvent) => {
    this.props.onClickOutside(event);
  };

  override render = () => this.props.children;
}

export const OutsideClickHandler: React.ComponentClass<OnClickOutProps<any>> = onClickOutside(
  XOutsideClickHandler,
);
