import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import ConfirmModal, { ConfirmationVariant } from '../../../components/Modal/ConfirmModal';
import { getDeviceGroupPath, NO_ANCESTORS_PATH } from '../../../utils/device-group-path-helper';
import RenderOnCondition from '../../../components/RenderOnCondition';
import KeyDevicesModal from '../KeyDevicesModal';
import { RadioKeyExtended } from '../../../state/types';
import KeyActionModal from '../KeyActionModal';
import TableMenu from '../Table/TableMenu';
import { AppState } from '../../../state/reducers/rootReducer';
import { getMenuId } from '../../../components/PortalMenu/constants/context-menu-ids';
import { closeContextMenu, openContextMenu } from '../../../state/context-menu/toggleContextMenuActionCreators';

import { DeviceGroupPath } from '../../../utils/device-group-path';
import { DEVICE_COUNT_HEADER, DEVICE_GROUP_HEADER, KEY_TYPE_HEADER, RADIO_KEY_TABLE_HEADERS } from '../radio-key-table-headers';

interface IDataRow {
  radioKey: RadioKeyExtended;
  deviceCount: number | null;
  removeUnit: (id: string) => Promise<void>;
  refreshData: (column?: IColumnObject) => void;
}

const DataRow = (props: IDataRow) => {
  const menuButtonRef = useRef(null);
  const [showKeyDevices, setShowKeyDevices] = useState(false);
  const [showKeyActionModal, setShowKeyActionModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const visibleContextMenu = useSelector((state: AppState) => state.contextMenu.visibleContextMenuId);

  const dispatch = useDispatch();

  const toggleShowKeyDevices = (event?: React.MouseEvent) => {
    event && event.preventDefault();
    setShowKeyDevices((prevState) => !prevState);
  };

  const menuId = getMenuId(props.radioKey.id);
  const showMenu = (visibleContextMenu === menuId);
  const toggleMenu = () => {
    if (!showMenu) {
      dispatch(openContextMenu(menuId));
    } else {
      dispatch(closeContextMenu());
    }
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prevState) => !prevState);
  };

  const toggleKeyActionModal = () => {
    setShowKeyActionModal((prevState) => !prevState);
  };

  const getSelectedTree = (): DeviceGroupPath => {
    const { radioKey } = props;

    if (radioKey.deviceGroup && typeof radioKey.deviceGroup === 'string') {
      return {
        fullPath: '',
        truncatedPath: radioKey.deviceGroup,
      };
    }
    if (radioKey.deviceGroup) {
      const deviceGroupPath = getDeviceGroupPath(radioKey.deviceGroup);
      return deviceGroupPath;
    }
    return NO_ANCESTORS_PATH;
  };

  const getKeyType = (totalDevices?: number | null) => {
    if (totalDevices && totalDevices > 1) {
      return 'MULTI-KEY';
    }

    if (totalDevices && totalDevices === 1) {
      return 'SINGLE-KEY';
    }
    return 'NO-KEY';
  };

  const { id, name } = props.radioKey;
  const { deviceCount } = props;
  const keyType = getKeyType(deviceCount);
  const { truncatedPath } = getSelectedTree();

  return (
    <div className="radio-key-data-card-container">
      <Row className="radio-key-data-card-container__header">
        <div className="radio-key-data-card-container__header__title text-overflow-ellipsis">{name || '-'}</div>
      </Row>
      <Row className="radio-key-data-card-container__body">
        <Col xs={4} className="radio-key-data-card-container__key">
          <Translate id={DEVICE_GROUP_HEADER} />
          {':'}
        </Col>
        <Col xs={8}>{truncatedPath || '-'}</Col>
      </Row>
      <Row className="radio-key-data-card-container__body">
        <Col xs={4} className="radio-key-data-card-container__key">
          <Translate id={DEVICE_COUNT_HEADER} />
          {':'}
        </Col>
        <Col xs={8}>{deviceCount}</Col>
      </Row>
      <Row className="radio-key-data-card-container__body">
        <Col xs={4} className="radio-key-data-card-container__key">
          <Translate id={KEY_TYPE_HEADER} />
          {':'}
        </Col>
        <Col xs={8}>
          <Translate id={keyType} />
        </Col>
      </Row>
      <Row className="radio-key-data-card-container__footer justify-content-center">
        <Col xs={9} className="text-center">
          <RenderOnCondition condition={deviceCount}>
            <span className="radio-details-btn" onClick={toggleShowKeyDevices}>
              <Translate id="device-list" />
            </span>
            <RenderOnCondition condition={showKeyDevices}>
              <KeyDevicesModal handleClose={toggleShowKeyDevices} keyName={name || ''} keyId={id} />
            </RenderOnCondition>
          </RenderOnCondition>
        </Col>
        <Col xs={3} ref={menuButtonRef}>
          <div className="radio-details">
            <div className="radio-details-icon" role="presentation" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </div>
            <RenderOnCondition condition={showMenu}>
              <TableMenu
                isMobileDisplay
                refreshData={props.refreshData}
                removeUnit={props.removeUnit}
                menuId={menuId}
                toggleKeyActionModal={toggleKeyActionModal}
                toggleDeleteModal={toggleDeleteModal}
              />
            </RenderOnCondition>

            <RenderOnCondition condition={showDeleteModal}>
              <ConfirmModal
                modalCloseRequested={() => toggleDeleteModal()}
                actionConfirmed={() => props.removeUnit(id)}
                translationIdOfElementType="key"
                confirmationVariant={ ConfirmationVariant.DELETE }
              />
            </RenderOnCondition>

            <RenderOnCondition condition={showKeyActionModal}>
              <KeyActionModal
                closeAddAndUpdateModal={toggleKeyActionModal}
                title="update-key"
                showDeleteButton
                addUnit={false}
                removeUnit={(itemId: string) => props.removeUnit(itemId)}
                id={id}
                refreshData={props.refreshData}
              />
            </RenderOnCondition>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DataRow;
