import * as React from 'react';
import { Translate } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import RenderOnCondition from '../RenderOnCondition';
import ColumnSelectionModal from '../../pages/DeviceManager/Table/ColumnSelectionModal';
import { Tooltip } from '../shared/Tooltip';

export interface TableDataHeaderProps {
  withCheckbox: boolean;
  withColumnSelection?: boolean;
  tableHeaders: IColumnObject[];
  markAllRowsAsSelected?: (event: React.FormEvent<HTMLInputElement>) => void;
  isSelectAllChecked?: boolean;
  handleColumnsChange?: (updatedColumns: IColumnObject[]) => void;
}

const TableDataHeader = (props: TableDataHeaderProps) => {
  const [showColumnSelection, setShowColumnSelection] = useState(false);
  const {
    isSelectAllChecked,
    tableHeaders,
    withColumnSelection,
    handleColumnsChange,
  } = props;
  const visibleColumns = tableHeaders.filter((header) => header.visible);

  return (
    <>
      <thead
        data-testid="device-manager-data-table-theaders"
        className="device-manager__table__table__thead top-0"
      >
        <tr>
          {props.withCheckbox && (
            <th className="device-manager__table__table__theader device-manager__table__table__theader__checkbox">
              <label className="custom-checkbox custom-checkbox--header">
                <input
                  readOnly
                  type="checkbox"
                  onClick={props.markAllRowsAsSelected}
                  checked={isSelectAllChecked}
                />
                <span className="checkmark border-color-main" />
              </label>
            </th>
          )}
          {visibleColumns.map(({ name, sort, sortable }) => (
            <th
              key={name}
              className={`device-manager__table__table__theader device-manager__table__table__theader__${name}`}
            >
              <RenderOnCondition condition={name !== 'actions'}>
                <Translate id={name} />
              </RenderOnCondition>
              <RenderOnCondition condition={withColumnSelection && name === 'actions'}>
                <div
                  className="ellipsis text-color-main"
                  role="presentation"
                  data-tip="dev-mgr-column-selection-button"
                  data-for="dev-mgr-column-selection-button"
                  onClick={() => setShowColumnSelection(true)}
                >
                  <FontAwesomeIcon icon={faColumns} />
                </div>
                <Tooltip id="dev-mgr-column-selection-button">
                  <Translate id="select-columns" />
                </Tooltip>
              </RenderOnCondition>
            </th>
          ))}
        </tr>
      </thead>
      <RenderOnCondition condition={withColumnSelection && showColumnSelection}>
        {handleColumnsChange ? (
          <ColumnSelectionModal
            columns={tableHeaders}
            handleClose={() => setShowColumnSelection(false)}
            saveColumns={handleColumnsChange}
          />
        ) : null}
      </RenderOnCondition>
    </>
  );
};

export default TableDataHeader;
