import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { LocalizeContextProps, Translate, withLocalize, } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import AddLicenses from './AddLicenses';
import LicenseUsagesList from './LicenseUsagesList';
import LicensesList from './LicensesList';
import { fetchLicensingOverview } from '../../../state/licensing/fetchLicensingOverview';

/**
 * Props of the {@link LicensingOverview} component.
 */
export interface LicensesOverviewProps extends LocalizeContextProps {
  /**
   * Determines whether the component should be displayed in read-only mode,
   * meaning the user will not be able to add licenses.
   */
  readOnly: boolean;
}

/**
 * A component that displays the overview of the licensing of the platform and allows the user to add licenses.
 */
const LicensingOverview = ({ readOnly }: LicensesOverviewProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLicensingOverview());
  }, []);

  return (
    <div>
      <div className="settings-header">
        <Translate id="update-and-licenses-page-licenses" />
      </div>
      <Row>
        <Col
          sm={2}
          lg={3}
        >
          <label className="settings-form__label">
            <Translate id="license-usages" />
          </label>
        </Col>
        <Col
          sm={6}
          lg={9}
        >
          <LicenseUsagesList />
        </Col>
      </Row>
      <AddLicenses readOnly={readOnly} />
      <Row>
        <Col
          sm={2}
          lg={3}
        >
          <label className="settings-form__label">
            <Translate id="all-licenses" />
          </label>
        </Col>
        <Col
          sm={6}
          lg={9}
        >
          <LicensesList />
        </Col>
      </Row>
    </div>
  );
};

export default withLocalize(LicensingOverview);
