import React, { ReactElement } from 'react';

interface IRenderOnCondition {
  condition: any;
  children: ReactElement | ReactElement[] | null;
}

const RenderOnCondition = ({ condition, children }: IRenderOnCondition) => {
  if (condition) {
    return <>{children}</>;
  }
  return null;
};

export default RenderOnCondition;
