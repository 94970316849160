import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';

import { FeedbackAttachment } from './feedback';

import useTranslation from '../../hooks/useTranslation';
import { openFeedbackModal } from '../../state/feedback/feedbackActionCreators';

export interface OpenFeedbackModalButtonProps {
  getFeedbackAttachment: () => FeedbackAttachment | null;
}

export function OpenFeedbackModalButton(props: OpenFeedbackModalButtonProps): React.ReactElement {
  const { getFeedbackAttachment } = props;

  const dispatch = useDispatch();
  const translate = useTranslation();

  return (
    <button
      type="button"
      className="details__button--feedback"
      title={ translate('send-feedback').toString() }
      onClick={ () => dispatch(openFeedbackModal(getFeedbackAttachment())) }
    >
      <FontAwesomeIcon
        icon={ faHeadset }
        className={ "details__button--feedback--icon" }
      />
    </button>
  );
}
