import React from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { Col, Row } from 'react-bootstrap';
import { getIn } from 'formik';
import { toastr } from 'react-redux-toastr';
import { IRole } from '@wiot/shared-domain/models/role/role';
import { SetFieldValue } from '../../../state/types';
import { CustomFieldInput } from '../../../components/Table/CustomFieldInput';
import { momentUnits } from '../../../constants';
import RenderOnCondition from '../../../components/RenderOnCondition';
import { SelectWrapperWithFormik } from '../../../components/FilterBar/select/SelectWrapperWithFormik';
import { Tooltip } from '../../../components/shared/Tooltip';

interface IMessageRoleGroup extends LocalizeContextProps {
  groupKey: string;
  values: IRole;
  setFieldValue: SetFieldValue;
  labelKey: string;
  readOnly?: boolean;
}

const MessageRoleGroup = ({
  groupKey,
  values,
  setFieldValue,
  labelKey,
  readOnly,
  translate,
}: IMessageRoleGroup) => {
  const amount: number | undefined = getIn(values, `${groupKey}.amount`);
  const unit: string | undefined = getIn(values, `${groupKey}.unit`);
  const groupPath = groupKey.split('.');
  // Check if 'view' is checked for deviceType
  const show: boolean = getIn(values, `${groupPath.slice(0, groupPath.length - 1).join('.')}.view`);

  const validateNumber = (value: any) => {
    if (unit !== 'all' && unit !== undefined) {
      if (Number.isNaN(value) || value <= 0) {
        const field = translate(groupPath[groupPath.length - 1]);
        const warning = `${translate('invalid-user-input').toString()}:\n ${field} ${translate(
          'invalid',
        )}.`;
        toastr.warning(translate('ERROR').toString(), warning);
        return translate('number-must-be-positive').toString();
      }
    }
  };

  const getTooltipContent = (tooltipLabelKey: string) => {
    switch (tooltipLabelKey) {
      case 'period':
        return <Translate id="messages-period-description" />;
      default:
        return <Translate id="messages-frequency-description" />;
    }
  };

  return (
    <>
      {show && (
        <Row data-tip="" data-for={`${groupKey}-${labelKey}`}>
          <Col lg={3} className="pt-2 align-self-center">
            <label className="form__label">
              <Translate id={labelKey} />
            </label>
          </Col>
          <Col lg={3} className="pt-2">
            <RenderOnCondition condition={readOnly && unit !== 'all'}>
              <label className="form__label">{amount}</label>
            </RenderOnCondition>
            <RenderOnCondition condition={!readOnly && unit !== 'all'}>
              <CustomFieldInput
                type="number"
                fieldName={`${groupKey}.amount`}
                value={amount}
                validate={validateNumber}
                hideLabel
                readOnly={readOnly}
              />
            </RenderOnCondition>
          </Col>
          <Col lg={3} className="pt-2">
            <SelectWrapperWithFormik
              fieldName={`${groupKey}.unit`}
              options={momentUnits}
              value={unit}
              defaultValue={momentUnits[0]}
              onChange={setFieldValue}
              labelKey={labelKey}
              hideLabel
              readOnly={readOnly}
              isClearable={false}
              translateOptions={true}
            />
          </Col>
          {labelKey === 'frequency' && (
            <Col lg={3} className="pt-2 align-self-center">
              <label className="form__label">
                <Translate id="value-s" />
              </label>
            </Col>
          )}
          <Tooltip id={`${groupKey}-${labelKey}`} delayShow={400}>
            {getTooltipContent(labelKey)}
          </Tooltip>
        </Row>
      )}
    </>
  );
};

export default withLocalize(MessageRoleGroup);
