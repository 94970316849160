import React, { useMemo, useRef } from 'react';
import { Marker } from 'react-leaflet';
import { LatLng, LatLngExpression, Marker as LeafletMarker } from 'leaflet';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import { leafletDeviceTypeIcon } from './icons';

interface IDraggableMarkerProps {
  deviceType?: IDeviceType;
  position: LatLngExpression;
  handlePositionChange: (latLng: LatLng) => void;
}

const DraggableMarker = ({ deviceType, position, handlePositionChange }: IDraggableMarkerProps) => {
  const markerRef = useRef<LeafletMarker>(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          handlePositionChange(marker.getLatLng());
        }
      },
    }),
    [handlePositionChange],
  );

  return (
    <Marker
      draggable
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
      icon={leafletDeviceTypeIcon(deviceType)}
    />
  );
};

export default DraggableMarker;
