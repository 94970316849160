import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { AppState } from '../../../../state/reducers/rootReducer';
import { setFilter } from '../../../../state/filter/set-filter-action-creator';
import { PageKeys } from '../../../../state/reducers/filterSortReducer';
import { FilterFieldKeys } from '../../../../components/Filter/filter-field-keys';
import useTranslation from '../../../../hooks/useTranslation';
import { MBusDeviceType } from '@wiot/shared-domain/models/device-types/m-bus-device-types';
import { Tooltip } from '../../../../components/shared/Tooltip';

type Props = {
  icon: IconDefinition;
  pageKey: PageKeys;
  stateField: FilterFieldKeys;
  mBusDeviceTypeId?: MBusDeviceType;
  onClick?: () => void;
};
export const FilterSelectButton = (props: Props) => {
  const { icon, pageKey, stateField, mBusDeviceTypeId, onClick } = props;
  const dispatch = useDispatch();
  const translate = useTranslation();
  const filter = useSelector((state: AppState) => state.filters.filter);

  const selected = (filter[pageKey] as any)[stateField];

  const handleButtonClick = async () => {
    dispatch(
      setFilter({
        page: pageKey,
        values: {
          [stateField]: mBusDeviceTypeId,
        },
      }),
    );
    if (onClick) {
      onClick();
    }
  };

  const getIconStyle = () => {
    const color = selected === mBusDeviceTypeId ? 'var(--main-color-lighter)' : '';

    return { color };
  };

  if (!mBusDeviceTypeId) {
    return null;
  }

  return (
    <>
      <Tooltip
        id="filter-select-button-tooltip"
        className="type-dark"
      />
      <button
        className="export__button"
        onClick={handleButtonClick}
        data-tip={ translate(`${ stateField }-graph`) }
      >
        <FontAwesomeIcon
          style={ getIconStyle() }
          icon={ icon }
        />
      </button>
    </>
  );
};
