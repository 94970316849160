import { RadioKey } from '@wiot/shared-domain/models/radio-key/radio-key';
import { SAVE_KEYS, TOGGLE_SELECT_ALL_KEYS, TOGGLE_SELECT_KEY } from '../constants/actionTypes';
import { KeysActionTypes } from '../types';

export const saveKeys = (keys: RadioKey[] = []): KeysActionTypes => ({
  type: SAVE_KEYS,
  payload: keys,
});

export const toggleSelectAllKeys = (isChecked: boolean): KeysActionTypes => ({
  type: TOGGLE_SELECT_ALL_KEYS,
  payload: isChecked,
});

export const toggleSelectKey = (keyId: string): KeysActionTypes => ({
  type: TOGGLE_SELECT_KEY,
  payload: keyId,
});
