import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export class ApiCallError extends Error implements AxiosError {
  isAxiosError: boolean;

  message: string;

  code?: string;

  request?: any;

  response?: AxiosResponse;

  config: AxiosRequestConfig;

  constructor(name: string, message = 'Unknown Error', statusCode?: number) {
    super(message);

    this.isAxiosError = false;
    this.name = name;
    this.message = message;
    this.code = statusCode?.toString();
  }

  toJSON(): object {
    return { name: this.name, message: this.message, status: this.response?.status };
  }
}
