import React from 'react';
import Col from 'react-bootstrap/Col';
import { Translate } from 'react-localize-redux';
import {
  authMethodOptions as defaultAuthMethodOptions,
  DataIntegrationApiFormats,
  DataIntegrationConfigs,
} from '@wiot/shared-domain/models/data-integration/data-integration';
import RenderOnCondition from '../../../../components/RenderOnCondition';
import { SetFieldValue } from '../../../../state/types';
import CustomFieldSelect from '../../../../components/Table/CustomFieldSelect';
import { CustomFieldInput } from '../../../../components/Table/CustomFieldInput';
import PullFrequencySelect from './PullFrequencySelect';
import DescriptionInput from '../Shared/DescriptionInput';
import IsActiveButton from '../Shared/IsActiveButton';
import AuthSection from './AuthSection';
import ExecuteJobButton from './ExecuteJobButton';
import { IntegrationModalState } from '../IntegrationActionModal';
import { validateRequiredFieldValue } from '../../../../utils/validation';
import GroupSelect from '../../../../components/shared/GroupSelect';
import Portal from '../../../../components/shared/Portal';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';

export interface IApiSectionProps {
  refreshData: () => void;
  setFieldValue: SetFieldValue;
  selectedMethod: DataIntegrationApiFormats;
  formikValues: IntegrationModalState;
  isApiValid: boolean;
}

const ApiSection = (props: IApiSectionProps) => {
  const { formikValues, setFieldValue, selectedMethod, refreshData, isApiValid } = props;
  const { api } = formikValues;

  const selectedConfig = DataIntegrationConfigs[selectedMethod];

  const handleDeviceGroupChange = (deviceGroup: DeviceGroup | undefined) => {
    setFieldValue('api.deviceGroup', deviceGroup, false);
  };

  return (
    <>
      <div className="form__row">
        <DescriptionInput api={ api }/>
        <IsActiveButton api={ api }/>
      </div>
      <div className="form__row">
        <Col lg={ 12 }>
          <CustomFieldInput
            validate={ validateRequiredFieldValue }
            required
            translationId="api-path"
            fieldName="api.apiPath"
            value={ api.apiPath || '' }
            readOnly={ isApiValid }
            placeholder={ selectedConfig.apiPlaceholder }
          />
        </Col>
      </div>
      <div className="form__row">
        <Col lg={ 4 }>
          <CustomFieldSelect
            validate={ validateRequiredFieldValue }
            required
            translationId="authentication"
            fieldName="api.authMethod"
            options={ defaultAuthMethodOptions
              .filter((option) => selectedConfig.authMethod.includes(option))
              .map((option) => ({
                id: option,
                name: option,
              })) }
            translateOptions
            readOnly={ isApiValid }
            value={ api.authMethod || '' }
            onChange={ (event: React.ChangeEvent<HTMLSelectElement>) => {
              const target = (event?.currentTarget || event?.target);
              if (target) {
                const targetName = target.name;
                const targetValue = target.value;
                if (targetName && targetValue) {
                  setFieldValue(targetName, targetValue);
                }
              }
            } }

            portalTargetId="modal-authentication-method-select-portal"
          />
          <Portal>
            <div id="modal-authentication-method-select-portal"/>
          </Portal>
        </Col>
        <AuthSection api={ api } isApiValid={ isApiValid } selectedMethod={ selectedMethod }/>
      </div>
      <div className="form__row">
        <PullFrequencySelect api={ api } setFieldValue={ setFieldValue }/>
        <RenderOnCondition
          condition={
            [
              DataIntegrationApiFormats.MyDatanet,
              DataIntegrationApiFormats.CasametaCloud,
              DataIntegrationApiFormats.LoraTTN,
            ].includes(selectedMethod) &&
            isApiValid &&
            api.id
          }
        >
          <ExecuteJobButton api={ api } refreshData={ refreshData }/>
        </RenderOnCondition>
      </div>
      <RenderOnCondition condition={ selectedMethod === DataIntegrationApiFormats.CasametaCloud }>
        <div className="form__row">
          <Col>
            <div className="form__label my-3">
              <Translate id="choose-group-that-should-sync-with-casameta-cloud-box"/>:
            </div>
            <GroupSelect
              onSelectionChangedSingleSelect={ (deviceGroup) => handleDeviceGroupChange(deviceGroup) }
              preSelectedDeviceGroupId={ api.deviceGroup?.id || '' }
              required
              targetId="group-select-create-dataIntegrationJob"
            />
          </Col>
        </div>
      </RenderOnCondition>
    </>
  );
};

export default ApiSection;
