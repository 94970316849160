import * as React from 'react';
import { connect } from 'react-redux';
import { IRadioKeyDeviceCounts, RadioKey } from '@wiot/shared-domain/models/radio-key/radio-key';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import TableDataHeader from '../../../components/Table/TableDataHeader';
import NoResultsInfo from '../../../components/Table/NoResultsInfo';
import RenderOnCondition from '../../../components/RenderOnCondition';
import { AppState } from '../../../state/reducers/rootReducer';
import TableDataRow from './TableDataRow';
import { Tooltip } from '../../../components/shared/Tooltip';
import { RADIO_KEY_TABLE_HEADERS } from '../radio-key-table-headers';

export interface TableDataProps {
  keys: RadioKey[];
  markOneKeyAsSelected: (id: string) => void;
  markAllKeysAsSelected: (event: React.FormEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  refreshData: (column?: IColumnObject) => void;
  removeUnit: (id: string) => Promise<void>;
  isSelectAllChecked: boolean;
  deviceCounts: IRadioKeyDeviceCounts;
}

export interface TableDataState {
  columns: IColumnObject[];
}

class TableData extends React.Component<TableDataProps, TableDataState> {
  constructor(props: TableDataProps) {
    super(props);
    this.state = {
      columns: [],
    };
  }

  componentDidMount = () => {
    this.initState();
  };

  initState = () => {
    const columnState = RADIO_KEY_TABLE_HEADERS.map((headerName: string) => ({
      name: headerName,
      visible: true,
      sortable: true,
    }));

    this.setState({ columns: columnState });
  };

  render() {
    if (this.props.isLoading) {
      return <LoadingIcon/>;
    }

    const showResults = (this.props.keys && this.props.keys.length);

    return (
      <div className="device-manager__table__table__container">
        <Tooltip
          id="device-manager-table-data-keys-tooltip"
          className="type-dark z-index-1000"
        />
        <table className="device-manager__table__table">
          <TableDataHeader
            withCheckbox
            tableHeaders={this.state.columns}
            markAllRowsAsSelected={this.props.markAllKeysAsSelected}
            isSelectAllChecked={this.props.isSelectAllChecked}
          />
          <tbody>
            <RenderOnCondition condition={showResults}>
              { this.props.keys.map((radioKey: RadioKey) => (
                <TableDataRow
                  refreshData={this.props.refreshData}
                  radioKey={radioKey}
                  key={radioKey.id}
                  deviceCount={this.props.deviceCounts && this.props.deviceCounts[radioKey.id]}
                  selectOne={this.props.markOneKeyAsSelected}
                  removeUnit={this.props.removeUnit}
                />
              ))}
            </RenderOnCondition>

          </tbody>
        </table>
        <RenderOnCondition condition={!showResults}>
          <NoResultsInfo />
        </RenderOnCondition>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  keys: state.keys,
  isLoading: state.isLoading,
});

export default connect(mapStateToProps)(TableData);
