export const REMOVE_DEVICE = 'REMOVE_DEVICE';
export const DEVICE_REMOVED = 'DEVICE_REMOVED';

export interface RemoveDeviceAction {
  type: typeof REMOVE_DEVICE;
  id: string;
}

export interface DeviceRemovedAction {
  type: typeof DEVICE_REMOVED;
  id: string;
}
