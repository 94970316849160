import React from 'react';
import { Translate } from 'react-localize-redux';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import RenderOnCondition from './RenderOnCondition';

interface CustomFileInputProps {
  options: DropzoneOptions;
  label: string;
  isDisabled?: boolean;
}

const CustomFileInput = ({ options, label, isDisabled }: CustomFileInputProps) => {
  const { getRootProps, getInputProps } = useDropzone(options);

  return (
    <div>
      <RenderOnCondition condition={label}>
        <div className="form__label">
          <Translate id={label} />
        </div>
      </RenderOnCondition>
      <div {...getRootProps({ className: 'dropzone-root' })}>
        <Translate id={options.multiple ? 'choose-files' : 'choose-file'} />
        <input {...getInputProps()} disabled={isDisabled} />
      </div>
    </div>
  );
};

export default CustomFileInput;
