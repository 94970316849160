import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getValueWithUnitIfAvailable } from '../../utils/common';
import RenderOnCondition from '../RenderOnCondition';
import { MonthlyValuesWithDifferenceToLastMonth } from '@wiot/shared-domain/models/device/historical-value';

interface ConsumptionGraphProps extends LocalizeContextProps {
  consumptionValues: MonthlyValuesWithDifferenceToLastMonth;
  hideTitle?: boolean;
  responsiveContainerHeight?: number;
}

const ConsumptionGraph = (props: ConsumptionGraphProps) => {
  const { consumptionValues: { values }, hideTitle, responsiveContainerHeight = 150, translate } = props;

  function getUnit(): string {
    if (!values[0].unit) {
      return '';
    }

    return `(${ values[0].unit })`;
  }

  return (
    <>
      <RenderOnCondition condition={!hideTitle}>
        <Row>
          <Col className="text-center">
            { translate('consumption') }
            { getUnit() }
          </Col>
        </Row>
      </RenderOnCondition>
      <ResponsiveContainer className='mt-2' width="100%" height={ responsiveContainerHeight }>
        <BarChart
          margin={ {
            left: -10,
          } }
          data={ values }
        >
          <CartesianGrid strokeDasharray="3 3"/>
          <YAxis dataKey="differenceToLastMonthlyValue"/>
          <XAxis tickLine={ false } dataKey="atDate"/>
          <Tooltip
            formatter={ (value: string | number | React.ReactText[]) =>
              [getValueWithUnitIfAvailable(value, values[0].unit)]
            }
          />
          <Bar type="monotone" dataKey="differenceToLastMonthlyValue" fill="var(--main-color)"/>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default withLocalize(ConsumptionGraph);
