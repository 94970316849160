import { DeviceKeyExportFileFormat } from '@wiot/shared-domain/models/device-key/device-keys-export-file-format';

export const FETCH_DEVICE_KEYS_AS_FILE = 'FETCH_DEVICE_KEYS_AS_FILE';
export const DEVICE_KEYS_FILE_FETCHED = 'DEVICE_KEYS_FILE_FETCHED';
export const RESET_DEVICE_KEYS_FILE = 'RESET_DEVICE_KEYS_FILE';

export interface FetchDeviceKeysAsFileAction {
  type: typeof FETCH_DEVICE_KEYS_AS_FILE;
  fileFormat: DeviceKeyExportFileFormat;
}

export interface DeviceKeysFileFetchedAction {
  type: typeof DEVICE_KEYS_FILE_FETCHED;
  fileContent: Blob;
  fileFormat: DeviceKeyExportFileFormat;
}

export interface ResetDeviceKeysFileAction {
  type: typeof RESET_DEVICE_KEYS_FILE;
}
