import React from 'react';
import { components, ControlProps, OptionTypeBase } from 'react-select';

export const Control = <OptionType extends OptionTypeBase> (props: ControlProps<OptionType>) => {
  const { children, selectProps } = props;
  const firstChild = React.Children.toArray(children)[0];
  const otherChildren = React.Children.toArray(children).splice(1);
  const { readOnly, isClearable, classNamePrefix } = selectProps;

  return (
    <components.Control
      className={`${ classNamePrefix }__control ${ readOnly ? 'select-disabled' : '' }` }
      { ...props }
    >
      <div
        className="select__control__container"
      >
        <div
          className={
            isClearable && !readOnly
              ? 'select__control__left-aligned-clearable'
              : 'select__control__left-aligned-dropdown'
          }
        >
          { firstChild }
        </div>
        <div className="select__control__right-aligned">
          { otherChildren }
        </div>
      </div>
    </components.Control>
  );
}
