import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { v4 as uuidv4 } from 'uuid';
import { DeviceReading } from '@wiot/shared-domain/models/device-reading/device-reading';
import { Device } from '@wiot/shared-domain/models/device/device';
import { generateDefaultTranslations } from '@wiot/shared-domain/models/localization/translation';
import { IDeviceRole, IMessageRole } from '@wiot/shared-domain/models/role/role';
import { filterOutSensitiveDataFromDevice } from '../../../components/Feedback/filter-sensitive-data';

import ModalHeader from '../../../components/Modal/ModalHeader';
import Gallery from '../../../components/Gallery/Gallery';
import {
  addDeviceImages,
  appendFilesToFormData,
  deleteDeviceImage,
  fetchDeviceFromDB,
  fetchLatestMessagesForDeviceFromDB,
  getDeviceImages,
  updateDeviceInDB,
} from '../../../api/apiHelpers';
import { AppState } from '../../../state/reducers/rootReducer';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import OcrWizard from '../../../components/Ocr/OcrWizard';
import DeviceDetailsBody from './DeviceDetailsBody';
import DeviceChangeOptions from '../DeviceChangeOptions';
import DeviceActionModal from '../DeviceActionModal/DeviceActionModal';
import { hasPermission } from '../../../utils/common';
import { getDeviceGroupPath, getDeviceGroupPathOfDevice } from '../../../utils/device-group-path-helper';
import { getRandomModalOffset } from '../../../utils/dialog';
import { DeviceExtended } from '../../../state/types';
import { FeedbackAttachment } from '../../../components/Feedback/feedback';
import { ModalTabEventKeys } from '../ModalTabEventKeys';

export enum ChangeDevice {
  REMOVE_DEVICE = 'remove-device-mark-as-removed',
  CREATE_NEW = 'create-new-device-and-change',
  USE_EXISTING = 'use-existing-device-and-change',
}

export interface DeviceDetailsModalProps extends LocalizeContextProps {
  devices: Device[];
  isOffline: boolean;
  closeModal: (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => void;
  deviceId: string;
  ancestorPath?: string;
  isChangeModal?: boolean;
  removeUnit?: (id: string) => void;
  refreshTreeData?: () => void;
  refreshDevices?: () => void;
}

interface DeviceDetails extends DeviceExtended {
  permission?: IDeviceRole;
  messageRole?: IMessageRole;
  latitude: string;
  longitude: string;
  deviceMessages?: DeviceReading[];
  responseStatusCode?: number;
  images?: (string | File)[];
}

export interface DeviceDetailsModalState {
  device: DeviceDetails;
  isLoading: boolean;
  showGallery: boolean;
  showChangeOptions: boolean;
  selectedChangeOption?: ChangeDevice;
  showAddModal: boolean;
  currentImageIndex: number;
  currentFile: string;
  images: (string | File)[];
  imagesToRemove: (string | File)[];
  isOcrLoading: boolean;
  showOcrWizard: boolean;
  ocrFoundData: string[];
  deviceRole?: IDeviceRole;
  messageRole?: IMessageRole;
  randomModalOffset?: { marginTop: number; marginRight: number };
}

class DeviceDetailsModal extends React.Component<DeviceDetailsModalProps, DeviceDetailsModalState> {
  modalUid = uuidv4();

  constructor(props: DeviceDetailsModalProps) {
    super(props);
    this.state = {
      device: {
        id: '',
        name: '',
        deviceId: '',
        deviceType: {
          id: '',
          name: generateDefaultTranslations('', ''),
          isActive: false,
          isSystem: false,
        },
        deviceGroup: undefined,
        latitude: '',
        longitude: '',
        notes: '',
        blacklisted: false,
        deviceGroupAncestors: [''],
        gateways: [],
        manufacturer: undefined,
        deviceMetadata: {
          deviceDescription: [],
          deviceInfo: [],
          radioInfo: [],
          metadata: [],
        },
        status: [],
        radioKey: {
          name: '',
        },
        usersWithAccess: [],
        deviceMessages: [],
      },
      isLoading: true,
      showGallery: false,
      showChangeOptions: false,
      selectedChangeOption: undefined,
      showAddModal: false,
      currentImageIndex: 0,
      currentFile: '',
      images: [],
      imagesToRemove: [],
      isOcrLoading: false,
      showOcrWizard: false,
      ocrFoundData: [''],
      deviceRole: undefined,
      messageRole: undefined,
      randomModalOffset: { marginTop: 0, marginRight: 0 },
    };
  }

  toggleGallery = () => {
    this.setState((prevState) => ({
      showGallery: !prevState.showGallery,
    }));
  };

  onGallerySlideClick = (index: number) => {
    this.setState({ currentImageIndex: index });
  };

  setCurrentFile = (file: string) => {
    this.setState({ currentFile: file });
  };

  toggleChangeOptions = () => {
    this.setState((prevState) => ({
      showChangeOptions: !prevState.showChangeOptions,
    }));
  };

  handleSelectionChange = (selectedChangeOption: ChangeDevice) => {
    this.setState({
      selectedChangeOption,
    });
  };

  toggleShowModal = async (_?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent, newDeviceId?: string) => {
    this.setState((prevState) => ({
      showAddModal: !prevState.showAddModal,
    }));

    newDeviceId && (await this.completeChangeAction(newDeviceId));
  };

  getDeviceMessages = async (deviceId: string) => {
    try {
      const deviceMessages: DeviceReading[] = await fetchLatestMessagesForDeviceFromDB(
        deviceId,
        this.props.isChangeModal ? 3 : 5,
      );
      return deviceMessages;
    } catch (e) {
      return [];
    }
  };

  getDeviceInfo = async (deviceId: string, setActiveTabKey?: React.Dispatch<React.SetStateAction<ModalTabEventKeys>>) => {
    try {
      const device = await fetchDeviceFromDB(deviceId);

      const deviceMessages = await this.getDeviceMessages(deviceId);

      this.setState({
        device: { ...device, deviceMessages },
        deviceRole: device?.permission,
        messageRole: device?.messageRole,
      });

      if (setActiveTabKey) {
        setActiveTabKey(ModalTabEventKeys.BASIC);
      }

      if (hasPermission(device?.permission, 'view.images')) {
        // get images only it has permission
        const images = await getDeviceImages(deviceId);
        this.setState({ images });
      }
      this.setState({ isLoading: false });
    } catch (e) {
      console.error(e);
    }
  };

  getDeviceAncestorPath = () => {
    const deviceGroupPath = getDeviceGroupPathOfDevice(this.state.device);
    return deviceGroupPath.truncatedPath;
  };

  componentDidMount = async () => {
    this.setState({ randomModalOffset: getRandomModalOffset() });
    this.props.deviceId && await this.getDeviceInfo(this.props.deviceId);
  };

  handleClose = (event: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
    this.props.closeModal(event);
  };

  /*
  Image upload and OCR related methods
  */
  onDropFiles = (images: (string | File)[]) => {
    this.setState({ images });
  };

  onImagesRemoved = (imagesToRemove: (string | File)[]) => {
    this.setState((prevState) => ({
      imagesToRemove: [...prevState.imagesToRemove, ...imagesToRemove],
    }));
  };

  toggleOcrLoading = () => {
    this.setState((prevState) => ({
      isOcrLoading: !prevState.isOcrLoading,
    }));
  };

  toggleOcrWizard = () => {
    this.setState((prevState) => ({
      showOcrWizard: !prevState.showOcrWizard,
    }));
  };

  setOcrFoundData = (foundData: string[]) => {
    this.setState({ ocrFoundData: foundData });
  };

  setFieldValue = (field: string, value: any) => {
    this.setState((prevState) => ({
      device: {
        ...prevState.device,
        [field]: value,
      },
    }));
  };

  uploadImageChanges = async () => {
    const { device, images, imagesToRemove } = this.state;
    if (device && device.id) {
      if (this.props.isOffline) {
        const {
          latitude,
          longitude,
          deviceMessages,
          status,
          responseStatusCode,
          deviceGroupAncestors,
          usersWithAccess,
          permission,
          messageRole,
          ...cleanedDevice
        } = device;
        cleanedDevice.images = images;
        if (cleanedDevice.deviceGroup) {
          cleanedDevice.deviceGroup = cleanedDevice.deviceGroup.id;
        }
        await updateDeviceInDB(cleanedDevice);
      } else {
        const uploadedImages = _.filter(images, (image) => typeof image !== 'string');
        const deletedImages = _.filter(imagesToRemove, (image) => typeof image === 'string');

        const imagesForm = appendFilesToFormData(new FormData(), uploadedImages);

        uploadedImages.length > 0 && (await addDeviceImages(device.id, imagesForm));

        if (deletedImages.length > 0) {
          // @ts-ignore
          const deletePromises = deletedImages.map((image: string) => {
            const splitParts = image.split('/');
            const imageId = splitParts[splitParts.length - 1];
            return deleteDeviceImage(imageId);
          });
          await Promise.all(deletePromises);
        }
      }
    }
  };

  completeChangeAction = async (newDeviceId?: string) => {
    // @ts-ignore
    const { id, deviceId } = this.state.device;
    if (id === newDeviceId) {
      toastr.warning(
        this.props.translate('ERROR').toString(),
        this.props.translate('cannot-change-with-same-device').toString(),
      );
      return;
    }
    try {
      if (this.state.selectedChangeOption === ChangeDevice.REMOVE_DEVICE) {
        await updateDeviceInDB({ id, deviceId, isDeprecated: true });
        toastr.success(
          this.props.translate('success').toString(),
          this.props.translate('device-marked-deprecated').toString(),
        );
        this.props.refreshDevices && this.props.refreshDevices();
        this.props.closeModal();
      } else if (this.state.selectedChangeOption === ChangeDevice.CREATE_NEW) {
        if (newDeviceId) {
          const { device: updatedDevice } = await updateDeviceInDB({
            id,
            deviceId,
            isDeprecated: true,
            newDevice: newDeviceId,
          });
          if (updatedDevice) {
            toastr.success(
              this.props.translate('success').toString(),
              this.props.translate('new-device-added-and-linked').toString(),
            );
            this.props.refreshDevices && this.props.refreshDevices();
            this.props.closeModal();
          }
        } else {
          await this.toggleShowModal();
        }
      } else if (this.state.selectedChangeOption === ChangeDevice.USE_EXISTING) {
        if (newDeviceId) {
          const { device: updatedDevice } = await updateDeviceInDB({
            id: newDeviceId,
            oldDevice: id,
          });
          if (updatedDevice) {
            await updateDeviceInDB({
              id,
              deviceId,
              isDeprecated: true,
              newDevice: newDeviceId,
            });
            toastr.success(
              this.props.translate('success').toString(),
              this.props.translate('selected-devices-linked').toString(),
            );
            this.props.refreshDevices && this.props.refreshDevices();
            this.props.closeModal();
          }
        } else {
          toastr.warning(
            this.props.translate('ERROR').toString(),
            this.props.translate('please-select-existing-device').toString(),
          );
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { ancestorPath, isChangeModal } = this.props;
    const {
      showGallery,
      showAddModal,
      currentImageIndex,
      showOcrWizard,
      isOcrLoading,
      device,
      images,
    } = this.state;

    const { deviceId } = device;

    const getModalBody = () => {
      if (this.state.isLoading) {
        return <LoadingIcon />;
      }

      if (this.state.showChangeOptions) {
        return (
          <DeviceChangeOptions
            handleOptionSelection={this.handleSelectionChange}
            selectedChangeOption={this.state.selectedChangeOption}
            handlePrevious={this.toggleChangeOptions}
            completeChangeAction={this.completeChangeAction}
          />
        );
      }
      return (
        <DeviceDetailsBody
          ancestorPath={ancestorPath}
          isChangeModal={isChangeModal}
          device={device}
          getDeviceAncestorPath={this.getDeviceAncestorPath}
          getDeviceInfo={this.getDeviceInfo}
          onDropFiles={this.onDropFiles}
          onImagesRemoved={this.onImagesRemoved}
          // @ts-ignore
          images={images}
          toggleGallery={this.toggleGallery}
          toggleOcrLoading={this.toggleOcrLoading}
          toggleOcrWizard={this.toggleOcrWizard}
          isOcrLoading={isOcrLoading}
          setCurrentFile={this.setCurrentFile}
          setOcrFoundData={this.setOcrFoundData}
          toggleChangeOptions={this.toggleChangeOptions}
          uploadImages={this.uploadImageChanges}
          removeUnit={this.props.removeUnit}
          refreshDevices={this.props.refreshDevices}
          refreshTreeData={this.props.refreshTreeData}
          deviceRole={this.state.deviceRole}
          messageRole={this.state.messageRole}
        />
      );
    };

    const getAttachmentForFeedback = (): FeedbackAttachment | null => {
      if (!device) {
        return null;
      }
      return {
        device: filterOutSensitiveDataFromDevice(device),
      };
    };

    return (
      <div
        tabIndex={0} // make it focusable
        style={this.state.randomModalOffset}
        id={`device-details-${this.modalUid}`}
        className="device-modal"
        onClick={(event: React.MouseEvent) => event.stopPropagation()}
      >
        <ModalHeader
          isDevice
          isDeviceToChange={isChangeModal}
          titleTranslationId="device-details"
          targetId={this.modalUid}
          titlePostfix={deviceId}
          handleClose={this.handleClose}
          enableFeedbackSubmission={true}
          getFeedbackAttachment={getAttachmentForFeedback}
        />
        <div className="device-modal__body details">{getModalBody()}</div>

        {showGallery && (
          <Gallery
            files={images}
            currentIndex={currentImageIndex}
            onGallerySlideClick={this.onGallerySlideClick}
            toggleGallery={this.toggleGallery}
          />
        )}
        {showOcrWizard && (
          <OcrWizard
            isOcrLoading={isOcrLoading}
            values={this.state.device}
            setFieldValue={this.setFieldValue}
            ocrFoundData={this.state.ocrFoundData}
            toggleOcrWizard={this.toggleOcrWizard}
            currentFile={this.state.currentFile}
          />
        )}
        {showAddModal && (
          <DeviceActionModal
            closeAddAndUpdateModal={this.toggleShowModal}
            title="add-device"
            showDeleteButton={false}
            addUnit
            refreshDevices={this.props.refreshDevices}
            refreshTreeData={this.props.refreshTreeData}
            oldDeviceId={device?.id}
            selectedDeviceGroup={device?.deviceGroup}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  devices: state.devices,
  isOffline: state.isOffline,
});

export default connect(mapStateToProps)(withLocalize(DeviceDetailsModal));
