import React, { ReactElement } from 'react';
import { Col } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { MBusDeviceType } from '@wiot/shared-domain/models/device-types/m-bus-device-types';
import RenderOnCondition from '../RenderOnCondition';
import { Tooltip } from './Tooltip';

interface LabeledColProps {
  labelTranslationId: string;
  content?: string | ReactElement | MBusDeviceType;
  lg?: number;
  detailedTextClassName?: String;
  showTooltip?: boolean;
}

export const LabeledCol = ({labelTranslationId, content="", lg=12, showTooltip=false, detailedTextClassName }: LabeledColProps): ReactElement => (
  <>
    <RenderOnCondition condition={!showTooltip}>
      <Col lg={lg}>
        <label className="details__label">
          <Translate id={labelTranslationId}/>
          <p className={`details__text ${detailedTextClassName}`}>{content}</p>
        </label>
      </Col>
    </RenderOnCondition>
    <RenderOnCondition condition={showTooltip}>
      <Col lg={lg}>
        <label className="details__label">
          <Translate id={labelTranslationId}/>
          <p
            className={`details__text ${detailedTextClassName}`}
            data-tip={`label-translation-id-${labelTranslationId}`}
            data-for={`label-translation-id-${labelTranslationId}`}
          >
            {content}
          </p>
          <Tooltip
            id={`label-translation-id-${labelTranslationId}`}
            place="bottom"
          >
            {content}
          </Tooltip>
        </label>
      </Col>
    </RenderOnCondition>
  </>
);
