import { CurrentUserState, UserPermissionActionTypes, SetCurrentUserAction, LogoutAction } from '../types';
import { LOGOUT, SET_CURRENT_USER, SET_USER_PERMISSION } from '../constants/actionTypes';


export const CURRENT_USER_DETAILS_KEY ='currentUserDetails';

let initialState: CurrentUserState = {};
const currentUserJson =localStorage.getItem(CURRENT_USER_DETAILS_KEY);
if(currentUserJson){
  initialState = JSON.parse(currentUserJson);
}

export const currentUserReducer = (
  state: CurrentUserState = initialState,
  action: SetCurrentUserAction | UserPermissionActionTypes| LogoutAction,
): CurrentUserState => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_USER_PERMISSION:
      return {
        ...state,
        permission: action.payload,
      };

    case LOGOUT:
      return { };

    default:
      return state;
  }
};
