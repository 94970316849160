import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { Device } from '@wiot/shared-domain/models/device/device';
import ModalHeader from '../../components/Modal/ModalHeader';
import Portal from '../../components/shared/Portal';
import { isUndefined } from '../../utils/common';
import GroupSelect from '../../components/shared/GroupSelect';
import { SetFieldValue } from '../../state/types';
import { fetchManufacturersFromDB, updateDeviceInDB } from '../../api/apiHelpers';
import { isTableLoading } from '../../state/table/isTableLoadingAction';
import useTranslation from '../../hooks/useTranslation';
import Mobile from '../../components/Responsive/Mobile';
import CustomCheckbox from '../../components/shared/CustomCheckbox';
import Desktop from '../../components/Responsive/Desktop';
import { getRandomModalOffset } from '../../utils/dialog';
import { AppState } from '../../state/reducers/rootReducer';
import RenderOnCondition from '../../components/RenderOnCondition';
import { SelectWrapperWithFormik } from '../../components/FilterBar/select/SelectWrapperWithFormik';
import { SelectableOption } from '../../components/FilterBar/select/selectable-option';
import { Tooltip } from '../../components/shared/Tooltip';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';

interface IDeviceMassEditModalProps {
  title: string;
  closeAddAndUpdateModal: (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
    newDeviceId?: string,
  ) => void;
  selectedDevices?: Device[];
  refreshDevices?: () => void;
}

interface IMassEditData {
  blacklisted: boolean;
  deviceGroup?: string;
  manufacturer?: SelectableOption;
}

const DeviceMassEditModal = (props: IDeviceMassEditModalProps) => {
  const modalUid = uuidv4();
  const dispatch = useDispatch();
  const translate = useTranslation();

  const { title, closeAddAndUpdateModal, selectedDevices, refreshDevices } = props;

  const isKeyManagerModeEnabled = useSelector((state: AppState) => state.siteSettings.isKeyManagerModeEnabled);

  const [randomModalOffset, setRandomModalOffset] = useState({ marginTop: 0, marginRight: 0 });
  const [manufacturerOptions, setManufacturerOptions] = useState<SelectableOption[]>([]);

  useEffect(() => {
    setRandomModalOffset(getRandomModalOffset());
  }, []);

  useEffect(() => {
    const updateManufacturerOptions = async () => {
      const manufacturers = await fetchManufacturersFromDB();
      if (Array.isArray(manufacturers) && manufacturers.length > 0) {
        const options: SelectableOption[] = manufacturers.map((manufacturer) => ({
          label: `${ manufacturer.flagId } - ${ manufacturer.name }`,
          value: manufacturer.flagId,
        }));
        setManufacturerOptions(options);
      }
    };
    updateManufacturerOptions();
  }, []);

  const handleDeviceGroupChange = async (
    deviceGroup: DeviceGroup | undefined,
    setFieldValue: SetFieldValue,
  ) => {
    setFieldValue('deviceGroup', deviceGroup?.id, false);
  };

  const handleMassEdit = async (values: IMassEditData) => {
    dispatch(isTableLoading(true));
    const { deviceGroup, blacklisted, manufacturer: selectedManufacturer } = values;

    const isEmpty = (thing: any) => isUndefined(thing) || thing === null || thing === '';
    // if device group is blacklisted and manufacturer is empty,
    // display toastr message to input a value!
    if (isEmpty(deviceGroup) && isEmpty(blacklisted) && isEmpty(selectedManufacturer)) {
      toastr.warning(
        translate('ERROR').toString(),
        translate('please-enter-at-least-one-value').toString(),
      );
      return;
    }

    if (selectedDevices) {
      const updatePromises = selectedDevices.map((selectedDevice: Device) => {
        const updatedDevice: Partial<Device> = {
          id: selectedDevice.id,
          blacklisted,
        };

        if (deviceGroup) {
          updatedDevice.deviceGroup = deviceGroup;
        }
        if (selectedManufacturer) {
          // @ts-ignore
          updatedDevice.manufacturer = { flagId: selectedManufacturer.value };
        }

        return updateDeviceInDB(updatedDevice);
      });
      try {
        await Promise.all(updatePromises);
        refreshDevices && refreshDevices();
        closeAddAndUpdateModal();
        dispatch(isTableLoading(false));
      } catch (e) {
        closeAddAndUpdateModal();
        dispatch(isTableLoading(false));
      }
    }
  };

  const validationSchema = Yup.object().shape({
    blacklisted: Yup.boolean(),
  });

  return (
    <Portal>
      <div
        tabIndex={0} // make it focusable
        style={randomModalOffset}
        id={`${title}-${modalUid}`}
        className="device-modal"
      >
        <ModalHeader
          isDevice
          titleTranslationId={title}
          handleClose={closeAddAndUpdateModal}
          targetId={modalUid}
        />
        <Formik
          initialValues={{
            deviceGroup: '',
            blacklisted: false,
            manufacturer: undefined,
          }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleMassEdit}
          render={({
            values,
            setFieldValue,
            setFieldTouched,
            errors,
            touched,
          }: FormikProps<IMassEditData>) => (
            <Form className="form" onClick={(event: React.MouseEvent) => event.stopPropagation()}>
              <div className="form__section">
                <div className="form__row">
                  <GroupSelect
                    onSelectionChangedSingleSelect={ (deviceGroup) => handleDeviceGroupChange(deviceGroup, setFieldValue) }
                    preSelectedDeviceGroupId={values.deviceGroup || ''}
                    error={errors.deviceGroup}
                    touched={touched.deviceGroup}
                    targetId={`group-select-${title}`}
                  />
                </div>
              </div>

              <RenderOnCondition condition={!isKeyManagerModeEnabled}>
                <Row>
                  <Col xs={12} className="form__row">
                    <SelectWrapperWithFormik<SelectableOption>
                      label="manufacturer"
                      fieldName={ 'manufacturer' }
                      options={ manufacturerOptions }
                      value={ values.manufacturer }
                      onChange={ setFieldValue }
                      onBlur={ setFieldTouched }
                      error={ errors.manufacturer }
                      touched={ touched.manufacturer }
                      valueKey="value"
                      hideLabel={ false }
                      readOnly={ isKeyManagerModeEnabled }
                      isMulti={ false }
                    />
                  </Col>
                </Row>
              </RenderOnCondition>
              <RenderOnCondition condition={!isKeyManagerModeEnabled}>
                <Mobile>
                  <div className="form__section">
                    <div
                      className="form__blacklist"
                      data-tip="dev-action-modal-blacklist"
                      data-for="dev-action-modal-blacklist"
                    >
                      <label className="form__label">
                        <Translate id="blacklist" />
                        <CustomCheckbox
                          checked={values.blacklisted}
                          onClick={() => setFieldValue('blacklisted', !values.blacklisted)}
                        />
                      </label>
                    </div>
                    <Tooltip
                      id="dev-action-modal-blacklist"
                      place="top"
                    >
                      <Translate id="device-action-blacklist-description" />
                    </Tooltip>
                  </div>
                </Mobile>
              </RenderOnCondition>

              <div className="form__section last">
                <div className="form__row space-between ai">
                  <div className="form__row--left">
                    <RenderOnCondition condition={!isKeyManagerModeEnabled}>
                      <Desktop>
                        <>
                          <div
                            className="form__blacklist"
                            data-tip="dev-action-modal-blacklist"
                            data-for="dev-action-modal-blacklist"
                          >
                            <label className="form__label">
                              <Translate id="blacklist" />
                              <CustomCheckbox
                                checked={values.blacklisted}
                                onChange={() => setFieldValue('blacklisted', !values.blacklisted)}
                              />
                            </label>
                          </div>
                          <Tooltip
                            id="dev-action-modal-blacklist"
                            offset={{ right: 10 }}
                            place="right"
                          >
                            <Translate id="device-action-blacklist-description" />
                          </Tooltip>
                        </>
                      </Desktop>
                    </RenderOnCondition>
                  </div>

                  <div className="form__row--right">
                    <button
                      type="button"
                      className="form__button--cancel"
                      onClick={closeAddAndUpdateModal}
                    >
                      <Translate id="cancel" />
                    </button>
                    <button
                      className="form__button--blue background-color-main text-color-white border-color-main"
                      type="submit"
                    >
                      <Desktop>
                        <Translate id="save-device" />
                      </Desktop>
                      <Mobile>
                        <Translate id="save" />
                      </Mobile>
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        />
      </div>
    </Portal>
  );
};

export default DeviceMassEditModal;
