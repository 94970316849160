import React, { ReactElement } from 'react';
import { Place, Type } from 'react-tooltip';
import { Tooltip } from './shared/Tooltip';

interface TableDataRowTooltipProps {
  id: string ;
  place?: Place;
  delayUntilShow?: number;
  className?: string;
  backgroundColor?: string ;
  themeType?: Type;
  children: ReactElement | ReactElement[] | string | null;
}

const TableDataRowTooltip = ({
  id,
  place = "top",
  delayUntilShow = 400,
  className,
  backgroundColor,
  themeType,
  children,
}: TableDataRowTooltipProps) => (
  <Tooltip
    id={ id }
    place={ place }
    delayShow={ delayUntilShow }
    backgroundColor={ backgroundColor }
    type={ themeType }
    className={ className }
  >
    { children }
  </Tooltip>
);

export default TableDataRowTooltip;
