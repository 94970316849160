import { SelectableOption } from '../components/FilterBar/select/selectable-option';

export function getGroupTypes(
  groupTypeFilter: SelectableOption[] | undefined,
) : string[] | undefined {
  const groupTypes: string[] = [];

  groupTypeFilter?.map((option: SelectableOption) => {
    if (option.value) {
      groupTypes.push(option.value);
    }
  });

  if (groupTypes.length === 0) {
    return undefined;
  }

  return groupTypes;
}
