export const REMOVE_DEVICE_GROUP = 'REMOVE_DEVICE_GROUP';
export const DEVICE_GROUP_REMOVED = 'DEVICE_GROUP_REMOVED';

export interface RemoveDeviceGroupAction {
  type: typeof REMOVE_DEVICE_GROUP;
  id: string;
}

export interface DeviceGroupRemovedAction {
  type: typeof DEVICE_GROUP_REMOVED;
  id: string;
}
