import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { IUser } from '@wiot/shared-domain/models/user/user';
import { filterOutSensitiveDataFromUser } from '../../components/Feedback/filter-sensitive-data';
import ModalHeader from '../../components/Modal/ModalHeader';
import { fetchUserWithRolesFromDB } from '../../api/apiHelpers';
import LoadingIcon from '../../components/shared/LoadingIcon';
import UserRolesTable from './Table/UserRolesTable';
import RenderOnCondition from '../../components/RenderOnCondition';
import { getRandomModalOffset } from '../../utils/dialog';
import { LabeledCol } from '../../components/shared/LabeledCol';
import { UserRole } from '@wiot/shared-domain/models/user/user-role';
import { FeedbackAttachment } from '../../components/Feedback/feedback';
import useTranslation from '../../hooks/useTranslation';

interface UserDetailsModalProps {
  id: string;
  closeModal: (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => void;
}

const UserDetailsModal = (props: UserDetailsModalProps): JSX.Element => {
  const translate = useTranslation();
  const modalUid = uuidv4();
  const { id, closeModal } = props;

  const [randomModalOffset, setRandomModalOffset] = useState({ marginTop: 0, marginRight: 0 });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userDetails, setUserDetails] = useState<IUser>();
  const [userRoles, setUserRoles] = useState<UserRole[]>();

  useEffect(() => {
    setRandomModalOffset(getRandomModalOffset());
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      setIsLoading(true);
      const user = await fetchUserWithRolesFromDB(id);
      setUserDetails(user);
      setUserRoles(user.roles);
      setIsLoading(false);
    };
    fetchUserDetails();
  }, [id]);

  const handleClose = (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
    event && closeModal(event);
  };

  if (isLoading) {
    return <LoadingIcon />;
  }

  const getAttachmentForFeedback = (): FeedbackAttachment | null => {
    if (!userDetails) {
      return null;
    }
    return {
      user: filterOutSensitiveDataFromUser(userDetails),
    };
  };

  const userAccountType = userDetails?.isAnonymous ? translate('anonymous-user').toString() : translate('normal-user').toString();

  return (
    <div
      tabIndex={0} // make it focusable
      style={randomModalOffset}
      className="device-modal"
      id={`details-of-user-${modalUid}`}
    >
      <ModalHeader
        isDevice={false}
        titleTranslationId="details-of-user"
        targetId={modalUid}
        titlePostfix={userDetails?.firstName}
        handleClose={handleClose}
        enableFeedbackSubmission={true}
        getFeedbackAttachment={ getAttachmentForFeedback }
      />
      <div className="device-modal__body details">
        <div className="details__section">
          <Row>
            <LabeledCol
              labelTranslationId={'user-type'}
              content={ userAccountType }
              lg={12}
            />
          </Row>
          <RenderOnCondition condition={userDetails?.isAnonymous}>
            <Row>
              <LabeledCol
                labelTranslationId={'name-or-description'}
                content={ userDetails?.name || '-' }
                lg={6}
              />
              <LabeledCol
                labelTranslationId={'user-id'}
                content={ userDetails?.id || '-' }
                lg={6}
              />
            </Row>
          </RenderOnCondition>
          <RenderOnCondition condition={!userDetails?.isAnonymous}>
            <Row>
              <LabeledCol
                labelTranslationId={'first-name'}
                content={ userDetails?.firstName || '-' }
                lg={6}
              />
              <LabeledCol
                labelTranslationId={'last-name'}
                content={ userDetails?.lastName || '-' }
                lg={6}
              />
            </Row>
          </RenderOnCondition>
          <Row>
            <LabeledCol
              labelTranslationId={'email'}
              content={ userDetails?.email || '-' }
              lg={12}
            />
          </Row>
          <Row>
            <LabeledCol
              labelTranslationId={'street'}
              content={ userDetails?.street || '-' }
              lg={6}
            />
            <LabeledCol
              labelTranslationId={'city'}
              content={ userDetails?.city || '-' }
              lg={6}
            />
          </Row>
        </div>
        <div className="details__section">
          <h4 className="details__headings">
            {userRoles?.length} <Translate id="access-right" />
          </h4>
          <UserRolesTable userRoles={userRoles!} />
        </div>
      </div>
    </div>
  );
};

export default UserDetailsModal;
