import * as React from 'react';
import { ImpulseSpinner } from 'react-spinners-kit';

interface TableLoadingIconProps {
  size?: number;
  groupLoading?: boolean;
}

const TableLoadingIcon = (props: TableLoadingIconProps) => (
  <div className={props.groupLoading ? 'loading-icon__group' : 'loading-icon centered-both-axis'}>
    <ImpulseSpinner frontColor="var(--main-color)" size={props.size || 100} />
  </div>
);

export default TableLoadingIcon;
