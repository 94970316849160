import React from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';

import { BlobWithPath } from '@wiot/shared-domain/models/data-integration/data-integration';
import { CustomFieldAdvancedInput } from '../../../../../components/Table/CustomFieldAdvancedInput';

interface IXmlOmsMasterKeyInputProps extends LocalizeContextProps {
  omsXmlMasterKey?: string;
  file: BlobWithPath;
}

const XmlOmsMasterKeyInput = (props: IXmlOmsMasterKeyInputProps) => {
  const { omsXmlMasterKey, translate, file } = props;
  return (
    <>
      <h4 className="form__subheading">
        <Translate id="file" />: {file.path}{' '}
      </h4>
      <div className="form__label my-3">
        <Translate id="enter-oms-xml-master-decryption-key" />:
      </div>
      <CustomFieldAdvancedInput
        type="password"
        fieldName="omsXmlMasterKey"
        inputFontFamily="monospace"
        maxLength={32}
        minLength={32}
        translationId="master-key"
        value={omsXmlMasterKey}
        showEyeButton
        onKeyPress={(e) => {
         if (e.key === 'Enter') e.preventDefault();
        }}
        required
        showCopyButton
      />
    </>
  );
};

export default withLocalize(XmlOmsMasterKeyInput);
