import React, { useRef } from 'react';
import Clipboard from 'react-clipboard.js';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastr } from 'react-redux-toastr';
import RenderOnCondition from "../RenderOnCondition";
import useTranslation from '../../hooks/useTranslation';

interface CopyButtonProps {
  copyValue: string;
  hideIcon?: boolean;
  iconColor?: string;
  children?: any;
}

const CopyButton = (props: CopyButtonProps) => {
  const {
    copyValue,
    hideIcon,
    iconColor,
    children,
  } = props;

  const translate = useTranslation();

  const onSuccess = () => {
    toastr.success(translate('copy').toString(), translate('copy-clipboard-success').toString());
  };

  const onError = () => {
    toastr.error(translate('copy').toString(), translate('copy-clipboard-error').toString());
  };

  // TODO(PS) Remove the hidden button hack
  // There is a problem with the Clipboard component.
  // If we bind the  onSuccess={ onSuccess } then the translate function does not work.
  const hiddenButtonOnSuccess = React.useRef(null);
  const onSuccessHiddenButtonClick = () => {
    // @ts-ignore
    hiddenButtonOnSuccess.current.click();
  };

  return (
    <>
      <button type="button" hidden onClick={onSuccess} ref={hiddenButtonOnSuccess} />
      <Clipboard
        onError={onError}
        onSuccess={onSuccessHiddenButtonClick}
        data-clipboard-text={copyValue}
        className="form__input__copy"
        title={translate('copy').toString()}
      >
        {children}
        <RenderOnCondition condition={!hideIcon}>
          <FontAwesomeIcon
            icon={faClipboardList}
            size="lg"
            className="form__input__copy__icon"
            color={iconColor}
          />
        </RenderOnCondition>
      </Clipboard>
    </>
  );
};

export default CopyButton;
