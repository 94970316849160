import React from 'react';
import ClosingIcon from '../shared/ClosingIcon';

interface ITableMenuCloseButtonProps {
  closeMenu: (event: React.MouseEvent) => void;
}

// Displays only on screens @media(tablet-down)
const TableMenuCloseButton = (props: ITableMenuCloseButtonProps) => (
  <>
    <div className="td-menu__closing-icon" onClick={props.closeMenu}>
      <ClosingIcon stroke="black" />
    </div>
  </>
);

export default TableMenuCloseButton;
