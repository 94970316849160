import { Dispatch } from 'redux';
import { fetchDeviceTypeFromDB } from '../../api/apiHelpers';
import { setFilter } from './set-filter-action-creator';

export const updateDeviceManagerFilter = () => async (
  dispatch: Dispatch<any>,
): Promise<void> => {
  // Override device manager filter from URL search params
  const urlParams = new URLSearchParams(window.location.search);
  const deviceTypeId = urlParams.get('deviceType');
  if (deviceTypeId) {
    const deviceTypeObj = await fetchDeviceTypeFromDB(deviceTypeId);
    if (deviceTypeObj) {
      dispatch(
        setFilter({
          page: 'device-manager',
          values: {
            deviceTypes: [ deviceTypeObj ],
          },
        }),
      );
    }
  }
  const deviceStatus = urlParams.get('deviceStatus');
  if (deviceStatus) {
    dispatch(
      setFilter({
        page: 'device-manager',
        values: {
          status: [
            {
              id: deviceStatus,
              name: deviceStatus,
            },
          ],
        },
      }),
    );
  }
};
