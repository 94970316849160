import { Dispatch } from 'redux';
import { DeviceKey } from '@wiot/shared-domain/models/device/device';
import { DownloadDetails } from '@wiot/shared-domain/models/device-key/download-details';
import {
  DeviceKeyDownloadDetailsFetchedAction,
  FetchDeviceKeyDownloadDetailsAction,
  HideOrShowAllKeysAction,
  HideOrShowDeviceKeyAction,
} from '../types';
import { getDeviceKeyDownloadDetails } from '../../api/deviceKeyService';
import {
  DEVICE_KEY_DOWNLOAD_DETAILS_FETCHED,
  FETCH_DEVICE_KEY_DOWNLOAD_DETAILS,
  HIDE_OR_SHOW_ALL_DEVICE_KEYS,
  HIDE_OR_SHOW_DEVICE_KEY,
} from './deviceKeyActionTypes';

export const deviceKeyDownloadDetailsFetched = (
  deviceKeyResponse: DownloadDetails,
): DeviceKeyDownloadDetailsFetchedAction => ({
  type: DEVICE_KEY_DOWNLOAD_DETAILS_FETCHED,
  downloadDetails: deviceKeyResponse,
});

const hideAllKeys = (deviceKeys: DeviceKey[]): DeviceKey[] => {
  const updated = [...deviceKeys] as DeviceKey[];
  for (let index = 0; index < updated.length; index += 1) {
    updated[index].isVisible = false;
  }
  return updated;
};

export const fetchDeviceKeyDownloadDetails =
  (downloadHash: string) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch({
      type: FETCH_DEVICE_KEY_DOWNLOAD_DETAILS,
      downloadHash,
    } as FetchDeviceKeyDownloadDetailsAction);
    const downloadDetails: DownloadDetails = await getDeviceKeyDownloadDetails(downloadHash);

    if (downloadDetails) {
      downloadDetails.deviceKeys = hideAllKeys(downloadDetails.deviceKeys);

      dispatch(deviceKeyDownloadDetailsFetched(downloadDetails));
    } else {
      throw new Error('Fetching of device key download information failed!');
    }
  };

export const showOrHideDeviceKey = (index: number): HideOrShowDeviceKeyAction => ({
  type: HIDE_OR_SHOW_DEVICE_KEY,
  deviceKeyIndex: index,
});

export const showOrHideAllKeys = (areAllKeysVisible: boolean): HideOrShowAllKeysAction => ({
  type: HIDE_OR_SHOW_ALL_DEVICE_KEYS,
  areAllKeysVisible,
});
