import * as React from 'react';
import useTranslation from '../../../hooks/useTranslation';
import './PanelLock.less';

interface PanelLockProps {
  isLocked: boolean;
  translationIdForPanelName: string;
  children: React.ReactNode | React.ReactNode[];
}

export const PanelLock = (props: PanelLockProps): JSX.Element => {
  const {
    isLocked,
    translationIdForPanelName,
    children,
  } = props;
  const translate = useTranslation();

  if (!isLocked) {
    return (<div>{ children }</div>);
  }

  const content = translate(
    'lock-group-not-defined-tooltip',
    {fieldName: translationIdForPanelName},
  ).toString()

  return (
    <div className="locked-state">
      <a title={ content }>
        { children }
      </a>
    </div>
  );
}
