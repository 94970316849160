import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { getSystemHealth } from '../../../api/apiHelpers';
import SystemLogsModal from './SystemLogsModal';
import SystemHealthComponent from './SystemHealthComponent';
import { ReactComponent as HealthTopologySVG } from '../../../assets/network_topology.svg';

export interface ISystemHealth {
  version: string;
  environment: string;
  status: string;
  state: string;
  name: string;
}

export const SUCCESS_STATES = ['running'];
export const WARNING_STATES = ['created', 'restarting', 'paused'];
export const DANGER_STATES = ['exited', 'dead'];

const SystemHealth = () => {
  const [healthData, setHealthData] = useState<ISystemHealth[]>([]);
  const [selectedComponent, setSelectedComponent] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchSystemHealth = async () => {
      try {
        const healthDataRes = await getSystemHealth();
        healthDataRes && setHealthData(healthDataRes);
      } catch (e) {
        console.error(e);
      }
    };
    fetchSystemHealth();
  }, []);

  /**
   * Remove the docker prefix for a container name like '0a93c2b1681d_adminMongo' -> 'adminMongo'
   * but not for 'traefik_proxy_1'
   * @param data - ISystemHealth
   * @returns ISystemHealth
   * */
  const removePrefixFromDataName = (data: ISystemHealth) => {
    const { name, ...otherData } = data;
    const parsedName = name.replace(/^[a-z0-9]{11,}_/, '');
    return { ...otherData, name: parsedName };
  };

  return (
    <>
      <div className="health-container">
        <div className="settings-header">
          <Translate id="health-page-description" />
        </div>
        <Row>
          <div className="health-svg-container">
            <HealthTopologySVG width={658} height={700} />
            {healthData.map((data, index) => (
              <SystemHealthComponent
                key={index}
                data={removePrefixFromDataName(data)}
                setSelectedComponent={setSelectedComponent}
              />
            ))}
          </div>
        </Row>
      </div>
      <SystemLogsModal
        show={!!selectedComponent}
        handleClose={() => setSelectedComponent(undefined)}
        component={selectedComponent}
      />
    </>
  );
};

export default SystemHealth;
