import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Device, IColumnObject } from '@wiot/shared-domain/models/device/device';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import TableDataHeader from '../../../components/Table/TableDataHeader';
import NoResultsInfo from '../../../components/Table/NoResultsInfo';
import RenderOnCondition from '../../../components/RenderOnCondition';
import { AppState } from '../../../state/reducers/rootReducer';
import TableDataRow from './TableDataRow';
import { DEFAULT_COLUMNS, KEY_MANAGER_TABLE_COLUMNS, } from '../../../config/table-columns/device-manager';
import { updateUserInDB } from '../../../api/apiHelpers';
import { setCurrentUser } from '../../../state/actions/setCurrentUserAction';
import { Tooltip } from '../../../components/shared/Tooltip';

export interface TableDataProps {
  markOneDeviceAsSelected: (id: string) => void;
  markAllDevicesAsSelected: (event: React.FormEvent<HTMLInputElement>) => void;
  refreshData: (column?: IColumnObject) => void;
  removeUnit: (id: string) => Promise<void>;
  isSelectAllChecked: boolean;
}

const TableDataDevManager = (props: TableDataProps) => {
  const {
    markOneDeviceAsSelected,
    markAllDevicesAsSelected,
    refreshData,
    removeUnit,
    isSelectAllChecked,
  } = props;
  const dispatch = useDispatch();
  const currentUser = useSelector((state: AppState) => state.currentUser.user);
  const devices = useSelector((state: AppState) => state.devices);
  const isLoading = useSelector((state: AppState) => state.isLoading);

  const isKeyManagerModeEnabled = useSelector((state: AppState) => state.siteSettings.isKeyManagerModeEnabled);

  const getVisibleColumns = () => {
    if (currentUser?.settings?.columns?.deviceManager.length) {
      return currentUser.settings?.columns?.deviceManager;
    }
    if (isKeyManagerModeEnabled) {
      return KEY_MANAGER_TABLE_COLUMNS;
    }
    return DEFAULT_COLUMNS;
  };

  const [columns, setColumns] = useState<IColumnObject[]>(getVisibleColumns());

  const handleColumnsChange = async (updatedColumns: IColumnObject[]) => {
    setColumns(updatedColumns);
    const userData = {
      ...currentUser,
      settings: {
        ...currentUser?.settings,
        columns: {
          ...currentUser?.settings?.columns,
          deviceManager: updatedColumns,
        },
      },
    };
    const response: any = await updateUserInDB(userData);
    const { user } = response;
    dispatch(setCurrentUser(user));
  };

  if (isLoading) {
    return <LoadingIcon/>;
  }

  const showResults = (devices && devices.length);

  return (
    <div className="device-manager__table__table__container overflow-x-auto">
      <Tooltip
        id="device-manager__table__table__trow__device-type-icon__tooltip"
        className="device-manager_0_table__table__trow__device-type-icon__tooltip type-dark"
      />
      <table className="device-manager__table__table device-manager__table__sticky no-width">
        <TableDataHeader
          withCheckbox
          withColumnSelection={ !isKeyManagerModeEnabled }
          tableHeaders={ columns }
          markAllRowsAsSelected={ markAllDevicesAsSelected }
          isSelectAllChecked={ isSelectAllChecked }
          handleColumnsChange={ handleColumnsChange }
        />
        <tbody data-testid="device-manager-data-table-tbody">
          <RenderOnCondition condition={showResults}>
            {
              devices.map((device: Device) => (
                <TableDataRow
                  device={ device }
                  key={ device.id }
                  removeUnit={ removeUnit }
                  markOneDeviceAsSelected={ markOneDeviceAsSelected }
                  refreshDevices={ refreshData }
                  columns={ columns }
                />
            ))}
          </RenderOnCondition>
        </tbody>
      </table>
      <RenderOnCondition condition={!showResults}>
        <NoResultsInfo />
      </RenderOnCondition>
    </div>
  );
};

export default TableDataDevManager;
