import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { groupBy } from 'lodash';
import { Translate } from 'react-localize-redux';
import { getIn } from 'formik';
import { DeviceTypeRole, IMessageRole, IRole } from '@wiot/shared-domain/models/role/role';
import RoleGroup from '../components/RoleGroup';
import {
  getDeviceTypeIconFullPath,
  getTranslationValueInCurrentLanguage,
  localCompareTranslationsCurrentLanguage,
} from '../../../utils/common';
import { IconVariant } from '../../../constants';
import { Tooltip } from '../../../components/shared/Tooltip';

interface IDeviceTypeColumnProps {
  role: IRole;
}

const DeviceTypeColumn = ({ role }: IDeviceTypeColumnProps) => {
  const { deviceTypes } = role;

  const { shown } = groupBy(deviceTypes, (deviceTypeRole: DeviceTypeRole) =>
    deviceTypeRole.deviceType?.isActive ? 'shown' : 'hidden',
  );

  const getTooltipContent = (deviceTypeKey: number, deviceTypeName: string) => (
    <>
      <label className="details__label">
        <Translate id="device-type" />
        <p className="details__text">{deviceTypeName}</p>
      </label>
      <RoleGroup
        values={role}
        setFieldValue={() => {}}
        groupKey={`deviceTypes.${deviceTypeKey}.devices`}
        readOnly
      />
      <RoleGroup
        values={role}
        setFieldValue={() => {}}
        groupKey={`deviceTypes.${deviceTypeKey}.messages`}
        readOnly
      />
    </>
  );

  const deviceTypeSortFunction = (a: DeviceTypeRole, b: DeviceTypeRole) =>
    localCompareTranslationsCurrentLanguage(a.deviceType.name, b.deviceType.name);

  const hasTruthy = (groupObj: any) => {
    const truthyIndex = Object.values(groupObj).findIndex((val) => {
      if (typeof val === 'object') {
        return hasTruthy(val);
      }
      return val;
    });
    return groupObj && truthyIndex !== -1;
  };

  const shouldDisplay = (deviceTypeKey: number) => {
    const deviceObj = getIn(role, `deviceTypes.${deviceTypeKey}.devices`);
    const messageObj: IMessageRole = getIn(role, `deviceTypes.${deviceTypeKey}.messages`);
    return (
      (deviceObj && hasTruthy(deviceObj)) || (messageObj && (messageObj.add || messageObj.view))
    );
  };

  return (
    <Row>
      {shown &&
        shown.sort(deviceTypeSortFunction).map((deviceTypeRole: DeviceTypeRole, index) => {
          const shouldDisplayItem = shouldDisplay(index);
          if (!shouldDisplayItem) {
            return null;
          }
          return (
            <React.Fragment key={index}>
              <Col key={index} xs={1} className="cursor-pointer">
                <img
                  data-tip=""
                  data-for={`${deviceTypeRole.deviceType.id}-deviceType-${index}`}
                  src={getDeviceTypeIconFullPath(deviceTypeRole.deviceType, IconVariant.small)}
                  alt={getTranslationValueInCurrentLanguage(deviceTypeRole.deviceType.name)}
                />
              </Col>
              <Tooltip
                id={`${deviceTypeRole.deviceType.id}-deviceType-${index}`}
                backgroundColor="#e8e7e7"
                effect="solid"
                delayShow={400}
                place="bottom"
              >
                {getTooltipContent(
                  index,
                  getTranslationValueInCurrentLanguage(deviceTypeRole.deviceType.name),
                )}
              </Tooltip>
            </React.Fragment>
          );
        })}
    </Row>
  );
};

export default DeviceTypeColumn;
