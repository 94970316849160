import React from 'react';
import { components, OptionTypeBase } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { OptionProps } from 'react-select/src/components/Option';
import RenderOnCondition from '../../../RenderOnCondition';

export interface CustomOptionProps<OptionType extends OptionTypeBase> extends OptionProps<OptionType> {
  label: string;
  isOptionChecked: boolean;
}

export const Option = <OptionType extends OptionTypeBase> (props: CustomOptionProps<OptionType>) => {
  const { label, isOptionChecked } = props;
  return (
    <components.Option
      className="select__option"
      { ...props }
    >
      <RenderOnCondition condition={ isOptionChecked }>
        <div className="select__option__selected">
          <div className="select__option__selected-label text-overflow-ellipsis">
            { label }
          </div>
          <div className="select__option__selected-icon">
            <FontAwesomeIcon icon={ faCheck }/>
          </div>
        </div>
      </RenderOnCondition>


      <RenderOnCondition condition={ !isOptionChecked }>
        <div className="select__option__unselected">
          <div className="select__option__unselected-label text-overflow-ellipsis">
            { label }
          </div>
        </div>
      </RenderOnCondition>
    </components.Option>
  );
}
