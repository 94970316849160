import React from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ILegalDetails } from '@wiot/shared-domain/models/settings/settings';
import { AppState } from '../../state/reducers/rootReducer';

interface FooterProps {
  legalDetails?: ILegalDetails;
}

const Footer = (props: FooterProps) => (
  <footer className="footer" data-testid="footer">
    <nav className="footer__list">
      {props.legalDetails &&
        Object.keys(props.legalDetails).map(
          (page) =>
            props.legalDetails?.[page]?.isActive && (
              <Translate key={page}>
                {({ translate }) => (
                  <NavLink to={`/${page}`} className="footer__list__item">
                    {translate(page)}
                  </NavLink>
                )}
              </Translate>
            ),
        )}
    </nav>
  </footer>
);

const mapStateToProps = (state: AppState) => ({
  legalDetails: state.siteSettings.legalDetails,
});

export default connect(mapStateToProps)(Footer);
