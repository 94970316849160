import React from 'react';
import {
  DataIntegrationApiFormats,
  DataIntegrationConfigs,
  DataIntegrationJobExtended,
} from '@wiot/shared-domain/models/data-integration/data-integration';
import { Col } from 'react-bootstrap';
import { CustomFieldInput } from '../../../../components/Table/CustomFieldInput';
import { CustomFieldAdvancedInput } from '../../../../components/Table/CustomFieldAdvancedInput';
import RenderOnCondition from '../../../../components/RenderOnCondition';
import { validateRequiredFieldValue } from '../../../../utils/validation';

interface IAuthSectionProps {
  api: DataIntegrationJobExtended;
  selectedMethod: DataIntegrationApiFormats;
  isApiValid: boolean;
}

const AuthSection = (props: IAuthSectionProps) => {
  const { api, isApiValid, selectedMethod } = props;

  const selectedConfig = DataIntegrationConfigs[selectedMethod];

  return (
    <RenderOnCondition condition={ api.authMethod === 'basic' }>
      <>
        <Col lg={ 4 }>
          <CustomFieldInput
            validate={ validateRequiredFieldValue }
            required
            translationId={ selectedConfig.usernameDisplay }
            fieldName="api.username"
            value={ api.username || '' }
            readOnly={ isApiValid || selectedConfig.usernameDisabled }
            autoComplete={ selectedConfig.usernameDisabled ? 'off' : 'on' }
          />
        </Col>
        <Col lg={ 4 }>
          <CustomFieldAdvancedInput
            validate={ validateRequiredFieldValue }
            required
            type="password"
            translationId={ selectedConfig.passPhraseDisplay }
            fieldName="api.passphrase"
            value={ api.passphrase || '' }
            showEyeButton
            readOnly={ isApiValid }
          />
        </Col>
      </>
    </RenderOnCondition>
  );
};

export default AuthSection;
