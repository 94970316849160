import { TreeNode } from '@wiot/shared-domain/domain/device-tree/tree-node';


export const getTreeNodeById = (id: string, sourceOfTreeNodes: TreeNode[]): TreeNode | null => {
  const nodeWithGivenId = sourceOfTreeNodes.find(node => node.id === id);
  if (nodeWithGivenId) {
    return nodeWithGivenId;
  }

  for (const node of sourceOfTreeNodes) {
    if (node.children) {
      const childWithGivenId = getTreeNodeById(id, node.children);
      if (childWithGivenId) {
        return childWithGivenId;
      }
    }
  }

  return null;
};

export const toggleTreeNodeAndReturnUpdatedTree = (
  tree: TreeNode,
  idOfNodeToBeUpdated: string,
): TreeNode => {
  if (tree.id === idOfNodeToBeUpdated) {
    return { ...tree, children: tree.hiddenChildren, hiddenChildren: tree.children };
  }

  if (tree.children.length > 0) {
    const updatedTreeChildren = tree.children.map(child => toggleTreeNodeAndReturnUpdatedTree(child, idOfNodeToBeUpdated));
    return { ...tree, children: updatedTreeChildren };
  }

  if (tree.hiddenChildren.length > 0) {
    const updatedHiddenChildren = tree.hiddenChildren.map(
      hiddenChild => toggleTreeNodeAndReturnUpdatedTree(hiddenChild, idOfNodeToBeUpdated));
    return { ...tree, hiddenChildren: updatedHiddenChildren };
  }

  return tree;
};

export const setTreeNodeChildrenAndReturnUpdatedTree = (
  treeNode: TreeNode,
  treeNodeWithLoadedChildren: TreeNode,
): TreeNode => {
  if (treeNode.id === treeNodeWithLoadedChildren.id) {
    return {
      ...treeNode,
      children: treeNodeWithLoadedChildren.children,
      hiddenChildren: treeNodeWithLoadedChildren.hiddenChildren,
      hasChildrenToBeLoaded: false,
    };
  }

  if (treeNode.children.length > 0) {
    const updatedTreeChildren = treeNode.children.map(
      child => setTreeNodeChildrenAndReturnUpdatedTree(child, treeNodeWithLoadedChildren));
    return { ...treeNode, children: updatedTreeChildren };
  }

  return treeNode;
};
