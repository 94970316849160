import React, { useEffect, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';

import { Device } from '@wiot/shared-domain/models/device/device';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import { AppState } from '../../../state/reducers/rootReducer';
import { DeviceSelect } from '../../../components/shared/DeviceSelect';
import ConsumptionGraph from '../../../components/shared/ConsumptionGraph';
import { fetchLast14MonthlyEndValues } from '../../../state/device/consumption/fetchLast14MonthlyEndValuesActionCreators';
import DashboardConsumptionGraphTitle from './DashboardConsumptionGraphTitle';

const DashboardConsumptionGraph = () => {
  const dispatch = useDispatch();

  const [ activeDevice, setActiveDevice ] = useState<Device>();

  const monthlyEndValues = useSelector((appState: AppState) => appState.last14MonthlyEndValues[activeDevice?.id || '']);

  useEffect(() => {
    dispatch(fetchLast14MonthlyEndValues(activeDevice));
  }, [dispatch, activeDevice]);

  const { devicesHavingConsumptionData } = useSelector((state: AppState) => state.widgetsData);
  const isLoading = useSelector((state: AppState) => state.isLoading);

  useEffect(() => {
    if (devicesHavingConsumptionData?.length > 0) {
      setActiveDevice(devicesHavingConsumptionData[0]);
    }
  }, [ devicesHavingConsumptionData ]);

  const selectDevice = (id: string) => {
    const selectedDevice = devicesHavingConsumptionData.find((device) => device.id === id);
    setActiveDevice(selectedDevice);
  };

  if (isLoading) {
    return (
      <div className="responsive-container__item__row flex-column">
        <LoadingIcon/>
      </div>
    );
  }

  if (!isLoading && !monthlyEndValues) {
    return (
      <div className="standard-font-size pt-5"><Translate id='no-data' /></div>
    );
  }

  if (activeDevice) {
    return <>
      <div className="responsive-container__item__chart-select-wrapper">
        <DeviceSelect
          devices={ devicesHavingConsumptionData }
          activeDevice={ activeDevice }
          selectDevice={ selectDevice }
          portalTargetId="modal-dashboard-consumption-select"
        />
      </div>

      { monthlyEndValues.values.length ? <>
        <DashboardConsumptionGraphTitle
          device={ activeDevice }
          monthlyEndValues={ monthlyEndValues }
        />

        <ConsumptionGraph
          hideTitle={ true }
          key={ monthlyEndValues.atDate?.toString() }
          consumptionValues={ monthlyEndValues }
          responsiveContainerHeight={ 230 }
        />
      </> :
        <div className="standard-font-size pt-5"><Translate id='no-data' /></div>
      }
    </>;
  }

  return <div className="standard-font-size pt-5"><Translate id='no-data' /></div>;
};

export default withLocalize(DashboardConsumptionGraph);
