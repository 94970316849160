import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import * as React from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { setFilter } from '../../state/filter/set-filter-action-creator';
import { PageKeys } from '../../state/reducers/filterSortReducer';
import { AppState } from '../../state/reducers/rootReducer';
import { FilterField } from './filter-field';
import useTranslation from '../../hooks/useTranslation';
import RenderOnCondition from '../RenderOnCondition';
import { MeasurementFrequency } from '@wiot/shared-domain/models/device-reading/measurement';
import { generatePropertyViewPath } from '../../pages/PropertyView/PropertyViewPathUtils';

interface FilterBillingPeriodPickerProps {
  field: FilterField;
  pageKey: PageKeys;
}

const FilterBillingPeriodPicker = (props: FilterBillingPeriodPickerProps) => {
  const { field, pageKey } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const translate = useTranslation();

  const filter = useSelector((state: AppState) => state.filters.filter);
  const selected = moment((filter[pageKey] as any)[field.stateField]).toDate();

  const { billingFrequency } = filter[pageKey] as any;

  const handleDatePickerChange = (date: Date | null) => {
    if (!date) {
      return;
    }
    const nextBillingPeriodStartDate = moment(date).format('YYYY-MM-DD');

    const nextPath = generatePropertyViewPath({
      billingPeriodStartDate: nextBillingPeriodStartDate,
    });
    history.push(nextPath);

    dispatch(
      setFilter({
        page: pageKey,
        values: { [field.stateField]: nextBillingPeriodStartDate },
      }),
    );
  };

  return (
    <div className="filterbar__item">
      <RenderOnCondition condition={ billingFrequency === MeasurementFrequency.MONTH }>
        <DatePicker
          className={ 'filter-bar-control filter-bar-select__control' }
          selected={ selected }
          dateFormat="yyyy"
          showYearPicker
          maxDate={ new Date() }
          onChange={  handleDatePickerChange }
          placeholderText={ translate(field.name).toString() }
        />
      </RenderOnCondition>

      <RenderOnCondition condition={ billingFrequency === MeasurementFrequency.DAY }>
        <DatePicker
          className={ 'filter-bar-control filter-bar-select__control' }
          selected={ selected }
          dateFormat="MM/yyyy"
          showMonthYearPicker
          maxDate={ new Date() }
          onChange={ handleDatePickerChange }
          placeholderText={ translate(field.name).toString() }
        />
      </RenderOnCondition>
    </div>
  );
};

export default FilterBillingPeriodPicker;
