import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Device, DeviceMetadataFields } from '@wiot/shared-domain/models/device/device';
import { IDeviceRole } from '@wiot/shared-domain/models/role/role';
import RenderOnCondition from '../../../components/RenderOnCondition';
import ConfirmModal, { ConfirmationVariant } from '../../../components/Modal/ConfirmModal';
import { IconVariant, sourceTypeIconSmall, } from '../../../constants';
import QUESTION_MARK from '../../../assets/devices/question-mark-small.svg';
import DeviceDetailsModal from '../DeviceDetailsModal/DeviceDetailsModal';
import { getDeviceTypeIconFullPath, getSourceTypeName, getStatusIcon, hasPermission, localizeDate, } from '../../../utils/common';
import { fetchDeviceFromDB } from '../../../api/apiHelpers';
import DeviceActionModal from '../DeviceActionModal/DeviceActionModal';
import TableMenu from '../Table/TableMenu';
import { AppState } from '../../../state/reducers/rootReducer';
import { DEVICE_MANAGER_TABLE_HEADERS } from '../../../config/table-columns/device-manager';
import { getSelectedMetaDataValue } from '../DeviceActionModal/metaDataUtilityFunctions';
import AddManualDeviceReadingModal from '../AddManualDeviceReadingModal';
import { getMenuId } from '../../../components/PortalMenu/constants/context-menu-ids';
import { closeContextMenu, openContextMenu } from '../../../state/context-menu/toggleContextMenuActionCreators';
import { FormattedDeviceReadingValue } from '../../../components/shared/FormattedDeviceReadingValue';
import { Tooltip } from '../../../components/shared/Tooltip';
import { WiotDeviceStatus } from '@wiot/shared-domain/models/device/device-status';

interface Props {
  device: Partial<Device>;
  removeUnit: (id: string, refresh?: boolean) => Promise<void>;
  refreshData: () => void;
}

const DataRow = (props: Props) => {
  const { device, removeUnit, refreshData } = props;

  const [showDeviceDetails, setShowDeviceDetails] = useState(false);
  const [permission, setPermission] = useState<IDeviceRole | undefined>(undefined);
  const [deviceMessageRole, setDeviceMessageRole] = useState<any>(undefined);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeviceActionModal, setShowDeviceActionModal] = useState(false);
  const [showDuplicateDeviceActionModal, setShowDuplicateDeviceActionModal] = useState(false);
  const [showChangeDeviceActionModal, setShowChangeDeviceActionModal] = useState(false);
  const [showDeviceActionAddSameGroupModal, setShowDeviceActionAddSameGroupModal] = useState(false);
  const [showAddManualDeviceReadingModal, setShowAddManualDeviceReadingModal] = useState(false);

  const showFullTimestamp = useSelector(
    (state: AppState) => state.currentUser.user?.settings?.display?.showFullTimestamp,
  );
  const isKeyManagerModeEnabled = useSelector((state: AppState) => !!state.siteSettings.isKeyManagerModeEnabled);
  const visibleContextMenu = useSelector((state: AppState) => state.contextMenu.visibleContextMenuId);

  const dispatch = useDispatch();

  const { id, name, deviceType, deviceId, deviceGroup, manufacturer, status, lastMessage } = device;

  const downloadHash = getSelectedMetaDataValue(DeviceMetadataFields.metadata, 'downloadHash', device.deviceMetadata);

  if (!id) {
    return null;
  }

  const { source } = lastMessage || {};
  const sourceTypeName = getSourceTypeName(source);

  const menuId = getMenuId(props.device.id);
  const showMenu = (visibleContextMenu === menuId);
  const closeMenu = (event?: React.MouseEvent) => {
    event && event.preventDefault();
    dispatch(closeContextMenu());
  }
  const toggleMenu = () => {
    if (!showMenu) {
      dispatch(openContextMenu(menuId));
    } else {
      dispatch(closeContextMenu());
    }
  };

  const toggleDetails = async (itemId: string) => {
    const device = await fetchDeviceFromDB(itemId);
    const devicePermission = device?.permission;

    if (hasPermission(devicePermission, 'view', true)) {
      setShowDeviceDetails((prevState) => !prevState);
      closeMenu();
    }
  };

  const toggleDeviceActionModal = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDeviceActionModal((prevState) => !prevState);
    closeMenu();
  };

  const toggleDuplicateDeviceActionModal = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDuplicateDeviceActionModal((prevState) => !prevState);
    closeMenu();
  };

  const toggleChangeDeviceActionModal = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowChangeDeviceActionModal((prevState) => !prevState);
    closeMenu();
  };

  const toggleDeviceActionModalAddSameGroup = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDeviceActionAddSameGroupModal((prevState) => !prevState);
    closeMenu();
  };

  const toggleAddManualDeviceReadingModal = () => {
    setShowAddManualDeviceReadingModal((prevState) => !prevState);
    closeMenu();
  };

  const onAddManualDeviceReadingModalClosed = (hasUserAddedOneOrMoreDeviceReadings: boolean) => {
    setShowAddManualDeviceReadingModal(false);

    if (hasUserAddedOneOrMoreDeviceReadings) {
      refreshData();
    }
    closeMenu();
  };

  const toggleDeleteModal = (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDeleteModal((prevState) => !prevState);
    closeMenu();
  };

  const toggleShowMenu = async () => {
    if (!showMenu && id) {
      const device = await fetchDeviceFromDB(id);
      setPermission(device.permission);
      setDeviceMessageRole(device.messageRole);
    }

    toggleMenu();
  };

  // @ts-ignore
  const manufacturerName = manufacturer ? manufacturer.name : '-';

  let pillClassname = `pil ${status?.includes(WiotDeviceStatus.OK) ? 'pil-green' : 'pil-red'}`;
  if (isKeyManagerModeEnabled) {
    pillClassname = `pil pil-primary`;
  }

  return (
    <div className="data-card-container">
      <Row className="data-card-container__header">
        <div className="data-card-container__header__title  text-overflow-ellipsis">{ name }</div>
      </Row>
      <Row className="data-card-container__device-type">
        <img
          data-testid="device-manager__table__table__trow__device-type-icon"
          data-tip="device-type-icon"
          data-for={ id }
          src={ getDeviceTypeIconFullPath(deviceType, IconVariant.small) }
          alt="device type"
        />
      </Row>
      <Row className="data-card-container__body border-color-main">
        <Col xs={ 4 } className="data-card-container__key">
          <Translate id={ DEVICE_MANAGER_TABLE_HEADERS[1] }/>
          {':'}
        </Col>
        <Col className="data-card-container__value">{ deviceId }</Col>
      </Row>
      <Row className="data-card-container__body border-color-main">
        <Col xs={ 4 } className="data-card-container__key">
          <Translate id={ DEVICE_MANAGER_TABLE_HEADERS[4] }/>
          {':'}
        </Col>
        <Col className="data-card-container__value">
          { deviceGroup ? deviceGroup.name : <Translate id="not-assigned"/> }
        </Col>
      </Row>
      <Row className="data-card-container__body border-color-main">
        <Col xs={ 4 } className="data-card-container__key">
          <Translate id={ DEVICE_MANAGER_TABLE_HEADERS[3] }/>
          {':'}
        </Col>
        <Col className="data-card-container__value">{ manufacturerName }</Col>
      </Row>
      <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
        <Row className="data-card-container__body border-color-main">
          <Col xs={ 4 } className="data-card-container__key">
            <Translate id={ DEVICE_MANAGER_TABLE_HEADERS[5] }/>
          {':'}
          </Col>
          <Col className="data-card-container__value">
            { lastMessage ? (
              <>
                {/* if lastMsg date = timestamp, show it before the value
                  if lastMsg date = timedelta, show it after */ }
                { lastMessage.receivedAt && (
                  <span className="device-manager__table__table__last-msg__date">
                  { showFullTimestamp && localizeDate(new Date(lastMessage.receivedAt)) }
                </span>
                ) }
                { lastMessage.values && (
                  <span
                    className="device-manager__table__table__last-msg"
                    style={ {
                      paddingLeft: showFullTimestamp ? '5px' : '',
                    } }
                  >
                    <FormattedDeviceReadingValue deviceReadingValues={ lastMessage.values }/>
                </span>
                ) }
                { lastMessage.receivedAt && (
                  <span className="device-manager__table__table__last-msg__date">
                  { !showFullTimestamp && moment(new Date(lastMessage.receivedAt)).fromNow() }
                </span>
                ) }
                <Tooltip
                  id={ `dev-mgr_table_last-msg${ id }` }
                  className="device-manager__table__table__last-msg__tooltip"
                  backgroundColor="#ddd"
                  type="light"
                  place="top"
                >
                  <img
                    src={ (sourceTypeName && sourceTypeIconSmall[sourceTypeName]) || QUESTION_MARK }
                    alt="source type"
                  />{ ' ' }
                  { lastMessage?.receivedAt && moment(new Date(lastMessage.receivedAt)).fromNow() }
                </Tooltip>
              </>
            ) : (
              '-'
            ) }
          </Col>
        </Row>
      </RenderOnCondition>
      <Row className="data-card-container__footer border-color-main">
        <Col xs={ 3 } className="d-flex justify-content-center">
          { (status && status.length > 0) ? (
            <div
              data-tip="device-status"
              data-for={ status + id }
              className={ pillClassname }
            >
              <span className="pil__text">
                { status.map((st, i) => (
                  <FontAwesomeIcon
                    key={ i }
                    icon={ getStatusIcon(st) }
                    size="sm"
                    className="device-status-fa pil-fa"
                  />
                )) }
              </span>
            </div>
          ) : (
            <div/>
          ) }
        </Col>
        <Col xs={ 6 } className="d-flex justify-content-center">
          <span className="device-details-btn" onClick={ () => setShowDeviceDetails(true) }>
            <Translate id="device-details"/>
          </span>
          <RenderOnCondition condition={ showDeviceDetails }>
            <DeviceDetailsModal
              closeModal={ () => setShowDeviceDetails(false) }
              removeUnit={ removeUnit }
              deviceId={ id }
            />
          </RenderOnCondition>
        </Col>
        <Col xs={ 3 }>
          <div className="device-details">
            <div className="device-details-icon" role="presentation" onClick={ toggleShowMenu }>
              <FontAwesomeIcon icon={ faEllipsisV }/>
            </div>
            <RenderOnCondition condition={ showMenu }>
              <TableMenu
                isMobileDisplay
                deviceRole={ permission }
                deviceMessageRole={ deviceMessageRole }
                deviceType={ deviceType }
                removeUnit={ (itemId: string) => removeUnit(itemId, true) }
                deviceId={ id }
                menuId={ menuId }
                refreshDevices={ refreshData }
                deviceGroup={ deviceGroup }
                showDetails={ toggleDetails }
                toggleDeviceActionModal={ toggleDeviceActionModal }
                toggleDeleteModal={ toggleDeleteModal }
                toggleDuplicateDeviceActionModal={ toggleDuplicateDeviceActionModal }
                toggleChangeDeviceActionModal={ toggleChangeDeviceActionModal }
                toggleDeviceActionModalAddSameGroup={ toggleDeviceActionModalAddSameGroup }
                toggleAddManualDeviceReadingModal={ toggleAddManualDeviceReadingModal }
                downloadHash={downloadHash}
              />
            </RenderOnCondition>
            <RenderOnCondition condition={ showDeviceActionModal }>
              <DeviceActionModal
                closeAddAndUpdateModal={ toggleDeviceActionModal }
                title="update-device"
                showDeleteButton
                addUnit={ false }
                removeUnit={ (itemId: string) => removeUnit(itemId, true) }
                id={ id }
                refreshDevices={ refreshData }
              />
            </RenderOnCondition>
            <RenderOnCondition condition={ showDeleteModal }>
              <ConfirmModal
                modalCloseRequested={ () => toggleDeleteModal() }
                actionConfirmed={ () => removeUnit(id, true) }
                translationIdOfElementType="device"
                confirmationVariant={ ConfirmationVariant.DELETE }
              />
            </RenderOnCondition>
            <RenderOnCondition condition={ showDuplicateDeviceActionModal }>
              <DeviceActionModal
                closeAddAndUpdateModal={ toggleDuplicateDeviceActionModal }
                title="clone-device"
                showDeleteButton={ false }
                duplicate
                addUnit
                id={ id }
                refreshDevices={ refreshData }
              />
            </RenderOnCondition>
            <RenderOnCondition condition={ showChangeDeviceActionModal }>
              <DeviceDetailsModal
                removeUnit={ removeUnit }
                closeModal={ toggleChangeDeviceActionModal }
                deviceId={ id }
                isChangeModal
                refreshDevices={ refreshData }
              />
            </RenderOnCondition>
            <RenderOnCondition condition={ showDeviceActionAddSameGroupModal }>
              <DeviceActionModal
                closeAddAndUpdateModal={ toggleDeviceActionModalAddSameGroup }
                title="add-dev-same-group"
                showDeleteButton={ false }
                addUnit
                selectedDeviceGroup={ deviceGroup }
                refreshDevices={ refreshData }
                disableGroupSelect
              />
            </RenderOnCondition>
            <RenderOnCondition condition={ showAddManualDeviceReadingModal }>
              <AddManualDeviceReadingModal
                onModalClosed={ onAddManualDeviceReadingModalClosed }
                device={ device }
              />
            </RenderOnCondition>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DataRow;
