import { Dispatch } from 'redux';
import { CreateDevicePasswordAction } from '../types';
import { createAnonymousUserAsDevicePassword } from '../../api/deviceKeyService';
import { setCurrentUserAndPermissions } from '../actions/loginActionCreators';
import { fetchDeviceKeyProtection } from './fetchDeviceKeyProtectionActionCreators';
import { CREATE_DEVICE_PASSWORD } from './deviceKeyActionTypes';

export const createDevicePassword = (
  downloadHash: string,
  password: string,
  email?: string,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  dispatch({ type: CREATE_DEVICE_PASSWORD, downloadHash, password } as CreateDevicePasswordAction);

  const userDetails = await createAnonymousUserAsDevicePassword(downloadHash, password, email);
  if (userDetails) {
    setCurrentUserAndPermissions(userDetails, dispatch);
    dispatch(fetchDeviceKeyProtection(downloadHash));
  } else {
    throw new Error('Create device password failed!');
  }
};
