import React from 'react';

import { Database } from '@wiot/shared-domain/models/backup/database';
import ModalHeader from '../../../components/Modal/ModalHeader';

import { AppState } from '../../../state/reducers/rootReducer';
import { useSelector } from 'react-redux';

import { Form, Formik, FormikValues } from 'formik';
import { Translate } from 'react-localize-redux';
import { getRandomModalOffset } from '../../../utils/dialog';
import { v4 as uuidv4 } from 'uuid';
import CustomFieldSelect from '../../../components/Table/CustomFieldSelect';
import { ICustomFieldSelectOption } from '../../../components/Table/custom-field-select';
import { isTableLoading } from '../../../state/table/isTableLoadingAction';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import useTranslation from '../../../hooks/useTranslation';
import { createBackupFile } from '../../../api/backupService';
import { validateBackupFilename } from '../../../utils/validation';
import { CustomFieldInput } from '../../../components/Table/CustomFieldInput';
import { SetFieldValue } from '../../../state/types';

type CreateBackupModalProps = {
  closeModal: (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => void;
  title: string;
  refreshData: () => void;
}

const databaseBackupTypes: ICustomFieldSelectOption[] = [
  { id: Database.MONGO_DB, name: 'mongodb-database-backup' },
  { id: Database.INFLUX_DB, name: 'influxdb-database-backup' },
];

const CreateBackupModal = (props: CreateBackupModalProps) => {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const modalUid = uuidv4();

  const { title, closeModal, refreshData } = props;

  const isKeyManagerEnabled: boolean = useSelector((appState: AppState) => !!appState.siteSettings?.isKeyManagerModeEnabled);

  const getDatabaseBackupTypesOptions = () => {
    if (!isKeyManagerEnabled)
      return databaseBackupTypes;
    else
      return databaseBackupTypes.filter((databaseBackupType: ICustomFieldSelectOption) => databaseBackupType.id !== Database.INFLUX_DB)
  }

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>, setFieldValue: SetFieldValue) => {
    const target = (event?.currentTarget || event?.target);
    if (target) {
      setFieldValue(target.name, target.value);
    } else {
      setFieldValue('selectedDataBackupType', initialDatabaseBackupType);
    }
  }

  const handleSubmit = async (values: FormikValues) => {
    dispatch(isTableLoading(true));
    closeModal();

    const { selectedDatabaseBackupType, filename } = values;
    const response = await createBackupFile(selectedDatabaseBackupType, filename);
    if (response?.success) {
      toastr.success(translate('success').toString(), translate('backup-created-successfully').toString());
    } else {
      toastr.error(translate('error').toString(), translate('creating-backup-failed').toString());
    }

    await refreshData();
    dispatch(isTableLoading(false));
    return;
  };

  const initialDatabaseBackupType = databaseBackupTypes[0].id;

  return (
    <div
      tabIndex={ 0 } // make it focusable
      style={ getRandomModalOffset() }
      className="device-modal"
      id={ `${ title }-${ modalUid }` }
    >
      <ModalHeader
        isDevice={ false }
        titleTranslationId={ title }
        targetId={ modalUid }
        handleClose={ closeModal }
      />
      <div className="device-modal__body">
        <Formik
          initialValues={ {
            selectedDatabaseBackupType: initialDatabaseBackupType,
            filename: '',
          } }
          enableReinitialize
          onSubmit={ handleSubmit }
          render={ ({ values, setFieldValue }) =>
            (
              <Form
                className="form"
                onClick={ (event: React.MouseEvent) => event.stopPropagation() }
              >
                <div className="form__section">
                  <div className="form__row">
                    <CustomFieldSelect
                      translationId="add-database-backup"
                      hideLabel={ true }
                      fieldName="selectedDatabaseBackupType"
                      options={getDatabaseBackupTypesOptions()}
                      translateOptions={ true }
                      value={values.selectedDatabaseBackupType}
                      onChange={ (e) => handleChange(e, setFieldValue) }
                    />
                  </div>
                </div>
                <div className="form__section">
                  <div className="form__row">
                    <CustomFieldInput
                      validate={ validateBackupFilename }
                      required
                      fieldName="filename"
                      value={ values.filename }
                      placeholder={ translate('filename').toString() }
                    />
                  </div>
                </div>

                <div className="form__row">
                  <div className="form__label my-3">
                    <Translate id={ `${ values.selectedDatabaseBackupType }-backup-description` }/>.
                  </div>
                </div>

                <div className="form__section last">
                  <div className="form__row space-between ai">
                    <div className="form__row--left"/>
                    <div className="form__row--right">
                      <button
                        type="button"
                        className="form__button--cancel"
                        onClick={ closeModal }
                      >
                        <Translate id="cancel"/>
                      </button>
                      <button
                        className="form__button--blue background-color-main text-color-white border-color-main"
                        type="submit"
                      >
                        <Translate id={ 'create' }/>
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )
          }
        />
      </div>
    </div>
  );
};

export default CreateBackupModal;
