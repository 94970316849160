export const OPEN_DKD_MODAL = 'OPEN_DKD_MODAL';
export const CLOSE_DKD_MODAL = 'CLOSE_DKD_MODAL';

export interface OpenDKDModalAction {
  type: typeof OPEN_DKD_MODAL;
  downloadHash: string;
}

export interface CloseDKDModalAction {
  type: typeof CLOSE_DKD_MODAL;
}

export const downloadHashOfDKDModalReducer = (
  state: string | null = null,
  action: OpenDKDModalAction | CloseDKDModalAction,
): string | null => {
  switch (action.type) {
    case OPEN_DKD_MODAL:
      return action.downloadHash;

    case CLOSE_DKD_MODAL:
      return null;
    default:
      return state;
  }
};
