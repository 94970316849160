import React from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Device } from '@wiot/shared-domain/models/device/device';
import { fetchRadioKeyDevices } from '../../api/apiHelpers';
import ModalHeader from '../../components/Modal/ModalHeader';
import DeviceDetailsModal from '../DeviceManager/DeviceDetailsModal/DeviceDetailsModal';
import KeyDevicesTable from './KeyDevicesTable';
import { AppState } from '../../state/reducers/rootReducer';
import HasPermission from '../../components/HasPermission';
import RenderOnCondition from '../../components/RenderOnCondition';
import { getRandomModalOffset } from '../../utils/dialog';
import {FeedbackAttachment} from "../../components/Feedback/feedback";
import {
  filterOutSensitiveDataFromDevices,
} from "../../components/Feedback/filter-sensitive-data";

interface KeyDevicesModalProps {
  keyId: string;
  keyName: string;
  handleClose: () => void;
}

const KeyDevicesModal = ({ keyId, keyName, handleClose }: KeyDevicesModalProps) => {
  const [devices, setDevices] = React.useState<Device[]>([]);
  const [inProgress, setInProgress] = React.useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = React.useState<string | undefined>(undefined);
  const [randomModalOffset] = React.useState(getRandomModalOffset());
  const [modalUid] = React.useState(uuidv4());

  const userPermission = useSelector<AppState>((state) => state.currentUser.permission);

  React.useEffect(() => {
    const fetchKeyDevices = async () => {
      setInProgress(true);
      try {
        const devicesResult = await fetchRadioKeyDevices(keyId);
        setDevices(devicesResult);
      } catch (e) {
        setDevices([]);
        console.error(e);
      }
      setInProgress(false);
    };

    fetchKeyDevices();
  }, [keyId]);

  const showDeviceDetails = (deviceId: string) => {
    setSelectedDeviceId(deviceId);
  };

  const closeDeviceDetails = () => {
    setSelectedDeviceId(undefined);
  };

  const getAttachmentForFeedback = (): FeedbackAttachment | null => {
    if (!devices) {
      return null;
    }
    const filteredDevices = filterOutSensitiveDataFromDevices(devices);
    return {
      keyDevices: { key: keyId, devices: filteredDevices },
    };
  };

  return (
    <>
      <div
        tabIndex={0} // make it focusable
        style={randomModalOffset}
        className="overflow-y-auto overflow-x-hidden device-modal"
        onClick={(event: React.MouseEvent) => event.stopPropagation()}
        id={`key-devices-${modalUid}`}
      >
        <ModalHeader
          isDevice
          titleTranslationId="key-devices"
          targetId={modalUid}
          titlePostfix={keyName}
          handleClose={handleClose}
          enableFeedbackSubmission={true}
          getFeedbackAttachment={getAttachmentForFeedback}
        />
        <div className="device-modal__body details">
          <KeyDevicesTable
            devices={devices}
            inProgress={inProgress}
            handleRowClick={showDeviceDetails}
          />
        </div>
      </div>
      <HasPermission permissionObj={userPermission} permissionKey="view" nested>
        <RenderOnCondition condition={selectedDeviceId}>
          <DeviceDetailsModal
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            deviceId={selectedDeviceId!}
            closeModal={closeDeviceDetails}
          />
        </RenderOnCondition>
      </HasPermission>
    </>
  );
};

export default KeyDevicesModal;
