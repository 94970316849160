import React from 'react';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'react-localize-redux';
import { Tooltip } from './Tooltip';

const AesKeyHintIconWithTooltip = () => (
  <>
    <FontAwesomeIcon icon={faKey} data-for="aesKeyRequirement" data-tip size="lg" />
    <Tooltip
      id="aesKeyRequirement"
      place="right"
      type="light"
      className="tooltip"
    >
      <div className="tooltip__heading">
        <Translate id="aes-encryption-key-requirements" />:
      </div>
      <div className="tooltip__text">
        <Translate id="aes-encryption-key-requirements-text" />
      </div>
    </Tooltip>
  </>
);

export default AesKeyHintIconWithTooltip;
