import { AppState } from '../reducers/rootReducer';
import { PROPERTY_VIEW_PAGE_KEY } from '@wiot/shared-domain/domain/property/property-view-page-key';

export const selectIsPropertyLoading = (state: AppState) => state.properties.isPropertyLoading;
export const selectIsPropertyConsumptionHistoryLoading = (state: AppState) => state.properties.isPropertyConsumptionHistoryLoading;
export const selectProperty = (state: AppState, id?: string) => {
  if (!id) {
    const filterPropertyId = state.filters.filter[PROPERTY_VIEW_PAGE_KEY].deviceGroup?.[0]?.[0]?.id;
    return filterPropertyId ? state.properties.propertiesById[filterPropertyId] : null;
  }

  return state.properties.propertiesById[id];
};
export const selectIsPropertySettingsModalVisible = (state: AppState) => state.properties.settingsModal.isVisible;
export const selectPropertyConsumptionUnit = (state: AppState) => state.properties.consumptionUnit;
