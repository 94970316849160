import MultiSelectPill from './MultiSelectPill';
import RenderOnCondition from '../RenderOnCondition';
import * as React from 'react';
import { PROPERTY_VIEW_PAGE_KEY } from '@wiot/shared-domain/domain/property/property-view-page-key';
import { DisplayTypes } from '@wiot/shared-domain/models/device/device';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../state/reducers/rootReducer';
import { setFilter } from '../../state/filter/set-filter-action-creator';
import { PageKeys } from '../../state/reducers/filterSortReducer';
import { GROUP_PATH_DELIMITER } from '../../utils/device-group-path-helper';


export interface SelectedDeviceGroupsPillsProps {
  page: PageKeys;
}

const SelectedDeviceGroupsPills = ({ page }: SelectedDeviceGroupsPillsProps) => {
  const dispatch = useDispatch();

  const filter = useSelector((state: AppState) => state.filters.filter);
  const selectedDeviceGroups: DeviceGroup[] = 'deviceGroups' in filter[page] ? filter[page].deviceGroups : [];
  const selectedContentDisplay = useSelector((state: AppState) => state.deviceManagerState.contentDisplay);
  const isDeviceGroupSelectionVisible = selectedDeviceGroups && page !== PROPERTY_VIEW_PAGE_KEY && selectedContentDisplay !==
    DisplayTypes.UNASSIGNED;

  const getDeviceGroupDisplayName = (deviceGroup: DeviceGroup) => {
    const ancestorNames = (deviceGroup.ancestors || []).map(a => a.name);
    return [...ancestorNames, deviceGroup.name].join(GROUP_PATH_DELIMITER);
  };

  const removeDeviceGroupItem = (index: number) => {
    const { deviceGroup } = filter[page];
    const updatedDeviceGroups = [...deviceGroup];
    updatedDeviceGroups.splice(index, 1);
    dispatch(
      setFilter({
        page,
        values: {
          deviceGroups: updatedDeviceGroups,
        },
      }),
    );
  };
  return (
    <RenderOnCondition condition={ isDeviceGroupSelectionVisible }>
      <MultiSelectPill
        items={ selectedDeviceGroups } fieldTranslationId="device-group"
        handleRemoveClick={ (index) => removeDeviceGroupItem(index) }
        getSelectedValueText={
          (selectedValue) => getDeviceGroupDisplayName(selectedValue) }
      />
    </RenderOnCondition>
  );
};

export default (SelectedDeviceGroupsPills);