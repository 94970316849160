/**
 * Returns an object containing two values, each with a random number between -15 and 15px
 * Used when painting modals, to prevent them being all exactly on top of each other
 * @return marginTop, marginRight
 */
export const getRandomModalOffset = (): { marginTop: number; marginRight: number } => {
  const marginTop = Math.floor(Math.random() * 31) - 15;
  const marginRight = Math.floor(Math.random() * 31) - 15;
  return { marginTop, marginRight };
};
