import { Form, FormikProps, withFormik } from 'formik';
import { connect, useDispatch } from 'react-redux';
import React from 'react';
import { LocalizeContextProps, Translate, TranslateFunction, withLocalize, } from 'react-localize-redux';
import { Col, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import * as Yup from 'yup';
import { toastr } from 'react-redux-toastr';
import { Dispatch } from 'redux';
import { IUser } from '@wiot/shared-domain/models/user/user';
import { IRole } from '@wiot/shared-domain/models/role/role';
import RenderOnCondition from '../../../components/RenderOnCondition';
import { CustomFieldInput } from '../../../components/Table/CustomFieldInput';
import { AccessRightFormSection } from './AccessRightFormSection';
import { toggleShowDeleteModal } from '../../../state/actions/user-action-modal/fetchPermittedRolesActionCreators';
import { isTableLoading } from '../../../state/table/isTableLoadingAction';
import { addUserToDB, updateUserInDB } from '../../../api/apiHelpers';
import { DispatchActionTypes } from '../../../state/types';
import { UserRole } from '@wiot/shared-domain/models/user/user-role';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';

export interface UserFormProps extends LocalizeContextProps {
  modalId: string;
  modalTitle: string;
  user: IUser | undefined | null;
  userRoles: UserRole[] | undefined | null;
  userCanBeDeleted: boolean;
  cancelled?: () => void;
  submitted?: () => void;
  isInCreationMode: boolean;
}

interface UserFormDispatchProps {
  setIsTableLoading: (isLoading: boolean) => void;
}

export interface IUserActionModalFormValues extends Partial<IUser> {
  userRoles: Partial<UserRole>[] | undefined;
  currentPassword: '';
}

const InnerUserForm = (
  props: UserFormProps & FormikProps<IUserActionModalFormValues>,
): JSX.Element => {
  const dispatch = useDispatch();
  const {
    modalId,
    modalTitle,
    cancelled,
    userCanBeDeleted,
    values,
    setFieldValue,
    isValid,
  } = props;

  const getSubmitButton = () => {
    const { isInCreationMode } = props;

    let translateId = 'save-user';

    if (values.email && isInCreationMode) {
      translateId = 'send-invite';
    }

    return <button
      className="form__button--blue background-color-main text-color-white border-color-main"
      type="submit"
      disabled={!isValid}
    >
      <Translate id={translateId} />
    </button>
  };

  return (
    <Form className="form text-left" onClick={(event: React.MouseEvent) => event.stopPropagation()}>
      <div className="form__section">
        <label className="form__label mb-2">
          <Translate id="userType-description" />
        </label>

        <ToggleButtonGroup
          name="userType"
          value={values.isAnonymous}
          onChange={(isAnonymous: boolean) => setFieldValue('isAnonymous', isAnonymous, true)}
          className="toggle-btn-group-wrapper2"
        >
          <ToggleButton value={false} variant="outline-info" className="standard-font-size">
            <Translate id="normal" />
          </ToggleButton>
          <ToggleButton className="standard-font-size" variant="outline-info" value>
            <Translate id="anonymous" />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className="form__section">
        <Row>
          <RenderOnCondition condition={!values.isAnonymous}>
            <Col lg={6}>
              <CustomFieldInput
                required
                translationId="first-name"
                fieldName="firstName"
                value={values.firstName}
              />
            </Col>
            <Col lg={6}>
              <CustomFieldInput
                required
                translationId="last-name"
                fieldName="lastName"
                value={values.lastName}
              />
            </Col>
          </RenderOnCondition>
          <RenderOnCondition condition={values.isAnonymous}>
            <Col lg={12}>
              <CustomFieldInput
                required
                translationId="name-or-description"
                fieldName="name"
                value={values.name}
              />
            </Col>
          </RenderOnCondition>
        </Row>
        <Row>
          <Col lg={6}>
            <CustomFieldInput
              translationId="street"
              fieldName="street"
              value={values.street}
              required={!values.isAnonymous}
            />
          </Col>
          <Col lg={6}>
            <CustomFieldInput
              translationId="city"
              fieldName="city"
              value={values.city}
              required={!values.isAnonymous}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <CustomFieldInput
              translationId="email"
              fieldName="email"
              value={values.email}
              required={!values.isAnonymous}
            />
          </Col>
        </Row>
      </div>
      {'userRoles' in values && (
        <AccessRightFormSection
          modalId={modalId}
          modalTitle={modalTitle}
          setFieldValue={setFieldValue}
          userRolesFormValue={values.userRoles}
        />
      )}
      <div className="form__section last">
        <div className="form__row space-between ai">
          <div className="form__row--left">
            {userCanBeDeleted && (
              <button
                type="button"
                className="form__button--delete"
                onClick={() => dispatch(toggleShowDeleteModal())}
              >
                <Translate id="remove" />
              </button>
            )}
          </div>
          <div className="form__row--right">
            <button type="button" className="form__button--cancel" onClick={cancelled}>
              <Translate id="cancel" />
            </button>
            {getSubmitButton()}
          </div>
        </div>
      </div>
    </Form>
  );
};

export const getInitialValuesFromUser = (
  user?: IUser | null,
  userRoles?: UserRole[] | null,
): IUserActionModalFormValues => ({
  id: user?.id,
  firstName: user?.firstName || '',
  lastName: user?.lastName || '',
  name: user?.name || '',
  street: user?.street || '',
  city: user?.city || '',
  email: user?.email || '',
  isAnonymous: user?.isAnonymous || false,
  isAdmin: user?.isAdmin || false,
  settings: user?.settings || {},

  currentPassword: '',
  userRoles: userRoles || [],
});

function getValidationSchema(translate: TranslateFunction): Yup.Schema<any> {
  return Yup.object().shape<Partial<IUserActionModalFormValues>>({
    isAnonymous: Yup.boolean(),
    name: Yup.string().when('isAnonymous', {
      is: true,
      then: Yup.string().trim().required(translate('required-field').toString()),
    }),
    firstName: Yup.string().when('isAnonymous', {
      is: false,
      then: Yup.string().trim().required(translate('required-field').toString()),
    }),
    lastName: Yup.string().when('isAnonymous', {
      is: false,
      then: Yup.string().trim().required(translate('required-field').toString()),
    }),
    street: Yup.string().when('isAnonymous', {
      is: false,
      then: Yup.string().trim().required(translate('required-field').toString()),
    }),
    city: Yup.string().when('isAnonymous', {
      is: false,
      then: Yup.string().trim().required(translate('required-field').toString()),
    }),
    email: Yup.string()
      .email(translate('invalid-email').toString())
      .when('isAnonymous', {
        is: false,
        then: Yup.string().trim().required(translate('required-field').toString()),
      }),
    userRoles: Yup.array()
      .of(
        Yup.object().shape({
          role: Yup.object<IRole>().required(translate('required-field').toString()),
          deviceGroup: Yup.object<DeviceGroup>().required(
            translate('required-field').toString(),
          ),
        }),
      )
      .min(1, translate('required-field').toString()),
  });
}

const UserForm = withFormik<UserFormProps & UserFormDispatchProps, IUserActionModalFormValues>({
  mapPropsToValues: ({ user, userRoles }) => getInitialValuesFromUser(user, userRoles),

  validationSchema: ({ translate }: UserFormProps) => getValidationSchema(translate),

  handleSubmit: async (
    values,
    { props: { translate, submitted, setIsTableLoading, isInCreationMode } },
  ) => {
    const { email } = values;

    setIsTableLoading(true);
    const { isAdmin, currentPassword, userRoles, ...cleanedUser } = values;
    cleanedUser.roles = userRoles;
    const response = isInCreationMode
      ? await addUserToDB(cleanedUser)
      : await updateUserInDB(cleanedUser);

    if (response) {
      if (isInCreationMode) {
        if (email) {
          // If the email exists then the backend will send the invitation as well. Success message denotes the same
          toastr.success(
            translate('success').toString(),
            translate('added-user-sent-invitation', {
              email,
            }).toString(),
          );
        } else {
          toastr.success(
            translate('success').toString(),
            translate('added-user', {
              email,
            }).toString(),
          );
        }
      } else {
        toastr.success(
          translate('success').toString(),
          translate('edited-user-success').toString(),
        );
      }
      submitted && submitted();
      setIsTableLoading(false);
    } else {
      toastr.error(translate('error').toString(), translate('edit-user-failed').toString());
      setIsTableLoading(false);
    }
  },
})(InnerUserForm);

export default connect<UserFormProps, UserFormDispatchProps>(
  null,
  (dispatch: Dispatch<DispatchActionTypes>): UserFormDispatchProps => ({
    setIsTableLoading: (isLoading: boolean) => dispatch(isTableLoading(isLoading)),
  }),
)(withLocalize(UserForm));
