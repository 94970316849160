import * as React from 'react';
import { MetroSpinner } from 'react-spinners-kit';

interface LoadingIconProps {
  size?: number;
}

const LoadingIcon = (props: LoadingIconProps) => (
  <div className="loading-icon__topology">
    <MetroSpinner color="var(--main-color)" size={props.size || 35} />
  </div>
);

export default LoadingIcon;
