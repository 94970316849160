import { Dispatch } from 'redux';
import moment from 'moment/moment';
import { DeviceExportFormats } from '@wiot/shared-domain/models/domain/services/export/export.models';
import { fetchDeviceExport } from '../../../api/exportService';
import { Measurement, MeasurementFrequency, } from '@wiot/shared-domain/models/device-reading/measurement';
import { Device, } from '@wiot/shared-domain/models/device/device';
import {
  DefaultFormat_DeviceExport
} from '@wiot/shared-domain/models/domain/services/export/export-providers/default-format/default-format.model';
import {
  FETCH_LAST_14_MONTHLY_END_VALUES,
  FetchLast14MonthlyEndValuesAction,
  Last14MonthlyEndValuesAction,
  LAST_14_MONTHLY_END_VALUES_FETCHED
} from './last14MonthlyEndValuesAction';
import {
  convertMeasurementToHistoricalValue,
  HISTORICAL_VALUE_DATE_FORMAT,
  HistoricalReadingType,
  HistoricalValue,
  MonthlyValue,
  MonthlyValuesWithDifferenceToLastMonth
} from '@wiot/shared-domain/models/device/historical-value';

import { MBusDeviceType } from '@wiot/shared-domain/models/device-types/m-bus-device-types';
import { isHeatCostAllocator } from '@wiot/shared-domain/models/device-types/device-types';
import { MonthlyEndValuesByDevice } from './monthly-end-values-by-device';

const MONTH_MOMENT_FORMAT = 'MMM YY';

export const last14MonthlyEndValuesFetched = (
  { idOfDevice, values }: MonthlyEndValuesByDevice,
): Last14MonthlyEndValuesAction => ({
  type: LAST_14_MONTHLY_END_VALUES_FETCHED,
  payload: {
    idOfDevice,
    values,
  },
});

const isHistoricalValueFromJanuary = (historicalValue: HistoricalValue): boolean => moment(historicalValue.atDate).month() === 0;

const getDifferenceToLastMonth = (historicalValues: HistoricalValue[], indexOfCurrentValue: number, mBusDeviceTypeId?: MBusDeviceType): number => {
  if (indexOfCurrentValue === 0) return NaN;
  const currentConsumptionValue = historicalValues[indexOfCurrentValue].value;
  const previousConsumptionValue = historicalValues[indexOfCurrentValue - 1].value;

  // The saved consumption value of a HeatCostAllocators resets after DEZ
  if (isHeatCostAllocator(mBusDeviceTypeId) && isHistoricalValueFromJanuary(historicalValues[indexOfCurrentValue])) {
    return currentConsumptionValue;
  }
  return currentConsumptionValue - previousConsumptionValue;
}

function addValueDifferenceToLastMonth(historicalValues: HistoricalValue[], mBusDeviceTypeId?: MBusDeviceType): MonthlyValue[] {
  const differencesOfHistoricalValues = historicalValues.map(
    (historicalValue: HistoricalValue, index: number) => {
      const differenceToLastMonthlyValue = getDifferenceToLastMonth(historicalValues, index, mBusDeviceTypeId);
      return {
        atDate: moment(historicalValue.atDate).format(MONTH_MOMENT_FORMAT),
        value: historicalValue.value,
        unit: historicalValue.unit!,
        differenceToLastMonthlyValue,
      };
    },
  );

  return differencesOfHistoricalValues;
}

const fillUpWithHistoricalValues = (monthlyValues: HistoricalValue[], limit = 14): HistoricalValue[] => {
  const dates = Array.from({length: limit}, (_, i) => moment().subtract(i + 1, 'months')).reverse();

  const filledUpMonthlyValues = dates.map(date => {
    const existingValue = monthlyValues.find(mv => {
      const monthlyValueDate = moment(mv.atDate, HISTORICAL_VALUE_DATE_FORMAT);
      return date.year() === monthlyValueDate.year()
        && date.month() === monthlyValueDate.month();
    });

    return existingValue || {
      atDate: moment(date).format(HISTORICAL_VALUE_DATE_FORMAT),
      value: NaN,
      differenceToLastMonthlyValue: NaN,
      unit: monthlyValues[0]?.unit,
    }
  });

  return filledUpMonthlyValues;
}

async function fetchHistoricalValues(device: Device): Promise<MonthlyValuesWithDifferenceToLastMonth | null> {
  const endDate = moment().subtract(1, 'month').endOf('month').toISOString();
  const startDate = moment().subtract(14, 'months').toString();
  const exportFormat = DeviceExportFormats.JSON_DEFAULT_FORMAT_V_1_0_0;
  const deviceManagerFilter = { _id: device.id, deviceGroups: [] };

  const [deviceExport]: DefaultFormat_DeviceExport[] = await fetchDeviceExport(
    endDate,
    exportFormat,
    deviceManagerFilter,
    startDate,
    undefined,
    MeasurementFrequency.MONTH,
  );

  if (!deviceExport) {
    return null;
  }

  const monthlyDeviceMeasurements: Measurement[] = deviceExport.frequentMeasurements.sort((a: Measurement, b: Measurement) => {
    if (a.date < b.date) return -1;
    if (a.date > b.date) return 1;
    return 0;
  });

  let historicalValues = monthlyDeviceMeasurements.map(m => convertMeasurementToHistoricalValue(m));
  historicalValues = fillUpWithHistoricalValues(historicalValues);

  const historicalValuesWithDifference = addValueDifferenceToLastMonth(
    historicalValues,
    device.deviceType?.mBusDeviceTypeId,
  );

  return {
    atDate: new Date(),
    description: HistoricalReadingType.MONTHLY_END_VALUES,
    values: historicalValuesWithDifference,
  };
}

function getMonthlyEndValuesFromDevice(device: Device): MonthlyValuesWithDifferenceToLastMonth | null {
  const monthlyEndValues = device.historicalDeviceTypeProfileDeviceReadingValues?.find(
    hv => hv.description === HistoricalReadingType.MONTHLY_END_VALUES
  );

  if (!monthlyEndValues) {
    return null;
  }

  const historicalValues = fillUpWithHistoricalValues(monthlyEndValues.values.slice(-14));

  const historicalValuesWithDifference = addValueDifferenceToLastMonth(
    historicalValues,
    device.deviceType?.mBusDeviceTypeId,
  );

  return {
    ...monthlyEndValues,
    values: historicalValuesWithDifference,
  };
}

export const fetchLast14MonthlyEndValues = (device?: Device) => async (
  dispatch: Dispatch<any>,
): Promise<void> => {
  dispatch({
    type: FETCH_LAST_14_MONTHLY_END_VALUES,
    device,
  } as FetchLast14MonthlyEndValuesAction);
  if (!device) {
    return;
  }

  let monthlyHistoricalValues = getMonthlyEndValuesFromDevice(device);

  // TODO(TL): WIOT-902: Add here a check if the `monthlyHistoricalValues` are from the correct time range/start date
  if (!monthlyHistoricalValues) {
    monthlyHistoricalValues = await fetchHistoricalValues(device);
  }

  dispatch(last14MonthlyEndValuesFetched({
    idOfDevice: device.id,
    values: monthlyHistoricalValues,
  }));
};
