import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { UserCredentials, UserDetails } from '@wiot/shared-domain/models/auth/auth-api';
import { DispatchActionTypes, TryLoginAction, } from '../types';
import { TRY_LOGIN, } from '../constants/actionTypes';
import { loginUser } from '../../api/apiHelpers';
import { setCurrentUser, setUserPermission } from './setCurrentUserAction';
import { CURRENT_USER_DETAILS_KEY } from '../reducers/currentUserReducer';
import { fetchDeviceKeyProtection } from '../device-key/fetchDeviceKeyProtectionActionCreators';
import { AppState } from '../reducers/rootReducer';

export const setCurrentUserAndPermissions = (
  userDetails: UserDetails,
  dispatch: Dispatch<DispatchActionTypes>) => {
  const { user, permission } = userDetails;
  dispatch(setCurrentUser(user));
  localStorage.setItem(CURRENT_USER_DETAILS_KEY, JSON.stringify(userDetails));
  if (permission) {
    dispatch(setUserPermission(permission));
  }
};

export const login = (credentials: UserCredentials) => async (
  dispatch: Dispatch<DispatchActionTypes>,
): Promise<UserDetails> => {
  dispatch({ type: TRY_LOGIN, credentials } as TryLoginAction);

  const userDetails = await loginUser(credentials);
  if (userDetails) {
    setCurrentUserAndPermissions(userDetails, dispatch);
  } else {
    throw new Error('Login failed!');
  }
  return userDetails;
};


export const loginAndFetchDeviceKeyProtection = (
  credentials: UserCredentials,
  downloadHash: string,
) => async (
  dispatch: ThunkDispatch<AppState, void, DispatchActionTypes>,
  getState: () => AppState,
): Promise<void> => {
  try {
    await dispatch(login(credentials));
    const state = getState();
    if (state.isLoggedIn) {
      await dispatch(fetchDeviceKeyProtection(downloadHash));
    }
  } catch {
    throw new Error('Login in device key wizard failed!');
  }
};
