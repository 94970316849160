import React from 'react';
import { toastr } from 'react-redux-toastr';
import { withLocalize, LocalizeContextProps, Translate } from 'react-localize-redux';
import { BlobWithPath } from '@wiot/shared-domain/models/data-integration/data-integration';
import { importXML, validateXML } from '../../../../api/apiHelpers';
import { IntegrationModalState } from '../IntegrationActionModal';
import { SetFieldValue } from '../../../../state/types';

interface IXmlImportButtonProps extends LocalizeContextProps {
  updateTableAndClose: () => void;
  acceptedXmlFile?: BlobWithPath;
  setFieldValue: SetFieldValue;
  formikValues: IntegrationModalState;
}

const XmlImportButton = (props: IXmlImportButtonProps) => {
  const { translate, updateTableAndClose, acceptedXmlFile, formikValues, setFieldValue } = props;
  const {
    xmlValidationResult,
    omsXmlMasterKey,
    selectedDeviceGroupId,
    importOption,
  } = formikValues;

  const onButtonClick = async (isImport?: boolean) => {
    if (isImport) {
      toastr.info(translate('upload-xml').toString(), translate('xml-upload-started').toString());
    } else {
      toastr.info(
        translate('validate-xml').toString(),
        translate('xml-validation-started').toString(),
      );
    }

    if (acceptedXmlFile) {
      const formData = new FormData();
      formData.append('xmlFile', acceptedXmlFile);
      omsXmlMasterKey && formData.append('masterKey', omsXmlMasterKey);
      selectedDeviceGroupId && formData.append('deviceGroup', selectedDeviceGroupId);
      importOption && formData.append('importOption', importOption);

      let response;
      if (isImport) response = await importXML(formData);
      else response = await validateXML(formData);

      if (response) {
        setFieldValue('xmlValidationResult', response);

        if (response.err?.msg) {
          if (response.err.msg === 'aes-kw-integrity-check-failed' && omsXmlMasterKey) {
            toastr.error(
              translate('failed').toString(),
              translate('aes-kw-integrity-check-failed').toString(),
            );
          }
          return;
        }
        if (isImport) {
          updateTableAndClose();
          toastr.success(
            translate('upload-xml').toString(),
            translate('xml-upload-success').toString(),
          );
        } else {
          toastr.success(
            translate('validate-xml').toString(),
            translate('xml-validation-success').toString(),
          );
        }
      }
    }
  };

  // Check if newObjects has any properties
  const resultIsValidated =
    xmlValidationResult?.newObjects && Object.entries(xmlValidationResult?.newObjects).length !== 0;

  if (!resultIsValidated) {
    return (
      <button
        type="button"
        className="form__button--blue background-color-main text-color-white border-color-main"
        onClick={() => onButtonClick()}
        disabled={!resultIsValidated && omsXmlMasterKey?.length !== 32}
      >
        <Translate id="validate-xml" />
      </button>
    );
  }

  const importIsDisabled =
    (xmlValidationResult?.newObjects?.keys && !formikValues.selectedDeviceGroupId) ||
    (xmlValidationResult?.newObjects?.devices && !formikValues.importOption);

  return (
    <button
      type="button"
      className="form__button--blue background-color-main text-color-white border-color-main"
      onClick={() => onButtonClick(true)}
      disabled={importIsDisabled}
    >
      <Translate id="import" />
    </button>
  );
};

export default withLocalize(XmlImportButton);
