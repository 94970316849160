import * as React from 'react';
import { connect } from 'react-redux';
import { IUser } from '@wiot/shared-domain/models/user/user';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import TableDataHeader from '../../../components/Table/TableDataHeader';
import NoResultsInfo from '../../../components/Table/NoResultsInfo';
import RenderOnCondition from '../../../components/RenderOnCondition';
import { AppState } from '../../../state/reducers/rootReducer';
import TableDataRow from './TableDataRow';
import { USER_TABLE_HEADERS } from '../user-table-headers';

export interface TableDataProps {
  disableControls?: boolean;
  disabledControlsTooltipTranslationTerm?: string;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  markAllUsersAsSelected: (event: React.FormEvent<HTMLInputElement>) => void;
  markOneUserAsSelected: (id: string) => void;
  refreshData: (column?: IColumnObject) => void;
  removeUnit: (id: string) => Promise<void>;
  users: IUser[];
}

export interface TableDataState {
  columns: IColumnObject[];
}

class TableData extends React.Component<TableDataProps, TableDataState> {
  constructor(props: TableDataProps) {
    super(props);
    this.state = {
      columns: [],
    };
  }

  componentDidMount = async () => {
    const { refreshData } = this.props;
    refreshData();
    this.initState();
  };

  initState = () => {
    const columnState = USER_TABLE_HEADERS.map((headerName: string) => ({
      name: headerName,
      visible: true,
      sortable: true,
    }));

    this.setState({ columns: columnState });
  };

  render() {
    const {
      disableControls,
      disabledControlsTooltipTranslationTerm,
      isLoading,
      isSelectAllChecked,
      markAllUsersAsSelected,
      markOneUserAsSelected,
      refreshData,
      removeUnit,
      users,
    } = this.props;
    const { columns } = this.state;

    if (isLoading) {
      return <LoadingIcon />
    }

    const showResults = (users && users.length);

    return (
      <div className="device-manager__table__table__container">
        <table className="device-manager__table__table">
          <TableDataHeader
            withCheckbox
            markAllRowsAsSelected={markAllUsersAsSelected}
            tableHeaders={columns}
            isSelectAllChecked={isSelectAllChecked}
          />
          <tbody>
            <RenderOnCondition condition={showResults}>
              {
                users.map((user: IUser) => (
                  <TableDataRow
                    disableControls={disableControls}
                    disabledControlsTooltipTranslationTerm={disabledControlsTooltipTranslationTerm}
                    user={user}
                    key={user.id}
                    selectOne={markOneUserAsSelected}
                    removeUnit={removeUnit}
                    refreshData={refreshData}
                  />
                ))}
            </RenderOnCondition>
          </tbody>
        </table>
        <RenderOnCondition condition={!showResults}>
          <NoResultsInfo />
        </RenderOnCondition>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  users: state.users,
  isLoading: state.isLoading,
});

export default connect(mapStateToProps)(TableData);
