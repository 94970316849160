import InputMask from "react-input-mask";
import React from "react";

export const MASKED_INPUT_PLACEHOLDER= "MM/YYYY"

export interface MaskedInputProps {
  field: any;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const MaskedMonthYearDateInput = (props: MaskedInputProps) => (
  <InputMask
    mask="99/9999"
    value={ props.value }
    onChange={ props.onChange }
    className="modal-input"
    placeholder={ MASKED_INPUT_PLACEHOLDER }
  />
);
