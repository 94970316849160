export function roundToDecimalPlaces(number: number, decimalPlaces: number): number {
  const factor = 10 ** decimalPlaces;
  return Math.round(number * factor) / factor;
}

export function mapNumberToRange(value: number, oldMin: number, oldMax: number, newMin: number, newMax: number): number {
  return (value - oldMin) * (newMax - newMin) / (oldMax - oldMin) + newMin;
}

export function getPercentage(currentValue: number, previousValue: number) {
  return Math.round((currentValue - previousValue) / previousValue * 100);
}
