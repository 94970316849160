import { PageKeys } from '../../state/reducers/filterSortReducer';

const tableRowHeightsPerPage: { [p in PageKeys]?: number } = {
  roles: 58,
};

export const calculateRecommendedEntriesPerPage = (
  page?: PageKeys,
  // this is the windows minimum height (header + filterbar + scrollbar + body + footer)
  windowMinimumHeight = 230,
  // the step size, in this case it will add 1 entry every time
  stepSize = 1,
) => {
  // get the browser window height or take the minimum
  const windowHeight = window.innerHeight;
  const tableRowHeight = (page && tableRowHeightsPerPage[page]) || 29;

  let minimumTableRowCount = (29 * 6) / tableRowHeight;
  let tableRestHeight = windowHeight - (windowMinimumHeight + 29 * 6);

  while (tableRestHeight / (stepSize * tableRowHeight) > 1) {
    tableRestHeight -= stepSize * tableRowHeight;
    minimumTableRowCount += stepSize;
  }

  return minimumTableRowCount;
};
