import { Dispatch } from 'redux';
import { License } from '@wiot/shared-domain/models/license/License';
import { toastr } from 'react-redux-toastr';
import { authenticatedInstance, handleApiError } from '../../api/apiHelpers';
import api from '../../api/api';
import useTranslation from '../../hooks/useTranslation';
import {
  ADD_LICENSE,
  ADD_LICENSE_FAILED,
  ADD_LICENSE_SUCCEEDED,
  AddLicenseAction,
  AddLicenseFailedAction,
  AddLicenseSucceededAction
} from './AddLicenseActions';

export const addLicense =
  (license: License, fileName: string) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch({
      type: ADD_LICENSE,
      license: license,
      fileName: fileName,
    } as AddLicenseAction);
    const translate = useTranslation();

    try {
      await authenticatedInstance.post(api.licenses, license);

      dispatch({
        type: ADD_LICENSE_SUCCEEDED,
      } as AddLicenseSucceededAction);

      toastr.success(
        translate('success').toString(),
        translate('add-license-success', {
          fileName: fileName,
        }).toString(),
      );
    } catch (error) {
      handleApiError(error, false);
      const errorMessage = error.response ? error.response.data : error.message;

      dispatch({
        type: ADD_LICENSE_FAILED,
        errorMessage: errorMessage,
      } as AddLicenseFailedAction);

      toastr.error(
        translate('add-license-failed', {
          fileName: fileName,
        }).toString(),
        errorMessage,
      );
    }
  };
