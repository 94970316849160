import React from 'react';
import { Translate } from 'react-localize-redux';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import DataRow from './DataRow';
import MobileFooter from '../../../components/Mobile/MobileFooter';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import { DispatchActionTypes, FetchOptions } from '../../../state/types';
import { AppState } from '../../../state/reducers/rootReducer';
import { saveCurrentPage } from '../../../state/actions/savePaginationAction';
import { IDeviceGroupFilter, PageKeys } from '../../../state/reducers/filterSortReducer';

interface MobileTableProps {
  deviceGroups: DeviceGroup[];
  removeUnit: (id: string) => Promise<void>;
  currentPageTitle: PageKeys;
  totalDocs: number;
  currentEntriesPerPage: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  isLoading: boolean;
  refreshData: (column?: IColumnObject, pageOptions?: FetchOptions<IDeviceGroupFilter>) => void;
}

const MobileTable = (props: MobileTableProps) => {
  const firstEntry = (props.currentPage - 1) * props.currentEntriesPerPage + 1;
  const lastEntry = Math.min(firstEntry + props.currentEntriesPerPage - 1, props.totalDocs);

  return props.isLoading ? (
    <LoadingIcon />
  ) : (
    <div className="device-manager__table__container">
      <div className="page-header-wrapper">
        <h3 className="page-title" data-testid="header-page-title">
          <Translate id={props.currentPageTitle} />
        </h3>
        <h4 className="page-subtitle" data-testid="table-subheading">
          {props.totalDocs ? (
            <Translate
              id="device-messages-table-header-subheading"
              data={{
                firstEntry,
                lastEntry,
                allDevices: props.totalDocs,
              }}
            />
          ) : null}
        </h4>
      </div>
      {props.deviceGroups && props.deviceGroups.length ? (
        props.deviceGroups.map((group: DeviceGroup) => (
          <DataRow
            key={group.id}
            deviceGroup={group}
            removeUnit={props.removeUnit}
            refreshData={props.refreshData}
          />
        ))
      ) : (
        <div className="text-center m-2">
          <Translate id="no-data" />
        </div>
      )}
      <MobileFooter
        currentPage={props.currentPage}
        setCurrentPage={props.setCurrentPage}
        totalDocs={props.totalDocs}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  deviceGroups: state.paginatedDeviceGroups.currentPageItems,
  currentPageTitle: state.currentPageTitle,
  pagination: state.pagination['device-groups'].totalDocs,
  currentEntriesPerPage: state.currentEntriesPerPage,
  currentPage: state.pagination['device-groups'].currentPage,
  isLoading: state.paginatedDeviceGroups.isLoadingDeviceGroups,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  setCurrentPage: (pageNumber: number) => dispatch(saveCurrentPage(pageNumber, 'device-groups')),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileTable);
