import React from 'react';
import { DeviceReading, DeviceReadingValue, FunctionType } from '@wiot/shared-domain/models/device-reading/device-reading';
import { faAngleDoubleDown, faAngleDoubleUp, faExclamationCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';
import { Translate } from 'react-localize-redux';
import { getFormattedDeviceReading } from '@wiot/shared-domain/models/device-reading/device-reading-values';
import { Tooltip } from './Tooltip';

type FormattedDeviceReadingValueProps = {
  deviceReadingValues?: DeviceReadingValue[];
  deviceReading?: DeviceReading;
  systemFieldId?: string;
};

export const FormattedDeviceReadingValue = (
  {
    deviceReadingValues,
    deviceReading,
    systemFieldId,
  }: FormattedDeviceReadingValueProps) => {

  const id = uuidv4();
  const formattedReading = getFormattedDeviceReading(deviceReadingValues, deviceReading, systemFieldId);

  if (!formattedReading) return null;

  const { formattedValue, functionType } = formattedReading;

  let iconDefinition: IconDefinition | null = null;
  switch (functionType) {
    case FunctionType.MIN_VALUE:
      iconDefinition = faAngleDoubleDown;
      break;
    case FunctionType.MAX_VALUE:
      iconDefinition = faAngleDoubleUp;
      break;
    case FunctionType.VALUE_IN_ERROR_STATE:
      iconDefinition = faExclamationCircle;
      break;
    default:
      break;
  }

  if (!iconDefinition || !functionType) {
    return <>{ formattedValue }</>;
  }

  return (
    <>
      {formattedValue}{" "}
      <FontAwesomeIcon
        data-tip="function-type-icon"
        data-for={`tooltip-${id}`}
        icon={iconDefinition}
      />
      <Tooltip
        id={`tooltip-${id}`}
        place="top"
      >
        <Translate id={`function-type-${FunctionType[functionType]}`} />
      </Tooltip>
    </>
  );
}
