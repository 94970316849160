import React from 'react';
import { Accordion, Image, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import _ from 'lodash';
import { Device } from "@wiot/shared-domain/models/device/device";
import Portal from "../shared/Portal";
import LoadingIcon from '../shared/LoadingIcon';
import { IDeviceFormData } from '../../pages/DeviceManager/DeviceActionModal/DeviceActionModal';
import OcrWizardSection from './OcrWizardSection';

interface OcrWizardProps {
  isOcrLoading?: boolean;
  toggleOcrWizard: () => void;
  values: IDeviceFormData | Device;
  setFieldValue: (field: string, value: any) => void;
  ocrFoundData?: string[];
  currentFile: string;
}

interface OcrWizardState {
  activeKey: React.SyntheticEvent<HTMLDivElement> | undefined;
  ocrFoundData: string[];
  serial: { label: string; value: string };
  wireless: { label: string; value: string };
  reading: { label: string; value: string };
}

class OcrWizard extends React.Component<OcrWizardProps, OcrWizardState> {
  constructor(props: OcrWizardProps) {
    super(props);

    this.state = {
      activeKey: undefined,
      ocrFoundData: [],
      serial: {
        label: 'serial-number',
        value: '',
      },
      wireless: {
        label: 'wireless-attachment',
        value: '',
      },
      reading: {
        label: 'meter-reading',
        value: '',
      },
    };
  }

  handleGroupSelection = (event: React.SyntheticEvent<HTMLDivElement>) => {
    this.setState({
      activeKey: event,
    });
  };

  setOcrData = (ocrFoundData: string[]) => {
    if (this.state.ocrFoundData !== ocrFoundData) {
      ocrFoundData && this.setState({ ocrFoundData });
    }
  };

  onFoundValueChange = (event: React.FormEvent, index: number, searchedValue: string) => {
    const { ocrFoundData } = this.state;
    const target = event.target as HTMLInputElement;
    const newValue = target.value;
    ocrFoundData[index] = newValue;
    this.setState({
      ocrFoundData,
    });

    const { serial, wireless, reading } = this.state;

    switch (searchedValue) {
      case 'serial-number':
        this.setState({ serial: { ...serial, value: newValue } });
        break;
      case 'wireless-attachment':
        this.setState({ wireless: { ...wireless, value: newValue } });
        break;
      case 'meter-reading':
        this.setState({ reading: { ...reading, value: newValue } });
        break;
    }
  };

  setFormikValues = (serial?: string, wirelessSerial?: string, reading?: string) => {
    const { values, setFieldValue } = this.props;
    if (reading) {
      if (values.deviceMetadata && values.deviceMetadata.deviceInfo) {
        const deviceInfo = [...values.deviceMetadata.deviceInfo];
        const existAt = deviceInfo.findIndex((info) => info.key === 'meter_reading');
        if (existAt >= 0) {
          deviceInfo[existAt].value = reading;
        } else {
          deviceInfo.push({ key: 'meter_reading', value: reading });
        }
        const deviceMetadata = {
          ...values.deviceMetadata,
          deviceInfo,
        };
        setFieldValue('deviceMetadata', deviceMetadata);
      }
    }
    if (serial) {
      setFieldValue('deviceId', serial);
    }
    if (wirelessSerial) {
      if (values.deviceMetadata && values.deviceMetadata.radioInfo) {
        const radioInfo = [...values.deviceMetadata.radioInfo];
        const existAt = radioInfo.findIndex((info) => info.key === 'device-id');
        if (existAt >= 0) {
          radioInfo[existAt].value = wirelessSerial;
        } else {
          radioInfo.push({ key: 'device-id', value: wirelessSerial });
        }
        const deviceMetadata = {
          ...values.deviceMetadata,
          radioInfo,
        };
        setFieldValue('deviceMetadata', deviceMetadata);
      }
    }
  };

  onSubmit = () => {
    const { toggleOcrWizard } = this.props;
    const {
      serial: { value: serial },
      wireless: { value: wirelessSerial },
      reading: { value: reading },
    } = this.state;
    toggleOcrWizard();
    this.setFormikValues(serial, wirelessSerial, reading);
  };

  render() {
    const { isOcrLoading, toggleOcrWizard, currentFile, ocrFoundData } = this.props;
    ocrFoundData && this.setOcrData(ocrFoundData);
    const { activeKey, serial, wireless, reading } = this.state;
    const searchedValues = { serial, wireless, reading };

    return (
      <Portal>
        <Row className="gallery__popup" data-testid="ocr-wizard">
          <div className="device-modal ocr-wizard">
            <div className="form">
              <div className="form__section ocr-wizard__form-section">
                <div className="form__row">
                  <h4 className="form__heading">
                    <Translate id="ocr-title" />
                  </h4>
                </div>
                <Image
                  data-testid="ocr-wizard-image"
                  onClick={(event: React.MouseEvent) => {
                    event.stopPropagation();
                  }}
                  className="ocr-wizard__image"
                  thumbnail
                  src={currentFile}
                />
                {isOcrLoading ? (
                  <LoadingIcon />
                ) : (
                  <>
                    <div className="form__section">
                      <Accordion
                        data-testid="ocr-wizard-accordion"
                        onSelect={this.handleGroupSelection}
                        // @ts-ignore
                        activeKey={activeKey}
                      >
                        {_.map(
                          searchedValues,
                          (searchedValue: { label: string; value: string }) => (
                            <OcrWizardSection
                              activeKey={activeKey}
                              setOcrData={this.setOcrData}
                              ocrFoundData={ocrFoundData || ['']}
                              onFoundValueChange={this.onFoundValueChange}
                              searchedValue={searchedValue}
                            />
                          ),
                        )}
                      </Accordion>
                    </div>
                    <div className="form__section last">
                      <div className="form__row space-between ai">
                        <div className="form__row--left">
                          <button
                            data-testid="ocr-wizard-cancel"
                            type="button"
                            className="form__button--delete"
                            onClick={toggleOcrWizard}
                          >
                            <Translate id="cancel" />
                          </button>
                        </div>
                        <div className="form__row--right">
                          <button
                            data-testid="ocr-wizard-ok"
                            className="form__button--blue background-color-main text-color-white border-color-main"
                            onClick={this.onSubmit}
                          >
                            <Translate id="OK" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Row>
      </Portal>
    );
  }
}

export default OcrWizard;
