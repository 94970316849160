import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { DeviceReading } from '@wiot/shared-domain/models/device-reading/device-reading';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import RenderOnCondition from '../../../components/RenderOnCondition';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import TableDataHeader from '../../../components/Table/TableDataHeader';
import NoResultsInfo from '../../../components/Table/NoResultsInfo';
import { isLoading } from '../../../state/actions/isLoadingAction';
import { saveDeviceMessages } from '../../../state/actions/saveDeviceMessagesAction';
import { savePagination } from '../../../state/actions/savePaginationAction';
import { AppState } from '../../../state/reducers/rootReducer';
import { DEVICE_MESSAGES_TABLE_HEADERS as TABLE_HEADERS } from '../../../constants';
import { DispatchActionTypes, IPagination } from '../../../state/types';
import TableDataRow from './TableDataRow';

export interface DeviceMessagesProps {
  deviceMessages: DeviceReading[];
  isLoading: boolean;
  refreshData: (column?: IColumnObject) => void;
}

export interface TableDataState {
  columns: IColumnObject[];
}

class TableDataDevMessages extends React.Component<DeviceMessagesProps, TableDataState> {
  constructor(props: DeviceMessagesProps) {
    super(props);
    this.state = {
      columns: [],
    };
  }

  componentDidMount = async () => {
    this.initState();
  };

  initState = () => {
    const columnState = TABLE_HEADERS.map((headerName: string) => ({
      name: headerName,
      visible: true,
      sortable: true,
    }));

    this.setState({ columns: columnState });
  };

  render() {
    if (this.props.isLoading) {
      return <LoadingIcon />;
    }

    const showResults = (this.props.deviceMessages && this.props.deviceMessages.length);

    return (
      <div className="device-manager__table__table__container device-messages__table__table__container">
        <table className="device-manager__table__table">
          <TableDataHeader
            withCheckbox={false}
            tableHeaders={this.state.columns}
          />
          <tbody data-testid="device-messages-data-table-tbody">
            <RenderOnCondition condition={showResults}>
              {
                this.props.deviceMessages.map((message: DeviceReading) =>
                  (
                    <RenderOnCondition key={message.id} condition={typeof message.device === 'object'}>
                      <TableDataRow key={message.id} message={message} refreshData={ this.props.refreshData } />
                    </RenderOnCondition>
                  )
                )
              }
            </RenderOnCondition>
          </tbody>
        </table>
        <RenderOnCondition condition={!showResults}>
          <NoResultsInfo />
        </RenderOnCondition>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  deviceMessages: state.deviceMessages,
  currentEntriesPerPage: state.currentEntriesPerPage,
  isLoading: state.isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveDeviceMessages: (deviceMessages: DeviceReading[]) =>
    dispatch(saveDeviceMessages(deviceMessages)),

  savePagination: (paginationData: IPagination) =>
    dispatch(savePagination(paginationData, 'device-messages')),

  setIsLoading: (loading: boolean) =>
    dispatch(isLoading(loading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableDataDevMessages);
