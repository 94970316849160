import { IDeviceRole, IRole } from '@wiot/shared-domain/models/role/role';

export const ROLE_DEFAULT_VALUES_WIOT: IRole = {
  name: '',
  superAdmin: false,
  deviceTypes: [],
  deviceManager: {
    viewUnassigned: true,
    viewBlacklisted: true,
    viewTopology: true,
    viewMap: true,
  },
  deviceKeyDownload: {
    view: false,
  },
  deviceGroups: {
    view: true,
    add: true,
    edit: true,
    remove: true,
    flagAsProperty: false,
  },
  keys: {
    view: true,
    add: true,
    edit: true,
    remove: true,
  },
  users: {
    view: false,
    add: false,
    edit: false,
    remove: false,
  },
  roles: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    addRootAdmin: false,
  },
  dataIntegration: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    allowedFormat: {
      csv: false,
      json: false,
      api: false,
      xml: false,
      ftp: false,
    },
  },
  profile: {
    changeUserSettings: true,
    changeAddress: true,
    changePassword: true,
  },
  settings: {
    view: false,
    edit: false,
  },
  permittedRoles: [],
  propertyView: {
    view: false,
    viewPropertySettings: false,
    editPropertySettings: false,
  },
};

export const DEVICE_ROLE_DEFAULT_VALUES_WIOT: IDeviceRole = {
  add: true,
  edit: {
    basic: true,
    metadata: true,
    radioInfo: true,
    description: true,
    images: true,
    removeDeviceGroup: false,
  },
  view: {
    basic: true,
    metadata: true,
    radioInfo: true,
    description: true,
    images: true,
    consumption: true,
    gateways: true,
    usersWithAccess: false,
  },
  remove: true,
  canExport: true,
};

export const MESSAGE_ROLE_DEFAULT_VALUES_WIOT = {
  view: true,
  period: {
    unit: undefined,
    amount: undefined,
  },
  frequency: {
    unit: undefined,
    amount: undefined,
  },
  add: true,
  remove: true,
};
