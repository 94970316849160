import { IRoleChecked } from '@wiot/shared-domain/models/role/role';
import { SAVE_ROLES, TOGGLE_SELECT_ALL_ROLES, TOGGLE_SELECT_ROLE } from '../constants/actionTypes';
import { RolesActionTypes, RolesState } from '../types';

const initialState: RolesState = [];

export const rolesReducer = (
  state: RolesState = initialState,
  action: RolesActionTypes,
): RolesState => {
  switch (action.type) {
    case SAVE_ROLES:
      return action.payload.map((role: IRoleChecked) => ({
        ...role,
        checked: false,
      }));
    case TOGGLE_SELECT_ROLE:
      return state.map((role: IRoleChecked) => {
        if (String(role.id) !== action.payload) {
          return role;
        }
        return {
          ...role,
          checked: !role.checked,
        };
      });

    case TOGGLE_SELECT_ALL_ROLES:
      return state.map((role: IRoleChecked) => ({
        ...role,
        checked: action.payload,
      }));

    default:
      return state;
  }
};
