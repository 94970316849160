import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as React from 'react';

import {
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { Link, withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { History } from 'history';
import { ISite } from '@wiot/shared-domain/models/settings/settings';
import { DataValidator } from '@wiot/shared-domain/models/validators/data-validators';
import {
  EmailCredentials,
  IdCredentials,
  UserCredentials,
} from '@wiot/shared-domain/models/auth/auth-api';
import { useEffect, useRef, useState } from 'react';
import { AppState } from '../../state/reducers/rootReducer';
import api from '../../api/api';
import { login as loginActionCreator } from '../../state/actions/loginActionCreators';
import { clearLoginData } from '../../state/actions/logoutActionCreator';
import LoginButton from '../../components/Login/LoginButton';
import { DASHBOARD_PATH, DEVICE_MANAGER_PATH, RESET_PASSWORD_PATH } from '../../navigation/paths';
import LoginPasswordIndicator from '../../components/Login/LoginPasswordIndicator';
import VersionIndicator from '../../components/FrontendVersionCheck/VersionIndicator';


/**
 * Props of the {@link LoginPage} component.
 */
interface LoginPageProps extends LocalizeContextProps {
  login: (credentials: UserCredentials) => Promise<void>;
  history: History;
  siteSettings: ISite;
  isLoggedIn: boolean;
}

type LoginFormData = {
  email: string;
  password: string;
};

const initialLoginFormData: LoginFormData = {
  email: '',
  password: '',
};

/**
 * A UI page that allows the user to log in to the platform.
 */
const LoginPage = (props: LoginPageProps) => {
  const { isLoggedIn, login, history, translate, siteSettings } = props;
  const [showPassword, setShowPassword] = useState(false);
  const passwordFieldRef = useRef<HTMLInputElement>(null);

  const loginFormDataValidationSchema = Yup.object().shape({
    email: Yup.string().required(translate('required-field').toString()),
    password: Yup.string().required(translate('required-field').toString()),
  });

  useEffect(() => {
    if (!isLoggedIn) {
      clearLoginData();
    }
  }, []);

  const onSubmitForm = async ({ email, password }: LoginFormData) => {
    const isEmailValid = DataValidator.validateEmail(email.trim());

    const credentials = isEmailValid
      ? ({
          email: email.trim(),
          password,
        } as EmailCredentials)
      : ({
          id: email.trim(),
          password,
        } as IdCredentials);

    try {
      await login(credentials);

      history.push(
        siteSettings.isKeyManagerModeEnabled
          ? DEVICE_MANAGER_PATH
          : DASHBOARD_PATH,
      );
    } catch {
      // When something went wrong a toastr is shown, which steals the focus from the form,
      // so we give the focus to the password field.
      passwordFieldRef.current?.focus();
    }
  };

  const onShowPasswordIconClicked = () => {
    setShowPassword(!showPassword);
  };

  return (
    <main
      data-testid="page-login"
      className="main"
      style={{
        backgroundImage: `url(${api.baseAPIUrl}${api.customizeWallpaper})`,
      }}
    >
      <Formik
        initialValues={initialLoginFormData}
        onSubmit={onSubmitForm}
        isInitialValid={false}
        validationSchema={loginFormDataValidationSchema}
        render={({ isValid }) => (
          <>
            <img
              src={`${api.baseAPIUrl}${api.customizeBranding}`}
              alt="Logo"
              className="login-brand"
            />
            <Form className="login-form">
              <h1
                className="login-form__heading"
                data-testid="login-heading"
              >
                { siteSettings?.title }
              </h1>

              <label className="login-form__label">
                <Field
                  className="login-form__input"
                  name="email"
                  type="text"
                  data-testid="email-input"
                  placeholder={translate('email-id')}
                  required="required"
                  autoComplete="username"
                />
              </label>

              <label className="login-form__label">
                <div className="login-form__container">
                  <Field
                    id="login-password-input-field"
                    innerRef={passwordFieldRef}
                    className="login-form__input login-form__input--icon"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    data-testid="password-input"
                    placeholder={translate('password')}
                    required="required"
                    autoComplete="current-password"
                  />

                  <div onClick={onShowPasswordIconClicked}>
                    <FontAwesomeIcon
                      icon={showPassword ? faEye : faEyeSlash}
                      size="lg"
                      className="login-form__input__eye"
                    />
                  </div>
                </div>
              </label>

              <div className="login-form__forgot-pw">
                <Link
                  to={ RESET_PASSWORD_PATH }
                  className="login-form__forgot-pw__button"
                  type="text"
                  data-testid="reset-password"
                >
                  <Translate id="forgot-pw"/>
                </Link>
              </div>

              <div className="login-form__buttons">
                <LoginButton show={ isValid ? 1 : 0.5 }/>
              </div>
            </Form>
          </>
        ) }
      />
      <VersionIndicator className= "login-page__version-indicator" />
    </main>
  );
};

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.isLoggedIn,
  siteSettings: state.siteSettings,
});

const mapDispatchToProps = (dispatch: any) => ({
  login: (credentials: UserCredentials) =>
    dispatch(loginActionCreator(credentials)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withLocalize(LoginPage)),
);
