
export const RESET_TREE_EXPAND_STATE = 'RESET_TREE_EXPAND_STATE';

export interface TreeExpandStateResetAction {
  type: typeof RESET_TREE_EXPAND_STATE;
}

export const resetTreeExpandState = (): TreeExpandStateResetAction => ({
  type: RESET_TREE_EXPAND_STATE,
});
