export class NumberUtils {
  public static formatAsDin5008 = (value: number, decimalPlaces = 3): string => {
    let roundedValue = value;
    if (decimalPlaces) {
      const multipleOf10 = Math.pow(10, decimalPlaces);
      roundedValue = Math.round(value * multipleOf10) / multipleOf10;
    }

    return roundedValue.toFixed(decimalPlaces).replace('.', ',');
  };
}
