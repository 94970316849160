import { EntriesPerPageActionTypes, EntriesPerPageState } from '../types';
import { CHANGE_ENTRIES_PER_PAGE } from '../constants/actionTypes';

const initialState: EntriesPerPageState = 0;

export const entriesPerPageReducer = (
  state: EntriesPerPageState = initialState,
  action: EntriesPerPageActionTypes,
): EntriesPerPageState => {
  switch (action.type) {
    case CHANGE_ENTRIES_PER_PAGE:
      return action.payload;
    default:
      return state;
  }
};
