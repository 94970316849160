import React, { ReactElement } from 'react';
import { hasPermission } from '../utils/common';

interface IHasPermission {
  permissionObj: any;
  permissionKey?: string;
  oneOf?: string[];
  nested?: boolean;
  skipCheck?: boolean;
  children: ReactElement | ReactElement[] | null;
}

const HasPermission = ({
  permissionObj,
  permissionKey,
  oneOf,
  nested,
  skipCheck,
  children,
}: IHasPermission) => {
  const passAllPermissionCheck = () => {
    if (skipCheck) {
      return true;
    }
    if (oneOf && oneOf?.length > 0 && oneOf.some((key) => hasPermission(permissionObj, key))) {
      return true;
    }

    return !!(permissionKey && hasPermission(permissionObj, permissionKey, nested));
  };
  if (passAllPermissionCheck()) {
    return <>{children}</>;
  }
  return null;
};

export default HasPermission;
