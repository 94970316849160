/**
 * This component is a replacer for react-select/MenuPortal with a fix from
 * pull request https://github.com/JedWatson/react-select/pull/3086
 *
 * It can be used in react-select components as follows:
 * <Select components={{ MenuPortal }}/>
 *
 * If/when this pull request is merged, it should be safe to remove this component after updating react-select
 * to a newer version where bug is fixed.
 */

import React, { useState } from 'react';
import { createPortal } from 'react-dom';

interface IMenuPortal {
  menuPosition: 'absolute' | 'fixed';
  appendTo: HTMLElement;
  controlElement: HTMLElement;
  menuPlacement: 'top' | 'bottom' | 'auto';
  children: React.ReactNode;
}

interface IMenuCSSPortal {
  position: 'absolute' | 'fixed';
  appendTo: HTMLElement;
  controlElement: HTMLElement;
  placement: 'top' | 'bottom' | 'auto';
  isFixed: boolean;
}

const coercePlacement = (p: 'top' | 'bottom' | 'auto') => (p === 'auto' ? 'bottom' : p);

const menuPortalCSS = ({
  position,
  appendTo,
  controlElement,
  placement,
  isFixed,
}: IMenuCSSPortal) => {
  let containerLeft = 0;
  let containerTop = 0;

  if (appendTo) {
    const { left, top } = appendTo.getBoundingClientRect();
    const { offsetLeft, offsetTop } = appendTo;
    containerLeft = left - offsetLeft;
    containerTop = top - offsetTop;
  }

  const rect = controlElement.getBoundingClientRect();

  const r = {
    left: rect.left - containerLeft,
    position,
    top: rect.top - containerTop + (placement === 'bottom' ? rect.height : 0),
    width: rect.width,
    zIndex: 6000,
  };

  if (isFixed) {
    return {
      ...r,
      top: rect[coercePlacement(placement)],
    };
  }

  return r;
};

const MenuPortal = (props: IMenuPortal) => {
  const [placement, setPlacement] = useState<'top' | 'bottom' | 'auto' | null>(null);

  // callback for occassions where the menu must "flip"
  const getPortalPlacement = ({ menuPlacement }: Pick<IMenuPortal, 'menuPlacement'>) => {
    const initialPlacement = coercePlacement(props.menuPlacement);

    // avoid re-renders if the placement has not changed
    if (menuPlacement !== initialPlacement) {
      setPlacement(menuPlacement);
    }
  };

  const { appendTo, children, controlElement, menuPlacement, menuPosition: position } = props;
  const isFixed = position === 'fixed';

  // bail early if required elements aren't present
  if ((!appendTo && !isFixed) || !controlElement) {
    return null;
  }

  const currentPlacement = placement || coercePlacement(menuPlacement);
  const menuState = { position, appendTo, controlElement, placement: currentPlacement, isFixed };

  // same wrapper element whether fixed or portalled
  const menuWrapper = (
    <div className="opuscapita_react-select--2-0-0">
      <div style={menuPortalCSS(menuState)}>{children}</div>
    </div>
  );

  return appendTo ? createPortal(menuWrapper, appendTo) : menuWrapper;
};

export default MenuPortal;
