export const DELETE_MANUAL_DEVICE_READING = 'DELETE_MANUAL_DEVICE_READING';
export const DELETE_MANUAL_DEVICE_READING_SUCCEEDED = 'DELETE_MANUAL_DEVICE_READING_SUCCEEDED';
export const DELETE_MANUAL_DEVICE_READING_FAILED = 'DELETE_MANUAL_DEVICE_READING_FAILED';

export interface DeleteManualDeviceReadingAction {
  type: typeof DELETE_MANUAL_DEVICE_READING;
  deviceReadingId: string;
}

export interface DeleteManualDeviceReadingSucceededAction {
  type: typeof DELETE_MANUAL_DEVICE_READING_SUCCEEDED;
  deviceReadingId: string;
}

export interface DeleteManualDeviceReadingFailedAction {
  type: typeof DELETE_MANUAL_DEVICE_READING_FAILED;
  deviceReadingId: string;
  errorMessage: string;
}
