import { Device } from '@wiot/shared-domain/models/device/device';
import { IDeviceTypeProfile } from '@wiot/shared-domain/models/device-type-profile/device-type-profile';
import { DeviceReading } from '@wiot/shared-domain/models/device-reading/device-reading';
import { addDeviceMessageToDB } from './apiHelpers';

export const addDeviceReading = async (
  readingValue: number,
  device: Device,
  deviceTypeProfile: IDeviceTypeProfile,
  readingDate: Date,
): Promise<DeviceReading | null | undefined> => {
  try {
    const data: DeviceReading = {
      receivedAt: readingDate,
      device,
      values: [
        {
          unit: deviceTypeProfile.unit,
          description: deviceTypeProfile.mainDescription,
          value: readingValue,
        },
      ],
    };
    return await addDeviceMessageToDB(data);
  } catch (error) {
    console.error(error);
    return null;
  }
};
