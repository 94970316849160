import React, { ReactPortal } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  children: React.ReactNode;
  targetId?: string;
}

const Portal = ({ children, targetId = 'root' }: PortalProps): ReactPortal | null => {
  const modalRoot = document.getElementById(targetId);

  return modalRoot && ReactDOM.createPortal(children, modalRoot);
};

export default Portal;
