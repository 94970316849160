import { Col, Row } from 'react-bootstrap';
import React from 'react';
import { IUser } from '@wiot/shared-domain/models/user/user';

export const UsersWithAccess = (props: { users?: IUser[] }) => {
  const { users } = props;

  return (
    <div className='details__section'>
      {users?.map((user) => (
        <Row
          key={user.id}
          className='details__text'
        >
          <Col>{user.email}</Col>
          <Col>{user.displayName}</Col>
        </Row>
      ))}
    </div>
  );
};
