import { TreeNode } from '@wiot/shared-domain/domain/device-tree/tree-node';

export const TREE_NODE_EXPAND_STATE_CHANGE = 'TREE_NODE_EXPAND_STATE_CHANGE';

export interface TreeNodeExpandStateChangedAction {
  type: typeof TREE_NODE_EXPAND_STATE_CHANGE;
  treeNode: TreeNode,
  isExpandAction: boolean,
}

export const saveExpandStateActionCreator = (treeNode: TreeNode, isExpandAction:boolean): TreeNodeExpandStateChangedAction => ({
  type: TREE_NODE_EXPAND_STATE_CHANGE,
  treeNode: treeNode,
  isExpandAction: isExpandAction,
});


