import { FeedbackModalState } from '../../components/Feedback/feedback';

import {
  CLOSE_FEEDBACK_MODAL,
  CloseFeedbackModalAction,
  OPEN_FEEDBACK_MODAL,
  OpenFeedbackModalAction,
  SEND_FEEDBACK,
  SendFeedbackAction,
  FEEDBACK_SENT,
  FeedbackSentAction,
} from './feedbackActions';

const initialState: FeedbackModalState = {
  isVisible: false,
  information: {
    messageOfSendingUser: '',
    emailAddressOfSendingUser: undefined,
    attachment: undefined,
  },
};

export const feedbackReducer = (
  state: FeedbackModalState = initialState,
  action:
    OpenFeedbackModalAction
    | CloseFeedbackModalAction
    | SendFeedbackAction
    | FeedbackSentAction,
): FeedbackModalState => {
  switch (action.type) {
    case OPEN_FEEDBACK_MODAL:
      return {
        isVisible: true,
        information: {
          messageOfSendingUser: '',
          emailAddressOfSendingUser: undefined,
          attachment: action.attachment,
        },
      };
    case SEND_FEEDBACK:
      return {
        ...state,
        information: action.information,
      };
    case FEEDBACK_SENT:
    case CLOSE_FEEDBACK_MODAL:
      return initialState;
    default:
      return state;
  }
};
