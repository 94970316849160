import localforage from 'localforage';
import { Device, DeviceCreation, Devices } from '@wiot/shared-domain/models/device/device';
import { IDeviceTypeProfile } from '@wiot/shared-domain/models/device-type-profile/device-type-profile';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { RadioKey } from '@wiot/shared-domain/models/radio-key/radio-key';
import { Manufacturer } from '@wiot/shared-domain/models/manufacturer/manufacturer';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import {
  addDeviceImages,
  addDeviceToDB,
  addKeyToDB,
  appendFilesToFormData,
  fetchDeviceTypesFromDB,
  fetchKeysFromDB,
  fetchManufacturersFromDB,
  getDeviceTypeProfiles,
  removeDeviceFromDB,
  removeDeviceGroupFromDB,
  removeKeyFromDB,
  updateDeviceInDB,
  updateKeyInDB,
} from './apiHelpers';
import { FetchOptions } from '../state/types';
import store from '../state/store';
import { updateOffline } from '../state/actions/isOfflineAction';
import { isLoading, setLoadingMessage } from '../state/actions/isLoadingAction';
import { IDeviceGroupFilter, IKeysFilter, } from '../state/reducers/filterSortReducer';
import { DeviceTypeCache } from './deviceTypeCache';
import { getDeviceManagersFilterString } from '../components/Filter/get-device-managers-filter-string';
import { getGroupTypes } from '../utils/device-group-filter-helper';
import { includesCaseInsensitive } from '../utils/string';
import { DeviceManagerFilter } from '../state/device/device-manager-filter';

export const cacheDeviceManagerRequests = async () => {
  store.dispatch(isLoading(true));
  await localforage.clear();
  store.dispatch(setLoadingMessage({ message: 'startCache', translatable: true }));
  const fetchOptions = {
    page: 1,
    pageSize: 1000,
  };
  // const fetchUnAssigned: FetchOptions<DeviceManagerFilter> = {
  //   ...fetchOptions,
  //   filters: {
  //     deviceGroup: null,
  //   },
  // };
  // const fetchBlacklisted: FetchOptions<DeviceManagerFilter> = {
  //   ...fetchOptions,
  //   filters: {
  //     blacklisted: true,
  //   },
  // };
  store.dispatch(setLoadingMessage({ message: 'cacheDevices', translatable: true }));
  // const devices = await fetchDevicesFromDB(fetchOptions, 5);
  // const devicesUnassigned = await fetchDevicesFromDB(fetchUnAssigned, 5);
  // const devicesBlacklisted = await fetchDevicesFromDB(fetchBlacklisted, 5);
  store.dispatch(setLoadingMessage({ message: 'cacheDeviceTypes', translatable: true }));
  const deviceTypes = await fetchDeviceTypesFromDB();
  store.dispatch(setLoadingMessage({ message: 'cacheDeviceTypeProfiles', translatable: true }));
  const manufacturers = await fetchManufacturersFromDB();
  store.dispatch(setLoadingMessage({ message: 'cacheManufacturers', translatable: true }));
  const profiles = await getDeviceTypeProfiles();
  store.dispatch(setLoadingMessage({ message: 'cacheDeviceKeys', translatable: true }));
  const radioKeys = await fetchKeysFromDB(fetchOptions);
  store.dispatch(setLoadingMessage({ message: 'cacheDeviceGroups', translatable: true }));
  // const deviceGroups = await fetchDeviceGroupsFromDB(fetchOptions, true, true, false, true);
  // await localforage.setItem('devices', devices);
  // await localforage.setItem('devices:unassigned', devicesUnassigned);
  // await localforage.setItem('devices:blacklisted', devicesBlacklisted);
  await DeviceTypeCache.replaceCachedDeviceTypes(deviceTypes);
  await localforage.setItem('manufacturers', manufacturers);
  await localforage.setItem('deviceTypeProfiles', profiles);
  await localforage.setItem('radioKeys', radioKeys);
  // await localforage.setItem('deviceGroups', deviceGroups);
  store.dispatch(updateOffline(true));
  store.dispatch(isLoading(false));
  store.dispatch(setLoadingMessage({ message: '', translatable: true }));
};

export const clearCache = async () => {
  await localforage.clear();
  store.dispatch(updateOffline(false));
  window.location.reload();
};

/* Utility functions */
// device groups
export const getAddedDeviceGroups = async () =>
  localforage.getItem<DeviceGroup[]>('deviceGroups:add');

export const getUpdatedDeviceGroups = async () =>
  localforage.getItem<DeviceGroup[]>('deviceGroups:edit');

export const getRemovedDeviceGroups = async () =>
  localforage.getItem<string[]>('deviceGroups:remove');

const getDeviceGroupIdMapping = async () =>
  localforage.getItem<{ [key: string]: string }>('deviceGroup:ids');

const setDeviceGroupIdMapping = async (newGroupIds: { [key: string]: string }) =>
  localforage.setItem('deviceGroup:ids', newGroupIds);

// Devices
export const getAddedDevices = async () => localforage.getItem<Device[]>('devices:add');

export const getUpdatedDevices = async () => localforage.getItem<Device[]>('devices:edit');

export const getRemovedDevices = async () => localforage.getItem<string[]>('devices:remove');

export const fetchManufacturersOffline = async (): Promise<Manufacturer[]> =>
  localforage.getItem('manufacturers');

export const fetchDeviceTypeProfileOffline = async (
  manufacturer?: string,
  deviceTypeId?: string,
) => {
  const deviceTypeProfiles: IDeviceTypeProfile[] = await localforage.getItem('deviceTypeProfiles');
  return deviceTypeProfiles.filter(
    (profile) =>
      profile.manufacturerName === manufacturer &&
      (profile.deviceType as IDeviceType).id === deviceTypeId,
  );
};

// Keys
export const getAddedKeys = async () => localforage.getItem<RadioKey[]>('radioKeys:add');

export const getUpdatedKeys = async () => localforage.getItem<RadioKey[]>('radioKeys:edit');

export const getRemovedKeys = async () => localforage.getItem<string[]>('radioKeys:remove');

// CRUD functions
/* DeviceGroups */

const getFilteredGroups = async (filter?: IDeviceGroupFilter) => {
  const { deviceGroups, ...other } = await localforage.getItem<any>('deviceGroups');
  if (deviceGroups) {
    const filteredGroups = [...deviceGroups];
    const { type, name } = filter || {};

    const nameFilteredGroups = name
      ? filteredGroups.filter((group: DeviceGroup) => includesCaseInsensitive(group.name, name))
      : filteredGroups;

    const groupTypes = getGroupTypes(type);

    const typeFilteredGroups = type
      ? nameFilteredGroups.filter((group: DeviceGroup) => (group.type ? groupTypes?.includes(group.type) : false))
      : nameFilteredGroups;
    return { deviceGroups: typeFilteredGroups, ...other };
  }
  return { deviceGroups: [], ...other };
};

const getDeviceGroupsWithoutDescendants = async () => {
  const { deviceGroups, ...other } = await localforage.getItem<any>('deviceGroups');
  const rootDeviceGroups = deviceGroups.filter(
    (group: DeviceGroup) => group.ancestors && group.ancestors.length === 0,
  );
  return { deviceGroups: rootDeviceGroups, ...other };
};

export const fetchDeviceGroupsOffline = async (
  { page, pageSize, filters: filter }: FetchOptions<IDeviceGroupFilter>,
  // includeDescendants = true,
) => {
  if (page && pageSize) {
    const { deviceGroups } = await getFilteredGroups(filter);
    const firstIndex = (page - 1) * pageSize;
    const endIndex = page * pageSize;
    const lastIndex = endIndex > deviceGroups.length ? deviceGroups.length : endIndex;

    return {
      totalDocs: deviceGroups.length,
      totalPages: Math.round(deviceGroups.length / pageSize),
      deviceGroups: deviceGroups.slice(firstIndex, lastIndex),
    };
  }
    const { deviceGroups } = await getDeviceGroupsWithoutDescendants();
    return {
      deviceGroups,
      totalDocs: deviceGroups.length,
      totalPages: 1,
    };
};

export const fetchDeviceGroupOffline = async (id: string) => {
  const { deviceGroups } = await localforage.getItem<any>('deviceGroups');
  const item = deviceGroups.find((deviceGroup: DeviceGroup) => deviceGroup.id === id);
  return {
    deviceGroups: [item],
  };
};

export const addDeviceGroupOffline = async (deviceGroup: DeviceGroup) => {
  const { deviceGroups, totalDocs, ...other } = await localforage.getItem<any>('deviceGroups');
  const addedDeviceGroups: DeviceGroup[] = (await localforage.getItem('deviceGroups:add')) || [];

  const parentIndex = deviceGroups.findIndex(
    (groupObj: DeviceGroup) => groupObj.id === deviceGroup.parent,
  );
  const parentGroup = deviceGroups[parentIndex];

  const childrenGroup = {
    ...deviceGroup,
    children: [],
    metadata: [],
    id: `TempGroupID${addedDeviceGroups.length + 1}`,
  };
  const updatedParentGroup = {
    ...parentGroup,
  };

  const viewableDeviceGroup = {
    ...childrenGroup,
    ancestors: [...parentGroup.ancestors, updatedParentGroup],
    parent: updatedParentGroup,
    devices: [],
    users: [],
  };

  deviceGroups.splice(parentIndex, 1, updatedParentGroup);

  if (parentGroup && parentGroup.parent && parentGroup.parent.id) {
    const ancestorIndex = deviceGroups.findIndex(
      (groupObj: DeviceGroup) => groupObj.id === parentGroup.parent.id,
    );
    const ancestorGroup = deviceGroups[ancestorIndex];
    // const parentIndexInAncestors = ancestorGroup.children.findIndex(
    //   (groupObj: DeviceGroup) => groupObj.id === parentGroup.id,
    // );

    // ancestorGroup.children.splice(parentIndexInAncestors, 1, updatedParentGroup);
    const updatedAncestorGroup = {
      ...ancestorGroup,
      // children: [...ancestorGroup.children],
    };
    deviceGroups.splice(ancestorIndex, 1, updatedAncestorGroup);
  }

  await localforage.setItem('deviceGroups', {
    deviceGroups: [...deviceGroups, viewableDeviceGroup],
    totalDocs: totalDocs + 1,
    ...other,
  });
  await localforage.setItem('deviceGroups:add', [...addedDeviceGroups, childrenGroup]);
  return true;
};

const updateGroupLocal = async (deviceGroup: DeviceGroup) => {
  const { deviceGroups, ...other } = await localforage.getItem<any>('deviceGroups');
  const addedGroups: DeviceGroup[] = (await localforage.getItem('deviceGroups:add')) || [];
  const index = deviceGroups.findIndex((groupObj: DeviceGroup) => groupObj.id === deviceGroup.id);
  const secondIndex = addedGroups.findIndex(
    (groupObj: DeviceGroup) => groupObj.id === deviceGroup.id,
  );

  const currentGroup = deviceGroups[index];
  if (currentGroup.parent.id !== deviceGroup.parent) {
    // Group has changed. Remove group from parent children array
    const currentParentIndex = deviceGroups.findIndex(
      (group: DeviceGroup) => group.id === currentGroup.parent.id,
    );
    const currentParent = deviceGroups[currentParentIndex];
    // const groupIndexInParentChildren = currentParent.children.findIndex(
    //   (group: DeviceGroup) => group.id === deviceGroup.id,
    // );
    // currentParent.children.splice(groupIndexInParentChildren, 1);
    deviceGroups.splice(currentParentIndex, 1, currentParent);
  }

  const parentIndex = deviceGroups.findIndex(
    (groupObj: DeviceGroup) => groupObj.id === deviceGroup.parent,
  );
  const parentGroup = deviceGroups[parentIndex];

  const childrenGroup = {
    ...currentGroup,
    ...deviceGroup,
  };
  const updatedParentGroup = {
    ...parentGroup,
    // children: [...parentGroup.children, childrenGroup],
  };

  const viewableDeviceGroup = {
    ...childrenGroup,
    ancestors: [...parentGroup.ancestors, updatedParentGroup],
    parent: updatedParentGroup,
    devices: [],
    users: [],
  };
  deviceGroups.splice(parentIndex, 1, updatedParentGroup);

  if (parentGroup && parentGroup.parent && parentGroup.parent.id) {
    const ancestorIndex = deviceGroups.findIndex(
      (groupObj: DeviceGroup) => groupObj.id === parentGroup.parent.id,
    );
    const ancestorGroup = deviceGroups[ancestorIndex];
    // const parentIndexInAncestors = ancestorGroup.children.findIndex(
    //   (groupObj: DeviceGroup) => groupObj.id === parentGroup.id,
    // );

    // ancestorGroup.children.splice(parentIndexInAncestors, 1, updatedParentGroup);
    const updatedAncestorGroup = {
      ...ancestorGroup,
      // children: [...ancestorGroup.children],
    };
    deviceGroups.splice(ancestorIndex, 1, updatedAncestorGroup);
  }

  deviceGroups.splice(index, 1, viewableDeviceGroup);
  addedGroups.splice(secondIndex, 1, deviceGroup);
  await localforage.setItem('deviceGroups', { deviceGroups, ...other });
  await localforage.setItem('deviceGroups:add', addedGroups);
  return true;
};

export const updateDeviceGroupOffline = async (deviceGroup: DeviceGroup) => {
  if (deviceGroup.id && deviceGroup.id.startsWith('Temp')) {
    return updateGroupLocal(deviceGroup);
  }
  const { deviceGroups, ...other } = await localforage.getItem<any>('deviceGroups');
  const editedDeviceGroups: DeviceGroup[] = (await localforage.getItem('deviceGroups:edit')) || [];
  if (deviceGroups) {
    const index = deviceGroups.findIndex((group: DeviceGroup) => group.id === deviceGroup.id);
    const secondIndex = editedDeviceGroups.findIndex(
      (group: DeviceGroup) => group.id === deviceGroup.id,
    );

    const currentGroup = deviceGroups[index];
    if (currentGroup.parent.id !== deviceGroup.parent) {
      // Group has changed. Remove group from parent children array
      const currentParentIndex = deviceGroups.findIndex(
        (group: DeviceGroup) => group.id === currentGroup.parent.id,
      );
      const currentParent = deviceGroups[currentParentIndex];
      // const groupIndexInParentChildren = currentParent.children.findIndex(
      //   (group: DeviceGroup) => group.id === deviceGroup.id,
      // );
      // currentParent.children.splice(groupIndexInParentChildren, 1);
      deviceGroups.splice(currentParentIndex, 1, currentParent);
    }

    const parentIndex = deviceGroups.findIndex(
      (groupObj: DeviceGroup) => groupObj.id === deviceGroup.parent,
    );
    const parentGroup = deviceGroups[parentIndex];

    const childrenGroup = {
      ...currentGroup,
      ...deviceGroup,
    };
    const updatedParentGroup = {
      ...parentGroup,
      // children: [...parentGroup.children, childrenGroup],
    };

    const viewableDeviceGroup = {
      ...childrenGroup,
      ancestors: [...parentGroup.ancestors, updatedParentGroup],
      parent: updatedParentGroup,
      devices: [],
      users: [],
    };
    deviceGroups.splice(parentIndex, 1, updatedParentGroup);

    if (parentGroup && parentGroup.parent && parentGroup.parent.id) {
      const ancestorIndex = deviceGroups.findIndex(
        (groupObj: DeviceGroup) => groupObj.id === parentGroup.parent.id,
      );
      const ancestorGroup = deviceGroups[ancestorIndex];
      // const parentIndexInAncestors = ancestorGroup.children.findIndex(
      //   (groupObj: DeviceGroup) => groupObj.id === parentGroup.id,
      // );

      // ancestorGroup.children.splice(parentIndexInAncestors, 1, updatedParentGroup);
      const updatedAncestorGroup = {
        ...ancestorGroup,
        // children: [...ancestorGroup.children],
      };
      deviceGroups.splice(ancestorIndex, 1, updatedAncestorGroup);
    }
    deviceGroups.splice(index, 1, viewableDeviceGroup);
    if (secondIndex >= 0) {
      editedDeviceGroups.splice(secondIndex, 1, deviceGroup);
    } else {
      editedDeviceGroups.push(deviceGroup);
    }
    await localforage.setItem('deviceGroups', { deviceGroups, ...other });
    await localforage.setItem('deviceGroups:edit', editedDeviceGroups);
    return true;
  }
  return false;
};

export const removeDeviceGroupOffline = async (id: string) => {
  const { deviceGroups, totalDocs, ...other } = await localforage.getItem<any>('deviceGroups');
  const removedDeviceGroups: DeviceGroup[] =
    (await localforage.getItem('deviceGroups:remove')) || [];

  const index = deviceGroups.findIndex((group: DeviceGroup) => group.id === id);
  deviceGroups.splice(index, 1);
  await localforage.setItem('deviceGroups', {
    ...other,
    deviceGroups,
    totalDocs: totalDocs - 1,
  });
  await localforage.setItem('deviceGroups:remove', [...removedDeviceGroups, id]);
  return true;
};

const addCachedDeviceGroupToDB = async () => {
  const newGroupIds = (await getDeviceGroupIdMapping()) || {};
  const failedIds: string[] = [];
  const addedDeviceGroups = await getAddedDeviceGroups();
  if (addedDeviceGroups && addedDeviceGroups.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const group of addedDeviceGroups) {
      const { id, syncState, ...cleanedDeviceGroup } = group;
      if (syncState !== 'success') {
        if (cleanedDeviceGroup.parent.startsWith('TempGroupID')) {
          cleanedDeviceGroup.parent = newGroupIds[cleanedDeviceGroup.parent];
        }
        const alreadyAdded = newGroupIds[id!];
        if (alreadyAdded) {
          const editedGroup = { ...cleanedDeviceGroup, id: alreadyAdded };
          // eslint-disable-next-line no-await-in-loop
          // const res = await updateDeviceGroupInDB(editedGroup);
          // if (!(res && res.deviceGroup)) {
          //   failedIds.push(id!);
          // }
        } else {
          // eslint-disable-next-line no-await-in-loop
          // const res = await addDeviceGroupToDB(cleanedDeviceGroup);
          // if (res && res.deviceGroup) {
          //   const { deviceGroup } = res;
          //   newGroupIds[id!] = deviceGroup.id;
          // } else {
          //   failedIds.push(group.id!);
          // }
        }
      }
    }
    const deviceGroupsAfterSync = addedDeviceGroups.map((group) => {
      if (failedIds.includes(group.id!)) {
        return {
          ...group,
          syncState: 'error',
        };
      }
      return {
        ...group,
        syncState: 'success',
      };
    });
    await setDeviceGroupIdMapping(newGroupIds);
    await localforage.setItem('deviceGroups:add', deviceGroupsAfterSync);
  }
};

const updateCachedDeviceGroupsInDB = async () => {
  const newGroupIds = (await getDeviceGroupIdMapping()) || {};
  const failedIds: string[] = [];
  const updatedDeviceGroups = await getUpdatedDeviceGroups();
  if (updatedDeviceGroups && updatedDeviceGroups.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const group of updatedDeviceGroups) {
      const { syncState, id, ...cleanedDeviceGroup } = group;
      if (syncState !== 'success') {
        if (cleanedDeviceGroup.parent.startsWith('TempGroupID')) {
          cleanedDeviceGroup.parent = newGroupIds[cleanedDeviceGroup.parent];
        }
        // eslint-disable-next-line no-await-in-loop
        // const res = await updateDeviceGroupInDB({
        //   id,
        //   ...cleanedDeviceGroup,
        // });
        // if (!(res && res.deviceGroup)) {
        //   failedIds.push(id!);
        // }
      }
    }

    const deviceGroupsAfterSync = updatedDeviceGroups.map((group) => {
      if (failedIds.includes(group.id!)) {
        return {
          ...group,
          syncState: 'error',
        };
      }
      return {
        ...group,
        syncState: 'success',
      };
    });
    await localforage.setItem('deviceGroups:edit', deviceGroupsAfterSync);
  }
};

const removeCachedDeviceGroupsFromDB = async () => {
  const removedDeviceGroups = await getRemovedDeviceGroups();
  if (removedDeviceGroups && removedDeviceGroups.length > 0) {
    await Promise.all(
      removedDeviceGroups.map(async (groupId) => {
        await removeDeviceGroupFromDB(groupId);
      }),
    );
  }
};

/* RadioKeys */
const addCachedKeysToDB = async () => {
  const addedKeys = await getAddedKeys();
  const newGroupIds = (await getDeviceGroupIdMapping()) || {};
  const failedIds: string[] = [];
  if (addedKeys && addedKeys.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of addedKeys) {
      const { id, syncState, deviceGroup, ...cleanedKey } = key;
      if (syncState !== 'success') {
        let groupId = '';
        if (typeof deviceGroup === 'string') {
          groupId = deviceGroup;
        } else if (deviceGroup && deviceGroup.id) {
          groupId = deviceGroup.id;
        }
        const updatedKey = {
          ...cleanedKey,
          deviceGroup: groupId.startsWith('TempGroupID') ? newGroupIds[groupId] : groupId,
        };
        // eslint-disable-next-line no-await-in-loop
        const res = await addKeyToDB(updatedKey);

        if (!(res && res.radioKey)) {
          failedIds.push(id);
        }
      }
    }

    const keysAfterSync = addedKeys.map((key) => {
      if (failedIds.includes(key.id)) {
        return {
          ...key,
          syncState: 'error',
        };
      }
      return {
        ...key,
        syncState: 'success',
      };
    });
    await localforage.setItem('radioKeys:add', keysAfterSync);
  }
};

const updateCachedKeysInDB = async () => {
  const updatedKeys = await getUpdatedKeys();
  const newGroupIds = (await getDeviceGroupIdMapping()) || {};
  const failedIds: string[] = [];
  if (updatedKeys && updatedKeys.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of updatedKeys) {
      const { syncState, deviceGroup, ...cleanedKey } = key;
      if (syncState !== 'success') {
        let groupId = '';
        if (typeof deviceGroup === 'string') {
          groupId = deviceGroup;
        } else if (deviceGroup && deviceGroup.id) {
          groupId = deviceGroup.id;
        }
        const updatedKey = {
          ...cleanedKey,
          deviceGroup: groupId.startsWith('TempGroupID') ? newGroupIds[groupId] : groupId,
        };
        // eslint-disable-next-line no-await-in-loop
        const res = await updateKeyInDB(updatedKey);

        if (!(res && res.radioKey)) {
          failedIds.push(key.id);
        }
      }
    }
    const keysAfterSync = updatedKeys.map((key) => {
      if (failedIds.includes(key.id)) {
        return {
          ...key,
          syncState: 'error',
        };
      }
      return {
        ...key,
        syncState: 'success',
      };
    });
    await localforage.setItem('radioKeys:edit', keysAfterSync);
  }
};

const removeCachedKeysFromDB = async () => {
  const removedKeys = await getRemovedDevices();
  if (removedKeys && removedKeys.length > 0) {
    await Promise.all(
      removedKeys.map(async (key) => {
        await removeKeyFromDB(key);
      }),
    );
  }
};

const getFilteredKeys = async (filter?: IKeysFilter) => {
  const { radioKeys, ...other } = await localforage.getItem<any>('radioKeys');
  if (radioKeys) {
    const filteredKeys = [...radioKeys];
    const { name } = filter || {};

    const nameFilteredKeys = name
      ? filteredKeys.filter((radioKey: RadioKey) => includesCaseInsensitive(radioKey.name, name))
      : radioKeys;

    return { radioKeys: nameFilteredKeys, ...other };
  }
  return { radioKeys: [], ...other };
};

export const fetchKeysOffline = async ({
  page,
  pageSize,
  filters: filter,
}: FetchOptions<IKeysFilter>) => {
  if (page && pageSize) {
    const { radioKeys } = await getFilteredKeys(filter);
    const firstIndex = (page - 1) * pageSize;
    const endIndex = page * pageSize;
    const lastIndex = endIndex > radioKeys.length ? radioKeys.length : endIndex;

    return {
      totalDocs: radioKeys.length,
      totalPages: Math.round(radioKeys.length / pageSize),
      radioKeys: radioKeys.slice(firstIndex, lastIndex),
    };
  }
  return localforage.getItem('radioKeys');
};

export const fetchKeyOffline = async (id: string) => {
  const { radioKeys } = await localforage.getItem<any>('radioKeys');
  const item = radioKeys.find((radioKey: RadioKey) => radioKey.id === id);
  return {
    radioKey: item,
  };
};

export const addKeyOffline = async (key: RadioKey) => {
  const { radioKeys, totalDocs, ...other } = await localforage.getItem<any>('radioKeys');
  const addedRadioKeys: RadioKey[] = (await localforage.getItem('radioKeys:add')) || [];
  // const { deviceGroups } = await fetchDeviceGroupFromDB(key.deviceGroup);
  const viewableKey = {
    ...key,
    id: `TempID${addedRadioKeys.length + 1}`,
    // deviceGroup: deviceGroups[0],
  };

  await localforage.setItem('radioKeys', {
    radioKeys: [...radioKeys, viewableKey],
    totalDocs: totalDocs + 1,
    ...other,
  });
  await localforage.setItem('radioKeys:add', [...addedRadioKeys, viewableKey]);
  return true;
};

const updateKeysLocal = async (key: RadioKey) => {
  const { radioKeys, ...other } = await localforage.getItem<any>('radioKeys');
  const addedRadioKeys: RadioKey[] = (await localforage.getItem('radioKeys:add')) || [];
  const index = radioKeys.findIndex((keyObj: RadioKey) => keyObj.id === key.id);
  const secondIndex = addedRadioKeys.findIndex((keyObj: RadioKey) => keyObj.id === key.id);
  radioKeys.splice(index, 1, key);
  addedRadioKeys.splice(secondIndex, 1, key);
  await localforage.setItem('radioKeys', { radioKeys, ...other });
  await localforage.setItem('radioKeys:add', addedRadioKeys);
  return true;
};

export const updateKeyOffline = async (key: RadioKey) => {
  if (key.id && key.id.startsWith('Temp')) {
    return updateKeysLocal(key);
  }
  const { radioKeys, ...other } = await localforage.getItem<any>('radioKeys');
  const editedRadioKeys: RadioKey[] = (await localforage.getItem('radioKeys:edit')) || [];
  if (radioKeys) {
    const index = radioKeys.findIndex((radioKey: RadioKey) => radioKey.id === key.id);
    const secondIndex = editedRadioKeys.findIndex((radioKey: RadioKey) => radioKey.id === key.id);
    // const { deviceGroups } = await fetchDeviceGroupFromDB(key.deviceGroup);
    // const viewableKey = { ...key, deviceGroup: deviceGroups[0] };
    // radioKeys.splice(index, 1, viewableKey);
    if (secondIndex >= 0) {
      editedRadioKeys.splice(secondIndex, 1, key);
    } else {
      editedRadioKeys.push(key);
    }
    await localforage.setItem('radioKeys', { radioKeys, ...other });
    await localforage.setItem('radioKeys:edit', editedRadioKeys);
    return true;
  }
  return false;
};

export const removeKeyOffline = async (id: string) => {
  const { radioKeys, totalDocs, ...other } = await localforage.getItem<any>('radioKeys');
  const removedRadioKeys: RadioKey[] = (await localforage.getItem('radioKeys:remove')) || [];

  const index = radioKeys.findIndex((radioKey: RadioKey) => radioKey.id === id);
  radioKeys.splice(index, 1);
  await localforage.setItem('radioKeys', {
    ...other,
    radioKeys,
    totalDocs: totalDocs - 1,
  });
  await localforage.setItem('radioKeys:remove', [...removedRadioKeys, id]);
  return true;
};

/* Devices */
const updateChangeDevice = async (newDeviceId: string, oldDeviceId: string) => {
  const updatedDevices = await getUpdatedDevices();
  if (updatedDevices) {
    const index = updatedDevices.findIndex((device) => device.id === oldDeviceId);
    if (index >= 0) {
      const oldDevice = updatedDevices[index];
      const updatedDevice = { ...oldDevice, newDevice: newDeviceId };
      updatedDevices.splice(index, 1, updatedDevice);
      await localforage.setItem('devices:edit', updatedDevices);
    }
  }
};

const addCachedDevicesToDB = async () => {
  const addedDevices = await getAddedDevices();
  const newGroupIds = (await getDeviceGroupIdMapping()) || {};
  const failedIds: string[] = [];
  if (addedDevices && addedDevices.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const device of addedDevices) {
      const {
        id,
        syncState,
        // @ts-ignore
        images,
        oldDevice,
        deviceGroup,
        ...cleanedDevice
      } = device;
      if (syncState !== 'success') {
        const deviceGroupId = deviceGroup.startsWith('TempGroupID')
          ? newGroupIds[deviceGroup]
          : deviceGroup;
        const updatedDevice = {
          ...cleanedDevice,
          deviceGroup: deviceGroupId,
        };
        // eslint-disable-next-line no-await-in-loop
        const res = await addDeviceToDB(updatedDevice);
        if (images && res) {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const { device } = res;
          if (device && device.id && images.length > 0) {
            const imagesForm = appendFilesToFormData(new FormData(), images);
            // eslint-disable-next-line no-await-in-loop
            await addDeviceImages(device.id, imagesForm);
          }
          if (oldDevice && device.id) {
            // eslint-disable-next-line no-await-in-loop
            await updateChangeDevice(device.id, oldDevice);
          }
        }

        if (!res || !res.device) {
          failedIds.push(id);
        }
      }
    }

    const devicesAfterSync = addedDevices.map((device) => {
      if (failedIds.includes(device.id)) {
        return {
          ...device,
          syncState: 'error',
        };
      }
      return {
        ...device,
        syncState: 'success',
      };
    });
    await localforage.setItem('devices:add', devicesAfterSync);
  }
};

const updateCachedDevicesInDB = async () => {
  const updatedDevices = await getUpdatedDevices();
  const newGroupIds = (await getDeviceGroupIdMapping()) || {};
  const failedIds: string[] = [];
  if (updatedDevices && updatedDevices.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const device of updatedDevices) {
      const {
        syncState,
        // @ts-ignore
        images,
        deviceGroup,
        ...cleanedDevice
      } = device;
      if (syncState !== 'success') {
        const deviceGroupId = deviceGroup.startsWith('TempGroupID')
          ? newGroupIds[deviceGroup]
          : deviceGroup;
        const updatedDevice = {
          ...cleanedDevice,
          deviceGroup: deviceGroupId,
        };
        // eslint-disable-next-line no-await-in-loop
        const res = await updateDeviceInDB(updatedDevice);
        if (images && res) {
          const { device: newDevice } = res;
          if (newDevice && newDevice.id && images.length > 0) {
            const imagesForm = appendFilesToFormData(new FormData(), images);
            // eslint-disable-next-line no-await-in-loop
            await addDeviceImages(newDevice.id, imagesForm);
          }
        }
        if (!res || !res.device) {
          failedIds.push(device.id);
        }
      }
    }

    const devicesAfterSync = updatedDevices.map((device) => {
      if (failedIds.includes(device.id)) {
        return {
          ...device,
          syncState: 'error',
        };
      }
      return {
        ...device,
        syncState: 'success',
      };
    });
    await localforage.setItem('devices:edit', devicesAfterSync);
  }
};

const removeCachedDevicesFromDB = async () => {
  const removedDevices = await getRemovedDevices();
  if (removedDevices && removedDevices.length > 0) {
    await Promise.all(
      removedDevices.map(async (device) => {
        await removeDeviceFromDB(device);
      }),
    );
  }
};

export const syncCachedDataToBackend = async () => {
  store.dispatch(updateOffline(false));

  await DeviceTypeCache.persistAllTemporaryChangesFromCache();

  store.dispatch(setLoadingMessage({ message: 'addCacheDeviceGroups', translatable: true }));
  await addCachedDeviceGroupToDB();
  store.dispatch(
    setLoadingMessage({
      message: 'updateCacheDeviceGroups',
      translatable: true,
    }),
  );
  await updateCachedDeviceGroupsInDB();
  store.dispatch(
    setLoadingMessage({
      message: 'removeCacheDeviceGroups',
      translatable: true,
    }),
  );
  await removeCachedDeviceGroupsFromDB();

  store.dispatch(setLoadingMessage({ message: 'addCacheDevices', translatable: true }));
  await addCachedDevicesToDB();
  store.dispatch(setLoadingMessage({ message: 'updateCacheDevices', translatable: true }));
  await updateCachedDevicesInDB();
  store.dispatch(setLoadingMessage({ message: 'removeCacheDevices', translatable: true }));
  await removeCachedDevicesFromDB();
  store.dispatch(setLoadingMessage({ message: 'addCacheKeys', translatable: true }));
  await addCachedKeysToDB();
  store.dispatch(setLoadingMessage({ message: 'updateCacheKeys', translatable: true }));
  await updateCachedKeysInDB();
  store.dispatch(setLoadingMessage({ message: 'removeCacheKeys', translatable: true }));
  await removeCachedKeysFromDB();
  store.dispatch(updateOffline(true));
  store.dispatch(setLoadingMessage({ message: '', translatable: true }));
};

const getDeviceKey = (parsedFilter: Record<string, unknown>) => {
  // @ts-ignore
  if (parsedFilter && 'deviceGroup' in parsedFilter && parsedFilter.deviceGroup === null) {
    return 'devices:unassigned';
  }
  // @ts-ignore
  if (parsedFilter && 'blacklisted' in parsedFilter && parsedFilter.blacklisted) {
    return 'devices:blacklisted';
  }
  return 'devices';
};

const getFilteredDevices = async (filter: Record<string, unknown>) => {
  const { devices, ...other } = await localforage.getItem<any>(getDeviceKey(filter));
  if (devices) {
    let filteredDevices = [...devices];

    const { name, deviceGroup, deviceType, gateways, status } = filter;
    if (name && typeof name === 'string') {
      filteredDevices = filteredDevices.filter(
        (device: Device) => device.name?.includes(name) || device.deviceId?.includes(name),
      );
    }
    if (Array.isArray(deviceGroup) && deviceGroup.length > 0) {
      filteredDevices = filteredDevices.filter((device: Device) =>
        deviceGroup.includes(device.deviceGroup.id),
      );
    }
    if (Array.isArray(deviceType) && deviceType.length > 0) {
      filteredDevices = filteredDevices.filter((device: Device) =>
        deviceType.includes(device.deviceType?.id),
      );
    }
    if (Array.isArray(gateways) && gateways.length > 0) {
      const gatewayIds = gateways.map((gateway: Device) => gateway.id).join(',');
      filteredDevices = filteredDevices.filter(
        (device: Device) =>
          device.gateways &&
          device.gateways.length > 0 &&
          // @ts-ignore
          device.gateways.some((gateway) => gatewayIds.includes(gateway)),
      );
    }
    const manufacturerFlagIds = filter['manufacturer.flagId'];
    if (Array.isArray(manufacturerFlagIds) && manufacturerFlagIds.length > 0) {
      filteredDevices = filteredDevices.filter((device: Device) =>
        manufacturerFlagIds.includes(device.manufacturer?.flagId),
      );
    }
    if (Array.isArray(status) && status.length > 0) {
      filteredDevices = filteredDevices.filter(
        (device: Device) =>
          device.status &&
          device.status.length > 0 &&
          status.some((s) => device.status?.includes(s)),
      );
    }
    return { devices: filteredDevices, ...other };
  }
  return { devices: [], ...other };
};

export const fetchDevicesOffline = async ({
  page,
  pageSize,
  filters: filter,
}: FetchOptions<DeviceManagerFilter>): Promise<Devices> => {
  const parsedFilter = JSON.parse(getDeviceManagersFilterString(filter)) || {};
  if (page && pageSize) {
    const { devices } = await getFilteredDevices({ ...parsedFilter, gateways: filter?.gateways });
    const firstIndex = (page - 1) * pageSize;
    const endIndex = page * pageSize;
    const lastIndex = endIndex > devices.length ? devices.length : endIndex;

    return {
      totalDocs: devices.length,
      totalPages: Math.round(devices.length / pageSize),
      devices: devices.slice(firstIndex, lastIndex),
    };
  }
  return localforage.getItem('devices');
};

export const fetchDeviceOffline = async (id: string) => {
  const { devices } = await localforage.getItem<any>('devices');
  let item = devices.find((device: Device) => device.id === id);
  if (!item) {
    const { devices: unassignedDevices } = await localforage.getItem<any>('devices:unassigned');
    item = unassignedDevices.find((device: Device) => device.id === id);
  }
  if (!item) {
    const { devices: blacklistedDevices } = await localforage.getItem<any>('devices:blacklisted');
    item = blacklistedDevices.find((device: Device) => device.id === id);
  }
  return item;
};

export const addDeviceOffline = async (device: DeviceCreation) => {
  const { devices, totalDocs, ...other } = await localforage.getItem<any>('devices');
  const addedDevices: Record<string, unknown>[] = (await localforage.getItem('devices:add')) || [];

  // const { deviceGroups } = await fetchDeviceGroupFromDB(device.deviceGroup);
  const viewableDevice = {
    ...device,
    id: `TempID${addedDevices.length + 1}`,
    // deviceGroup: deviceGroups[0],
  };

  await localforage.setItem('devices', {
    devices: [...devices, viewableDevice],
    totalDocs: totalDocs + 1,
    ...other,
  });
  await localforage.setItem('devices:add', [...addedDevices, device]);
  return {
    device: viewableDevice,
  };
};

const updateDeviceLocal = async (device: Partial<Device>) => {
  const { devices, totalDocs, ...other } = await localforage.getItem<any>('devices');
  const addedDevices: Partial<Device>[] = (await localforage.getItem('devices:add')) || [];
  const index = devices.findIndex((deviceObj: Device) => deviceObj.id === device.id);
  const secondIndex = addedDevices.findIndex(
    (deviceObj: Partial<Device>) => deviceObj.id === device.id,
  );
  devices.splice(index, 1, device);
  addedDevices.splice(secondIndex, 1, device);
  await localforage.setItem('devices', { devices, ...other });
  await localforage.setItem('devices:add', addedDevices);
  return {
    device,
  };
};

export const updateDeviceOffline = async (device: Partial<Device>) => {
  if (device.id && device.id.startsWith('Temp')) {
    return updateDeviceLocal(device);
  }
  const { devices, ...other } = await localforage.getItem<any>('devices');
  const editedDevices: Partial<Device>[] = (await localforage.getItem('devices:edit')) || [];
  if (devices) {
    const index = devices.findIndex((deviceObj: Device) => deviceObj.id === device.id);
    const secondIndex = editedDevices.findIndex(
      (deviceObj: Partial<Device>) => deviceObj.id === device.id,
    );
    // const { deviceGroups } = await fetchDeviceGroupFromDB(device.deviceGroup);
    // const viewableDevice = { ...device, deviceGroup: deviceGroups[0] };
    // devices.splice(index, 1, viewableDevice);
    if (secondIndex >= 0) {
      editedDevices.splice(secondIndex, 1, device);
    } else {
      editedDevices.push(device);
    }

    await localforage.setItem('devices', { devices, ...other });
    await localforage.setItem('devices:edit', editedDevices);
    return {
      // device: viewableDevice,
    };
  }
  return false;
};

export const removeDeviceOffline = async (id: string) => {
  const { devices, totalDocs, ...other } = await localforage.getItem<any>('devices');
  const removedDevices: Device[] = (await localforage.getItem('devices:remove')) || [];

  const index = devices.findIndex((device: Device) => device.id === id);
  devices.splice(index, 1);
  await localforage.setItem('devices', {
    ...other,
    devices,
    totalDocs: totalDocs - 1,
  });
  await localforage.setItem('devices:remove', [...removedDevices, id]);
  return true;
};

export const fetchDeviceGatewaysOffline = async ({ filters: filter }: FetchOptions<string>) => {
  // @ts-ignore
  const { name } = filter ? JSON.parse(filter) : {};
  const { devices } = await localforage.getItem<any>('devices');
  const { devices: unassignedDevices } = await localforage.getItem<any>('devices:unassigned');
  const { devices: blacklistedDevices } = await localforage.getItem<any>('devices:blacklisted');
  const allDevices = [...devices, ...unassignedDevices, ...blacklistedDevices];
  const gatewayIds = allDevices.reduce((allGs: string[], device: Device) => {
    if (device.gateways) {
      return [...allGs, ...device.gateways];
    }
    return allGs;
  }, []);
  const allGateways = allDevices.filter((device: Device) => {
    if (name) {
      return (
        (device.name?.includes(name) || device.deviceId?.includes(name)) &&
        gatewayIds.includes(device.id)
      );
    }
    return gatewayIds.includes(device.id);
  });
  return {
    devices: allGateways,
  };
};

export const fetchMessagesForDeviceOffline = async (deviceId: string) => {
  const device = await fetchDeviceOffline(deviceId);
  // @ts-ignore
  return device.lastMessages;
};
