import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';

import {
  OPEN_FEEDBACK_MODAL,
  OpenFeedbackModalAction,
  CLOSE_FEEDBACK_MODAL,
  CloseFeedbackModalAction,
  SEND_FEEDBACK,
  SendFeedbackAction,
  FEEDBACK_SENT,
  FeedbackSentAction,
} from './feedbackActions';

import { authenticatedInstance as httpClientInstance } from '../../api/apiHelpers';
import { handleApiError } from '../../api/apiHelpers';
import useTranslation from '../../hooks/useTranslation';

import { FeedbackInformation, FeedbackAttachment } from "../../components/Feedback/feedback";
import { DispatchActionTypes } from '../types';

export const openFeedbackModal = (attachment: FeedbackAttachment | null = null) => (dispatch: Dispatch<DispatchActionTypes>): void => {
    dispatch({
      type: OPEN_FEEDBACK_MODAL,
      attachment,
    } as OpenFeedbackModalAction);
  };

export const closeFeedbackModal = () => (
  dispatch: Dispatch<DispatchActionTypes>,
): void => {
  dispatch({ type: CLOSE_FEEDBACK_MODAL } as CloseFeedbackModalAction);
};

export const sendFeedback = (feedbackInformation: FeedbackInformation) => async (dispatch: Dispatch<DispatchActionTypes>): Promise<void> => {
  const translate= useTranslation();

  const url = `/feedback/email`;
  dispatch({ type: SEND_FEEDBACK, information: feedbackInformation } as SendFeedbackAction);
  try {
    const response = await httpClientInstance.post(url, feedbackInformation);
    if (response) {
      toastr.success(
        translate('success').toString(),
        translate('feedback-form-submit-complete').toString(),
      );
      dispatch({ type: FEEDBACK_SENT, response } as FeedbackSentAction);
    }
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};
