import { Dispatch } from 'redux';
import { setFilter } from './set-filter-action-creator';

export const updateDeviceMessageFilter = () => (
  dispatch: Dispatch<any>,
): void => {
  // Override device message filter from URL search params
  const urlParams = new URLSearchParams(window.location.search);
  const deviceParam = urlParams.get('idOfDevice');
  if (deviceParam) {
    dispatch(
      setFilter({
        page: 'device-messages',
        values: {
          idOfDevice: deviceParam || undefined,
        },
      }),
    );
  }
  const rangeParam = urlParams.get('range');
  if (rangeParam) {
    const parsedRangeParams = JSON.parse(rangeParam);
    const receivedAtGte = new Date(parsedRangeParams.start)?.getTime();
    const receivedAtLte = new Date(parsedRangeParams.stop)?.getTime();
    dispatch(
      setFilter({
        page: 'device-messages',
        values: {
          receivedAt_gte: receivedAtGte || undefined,
          receivedAt_lte: receivedAtLte || undefined,
          period: undefined,
        },
      }),
    );
  }
};
