import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

export interface DropdownIconProps {
  isExpanded: boolean;
  className?: string;
  color?: string;
}

export const ExpandIcon = (props: DropdownIconProps) => {
  const {
    isExpanded,
    color = "var(--main-color)",
    className = 'select__dropdown-icon',
  } = props;
  return (
    <FontAwesomeIcon
      icon={ isExpanded ? faAngleUp : faAngleDown }
      className={ className }
      color={ color }
    />
  );
}
