import { USER_IS_VALIDATED, UserIsValidatedAction } from './userValidationActions';

export interface UserValidationResultState {
  email: string | null;
  wasAlreadyValidated: boolean;
}

const initialState = { email: null, wasAlreadyValidated: false };

export const userValidationResultReducer = (
  state: UserValidationResultState = initialState,
  action: UserIsValidatedAction,
): UserValidationResultState => {
  switch (action.type) {
    case USER_IS_VALIDATED:
      return {
        email: action.email,
        wasAlreadyValidated: action.wasAlreadyValidated,
      };

    default:
      return state;
  }
};
