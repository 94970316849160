import React, { ReactElement } from 'react';
import { Translate } from 'react-localize-redux';
import RenderOnCondition from '../../components/RenderOnCondition';
import LoadingIcon from '../../components/shared/LoadingIcon';

interface DashboardBoxProps {
  children: ReactElement | ReactElement[] | null;
  isLoading: boolean;
  title: string;
}

const DashboardBox = (props: DashboardBoxProps) => {
  const { children, isLoading, title } = props;

  return (
    <>
      <h5 className="responsive-container__card--title text-color-main">
        <Translate id={title} />
      </h5>
      <div className="responsive-container__item__card">
        <RenderOnCondition condition={isLoading}>
          <div className="dashboard__reading__loading-icon">
            <LoadingIcon />
          </div>
        </RenderOnCondition>
        <RenderOnCondition condition={!isLoading}>{children}</RenderOnCondition>
      </div>
    </>
  );
};

export default DashboardBox;
