import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

export const UpdateBoundComponent = (props: { bounds: L.LatLngBounds }) => {
  /*
  This component is just used to update the map bounds based on the markers displayed.
  This doesn't render any UI. As useMap context is only available for child components of MapContainer, this should be
  added as a child component
  */
  const map = useMap();
  useEffect(() => {
    if (props.bounds && props.bounds.isValid()) {
      map.fitBounds(props.bounds);
    }
  }, [map, props]);

  return null;
};
