import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { DEVICE_MESSAGES_PATH } from './paths';

export interface LinkToDeviceMessagesPageProps {
  children: React.ReactNode | React.ReactNode[];
  idOfDevice?: string;
  startDate?: Date;
  className?: string;
  style?: React.CSSProperties;
}

export const LinkToDeviceMessagesPage = (props: LinkToDeviceMessagesPageProps) => {
  const {
    className,
    children,
    idOfDevice,
    startDate,
    style,
  } = props;

  let url = DEVICE_MESSAGES_PATH;
  if (idOfDevice) {
    url += `?idOfDevice=${ idOfDevice }`;
  }
  if (startDate) {
    url += `&range={"start":"${ moment(startDate).utc().format()}"}`;
  }

  return (
    <Link
      to={ url }
      className={ className }
      style={ style }
    >
      {children}
    </Link>
  );
}
