import { IS_LOADING, SET_LOADING_MESSAGE } from '../constants/actionTypes';
import { IsLoadingActionTypes, LoadingMessageState, SetLoadingMessageActionTypes } from '../types';

export const isLoading = (loading: boolean): IsLoadingActionTypes => ({
  type: IS_LOADING,
  payload: loading,
});

export const setLoadingMessage = (message: LoadingMessageState): SetLoadingMessageActionTypes => ({
  type: SET_LOADING_MESSAGE,
  payload: message,
});
