import { Dispatch } from 'redux';
import HttpStatus from 'http-status-codes';
import { DeviceViews, DisplayTypes } from '@wiot/shared-domain/models/device/device';

import { DEVICE_MANAGER_PAGE_KEY } from '@wiot/shared-domain/domain/device-manager/device-manager-page-key';
import { FETCH_DEVICES } from '../constants/actionTypes';
import { IPagination } from '../types';
import { devicesFetched, FetchDevicesAction } from './devicesActions';
import { showDataExceedsDisplayLimit, showNoResultsInfo } from './fetching-limit/fetchNoResultInfoAction';
import { PageKeys } from '../reducers/filterSortReducer';
import { FETCH_CANCELLED_BY_NEW_FETCH, fetchDevicesFromDB } from '../../api/apiHelpers';
import { getFetchOptions } from '../../utils/common';
import { savePagination } from '../actions/savePaginationAction';
import { isLoading } from '../actions/isLoadingAction';
import { isTableLoading } from '../table/isTableLoadingAction';
import { fetchDeviceReadingsActionCreator } from '../device-readings/fetch-device-readings/fetchDeviceReadingsActionCreators';
import { DeviceManagerFilter } from './device-manager-filter';
import { fetchDeviceGroupTree } from '../device-tree/fetch-device-tree/fetchDeviceGroupTreeActionCreator';
import { AppState } from '../reducers/rootReducer';


function updateFilterBasedOnDisplayType(filter: DeviceManagerFilter, groupAssignmentSettings: DisplayTypes) {
  if (groupAssignmentSettings === DisplayTypes.ASSIGNED) {
    return filter;
  }

  const deviceListOptions =
      groupAssignmentSettings === DisplayTypes.UNASSIGNED ? { showOnlyUnAssigned: true } : { blacklisted: true };

  return {
    ...filter,
    ...deviceListOptions,
  };
}

export const fetchDevicesByView = () => async (dispatch: Dispatch<any>, getState: () => AppState) => {
  const { pagination, filters, currentEntriesPerPage, deviceManagerState } = getState();

  switch (deviceManagerState.visibleView) {
    case DeviceViews.TOPOLOGY:
      dispatch(fetchDeviceGroupTree(filters.filter[DEVICE_MANAGER_PAGE_KEY]));
      break;
    case DeviceViews.TABLE:
      dispatch(
        fetchDevices(
          deviceManagerState.contentDisplay,
          currentEntriesPerPage,
          pagination[DEVICE_MANAGER_PAGE_KEY].currentPage,
          filters.filter[DEVICE_MANAGER_PAGE_KEY],
          DEVICE_MANAGER_PAGE_KEY,
          false,
        )
      );
      break;
    default:
  }
};

export const fetchDevices = (
    groupAssignmentSettings: DisplayTypes,
    pageSize: number,
    currentPage: number,
    filter: DeviceManagerFilter,
    callerPage: PageKeys,
    fetchDeviceReadings: boolean,
  ) => async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch({
      type: FETCH_DEVICES,
    } as FetchDevicesAction);

    const updatedFilter: DeviceManagerFilter = updateFilterBasedOnDisplayType(filter, groupAssignmentSettings);

    const fetchOptions = getFetchOptions<DeviceManagerFilter>(pageSize, undefined, updatedFilter, currentPage);

    dispatch(isLoading(true));
    dispatch(isTableLoading(true));
    dispatch(showNoResultsInfo());

    let deactivateLoadingWhenFinished = true;
    try {
      const res = await fetchDevicesFromDB({ ...fetchOptions });
      if (res && res.devices) {
        const { devices: devicesRes, totalDocs: totalDocsRes, totalPages } = res;
        dispatch(devicesFetched(devicesRes));
        const paginationData: IPagination = { totalDocs: totalDocsRes || 0, totalPages };
        dispatch(savePagination(paginationData, callerPage));

        if (fetchDeviceReadings) {
          dispatch(fetchDeviceReadingsActionCreator(devicesRes.map((device) => device.id)));
        }
      }
    } catch (err) {
      if (err.response?.status === HttpStatus.UNPROCESSABLE_ENTITY) {
        dispatch(showDataExceedsDisplayLimit());
      }

      if (err.message === FETCH_CANCELLED_BY_NEW_FETCH) {
        deactivateLoadingWhenFinished = false;
      }
    } finally {
      if (deactivateLoadingWhenFinished) {
        dispatch(isLoading(false));
        dispatch(isTableLoading(false));
      }
    }
  };
