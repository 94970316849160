import { IDeviceRole, IRole } from '@wiot/shared-domain/models/role/role';

export const ROLE_DEFAULT_VALUES_KEY_MANAGER: IRole = {
  name: '',
  superAdmin: false,
  deviceTypes: [],
  deviceManager: {
    viewTopology: true,
  },
  deviceKeyDownload: {
    view: true,
  },
  deviceGroups: {
    view: true,
    add: false,
    edit: false,
    remove: false,
  },
  users: {
    view: false,
    add: false,
    edit: false,
    remove: false,
  },
  roles: {
    view: false,
    add: false,
    edit: false,
    remove: false,
    addRootAdmin: false,
  },
  profile: {
    changeUserSettings: true,
    changeAddress: true,
    changePassword: true,
  },
  settings: {
    view: false,
    edit: false,
  },
  permittedRoles: [],
};

export const DEVICE_ROLE_DEFAULT_VALUES_KEY_MANAGER: IDeviceRole = {
  edit: {
    basic: false,
    metadata: false,
  },
  view: {
    basic: true,
    metadata: true,
  },
  remove: false,
};
