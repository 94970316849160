import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';
import { ISite } from '@wiot/shared-domain/models/settings/settings';
import { IUser, IUserWithPermissions } from '@wiot/shared-domain/models/user/user';
import { AggregatedPermission } from '@wiot/shared-domain/models/role/role';
import { DispatchActionTypes } from '../../state/types';
import { fetchMyUserFromDB } from '../../api/apiHelpers';
import api from '../../api/api';
import { setCurrentUser, setUserPermission } from '../../state/actions/setCurrentUserAction';
import UserSettings from '../UserSettings/UserSettings';
import Desktop from '../Responsive/Desktop';
import Mobile from '../Responsive/Mobile';
import { AppState } from '../../state/reducers/rootReducer';
import SyncOfflineModal from '../Offline/SyncOfflineModal';
import RenderOnCondition from '../RenderOnCondition';
import { cacheDeviceManagerRequests } from '../../api/offline';
import HeaderProfileToggle from './HeaderProfileToggle';
import { PageKeys } from '../../state/reducers/filterSortReducer';

interface HeaderProps extends LocalizeContextProps {
  currentUser: IUser | undefined;
  currentPageTitle: PageKeys;
  onUserDetailsFetched: (object: IUser) => void;
  onUserPermissionFetched: (permission: AggregatedPermission) => void;
  setNavbarVisible: (visible: boolean) => void;
  navbarVisible: boolean;
  siteSettings: ISite;
  isOffline: boolean;
  isLoggedIn: boolean;
}

interface HeaderState {
  showUserSettings: boolean;
  showOfflineSyncModal: boolean;
}

const logo = `${api.baseAPIUrl}${api.customizeLogo}`;

class Header extends Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      showUserSettings: false,
      showOfflineSyncModal: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.isLoggedIn) {
      await this.fetchUserDetails();
    }
  };

  handleGoOffline = async () => {
    await cacheDeviceManagerRequests();
  };

  fetchUserDetails = async () => {
    try {
      const { onUserPermissionFetched, onUserDetailsFetched } = this.props;

      const { user, permission }: IUserWithPermissions = await fetchMyUserFromDB();

      onUserDetailsFetched(user);

      if (permission) {
        onUserPermissionFetched(permission);
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleGoOnline = () => {
    this.setState({
      showOfflineSyncModal: true,
    });
  };

  handleToggleOfflineState = async () => {
    const { translate, isOffline } = this.props;
    this.toggleUserSettings();
    if (isOffline) {
      toastr.info(translate('info').toString(), translate('handle-go-online').toString());
      this.handleGoOnline();
    } else {
      toastr.info(translate('info').toString(), translate('handle-go-offline').toString());
      await this.handleGoOffline();
    }
  };

  toggleOfflineSyncModal = () => {
    this.setState((prevState) => ({
      showOfflineSyncModal: !prevState.showOfflineSyncModal,
    }));
  };

  toggleUserSettings = () => {
    this.setState((prevState) => ({
      showUserSettings: !prevState.showUserSettings,
    }));
  };

  render() {
    const { currentUser, navbarVisible, setNavbarVisible } = this.props;

    return (
      <>
        <header className="header" data-testid="header">
          <Mobile>
            <>
              <div className="header__city">
                <span onClick={() => setNavbarVisible(!navbarVisible)}>
                  <FontAwesomeIcon
                    className="header__menu"
                    icon={navbarVisible ? faTimes : faBars}
                  />
                </span>
                {!navbarVisible && this.props.siteSettings?.title}
              </div>
              <RenderOnCondition condition={!navbarVisible}>
                <img src={logo} alt="logo" className="header__brand" />
              </RenderOnCondition>
              <RenderOnCondition condition={navbarVisible && currentUser}>
                <HeaderProfileToggle
                  toggleUserSettings={this.toggleUserSettings}
                  currentUser={currentUser!}
                  showUserSettings={this.state.showUserSettings}
                />
              </RenderOnCondition>
            </>
          </Mobile>
          <Desktop>
            <>
              <div className="header__left">
                <img src={logo} alt="logo" className="header__brand" />
                <h3 className="header__page-title" data-testid="header-page-title">
                  <Translate id={this.props.currentPageTitle} />
                </h3>
              </div>
              <div className="header__right">
                <div className="header__city">
                  <h6 className="header__city__text">{this.props.siteSettings?.title}</h6>
                </div>
                <RenderOnCondition condition={currentUser}>
                  <HeaderProfileToggle
                    toggleUserSettings={this.toggleUserSettings}
                    currentUser={currentUser!}
                    showUserSettings={this.state.showUserSettings}
                  />
                </RenderOnCondition>
              </div>
            </>
          </Desktop>
        </header>
        {this.state.showUserSettings && (
          <UserSettings
            closeUserSettings={this.toggleUserSettings}
            toggleOfflineState={this.handleToggleOfflineState}
          />
        )}
        <RenderOnCondition condition={this.state.showOfflineSyncModal}>
          <SyncOfflineModal toggleOfflineSyncModal={this.toggleOfflineSyncModal} />
        </RenderOnCondition>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  siteSettings: state.siteSettings,
  currentUser: state.currentUser.user,
  currentPageTitle: state.currentPageTitle,
  isOffline: state.isOffline,
  isLoggedIn: state.isLoggedIn,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  onUserDetailsFetched: (userDetails: IUser) => dispatch(setCurrentUser(userDetails)),
  onUserPermissionFetched: (permission: AggregatedPermission) => dispatch(setUserPermission(permission)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(Header));
