import React from 'react';
import { Translate } from 'react-localize-redux';
import { Formik } from 'formik';
import { Accordion, Card } from 'react-bootstrap';
import { ISettings } from '@wiot/shared-domain/models/settings/settings';
import { EmailSendgridSettings } from './EmailSendgridSettings';

interface EmailSettingsProps {
  tabKey: string;
  handleSubmit: (values: ISettings) => void;
  initialValues: ISettings;
  readOnly?: boolean;
}

const EmailSettings = ({ handleSubmit, initialValues, readOnly, tabKey }: EmailSettingsProps) => (
  <Formik enableReinitialize onSubmit={ handleSubmit } initialValues={ initialValues }>
    { (formikProps) => (
      <form id={ `${ tabKey }-settings` } className="form well" onSubmit={ formikProps.handleSubmit }>
        <div className="form__label mb-2">
          <div className="settings-header">
            <Translate id="emailProvider"/>
          </div>
        </div>
        <Accordion
          className="mt-3"
          activeKey={ formikProps.values.emailProvider?.toLocaleLowerCase() }
        >
          <Card>
            <Accordion.Toggle as={ Card.Header } eventKey="sendgrid" className="section-header">
              <Translate id="sendgrid-settings"/>
            </Accordion.Toggle>
            <EmailSendgridSettings formikProps={ formikProps } readOnly={ readOnly }/>
          </Card>
        </Accordion>
      </form>
    ) }
  </Formik>
);

export default EmailSettings;
