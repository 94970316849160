import React from 'react';
import { Image, Col, Button } from 'react-bootstrap';
import RenderOnCondition from "../RenderOnCondition";
import Portal from "../shared/Portal";

interface GalleryProps {
  files: (string | File)[];
  currentIndex: number;
  onGallerySlideClick: (index: number) => void;
  toggleGallery: (index: number) => void;
}

interface GalleryState {
  images: string[];
}

class Gallery extends React.Component<GalleryProps, GalleryState> {
  constructor(props: GalleryProps) {
    super(props);

    this.state = {
      images: [],
    };
  }

  componentDidMount = async () => {
    const { files } = this.props;
    const images: string[] = [];
    files.forEach((file) => {
      images.push(this.getFileUrl(file));
    });
    this.setState({
      images,
    });
  };

  onGalleryButtonSlide = (event: React.MouseEvent, direction: string) => {
    event.stopPropagation();
    const { files, currentIndex, onGallerySlideClick } = this.props;
    if (direction === 'right') {
      onGallerySlideClick(currentIndex === files.length - 1 ? 0 : currentIndex + 1);
    } else {
      onGallerySlideClick(currentIndex === 0 ? files.length - 1 : currentIndex - 1);
    }
  };

  getFileUrl = (file: File | string) => {
    if (typeof file === 'string') {
      return file;
    }
    return URL.createObjectURL(file);
  };

  render() {
    const { currentIndex, toggleGallery, files } = this.props;
    const { images } = this.state;
    return (
      <Portal>
        <Col
          className="gallery__popup"
          onClick={() => {
            toggleGallery(0);
          }}
        >
          <RenderOnCondition condition={files.length > 1}>
            <Button
              className="gallery__popup__direction-button"
              onClick={(event: React.MouseEvent) => {
                this.onGalleryButtonSlide(event, 'left');
              }}
              variant="secondary"
            >
              {`<`}
            </Button>
          </RenderOnCondition>
          <Image
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
            }}
            className="gallery__popup__image"
            thumbnail
            src={images[currentIndex]}
          />
          <RenderOnCondition condition={files.length > 1}>
            <Button
              className="gallery__popup__direction-button"
              onClick={(event: React.MouseEvent) => {
                this.onGalleryButtonSlide(event, 'right');
              }}
              variant="secondary"
            >
              {`>`}
            </Button>
          </RenderOnCondition>
        </Col>
      </Portal>
    );
  }
}

export default Gallery;
