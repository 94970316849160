import { Dispatch } from 'redux';
import { authenticatedInstance as httpClientInstance, handleApiError } from '../../api/apiHelpers';
import { USER_IS_VALIDATED, UserIsValidatedAction, VALIDATE_USER, ValidateUserAction } from './userValidationActions';

export const validateUser = (userId: string) => async (
  dispatch: Dispatch<any>,
): Promise<void> => {
  dispatch({ type: VALIDATE_USER, userId } as ValidateUserAction);
  try {
    const url = `/user/validate/${ userId }`;
    const { data } = await httpClientInstance.post(url);
    dispatch(
      {
        type: USER_IS_VALIDATED,
        email: data.email,
        wasAlreadyValidated: !data.email,
      } as UserIsValidatedAction);
  } catch (error) {
    handleApiError(error, false);
  }
};
