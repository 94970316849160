import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DashBoardGraph from './DashboardPieGraph';
import ErrorBoundary from '../../components/ErrorBoundary';
import DashboardBox from './DashboardBox';
import { useSelector } from 'react-redux';
import { AppState } from '../../state/reducers/rootReducer';

const DashboardOverview = () => {
  const { deviceCountsByTypeSummary, deviceStatusSummary, deviceGroupsSummary }
    = useSelector((state: AppState) => state.widgetsData);
  const isLoading = useSelector((state: AppState) => state.isLoading);

  return (
    <ErrorBoundary>
      <Row>
        <Col md={6} lg={ 6 } xl={4} className="vertical-spacer">
          <DashboardBox title="device-groups" isLoading={ isLoading }>
            <DashBoardGraph data={ deviceGroupsSummary }/>
          </DashboardBox>
        </Col>
        <Col md={6} lg={ 6 } xl={4} className="vertical-spacer">
          <DashboardBox title="devices-by-types" isLoading={ isLoading }>
            <DashBoardGraph data={ deviceCountsByTypeSummary }/>
          </DashboardBox>
        </Col>
        <Col md={6} lg={ 6 } xl={4} className="vertical-spacer">
          <DashboardBox title="devices-by-status" isLoading={ isLoading }>
            <DashBoardGraph data={ deviceStatusSummary }/>
          </DashboardBox>
        </Col>
      </Row>
    </ErrorBoundary>
  );
};

export default DashboardOverview;
