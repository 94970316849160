import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import { faUserCheck, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { EmailCredentials, LoginNameCredentials, UserCredentials } from '@wiot/shared-domain/models/auth/auth-api';
import ProcessStep from './ProcessStep';
import LoginForm from './LoginForm';
import { AppState } from '../../state/reducers/rootReducer';
import { loginAndFetchDeviceKeyProtection } from '../../state/actions/loginActionCreators';
import { logout as logoutActionCreator } from '../../state/actions/logoutActionCreator';
import PrimaryButton from '../shared/PrimaryButton';
import RenderOnCondition from '../RenderOnCondition';
import { useParams } from 'react-router-dom';
import InfoModal from '../Modal/InfoModal';

function AuthenticationStep(props: { downloadHash: string }) {
  const dispatch = useDispatch();
  const { downloadHash } = props;
  const { prefilledEmail = '' } = useParams<{ prefilledEmail?: string }>();

  const isLoggedIn = useSelector((appState: AppState) => appState.isLoggedIn);
  const currentUser = useSelector((appState: AppState) => appState.currentUser.user);
  const [ userHideEmailValidationModal, setUserHideEmailValidationModal ] = useState(false);
  const protectionDetails = useSelector(
    (state: AppState) => state.deviceKeyProtection.protectionDetails.protectionDetails,
  );

  const getAuthenticationStatusText = (): JSX.Element => {
    if (!protectionDetails.isProtected) {
      return <Translate id="device-key-download_start-process-prompt"/>;
    }

    if (isLoggedIn) {
      if (protectionDetails.hasCurrentUserAccessToKey) {
        return <Translate id="device-key-download_you-are-authorized"/>;
      }

      return <Translate id="device-key-download_you-are-not-authorized"/>;
    }

    if (protectionDetails.isProtectedByDevicePassword) {
      return <Translate id="device-key-download_enter-device-password"/>;
    }

    if (protectionDetails.assigneeMustBeValidated) {
      return <Translate id="device-key-download_email-must-be-validated"/>;
    }

    return <Translate id="device-key-download_please-log-in-to-access-the-key"/>;
  };

  const isLoginFormDisplayed = (): boolean =>
    protectionDetails.isProtected
    && !currentUser
    && !protectionDetails.assigneeMustBeValidated;

  const onLoginClick = (username: string, password: string) => {
    const credentials: UserCredentials = protectionDetails.isProtectedByDevicePassword ?
      { loginName: downloadHash, password } as LoginNameCredentials
      : { email: username, password } as EmailCredentials;

    dispatch(loginAndFetchDeviceKeyProtection(credentials, downloadHash));
  };

  const isLogoutButtonVisible = (): boolean =>
    isLoggedIn && !protectionDetails.hasCurrentUserAccessToKey && protectionDetails.isProtected;

  return (
    <ProcessStep
      isActive={ protectionDetails.isProtected }
      titleTranslationId={
        protectionDetails.isProtectedByDevicePassword ? 'password' : 'device-key-download_authentication'
      }
      mainMessage={ getAuthenticationStatusText() }
      mainMessageIcon={ protectionDetails.hasCurrentUserAccessToKey ? faUserCheck : faUserSlash }
      mainMessageIconColor={ protectionDetails.hasCurrentUserAccessToKey ? 'green' : '#ec2727' }
    >
      { isLoginFormDisplayed() && (
        <LoginForm
          onLoginButtonClick={ onLoginClick }
          loginButtonTranslationId={ protectionDetails.isProtectedByDevicePassword ? 'OK' : 'login' }
          userName={ protectionDetails.isProtectedByDevicePassword ? downloadHash : prefilledEmail }
          isUsernameVisible={ !protectionDetails.isProtectedByDevicePassword }
          autoFocusOnPassword={ !!prefilledEmail }
        />
      ) }

      <RenderOnCondition condition={ isLogoutButtonVisible() }>
        <div className="btn-group-vertical button-group">
          <PrimaryButton
            textTranslationId="device-key-download_use-different-account"
            onClick={ () => {
              dispatch(logoutActionCreator());
            } }
          />
        </div>
      </RenderOnCondition>
      <InfoModal
        closeModal={ () => {
          setUserHideEmailValidationModal(true);
        } }
        titleTranslationId="validate-user"
        mainTextTranslationId="device-key-download_email-must-be-validated"
        show={ protectionDetails.assigneeMustBeValidated && !userHideEmailValidationModal }
      />
    </ProcessStep>
  );
}

export default AuthenticationStep;
