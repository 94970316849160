import React from 'react';
import { useSelector } from 'react-redux';
import { components, MenuProps, OptionTypeBase } from 'react-select';
import { MenuButtons } from './MenuSelectAllNoneButtons';
import RenderOnCondition from '../../../RenderOnCondition';
import { AppState } from '../../../../state/reducers/rootReducer';

export interface CustomMenuProps<OptionType extends OptionTypeBase> extends MenuProps<OptionType> {
  showSelectAndDeselectAllButtons?: boolean;
  handleSelectAllClick: (MouseEvent) => void;
  handleSelectNoneClick: (MouseEvent) => void;
  defaultOptionsCount?: number;
}

export const Menu = <OptionType extends OptionTypeBase> (props: CustomMenuProps<OptionType>) => {
  const {
    showSelectAndDeselectAllButtons,
    handleSelectAllClick,
    handleSelectNoneClick,
    defaultOptionsCount,
    selectProps
  } = props;
  const { inputValue: searchText } = selectProps;
  const isDisabled = searchText !== '';
  return (
    <components.Menu
      { ...props }
      className="modal-select__menu"
    >
      <>
        { props.children }
        <RenderOnCondition condition={ showSelectAndDeselectAllButtons }>
          <MenuButtons
            handleSelectAllClick={ handleSelectAllClick }
            handleSelectNoneClick={ handleSelectNoneClick }
            defaultOptionsCount={ defaultOptionsCount }
            isDisabled={ isDisabled }
          />
        </RenderOnCondition>
      </>
    </components.Menu>
  );
}
