import { DataIntegrationJobExtended } from '@wiot/shared-domain/models/data-integration/data-integration';
import React from 'react';
import { Col } from 'react-bootstrap';
import { CustomFieldInput } from '../../../../components/Table/CustomFieldInput';
import { validateRequiredFieldValue } from '../../../../utils/validation';

interface IDescriptionInputProps {
  api: DataIntegrationJobExtended;
  width?: number;
}

const DescriptionInput = (props: IDescriptionInputProps) => (
  <Col lg={ props.width || 10 }>
    <CustomFieldInput
      validate={ validateRequiredFieldValue }
      translationId="description"
      fieldName="api.name"
      value={ props.api.name || '' }
      required
    />
  </Col>
);

export default DescriptionInput;
