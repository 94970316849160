
import {
  DEVICE_READINGS_FETCH_FAILED,
  DEVICE_READINGS_FETCHED,
  FETCH_DEVICE_READINGS,
  FetchDeviceReadingsActionTypes
} from './fetchDeviceReadingsTypes';

const initialState: boolean = false;

export const isLoadingDeviceReadingsReducer = (
  state: boolean = initialState,
  action: FetchDeviceReadingsActionTypes,
): boolean => {
  switch (action.type) {
    case FETCH_DEVICE_READINGS:
      return true;
    case DEVICE_READINGS_FETCH_FAILED:
    case DEVICE_READINGS_FETCHED:
      return false;
    default:
      return state;
  }
};
