import { TREE_NODE_EXPAND_STATE_CHANGE, TreeNodeExpandStateChangedAction } from './saveExpandStateActionCreator';
import { RESET_TREE_EXPAND_STATE, TreeExpandStateResetAction } from './resetTreeExpandStateActionCreator';
import { ExpandedNode } from '@wiot/shared-domain/domain/device-tree/tree-node';
import { INITIAL_TREE_CHILD_LIMIT } from '@wiot/shared-domain/domain/device-tree/initial-tree-children-limit';
import { TREE_NODE_FETCHED, TreeNodeFetchedAction } from '../fetch-tree-node-with-children/fetchTreeNodeAction';

export const TREE_EXPAND_STATE = 'TREE_EXPAND_STATE';

export interface TreeExpandState {
  type: typeof TREE_EXPAND_STATE;
  nodesExpandedByUser: ExpandedNode[];
}

const initialState: TreeExpandState = {
  nodesExpandedByUser: [],
  type: TREE_EXPAND_STATE
};

export const deviceTreeExpandStateReducer = (
  state: TreeExpandState = initialState,
  action: TreeNodeExpandStateChangedAction | TreeExpandStateResetAction | TreeNodeFetchedAction,
): TreeExpandState => {
  switch (action.type) {
    case TREE_NODE_EXPAND_STATE_CHANGE: {
      const nodeId = action.treeNode.id;
      const isExpanded = state.nodesExpandedByUser.some(node => node.nodeId === nodeId);
      const expand = action.isExpandAction && !isExpanded;
      if (expand) {
        const extendedNodes = [...state.nodesExpandedByUser, { nodeId: nodeId, childLimit: INITIAL_TREE_CHILD_LIMIT }];
        return { ...state, nodesExpandedByUser: extendedNodes };
      } else {
        const reducedNodes = state.nodesExpandedByUser.filter((node) => node.nodeId !== nodeId);
        return { ...state, nodesExpandedByUser: reducedNodes };
      }
    }

    case RESET_TREE_EXPAND_STATE:
      return { ...state, nodesExpandedByUser: initialState.nodesExpandedByUser };

    case TREE_NODE_FETCHED:
      const treeNodeId = action.treeNode.id;
      let expandedNode = state.nodesExpandedByUser.find(
        node => node.nodeId === treeNodeId);
      if (!expandedNode) {
        expandedNode = { nodeId: treeNodeId, childLimit: action.childLimit };
      }

      const updatedNodes = state.nodesExpandedByUser.map((node) => {
        return (node.nodeId === treeNodeId) ? { ...node, childLimit: action.childLimit } : node;
      });

      if (updatedNodes.length === 0) {
        updatedNodes.push({ nodeId: treeNodeId, childLimit: action.childLimit });
      }

      return { ...state, nodesExpandedByUser: updatedNodes };

    default:
      return state;
  }
};
