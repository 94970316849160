import React, { useCallback, useEffect, useState } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import { AggregatedPermission } from '@wiot/shared-domain/models/role/role';
import FilterBar from '../../components/Filter/FilterBar';
import Table from '../../components/Table/Table';
import { DeviceTypeExtended, IPagination } from '../../state/types';
import { hasPermission } from '../../utils/common';
import { removeDeviceTypeFromDB } from '../../api/apiHelpers';
import TableDataDeviceTypes from './Table/TableDataDeviceTypes';
import { AppState } from '../../state/reducers/rootReducer';
import { savePagination as savePaginationAction } from '../../state/actions/savePaginationAction';
import { isLoading } from '../../state/actions/isLoadingAction';
import { isTableLoading } from '../../state/table/isTableLoadingAction';
import ErrorBoundary from '../../components/ErrorBoundary';
import Desktop from '../../components/Responsive/Desktop';
import Mobile from '../../components/Responsive/Mobile';
import MobileTable from './Mobile/MobileTable';
import RenderOnCondition from '../../components/RenderOnCondition';
import DeviceTypeActionModal from './DeviceTypeActionModal';
import { IFilterState } from '../../state/reducers/filterSortReducer';
import { fetchDeviceTypes } from '../../state/device-types/fetchDeviceTypesActionCreator';

interface ManageDeviceTypesProps extends LocalizeContextProps {
  deviceTypes: IDeviceType[];
  setDeviceTypes: () => void;
  savePagination: (paginationData: IPagination) => void;
  toggleSelectUnit: (id: string) => void;
  toggleSelectAllUnits: (isChecked: boolean) => void;
  currentEntriesPerPage: number;
  currentPage: number;
  setIsLoading: (loading: boolean) => void;
  setIsTableLoading: (loading: boolean) => void;
  filter: IFilterState;
  permission?: AggregatedPermission;
}

const ManageDeviceTypes = (props: ManageDeviceTypesProps) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const {
    toggleSelectUnit,
    toggleSelectAllUnits,
    permission,
    deviceTypes,
    translate,
    setIsTableLoading,
    setIsLoading,
    setDeviceTypes,
  } = props;

  const fetchDeviceTypesCallback = useCallback(async () => {
    setIsTableLoading(true);
    try {
      await setDeviceTypes();

      // TODO(DS): Implement pagination
      // const paginationData: IPagination = { totalDocs, totalPages };
      // await savePagination(paginationData);
      setIsLoading(false);
      setIsTableLoading(false);
    } catch (e) {
      setIsLoading(false);
      setIsTableLoading(false);
    }
  }, [
    setIsLoading,
    setIsTableLoading,
  ]);

  useEffect(() => {
    setIsLoading(true);
    fetchDeviceTypesCallback();
  }, [fetchDeviceTypesCallback, setIsLoading]);

  const deleteDeviceType = async (deviceType: IDeviceType) => {
    setIsTableLoading(true);
    try {
      await removeDeviceTypeFromDB(deviceType);
      toastr.success(
        translate('success').toString(),
        translate('delete-device-type-success').toString(),
      );
      await fetchDeviceTypesCallback(); // TODO(DS): Check if necessary because we already remove it from the store afterwards.
    } catch (error) {
      toastr.error(translate('error').toString(), translate('delete-device-type-error').toString());
    }
  };

  const getSelectedDeviceTypes = (): DeviceTypeExtended[] =>
    deviceTypes ? deviceTypes.filter((deviceType: DeviceTypeExtended) => deviceType.checked) : [];

  const handleBulkRemove = async () => {
    const selectedDeviceTypes: DeviceTypeExtended[] = getSelectedDeviceTypes();
    const removePromises = selectedDeviceTypes.map(async (deviceType) =>
      deleteDeviceType(deviceType),
    );
    await Promise.all(removePromises);
    await fetchDeviceTypesCallback();
  };

  const selectAll = (event: React.FormEvent<HTMLInputElement>) => {
    const isChecked = event.currentTarget.checked;
    toggleSelectAllUnits(isChecked);
  };

  const toggleShowAddModal = () => {
    setShowAddModal((prevState) => !prevState);
  };

  const getTableComponent = () => (
    <TableDataDeviceTypes
      fetchDeviceTypes={fetchDeviceTypesCallback}
      deleteDeviceType={deleteDeviceType}
      markOneDeviceGroupAsSelected={toggleSelectUnit}
      markAllDeviceGroupsAsSelected={selectAll}
      isSelectAllChecked={deviceTypes && deviceTypes.length === getSelectedDeviceTypes().length}
    />
  );

  return (
    <main className="manage-device-types" data-testid="page-manage-device-types">
      <ErrorBoundary>
        <FilterBar page="manage-device-types" handleAddBtnClick={toggleShowAddModal} />
      </ErrorBoundary>
      <Desktop>
        <ErrorBoundary>
          <Table
            page="manage-device-types"
            changeView={false}
            addModal={hasPermission(permission, 'deviceTypes.add')}
            addText="device-type"
            tableComponent={getTableComponent()}
            selectedRows={getSelectedDeviceTypes()}
            handleBulkRemove={handleBulkRemove}
            elementType="deviceTypes"
            showPagination
            refreshTableData={fetchDeviceTypesCallback}
            editorModalCreator={(toggleShowEditorModal) => (
              <DeviceTypeActionModal
                isInCreationMode
                deviceTypeChanged={fetchDeviceTypesCallback}
                closeAddAndUpdateModal={toggleShowEditorModal}
                title="add-device-type"
              />
            )}
          />
        </ErrorBoundary>
      </Desktop>
      <Mobile>
        <>
          <MobileTable deleteDeviceType={deleteDeviceType} refreshData={fetchDeviceTypesCallback} />
          <RenderOnCondition condition={showAddModal}>
            <DeviceTypeActionModal
              isInCreationMode
              closeAddAndUpdateModal={toggleShowAddModal}
              deviceTypeChanged={fetchDeviceTypesCallback}
              title="add-device-type"
            />
          </RenderOnCondition>
        </>
      </Mobile>
    </main>
  );
};

const mapStateToProps = (state: AppState) => ({
  deviceTypes: state.deviceTypes,
  currentEntriesPerPage: state.currentEntriesPerPage,
  currentPage: state.pagination['manage-device-types'].currentPage,
  filter: state.filters.filter,
  permission: state.currentUser.permission,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setDeviceTypes: () => dispatch(fetchDeviceTypes()),
  savePagination: (paginationData: IPagination) =>
    dispatch(savePaginationAction(paginationData, 'manage-device-types')),
  setIsLoading: (loading: boolean) => dispatch(isLoading(loading)),
  setIsTableLoading: (loading: boolean) => dispatch(isTableLoading(loading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(ManageDeviceTypes));
