import React, { useEffect, useState } from 'react';
import { PageKeys } from '../../state/reducers/filterSortReducer';
import useTranslation from '../../hooks/useTranslation';
import { SelectableOption } from '../FilterBar/select/selectable-option';
import { SelectWrapperForFilters } from '../FilterBar/select/SelectWrapperForFilters';
import { getSelectedLanguage } from '../../utils/common';
import { useSelector } from 'react-redux';
import { AppState } from '../../state/reducers/rootReducer';
import { getDeviceStatuses } from '@wiot/shared-domain/models/device/device-status';

interface DeviceStatusSelectProps {
  pageKey: PageKeys;
}

const DeviceStatusSelect = ({ pageKey }: DeviceStatusSelectProps) => {
  const [options, setOptions] = useState<SelectableOption[]>([]);
  const isKeyManagerModeEnabled = useSelector((state: AppState) => !!state.siteSettings.isKeyManagerModeEnabled);
  const translate = useTranslation();

  const language = getSelectedLanguage()

  useEffect(() => {
    initializeDeviceStatusOptions();
  }, [ pageKey, language ]);

  const initializeDeviceStatusOptions = async (): Promise<void> => {
    try {
      const deviceStatuses = getDeviceStatuses(isKeyManagerModeEnabled);
      if (!deviceStatuses) {
        return;
      }
      const deviceStatusOptions = deviceStatuses.map(
        (status): SelectableOption => ({
          label: `${ translate(status) }`,
          value: status,
        }),
      );
      setOptions(deviceStatusOptions);
    } catch (e) {
      console.error(e);
      setOptions([])
    }
  };

  return (
    <SelectWrapperForFilters<SelectableOption>
      translationId="device-status"
      pageKey={ pageKey }
      filterFieldKey="status"
      defaultOptions={ options }
    />
  );
};

export default DeviceStatusSelect;
